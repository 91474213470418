import styled from "styled-components"
import WizrFlexLayout from "../../../../components/WizrFlexLayout"
import WizrInput from "../../../../components/WizrInput"
import WizrText from "../../../../components/WizrText"
import WizrIcon from "../../../../components/WizrIcon"
import WizrButton from "../../../../components/WizrButton"
import Wizrlogo from "../../../../assets/icons/wizrlogo"
import Upload from "../../../../assets/icons/Upload"
import InfoIcon from "../../../../assets/icons/Info"
import WizrUploadContainer from "../../../../components/WizrUploadButton"
import { useEffect, useState } from "react"
import { set } from "react-hook-form"
import { Alert } from "antd"
import { useBotDetailsSelector, useContentLoadingSelector, useFeatureSettingSelector } from "../../../../redux/selectors/content"
import { dispatchBotDetails, dispatchUpdateBot, dispatchUploadFileUsingLink } from "../../../../redux/actions/content"
import { useParams } from "react-router-dom"
import DislikeIcon from "../../../../assets/icons/DislikeIcon"
import LikeIcon from "../../../../assets/icons/LikeIcon"

const StyledWizrInput = styled(WizrInput)`
    width: 50%;
    height: 50px;
    background-color: #f2f4f9;
    border: 0px #f2f4f9 !important;
    `

const BotPersonality = (): JSX.Element => {
    const [imageSrc, setImageSrc] = useState('');

    // Function to update the image source for cache busting
    const refreshImage = () => {
      const cacheBuster = Date.now();
      const url = `https://wzsmartbotfiledev.blob.core.windows.net/icon/${BotDetails?.tenant_id}/${BotDetails?.id}/${botPersonality?.bot_icon}`

      setImageSrc(`${url}?${cacheBuster}`);
    };
  
    const featureSettings = useFeatureSettingSelector()
    const BotDetails = useBotDetailsSelector()
    const loading = useContentLoadingSelector()
    const [showWarning, setShowWarning] = useState(false)
    const [botPersonality, setBotPersonality] = useState(BotDetails?.bot_config?.bot_personality)
    const [botMessages, setBotMessages] = useState(BotDetails?.bot_config?.bot_personality?.bot_messages)

    useEffect(() => {
        setBotPersonality(BotDetails?.bot_config?.bot_personality)
        setBotMessages(BotDetails?.bot_config?.bot_personality?.bot_messages)
        console.log('BotDetails?.bot_config?.bot_personality?.bot_messages?.bot_icon',BotDetails?.bot_config?.bot_personality?.bot_icon)
        const cacheBuster = Date.now();
        const url = `https://wzsmartbotfiledev.blob.core.windows.net/icon/${BotDetails?.tenant_id}/${BotDetails?.id}/${BotDetails?.bot_config?.bot_personality?.bot_icon}?${cacheBuster}`
        setImageSrc(url)
        
    }, [BotDetails])
    
console.log('botsrc',imageSrc)
    const params = useParams()
    const featureId = params?.id
    console.log('BotDetails', BotDetails);
    const startFileUpload =async (fileInfo: any) => {
        setImageSrc('')
        
        
        const maxSize = 2 * 1024 * 1024 //2mb
        if (fileInfo.file.size > maxSize) {
            setShowWarning(true)
        }
        else {
            showWarning && setShowWarning(false)
            const fileUploadPayload={
                container_id: 6,
                path: `icon/${BotDetails?.tenant_id}/${BotDetails?.id}`,
                files: [
                //   `${fileInfo?.fileList[0]?.originFileObj?.name}`,
                'web_format_large_icon.png'
                ]
              }
              const formData = new FormData();
              const jsonData = JSON.stringify(fileUploadPayload);
              formData.append('data', jsonData);
              formData.append('web_format_large_icon.png', fileInfo?.fileList[0]?.originFileObj);
              await dispatchUploadFileUsingLink(formData)
              refreshImage()
              doUpdateField('bot_icon', 'web_format_large_icon.png')

        }
    }

    const doUpdateField = (field: string, value: string) => {
        setBotPersonality({ ...botPersonality, [field]: value })
    }
    const doUpdateMessage = (field: string, value: string) => {
        setBotMessages({ ...botMessages, [field]: value })

    }

    const onSaveAction =async () => {
        const payload = {
            tenant_feature_id: featureSettings?.id,
            app_feature_id: featureId,
            bot_config: {
                ...BotDetails,
                bot_config:{ 
                    ...BotDetails?.bot_config,
                    bot_personality: {
                    ...botPersonality,
                    bot_messages: botMessages,
                },                }
            }
        }
        await dispatchUpdateBot(payload)
        await dispatchBotDetails(BotDetails?.id)

    }

    const onCancelAction = () => {
        setBotPersonality(BotDetails?.bot_personality)
        setBotMessages(BotDetails?.bot_personality?.bot_messages)
    }

    console.log('botPersonality', `hhttps://wzsmartbotfiledev.blob.core.windows.net/icon/${BotDetails?.tenant_id}/${BotDetails?.id}/${botPersonality?.bot_icon}`)
    return (



        <WizrFlexLayout background="white" textAlign="left" style={{ gap: '10px', padding: '24px' }}>
            <WizrText type='sub1'>
                Bot Personality
            </WizrText>
            <WizrFlexLayout background="white" textAlign="left" style={{ gap: "5px" }}>
                <WizrText type='body2'> Bot Name </WizrText>
                <StyledWizrInput placeholder='Enter Bot Name' value={botPersonality?.bot_name} onChange={(value: any) => doUpdateField('bot_name', value)} />
            </WizrFlexLayout>
            <WizrFlexLayout background="white" textAlign="left" style={{ gap: "10px" }}>
                <WizrText type='body2'> Icon Settings </WizrText>
                <WizrFlexLayout background="white" textAlign="left" style={{ gap: "30px" }} flexDirection="row" justifyContent="flex-start" alignItems="center">
                    {/* <Wizrlogo /> */}
                   
                    <WizrUploadContainer onChange={startFileUpload} fileTypes=".png,.jpeg"  >
                        <WizrButton type='text' text='Upload Icon' style={{ border: '1.5px solid #1D1F22' }} icon={<Upload />}></WizrButton>
                    </WizrUploadContainer>
                    {imageSrc?
                    <img src={imageSrc} alt="icon"style={{ width: "140px", height: "50px" }}/>:<></>}
                </WizrFlexLayout>
                <WizrFlexLayout background="white" flexDirection="row" justifyContent="flex-start" style={{ gap: '10px' }}>
                    <InfoIcon />
                    <WizrText type='caption'>JPEG, PNG formats only, up to 2MB</WizrText>
                </WizrFlexLayout>
                {showWarning ? <Alert message="File size must be less than 2MB" type="error" showIcon style={{ width: '50%' }} /> : <></>}

            </WizrFlexLayout>
            <WizrFlexLayout background="white" textAlign="left" style={{ gap: "5px" }}>
                <WizrText type='body2'> Welcome message</WizrText>
                <StyledWizrInput placeholder='Hey How can I help you today' value={botMessages?.welcome_message} onChange={(value: any) => doUpdateMessage('welcome_message', value)} />
            </WizrFlexLayout>
            <WizrFlexLayout background="white" textAlign="left" style={{ gap: "5px" }}>
                <WizrText type='body2'>Unanswerable</WizrText>
                <StyledWizrInput placeholder='Oops I’m sorry I couldn’t generate response for that.' value={botMessages?.unanswerable_message} onChange={(value: any) => doUpdateMessage('unanswerable_message', value)} />
            </WizrFlexLayout>
            <WizrFlexLayout background="white" textAlign="left" style={{ gap: "5px" }}>
                <WizrFlexLayout background="#FFF" flexDirection="row" justifyContent="flex-start" style={{ gap: '8px' }}>
                    <WizrText type='body2'>Downvote message</WizrText>
                    <DislikeIcon />
                </WizrFlexLayout>
                <StyledWizrInput placeholder='Thanks for the feedback.' value={botMessages?.downvote_message} onChange={(value: any) => doUpdateMessage('downvote_message', value)} />
            </WizrFlexLayout>
            <WizrFlexLayout background="white" textAlign="left" style={{ gap: "5px" }}>
                <WizrFlexLayout background="#FFF" flexDirection="row" justifyContent="flex-start" style={{ gap: '8px' }}>
                    <WizrText type='body2'>Upvote message</WizrText>
                    <LikeIcon />
                </WizrFlexLayout>
                <StyledWizrInput placeholder='Thankyou for the upvote, let me know if need help with anything else..' value={botMessages?.upvote_message} onChange={(value: any) => doUpdateMessage('upvote_message', value)} />
            </WizrFlexLayout>
            <WizrFlexLayout background="white" textAlign="left" style={{ gap: "5px", padding: "5px" }} flexDirection="row" justifyContent="flex-end">
                <WizrButton type='text' text='Cancel' style={{ border: '1.5px solid #1D1F22', borderRadius: "16px" }} onClick={onCancelAction}></WizrButton>
                <WizrButton text='Save' style={{ borderRadius: "16px" }} onClick={onSaveAction} disabled={loading}></WizrButton>


            </WizrFlexLayout>
        </WizrFlexLayout>)
}
export default BotPersonality   