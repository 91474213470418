import { GroupOutlined } from "@ant-design/icons";
import NodeContainer from "./NodeContainer";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";
import WizrFlexLayout from "../../../components/WizrFlexLayout";
import WizrText from "../../../components/WizrText";
import WizrDropDown from "../../../components/WizrDropDown";
import { useFaqDataSelector, usePastTicketDataSelector, useVisionModelSelector } from "../../../redux/selectors/content";
import WizrInput from "../../../components/WizrInput";
import WizrFlexSpacing from "../../../components/WizrFlexSpacing";
import WizrTag from "../../../components/WizrTags";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import WizrButton from "../../../components/WizrButton";
import WizrToggleButton from "../../../components/WizrToggleButton";
import { useEdges, useNodes } from "reactflow";
import { generateNodeSequenceResponse, getTenantId } from "../../../utils";
import { dispatcVisionMModels } from "../../../redux/actions/content";

const VectorContainer = styled(WizrFlexLayout)`
  gap: 16px;
  align-items: flex-start;
`
const StyledFlexSpacing = styled(WizrFlexSpacing)`
  .ant-space-item {
    display: flex !important;
    width: 100% !important;
    flex-wrap: wrap;
  }
`
const StyledWizrTag = styled(WizrTag)`
  height: 42px;
  padding: 8px 11px;
  justify-content: space-between;
`

const VoiceBlock = (props: any):JSX.Element=>{

    const { id, dataCode, updateNodeData, data, onEdit, onCopyNodeDetail, isDisabled } = props 

    const [isEditView, setIsEditView] = useState(false)
    
    const onDeleteNodeDetail = (e: any) => {
        updateNodeData((nds: any) =>
          nds.filter((node: any) => node.id !== e)
        );
      }

   

      const [content, setContent] = useState('')
      const [top_k, setTop_k] = useState(false)
      const [threshold, setMatch_threshold] = useState(false)
      const [userQuery, setUser_query] = useState(false)
const folderList = data?.label==='Past Ticket Search Block'?usePastTicketDataSelector():useFaqDataSelector()

      useEffect(() => {
const content = folderList?.find((item:any)=>item?.folder_id===data?.config?.content)?.folder_name
        setContent(content)
        setTop_k(data?.config?.top_k)
        setMatch_threshold(data?.config?.match_threshold)
        setUser_query(data?.config?.user_query)
        // extractFilterInfo(data?.config?.filter);
        
      }, [data])
      const type = data?.label==='Past Ticket Search Block'?'8':'7'
    return(
      <NodeContainer
        item={{ nodeTitle: data?.label, id: id, icon: <GroupOutlined style={{ marginRight: 10 }} /> }}
        data={dataCode}
        isEditMode={isEditView}
        // saveCurrentAction={updateCurrentNodeData}
        onEditNodeDetail={() => { onEdit(data?.label, props)}}
        onDeleteNodeDetail={onDeleteNodeDetail}
        onCopyNodeDetail={() => onCopyNodeDetail(data, 'transcription')}
        isDisabled={isDisabled}
        nodeType={type}
      >
        <VectorContainer>
        <WizrText type='body3' textColor='#707276'>
          {data?.label==='Past Ticket Search Block'?'Past Tickets':'FAQ'}
            </WizrText>
            <WizrText type='body2' textColor='#1D1F22' >
              {content}
            </WizrText>
            <WizrText type='body3' textColor='#707276'>
              Top results (k)
            </WizrText>
            <WizrText type='body2' textColor='#1D1F22' >
              {top_k}
            </WizrText>
            <WizrText type='body3' textColor='#707276'>
              Match threshold
            </WizrText>
            <WizrText type='body2' textColor='#1D1F22' >
              {threshold}
            </WizrText>
            <WizrText type='body3' textColor='#707276'>
              User Query
            </WizrText>
            <WizrText type='body2' textColor='#1D1F22' >
              {userQuery}
            </WizrText>
        </VectorContainer>
      </NodeContainer>
    )
}

export default VoiceBlock