import React from 'react'
import { PieChart, Pie, Cell } from 'recharts'

const GaugeChart = ({value}:any): JSX.Element => {
  const width = 500
  const chartValue = 100-value // Point needle to position 50
  const colorData = [
    {
      value: 30, // Meaning span is 0 to 65
      color: '#EF4444',
    },
    {
      value: 40, // span 65 to 80
      color: '#F59E0B',
    },
    {
      value: 30, // span 80 to 100
      color: '#22C55E',
    },
  ]

  const activeSectorIndex = colorData
    .map((cur, index, arr) => {
      const curMax = [...arr]
        .slice(0, index + 1)
        .reduce((a: { value: number }, b: { value: number }) => ({ value: a.value + b.value }), {
          value: 0,
        }).value
      return chartValue > curMax - cur.value && chartValue <= curMax
    })
    .findIndex((cur) => cur)
  const sumValues = colorData.map((cur: any) => cur.value).reduce((a, b) => a + b)
  const arrowData = [{ value: chartValue }, { value: 0 }, { value: sumValues - chartValue }]

  const pieProps = {
    startAngle: 180,
    endAngle: 0,
    cx: width / 2,
    cy: width / 2,
  }

  const pieRadius = {
    innerRadius: '85%',
    outerRadius: (width / 2) * 0.4,
  }

  const angle = (chartValue / sumValues) * 180 // Calculate angle for needle

  const Arrow = ({ cx, cy, outerRadius }: any) => {
    const RADIAN = Math.PI / 180
    const midAngle = angle // Set midAngle to angle calculated
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const mx = cx + (outerRadius + width * 0.03) * cos
    const my = cy + (outerRadius + width * 0.03) * sin
    return (
      <g>
        <g transform={`translate(255, 255) rotate(${360 - midAngle})`}>
          <path
            d='M5.60469 9.37139C2.82684 9.54267 0.429368 7.66264 0.276978 5.19354C0.124588 2.72445 2.27269 0.564139 5.05054 0.392861L63.1551 1.279L5.60469 9.37139Z'
            fill='#2E2E2E'
          />
        </g>
      </g>
    )
  }

  return (
    <PieChart width={width} height={width / 2 + 30}>
      <text x={375} y={250} textAnchor='middle' dominantBaseline='middle'>
        100
      </text>
      <text x={140} y={250} textAnchor='middle' dominantBaseline='middle'>
        0
      </text>

      {/* Label at 30 */}
      <text x={180} y={165} textAnchor='middle' dominantBaseline='middle'>
        30
      </text>
      {/* Label at 70 */}
      <text x={330} y={165} textAnchor='middle' dominantBaseline='middle'>
        70
      </text>

      <Pie
        dataKey='value'
        activeIndex={activeSectorIndex}
        innerRadius='65%'
        data={colorData}
        blendStroke
        fill='#8884d8'
        {...pieProps}
      >
        {colorData.map((entry: any, index: any) => (
          <Cell key={`cell-${index}`} fill={colorData[index].color} />
        ))}
      </Pie>
      <Pie
        dataKey='value'
        stroke='none'
        activeIndex={1}
        activeShape={Arrow}
        data={arrowData}
        outerRadius={pieRadius.innerRadius}
        fill='none'
        {...pieProps}
      />
    </PieChart>
  )
}

export default GaugeChart
