import WizrFlexLayout from "./WizrFlexLayout"
import WizrText from "./WizrText"

const WizrNoAccessScreen = ():JSX.Element=> {

    return (
    <WizrFlexLayout textAlign='center' background='#FFF'>
        <WizrText type="h4">You  have no access to this action... </WizrText >
    </WizrFlexLayout>)
}
export default WizrNoAccessScreen