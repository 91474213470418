const ChatBotViewIcon = () => {
return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5881 15.6601C18.5881 17.2772 17.2772 18.5881 15.6601 18.5881H8.34011C6.72302 18.5881 5.41211 17.2772 5.41211 15.6601V8.34011C5.41211 6.72302 6.72302 5.41211 8.34011 5.41211H15.6601C17.2772 5.41211 18.5881 6.72302 18.5881 8.34011V15.6601Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.32422 14.7495L15.6592 14.7495" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
    </svg>
    

)
}
export default ChatBotViewIcon