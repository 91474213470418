import { Button, Input, Space } from "antd"
import WizrInput from "../components/WizrInput"
import WizrText from "../components/WizrText"
import WizrToggleButton from "../components/WizrToggleButton"
import WizrUploadContainer from "../components/WizrUploadButton"
import { WizrAzurOrchestratorInstance } from "../utils/wizrAzureConnector"
import { getFileType } from "../utils"
import { useState } from "react"
import WizrDropDown from "../components/WizrDropDown"
import InputDropDown from "../components/DropDownInput"
import WizrFlexLayout from "../components/WizrFlexLayout"
import WizrTextArea from "../components/WizrTextArea"
import WizrTagInput from "../components/WizrTagInput"
// import { Loi, ageColumn, duplicateField, interviewStartTime, interviewStatus, maxAge, minAge, straightlinerSets } from "../utils/dataStorage"
const LeftComponet = ({ updateFields, inputValue, parameters, updateMessageParams, setInputValue, doUpdateFields, uploadFile, type }: any): JSX.Element => {

  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item,
        value: item,
      }))
    return result
  }
  let excludedNames: string[] = [];
  let height;
  switch(type){
    case "general":
        excludedNames = ["Type","Format","immigration center",]
        height='230px'
        break;
        case "company":
            excludedNames = ["US Company name","Abroad company name","Petitioner details",]
            height='230px'

            break;
            case "employee":
                excludedNames = ["Us role","abroad role","Employee details","Position","Employee"]
                height='380px'

                break;
    default :
         break;
}

  return (
    <WizrFlexLayout background="white" textAlign="left" style={{ gap: "5px", minHeight: height }}>

      {parameters && parameters?.map((input: any, key: number) => {

        if (excludedNames && excludedNames.includes(input.name)) {
          switch (input.type) {
            case 1:
              return (
                <>
                  <WizrText type='body2'>{input.name === "Employee" ? "Employee Name" : input.name === "Position" ? "Employee Position" : input.name === "Format" ? "Job duties list format" : input.name}</WizrText>
                  <WizrInput
                    background='white'
                    key={key}
                    placeholder="Parameter Value"
                    style={{ maxHeight: '25px' }}
                    value={inputValue[input.name]}
                    onChange={(value: any) => {
                      updateMessageParams(input.name, value)
                      setInputValue({ ...inputValue, [input.name]: value });
                      doUpdateFields(input.name, value)
                    }}
                  />
                </>
              )
            case 2:
              return (
                <>
                  <WizrText type='body2'>{input.name === "Employee" ? "Employee Name" : input.name === "Position" ? "Employee Position" : input.name === "Format" ? "Job duties list format" : input.name}</WizrText>
                  <WizrTextArea
                    onChange={(value: any) => {
                      updateMessageParams(input.name, value);
                      setInputValue({ ...inputValue, [input.name]: value })
                      doUpdateFields(input.name, value)
                    }}
                    placeholder='Enter text'
                    minRows={2}
                    value={inputValue[input.name]}
                    background="white"
                  />
                </>
              )
            case 4:
              return (

                <>
                  <WizrText type='body2'>{input.name === "Employee" ? "Employee Name" : input.name === "Position" ? "Employee Position" : input.name === "Format" ? "Job duties list format" : input.name}</WizrText>
                  <InputDropDown list={input?.modalTags} updateFields={updateFields} keyToSet={`${input.name}`} inputValue={inputValue} />

                </>
              )
            case 5:
              return (
                <>
                  <WizrText type='body2'>{input.name === "Employee" ? "Employee Name" : input.name === "Position" ? "Employee Position" : input.name === "Format" ? "Job duties list format" : input.name}</WizrText>
                  <WizrTagInput
                    placeHolder='Select multiple option'
                    options={dropDownConverter(input?.modalTags)}
                    onChange={(value: any) => {
                      updateMessageParams(input.name, value);
                      setInputValue({ ...inputValue, [input.name]: value })
                      doUpdateFields(input.name, value);
                    }}
                  ></WizrTagInput>
                </>
              )
            case 3:
              return (
                <>
                  <WizrText type='body2'>{input.name}</WizrText>
                  <Input
                    //onChange={(value: any) => updateMessageParams(item.name, value)}
                    placeholder='Upload File'
                    value={inputValue[input.name]}
                    suffix={
                      <Space>
                        {inputValue[input.name] && ( // Only display clear icon if there's a value
                          <Button shape='round' onClick={(e: any) => {
                            uploadFile(input.name, '')
                            setInputValue({ ...inputValue, [input.name]: null })
                            doUpdateFields(input.name, '')
                          }}>
                            remove file
                          </Button>
                        )}
                        <WizrUploadContainer
                          onChange={(e: any) => {
                            uploadFile(input.name, e)
                            setInputValue({ ...inputValue, [input.name]: e?.file?.name })
                            doUpdateFields(input.name, e?.file?.name)
                          }}
                        >
                          <Button shape='round' >
                            Upload
                          </Button>
                        </WizrUploadContainer>
                      </Space>
                    }
                  />
                </>
              )
            case 6:
              return (
                // <WizrFlexLayout alignItems='start' background='#FFF' overFlow='hidden' style={{  width: '100%' }} >
                <>
                  <WizrText type='body2'>{input.name}</WizrText>
                  <Input
                    placeholder='Upload image'
                    value={inputValue[input.name]}
                    suffix={
                      <Space>
                        {inputValue[input.name] && (
                          <Button shape='round' onClick={(e: any) => {
                            uploadFile(input.name, '')
                            setInputValue({ ...inputValue, [input.name]: null })
                          }}>
                            remove file
                          </Button>
                        )}
                        <WizrUploadContainer
                          onChange={(e: any) => {
                            uploadFile(input.name, e)
                            setInputValue({ ...inputValue, [input.name]: e?.file?.name })
                          }}
                        >
                          <Button shape='round' >
                            Upload
                          </Button>
                        </WizrUploadContainer>
                      </Space>
                    }
                  />

                  {/* </WizrFlexLayout> */}
                </>
              )
          }
        }
      })}
    </WizrFlexLayout>)
}

const EnterpriseContentLeftComponent = ({ updateFields, inputValue, parameters, updateMessageParams, setInputValue, doUpdateFields, uploadFile, userquery, setUserquery, mapValueToUserQuery }: any): JSX.Element => {
  return (
    <WizrFlexLayout className="wizr-layout-wrapper" background="white" textAlign="left" overFlow="auto" style={{ gap: "15px", height: "400px", }}>

      <WizrText type='sub2'>General</WizrText>
      <LeftComponet updateFields={updateFields} inputValue={inputValue} parameters={parameters} updateMessageParams={updateMessageParams} setInputValue={setInputValue} doUpdateFields={doUpdateFields} uploadFile={uploadFile} type="general" />

      <WizrText type='sub2'>Company Details</WizrText>
      <LeftComponet updateFields={updateFields} inputValue={inputValue} parameters={parameters} updateMessageParams={updateMessageParams} setInputValue={setInputValue} doUpdateFields={doUpdateFields} uploadFile={uploadFile} type="company" />

      <WizrText type='sub2'>Employee Details</WizrText>
      <LeftComponet updateFields={updateFields} inputValue={inputValue} parameters={parameters} updateMessageParams={updateMessageParams} setInputValue={setInputValue} doUpdateFields={doUpdateFields} uploadFile={uploadFile} type="employee" />

      <WizrText type='body2'>Additional Instructions</WizrText>
      <WizrInput
        background="white"
        placeholder="Additional Instructions"
        style={{ maxHeight: '25px' }}
        value={userquery}
        onChange={(value: any) => {
          setUserquery(value)
          mapValueToUserQuery(value)
        }}
      />

    </WizrFlexLayout>
  )
}
export default EnterpriseContentLeftComponent  