import { GroupOutlined } from "@ant-design/icons";
import NodeContainer from "./NodeContainer";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";
import WizrFlexLayout from "../../../components/WizrFlexLayout";
import WizrText from "../../../components/WizrText";
import WizrDropDown from "../../../components/WizrDropDown";
import { useVisionModelSelector } from "../../../redux/selectors/content";
import WizrInput from "../../../components/WizrInput";
import WizrFlexSpacing from "../../../components/WizrFlexSpacing";
import WizrTag from "../../../components/WizrTags";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import WizrButton from "../../../components/WizrButton";
import WizrToggleButton from "../../../components/WizrToggleButton";
import { useEdges, useNodes } from "reactflow";
import { generateNodeSequenceResponse, getTenantId } from "../../../utils";
import { dispatcVisionMModels, dispatchVoiceModels } from "../../../redux/actions/content";

const VisionContainer = styled(WizrFlexLayout)`
  gap: 16px;
`
const StyledFlexSpacing = styled(WizrFlexSpacing)`
  .ant-space-item {
    display: flex !important;
    width: 100% !important;
    flex-wrap: wrap;
  }
`
const StyledWizrTag = styled(WizrTag)`
  height: 42px;
  padding: 8px 11px;
  justify-content: space-between;
`

const VoiceBlock = (props: any):JSX.Element=>{
    const { id, dataCode, updateNodeData, data, onEdit, onCopyNodeDetail, isDisabled } = props
    const [isEditView, setIsEditView] = useState(false)
    const [selectedModel, setSelectedModel] = useState<any>("")
    const [selectedModelName, setSelectedModelName] = useState<any>("")
    const [selectedLanguage, setSelectedLanguage] = useState<any>("")
    const [userQuery, setUserQuery] = useState<any>("")
    const [parameterList, setParameterList] = useState<any>()
    const [token, setToken] = useState<any>("")
    const [Diarize,setDiarize] = useState<any>(true)
    const [Punctuate,setPunctuate] = useState<any>(true)
    const [Utterance,setUtterance] = useState<any>(true)
    const [Summarize,setSummarize] = useState<any>(true)
    const [Topics,setTopics] = useState<any>(true)
    const [radio, setRadio] = useState<any>()
    const [modelSetting, setModelSetting] = useState<any>([])

    const voiceModelData = useVisionModelSelector()    
    
    const { register, control, setValue, getValues } = useForm({
        defaultValues: {
          voiceBlock: [{ file: "" }]
        }
      });
      const {
        fields,
        append,
        remove,
      } = useFieldArray({
        control,
        name: "voiceBlock"
      });

      const edges = useEdges()
      const nodes = useNodes()
    
      const startnodedata: any = nodes && nodes[0]?.data


      useEffect(() => {
        //same api is calling to list visionmodels
        const tenantId =  getTenantId()
        dispatchVoiceModels()
      }, [])

      useEffect(() => {
        const saveAppPayload = {
          edges: edges,
          nodes: nodes,
        };
        const sequence = generateNodeSequenceResponse(saveAppPayload);
        const trimmedNodeSequence = elementsBeforeSpecificElement(sequence, id);
        const defaultParameters = startnodedata?.config?.parameters.map((param: any) => param?.name)
        setParameterList((prevList: any) => [...defaultParameters, ...trimmedNodeSequence]);
    
      }, [nodes, edges, setParameterList, id]);

      function elementsBeforeSpecificElement(arr: any, specificElement: any) {
        const index = arr.indexOf(specificElement);
        if (index === -1) {
          return arr;
        }
        const newArray = arr.slice(0, index).slice(1).map((item: any) => {
          return `${item}.output`
        })
        return newArray
    
      }

      const findModalId = (modelType: any) => {        
        const modelId = voiceModelData?.find((item: any) => item.display_name === modelType)
        return modelId?.id
      }

      const findModalName = (id: any) => {
        const modelName = voiceModelData?.find((item: any) => item.id === id)
        return modelName?.display_name
      }

      
      const drodownConverter = () => {
        const normalOptions = voiceModelData?.map((item: any) => {
          return { label: item.display_name, value: item.display_name, model_type: item.llm_type }
        })        
        return normalOptions
      }

      
      useEffect(() => {        
        setPunctuate(data?.config?.punctuate)
        setUtterance(data?.config?.Utterance)
        setSelectedLanguage(data?.config?.language)
        setSelectedModel(data?.config?.model_name)
        setDiarize(data?.config?.multiple_speakers)
        setTopics(data?.config?.topics_required)
        const newArray = data?.config?.files.map((value: any, index: any) => ({ file: value }));
        setValue("voiceBlock", newArray);
        setSelectedModelName(data?.config?.transcription_model_id)
      }, [data])
      
    const updateCurrentNodeData = () => {

        const fieldArrayFormValues = getValues().voiceBlock
        updateNodeData((nds: any) =>
          nds.map((node: any) => {
            if (node.id === id) {
              node.data = {
                ...node.data,
                config: {
                  model_name: selectedModel,
                  punctuate: Punctuate,
                  Utterance: Utterance,
                  multiple_speakers: Diarize,
                  topics_required: Topics,
                  language: selectedLanguage,
                  llm_type: 'deepgram',
                  transcription_model_id: findModalId(selectedModel),
                  files: fieldArrayFormValues?.length > 0 ? fieldArrayFormValues.map((item: any) => item.file) : [],
                },
              }
            }
            return node
          }),
        )
        setIsEditView(!isEditView)
  
        
      }

      const onDeleteNodeDetail = (e: any) => {
        updateNodeData((nds: any) =>
          nds.filter((node: any) => node.id !== e)
        );
      }


      const toggleSettings = [
        'Diarize','Punctuate','Utterance','Summarize','Topics'
      ]

      const setToggleSetting = (e:boolean,item:any)=>{
        switch (item){
            case "Diarize":
                setDiarize(e)
                setRadio({'event': e, 'state': item})
                break
            case "Punctuate":
                setPunctuate(e)
                break
            case "Utterance":
                setUtterance(e)
                break
            case "Summarize":
                setSummarize(e)
                break
            case "Topics":
                setTopics(e)
                break
        }
      }

      const languageOptions = [
        {
          label: 'English',
          value: 'en'
        },
        {
          label: 'Hindi',
          value: 'hi'
        },
      ]      

    return(
        <NodeContainer
        item={{ nodeTitle: data?.label, id: id, icon: <GroupOutlined style={{ marginRight: 10 }} /> }}
        data={dataCode}
        isEditMode={isEditView}
        saveCurrentAction={updateCurrentNodeData}
        onEditNodeDetail={() => { onEdit(data?.label, props)}}
        onDeleteNodeDetail={onDeleteNodeDetail}
        onCopyNodeDetail={() => onCopyNodeDetail(data, 'transcription')}
        isDisabled={isDisabled}
        nodeType={'9'}
      >
        <VisionContainer alignItems='flex-start'>
          {isEditView ? (
            <>
              <WizrText type='body2'>Model</WizrText>
              <WizrDropDown
                placeHolder='Select one option'
                value={selectedModel}
                options={drodownConverter()}
                onChange={(value: any) => { setSelectedModel(value) }}
                className="nodrag nopan"
              />
              <WizrText type='body2'>Languages</WizrText>
              {/* <WizrInput placeholder='user query' onChange={(text: string) => setUserQuery(text)} value={userQuery} /> */}
              <WizrDropDown
                placeHolder='Select one option'
                value={selectedLanguage}
                options={languageOptions}
                onChange={(value: any) => { setSelectedLanguage(value)}}
                className="nodrag nopan"
              />
                <WizrFlexLayout flexDirection="row" alignContent="space-between">
                    <WizrText type="body2">Diarize</WizrText>
                    <WizrToggleButton onChange={(e:boolean)=>setDiarize(e)} 
                    isEnabled={Diarize}></WizrToggleButton>
                </WizrFlexLayout>
                <WizrFlexLayout flexDirection="row" alignContent="space-between" >
                    <WizrText type="body2">Punctuate</WizrText>
                    <WizrToggleButton onChange={(e:boolean)=>setPunctuate(e)} 
                    isEnabled={Punctuate}></WizrToggleButton>
                </WizrFlexLayout>
                <WizrFlexLayout flexDirection="row" alignContent="space-between" >
                    <WizrText type="body2">Utterance</WizrText>
                    <WizrToggleButton onChange={(e:boolean)=>setUtterance(e)} 
                    isEnabled={Utterance}></WizrToggleButton>
                </WizrFlexLayout>
                <WizrFlexLayout flexDirection="row" alignContent="space-between" >
                    <WizrText type="body2">Summarize</WizrText>
                    <WizrToggleButton onChange={(e:boolean)=>setSummarize(e)} 
                    isEnabled={Summarize}></WizrToggleButton>
                </WizrFlexLayout>
                <WizrFlexLayout flexDirection="row" alignContent="space-between" >
                    <WizrText type="body2">Topics</WizrText>
                    <WizrToggleButton onChange={(e:boolean)=>setTopics(e)} 
                    isEnabled={Topics}></WizrToggleButton>
                </WizrFlexLayout>
                
             {/* //   )})} */}
              {fields.map((item, index) => {
                return (
                  <WizrFlexLayout key={item.id} >
                    <WizrText type='body3' textColor='#707276'>
                      File
                    </WizrText>
                    <WizrFlexLayout flexDirection='row' justifyContent='flex-start' alignItems='flex-start' style={{ gap: '10px', margin: '5px 0px' }}>
                      <Controller
                        render={({ field }) => <WizrInput {...field} />}
                        name={`voiceBlock.${index}.file`}
                        control={control}
                      />
                      <WizrButton type='text' icon={<DeleteIcon />} onClick={() => remove(index)} style={{ minHeight: '50px' }} />
                    </WizrFlexLayout>
                    <StyledFlexSpacing justifyContent='space-between' flexDirection='column' alignItems='center' size={20}>
                      <StyledWizrTag
                        background="grey"
                        data={parameterList}
                        onClick={(e: any) => {
                          setValue(`voiceBlock.${index}.file`, `{{${e}}}`);
                        }}
                      />
                    </StyledFlexSpacing>
                  </WizrFlexLayout>
                );
              })}
              <WizrButton type='text' text='Add files' onClick={() => append({ file: '' })} style={{ borderWidth: '2px', borderColor: 'black', borderRadius: '20px', width: '100%' }} />
  
              
              {/* <WizrButton type='text' text='Add files' onClick={() => setFileNumbers(fileNumbers + 1)} style={{ borderWidth: "2px", borderColor: "black", borderRadius: "20px", width: "100%" }}></WizrButton> */}
            </>
          ) : (
            <>
              <WizrText type='body3' textColor='#707276'>
                Model
              </WizrText>
              <WizrText type='body2' textColor='#1D1F22' >
                {findModalName(selectedModelName)}
              </WizrText>
              <WizrText type='body3' textColor='#707276'>
              Languages
              </WizrText>
              <WizrText type='body2' textColor='#1D1F22' >
                {selectedLanguage}
              </WizrText>
              {/* <WizrText type='body3' textColor='#707276'>
                Token
              </WizrText>
              <WizrText type='body2' textColor='#1D1F22' >
                {token}
              </WizrText> */}
              {fields.map((item, index) => {
                return (
                  <WizrFlexLayout key={item.id} flexDirection='row' alignContent='flex-start'>
                    <WizrText type='body3' textColor='#707276'>
                      File
                    </WizrText>
                    <WizrText type='body2' textColor='#1D1F22' >
                      {item.file}
                    </WizrText>
                  </WizrFlexLayout>
                );
              })}
            </>
          )}
        </VisionContainer>
      </NodeContainer>
    )
}

export default VoiceBlock