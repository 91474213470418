import { useEffect, useState } from 'react'
import styled from 'styled-components'
import WizrText from '../../../components/WizrText'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import { useEdges, useNodes } from 'reactflow'
import { generateNodeSequenceResponse } from '../../../utils'
import WizrButton from '../../../components/WizrButton'
import { Divider, Dropdown, MenuProps, Popover } from 'antd'
import ApiBlockEdit from './ApiBlockEdit'
import CodeBlockEdit from './CodeBlockEdit'
import VisionNodeEdit from './VisionBlockEdit'
import VoiceBlockEdit from './VoiceBlockEdit'
import PromptEdit from './PromptCreation'
import StartNodeEdit from './StartNodeEdit'
import VectorBlockEdit from './VectorBlockEdit'

const EditArea = ({ closeEditArea, editData, setNodes, setEdges,setUnSavedDataPresent }: any): JSX.Element => {

    const id = editData?.id || ''
    const nodes = useNodes()
    const edges = useEdges()
    const data: any = getNode(id)
    // const [selectedPrompt, setSelectedPrompt] = useState<any>(data?.config?.prompt_config)
    const [nodeUpdate, setNodeUpdate] = useState<any>('')

    function getNode(id: string) {
        const nodeData: any = nodes.find((node) => node.id === id)
        if (nodeData) {
            return nodeData.data
        } else {
            return {}
        }
    };

    // useEffect(() => {
    //     const saveAppPayload = {
    //         edges: edges,
    //         nodes: nodes,
    //     }
    //     setNodeSequence(generateNodeSequenceResponse(saveAppPayload))
    // }, [nodes, edges])

    // useEffect(() => {
    //     if (data?.config) {
    //         setSelectedPrompt(data?.config?.prompt_config)
    //     }
    // }, [data?.config])

    const updaDatafornodes = () => {
        setNodeUpdate(editData?.type)
        setUnSavedDataPresent(true)
        // closeEditArea()
    }

    const editAreaClose = () => {
        setNodeUpdate('')
        closeEditArea()
    }
    // const items = [
    //     {
    //         key: "1",
    //         label: "Save as template",
    //     },

    // ];
    const [promptSave, setPromptSave] = useState(false)
    const parameterList = editData?.allNodeData && editData?.data?.config?.parameters
    const onMenuClick: MenuProps["onClick"] = (e: any) => {
        // const finalPayload = {
        //     ...payload,
        //         published:true,
        //         published_on  : new Date().getTime()
        // }
        // managePrompts(finalPayload)
        setPromptSave(true)

    };


    return (
        <WizrFlexLayout>
            <Container type={false}>
                <WizrFlexLayout flexDirection='row' justifyContent='space-between' >
                    <WizrFlexLayout
                        alignItems='flex-start'
                        justifyContent='flex-start'
                        flexDirection='row'
                        background='#fff'
                    >
                        <StyledInput type="text" value={data.label} />
                        <StepContainer>
                            <WizrText type='body3'>{editData.id}</WizrText>
                        </StepContainer>
                    </WizrFlexLayout>
                    <WizrFlexLayout alignItems='flex-end' background='#fff' flexDirection='row' justifyContent='flex-end' style={{ gap: "5px" }}>
                        <WizrButton text='Cancel' onClick={closeEditArea} style={{ backgroundColor: "grey", borderRadius: '20px' }} />
                        <WizrButton text='Save' onClick={updaDatafornodes} style={{borderRadius: '20px'}}/>
                        {/* {editData?.type !== "llm" ? <WizrButton text='Save' onClick={updaDatafornodes} /> :
                            <Dropdown.Button type='primary' onClick={updaDatafornodes} menu={{ items, onClick: onMenuClick }} style={{ width: "100px" }}>
                                Save
                            </Dropdown.Button>
                        } */}
                    </WizrFlexLayout>
                </WizrFlexLayout>
                <Divider style={{ margin: '0px', borderTop: '2px solid' }} />
            </Container>
            <ContentWrapper>
                {editData?.type === 'api' && <WizrFlexLayout alignItems='flex-start' background='#fff' style={{ padding: '10px 10px' }}>
                    < ApiBlockEdit data={data} id={id} allNodeData={nodes} updateNodeData={setNodes} updateEdgeData={setEdges} nodeUpdate={nodeUpdate} closeEditArea={editAreaClose} />
                </WizrFlexLayout>}
                {editData?.type === 'code' && <WizrFlexLayout alignItems='flex-start' background='#fff' style={{ padding: '10px 10px' }}>
                    < CodeBlockEdit data={data} id={id} allNodeData={nodes} updateNodeData={setNodes} updateEdgeData={setEdges} nodeUpdate={nodeUpdate} closeEditArea={editAreaClose} />
                </WizrFlexLayout>}
                {editData?.type === 'vision' && <WizrFlexLayout alignItems='flex-start' background='#fff' style={{ padding: '10px 10px' }}>
                    < VisionNodeEdit data={data} id={id} allNodeData={nodes} updateNodeData={setNodes} updateEdgeData={setEdges} nodeUpdate={nodeUpdate} closeEditArea={editAreaClose} />
                </WizrFlexLayout>}
                {editData?.type === 'transcription' && <WizrFlexLayout alignItems='flex-start' background='#fff' style={{ padding: '10px 10px' }}>
                    < VoiceBlockEdit data={data} id={id} allNodeData={nodes} updateNodeData={setNodes} updateEdgeData={setEdges} nodeUpdate={nodeUpdate} closeEditArea={editAreaClose} />
                </WizrFlexLayout>}
                {editData?.type === 'llm' && <WizrFlexLayout alignItems='flex-start' background='#fff' style={{ padding: '10px 10px' }}>
                    < PromptEdit allNodeData={nodes} updateNodeData={setNodes} id={id} updateEdgeData={setEdges} nodeUpdate={nodeUpdate} closeEditArea={editAreaClose} editData={editData} promptSave={promptSave} setPromptSave={setPromptSave} />
                </WizrFlexLayout>}
                {editData?.type === 'start' && <WizrFlexLayout alignItems='flex-start' background='#fff' style={{ padding: '10px 10px' }}>
                    <StartNodeEdit parameters={parameterList} id={id} updateNodeData={setNodes} nodeUpdate={nodeUpdate} closeEditArea={editAreaClose} />
                </WizrFlexLayout>}
                {editData?.type === 'vector' && <WizrFlexLayout alignItems='flex-start' background='#fff' style={{ padding: '10px 10px' }}>
                    <VectorBlockEdit data={data} id={id} allNodeData={nodes} updateNodeData={setNodes} updateEdgeData={setEdges} nodeUpdate={nodeUpdate} closeEditArea={editAreaClose} />
                </WizrFlexLayout>}
            </ContentWrapper>


        </WizrFlexLayout>
    )
}

const IndividualSettingsBox = styled.div`
  width: 100%;
  text-align: left;
  margin: 10px 0px 0px 0px;
  overflow: hidden;
`

const Container = styled(WizrFlexLayout) <{ type: boolean }>`
  border: 2px solid var(--generic-white, #fff);
  background: var(--grayscale-100, #fff);
  padding: 10px;
  gap: 16px;;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  position: fixed;
  width: -webkit-fill-available
`

const ContentWrapper = styled.div`
  margin-top: 80px; /* Adjust the margin to match the height of your fixed container */
  overflow-y: auto;
  height: calc(100vh - 80px); /* Adjust the height based on your design */
`

const StepContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 0px 10px;
  margin-left: 10px;
  background: #ddd;
`

const StyledInput = styled.input`
width:200px;
  padding: 0;
  background: #fff;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: var(--grayscale-800, #1D1F22);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.1px;

  &::placeholder {
    /* Apply styles to the placeholder text */
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: /* Your desired placeholder text color */;
  }
`;

export default EditArea
