import { useLocation, useNavigate } from 'react-router-dom'
import Analytics from '../../../assets/icons/AnalyticsIcon'
import Hamburger from '../../../assets/icons/Hamburger'
import WizrButton from '../../../components/WizrButton'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrText from '../../../components/WizrText'
import WizrUserJourney from '../../../components/WizrUserJourney'
import CodeEditor from '@uiw/react-textarea-code-editor'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Input, Spin, message } from 'antd'
import Copy from '../../../assets/icons/Copy'
import copy from 'copy-to-clipboard'
import {
  useContentLoadingSelector,
  useFeatureSettingSelector,
  useendPointDataSelector,
} from '../../../redux/selectors/content'
import { dispatchCurrentModule, dispatchFeatureSettings, dispatchGetEndPointUrl, dispatchupdateUserView } from '../../../redux/actions/content'

const StyledInput = styled(Input)`
  width: 90%;
  alignitems: center;
  height: 52px;
  .ant-input-group-addon {
    background: #dcdee2;
  }
`
const StyledTextButton = styled(WizrText)`
  /* Apply underline style and default cursor */
  text-decoration: underline !important;
  cursor: pointer !important;;
`;
const EndPoint = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const currentFeatureData = location?.state?.details
  const inputRef = useRef<any>(null)
  const featureData = useFeatureSettingSelector()
const endpoint = useendPointDataSelector()
const subscriptionkey = process.env.REACT_APP_API_WEBHOOK_AUTH_KEY
  const copyUrl = () => {
    message.success('Copied to clipboard', 2)
    copy(endpoint?.end_point_url)
  }
  const loading = useContentLoadingSelector()

  

  useEffect(() => {
    dispatchFeatureSettings()
  }, [])

  useEffect(() => {
    const payload = {
      tenant_feature_id: featureData?.id,
      app_feature_id: currentFeatureData?.id,
      end_point_url: currentFeatureData?.endpoint_url,
    }
    if (featureData?.id) {
      dispatchGetEndPointUrl(payload)
    }
  }, [featureData?.id])

  const goToSettings = () => {
    navigate('/settings')
    dispatchupdateUserView({
      activeKey: 'accounts',
    })
    dispatchCurrentModule('settings')

  }
  return (
    <WizrFlexLayout>
      <WizrFlexLayout justifyContent='space-between' flexDirection='row' flex={1.4}>
        <WizrUserJourney
          userJourney={[
            {
              title: (
                <WizrButton
                  padding='0px'
                  type='text'
                  text={
                    <WizrText type='h6' textColor='#707276'>
                      Cx Settings
                    </WizrText>
                  }
                  onClick={() => navigate(-1)}
                  icon={<Hamburger />}
                />
              ),
            },
            {
              title: (
                <WizrButton
                  padding='0px'
                  type='text'
                  text={
                    <WizrText type='h6'>{`${currentFeatureData?.setting_name} - Settings`}</WizrText>
                  }
                  onClick={() => navigate(-1)}
                />
              ),
              isCurrent: true,
            },
            {
              title: <WizrText type='h6'>Generate end point URL & Payload </WizrText>,
              isCurrent: true,
            },
          ]}
        />
        <WizrButton
          type='text'
          icon={<Analytics />}
          style={{ marginTop: '20px', borderRadius: '16px', backgroundColor: '#EAECF1' }}
          text={<WizrText textColor='black' type='button'>{`App Analytics`}</WizrText>}
          onClick={() => {}}
        >
          {' '}
        </WizrButton>
      </WizrFlexLayout>
      <WizrFlexLayout
        justifyContent='flex-start'
        textAlign='left'
        alignContent='flex-start'
        alignItems='flex-start'
        flex={11}
      >
        <WizrFlexLayout
          background='white'
          textAlign='left'
          style={{ padding: '20px 60px', margin: '20px 0px', borderRadius: '8px', gap: '12px' }}
          justifyContent='flex-start'
          alignContent='flex-start'
          alignItems='flex-start'
        >
          <WizrText type='body2' textColor='#707276'>
            End point URL
          </WizrText>
          <StyledInput
            addonBefore={<WizrText type='sub1'>GET</WizrText>}
            value={endpoint?.end_point_url}
            suffix={
              <WizrButton
                type='link'
                icon={loading ? <Spin /> : <Copy />}
                onClick={() => copyUrl()}
              ></WizrButton>
            }
          ></StyledInput>
          <WizrText type='body2' textColor='#707276'>
            Familiarize yourself with our API documentation available below. This documentation
            provides detailed information on authentication, endpoints, and making requests.
          </WizrText>
          <div>
            <WizrText type='body2'> API Key</WizrText>
          </div>
          <WizrText type='body2' textColor='#5D5F62'>
            {' '}
            To authenticate your requests, include your API key in the headers of your HTTP
            requests. You can find your API key in the admin portal.
          </WizrText>
          <WizrText type='body2' textColor='#5D5F62'>
            Include the following headers in your requests:
          </WizrText>
          <ul>
            <li>
              <WizrText type='body2' textColor='#5D5F62'>
                {' '}
                Content-Type: Set to application/json when sending data in the request body.
              </WizrText>
            </li>
            <li>
              <WizrText type='body2' textColor='#5D5F62'>
                {' '}
                Authorization: You can find the TenantId in your account settings. Click  <StyledTextButton type='link' onClick={()=>{goToSettings()}}> here </StyledTextButton>  to get tenant id.
                <br></br>
                wizr-auth-key     : {subscriptionkey}

              </WizrText>
            </li>
          </ul>
          <WizrText type='body2' textColor='#5D5F62'>
            Payload{' '}
          </WizrText>
          <CodeEditor
            ref={inputRef}
            value={JSON.stringify(endpoint?.payload, null, 2)}
            style={{
              padding:"0px",
              fontSize: 15,
              width: '90%',
              minHeight: '500px',
              backgroundColor: '#1D1F22',
              color: '#999B9F',
              borderRadius: '20px',
            }}
          />
        </WizrFlexLayout>
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}
export default EndPoint
