import { useLocation, useNavigate } from 'react-router-dom'
import WizrAccesTable from '../../../components/WizrAccessTable.tsx/WizrAccessTableEdit'
import WizrButton from '../../../components/WizrButton'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrText from '../../../components/WizrText'
import WizrUserJourney from '../../../components/WizrUserJourney'
import WizrInput from '../../../components/WizrInput'
import { useEffect, useState } from 'react'
import {
  dispatchCreateRole,
  dispatchModels,
  dispatchProuctsList,
  dispatchRolesList,
  dispatchUpdateProduct,
  dispatchUpdateRole,
  dispatchupdateUserView,
} from '../../../redux/actions/content'
import styled from 'styled-components'
import MoreIcon from '../../../assets/icons/More'
import { useLLMModelSelector } from '../../../redux/selectors/content'
import WizrTagInput from '../../../components/WizrTagInput'
import { Popover, Select } from 'antd'
import { set } from 'react-hook-form'
import EditIcon from '../../../assets/icons/EditIcon'
import WizrAccessTableView from '../../../components/WizrAccessTable.tsx/WizrAccessTableView'

const ProductPopOver = ({ onEditAction }: any): JSX.Element => {
  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      <WizrButton
        icon={<EditIcon />}
        text='Edit'
        type='text'
        onClick={onEditAction}
        justifyContent='flex-start'
      />
      {/* <WizrButton
          icon={<DeleteIcon />}
          text='Delete'
          type='text'
          onClick={onDeleteAction}
          justifyContent='flex-start'
        /> */}
    </WizrFlexLayout>
  )
}

const UpdateProduct = () => {
  const location = useLocation()
  const productDetails = location?.state?.product
  const mode = location?.state?.view
  const navigate = useNavigate()
  const [productInputState, setProductInputState] = useState(productDetails)
  const [viewMode, setViewMode] = useState(mode)
  const [productName, setProductName] = useState(
    productDetails?.product_name ? productDetails?.product_name : '',
  )
  const [selectedItems, setSelectedItems] = useState<string[]>(
    productDetails?.access_privileges ? productDetails?.access_privileges : [],
  )
  const llmModels = useLLMModelSelector()

  useEffect(() => {
    dispatchModels()
  }, [])

  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item?.display_name,
        value: item?.id,
      }))
    return result || []
  }

  const doUpdateFields = (name: any, value: any) => {
    setProductInputState({
      ...productInputState,
      [name]: value,
    })
  }

  const onEditAction = () => {
    setViewMode(false)
  }

  const updateProduct = async() => {
    const { llm_model_details, ...payload } = productInputState
    const finalPayload = { ...payload, access_privileges: selectedItems, product_name: productName }

    await dispatchUpdateProduct(finalPayload)
    await dispatchProuctsList()
    navigate(-1)
    dispatchupdateUserView({
      activeKey: 'products',
    })
  }

  const handleChange = (value: any) => {
    doUpdateFields('product_llm_models', value)
  }

  return (
    <WizrFlexLayout background='white'>
      <WizrFlexLayout background='white' flex={2}>
        <WizrUserJourney
          userJourney={[
            {
              title: (
                <WizrButton
                  type='text'
                  text={
                    <WizrText type='h6' textColor='#707276'>
                      Settings
                    </WizrText>
                  }
                  onClick={() => navigate(-1)}
                />
              ),
              isCurrent: true,
            },

            {
              title: (
                <WizrButton
                  type='text'
                  text={
                    <WizrText type='h6' textColor='#1D1F22'>
                      Update Product
                    </WizrText>
                  }
                />
              ),
              isCurrent: true,
            },
          ]}
        />
      </WizrFlexLayout>
      <WizrFlexLayout
        background='white'
        flex={10}
        style={{ borderRadius: '8px', gap: '10px', padding: '20px' }}
        textAlign='left'
      >
        {/* <WizrFlexLayout  background="white" style={{width:'30%',minHeight:'100px',maxHeight:'150px',gap:'10px'}} textAlign="left"> */}
        <WizrFlexLayout flexDirection='row' style={{ minHeight: '30px' }} background='white'>
          <WizrText type='sub2' textColor='#1D1F22'>
            Update Product
          </WizrText>
          {viewMode ? (
            <Popover
              trigger='click'
              zIndex={1}
              content={<ProductPopOver onEditAction={onEditAction} />}
            >
              <StyledButton icon={<MoreIcon />} text='' type='text' />
            </Popover>
          ) : (
            <></>
          )}
          {/* <StyledMoreButton icon={<MoreIcon />} text='' type='text' onChange={()=>{setViewMode(false)}}/> */}
        </WizrFlexLayout>
        <WizrFlexLayout
          flexDirection='row'
          style={{ minHeight: '70px', gap: '20px' }}
          background='white'
        >
          <WizrFlexLayout textAlign='left' background='white'>
            <WizrText type='body2' textColor='#707276'>
              {' '}
              Name
            </WizrText>

            <WizrInput
              value={productName}
              background={'#F2F4F9'}
              style={{ height: '50px' }}
              onChange={(value: any) => {
                setProductName(value)
              }}
            />
          </WizrFlexLayout>
          <WizrFlexLayout textAlign='left' background='white'>
            <WizrText type='body2' textColor='#707276'>
              Ai Models
            </WizrText>

            <StyledWizrTagInput
              mode='multiple'
              options={dropDownConverter(llmModels)}
              defaultValue={productDetails?.product_llm_models}
              //   onChange={(value: any) => {
              //     console.log('value', value)
              //     doUpdateFields('product_llm_models', value)
              //   }}
              onChange={handleChange}
              maxTagCount={'responsive'}
            ></StyledWizrTagInput>
          </WizrFlexLayout>
          <WizrFlexLayout textAlign='left' background='white'>
            <WizrText type='body2' textColor='#707276'>
              Number Of Calls
            </WizrText>

            <WizrInput
              value={productInputState?.calls_count}
              background={'#F2F4F9'}
              style={{ height: '50px' }}
              onChange={(value: any) => {
                doUpdateFields('calls_count', value)
              }}
            />
          </WizrFlexLayout>
        </WizrFlexLayout>

        {viewMode ? (
          <WizrAccessTableView setSelectedItems={setSelectedItems} selectedItems={selectedItems} />
        ) : (
          <WizrAccesTable setSelectedItems={setSelectedItems} selectedItems={selectedItems} />
        )}
      </WizrFlexLayout>
      <WizrFlexLayout alignItems='flex-end' style={{ padding: '8px 0 0 0' }} background='white'>
        <StyledButton
          text={
            <WizrText type='body2' textColor='#FFFFFF'>
              Update
            </WizrText>
          }
          onClick={() => {
            updateProduct()
          }}
        />
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}
export default UpdateProduct

const StyledButton = styled(WizrButton)`
  border-radius: 20px;
`

const StyledMoreButton = styled(WizrButton)`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding-right: 8px;
  justify-content: center;
  align-items: center;
  /* Default styles */
  path {
    stroke: #999b9f; /* Default stroke color */
    transition: stroke 0.3s ease; /* Smooth transition for color change */
  }

  /* Hover styles */
  &:hover path {
    stroke: #1d1f22;
  }
`

const StyledWizrTagInput = styled(Select)`
  /* Add your custom styles here */
  .ant-select-selection-placeholder {
    text-align: left;
  }
  width: 100%;
  .ant-select-selector {
    min-height: 50px;
    background: #f2f4f9 !important;
    border-radius: 16px !important;
    padding-left: 20px !important;
  }
  .ant-select-selection-item {
    background: white !important;
    border-radius: 20px !important;
    border: 1px solid black !important;
    gap: 8px !important;
    padding-right: 8px !important;
  }
  .ant-select-selection-overflow {
    gap: 8px !important;
  }
  overflow: auto; /* Hide any overflow content */
`
