const PlayIcon = (props: any) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={11} height={14} fill='none' {...props}>
    <path
      stroke='#1D1F22'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M3.715 1.367 9.4 5.664a1.412 1.412 0 0 1 0 2.3l-5.69 4.7A1.676 1.676 0 0 1 1 11.517v-9a1.676 1.676 0 0 1 2.715-1.15Z'
      clipRule='evenodd'
    />
  </svg>
)
export default PlayIcon
