import { Popover } from 'antd'
import WizrButton from '../WizrButton'
import MoreIcon from '../../assets/icons/More'
import EditIcon from '../../assets/icons/EditIcon'
import { dispatchSaveCurrentAppDetails, dispatchDeleteApp, dispatchEnableDisableUser, dispatchListUsers, dispatchDeleteUser, dispatchUpdateProduct } from '../../redux/actions/content'
import Copy from '../../assets/icons/Copy'
import { useNavigate } from 'react-router-dom'
import WizrFlexLayout from '../../stories/WizrFlexLayout/WizrFlexLayout'
import DeleteIcon from '../../assets/icons/DeleteIcon'
import { act } from 'react-dom/test-utils'
import { useCurrentAppDetailsSelector } from '../../redux/selectors/content'
import View from '../../assets/icons/View'
import Disable from '../../assets/icons/Disable'
import Reset from '../../assets/icons/Reset'
import { MODAL_TYPES, useWizrModalContext } from '../../providers/modal/WizrModalProvider'
import styled from 'styled-components'
import ViewIcon from '../../assets/icons/ViewIcon'
import { useAuth } from '../../authContext'



const ProductActionPopOverContent = ({product}:any): JSX.Element => {
  
  const { showModal, hideModal } = useWizrModalContext()
  const { userPermission, hasAcces } = useAuth();

  const navigate = useNavigate()
  const onEditAction = ()=>{
    // showModal(MODAL_TYPES.UPDATE_PRODUCTS,{
    //   productDetails:product,
    //   onCancel: hideModal,
    // })
    navigate('/updateproduct', { state:  {product:product,view:false}  })
  }

  const onViewAction = ()=>{
    navigate('/updateproduct', { state: {product: product,view:true } })

  }
  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      
    <WizrButton
        icon={<ViewIcon />}
        text='View'
        type='text'
        onClick={()=>{onViewAction()}}
        justifyContent='flex-start'
        disabled={hasAcces('editproduct_settings') || hasAcces('view_product')=== true ? false : true}

      />
       <WizrButton
        icon={<EditIcon />}
        text='Edit'
        type='text'
        onClick={()=>{onEditAction()}}
        justifyContent='flex-start'
        disabled={hasAcces('editproduct_settings')  === true ? false : true}

      />
    </WizrFlexLayout>
  )
}

const ProductActionPopOver = (productDetails:any): JSX.Element => {
  const navigate = useNavigate()
const product = productDetails?.productDetails
 

  return (
    <Popover
      content={
        <ProductActionPopOverContent
        product={product}
        />
      }
      trigger='click'
    >
      <StyledButton icon={<MoreIcon />} text='' type='text' style={{height: '40px', width: '40px', borderRadius: '50px'}}/>
    </Popover>
  )
}

export default ProductActionPopOver

const StyledButton = styled(WizrButton)`
border-radius: 50%;
width: 20px;
height: 20px;
padding-right: 8px;
justify-content: center;
align-items: center;
  /* Default styles */
  path {
    stroke: #999B9F; /* Default stroke color */
    transition: stroke 0.3s ease; /* Smooth transition for color change */
  }

  /* Hover styles */
  &:hover path {
    stroke: #1D1F22
  }
`;
