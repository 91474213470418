const GmailIcon = ()=>{
    return(
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_12639_43037)">
<path d="M5.45456 21.0679V11.7293L2.57878 9.07971L0 7.60938V19.4199C0 20.3318 0.733594 21.0679 1.63641 21.0679H5.45456Z" fill="#4285F4"/>
<path d="M18.5454 21.0679H22.3636C23.2691 21.0679 24 20.329 24 19.4198V7.60938L21.0791 9.29347L18.5454 11.7292V21.0679Z" fill="#34A853"/>
<path d="M5.45479 11.7272L5.06348 8.07828L5.45479 4.58594L12.0002 9.52992L18.5457 4.58594L18.9834 7.88973L18.5457 11.7272L12.0002 16.6711L5.45479 11.7272Z" fill="#EA4335"/>
<path d="M18.5454 4.58622V11.7274L24 7.60752V5.41018C24 3.37222 21.69 2.21043 20.0728 3.43265L18.5454 4.58622Z" fill="#FBBC04"/>
<path d="M0 7.60756L2.50866 9.50248L5.45456 11.7275V4.58626L3.92719 3.43269C2.30719 2.21038 0 3.37226 0 5.41013V7.60756Z" fill="#C5221F"/>
</g>
<defs>
<clipPath id="clip0_12639_43037">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

    )
}

export default GmailIcon