import React, { useEffect, useRef, useState } from "react"
import Chat from "../components/WizrChat"
import WizrFlexLayout from "../components/WizrFlexLayout"
import WizrText from "../components/WizrText"
import { useChatMessageSelector, useContentLoadingSelector, useCurrentAppDetailsSelector, useFileParams, useenduserresult } from "../redux/selectors/content"
import AppShare from "./AppShare"
import { dispatchAppDetails, dispatchAppToolTest, dispatchFileUploadMultiplePaths, dispatchUploadFileUsingLink, dispatchenduserapi } from "../redux/actions/content"
// import SingleRunContainerLeft from "./components/BulkRunPages.tsx/SingleRunContainerLeft"
import styled from 'styled-components'
// import { stateToHTML } from 'draft-js-export-html';


import { EditorState, ContentState, convertFromHTML } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import WizrUploadContainer from "../components/WizrUploadButton"
import WizrButton from "../components/WizrButton"
import { Button, Input, Space, Spin, message } from "antd"
import WizrTagInput from "../components/WizrTagInput"
import WizrTextArea from "../components/WizrTextArea"
import WizrInput from "../components/WizrInput"
import { getFileType, statusChecker } from "../utils"
import { WizrAzurOrchestratorInstance } from "../utils/wizrAzureConnector"
import WizrDropDown from "../components/WizrDropDown"
import HistoryIcon from "../assets/icons/History"
import { useLocation, useNavigate } from "react-router"
import TestArea from "./components/AppDesignerTool.tsx/TestArea"
import { testAppTool } from "../api/helpers/contents"
import InputDropDown from "../components/DropDownInput"

import showdown from 'showdown';
import MarkdownIt from 'markdown-it';
import Markdown from "react-markdown"
import Copy from "../assets/icons/Copy"
import copy from 'copy-to-clipboard';
import EnterpriseContentLeftComponent from "./EnterpriseContentLeft"
import { ArrowLeftOutlined } from "@ant-design/icons"

import Editor from "../components/ckEditor"


// eslint-disable-next-line @typescript-eslint/no-var-requires
const markdown = require('markdown-it')();

// const StyledWizrEditor = styled(Editor)`

// background: #FFF;
// .demo-editor rdw-editor-main {
//   border: 10px solid 
// }
// `
const StyledWizrButton = styled(WizrButton)`
border-radius: 20px;
border: 1px solid var(--grayscale-800, #1D1F22);
background: var(--generic-white, #FFF);
margin-right:10px
`

const StyledWizrEditor = styled.div`
  // height: -webkit-fill-available;
  // border: 1px solid #ccc; /* Apply border to the wrapper div
  .rdw-editor-main {
    // border: 1px solid #ccc !important;
  }
`;
const StyledHeaderWizrButton = styled(WizrButton)`
border-radius: 20px;
background: var(--grayscale-200, #EAECF1);`
const SingleRunContainerLeft = ({ parameters, doUpdateFields, saveFileParams, saveFilesData, onChatClick, appDetails ,mapValueToUserQuery,currentApp }: any): JSX.Element => {

  const [inputValue, setInputValue] = useState<any>({})
  //  let inputValue: any[]= []

  const [messageParams, setMessageParams] = useState<any>({ '{{user_query}}': '' })
  const [fileParams, setFfileParams] = useState<any>([])
  const [filesData, setFilesData] = useState<any>([])
  const [userquery, setUserquery] = useState<any>("")
const loading = useContentLoadingSelector()

  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item,
        value: item,
      }))
    return result
  }

  const updateMessageParams = (name: string, value: any) => {
    const names = `{{${name}}}`
    const finalValue = value?.length > 0 ? value.toString() : value
    setMessageParams((prevInputValue: any) => ({ ...prevInputValue, [names]: finalValue }))
    // setInputValue((prevInputValue: any) => ({ ...prevInputValue, [input]: value }));
  }

  const uploadFile = (name: any, fileInfo: any) => {
    if(appDetails?.app_id==="108b4c61-2068-4dc6-ae8a-71c24dc33785"){
    const appIds = [{
      id: "3110fc8b-c084-470d-b761-da4d7bc4ba70",
      app_id: "9fffac02-4355-4b11-927d-c6d8ede3f4eb"
    },
    {
      id: "c209847c-64a8-4458-b3e8-7e839e305f2b",
      app_id: "6aaa168f-28d0-4442-9834-2c248d829399"
    },
    {
      id: "c305d041-6658-424f-bdee-5b460afceacc",
      app_id: "52d2e3f2-77f5-402c-9faa-cd7b47e84d64"
    },
    {
      id: "46484768-1eb3-4e15-b258-4f1b82f214bc",
      app_id: "0aac0508-e673-40be-8dab-519860a2b320"
    }]
   // appIds?.map((item: any) => {
    //  const fileUploadPayload = {
      //  container_id: 1,
     //   path: item?.app_id,
    //    files: [
    //      `${name}`,
    //    ]
   //   }
   //   const formData = new FormData();
    //  const jsonData = JSON.stringify(fileUploadPayload);
   //   formData.append('data', jsonData);
    //  formData.append(name, fileInfo?.fileList[0]?.originFileObj);

      if (fileInfo !== '') {
        updateFileParams(name, fileInfo)
        // WizrAzurOrchestratorInstance.uploadFile('', fileInfo?.file, '', [], 'promptbox')
        dispatchFileUploadMultiplePaths(fileInfo, name, appIds)

      } else {
        const newFileParams = fileParams.filter((item: any) => item.param_name !== name)
        setFfileParams(newFileParams)
        saveFileParams(newFileParams)
      }
    //})

  }
  else if (appDetails?.app_id==="a69ea027-0c27-4d61-ab22-6639ff8b6f67"){
    const appIds = [ 
      {
      id: "dfeba802-3122-42ef-bf57-9d7f9c0ed0db",
      app_id: "a03761f2-d498-4b21-9c54-04f035dedc30"
    },
    {
    id: "b33b3290-76de-42d2-a95b-88e20d50e38a",
    app_id: "a69ea027-0c27-4d61-ab22-6639ff8b6f67"
  },
   ]
     // appIds?.map((item: any) => {
    //  const fileUploadPayload = {
      //  container_id: 1,
     //   path: item?.app_id,
    //    files: [
    //      `${name}`,
    //    ]
   //   }
   //   const formData = new FormData();
    //  const jsonData = JSON.stringify(fileUploadPayload);
   //   formData.append('data', jsonData);
    //  formData.append(name, fileInfo?.fileList[0]?.originFileObj);

    if (fileInfo !== '') {
      updateFileParams(name, fileInfo)
      // WizrAzurOrchestratorInstance.uploadFile('', fileInfo?.file, '', [], 'promptbox')
      dispatchFileUploadMultiplePaths(fileInfo, name, appIds)

    } else {
      const newFileParams = fileParams.filter((item: any) => item.param_name !== name)
      setFfileParams(newFileParams)
      saveFileParams(newFileParams)
    }
  //})


  }
  else{
    const fileUploadPayload = {
      container_id: 1,
      path: appDetails?.app_id,
      files: [
        `${name}`,
      ]
    }
    const formData = new FormData();
    const jsonData = JSON.stringify(fileUploadPayload);
    formData.append('data', jsonData);
    formData.append(name, fileInfo?.fileList[0]?.originFileObj);

    if (fileInfo !== '') {
      updateFileParams(name, fileInfo)
      // WizrAzurOrchestratorInstance.uploadFile('', fileInfo?.file, '', [], 'promptbox')
      dispatchUploadFileUsingLink(formData)

    } else {
      const newFileParams = fileParams.filter((item: any) => item.param_name !== name)
      setFfileParams(newFileParams)
      saveFileParams(newFileParams)
    }
  }

  }



  const updateFileParams = (name: any, fileInfo: any) => {
    const type = getFileType(fileInfo?.file?.name)

    const fileData = {
      param_name: name,
      file_name: fileInfo?.file?.name,
      file_location: 'promptbox',
      file_type: type,
    }

    fileParams?.map((item: any, key: any) => {
      if (item.param_name === name) {
        fileParams.splice(key, 1)
      }
    })

    setFfileParams([...fileParams, fileData])

    const fileValue = {
      param_name: name,
      file: fileInfo?.fileList[0]?.originFileObj,
    }

    filesData?.map((item: any, key: any) => {
      if (item.param_name === name) {
        filesData.splice(key, 1)
      }
    })

    setFilesData([...filesData, fileValue])
    saveFilesData([...filesData, fileValue])
  
  }
  // let testData: any = []
  const updateFields = (input: any, value: any) => {

    //input1 = [...input1, { [input]: value }];
    updateMessageParams(input, value);
    setInputValue((prevInputValue: any) => ({ ...prevInputValue, [input]: value }));
    doUpdateFields(input, value)
  }

  useEffect(() => {
  }, [loading])

  return (

    <WizrFlexLayout background="white" style={{ borderRadius: "16px", padding: "15px", }}>
      <WizrFlexLayout flex={8} background="white" alignItems='flex-start' justifyContent="flex-start" style={{ width: '90%', gap: '5px', marginLeft: "20px" }}>
        {(appDetails?.app_id!=="108b4c61-2068-4dc6-ae8a-71c24dc33785")?
        <>
      {parameters && parameters?.map((input: any, key: number) => {
          if ((input.name !== "user_query") && (input.name !== "immigration address line1") && (input.name !== "immigration address line2")) {
            switch (input.type) {
              case 1:
                return (
                  <>
                    <WizrText type='body2'>{input.name}</WizrText>
                    <WizrInput
                      background='white'
                      key={key}
                      placeholder="Parameter Value"
                      style={{ maxHeight: '25px' }}
                      value={inputValue[input.name]}
                      onChange={(value: any) => {
                        updateMessageParams(input.name, value)
                        setInputValue({ ...inputValue, [input.name]: value });
                        doUpdateFields(input.name, value)
                      }}
                    />
                  </>
                )
              case 2:
                return (
                  <>
                    <WizrText type='body2'>{input.name}</WizrText>
                    <WizrTextArea
                      onChange={(value: any) => {
                        updateMessageParams(input.name, value);
                        setInputValue({ ...inputValue, [input.name]: value })
                        doUpdateFields(input.name, value)
                      }}
                      placeholder='Enter text'
                      minRows={2}
                      value={inputValue[input.name]}
                      background="white"
                    />
                  </>
                )
              case 4:
                return (

                  <>
                    <WizrText type='body2'>{input?.name}</WizrText>
                    <InputDropDown list={input?.modalTags} updateFields={updateFields} keyToSet={`${input.name}`} inputValue={inputValue}/>
                   
                  </>
                )
              case 5:
                return (
                  <>
                    <WizrText type='body2'>{input?.name}</WizrText>
                    <WizrTagInput
                      placeHolder='Select multiple option'
                      options={dropDownConverter(input?.modalTags)}
                      onChange={(value: any) => {
                        updateMessageParams(input.name, value);
                        setInputValue({ ...inputValue, [input.name]: value })
                        doUpdateFields(input.name, value);
                      }}
                    ></WizrTagInput>
                  </>
                )
              case 3:
                return (
                  <>
                    <WizrText type='body2'>{input.name}</WizrText>
                    <Input
                      //onChange={(value: any) => updateMessageParams(item.name, value)}
                      placeholder='Upload File'
                      value={inputValue[input.name]}
                      suffix={
                        <Space>
                          {inputValue[input.name] && ( // Only display clear icon if there's a value
                            <Button shape='round' onClick={(e: any) => {
                              uploadFile(input.name, '')
                              setInputValue({ ...inputValue, [input.name]: null })
                              doUpdateFields(input.name, '')
                            }}>
                              remove file
                            </Button>
                          )}
                          <WizrUploadContainer
                            onChange={(e: any) => {
                              uploadFile(input.name, e)
                              setInputValue({ ...inputValue, [input.name]: e?.file?.name })
                              doUpdateFields(input.name, e?.file?.name)
                            }}
                          >
                            <Button shape='round' >
                              Upload
                            </Button>
                          </WizrUploadContainer>
                        </Space>
                      }
                    />
                  </>
                )
              case 6:
                return (
                  // <WizrFlexLayout alignItems='start' background='#FFF' overFlow='hidden' style={{  width: '100%' }} >
                  <>
                    <WizrText type='body2'>{input.name}</WizrText>
                    <Input
                      placeholder='Upload image'
                      value={inputValue[input.name]}
                      suffix={
                        <Space>
                          {inputValue[input.name] && (
                            <Button shape='round' onClick={(e: any) => {
                              uploadFile(input.name, '')
                              setInputValue({ ...inputValue, [input.name]: null })
                            }}>
                              remove file
                            </Button>
                          )}
                          <WizrUploadContainer
                            onChange={(e: any) => {
                              uploadFile(input.name, e)
                              setInputValue({ ...inputValue, [input.name]: e?.file?.name })
                            }}
                          >
                            <Button shape='round' >
                              Upload
                            </Button>
                          </WizrUploadContainer>
                        </Space>
                      }
                    />

                    {/* </WizrFlexLayout> */}
                  </>
                )
            }
          }
        })}</>:< EnterpriseContentLeftComponent  
        updateFields={updateFields} inputValue={inputValue} parameters={parameters} updateMessageParams={updateMessageParams} setInputValue={setInputValue} doUpdateFields={doUpdateFields} uploadFile={uploadFile} userquery = {userquery} setUserquery={setUserquery} mapValueToUserQuery={mapValueToUserQuery}/>}
       
      </WizrFlexLayout>
      <WizrFlexLayout flex={1} flexDirection="row" background="white" justifyContent="flex-end" style={{ marginTop: "10px", gap: "5px" }}>
        <WizrButton
        disabled={currentApp!==0}
          style={{ borderRadius: "20px" }}
          type="primary" text={<WizrText type="button" textColor="white">Run</WizrText>} onClick={() => { onChatClick() }}></WizrButton>
        <StyledWizrButton
          type="link" text={<WizrText type="button" textColor="#1D1F22">Reset</WizrText>} onClick={() => { setInputValue([]) }}></StyledWizrButton>
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

const EnterpriseContent = (): JSX.Element => {
  const loading = useContentLoadingSelector()
  const location = useLocation()
  const navigate = useNavigate()
  const appDetails = useCurrentAppDetailsSelector()

  const [inputState, setInputState] = useState<any>("")
  const [fileParams, setFileParams] = useState<any>([])
  const [filesData, setFilesData] = useState<any>([])
  const [parameterList, setParameterList] = useState<any>([])
  const [output, setOutput] = useState<any>("")
  const [currentApp, setCurrentApp] = useState<any>(0)
  const [action, setAction] = useState<any>('')

  const uploadedFileValues = useFileParams()

  const testParams = appDetails?.nodes && appDetails?.nodes[0]?.data?.config?.parameters.filter((item: any) => item.name !== 'user_query')

  testParams?.map((item: any) => {
    const file = uploadedFileValues?.find((file: any) => file[0].input_file_name === item.name)
    if (file) {
      // return {...fileParams, `{{${item.name}}}`: `${file[0].timestamp}/${file[0].file_name}` }
      inputState[`{{${item.name}}}`] = `${file[0].timestamp}/${file[0].file_name}`

    }

  })


  const doUpdateFields = (fieldName: string, fieldValue: any) =>
    setInputState((prevInputValue: any) => ({ ...prevInputValue, [`{{${fieldName}}}`]: fieldValue }));
  const chatMessage = useChatMessageSelector()
  const result = useenduserresult()

  const [editorInitialValue, setEditorInitialValue] = useState<any>('')

  const [historyChats, setHistoryChats] = useState<{ user: string; assistant: string }[]>([])

  useEffect(() => {
    const user = chatMessage?.filter((item: any) => item.isSent).map((item: any) => item.text)
    const assistant = chatMessage?.filter((item: any) => !item.isSent).map((item: any) => item.text)
    if (user?.length === assistant?.length) {
      const result = user.map((value:any, index:any) =>
        Object.assign({}, { user: value, assistant: assistant?.[index] }),
      )
      setHistoryChats(result)
    }
  }, [chatMessage])

  useEffect(() => {
    dispatchAppDetails(location?.state)
  }, [])

  useEffect(() => {
    setParameterList(appDetails?.nodes ? appDetails?.nodes[0]?.data?.config?.parameters : [])
    const parameterObject = parameters.reduce((result: any, parameter: any) => {
      result[`{{${parameter}}}`] = "";
      return result;
    }, {});
    setInputState(parameterObject)
  }, [appDetails, parameterList])

  // const parameterList: any = appDetails?.nodes[0]?.data?.config?.parameters 
  const parameters = (parameterList).map((item: any) => item?.name);



  const onChatClick = async () => {
    setOutput("")

    if ((appDetails?.id !== "9f4f23e8-2bb1-450c-a0bf-181c35875941") && (appDetails?.id !== "b33b3290-76de-42d2-a95b-88e20d50e38a") && (appDetails?.id !== "d0eb593c-9b4d-46d4-b5b7-0fdbf7c2b889")){
      testParams?.map((item: any) => {
        const file = uploadedFileValues?.find((file: any) => file[0].input_file_name === item.name)
        if (file) {
          // return {...fileParams, `{{${item.name}}}`: `${file[0].timestamp}/${file[0].file_name}` }
          inputState[`{{${item.name}}}`] = `${file[0].timestamp}/${file[0].file_name}`

        }

      })
      const testAppPayload = {
        id: appDetails?.id,
        app_id: appDetails?.app_id,
        history: appDetails?.memory ? historyChats : [],
        exec_type: "app",
        max_past_messages: appDetails?.memory ? 15 : 0,
        message_params: inputState,
        file_params: fileParams ? fileParams : [],
      }

      const formData = new FormData();
      const jsonData = JSON.stringify(testAppPayload);
      formData.append('data', jsonData);
      filesData?.map((item: any) => {
        formData.append(item.param_name, item.file);
      })
      setCurrentApp(1)

      setAction("Processing")

      //dispatchenduserapi(testAppPayload)
      const response = await testAppTool(testAppPayload)
        const statusResponse = await statusChecker(response?.data?.statusQueryGetUri)
        const parsedResponse = JSON.parse(statusResponse)
        const outputF = parsedResponse?.content
        // resultOfOrcherstrator = resultOfOrcherstrator + "\n\n" + outputF
        // const updatedOutput = output + "\n\n" + outputF;
        setOutput(outputF)
        setCurrentApp(0)
        // setAction("")

    }
    else if (appDetails?.id === "9f4f23e8-2bb1-450c-a0bf-181c35875941") {
      const appIds = [{
        id: "3110fc8b-c084-470d-b761-da4d7bc4ba70",
        app_id: "9fffac02-4355-4b11-927d-c6d8ede3f4eb"
      },
     
      {
        id: "c209847c-64a8-4458-b3e8-7e839e305f2b",
        app_id: "6aaa168f-28d0-4442-9834-2c248d829399"
      },
      {
        id: "c305d041-6658-424f-bdee-5b460afceacc",
        app_id: "52d2e3f2-77f5-402c-9faa-cd7b47e84d64"
      },
      {
        id: "46484768-1eb3-4e15-b258-4f1b82f214bc",
        app_id: "0aac0508-e673-40be-8dab-519860a2b320"
      }
      ]
      let iterationCounter = 0;
      let resultOfOrcherstrator = ''
      // appIds.map(async (app: any) => {
      for (const app of appIds) {
        iterationCounter++
        setCurrentApp(iterationCounter)
        switch (iterationCounter) {
          case 1:
            setAction("Generating introduction")
            break
          case 2:
            setAction("Generating  petitioner content")
            break
          case 3:
            setAction("Generating  US role")
            break
          case 4:
            setAction("Generating  abroad role and conclusion")
            break
        }
        testParams?.map((item: any) => {


          const file = uploadedFileValues?.find((file: any) => file[0].input_file_name === item.name && file[0].path === app?.app_id)
          if (file) {

            // return {...fileParams, `{{${item.name}}}`: `${file[0].timestamp}/${file[0].file_name}` }
            inputState[`{{${item.name}}}`] = `${file[0].timestamp}/${file[0].file_name}`

          }

        })

        const testAppPayload = {
          id: app?.id,
          app_id: app?.app_id,
          history: appDetails?.memory ? historyChats : [],
          exec_type: "app",
          max_past_messages: appDetails?.memory ? 15 : 0,
          message_params: inputState,
          file_params: fileParams ? fileParams : [],
        }

        const formData = new FormData();
        const jsonData = JSON.stringify(testAppPayload);
        formData.append('data', jsonData);
        filesData?.map((item: any) => {
          formData.append(item.param_name, item.file);
        })
        //await dispatchenduserapi(formData)
        const response = await testAppTool(testAppPayload)
        const statusResponse = await statusChecker(response?.data?.statusQueryGetUri)
        const parsedResponse = JSON.parse(statusResponse)
        const outputF = parsedResponse?.content
        resultOfOrcherstrator = resultOfOrcherstrator + "\n\n" + outputF
        const updatedOutput = output + "\n\n" + outputF;
        setOutput(resultOfOrcherstrator)
       

      }
      setCurrentApp(0)

    }
    else{
      const appIds = [
        {
          id: "dfeba802-3122-42ef-bf57-9d7f9c0ed0db",
          app_id: "a03761f2-d498-4b21-9c54-04f035dedc30"
        },
        {
        id: "b33b3290-76de-42d2-a95b-88e20d50e38a",
        app_id: "a69ea027-0c27-4d61-ab22-6639ff8b6f67"
      },
     ]
      let iterationCounter = 0;
      let resultOfOrcherstrator = ''
      // appIds.map(async (app: any) => {
      for (const app of appIds) {
        iterationCounter++
        setCurrentApp(iterationCounter)
        switch (iterationCounter) {
          case 1:
            setAction("Generating Part 1")
            break
          case 2:
            setAction("Generating  Part 2")
            break
          
        }
        testParams?.map((item: any) => {

          const file = uploadedFileValues?.find((file: any) => file[0].input_file_name === item.name && file[0].path === app?.app_id)
          if (file) {

            // return {...fileParams, `{{${item.name}}}`: `${file[0].timestamp}/${file[0].file_name}` }
            inputState[`{{${item.name}}}`] = `${file[0].timestamp}/${file[0].file_name}`

          }

        })

        const testAppPayload = {
          id: app?.id,
          app_id: app?.app_id,
          history: appDetails?.memory ? historyChats : [],
          exec_type: "app",
          max_past_messages: appDetails?.memory ? 15 : 0,
          message_params: inputState,
          file_params: fileParams ? fileParams : [],
        }

        const formData = new FormData();
        const jsonData = JSON.stringify(testAppPayload);
        formData.append('data', jsonData);
        filesData?.map((item: any) => {
          formData.append(item.param_name, item.file);
        })
        //await dispatchenduserapi(formData)
        const response = await testAppTool(testAppPayload)
        const statusResponse = await statusChecker(response?.data?.statusQueryGetUri)
        const parsedResponse = JSON.parse(statusResponse)
        const outputF = parsedResponse?.content
        resultOfOrcherstrator = resultOfOrcherstrator + "\n\n" + outputF
        const updatedOutput = output + "\n\n" + outputF;
        setOutput(resultOfOrcherstrator)
       

      }
      setCurrentApp(0)

    }
    }
  

  const mapValueToUserQuery = (fieldValue: any) =>
    setInputState({
      ...inputState,
      '{{user_query}}': fieldValue,
    })

  const initialContent = result ? result : ''

  // const text = `<p>United States Citizenship and Immigration Services,<br>
  // United States Citizenship and Immigration Services Texas Service Center,<br>
  // Attn: I-129 L Premium Processing,<br>
  // 6046 N Belt Line Rd,<br>
  // Irving TX 75038-0001,<br>
  // Attn: Non-immigrant Visa Section,<br>
  // **RE: L-1B Intra-Company Transfer Petition**
  // <strong>Petitioner: Wartsila North America Inc,</strong><br>
  // <strong>Beneficiary: Douglas James SHEPPARD</strong></p>`;


  useEffect(() => {

    
    // const joinedContent = formattedContent ? formattedContent.reduce((acc: any, curr: any) => [acc, ' ', curr]) : null;


  

    const blocksFromHTML = convertFromHTML(output);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );



    setEditorState(EditorState.createWithContent(state))
    setData(output)

  }, [output])

  const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromText('')));

  const apprunHistory = () => {
    if ((appDetails?.app_id !== "108b4c61-2068-4dc6-ae8a-71c24dc33785")&& (appDetails?.app_id !=="a69ea027-0c27-4d61-ab22-6639ff8b6f67")) {
      navigate("/runhistory", { state: appDetails })

    }
    else if (appDetails?.app_id !== "108b4c61-2068-4dc6-ae8a-71c24dc33785"){
      const data = [
        {
          id: "3110fc8b-c084-470d-b761-da4d7bc4ba70",
          app_id: "9fffac02-4355-4b11-927d-c6d8ede3f4eb"
        },
        {
          id: "c209847c-64a8-4458-b3e8-7e839e305f2b",
          app_id: "6aaa168f-28d0-4442-9834-2c248d829399"
        },
        {
          id: "c305d041-6658-424f-bdee-5b460afceacc",
          app_id: "52d2e3f2-77f5-402c-9faa-cd7b47e84d64"
        },
        {
          id: "46484768-1eb3-4e15-b258-4f1b82f214bc",
          app_id: "0aac0508-e673-40be-8dab-519860a2b320"
        }
      ];

      navigate("/euserhistory", { state: data });
    }
    else{
      const data = [
        {
          id: "dfeba802-3122-42ef-bf57-9d7f9c0ed0db",
          app_id: "a03761f2-d498-4b21-9c54-04f035dedc30"
        },
        {
        id: "b33b3290-76de-42d2-a95b-88e20d50e38a",
        app_id: "a69ea027-0c27-4d61-ab22-6639ff8b6f67"
      },
      
     ]
     navigate("/euserhistory", { state: data });

    }
  }

  const toolbarOptions = {
    options: ['fontFamily', 'inline', 'textAlign', 'list', 'fontSize',],
    // 'fontSize', 'fontFamily', 'textColor', 'backgroundColor', 'inline', 'blockType', 'list', 'textAlign', 'link', 'embedded', 'emoji', 'image'
    inline: {
      options: ['bold', 'italic', 'underline',],
    },
    textAlign: {
      options: ['left', 'center', 'right', 'justify'],
    },
    fontFamily: {
      options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Verdana'],
    },
    textColor: true,
  };

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
  };

  const copyContentToClipboard = () => {
    
    const range = document.createRange();
  range.selectNodeContents(contentRef.current!); // Use the non-null assertion operator (!) or add a null check

  const selection = window.getSelection();

  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand('copy');
    selection.removeAllRanges();
  }
  message.success('Copied to clipboard', 2);
  };

  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState('');

  useEffect(() => {
    setEditorLoaded(true);
  }, []);
  const contentRef = useRef<HTMLDivElement>(null);
 
  return (
    <WizrFlexLayout style={{ padding: "10px 0px" }}>
      <WizrFlexLayout flex={.5} flexDirection="row" alignItems="flex-start" style={{ padding: " 15px" }}>

        <WizrFlexLayout flex={1.5} alignItems="flex-start" flexDirection="row">
          <WizrButton icon={<ArrowLeftOutlined style={{fontSize:"20px"}}/>} text={<WizrText type="sub1">{appDetails?.app_id !== "a69ea027-0c27-4d61-ab22-6639ff8b6f67"?appDetails?.app_name:"Canada Supprt Letter V2"}</WizrText>}
          type='text' onClick={()=>{navigate(-1)}} ></WizrButton>
          
        </WizrFlexLayout>

        <WizrFlexLayout flexDirection="row" flex={4} alignItems="flex-start" style={{ paddingLeft: "20px" ,gap:"30px"}}>
          <WizrText type="sub1">Output</WizrText>
          {currentApp !== 0 ? <><WizrText type="body2">{action}...This might take some time</WizrText><Spin /></>
            : <></>}
{loading ?<><WizrText type="body2">File upload in progress....</WizrText><Spin /></>:<></>}
          <WizrFlexLayout alignItems="flex-end" flexDirection="row" justifyContent="flex-end">
          <StyledHeaderWizrButton type="link" icon={<HistoryIcon />} text={<WizrText type="button" textColor="#1D1F22" >history</WizrText>} onClick={() => { apprunHistory() }}></StyledHeaderWizrButton>
          {output && <WizrButton
            // icon={<RefreshIcon />}
            text={<StyledTextButton type='link'>copy</StyledTextButton>}
            type='link'
            icon={<Copy />}
            onClick={() => { copyContentToClipboard() }}
          />}
          </WizrFlexLayout>
        </WizrFlexLayout>


      </WizrFlexLayout>
      <WizrFlexLayout flex={9} flexDirection="row" style={{ paddingTop: "0" }}>

        <WizrFlexLayout style={{ padding: " 0 10px", maxWidth: "350px" }} justifyContent="flex-start">


          <SingleRunContainerLeft mapValueToUserQuery={mapValueToUserQuery} parameters={parameterList} doUpdateFields={doUpdateFields} saveFileParams={setFileParams} saveFilesData={setFilesData} onChatClick={onChatClick} currentApp={currentApp} appDetails={appDetails}/>
        </WizrFlexLayout>

        <WizrFlexLayout style={{ padding: " 0 10px" }}>

          <WizrFlexLayout style={{ borderRadius: "16px", padding: '-20px' }} background="white">
            {/* <StyledWizrEditor > */}
            <WizrFlexLayout>
            <div ref={contentRef}>

              <Editor
                name="description"
                onChange={(data: any) => {
                  setData(data);
                }}
                value={data}
                editorLoaded={editorLoaded}
              />
              </div>
              
              {/* <WizrFlexLayout alignItems="flex-end">
                <WizrButton
                  // icon={<RefreshIcon />}
                  text={<StyledTextButton type='link'>copy</StyledTextButton>}
                  type='link'
                  icon={<Copy />}
                  onClick={() => { copyContentToClipboard() }}
                />

              </WizrFlexLayout> */}
            </WizrFlexLayout>
            {/* <WizrFlexLayout>
              <WizrButton
          style={{ borderRadius: "20px" }}
          type="primary" text={<WizrText type="button" textColor="white">Run</WizrText>} onClick={() => { onChatClick() }}></WizrButton>
              </WizrFlexLayout> */}

            {/* </StyledWizrEditor> */}


          </WizrFlexLayout>

        </WizrFlexLayout>


      </WizrFlexLayout>

    </WizrFlexLayout>
  )
}
export default EnterpriseContent


const StyledTextButton = styled(WizrText)`
  text-decoration: underline !important;
`

