import { useLocation, useNavigate } from 'react-router-dom'
import WizrAccesTable from '../../../components/WizrAccessTable.tsx/WizrAccessTableEdit'
import WizrButton from '../../../components/WizrButton'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrText from '../../../components/WizrText'
import WizrUserJourney from '../../../components/WizrUserJourney'
import WizrInput from '../../../components/WizrInput'
import { useState } from 'react'
import {
  dispatchCreateRole,
  dispatchDeleteRole,
  dispatchRolesList,
  dispatchUpdateRole,
  dispatchupdateUserView,
} from '../../../redux/actions/content'
import styled from 'styled-components'
import MoreIcon from '../../../assets/icons/More'
import WizrAccessTableView from '../../../components/WizrAccessTable.tsx/WizrAccessTableView'
import { Popover } from 'antd'
import EditIcon from '../../../assets/icons/EditIcon'
import DeleteIcon from '../../../assets/icons/DeleteIcon'
import { MODAL_TYPES, useWizrModalContext } from '../../../providers/modal/WizrModalProvider'
import { useAuth } from '../../../authContext'


const ProductPopOver = ({ onEditAction,onDeleteAction }: any): JSX.Element => {
  const { userPermission, hasAcces } = useAuth();

    return (
      <WizrFlexLayout textAlign='left' background='#FFF'>
        <WizrButton
          icon={<EditIcon />}
          text='Edit'
          type='text'
          onClick={onEditAction}
          justifyContent='flex-start'
          disabled={hasAcces('editroles_settings')  === true ? false : true}

        />
        <WizrButton
            icon={<DeleteIcon />}
            text='Delete'
            type='text'
            onClick={onDeleteAction}
            justifyContent='flex-start'
            disabled={hasAcces('deleteroles_settings')  === true ? false : true}

          />
      </WizrFlexLayout>
    )
  }
  

const CreateRoles = () => {
  const location = useLocation()
  const roleDetails = location?.state?.role
  const mode = location?.state?.view
  const navigate = useNavigate()
  const [roleName, setRoleName] = useState(roleDetails?.role_name ? roleDetails?.role_name : '')
  const [selectedItems, setSelectedItems] = useState<string[]>(
    roleDetails?.access_privileges ? roleDetails?.access_privileges : [],
  )
  const [viewMode, setViewMode] = useState(mode)
  const { showModal, hideModal } = useWizrModalContext()

  const createRole = async () => {
    if (roleDetails?.id) {
      const key = roleName.replace(/\s+/g, '').toLowerCase()
      const uniqueValues = selectedItems.filter((value: any, index: any, self: any) => {
        return self.indexOf(value) === index
      })

      const payload = {
        ...roleDetails,
        role_key: key,
        role_name: roleName,
        access_privileges: uniqueValues,
      }
      await dispatchUpdateRole(payload)
    } else {
      const key = roleName.replace(/\s+/g, '').toLowerCase()
      const uniqueValues = selectedItems.filter((value: any, index: any, self: any) => {
        return self.indexOf(value) === index
      })

      const payload = {
        role_key: key,
        role_name: roleName,
        role_description: '',
        access_privileges: uniqueValues,
        tenant_id: 'WIZR-BASIC-TENANT',
        status: 'active',
      }
      await dispatchCreateRole(payload)
    }
    await dispatchRolesList()
    navigate(-1)
    dispatchupdateUserView({
      activeKey: 'roles',
    })
  }


  const onEditAction = () => {  
    setViewMode(false)
  }
  const deleteRole = async () => {
    hideModal()
    await dispatchDeleteRole(roleDetails?.id)
    dispatchRolesList()
    navigate(-1)
    dispatchupdateUserView({
      activeKey: 'roles',
    })
  }
  const onDeleteAction = async () => {
    showModal(MODAL_TYPES.CONFIRMATION, {
      text: `Are you sure you want to delete ${roleDetails?.role_name}`,
      buttontext: 'Confirm',
      onConfirm: deleteRole,
      onCancel: hideModal,
    })
  }
  
  return (
    <WizrFlexLayout background='white'>
      <WizrFlexLayout background='white' flex={1.5}>
        <WizrUserJourney
          userJourney={[
            {
              title: (
                <WizrButton
                  type='text'
                  text={
                    <WizrText type='h6' textColor='#707276'>
                      Settings
                    </WizrText>
                  }
                  onClick={() => navigate(-1)}
                />
              ),
              isCurrent: true,
            },
            {
              title: (
                <WizrButton
                  type='text'
                  text={
                    <WizrText type='h6' textColor='#707276'>
                      Roles
                    </WizrText>
                  }
                  onClick={() => navigate(-1)}
                />
              ),
              isCurrent: true,
            },
            {
              title: (
                <WizrButton
                  type='text'
                  text={
                    <WizrText type='h6' textColor='#1D1F22'>
                      Create new Role
                    </WizrText>
                  }
                />
              ),
              isCurrent: true,
            },
          ]}
        />
      </WizrFlexLayout>
      <WizrFlexLayout
        background='white'
        flex={10.5}
        style={{ borderRadius: '8px', gap: '10px', padding: '20px' }}
        textAlign='left'
      >
        {/* <WizrFlexLayout  background="white" style={{width:'30%',minHeight:'100px',maxHeight:'150px',gap:'10px'}} textAlign="left"> */}
        <WizrFlexLayout background='white' style={{minHeight:"30px"}} flexDirection='row
        '>
        <WizrText type='sub2' textColor='#1D1F22'>
          Create New Role
        </WizrText>
   
            <Popover
              trigger='click'
              zIndex={1}
              content={<ProductPopOver onEditAction={onEditAction} onDeleteAction={onDeleteAction} />}
            >
              <StyledMoreButton
                icon={<MoreIcon />}
                text=''
                type='text'
              />
            </Popover>
         
        </WizrFlexLayout>
        <WizrText type='body2' textColor='#707276'>
          Role Name
        </WizrText>

        <WizrInput
          value={roleName}
          background={'#F2F4F9'}
          style={{ width: '30%' }}
          onChange={(value: any) => {
            setRoleName(value)
          }}
        />
        {/* </WizrFlexLayout> */}
        {/* <WizrFlexLayout  background="white"> */}

        {viewMode ? (
          <WizrAccessTableView setSelectedItems={setSelectedItems} selectedItems={selectedItems} />
        ) : (
          <WizrAccesTable setSelectedItems={setSelectedItems} selectedItems={selectedItems} />
        )}
        {/* </WizrFlexLayout> */}
      </WizrFlexLayout>
      <WizrFlexLayout alignItems='flex-end' style={{ padding: '8px 0 0 0' }} background='white'>
        <StyledButton
          text={
            <WizrText type='body2' textColor='#FFFFFF'>
              {roleDetails?.id ? "Update" : "Create"}
            </WizrText>
          }
          onClick={() => {
            createRole()
          }}
        />
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}
export default CreateRoles

const StyledButton = styled(WizrButton)`
  border-radius: 20px;
`

const StyledMoreButton = styled(WizrButton)`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding-right: 8px;
  justify-content: center;
  align-items: center;
  /* Default styles */
  path {
    stroke: #999b9f; /* Default stroke color */
    transition: stroke 0.3s ease; /* Smooth transition for color change */
  }

  /* Hover styles */
  &:hover path {
    stroke: #1d1f22;
  }
`
