import { useEffect } from 'react'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrText from '../components/WizrText'
import { dispatchAppDraft } from '../redux/actions/content'
import { useContentLoadingSelector, useappDataSelector } from '../redux/selectors/content'
import { Card, Spin } from 'antd'
import styled from 'styled-components'
import WizrButton from '../components/WizrButton'
import AIIcon from '../assets/icons/AIIcon'
import WizrTag from '../components/WizrTags'
import { useNavigate } from 'react-router'
import { useAuth } from '../authContext'

const StyledCard = styled(Card)`
display: flex;
width: 23%;
height: 70%

flex-direction: row;

border-radius: 16px;
background: var(--generic-white, #FFF);
`
const EndUserAppListing = (): JSX.Element => {
  const { userPermission, hasAcces } = useAuth()

  useEffect(() => {
    dispatchAppDraft()
  }, [])
  const data = useappDataSelector()
  const navigate = useNavigate()
  const appData =
    data &&
    data.filter(
      (item: any) =>
        item.app_id === 'faf6b2ca-a3df-487a-882c-ae807665fea0' ||
        item.app_id === '108b4c61-2068-4dc6-ae8a-71c24dc33785' ||
        item.app_id === '03016072-92ea-40b7-8d7b-7380a7b445e1' ||
        item.app_id === 'a69ea027-0c27-4d61-ab22-6639ff8b6f67',
    )

  const loading = useContentLoadingSelector()

  const onRunClick = (item: any) => {
    navigate('/enduserapp', { state: item?.id })
  }
  return (
    <WizrFlexLayout
      style={{ padding: '20px', gap: '20px' }}
      textAlign='left'
      alignItems='flex-start'
      alignContent='flex-start'
      justifyContent='flex-start'
    >
      <WizrText type='h4'>Elevate with Wizr’s AI Toolbox</WizrText>
      <WizrText type='body2'>
        Drive into the future of AI innovation with Wizr’s curated selection of AI tools
      </WizrText>
      <WizrText type='h7'>Your Apps</WizrText>
      <WizrFlexLayout>
        {loading ? (
          <Spin />
        ) : (
          <WizrFlexLayout
            flexDirection='row'
            style={{ gap: '15px', marginTop: '16px', paddingRight: '30px', maxHeight: '79%' }}
          >
            {appData &&
              appData?.slice(0, 4).map((item: any, index: any) => (
                <StyledCard key={index}>
                  <WizrFlexLayout
                    style={{ gap: '2%', height: '90%', width: 'auto' }}
                    textAlign='left'
                    background='white'
                  >
                    <AIIcon></AIIcon>
                    <WizrText type='sub2'>
                      {item?.app_id !== 'a69ea027-0c27-4d61-ab22-6639ff8b6f67'
                        ? item?.app_name
                        : 'Canada Supprt Letter V2'}
                    </WizrText>
                    <WizrText type='caption'>
                      Version{' '}
                      {item?.app_id !== 'a69ea027-0c27-4d61-ab22-6639ff8b6f67'
                        ? item?.app_version
                        : 'V2'}
                    </WizrText>
                    {/* <WizrFlexLayout background="white" flexDirection='row' flex={1}> */}
                    <div>
                      <WizrTag type='colorfull' data={item?.tags} />
                    </div>
                    {/* </WizrFlexLayout> */}
                    <WizrText type='body2'>{item?.description ? item?.description : ''}</WizrText>
                    <WizrButton
                      type='link'
                      text={
                        <WizrText type='button' textColor='black'>
                          RUN
                        </WizrText>
                      }
                      style={{
                        borderWidth: '1px',
                        borderColor: 'black',
                        borderRadius: '16px',
                        width: 'auto',
                      }}
                     // disabled={hasAcces('view_app-history') === true ? false : true}
                      onClick={() => {
                        onRunClick(item)
                      }}
                    ></WizrButton>
                  </WizrFlexLayout>
                </StyledCard>
              ))}
          </WizrFlexLayout>
        )}
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}
export default EndUserAppListing
