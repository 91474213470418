import { ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";
import WizrButton from "../../../components/WizrButton";
import WizrFlexLayout from "../../../components/WizrFlexLayout";
import WizrUserJourney from "../../../components/WizrUserJourney";
import { Button, Card, Collapse, Divider, Dropdown, Input, Space } from "antd";
import PollIcon from "../../../assets/icons/poll";
import WizrText from "../../../components/WizrText";
import styled from "styled-components";
import Search from "../../../assets/icons/Search";
import SearchSetting from "../../../assets/icons/SearchSetting";
import WizrIcon from "../../../components/WizrIcon";
import WizrTag from "../../../components/WizrTags";
import WizrFlexSpacing from "../../../components/WizrFlexSpacing";
import WizrAvatar from "../../../components/WizrAvatar";
import { useEffect, useMemo, useState } from "react";
import { useCommonTags, useControlroomFilter, useIntentsGraphData, useIntentsListData } from "../../../redux/selectors/content";
import CloseIconOutlined from "../../../assets/icons/CloseIconOutlined";
import { dispatchFiltervalues, dispatchTicketBasedOnIntent } from "../../../redux/actions/content";
import { Bar, BarChart, CartesianGrid, Cell, Label, XAxis, YAxis } from "recharts";



const SearchInput = styled(Input)`
  border-radius: 30px;
  border: 0px solid var(--surface-overlay, #f9f9f9);
  background: #fff;
  width: 512px;
  height: 40px;
`
const StyledTextButton = styled(WizrText)`
  text-decoration: underline !important;
`
const StyledCard = styled(Card) <any>`
display: flex;
flex-direction: column;
.ant-card-body {
    padding: 16px
}
`
const StyledFlexSpacing = styled(WizrFlexSpacing)`
  .ant-space-item {
    display: flex !important;
    width: 100% !important;
    flex-wrap: wrap;
  }
`

const DiscoverScreen = ({ setSelectedTab, activeCollaps, filterModal, sentimentDropdownItems, handleCollapseChange, ticketsBasedOnIntentsArray, ticketSort, setTicketSort, setViewFullTicketSetting, onFormSubmission }: any): JSX.Element => {

  // const [ticketCollapse, setTicketCollapse] = useState(false)

  // const handleCollapseChangeTickets = (activeKeys: any) => {
  //   console.log(activeKeys)
  //   setTicketCollapse(activeKeys)
  // }

  const controlrromFilter = useControlroomFilter();
  const [filteredValues, setFilteredValues] = useState(useControlroomFilter());

  useEffect(() => {
    setFilteredValues(controlrromFilter)
  }, [controlrromFilter])

  const flattenedArray: any = useMemo(() => {
    // Get an array of values from the object, which are arrays
    const valuesArray = Object.values(filteredValues);
    // Flatten the array of arrays into a single array
    return valuesArray.flat();
  }, [filteredValues]);

  const handleRemoveFilters = (key: any, value: any) => {
    // Calculate the updated values first
    const updatedValues = filteredValues[key].filter((item: any) => item !== value);
  
    // Clone the filteredValues object
    const newFilterValues = { ...filteredValues };
  
    if (updatedValues.length > 0) {
      // If there are still values left in the array, update the object
      newFilterValues[key] = updatedValues;
    } else {
      // If the array is empty, remove the key from the object
      delete newFilterValues[key];
    }    
    // Update the state with the new filter values
    setFilteredValues(newFilterValues);
    onFormSubmission(newFilterValues);
  };


  const clearAllFilters = () => {
    const filterValue = {
      Intent: [`${activeCollaps}`]
    }
    onFormSubmission(filterValue)
  }

  const topIntents = useIntentsListData();
  const intentsGraphData = useIntentsGraphData();
  const commonTags = useCommonTags();

  const intentGraphDataArray = useMemo(() => {
    const rows = intentsGraphData?.resultTable?.rows || [];
    const columns = intentsGraphData?.resultTable?.dataSchema?.columnNames || [];
    const filteredColumns = columns.filter((columnName: any) => columnName === 'CreatedDate' || columnName === 'TotalCount');
    const filteredColumnIndexes = filteredColumns.map((column: any) => columns.indexOf(column));

    return rows.map((row: any) => {
      const obj: any = {};
      filteredColumnIndexes.forEach((columnIndex: any) => {
        const columnName = columns[columnIndex];
        obj[columnName] = row[columnIndex];
      });
      return obj;
    });
  }, [intentsGraphData]);

  const extractData = (result: any) => {
    const rows = result?.resultTable?.rows || [];
    const columns = result?.resultTable?.dataSchema?.columnNames || [];
    return rows.map((row: any) => {
      const obj: any = {};
      columns.forEach((columnName: any, index: any) => {
        obj[columnName] = row[index];
      });
      return obj;
    });
  };

  const topIntentsArray = useMemo(() => extractData(topIntents), [topIntents]);
  const commonTagsArray = useMemo(() => extractData(commonTags), [commonTags]);

  const items123 = topIntentsArray.map((intent: any, index: any) => ({
    key: String(intent.Intent),  // Ensure key is unique and a string
    label: <WizrFlexLayout background='white' flexDirection='row' justifyContent='space-beteen' flex={1} style={{ gap: "20px" }}>
      <WizrFlexLayout background='white' style={{ padding: "5px" }} textAlign='left'>
        <WizrText type='body2'>{intent.Intent}</WizrText>
        <WizrFlexLayout background='white' flexDirection='row' alignItems='center' justifyContent='flex-start' style={{ gap: '10px' }} >
          <WizrText type='sub2'>{intent.TotalCount}</WizrText>
          <WizrIcon name='trendUpRed' />
          <WizrText type='body2' textColor='#22C55E'>+15%</WizrText>
        </WizrFlexLayout>
      </WizrFlexLayout>
      <WizrFlexLayout background='white' style={{ padding: "5px", gap: '5px' }} textAlign='end' alignContent='flex-end' >
        <WizrText type='body2'> Sentiment</WizrText>
        <WizrFlexLayout background='white' flexDirection='row' justifyContent='end' alignItems='center' textAlign='end' alignContent='end' style={{ gap: '10px', alignItems: 'center' }} >
          <WizrText type='body1' textColor='#22C55E'>{intent?.OverallSentimentScore.toFixed(1)}</WizrText>
          <WizrTag
            background={parseInt(intent?.OverallSentimentScore) > 80 ? "#22C55E" : parseInt(intent?.OverallSentimentScore) < 80 && parseInt(intent?.OverallSentimentScore) > 40 ? "#FBBF24" : "#EF4444"}
            // background="#FBBF24" '#22C55E' : '#EF4444'
            data={parseInt(intent.OverallSentimentScore) > 80 ? ['GOOD'] : parseInt(intent.OverallSentimentScore) < 80 && parseInt(intent.OverallSentimentScore) > 40 ? ['FAIR'] : ['BAD']}
            borderradius='4px'
          />
        </WizrFlexLayout></WizrFlexLayout>
    </WizrFlexLayout>,  // Use intent name for label
    children: <Chart data={intentGraphDataArray} tags={commonTagsArray} onFormSubmission1={() => {console.log('hi111')}}/>  // Pass intent data to the Chart component
  }));


  return (
    <div>
      <WizrFlexLayout alignContent='flex-start' flexDirection='row' justifyContent='space-between'>
        <WizrFlexLayout flex={1}>
          <WizrUserJourney
            userJourney={[
              {
                title: (
                  <WizrButton
                    style={{ paddingBottom: '8px' }}
                    icon={<ArrowLeftOutlined style={{ fontSize: '22px', marginRight: '10px' }} />}
                    type='text'
                    text='Cutomer Intents and Sentiments'
                    onClick={() => { setSelectedTab('dashboards'); clearAllFilters(); }}
                  />
                ),
              },
            ]}
          ></WizrUserJourney>
        </WizrFlexLayout>
        <WizrFlexLayout alignItems='center' flex={2} style={{ padding: '10px' }}>
          <SearchInput placeholder='Search' prefix={<Search />} suffix={<Button shape='circle' icon={<SearchSetting />} onClick={() => filterModal()}></Button>} />
        </WizrFlexLayout>
        <WizrFlexLayout flex={1} alignItems='center' flexDirection='row' alignContent='flex-end' justifyContent='end' >
          <Dropdown trigger={['click']} menu={{ items: sentimentDropdownItems, selectable: true }} >
            <a onClick={(e) => e.preventDefault()}>
              <Space style={{ color: '#707276' }}>
                <span>{'Sentiment'}</span>
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
          <WizrButton
            icon={<PollIcon />}
            text='Explore'
            type='text'
            onClick={() => { setViewFullTicketSetting(true) }}
            style={{ borderRadius: '30px' }}
          />
        </WizrFlexLayout>
      </WizrFlexLayout>
      <WizrFlexLayout flexDirection='row' style={{ width: '100%', maxHeight: '90vh' }}>
        <WizrFlexLayout flexDirection='column' background='white' style={{ margin: '10px', borderRadius: '8px' }}>
          <WizrFlexLayout background='white' alignItems='flex-start' justifyContent='flex-start' style={{ padding: '16px', gap: '8px' }}>
            <WizrText type='sub2'>Intents</WizrText>
            <Divider style={{ margin: '10px 0px' }} />
            <Collapse accordion defaultActiveKey={activeCollaps} items={items123} bordered={false} onChange={handleCollapseChange} />
          </WizrFlexLayout>

        </WizrFlexLayout>
        <WizrFlexLayout background='white' style={{ margin: '10px', borderRadius: '8px' }}>
          <StyledCard>
            <WizrFlexLayout background='white' alignItems='flex-start' justifyContent='flex-start' flexDirection="">
              <WizrFlexLayout flexDirection='row' background='white' alignItems="flex-start">
                <WizrFlexLayout flexDirection='row' background='white' justifyContent='flex-start' style={{ minWidth: 'fit-content', flex: 0 }}>
                  <WizrText type='sub2' style={{ padding: '4px 0px' }}>All conversation </WizrText>
                  <WizrIcon name='breakCrumbArrow' />
                  <WizrText type='body2' style={{ padding: '4px 0px' }}>{activeCollaps}</WizrText>
                  {flattenedArray?.length > 0 ? <WizrIcon name='breakCrumbArrow' /> : <></>}
                </WizrFlexLayout>
                <WizrFlexLayout flexDirection="row" justifyContent="flex-start" background="#FFF" style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '0px 4px' }}>
                  {Object.keys(filteredValues).map((key) => (
                    filteredValues[key].map((item: any, index: any) => (
                      // Check if the key is "intent" and index is 0, if so, skip rendering
                      (key === "Intent" && index === 0) ? null :
                        <WizrFlexLayout key={`${key}-${index}`} flexDirection="row" background="#FFF" justifyContent="flex-start" alignItems="center" style={{ minWidth: 'fit-content', flex: 0 }}>
                          <WizrText type='body2' style={{ padding: '4px 0px' }}>{item}</WizrText>
                          <WizrButton
                            type='link'
                            icon={<CloseIconOutlined />}
                            style={{ padding: '0px' }}
                            onClick={() => handleRemoveFilters(key, item)}
                          />
                        </WizrFlexLayout>
                    ))
                  ))}
                </WizrFlexLayout>
                <WizrButton
                  text={<StyledTextButton type='link' onClick={() => clearAllFilters()}>Clear all filters</StyledTextButton>}
                  type='link'
                />
              </WizrFlexLayout>

              <Divider style={{ margin: '10px 0px' }} />

              <WizrFlexLayout flexDirection='row' background='white'>
                <WizrText type='sub2'>{`${ticketsBasedOnIntentsArray && ticketsBasedOnIntentsArray?.length} Conversations`}</WizrText>
                <Dropdown trigger={['click']} menu={{ items: [{ key: 'DESC', label: 'Latest' }, { key: 'ASC', label: 'Oldest' }], selectable: true, onSelect: (selectedItem) => setTicketSort(selectedItem.key) }} >
                  <Space style={{ color: '#707276' }}>
                    <WizrText type='sub2'>{`${ticketSort === 'DESC' ? `Latest` : `Oldest`}`}</WizrText>
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </WizrFlexLayout>
              <Divider style={{ margin: '10px 0px' }} />
              {
                ticketsBasedOnIntentsArray?.map((item: any, key: any) => {
                  return (
                    <WizrFlexLayout key={key}>
                      <WizrFlexLayout flexDirection='row' alignItems='space-between' background='white' justifyContent='space-between' >
                        <WizrFlexLayout background='white' flexDirection='row' alignItems='flex-start' justifyContent='flex-start' flex={2} style={{ gap: '10px' }}>
                          <WizrText type='sub1'>{`Ticketid : ${item?.Ticket_ID}`}</WizrText>
                          <WizrText type='body2'>{item?.Intent}</WizrText>
                        </WizrFlexLayout>
                        <WizrFlexLayout background='white' alignItems='flex-end' flex={1}>
                          <WizrText type='body2'>{item?.Created_date_timestamp}</WizrText>
                          <WizrText type='sub1'>{item?.Type}</WizrText>
                        </WizrFlexLayout>
                      </WizrFlexLayout>
                      <WizrFlexLayout background='white'>
                        <WizrFlexLayout flexDirection='row' justifyContent='flex-start' background='white'>
                          <WizrTag
                            background={item.Ticket_Priority === 'Low' ? "#22C55E" : item.Ticket_Priority === 'Medium' ? "#FBBF24" : "#EF4444"}
                            data={item?.Ticket_Priority?.split(',')}
                            borderradius='4px'
                            checkable={false}
                          />
                          {/* background={parseInt(intent?.OverallSentimentScore) > 80 ? "#22C55E" : parseInt(intent?.OverallSentimentScore) < 80 && parseInt(intent?.OverallSentimentScore) > 40 ? "#FBBF24" : "#EF4444"} */}
                          <WizrTag
                            background={item.Sentiment === 'Positive' ? "#22C55E" : item.Sentiment === 'Neutral' ? "#FBBF24" : "#EF4444"}
                            data={item?.Sentiment?.split(',')}
                            borderradius='4px'
                          />
                        </WizrFlexLayout>
                        <WizrFlexLayout flexDirection='column' alignItems='flex-start' background='white' textAlign='left' style={{ paddingTop: '16px' }}>
                          <WizrText type='body2'>{item?.Ticket_Subject}</WizrText>
                          <WizrText type='caption' >{item?.Description}</WizrText>
                          <StyledFlexSpacing
                            justifyContent='space-between'
                            flexDirection='column'
                            alignItems='center'
                            size={20}
                            background='white'
                          >
                            <WizrTag
                              background="grey"
                              data={item.Tags}
                              onClick={(e: any) => { }}
                            />
                          </StyledFlexSpacing>
                        </WizrFlexLayout>
                        <Divider style={{ margin: '10px 0px' }} />
                      </WizrFlexLayout>
                    </WizrFlexLayout>
                  )
                })
              }
            </WizrFlexLayout>
          </StyledCard>
        </WizrFlexLayout>

      </WizrFlexLayout>
    </div>)
}
export default DiscoverScreen

const StyledCollapse = styled(Collapse) <any>`
.ant-collapse-header{
  background: white !important;

}
.ant-collapse-content ant-collapse-content-active{
  background: white !important;

}`

const StyledIntentCard = styled(Card) <any>`
  background-color: #ffffff;
  min-width: 200px;
  height: fit-content;
  min-height: 66px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1) !important; 
  .ant-card-meta-detail {
    border-bottom: none;
    border-top: none;
  }
  .ant-card-body {
    text-align: start;
    padding: 18px;
    gap: 16px
  }
`


const Chart = (chartData: any, onFormSubmission1: any) => {

  const intentGraphColors = ['#6AB7DA', '#6874D9', '#6995DA', '#806BD9', '#C56BDA', '#DA6BCC'];
  const graphData: any = chartData.data
  const tags = chartData.tags

  const filterBasedOnTags = (value: any) => {        
    const defaultFilter = {
      Intent: [`${value.Intent}`],
      Tags: [`${value.tags}`]
    }
    dispatchFiltervalues(defaultFilter)
    onFormSubmission(defaultFilter)
  }

  const onFormSubmission = (newFilterValues: any) => {
    let sqlQuery = "SELECT * FROM ecommerce_analytics WHERE (Created_date_epoch_millis BETWEEN 1711909800000 AND 1712514600000) AND ";
    //the field values
    const queryObj = newFilterValues;
    // Loop through the keys and values in the object
    Object.entries(queryObj).forEach(([key, values]: any, index) => {
      // Add the opening parenthesis for each key
      sqlQuery += "(";
      // Loop through the values for each key and join them with OR condition
      values.forEach((value: any, i: any) => {
        // Add the key and value to the query
        sqlQuery += `${key} = '${value}'`;
        // Add OR condition if it's not the last value
        if (i < values.length - 1) {
          sqlQuery += " OR ";
        }
      });
      // Add the closing parenthesis for each key
      sqlQuery += ")";
      // Add AND condition if it's not the last key
      if (index < Object.keys(queryObj).length - 1) {
        sqlQuery += " AND ";
      }
    });
    const filteredQuery = {
      sql: `${sqlQuery} ORDER BY Created_date_epoch_millis ASC`
    }
    dispatchTicketBasedOnIntent(filteredQuery)
    dispatchFiltervalues(queryObj)
  }

  return (
    <>
      <WizrFlexLayout flexDirection='row' style={{ maxHeight: '60px' }}>
        <WizrFlexLayout background='white' flex={1}>
          <></>
        </WizrFlexLayout>
      </WizrFlexLayout>
      <WizrFlexLayout alignContent='flex-start' alignItems='flex-start' flexDirection='column' background='white' textAlign='left' style={{ gap: '20px', marginTop: '8px', flex: 'none' }}>
        <BarChart width={600} height={200} data={graphData}>
          <CartesianGrid stroke="#f0f0f0" horizontal={true} vertical={false} />
          <XAxis dataKey="CreatedDate" stroke="transparent" tickLine={false} tickMargin={10} tick={{ fill: '#bbb', textAnchor: 'middle' }} />
          <YAxis stroke="transparent" tick={{ fill: '#bbb' }} />
          <Label style={{
            textAnchor: "middle",
            fontSize: "130%",
            fill: "white",
          }} />
          <Bar dataKey="TotalCount" fill="#8884d8" barSize={25} >
            {graphData.map((_: any, index: any) => (
              <Cell key={`cell-${index}`} fill={intentGraphColors[index % 20]} />
            ))}
          </Bar>
        </BarChart>
      </WizrFlexLayout>
      <WizrText type='body2'>Common Tags</WizrText>
      <WizrFlexLayout background='white' alignContent='flex-start' flexDirection='row' style={{ padding: '10px', gap: '8px' }}>
        {tags?.map((item: any, key: any) => {
          return (
            <StyledIntentCard key={key} title={''} bordered={false} hoverable onClick={() => filterBasedOnTags(item)}>
              <WizrFlexLayout flexDirection='row' background='#FFFFFF' alignItems='flex-start' justifyContent='flex-start' style={{ gap: '5px', minWidth: '200px' }}>
                <WizrText type='body2'>{item.tags}</WizrText>
                {item.TagCount < item.TotalSentimentScore ? <WizrIcon name='trendUpRed' /> : <WizrIcon name='trendDownGreen' />}
                <WizrText type='body2' textColor={item.TagCount < item.TotalSentimentScore ? "#EF4444" : '#22C55E'}>{item.TagCount}%</WizrText>
              </WizrFlexLayout>
              <WizrFlexLayout alignItems='flex-start' justifyContent='flex-start' background='#FFF' style={{ gap: '16px', marginTop: '16px' }}>
                <WizrText type='caption'  >Sentiment: <WizrText type='caption' textColor={item.OverallSentimentScore.toFixed(1) > 70 ? "#22C55E" : item.OverallSentimentScore.toFixed(1) < 70 && item.OverallSentimentScore.toFixed(1) > 50 ? "#FBBF24" : "#EF4444"} style={{ fontSize: '16px' }}>{item.OverallSentimentScore.toFixed(1)}</WizrText></WizrText>
              </WizrFlexLayout>
            </StyledIntentCard>
          )
        })}
      </WizrFlexLayout>
    </>
  )
}
