import styled from 'styled-components'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrText from '../components/WizrText'
import { Tabs } from 'antd'
import TabPane from 'antd/es/tabs/TabPane'
import WizrButton from '../components/WizrButton'
import { useEffect, useState } from 'react'
import CreateNew from '../assets/icons/CreateNew'
import { useLocation, useNavigate } from 'react-router-dom'
import { render } from '@testing-library/react'
import Profile from './components/SettingsComponents/Profile'
import TenantListing from './components/SettingsComponents/TenantsListing'
import UserListing from './components/SettingsComponents/UserListing'
import Billing from './components/SettingsComponents/Billing'
import jwtDecode from 'jwt-decode'
import ProductListing from './components/SettingsComponents/ProductListing'
import { MODAL_TYPES, useWizrModalContext } from '../providers/modal/WizrModalProvider'
import RolesListing from './components/SettingsComponents/RolesListing'
import PackageListing from './components/SettingsComponents/PackageListing'
import { dispatchAccessList, dispatchCreateIntegration, dispatchIntegrationList, dispatchLLMModels, dispatchupdateUserView } from '../redux/actions/content'
import { useGetViewUserSelector, usePermissionValues } from '../redux/selectors/content'
import Integrations from './components/SettingsComponents/Integrations'
import { useAuth } from '../authContext'
import Accounts from './components/SettingsComponents/Accounts'

const StyledOuterFlex = styled(WizrFlexLayout)`
  padding-top: 20px;
`
const StyledTabs = styled(Tabs)`
  width: 100%;
  flex: 1;
  justify-content: space-between;
`

const settingsTabAll = [
  {
    key: 'profile',
    text: 'Profile',
  },
]
const settingsTabs1 = [
  {
    key: 'profile',
    text: 'Profile',
  },
  {
    key: 'products',
    text: 'Products',
  },
  {
    key: 'packages',
    text: 'Industry Templates',
  },
  {
    key: 'apiprovider',
    text: 'API Providers',
  },
  {
    key: 'userroles',
    text: 'Roles',
  },
  {
    key: 'tenant',
    text: 'Tenant',
  },

  {
    key: 'users',
    text: 'Users',
  },
  {
    key: 'accounts',
    text: 'Accounts',
  },
  {
    key: 'ai&db',
    text: 'AI & DB',
  },
  {
    key: 'integrations',
    text: 'Integrations',
  },

  {
    key: 'billing',
    text: 'Billing',
  },

]
const settingsTabSuperAdmin = [
  ...settingsTabAll,
  {
    key: 'products',
    text: 'Products',
  },
  {
    key: 'packages',
    text: 'Industry Templates',
  },
  {
    key: 'accounts',
    text: 'Accounts',
  },
  {
    key: 'ai&db',
    text: 'AI & DB',
  },
  {
    key: 'userroles',
    text: 'Roles',
  },
  {
    key: 'tenant',
    text: 'Tenant',
  },

  {
    key: 'users',
    text: 'Users',
  },
  {
    key: 'integrations',
    text: 'Integrations',
  },

  {
    key: 'billing',
    text: 'Billing',
  },
]
const settingsTabTenantAdmin = [
  ...settingsTabAll,
  {
    key: 'users',
    text: 'Users',
  },
  {
    key: 'accounts',
    text: 'Accounts',
  },
  {
    key: 'ai&db',
    text: 'AI & DB',
  },
  {
    key: 'apiprovider',
    text: 'API Providers',
  },
  {
    key: 'integrations',
    text: 'Integrations',
  },

  {
    key: 'billing',
    text: 'Billing',
  },
]
const Settings = (): JSX.Element => {

  const { showModal, hideModal } = useWizrModalContext()
  const navigate = useNavigate()
  const [tab, setTab] = useState('')
  const [currentTenant, setCurrentTenant] = useState({})
  const [activeKey, setActiveKey] = useState("")
  const token = localStorage.getItem("id_token")
  const userView = useGetViewUserSelector()

  const permissionValues: any = usePermissionValues();
  // const permissionValues = ['view_settings', 'view_profile', 'view_products', 'view_packages', 'view_userroles', 'view_apiprovider', 'view_roles', 'view_tenant', 'view_users', 'view_accounts', 'view_integrations', 'view_billing', 'view_ai&db']

  useEffect(() => {
    //dispatchupdateUserView({})
  }, [])
  
  useEffect(() => {
    if (userView?.activeKey) {
      setActiveKey(userView?.activeKey)
      setTab(userView?.activeKey)
    }
    else {
      setActiveKey("profile")
      setTab("profile")
    }

  }, [userView])

  let settingsTabs;

  const createSettingsTab = (tabs: any[], permissions: string[]) => {
    
    return tabs.filter(tab => permissions.includes(`view_${tab.key}`));
  };

  if (token) {
    const tokenDecoded: { extension_UserRole: any } = jwtDecode(token)
    const userRole = tokenDecoded?.extension_UserRole
    switch (userRole) {
      case "superadmin":
        settingsTabs = settingsTabSuperAdmin
        break;
      case "tenantadmin":
        settingsTabs = settingsTabTenantAdmin
        break;
      default:{
        const filteredTabs = createSettingsTab(settingsTabs1, permissionValues)
        settingsTabs = filteredTabs
        break;
      }
    }
  }


  const onCreation = () => {

    switch (tab) {
      case 'users':
        navigate('/userdetails', { state: { action: 'create', tenant: currentTenant } })
        break
      case 'tenant':
        navigate('/tenantdetails', { state: { action: 'create' } })
        break
      case 'packages':
        showModal(MODAL_TYPES.CREATE_PACKAGES, {
          action: "create",
          details: {},
          onCancel: hideModal,
        })
        break
      case 'userroles':
        // showModal(MODAL_TYPES.CREATE_ROLES,{
        //   roleDetails:{},
        // onCancel: hideModal,
        // })
        navigate('/userroles', { state: { view: false } })


        break
      case 'integrations':
        showModal(MODAL_TYPES.CREATE_INTEGRATION, {
          integrationData: {},
          onFormSubmission: async (integrationData: any) => {
            hideModal()
            const redirect = localStorage.getItem('redirectCxSetttings')
            if (redirect === 'true') {
              navigate('/cxsettings')
            }
            const payload = {
              config_data: integrationData.config_data,
              display_name: integrationData.display_name,
              integration_key: integrationData.integration_key,
              integration_display_name: integrationData.integration_display_name,
              registered_emailid: integrationData.registered_emailid,
              status: "active"
            }
            await dispatchCreateIntegration(payload)

            dispatchIntegrationList()
            localStorage.setItem('redirectCxSetttings', 'false')
          },
          onCancel: hideModal,
        })
        break

    }
  }
  const renderBody = () => {
    switch (tab) {
      case 'users':
        return <UserListing setCurrentTenant={setCurrentTenant} />

      case 'tenant':
        return <TenantListing />

      case 'profile':
        return <Profile />

      case 'billing':
        return <Billing />

      case 'products':
        return <ProductListing />

      case 'userroles':
        return <RolesListing />
      case "packages":
        return <PackageListing />

      case 'integrations':
        return <Integrations />

      case 'accounts':
        return <Accounts />
      default:
        return <></>
    }
  }
  const { userPermission, hasAcces } = useAuth();

  const getAuthStatus = () => {
    switch (tab) {
      case 'users':
        return hasAcces('createusers_settings') === true ? false : true

      case 'tenant':
        return hasAcces('createtenants_settings') === true ? false : true

      // case 'profile':
      //   return <Profile />

      // case 'billing':
      //   return <Billing />

      //   case 'products':
      //   return <ProductListing />

      case 'userroles':
        return hasAcces('createroles_settings') === true ? false : true
      case "packages":
        return hasAcces('createpackages_settings') === true ? false : true

      case 'integrations':
        return hasAcces('createintegrations_settings') === true ? false : true
      default:
        return false
    }

  }
  return (
    <StyledOuterFlex>
      <WizrFlexLayout flexDirection='row' flex={1} style={{ gap: '20px' }}>
        <WizrFlexLayout flexDirection='row' flex={10} >
          <WizrFlexLayout style={{ padding: "10px 0px", maxWidth: "130px", minWidth: "110px" }}>
            <WizrText type='h6'>Settings</WizrText></WizrFlexLayout>
          <StyledTabs
            defaultActiveKey='1'
            onChange={async (key: any) => {
              if (tab === "users") {
                await dispatchupdateUserView({})
              }
              setTab(key)
              setActiveKey(key)

            }}
            destroyInactiveTabPane={false}
            activeKey={activeKey}

          >
            {settingsTabs && settingsTabs.map((promptMenuItem: any) => (
              <TabPane
                tab={<WizrText type='sub2'>{promptMenuItem.text}</WizrText>}
                key={promptMenuItem.key}
              >
                {/* <PromptCategory prompTemplates={prompTemplatesData} tab={tabName} /> */}
              </TabPane>
            ))}
          </StyledTabs>
        </WizrFlexLayout>
        <WizrFlexLayout flex={2} alignContent='flex-end' alignItems='flex-end'>
          {tab === 'tenant' || tab === 'users' || tab === 'userroles' || tab === "packages" || tab === "integrations" ? (
            <WizrButton
              type='primary'
              onClick={() => {
                onCreation()
              }}
              icon={<CreateNew fill='white' />}
              text={<WizrText type='button'>{tab !== 'integrations' ? `Create ${tab}` : `New Integrations`}</WizrText>}
              padding={'10px'}
              style={{ borderRadius: '20px' }}
              disabled={getAuthStatus()}
            ></WizrButton>
          ) : (
            <></>
          )}
        </WizrFlexLayout>
      </WizrFlexLayout>
      <WizrFlexLayout background='white' flex={11} style={{ borderRadius: "8px" }}>
        {renderBody()}
      </WizrFlexLayout>
    </StyledOuterFlex>
  )
}

export default Settings
