import { useLocation, useNavigate } from "react-router-dom"
import WizrButton from "../../../components/WizrButton"
import WizrFlexLayout from "../../../components/WizrFlexLayout"
import WizrText from "../../../components/WizrText"
import WizrUserJourney from "../../../components/WizrUserJourney"
import { icon } from "../../../icons/svgr.config"
import { ArrowLeftOutlined } from "@ant-design/icons"
import Hamburger from "../../../assets/icons/Hamburger"
import CreateNew from "../../../assets/icons/CreateNew"
import WizrTable from "../../../components/WizrTable"
import styled from "styled-components"
import CompanyIcon from "../../../assets/icons/CompanyIcon"
import TextArea from "antd/es/input/TextArea"
import WizrTextArea from "../../../components/WizrTextArea"
import { useEffect, useState } from "react"
import { dispatchCompanyInfo, dispatchFeatureSettings } from "../../../redux/actions/content"
import { useContentLoadingSelector, useFeatureSettingSelector } from "../../../redux/selectors/content"
import { MODAL_TYPES, useWizrModalContext } from "../../../providers/modal/WizrModalProvider"
import { set } from "react-hook-form"

const StyledWizrTable = styled(WizrTable)`
  .ant-table-wrapper {
    width: 100% !important;
  }
  .ant-table-thead {
    background-color: #f2f4f9;
    border-spacing: 0px;
  }
  .ant-table-tbody > tr {
    background-color: #ffffff;
    margin-bottom: 0px;
  }

  .ant-table table {
    border-spacing: 0 5px;
  }
`


const Company = () => {
  const featureData = useFeatureSettingSelector()
  const loading = useContentLoadingSelector()
  useEffect(() => {
    dispatchFeatureSettings()
    setCompanyDetails(featureData?.company_settings?.company_details)
  }, [featureData?.company_settings?.company_details])

  const navigate = useNavigate()
  const location = useLocation()
  const { showModal, hideModal } = useWizrModalContext()

  const state = location?.state
  const type = state?.type
  const persona = featureData?.company_settings?.persona?.filter((item: any) => item.status !== 'deleted')
  const details = featureData?.company_settings?.company_details
  const [companyDetails, setCompanyDetails] = useState(details ? details : '')
  const onSaveCompany = () => {
    const payload = {
      id: featureData?.id,
      company_details: companyDetails
    }
    dispatchCompanyInfo(payload)
  }
  const onCreatePersona = () => {
    showModal(MODAL_TYPES.CREATE_PERSONA, {
      persona: {},
      action: 'create',
      onCancel: hideModal,
    })
  }
  return (
    <WizrFlexLayout alignContent="flex-start" >
      <WizrFlexLayout justifyContent="space-between" flexDirection="row" flex={1.4}>
        <WizrUserJourney
          userJourney={[
            {
              title: (
                <WizrButton
                  padding='0px'
                  type='text'
                  text={<WizrText type='h6' textColor='#707276'>Cx Settings</WizrText>}
                  onClick={() => navigate(-1)}
                  icon={<Hamburger />}
                />
              ),
            },
            {
              title: (
                <WizrButton
                  padding='0px'
                  type='text'
                  text={<WizrText type='h6'>{type === 'company' ? 'About the company' : 'Agent Persona'}</WizrText>}
                  onClick={() => navigate(-1)}
                />
              ),
              isCurrent: true,
            },
          ]}
        />
        {type !== 'company' ? <WizrButton type="primary" icon={<CreateNew fill={'white'} />} style={{ marginTop: "10px", borderRadius: "25px" }}
          text={<WizrText type='button'>{`Add New`}</WizrText>} onClick={() => { onCreatePersona() }}> </WizrButton> : <></>}
      </WizrFlexLayout>
      <WizrFlexLayout flex={11} >
        {type === 'company' ?
          <WizrFlexLayout background="white" style={{ borderRadius: '20px' }}>
            <WizrFlexLayout flex={1} justifyContent="flex-end" alignContent="center" background="white" alignItems="center">

              <CompanyIcon />
            </WizrFlexLayout>
            <WizrFlexLayout flex={2} flexDirection="row" background="white">
              <WizrFlexLayout background="white">
                <></>
              </WizrFlexLayout >
              <WizrFlexLayout flex={2} background="white" textAlign="left" justifyContent="flex-start" style={{ gap: '10px', padding: '10px' }}>
                <WizrText type="body2">About the company</WizrText>
                <WizrTextArea background='#F2F4F9' border='0px #F2F4F9' minRows={8} value={companyDetails} onChange={(value: any) => { setCompanyDetails(value) }} placeholder="Briefly explain what the company is and what it does in 3 to 5 lines. This will be used to set the context to the AI model" />

              </WizrFlexLayout >
              <WizrFlexLayout flex={1} background="white">
                <></>
              </WizrFlexLayout>
            </WizrFlexLayout>
            <WizrFlexLayout flex={2} background="white" flexDirection="row">
              <WizrFlexLayout background="white" flex={1}>
                <></>
              </WizrFlexLayout >
              <WizrFlexLayout flex={2} background="white" alignItems="flex-end" >
                <WizrButton type="primary" text={'Save'} style={{ borderRadius: '20px' }} onClick={() => { onSaveCompany() }} disabled={loading}> </WizrButton>
              </WizrFlexLayout >
              <WizrFlexLayout flex={1} background="white">
                <></>
              </WizrFlexLayout>
            </WizrFlexLayout>
          </WizrFlexLayout>
          :
          <>
            <StyledWizrTable type='persona' dataSource={persona && persona.sort((a: any, b: any) => b.created_on - a.created_on)} pagination={false} size='small' />
          </>
        }
        <></>
      </WizrFlexLayout>

    </WizrFlexLayout>
  )
}
export default Company
