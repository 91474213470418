import { Popover, Row, Col, Tooltip } from 'antd'
import WizrButton from '../WizrButton'
import MoreIcon from '../../assets/icons/More'
import EditIcon from '../../assets/icons/EditIcon'
import DeleteIcon from '../../assets/icons/DeleteIcon'
import InfoIcon from '../../assets/icons/Info'
import { dispatchDeleteFile, dispatchListFiles, dispatchUpdateFile } from '../../redux/actions/content'
import WizrFlexLayout from '../WizrFlexLayout'
import WizrText from '../WizrText'
import { useWizrModalContext, MODAL_TYPES } from '../../providers/modal/WizrModalProvider'
import MoveIcon from '../../assets/icons/MoveIcon'
import Copy from '../../assets/icons/Copy'
import DownloadIcon from '../../assets/icons/DownloadIcon'
import VersionHistoryIcon from '../../assets/icons/VersionHistoryIcon'
import { wizrAzureConnectorInstance } from '../../utils/wizrAzureConnector'
import { dispatchNotificationState } from '../../redux/actions/common'
import { useAuth } from '../../authContext'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useGetTenantDetails } from '../../redux/selectors/content'

interface IFolderActionPopOver {
  detail: any
}

interface IFolderActionPopOverContent {
  details: any
  onEditAction: any
  onDeleteAction: any
  onDownloadAction: any
}

const FileActionPopOverContent = ({
  details,
  onEditAction,
  onDeleteAction,
  onDownloadAction,
}: IFolderActionPopOverContent): JSX.Element => {
  const { userPermission, hasAcces } = useAuth();
  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      <WizrButton
        icon={<EditIcon />}
        text='Edit'
        type='text'
        onClick={onEditAction}
        justifyContent='flex-start'
        disabled={ details?.upload_status === 'in progress' || hasAcces('update_file') === true ? false : true}
      />
      <WizrButton
        icon={<MoveIcon />}
        text='Move'
        type='text'
        onClick={() => {}}
        disabled={true}
        justifyContent='flex-start'
      />
      <WizrButton
        icon={<Copy />}
        text='Copy'
        type='text'
        onClick={() => {}}
        disabled={true}
        justifyContent='flex-start'
      />
      <WizrButton
        icon={<DownloadIcon />}
        text='Download'
        type='text'
        onClick={onDownloadAction}
        justifyContent='flex-start'
      />
      <WizrButton
        icon={<VersionHistoryIcon />}
        text='Version History'
        type='text'
        onClick={() => {}}
        disabled={true}
        justifyContent='flex-start'
      />
      <WizrButton
        icon={<DeleteIcon />}
        text='Delete'
        type='text'
        disabled={details.upload_status === 'In progress' || hasAcces('delete_file') === true ? false : true}
        onClick={onDeleteAction}
        justifyContent='flex-start'
      />
    </WizrFlexLayout>
  )
}

const FileActionPopOver = ({ detail }: IFolderActionPopOver): JSX.Element => {
  const { showModal, hideModal } = useWizrModalContext()
  const params = useParams()
  const tenantDetails = useGetTenantDetails()
  return (
    <Row style={{ justifyContent: 'space-evenly' }}>
      <Col>
        <Popover
          content={
            <FileActionPopOverContent
              onEditAction={() =>
                showModal(MODAL_TYPES.ADD_TAGS, {
                  fileDetail: detail,
                  onSubmit:async (tagValues: any) => {
                    await dispatchUpdateFile({
                      file_id: detail?.file_id,
                      file_name: detail?.file_name,
                      file_tags: tagValues,
                    })
                    if (params?.id){
                    await dispatchListFiles(params?.id)}
                    hideModal()
                  },
                  onCancel: () => hideModal(),
                })
              }
              onDeleteAction={() =>
                showModal(MODAL_TYPES.DELETE_FILE, {
                  fileName: detail?.file_name,
                  onDeleteConfirm: () => {
                    hideModal()
                    dispatchDeleteFile(detail?.file_id)
                  },
                  onCancel: hideModal,
                })
              }
              details={detail}
              onDownloadAction={async () => {
                const folderId = localStorage.getItem('folderId')
                const url= `https://enterprisecontent.blob.core.windows.net/${tenantDetails?.tenant_id}%2Finarchive%2F${folderId}%2F${detail?.file_path}/${detail?.file_name}?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=2024-11-30T12:08:29Z&st=2023-11-30T04:08:29Z&spr=https&sig=UYDPAn%2Fp7UtjvfjYW7XyjDwS9UliX%2FnUcxoCVINg%2BRc%3D&_=1717648107648`
                window.open(url, '_blank')
              }}
            />
          }
          title=''
          trigger='click'
        >
      <StyledButton icon={<MoreIcon />} text='' type='text' style={{height: '40px', width: '40px', borderRadius: '50px'}}/>
        </Popover>
      </Col>
    </Row>
  )
}
export default FileActionPopOver

const StyledButton = styled(WizrButton)`
border-radius: 50%;
width: 20px;
height: 20px;
padding-right: 8px;
justify-content: center;
align-items: center;
  /* Default styles */
  path {
    stroke: #999B9F; /* Default stroke color */
    transition: stroke 0.3s ease; /* Smooth transition for color change */
  }

  /* Hover styles */
  &:hover path {
    stroke: #1D1F22
  }
`;
