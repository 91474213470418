import { Popover, Select } from 'antd'
import WizrButton from '../WizrButton'
import WizrFlexLayout from '../WizrFlexLayout'
import { useWizrModalContext, MODAL_TYPES } from '../../providers/modal/WizrModalProvider'
import CreateNew from '../../assets/icons/CreateNew'
import { useNavigate } from 'react-router-dom'
import { dispatchCreateApp, dispatchUpdateSelectedTools } from '../../redux/actions/content'
import styled from 'styled-components'
import { useAuth } from '../../authContext'
import WizrText from '../WizrText'
import { useState } from 'react'
import { useWidgetListSelector } from '../../redux/selectors/content'
import { dropDownConverter } from '../../utils'

const CreateAppContent = (value: any): JSX.Element => {
    
  const [dropValue, setDropValue] = useState(value.value.value)

    function convertToDropdownData(data: any) {
        return data.map((item: any) => ({
            label: item.tool_name,
            value: item.id
        }));
    }

    const widgetList = useWidgetListSelector();

    const handleChange = (value:any)=>{
        dispatchUpdateSelectedTools(value)
        setDropValue(value)
    }
    
      
    return (
        <WizrFlexLayout textAlign='left' background='#FFF' style={{minWidth: '312px'}}>
         <StyledWizrTagInput
              mode='multiple'
               options={convertToDropdownData(widgetList)}
              value={dropValue}
            //   defaultValue={productDetails?.product_llm_models}
              //   onChange={(value: any) => {
              //     console.log('value', value)
              //     doUpdateFields('product_llm_models', value)
              //   }}
              onChange={(value: any) => handleChange(value)}
              maxTagCount={'responsive'}
            ></StyledWizrTagInput>
        </WizrFlexLayout>
    )
}

const AddToolPopover = (value: any) => {
const [tools,setTools]= useState([])
    const StyledWizrButton = styled(WizrButton)`
    border-radius: 20px;`
    const handleChange = (va: any) => {
        // handleChange1(va)
    }

    
    
    
    return (
        <Popover content={<CreateAppContent  value={value}/>} title='' trigger='click' style={{width: '312px'}}>
            <StyledPlusButton
                type='primary'
                icon={<CreateNew fill='white' />}
            />
        </Popover>
    )
}

export default AddToolPopover

const StyledPlusButton = styled(WizrButton)`
  background-color: black;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding-right: 8px;
  justify-content: center;
  align-items: center;
`

const StyledWizrTagInput = styled(Select)`
  /* Add your custom styles here */
  .ant-select-selection-placeholder {
    text-align: left;
  }
  width: 100%;
  .ant-select-selector {
    min-height: 50px;
    background: #f2f4f9 !important;
    border-radius: 16px !important;
    padding-left: 20px !important;
  }
  .ant-select-selection-item {
    background: white !important;
    border-radius: 20px !important;
    border: 1px solid black !important;
    gap: 8px !important;
    padding-right: 8px !important;
  }
  .ant-select-selection-overflow {
    gap: 8px !important;
  }
  overflow: auto; /* Hide any overflow content */
`
