import { styled } from 'styled-components'
import WizrText from '../WizrText'
import WizrButton from '../WizrButton'
import WizrFlexSpacing from '../WizrFlexSpacing'

interface IDeleteFolderForm {
  folderName: string
  folderSize?:any
  onDeleteConfirm?: () => void
  onCancel?: () => void
}

const TextContainer = styled.div`
  padding: 20px 0px;
  background: #fff;
`

const DeleteFolderForm = ({
  folderName,
  folderSize,
  onDeleteConfirm,
  onCancel,
}: IDeleteFolderForm): JSX.Element => {
  
  return (
    <WizrFlexSpacing background='#FFF'>
      <TextContainer>
        {/* <WizrText type='body1'>Are you sure you want to delete {folderName} ?</WizrText> */}
        {folderSize===0? <WizrText type='body1'>Are you sure you want to delete {folderName} ?</WizrText>:
         <WizrText type='body1'> The folder {folderName}  you are trying to delete has data , Do you want to proceed?</WizrText>}
      </TextContainer>
      <WizrFlexSpacing flexDirection='row' size='large' justifyContent='flex-end' background='#FFF'>
        <WizrButton type='text' text='Cancel' onClick={onCancel} style={{borderRadius: '20px'}}/>
        <WizrButton text='Delete' onClick={onDeleteConfirm} style={{borderRadius: '20px'}}/>
        
      </WizrFlexSpacing>
    </WizrFlexSpacing>
  )
}

export default DeleteFolderForm
