import { useEffect, useState } from 'react'
import { dispatchCreateWidget, dispatchGetWidgetDetails, dispatchGetWidgetTypes, dispatchListWidgets, dispatchUpdateWidget } from '../../../redux/actions/content'
import { useContentLoadingSelector, useGetWidgetDetails, useLLMModelSelector, useLoaderSelector, useWidgetListSelector, useWidgetTypesSelector } from '../../../redux/selectors/content'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrCard from '../../../components/Wizrcard'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import KnowledgeAssistWidget from './WidgetScreens/KnowledgeAssistWidget'
import APIWidget from './WidgetScreens/ApiWidget'
import WizrUserJourney from '../../../components/WizrUserJourney'
import { ArrowLeftOutlined } from '@ant-design/icons'
import WizrButton from '../../../components/WizrButton'
import WizrText from '../../../components/WizrText'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import WizrTextArea from '../../../components/WizrTextArea'
import WizrInput from '../../../components/WizrInput'
import CreateNew from '../../../assets/icons/CreateNew'
import { Button, Layout, Drawer } from 'antd'
import DeleteIcon from '../../../assets/icons/DeleteIcon'
import styled from 'styled-components'
// import Sider from 'antd/es/layout/Sider'
import TestArea from './WidgetScreens/TestArea'
import { v4 as uuidv4 } from 'uuid'
import WizrDropDown from '../../../components/WizrDropDown'

const { Sider } = Layout

const StyledWizrInput = styled(WizrInput)`
  width: 47%;
  background: #f2f4f9;
`

const StyledWizrButton = styled(WizrButton)`
  height: 20px;
  width: 20px;
  border-radius: 50px;
  padding: 5.833px;
  justify-content: center;
  align-items: center;
  padding-left: 13px;
`
// const StyledController = styled(Controller)`
//     background:#F2F4F9 !important;
//     `

const InputForm = ({ form1Methods }: any) => {
  const { register, setValue, control } = form1Methods
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'formSection1',
  })

  return (
    <WizrFlexLayout
      flexDirection='column'
      justifyContent='flex-start'
      background='#fff'
      alignItems='flex-start'
      style={{ gap: '7px' }}
    >
      <form style={{ width: '100%' }}>
        <WizrFlexLayout
          flexDirection='row'
          style={{ gap: '5px', height: '25px' }}
          background='#fff'
          justifyContent='flex-start'
        >
          <WizrText type='body2'>Input Required</WizrText>
          <StyledWizrButton
            icon={<CreateNew fill={'white'} />}
            style={{}}
            onClick={() => append({ key: 'Name', value: 'Type' })}
          />
        </WizrFlexLayout>
        {fields.map((item: any, index: any) => {
          return (
            <WizrFlexLayout
              flexDirection='row'
              justifyContent='flex-start'
              background='#fff'
              alignItems='flex-start'
              style={{ gap: '10px', margin: '5px 0px' }}
              key={item.id}
            >
              <Controller
                render={({ field }) => <WizrInput {...field} value={field.value} />}
                name={`formSection1.${index}.key`}
                control={control}
              />
              <Controller
                render={({ field }) => 
                // <WizrInput {...field} />
                <StyledWizrDropDown
                {...field}
                placeHolder='agent_type'
                options={[
                  { value: 'String', id: 'String' },
                  { value: 'Integer', id: 'Integer' },
                  { value: 'Float', id: 'Float'}
                ]}
                />
              }
                name={`formSection1.${index}.value`}
                control={control}
              />
              <Button
                type='text'
                icon={<DeleteIcon />}
                onClick={() => remove(index)}
                style={{ minHeight: '50px' }}
              />
            </WizrFlexLayout>
          )
        })}
      </form>
    </WizrFlexLayout>
  )
}

const WidgetCreation = () => {

  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const details = useGetWidgetDetails()
  const loading = useContentLoadingSelector()
  
  const id = location?.state?.id
  

  useEffect(() => {
    if(id){
      dispatchGetWidgetDetails(id)
    }
  }, [id])
  


  useEffect(() => {
    if(id && details?.id){      
      const newConvertedValue: any = convertToFormSection(details?.params?.fn_params?.parameters); 
      setValue1('formSection1', newConvertedValue);
      const payload = {
        tool_name: details?.tool_name,
        description: details?.params?.fn_params?.description,
        system_message: details?.params?.tool_params?.system_message,
        frequency_penalty: details?.params?.tool_params?.frequency_penalty,
        max_tokens: details?.params?.tool_params?.max_tokens,
        presence_penalty: details?.params?.tool_params?.presence_penalty,
        top_p: details?.params?.tool_params?.top_p,
        temperature: details?.params?.tool_params?.temperature,
        llm_folder_id_list: details?.params?.tool_params?.llm_folder_id_list,
        llm_model_id: details?.params?.tool_params?.llm_model_id,
        top_k: details?.params?.tool_params?.top_k,
        match_threshold: details?.params?.tool_params?.match_threshold,
      }
      setPromptInputState(payload)
    }
  }, [details])

  const convertToFormSection = (parameters: any) => {
    const { properties } = parameters;    
    return Object.entries(properties).map(([key, value]: any) => ({
      key: key.replace('_', ' '),
      value: value.type,
    }));
  };
  

  const [promptInputState, setPromptInputState] = useState<{ [key: string]: any }>()


  const renderWidgetCreationComponent = () => {
    switch (params.type) {
      case 'kb_search':
        return <KnowledgeAssistWidget promptInputState={promptInputState} setPromptInputState={setPromptInputState} />
      case 'api_call':
        return <APIWidget promptInputState={promptInputState} setPromptInputState={setPromptInputState} />
      default:
        return <div></div>
    }
  }

  const {
    register: register1,
    setValue: setValue1,
    getValues: getValues1,
    control: control1,
  } = useForm({
    defaultValues: {
      formSection1: [{ key: 'Name', value: 'Type' }],
    },
  })

  const doUpdateFields = (fieldName: any, fieldValue: any) => {
    setPromptInputState({
      ...promptInputState,
      [fieldName]: fieldValue,
    })
  }

  const LLMModelData= useLLMModelSelector()

  const saveTool = () => {

    const fieldArrayFormValues = getValues1().formSection1
    const result = convertToParameters(fieldArrayFormValues);    
    const finalresult = convertToDesiredFormat(promptInputState, result);  
    id ? dispatchUpdateWidget(finalresult) : dispatchCreateWidget(finalresult)

  }

  const convertToParameters = (array: any) => {
    const properties = array.reduce((acc: any, item: any) => {
      const formattedKey = item.key.toLowerCase().replace(/\s+/g, '_');
      acc[formattedKey] = { "type": item.value };
      return acc;
    }, {});

    return {
      "parameters": {
        "type": "object",
        "properties": properties
      }
    };
  };

  const convertToDesiredFormat = (obj1: any, obj2: any) => {
    const llmType = LLMModelData?.find((item:any)=>item?.id===obj1.llm_model_id)

    return {
      "id": id ? id : uuidv4(),
      "tool_type": "kb_search",
      "status": "active",
      "published": true,
      "tool_name": obj1.tool_name,
      "params": {
        "fn_params": {
          "name": obj1.tool_name.toLowerCase().replace(/\s+/g, '_'),
          "description": obj1.description,
          "parameters": obj2.parameters
        },
        "tool_params": {
          "llm_folder_id_list": obj1.llm_folder_id_list,
          "llm_model_id": obj1.llm_model_id,
          "llm_type":llmType?.llm_type,
          "frequency_penalty": obj1.frequency_penalty,
          "presence_penalty": obj1.presence_penalty,
          "max_tokens": parseInt(obj1.max_tokens), // Convert to integer if necessary
          "temperature": obj1.temperature,
          "top_p": obj1.top_p,
          "stop_sequences": [], // Assuming an empty array if not provided
          "system_message": obj1.system_message,
          "embedding_model_id": obj1.embedding_model_id,
          'top_k': obj1.top_k,
          'match_threshold': obj1.match_threshold,
        }
      }
    };
  };

  const [showTestArea, setShowTestArea] = useState(false)
  const [startParameters, setStartParameters] = useState()

  const closeTestArea = () => {
    setShowTestArea(false)
  }


  return (

    <div style={{ width: '100%' }}>
      <WizrFlexLayout background='#FFF'>
        <WizrFlexLayout>
          <WizrUserJourney
            userJourney={[
              {
                title: (
                  <WizrButton
                    icon={<ArrowLeftOutlined style={{ fontSize: '22px', marginRight: '10px' }} />}
                    type='text'
                    text={
                      <WizrText type='h6' textColor='#707276'>
                        {'Cx Settings'}
                      </WizrText>
                    }
                    onClick={() => navigate(-1)}
                  />
                ),
              },
              {
                title: (
                  <WizrFlexLayout style={{ paddingTop: '5px' }}>
                    <WizrText type='h6'>{'Tools/Widgets'}</WizrText>
                  </WizrFlexLayout>
                ),
              },
            ]}
          />
        </WizrFlexLayout>
        <WizrFlexLayout background='#FFF' textAlign='left' style={{ borderRadius: "16px", padding: '16px', gap: '8px', }} >
          <WizrText type='sub1'> { params.type === 'kb_search' ? ` Knowledge Assist` : `API` }</WizrText>
          <WizrFlexLayout background='#FFF' textAlign='left' style={{ width: "50%", position: "relative", left: "25%", gap: '16px' }}>
            <WizrText type='body2'>Name</WizrText>
            <StyledWizrInput onChange={(value: any) => doUpdateFields(`tool_name`, value)} value={promptInputState ? promptInputState?.tool_name : ""} />
            <InputForm
              form1Methods={{ register: register1, setValue: setValue1, control: control1, getValues: getValues1 }}
            />
            <WizrText type='body2'>Description</WizrText>
            <WizrTextArea background='#F2F4F9' border='0px #F2F4F9' minRows={5} onChange={(value: any) => doUpdateFields(`description`, value)} value={promptInputState ? promptInputState?.description : ""} />
            <WizrFlexLayout>
              {renderWidgetCreationComponent()}
            </WizrFlexLayout>

            <WizrFlexLayout justifyContent='flex-end' flexDirection='row'
              background='#FFF'>
              <WizrButton type='text' text='Test' onClick={() => { setShowTestArea(true) }} />
              <WizrButton text='Save' onClick={() => saveTool()} disabled={loading}/>
            </WizrFlexLayout>

          </WizrFlexLayout>
          <StyledSider
            width={650}
            title="" onClose={closeTestArea} open={showTestArea}
          >
            <TestArea onTestAreaClose={closeTestArea} parameters={getValues1().formSection1} />

          </StyledSider>
        </WizrFlexLayout>

      </WizrFlexLayout>

    </div>
  )
}

export default WidgetCreation

const StyledSider = styled(Drawer)`
right:0;
  .ant-layout-drawer-children {
    background: #fff;
    display: flex;
  }
  .ant-drawer-header-close-only {
  display: none;
  }
  padding: 10px 0px 20px 0px;
  
  background: #fff !important;
  .sc-egWZns hPXWyL{
    margin-left:20px;
  }
`

const StyledWizrDropDown = styled(WizrDropDown)`
  .ant-select-selector {
    height: 53px !important;
    border-radius: 10px;
  }
  .ant-select-selection-item{
    padding:11px !important;
    }
`
