import { useEffect } from "react";

const TestEmbed = () => {

    useEffect(() => {
        // Ensure the function is available globally
        if (typeof window.wizrAI_webChatLarge_show === 'function') {
          window.wizrAI_webChatLarge_show('embedded-app-container', 'aVO2_MxZI3_iSEya3YWLsF7fkNU-qVq6fPSGy_ZaEvc');
        } else {
          console.error('wizrAI_webChatLarge_show function is not defined');
        }
      }, []);
      
    return ( 
        // <div id='embedded-app-container' style={{width: '100%'}}>
        // </div>
        <div style={{width: '100%'}}>
            <iframe src={process.env.REACT_APP_HTML_WEBCHATLARGE} style={{width: '100%', height: '100%', border: 'none'}}/>
        </div>

    )
}

export default TestEmbed