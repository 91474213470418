import {
    PieChart, Pie, Legend, Tooltip, Cell, BarChart,
    Bar,
    XAxis,
    YAxis,
    ReferenceLine,
    Label,
    Text,
    Line,
    CartesianGrid
} from "recharts";
import WizrFlexLayout from "../../../components/WizrFlexLayout";
import WizrText from "../../../components/WizrText";
import styled from "styled-components";
import { Avatar, Card, Select } from "antd";
import { useState } from "react";

const StyledWizrTagInput = styled(Select)`
  /* Add your custom styles here */
  width: 70%;
  .ant-select-selection-placeholder {
    text-align: left;
  }
  .ant-select-selector {
    min-height: 50px;
    border: 0px solid black !important;
  }
  .ant-select-selection-item {
    background: #eaecf1 !important;
    border-radius: 20px !important;
    gap: 8px !important;
    padding-right: 8px !important;
  }
  .ant-select-selection-overflow {
    gap: 8px !important;
  }
  .ant-select-selection-item-remove {
    color: black !important;
  }
  .ant-select-arrow {
    color: black !important;
  }
  overflow: auto; /* Hide any overflow content */
`

const AgentInsights = () => {

    const Customersatisfaction = [
        { name: "Apr 23", uv: 15, },
        { name: "May 23", uv: 30, },
        { name: "Jun 23", uv: 25, },
        { name: "Jul 23", uv: 20, },
        { name: "Aug 23", uv: 12, },
        { name: "Sep 23", uv: 8, },
        { name: "Oct 23", uv: 5, },
        { name: "Nov 23", uv: 3, },
        { name: "Dec 23", uv: 10, },
        { name: "Jan 24", uv: 30, },
        { name: "Feb 24", uv: 15, },
    ];

    const data = [
        { name: "Varun", overall_tickets: 4000, ticket_backlog: 2400, ticket_resolution_time: 2400, response_time: 1800 },
        { name: "Dona", overall_tickets: 3000, ticket_backlog: 1398, ticket_resolution_time: 2210, response_time: 2750 },
        { name: "Roshan", overall_tickets: 2000, ticket_backlog: 980, ticket_resolution_time: 2290, response_time: 1500 },
        { name: "Clint", overall_tickets: 2780, ticket_backlog: 3908, ticket_resolution_time: 2000, response_time: 4400 },
        { name: "Jithin", overall_tickets: 1890, ticket_backlog: 4800, ticket_resolution_time: 2181, response_time: 3181 },
        { name: "Adnan", overall_tickets: 2390, ticket_backlog: 3800, ticket_resolution_time: 2500, response_time: 1536 },
        { name: "Hr", overall_tickets: 3490, ticket_backlog: 4300, ticket_resolution_time: 2100, response_time: 3456 },
    ];

    const piechartData = [
        { name: "John", value: 500 },
        { name: "Michael", value: 100 },
        { name: "Harry simmons", value: 200 },
        { name: "Emma", value: 300 },
        { name: "Brooklyn simmons", value: 400 },
    ];

    const COLORS = ["#5415BC", "#22C55E", "#4285F4", "#FFA319", "#39CEF3",];

    const total = data.reduce((acc: any, curr: any) => acc + curr.value, 0);
    const formattedData = piechartData.map((entry: any, index: any) => ({
        ...entry,
        percent: `${((entry.value / total) * 100).toFixed(2)}%`,
        fill: COLORS[index % COLORS.length], // Assign color from COLORS array
    }));

    const [selectedGraphValues, setSelectedGraphValues] = useState([
        'overall_tickets',
        'ticket_backlog',
        'ticket_resolution_time',
    ])

    const GraphValues = [
        {
            label: 'Overall Tickets',
            value: 'overall_tickets',
        },
        {
            label: 'Ticket Backlog',
            value: 'ticket_backlog',
        },
        {
            label: 'Ticket Resolution Time',
            value: 'ticket_resolution_time',
        },
        {
            label: 'Response Time',
            value: 'response_time',
        },
    ]

    const handleChange = (value: any) => {
        setSelectedGraphValues(value)
    }

    const referenceIndex = Customersatisfaction.findIndex((entry) => entry.name === "Sep 23");

    const CustomerSatisfactionBar = (props: any) => {

        const { x, y, width, height, value, referenceIndex } = props;
        const firstValue = value[1]


        let fill = "";
        if (firstValue < 50) {
            fill = props.index < referenceIndex ? "rgba(239, 68, 68, 0.6)" : "#EF4444";
        } else if (firstValue >= 50 && firstValue <= 60) {
            fill = props.index < referenceIndex ? "rgba(251, 191, 36, 0.6)" : "#FBBF24";
        } else {
            fill = props.index < referenceIndex ? "rgba(34, 197, 94, 0.6)" : "#22C55E"
        }
        return <rect x={x} y={y} width={35} height={height} fill={fill} />;
    };

    const CustomXAxisTick = (props: any) => {
        const { x, y, payload } = props;

        return (
            <g transform={`translate(${x},${y})`}>
                <foreignObject x={-30} y={-5} width={60} height={40}>
                    <Avatar style={{ backgroundColor: "black" }}>
                        {payload.value
                            ? payload.value?.split("")?.[0]?.toUpperCase()
                            : "NA"}
                    </Avatar>
                </foreignObject>
            </g>
        );
    };

    const getStrokeColor = (dataKey: any) => {
        // You can define a function to return different stroke colors based on the dataKey
        switch (dataKey) {
            case 'overall_tickets':
                return '#785DFB'
            case 'ticket_backlog':
                return '#FD765D'
            case 'ticket_resolution_time':
                return '#22C55E'
            case 'response_time':
                return '#FFA319'
            default:
                return '#000000' // Default stroke color
        }
    }
    const renderLines = () => {
        return selectedGraphValues.map((dataKey: any, index: any) => (
            //   <Line key={index} type='monotone' dataKey={dataKey} stroke={getStrokeColor(dataKey)} />
            <Bar key={index} yAxisId={dataKey === 'ticket_backlog' ? 'right' : 'left'} dataKey={dataKey} fill={getStrokeColor(dataKey)} barSize={26} />
        ))
    }

    const intentGraphColors = ['#6AB7DA', '#6995DA', '#6874D9', '#806BD9', '#C56BDA', '#DA6BCC', '#DA69AA', '#DA6888', '#DA6A69', '#DA8C6B', '#D86B3D' ];

    return (
        <WizrFlexLayout style={{ gap: '16px' }}>
            <WizrFlexLayout flexDirection="row" style={{ gap: '16px', minHeight: '350px' }}>
                <WizrFlexLayout background="#FFF" alignItems="flex-start" flex={3} style={{ padding: '16px', gap: '24px' }}>
                    <StyledWizrTagInput
                        mode='multiple'
                        options={GraphValues}
                        defaultValue={selectedGraphValues}
                        onChange={handleChange}
                        maxTagCount={'responsive'}
                    ></StyledWizrTagInput>
                    <BarChart
                        width={950}
                        height={500}
                        data={data}
                        margin={{
                            top: 10,
                            right: 10,
                            left: 10,
                            bottom: 10,
                        }}
                    >
                        <XAxis dataKey="name" tick={<CustomXAxisTick />} tickLine={{ stroke: '#E5E7EB' }} axisLine={{ stroke: '#E5E7EB' }} />
                        <YAxis yAxisId="left" orientation="left" stroke="#785DFB" label={{ value: 'Time Count', angle: -90, position: 'insideLeft', offset: 0 }} tickLine={false} />
                        <YAxis yAxisId="right" orientation="right" stroke="#22C55E" label={{ value: 'Time(Minutes)', angle: 90, position: 'insideRight', offset: 0 }} tickLine={false} />
                        <Tooltip />
                        <Legend
                            wrapperStyle={{ margin: '0px 0px 0px 0px', padding: '30px', textAlign: 'left' }}
                            align="left"
                        />
                        {renderLines()}
                    </BarChart>
                </WizrFlexLayout>
                <WizrFlexLayout background="#FFF" alignItems="flex-start" justifyContent="flex-start" style={{ padding: '16px' }}>
                    <WizrText type="sub2">Ticket Clore Rate</WizrText>
                    <PieChart width={320} height={500}>
                        <Pie
                            data={formattedData}
                            labelLine={false}
                            innerRadius={70}
                            fill="#8884d8"
                            dataKey="value"
                            paddingAngle={2}
                            cornerRadius={10}
                        >
                            {formattedData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.fill} />
                            ))}
                        </Pie>
                        <text
                            x={160}
                            y={230}
                            textAnchor="middle"
                            dominantBaseline="middle"
                            fill="black"
                        >
                            Closed Tickets 532
                        </text>
                        <Legend
                            formatter={(value: any, entry: any) => `${value} - ${entry.payload.value}`}
                        />
                    </PieChart>
                </WizrFlexLayout>
            </WizrFlexLayout>
            <WizrFlexLayout flexDirection="row" style={{ gap: '16px' }}>
                <WizrFlexLayout background="#FFF" alignItems="flex-start" style={{ padding: '16px', gap: '24px' }}>
                    <WizrText type="sub2">Perfomance by Customer Sentiment</WizrText>
                </WizrFlexLayout>
                <WizrFlexLayout background="#FFF" alignItems="flex-start" style={{ padding: '16px', gap: '16px' }}>
                    <WizrText type="sub2">Average Resolution Time</WizrText>
                    <BarChart width={650} height={350} data={Customersatisfaction}>
                        <XAxis dataKey="name" stroke="#DCDEE2" tick={<CustomXAxisTick />} tickLine={{ stroke: '#E5E7EB' }} axisLine={{ stroke: '#E5E7EB' }} />
                        <YAxis
                            domain={[0, 35]}
                            tickCount={8}
                            ticks={[0, 5, 10, 15, 20, 25, 30, 35]}
                            axisLine={{ stroke: '#D9D9D9' }}
                            tick={{ stroke: '#DCDEE2', strokeWidth: 0 }}
                            tickLine={false}
                        />
                        <Label style={{
                            textAnchor: "middle",
                            fontSize: "130%",
                            fill: "white",
                        }} />
                        <Bar dataKey="uv" fill="#8884d8" barSize={45} >
                            {Customersatisfaction.map((_: any, index: any) => (
                                <Cell key={`cell-${index}`} fill={intentGraphColors[index % 20]} />
                            ))}
                        </Bar>
                    </BarChart>
                </WizrFlexLayout>
            </WizrFlexLayout>
        </WizrFlexLayout>
    );
}

export default AgentInsights;