import { Button, Divider, Col, Row, Spin, Tabs, message as antdMessage, Avatar, Empty } from 'antd'
import WizrButton from '../components/WizrButton'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrText from '../components/WizrText'
import WizrUserJourney from '../components/WizrUserJourney'
import styled from 'styled-components'
import TabPane from 'antd/es/tabs/TabPane'
import WizrTag from '../components/WizrTags'
import WizrIcon from '../components/WizrIcon'
import WizrFlexSpacing from '../components/WizrFlexSpacing'
import WizrInput from '../components/WizrInput'
import React, { useEffect, useMemo, useState } from 'react'
import Search from '../assets/icons/Search'
import WizrAvatar from '../components/WizrAvatar'
import WizrChatIcon from '../assets/icons/WizrChatIcon'
import Markdown from 'react-markdown'
import Copy from '../assets/icons/Copy'
import DislikeIcon from '../assets/icons/DislikeIcon'
import LikeIcon from '../assets/icons/LikeIcon'
import {
  dispatchAgentConversationList,
  dispatchFeatureSettings,
  dispatchGetAgentConversationDetails,
  dispatchGetAutosolveConversation,
  dispatchGetBotRunHistory,
  dispatchGetBotRunHistoryOfConversation,
  dispatchUpdateAgentChatdetailsToEmpty,
  dispatchUpdateChatdetailsToEmpty,
} from '../redux/actions/content'
import FilterPopover from '../components/popovers/filterPopover'
import {
  useAgentConversationDetail,
  useAgentConversationsList,
  useAutosolveConversationDetail,
  useAutoSolveRunHistory,
  useAutoSolveRunHistoryConversations,
  useContentLoadingSelector,
  useFeatureSettingSelector,
  useGetTenantDetails,
  useInnerContentLoadingSelector,
} from '../redux/selectors/content'
import PdflogoIcon from '../assets/icons/Pdflogo'
import HistoryLink from '../assets/icons/Linksforhistory'
import { getAllValues, getDayRange, getFormatedDate, getMonthRangeConversation, getQuestion, getWeekRange } from '../utils'
import WizrMarkDown from '../components/WizrMarkDown'

const StyledTabs = styled(Tabs)`
  width: 100%;
  flex: 1;
  justify-content: space-between;
  padding: 8px;
`

const StyledWizrInput = styled(WizrInput)`
  width: 426px;
  height: 40px;
  padding: 8px 8px 8px 8px;
  border-radius: 30px;
  border: 1px solid var(--grayscale-300, #dcdee2);
  background: #fff;
`
const StyledChatContainer = styled.div<{ bulkRun?: boolean }>`
  position: relative;
  flex: 1;
  border-radius: 16px;
  padding: 0px 12px 12px 6px;
  background-color: #ffffff;
  align-content: flex-start;
  scrollbar-width: thin !important;
  scrollbar-color: #00c8ca white !important;

  &::-webkit-scrollbar {
    width: 8px !important; /* Width of the vertical scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: red !important; /* Color of the scrollbar thumb */
    border-radius: 4px !important; /* Radius of the scrollbar thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: red !important; /* Color of the scrollbar thumb on hover */
  }

  &::-webkit-scrollbar-track {
    background-color: white !important; /* Color of the scrollbar track */
  }
`

const StyledMessage = styled.div<{ isSent: boolean }>`
  flex-direction: 'row';
  margin-bottom: 10px;
  overflow: hidden;
`

const StyledMessageBubble = styled.div<{ isSent: boolean }>`
  overflow: hidden;
  max-width: 90%;
  padding: 10px;
  display: flex;
  text-align: ${({ isSent }) => (isSent ? 'left' : 'left')};
  flex-direction: column;
  align-items: ${({ isSent }) => (isSent ? 'end' : 'start')};
  align-content: ${({ isSent }) => (isSent ? 'end' : 'start')};
  whitespace: 'nowrap'; /* Allows wrapping within words */
  border-radius: ${({ isSent }) => (isSent ? '24px 0px 24px 24px' : '16px 16px 16px 0px')};
  background-color: ${({ isSent }) => (isSent ? '#1D1F22' : '#EAECF1')};
  color: ${({ isSent }) => (isSent ? 'white' : '#1D1F22')};
  justify-content: ${({ isSent }) => (isSent ? 'flex-end' : 'flex-start')};
  align-self: ${({ isSent }) => (isSent ? 'flex-end' : 'flex-start')};
`

const FlexLayout = styled(WizrFlexLayout) <{ selectd: boolean }>`
  cursor: pointer;
  background: ${({ selectd }) => (selectd ? 'transparent' : '#FFF')};
  align-items: flex-start;
`

const StyledFlexLayout = styled(WizrFlexLayout) <{ selectd: boolean }>`
  :hover {
    background: #eaecf1;
  }
`

const interactionTabs = [
  {
    key: 'autoSolveConversation',
    text: 'Auto-Solve Conversation',
  },
  {
    key: 'agentAssistTickets',
    text: 'Agent Assist Tickets',
  },
]

type IntervalName = '1 day' | '1 week' | '1 month' | 'All';

const uniqueTagList = ['1 day', '1 week', '1 month', 'All']

const style: React.CSSProperties = { padding: '0pX 8px 4px 8px' }

const channelItems = [
  { id: 1, label: 'Web Format-Large', checked: false },
  { id: 2, label: 'Web Chat', checked: false },
  { id: 3, label: 'Slack', checked: false },
  { id: 4, label: 'Api', checked: false },
  { id: 5, label: 'Whatsapp', checked: false },
  { id: 6, label: 'Gmail', checked: false },
  { id: 7, label: 'Teams', checked: false },
  { id: 8, label: 'Outlook', checked: false },
]

const feedbackItems: any = [
  {
    id: 0, label: <WizrFlexLayout flexDirection='row' justifyContent='flex-start' alignItems='center' background='transparent' style={{ gap: '4px' }} >
      <WizrText type='body2'>Down Vote</WizrText>
      <DislikeIcon />
    </WizrFlexLayout>, checked: false
  },
  {
    id: 1, label: <WizrFlexLayout flexDirection='row' justifyContent='flex-start' alignItems='center' background='transparent' style={{ gap: '4px' }} >
      <WizrText type='body2'>Up Vote</WizrText>
      <LikeIcon />
    </WizrFlexLayout>, checked: false
  },
]

const Conversations = ({ conversationData, conversationSelected }: any) => {

  const [selectedKey, setSelectedKey] = useState('')

  useEffect(() => {
    setSelectedKey('')
  }, [conversationData])

  const selectConversation = (selectdConversation: any) => {
    conversationSelected(selectdConversation)
  }

  function trimString(str: string, maxLength: number) {
    if (str.length > maxLength) {
        return str.slice(0, maxLength) + '...';
    }
    return str;
}

  return (
    <WizrFlexLayout background='#FFF' justifyContent='flex-start' textAlign='left'>
      <WizrText type='sub1'>Conversations</WizrText>
      <WizrFlexLayout
        background='#FFF'
        justifyContent='flex-start'
        alignItems='flex-start'
        style={{ maxHeight: '54px', minHeight: '54px' }}
      >
        <StyledTabs defaultActiveKey='1' destroyInactiveTabPane={false}>
          <TabPane tab={<WizrText type='sub2'>Bot responses</WizrText>}></TabPane>
        </StyledTabs>
      </WizrFlexLayout>
      {conversationData.length === 0 ? (
        <WizrFlexLayout background='#FFF' alignContent='center' justifyContent='center'>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Select filters to view history' />
        </WizrFlexLayout>
      ) : (
        <>
          {conversationData?.map((item: any, key: any) => {
            const userInput = JSON.parse(item?.agent_input)
            const selectd = selectedKey === key
            return (
              <StyledFlexLayout
                onClick={() => {
                  selectConversation(item)
                  setSelectedKey(key)
                }}
                background={selectd ? '#EAECF1' : '#FFF'}
                selectd={conversationSelected ? selectd : false}
                flex='none'
                flexDirection='column'
                key={key}
                style={{
                  borderTop: '1px solid rgba(204, 204, 204, 0.5)',
                  borderBottom: '1px solid rgba(204, 204, 204, 0.5)',
                  borderRadius: '2px',
                }}
              >
                <FlexLayout style={{ gap: '8px', padding: '8px', }} textAlign='left' selectd={selectd}>
                  <WizrFlexLayout
                    flexDirection='row'
                    justifyContent='space-between'
                    background='transparent'
                    textAlign='left'
                  >
                    <WizrText type='sub2'>{trimString(getQuestion(userInput?.user_query), 35)}</WizrText>
                    <WizrText type='caption'>{getFormatedDate(item?.response_time)}</WizrText>
                  </WizrFlexLayout>
                  <WizrText type='caption'>{item?.app_feature_name}</WizrText>
                  {/* <WizrText type='body2'>{userInput?.user_query}</WizrText> */}
                </FlexLayout>
              </StyledFlexLayout>
            )
          })}
        </>
      )}
    </WizrFlexLayout>
  )
}

const AgentAssistConversations = ({ conversationData, conversationSelected }: any) => {

  const [selectedKey, setSelectedKey] = useState('')

  const selectConversation = (selectdConversation: any) => {
    conversationSelected(selectdConversation)
  }

  useEffect(() => {
    setSelectedKey('')
  }, [conversationData])

  return (
    <WizrFlexLayout background='#FFF' justifyContent='flex-start' textAlign='left'>
      <WizrText type='sub1'>Conversations</WizrText>
      <WizrFlexLayout
        background='#FFF'
        justifyContent='flex-start'
        alignItems='flex-start'
        style={{ maxHeight: '54px', minHeight: '54px' }}
      >
        <StyledTabs defaultActiveKey='1' destroyInactiveTabPane={false}>
          <TabPane tab={<WizrText type='sub2'>Agent Assist responses</WizrText>}></TabPane>
        </StyledTabs>
      </WizrFlexLayout>
      {conversationData.length === 0 ? (
        <WizrFlexLayout background='#FFF' alignContent='center' justifyContent='center'>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Select filters to view history' />
        </WizrFlexLayout>
      ) : (
        <>
          {conversationData?.map((item: any, key: any) => {
            // const userInput = JSON.parse(item.bot_input);
            const selectd = selectedKey === key
            return (
              <StyledFlexLayout
                onClick={() => {
                  selectConversation(item)
                  setSelectedKey(key)
                }}
                background={selectd ? '#EAECF1' : '#FFF'}
                selectd={conversationSelected ? selectd : false}
                flex='none'
                flexDirection='column'
                key={key}
                style={{
                  borderTop: '1px solid rgba(204, 204, 204, 0.5)',
                  borderBottom: '1px solid rgba(204, 204, 204, 0.5)',
                  borderRadius: '2px',
                }}
              >
                <FlexLayout style={{ gap: '8px', padding: '8px' }} selectd={selectd}>
                  <WizrFlexLayout
                    flexDirection='row'
                    justifyContent='space-between'
                    background='transparent'
                  >
                    <WizrText type='sub2'>{item?.agent_name}</WizrText>
                    <WizrText type='caption'>{getFormatedDate(item?.created_on)}</WizrText>
                    {/* {convertMillisToDateString(item?.created_on)} */}
                  </WizrFlexLayout>
                  <WizrText type='caption'>{`Ticket ID: ${item?.ticket_id}`}</WizrText>
                  <WizrText type='caption'>{`Subject: ${item?.ticket_subject}`}</WizrText>
                </FlexLayout>
              </StyledFlexLayout>
            )
          })}
        </>
      )}
    </WizrFlexLayout>
  )
}

const Chat = ({ selectedConversation, chatHistory }: any) => {

  const innerContentLoading = useInnerContentLoadingSelector()
  const bot_personality = chatHistory[0]?.bot_config ? JSON.parse(chatHistory[0]?.bot_config) : ''

  let userInputQuestion: any = ''
  if(chatHistory.length > 0){
     userInputQuestion = JSON.parse(chatHistory[0]?.bot_input)
  }

  
  return (
    <>
      {!innerContentLoading ? (
        <WizrFlexLayout background='#FFF' justifyContent='flex-start' textAlign='left'>
          <WizrAvatar name={getQuestion(userInputQuestion?.user_query)} background='#FFF'></WizrAvatar>
          <Divider style={{ margin: '10px 0px' }} />
          <StyledChatContainer bulkRun={false}>
            <div style={{ height: '58vh', overflow: 'auto', padding: '10px 0px' }}>
              <StyledMessage isSent={true}>
                <WizrFlexLayout flexDirection='row' background='#FFF'>
                  <WizrChatIcon />
                  <WizrFlexLayout
                    background='transparent'
                    justifyContent={'flex-end'}
                    style={{ gap: '8px' }}
                  >
                    <StyledMessageBubble isSent={false}>
                      <WizrText textColor='black' type='body2'>
                        <WizrMarkDown>
                          {bot_personality?.bot_config?.bot_personality?.bot_messages?.welcome_message}
                        </WizrMarkDown>
                      </WizrText>
                    </StyledMessageBubble>
                  </WizrFlexLayout>
                </WizrFlexLayout>
              </StyledMessage>
              {chatHistory.map((item: any, key: any) => {
                const userInput = JSON.parse(item.bot_input)
                const bot_output = JSON.parse(item.bot_output)
                return (
                  <StyledMessage isSent={true} key={key}>
                    <WizrFlexLayout
                      background='transparent'
                      justifyContent={'flex-end'}
                      style={{ gap: '8px' }}
                    >
                      <StyledMessageBubble isSent={true}>
                        <WizrText textColor='white' type='body2'>
                          <WizrMarkDown>{getQuestion(userInput?.user_query)}</WizrMarkDown>
                        </WizrText>
                      </StyledMessageBubble>
                      <WizrFlexLayout
                        flexDirection='row'
                        justifyContent='flex-end'
                        background='white'
                        alignItems='center'
                      >
                        <WizrText type='caption'>{getFormatedDate(item?.created_on)}</WizrText>
                      </WizrFlexLayout>
                      <WizrFlexLayout
                        flexDirection='row'
                        justifyContent='flex-start'
                        background='white'
                        alignItems='start'
                      >
                        <WizrChatIcon />
                        <WizrFlexLayout background='#FFF'>
                          <StyledMessageBubble isSent={false}>
                            <WizrText textColor='black' type='body2'>
                              <WizrMarkDown>{bot_output?.agent_response}</WizrMarkDown>
                            </WizrText>
                          </StyledMessageBubble>
                          <WizrFlexLayout
                            flexDirection='row'
                            justifyContent='flex-start'
                            background='white'
                            alignItems='center'
                            overFlow='hidden'
                          >
                            <Button
                              type='link'
                              icon={<Copy color={'#999B9F'} />}
                              onClick={() => {
                                antdMessage.success('Copied to clipboard', 2)
                                // copy(message.text)
                              }}
                            />

                            <Button
                              type='link'
                              shape='circle'
                              icon={<DislikeIcon colour={item.feedback === 0 ? '#000' : '#999B9F'} />}
                              onClick={() => {
                                // dislike(message)
                              }}
                            />

                            <Button
                              type='link'
                              icon={<LikeIcon colour={item.feedback === 1 ? '#000' : '#999B9F'} />}
                              onClick={() => {
                                // like(message)
                              }}
                            />
                            <WizrText type='caption'>{getFormatedDate(item?.response_time)}</WizrText>
                          </WizrFlexLayout>
                        </WizrFlexLayout>
                      </WizrFlexLayout>
                    </WizrFlexLayout>
                  </StyledMessage>
                )
              })}
            </div>
          </StyledChatContainer>
        </WizrFlexLayout>
      ) : (
        <WizrFlexLayout background='#FFF'>
          <Spin />
        </WizrFlexLayout>
      )}
    </>
  )
}

const AgentAssistDetails = ({ selectedConversation, conversationDetail }: any) => {

  const innerContentLoading = useInnerContentLoadingSelector()
  const tenantDetails = useGetTenantDetails()

  const {
    autoTag,
    ticketSummary,
    knowledgeAssistant,
    pastTickets,
    faq,
    autoTagContents,
    knowledgeAssistantContent,
    faqContent,
    pastTicketsContent,
    generateResponse,
    generateResponseContent
  }: any = useMemo(() => {
    let autoTag = null
    let ticketSummary = null
    let knowledgeAssistant = null
    let pastTickets = null
    let faq = null
    let autoTagContents = null
    let knowledgeAssistantContent = null
    let faqContent = null
    let pastTicketsContent = null
    let generateResponse = null
    let generateResponseContent = null

    conversationDetail.forEach((item: any) => {
      if (item.feature_name === 'Auto Tag') {
        autoTag = item
        const data = item?.app_output
        const parsedData = JSON.parse(data)
        const parsedContent = JSON.parse(parsedData?.content)
        autoTagContents = parsedContent
      } else if (item.feature_name === 'Ticket Summary') {
        ticketSummary = item
      } else if (item.feature_name === 'Knowledge Assistant') {
        knowledgeAssistant = item
        knowledgeAssistantContent = JSON.parse(knowledgeAssistant?.app_output)
      } else if (item.feature_name === 'Past Tickets') {
        pastTickets = item
        pastTicketsContent = JSON.parse(item?.app_output)
      } else if (item.feature_name === 'FAQ') {
        faq = item
        faqContent = JSON.parse(item?.app_output)
      } else if (item.feature_name === 'Generate Response') {
        generateResponse = item
        generateResponseContent = JSON.parse(generateResponse?.app_output)
      }
    })

    return {
      autoTag,
      ticketSummary,
      knowledgeAssistant,
      pastTickets,
      faq,
      autoTagContents,
      knowledgeAssistantContent,
      faqContent,
      pastTicketsContent,
      generateResponse,
      generateResponseContent
    }
  }, [conversationDetail])  
  
  

  const [pastTicketOpen, setPastTicketOpen] = useState(0)

  return (
    <React.Fragment>
      {!innerContentLoading ? (
        <WizrFlexLayout background='#FFF' justifyContent='flex-start' textAlign='left'>
          <WizrAvatar name={selectedConversation?.agent_name} background='#FFF'></WizrAvatar>
          <Divider style={{ margin: '10px 0px' }} />
          <WizrFlexLayout
            background='#FFF'
            justifyContent='flex-start'
            style={{ padding: '16px', gap: '8px' }}
          >
            <WizrFlexLayout
              background='#FFF'
              alignItems='flex-start'
              justifyContent='flex-start'
              alignContent='left'
              textAlign='left'
              flex='none'
              style={{
                border: '1px solid #E5E7EB',
                borderRadius: '4px',
                padding: '4px 8px',
                gap: '8px',
              }}
            >
              <WizrFlexLayout
                background='#FFF'
                justifyContent='flex-start'
                alignItems='center'
                alignContent='center'
                flexDirection='row'
                style={{ gap: '4px', maxHeight: '30px' }}
              >
                {/* <WizrIcon name='downArrowColored' /> */}
                <WizrIcon name='envelope' />
                <WizrText type='body2'>{`Subject: ${selectedConversation?.ticket_subject}`}</WizrText>
              </WizrFlexLayout>
              <WizrText type='caption2' style={{ whiteSpace: 'pre-wrap' }}>
                <WizrMarkDown>{autoTag?.ticket_description}</WizrMarkDown>
              </WizrText>
              {/* <WizrFlexLayout textAlign='right' background='#FFF'>
                <WizrText type='caption'>19 jan 2024 09:00</WizrText>
              </WizrFlexLayout> */}
            </WizrFlexLayout>
            <WizrFlexLayout
              background='#FFF'
              alignItems='flex-start'
              justifyContent='flex-start'
              alignContent='left'
              textAlign='left'
              flex='none'
              style={{
                border: '1px solid #E5E7EB',
                borderRadius: '4px',
                padding: '4px 8px',
                gap: '8px',
              }}
            >
              <WizrFlexLayout
                background='#FFF'
                justifyContent='flex-start'
                alignItems='center'
                alignContent='center'
                flexDirection='row'
                flex='none'
                style={{ gap: '4px' }}
              >
                {/* <WizrIcon name='downArrowColored' /> */}
                <WizrIcon name='ticket' />
                <WizrText type='body2'>{`${ticketSummary?.feature_name}`}</WizrText>
              </WizrFlexLayout>
              <WizrText type='caption2'>
                <WizrMarkDown>{ticketSummary && JSON.parse(ticketSummary?.app_output)?.content}</WizrMarkDown>
              </WizrText>
              {/* <WizrFlexLayout textAlign='right' background='#FFF'>
                <WizrText type='caption'>19 jan 2024 09:00</WizrText>
              </WizrFlexLayout> */}
            </WizrFlexLayout>
            <WizrFlexLayout
              background='#FFF'
              flexDirection='row'
              alignItems='flex-start'
              justifyContent='flex-start'
              alignContent='left'
              textAlign='left'
              flex='none'
              style={{
                border: '1px solid #E5E7EB',
                borderRadius: '4px',
                padding: '4px 8px',
                gap: '8px',
              }}
            >
              {!autoTagContents ? <WizrFlexLayout textAlign='left' background='white' style={{ padding: "20px" }}>
                <WizrText type='body2'>No relevant Auto tag contents found </WizrText>
              </WizrFlexLayout> :
              <WizrFlexLayout background='#FFF' justifyContent='flex-start' alignItems='flex-start'>
                <Row style={{ width: '100%' }}>
                  <Col span={10}>
                    <WizrFlexLayout
                      background='#FFF'
                      alignItems='flex-start'
                      justifyContent='flex-start'
                      style={style}
                    >
                      <WizrText type='caption2' textColor='#707276'>Category</WizrText>
                      <WizrText type='body2'>{autoTagContents?.Category}</WizrText>
                    </WizrFlexLayout>
                  </Col>
                  <Col span={6}>
                    <WizrFlexLayout
                      background='#FFF'
                      alignItems='flex-start'
                      justifyContent='flex-start'
                      style={style}
                    >
                      <WizrText type='caption2' textColor='#707276'>Intent</WizrText>
                      <WizrText type='body2'>{autoTagContents?.Intent}</WizrText>
                    </WizrFlexLayout>
                  </Col>
                  <Col span={4}>
                    <WizrFlexLayout
                      background='#FFF'
                      alignItems='flex-start'
                      justifyContent='flex-start'
                      style={style}
                    >
                      <WizrText type='caption2' textColor='#707276'>Sentiment</WizrText>
                      <WizrTag
                        background={
                          autoTagContents?.Sentiment === 'Negative'
                            ? '#EF4444'
                            : autoTagContents?.Sentiment === 'Neutral'
                              ? '#FBBF24'
                              : '#22C55E'
                        }
                        // background="#FBBF24" '#22C55E' : '#EF4444'
                        data={[`${autoTagContents?.Sentiment}`]}
                        borderradius='4px'
                      />
                    </WizrFlexLayout>
                  </Col>
                  <Col span={4}>
                    <WizrFlexLayout
                      background='#FFF'
                      alignItems='flex-start'
                      justifyContent='flex-start'
                      style={style}
                    >
                      <WizrText type='caption2' textColor='#707276'>Priority</WizrText>
                      <WizrTag
                        background={
                          autoTagContents?.Priority === 'Low'
                            ? '#EF4444'
                            : autoTagContents?.Priority === 'Medium'
                              ? '#FBBF24'
                              : '#22C55E'
                        }
                        // background="#FBBF24" '#22C55E' : '#EF4444'
                        data={[`${autoTagContents?.Priority}`]}
                        borderradius='4px'
                      />
                    </WizrFlexLayout>
                  </Col>
                </Row>
                <Row style={{ width: '100%' }}>
                  <Col span={4}>
                    <WizrFlexLayout
                      background='#FFF'
                      alignItems='flex-start'
                      justifyContent='flex-start'
                      style={style}
                    >
                      <WizrText type='caption2' textColor='#707276'>Type</WizrText>
                      <WizrText type='body2'>{autoTagContents?.Type}</WizrText>
                    </WizrFlexLayout>
                  </Col>
                  <Col span={12}>
                    <WizrFlexLayout
                      background='#FFF'
                      alignItems='flex-start'
                      justifyContent='flex-start'
                      style={style}
                    >
                      <WizrText type='caption2' textColor='#707276'>Tags</WizrText>
                      <WizrFlexLayout flexDirection='row' background='#FFF'>
                        <div
                          style={{
                            background: '#F9FAFA',
                            border: '1px solid #707276',
                            borderRadius: '16px',
                            padding: '2px 5px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <WizrText type='caption'>{autoTagContents?.Tags}</WizrText>
                        </div>
                      </WizrFlexLayout>
                    </WizrFlexLayout>
                  </Col>
                  <Col span={4}>
                    <WizrFlexLayout
                      background='#FFF'
                      alignItems='flex-start'
                      justifyContent='flex-start'
                      style={style}
                    >
                      <WizrText type='caption2' textColor='#707276'>Escalate</WizrText>
                      <WizrText type='body2'>{autoTagContents?.Escalate}</WizrText>
                    </WizrFlexLayout>
                  </Col>
                  <Col span={4}></Col>
                </Row>
                {/* <WizrFlexLayout
                  justifyContent='right'
                  flexDirection='row'
                  background='#FFF'
                  textAlign='right'
                  style={{ padding: '8px' }}
                >
                  <WizrText type='caption'>19 jan 2024 09:00</WizrText>
                </WizrFlexLayout> */}
              </WizrFlexLayout>}
            </WizrFlexLayout>
            <WizrFlexLayout
              background='#FFF'
              flexDirection='column'
              alignItems='flex-start'
              justifyContent='flex-start'
              alignContent='left'
              textAlign='left'
              overFlow='hide'
              flex='none'
              style={{ border: '1px solid #E5E7EB', borderRadius: '4px' }}
            >
              <WizrFlexLayout
                justifyContent='flex-start'
                alignItems='center'
                alignContent='center'
                flexDirection='row'
                style={{
                  gap: '4px',
                  padding: '8px',
                  maxHeight: '30px',
                  minHeight: '30px',
                  background:
                    'linear-gradient(90.32deg, #A36BFE -23.96%, #568FFE 56.55%, #00BDFC 81.5%, #02F5DB 97.71%)',
                }}
              >
                {/* <WizrIcon name='downArrowColored' /> */}
                <WizrIcon name='receipt' />
                <WizrText type='body2' textColor='#FFF'>
                  Past Tickets
                </WizrText>
              </WizrFlexLayout>
              {pastTicketsContent && pastTicketsContent?.content.length === 0 ? <WizrFlexLayout textAlign='left' background='white' style={{ padding: "20px" }}>
                <WizrText type='body2'>No relevant Past Tickets found </WizrText>
              </WizrFlexLayout> :
                <>
                  {pastTicketsContent?.content.map((item: any, key: any) => {
                    const conversations = item?.metadata?.conversation
                      ? JSON.parse(item?.metadata?.conversation)
                      : []
                    const isLastItem = key === pastTicketsContent.content.length - 1
                    return (
                      <WizrFlexLayout
                        onClick={() => setPastTicketOpen(key)}
                        key={key}
                        background='#FFF'
                        justifyContent='flex-start'
                        alignItems='flex-start'
                        textAlign='left'
                        flex='none'
                        style={{ padding: '8px', gap: '8px', cursor: 'pointer' }}
                      >
                        <WizrFlexLayout
                          flexDirection='row'
                          background='#FFF'
                          justifyContent='flex-start'
                          alignContent='center'
                          alignItems='baseline'
                          style={{ maxHeight: '50px', gap: '10px' }}
                        >
                          {/* <WizrCheckbox
                        text={`Ticket ID: ${item?.metadata?.ticket_id}`}
                        isEnabled={false}
                        onChange={() => { }}
                      /> */}
                          {/* <WizrTag data={[`${item?.metadata?.tags}`]} /> */}
                          <div
                            style={{
                              background: '#F9FAFA',
                              border: '1px solid #707276',
                              borderRadius: '16px',
                              padding: '2px 5px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {' '}
                            <WizrText type='caption'> {item?.metadata?.category}</WizrText>
                          </div>
                          <div
                            style={{
                              background: '#F9FAFA',
                              border: '1px solid #707276',
                              borderRadius: '16px',
                              padding: '2px 5px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {' '}
                            <WizrText type='caption'> {item?.metadata?.intent}</WizrText>
                          </div>
                          {/* <WizrText type='caption'>{item?.metadata?.intent}</WizrText> */}
                          <WizrFlexLayout textAlign='right' background='#FFF'>
                            <WizrText type='caption'>{item?.metadata?.created_on}</WizrText>
                          </WizrFlexLayout>
                        </WizrFlexLayout>
                        <WizrText type='body2'>{item?.metadata?.subject}</WizrText>
                        <WizrText type='caption2'>{item?.metadata?.description}</WizrText>
                        {pastTicketOpen === key ? <Divider style={{ margin: '10px 0px' }} /> : <></>}
                        {pastTicketOpen === key ? (
                          <>
                            {conversations?.map((conversation: any, index: any) => {
                              return (
                                <>
                                  <React.Fragment key={index}>
                                    <WizrFlexLayout
                                      justifyContent='space-between'
                                      flexDirection='row'
                                      background='#FFF'
                                      flex='none'
                                    >
                                      <WizrText type='caption'>
                                        {conversation?.comment_user_role}
                                      </WizrText>
                                      <WizrText type='caption'>{item?.metadata?.closed_on}</WizrText>
                                    </WizrFlexLayout>
                                    <WizrFlexLayout
                                      justifyContent='Flex-start'
                                      alignContent='center'
                                      alignItems='center'
                                      textAlign='left'
                                      flexDirection='row'
                                      background='#FFF'
                                      flex='none'
                                      style={{ gap: '16px' }}
                                    >
                                      <WizrFlexLayout background='#FFF' style={{ minWidth: '40px' }}>
                                        <Avatar style={{ backgroundColor: 'black' }}>
                                          {conversation?.comment_user_role?.charAt(0).toUpperCase()}
                                        </Avatar>
                                      </WizrFlexLayout>
                                      <WizrText type='caption2' style={{ whiteSpace: 'pre-wrap', }}>
                                        <WizrMarkDown>{conversation?.comment_text}</WizrMarkDown>
                                      </WizrText>
                                    </WizrFlexLayout>
                                    <WizrFlexLayout
                                      justifyContent='Flex-start'
                                      alignContent='start'
                                      textAlign='left'
                                      flexDirection='row'
                                      background='#FFF'
                                      flex='none'
                                      style={{ gap: '8px' }}
                                    >
                                      {item?.metadata?.tags &&
                                        item?.metadata?.tags
                                          .split(',')
                                          .map((tag: string, index: number) => (
                                            <div
                                              key={index} // Adding a key for each element in the array
                                              style={{
                                                background: '#EAECF1',
                                                border: '0px solid #707276',
                                                borderRadius: '16px',
                                                padding: '2px 5px',
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <WizrText type='caption' textColor='black'>{tag.trim()}</WizrText>{' '}
                                              {/* trim to remove extra spaces */}
                                            </div>
                                          ))}
                                    </WizrFlexLayout>
                                  </React.Fragment>
                                </>
                              )
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                        {conversations.length > 0 ? (
                          <>{!isLastItem ? <Divider style={{ margin: '10px 0px' }} /> : <></>}</>
                        ) : (
                          <></>
                        )}
                      </WizrFlexLayout>
                    )
                  })} </>}
            </WizrFlexLayout>

            {tenantDetails?.tenant_name !== "SAASANT" ? <WizrFlexLayout
              background='#FFF'
              alignItems='flex-start'
              justifyContent='flex-start'
              alignContent='left'
              textAlign='left'
              flex='none'
              style={{ border: '1px solid #E5E7EB', borderRadius: '4px', gap: '8px' }}
            >
              <WizrFlexLayout
                justifyContent='flex-start'
                alignItems='center'
                alignContent='center'
                flexDirection='row'
                style={{
                  gap: '4px',
                  padding: '8px',
                  maxHeight: '30px',
                  minHeight: '30px',
                  background:
                    'linear-gradient(90.32deg, #A36BFE -23.96%, #568FFE 56.55%, #00BDFC 81.5%, #02F5DB 97.71%)',
                }}
              >
                {/* <WizrIcon name='downArrowColored' /> */}
                <WizrIcon name='receipt' />
                <WizrText type='body2' textColor='#FFF'>
                  Faq
                </WizrText>
              </WizrFlexLayout>
              {faqContent?.content?.map((item: any, key: any) => {
                const isLastItem = key === faqContent.content.length - 1
                return (
                  <WizrFlexLayout
                    key={key}
                    background='#FFF'
                    textAlign='start'
                    justifyContent='flex-start'
                    alignContent='start'
                    alignItems='start'
                    flex='none'
                    style={{ padding: '8px', gap: '8px' }}
                  >
                    <WizrFlexLayout
                      flexDirection='row'
                      textAlign='left'
                      justifyContent='flex-start'
                      background='#FFF'
                      alignItems='start'
                    >
                      <WizrText type='body2'>{item?.metadata?.question}</WizrText>
                    </WizrFlexLayout>
                    <WizrText type='caption2'>{item?.metadata?.answer}</WizrText>
                    {!isLastItem ? <Divider style={{ margin: '10px 0px' }} /> : <></>}
                  </WizrFlexLayout>
                )
              })}
            </WizrFlexLayout> : <></>}

            <WizrFlexLayout
              background='#FFF'
              alignItems='flex-start'
              justifyContent='flex-start'
              alignContent='left'
              textAlign='left'
              flex='none'
              style={{ border: '1px solid #E5E7EB', borderRadius: '4px', gap: '8px' }}
            >
              <WizrFlexLayout
                justifyContent='flex-start'
                alignItems='center'
                alignContent='center'
                flexDirection='row'
                style={{
                  gap: '4px',
                  padding: '8px',
                  maxHeight: '30px',
                  minHeight: '30px',
                  background:
                    'linear-gradient(90.32deg, #A36BFE -23.96%, #568FFE 56.55%, #00BDFC 81.5%, #02F5DB 97.71%)',
                }}
              >
                {/* <WizrIcon name='downArrowColored' /> */}
                <WizrIcon name='receipt' />
                <WizrText type='body2' textColor='#FFF'>
                  Ai Answer
                </WizrText>
              </WizrFlexLayout>
              <WizrFlexLayout
                background='#FFF'
                textAlign='start'
                justifyContent='flex-start'
                alignContent='start'
                alignItems='start'
                flex='none'
                style={{ padding: '8px', gap: '8px' }}
              >
                <WizrText type='body2'>
                  <WizrMarkDown>{knowledgeAssistantContent?.content}</WizrMarkDown>
                </WizrText>
                <WizrFlexLayout
                  background='#FFF'
                  flexDirection='row'
                  justifyContent='flex-start'
                  alignItems='center'
                  style={{ gap: '8px' }}
                >
                  <WizrText type='sub2' style={{ minWidth: '100px' }}>
                    Learn More:{' '}
                  </WizrText>
                  {knowledgeAssistantContent?.docs_metadata?.map((item: any, key: any) => {
                    const url: any = Object.values(item)[0];
                    const urlName = url.split('/').slice(-2).join('/')

                    return (
                      <>
                        {!url.startsWith("https://") && !url.startsWith("http://") ? <Button
                          style={{ width: 'auto' }}
                          type='link'
                          key={key}
                          icon={
                            <WizrFlexLayout
                              flexDirection='row'
                              style={{
                                padding: '5px 10px',
                                borderRadius: '16px',
                                borderWidth: '1px',
                                gap: '5px',
                              }}
                            >
                              <PdflogoIcon />
                              <WizrText type='body2'>{url}</WizrText>
                            </WizrFlexLayout>
                          }
                          onClick={() => { window.open(url, '_blank') }} // Pass URL to the handler function
                        /> : <Button
                          style={{ width: 'auto', margin: '8px 0px' }}
                          type='link'
                          key={key}
                          icon={
                            <WizrFlexLayout
                              flexDirection='row'
                              alignItems='center'
                              style={{
                                padding: '5px 10px',
                                borderRadius: '16px',
                                borderWidth: '1px',
                                gap: '5px',
                              }}
                            >
                              <HistoryLink />
                              <WizrText type='body2'>{urlName}</WizrText>
                            </WizrFlexLayout>
                          }
                          onClick={() => { window.open(url, '_blank') }} // Pass URL to the handler function
                        />}
                      </>
                    )
                  })}
                </WizrFlexLayout>
              </WizrFlexLayout>

              <WizrFlexLayout
                flexDirection='row'
                justifyContent='flex-end'
                background='white'
                alignItems='center'
                overFlow='hidden'
                // alignContent='flex-end'
                textAlign='end'
              >

                <Button
                  type='link'
                  shape='circle'
                  icon={<DislikeIcon
                    colour={knowledgeAssistant?.feedback === 0 ? '#000' : '#999B9F'}
                  />}
                  onClick={() => {
                    // dislike(message)
                  }}
                />

                <Button
                  type='link'
                  icon={<LikeIcon
                    colour={knowledgeAssistant?.feedback === 1 ? '#000' : '#999B9F'}
                  />}
                  onClick={() => {
                    // like(message)
                  }}
                />
              </WizrFlexLayout>
            </WizrFlexLayout>

            {generateResponse ? <WizrFlexLayout
              background='#FFF'
              alignItems='flex-start'
              justifyContent='flex-start'
              alignContent='left'
              textAlign='left'
              flex='none'
              style={{ border: '1px solid #E5E7EB', borderRadius: '4px', gap: '8px' }}
            >
              <WizrFlexLayout
                justifyContent='flex-start'
                alignItems='center'
                alignContent='center'
                flexDirection='row'
                style={{
                  gap: '4px',
                  padding: '8px',
                  maxHeight: '30px',
                  minHeight: '30px',
                  background:
                    'linear-gradient(90.32deg, #A36BFE -23.96%, #568FFE 56.55%, #00BDFC 81.5%, #02F5DB 97.71%)',
                }}
              >
                {/* <WizrIcon name='downArrowColored' /> */}
                <WizrIcon name='receipt' />
                <WizrText type='body2' textColor='#FFF'>
                  Generate Response
                </WizrText>
              </WizrFlexLayout>
              <WizrFlexLayout
                background='#FFF'
                textAlign='start'
                justifyContent='flex-start'
                alignContent='start'
                alignItems='start'
                flex='none'
                style={{ padding: '8px', gap: '8px' }}
              >
                <WizrText type='body2'>
                  <WizrMarkDown>{generateResponseContent?.content}</WizrMarkDown>
                </WizrText>
                <WizrFlexLayout
                  background='#FFF'
                  flexDirection='row'
                  justifyContent='flex-start'
                  alignItems='center'
                  style={{ gap: '8px' }}
                >
                  <WizrText type='sub2' style={{ minWidth: '100px' }}>
                    Learn More:{' '}
                  </WizrText>
                  {generateResponseContent?.docs_metadata?.map((item: any, key: any) => {
                    const url: any = Object.values(item)[0];
                    return (
                      <>
                        {!url.startsWith("https://") ? <Button
                          style={{ width: 'auto' }}
                          type='link'
                          key={key}
                          icon={
                            <WizrFlexLayout
                              flexDirection='row'
                              style={{
                                padding: '5px 10px',
                                borderRadius: '16px',
                                borderWidth: '1px',
                                gap: '5px',
                              }}
                            >
                              <PdflogoIcon />
                              <WizrText type='body2'>{`pdf ${key + 1}`}</WizrText>
                            </WizrFlexLayout>
                          }
                          onClick={() => { }} // Pass URL to the handler function
                        /> : <Button
                          style={{ width: 'auto', margin: '8px 0px' }}
                          type='link'
                          key={key}
                          icon={
                            <WizrFlexLayout
                              flexDirection='row'
                              alignItems='center'
                              style={{
                                padding: '5px 10px',
                                borderRadius: '16px',
                                borderWidth: '1px',
                                gap: '5px',
                              }}
                            >
                              <HistoryLink />
                              <WizrText type='body2'>{`Link ${key + 1}`}</WizrText>
                            </WizrFlexLayout>
                          }
                          onClick={() => { }} // Pass URL to the handler function
                        />}
                      </>
                    )
                  })}
                </WizrFlexLayout>
              </WizrFlexLayout>

              <WizrFlexLayout
                flexDirection='row'
                justifyContent='flex-end'
                background='white'
                alignItems='center'
                overFlow='hidden'
                // alignContent='flex-end'
                textAlign='end'
              >

                <Button
                  type='link'
                  shape='circle'
                  icon={<DislikeIcon
                    colour={generateResponseContent?.feedback === 0 ? '#000' : '#999B9F'}
                  />}
                  onClick={() => {
                    // dislike(message)
                  }}
                />

                <Button
                  type='link'
                  icon={<LikeIcon
                    colour={generateResponseContent?.feedback === 1 ? '#000' : '#999B9F'}
                  />}
                  onClick={() => {
                    // like(message)
                  }}
                />
              </WizrFlexLayout>
            </WizrFlexLayout> : <></>}


          </WizrFlexLayout>
        </WizrFlexLayout>
      ) : (
        <WizrFlexLayout background='#FFF'>
          <Spin />
        </WizrFlexLayout>
      )}
    </React.Fragment>
  )
}

const CustomerInteractions = () => {

  const loading = useContentLoadingSelector()
  // const conversationHistory = useAutoSolveRunHistory()
  const chatHistory = useAutoSolveRunHistoryConversations()
  const agentConversations = useAgentConversationsList()
  const agentHistoryDetail = useAgentConversationDetail()
  const featureSettings = useFeatureSettingSelector()
  const features = featureSettings?.feature_settings

  const [searchValue, setSearchValue] = useState('')
  const [tabName, setTabName] = useState('autoSolveConversation')
  const [selectedConversation, setSelectedConversation] = useState('')
  const [selectedAgentConversation, setSelectedAgentConversation] = useState('')

  const [uniqueAgentConversations, setUniqueAgentConversations] = useState([])
  const [originalAgentConversations, setOriginalAgentConversations] = useState([])

  const [autoSolveFilter, setAutosolveFilter] = useState([])
  const [agentAssistFilter, setAgentAssistFilter] = useState([])
  const [agentNames, setAgentNames] = useState<any>([])
  const [ticketIds, setTicketIds] = useState<any>([])
  const [filterAdded, setFilterAdded] = useState(false)
  const [searchArray, setSearchArray] = useState([])

  // state for each filters
  const [autoSolveFilterLength, setAutoSolveFilterLength] = useState(0);
  const [feedbackFilterLength, setFeedbackFilterLength] = useState(0);
  const [channelFilterLength, setChannelFilterLength] = useState(0);
  const [agentAssistFilterLength, setAgentAssistFilterLength] = useState(0);
  const [filterName, setFilterName] = useState('');
  const [agentFeedbackFilterLength, setAgentFeedbackFilterLength] = useState(0);
  const [agentNameFilterLength, setAgentNameFilterLength] = useState(0);
  const [ticketIdFilterLength, setTicketIdFilterLength] = useState(0);

  // autosolve conversation
  const autosolveConversations: any = useAutosolveConversationDetail();
  const [autosolveConvoHistory, setAutosolveConvoHistory] = useState([])
  // temp variable to handle the search from FE
  const [temperoryAutosolveHistory, setTemporaryAutosolveHistory] = useState([])

  // console.log('setAutosolveConvoHistory', autosolveConvoHistory);
  

  const [autosolvePagination, setAutosolvePagination] = useState<any>({
    "limit": 200,
    "offset": 0,
    "start_date": 1719253800000,
    "end_date": 1724524200000
  })
  const [pagination, setPagination] = useState<any>({
    "limit": 200,
    "offset": 0,
    "start_date": 1719253800000,
    "end_date": 1724524200000
  })

  // effwect to update pagination with todays value
  useEffect(() => {
    setShowDatePicker(false);
    const newRange = getMonthRangeConversation();    
    const newPagination = {
      "limit": 200,
      "offset": 0,
      "start_date": newRange?.currentMonthStartDate,
      "end_date": newRange?.currentMonthEndDate,
    }
    setPagination(newPagination)
    setAutosolvePagination(newPagination)
  }, [])

    // effect to set autosolve conv history values
    useEffect(() => {    
      const sortedConversations = autosolveConversations.sort((a: any, b: any) => new Date(b.created_on).getTime() - new Date(a.created_on).getTime());
      setAutosolveConvoHistory(sortedConversations)
      setTemporaryAutosolveHistory(sortedConversations)
    }, [autosolveConversations])

  useEffect(() => {
    const names = originalAgentConversations?.map((item: any) => item?.agent_name)
    const ids = originalAgentConversations
      ?.map((item: any) => item?.ticket_id)
      .filter((id) => id && id !== ' ')

    // Use a Set to get unique names and ids
    const uniqueNames = Array.from(new Set(names))
    const uniqueIds = Array.from(new Set(ids))

    const namesList = uniqueNames.map((item) => ({
      id: item,
      label: item,
    }))

    const idList = uniqueIds.map((item) => ({
      id: item,
      label: item,
    }))
    setAgentNames(namesList)
    setTicketIds(idList)
  }, [originalAgentConversations])

  let autoSolve: any = []
  let agentAssist: any = []

  // effect to get the filterdopdown values
  useEffect(() => {
    if (features) {
      autoSolve = features.filter((item: any) => item.setting_type === 'autosolve').filter((item: any) => item.bot_id !== '')      
      const autoSolveFilterDropdown = autoSolve.map((item: any, key: number) => ({
        id: item?.bot_id,
        label: item?.setting_name,
        checked: false,
      }))
      setAutosolveFilter(autoSolveFilterDropdown)
      agentAssist = features.filter((item: any) => item.setting_type === "agentassist")
      const agentAssistFilterDropdown = agentAssist.map((item: any, key: number) => ({
        id: item?.id,
        label: item?.setting_name,
        checked: false,
      }))
      setAgentAssistFilter(agentAssistFilterDropdown)
    }
  }, features)

  useEffect(() => {
    const agentAssistConversations = agentConversations
      .reduce((acc: any, current: any) => {
        if (!acc.some((item: any) => item.conversation_id === current.conversation_id)) {
          acc.push(current)
        }
        return acc
      }, [])
      .sort((a: any, b: any) => b.created_on - a.created_on)

    // setOrginal conversation only for the first time. 
    if (filterAdded) {
      setUniqueAgentConversations(agentAssistConversations);
      setSearchArray(agentAssistConversations)
    } else {
      setOriginalAgentConversations(agentAssistConversations)
    }

  }, [agentConversations])

  useEffect(() => {
    // dispatchGetBotRunHistory(agentId)
    const resultObject = {
      ...pagination,
      filter_type: 'default'
    };
    dispatchGetAutosolveConversation(resultObject)
    dispatchFeatureSettings()
    dispatchAgentConversationList(pagination)
  }, [])



  const getConversationDetails = (details: any) => {
    setSelectedConversation(details)
    dispatchGetBotRunHistoryOfConversation({
      bot_id: details?.bot_id,
      conversationId: details?.conversation_id,
    })
  }

  const getAgentConversationDetails = (details: any) => {
    setSelectedAgentConversation(details)
    dispatchGetAgentConversationDetails(details?.conversation_id)
  }

  const searchedApps = (searchValue: any) => {
    if (!searchValue) {
      dispatchAgentConversationList(pagination)
      setAutosolveConvoHistory(temperoryAutosolveHistory)
      return
    }
    if (tabName === 'autoSolveConversation') {      
      const filteredConversationsOnUserName = temperoryAutosolveHistory.filter((conversation: any) => {
        return conversation?.user_name?.toLowerCase().includes(searchValue.toLowerCase())
      })
      const filteredConversationsOnAppFeatureName = temperoryAutosolveHistory.filter((conversation: any) => {
        return conversation?.app_feature_name?.toLowerCase().includes(searchValue.toLowerCase())
      })
      const filteredConversationsOnUserQuery = temperoryAutosolveHistory.filter((conversation: any) => {
        const userInput = JSON.parse(conversation?.agent_input)
        return userInput?.user_query?.toLowerCase().includes(searchValue.toLowerCase())
      })
      const filteredConversations = [...filteredConversationsOnUserName, ...filteredConversationsOnAppFeatureName, ...filteredConversationsOnUserQuery];
      setAutosolveConvoHistory(filteredConversations)
    } else {
      const agentFilteredConversations = searchArray.filter((conversation: any) => {
        return conversation?.agent_name?.toLowerCase().includes(searchValue?.toLowerCase())
      })
      const subjectFilteredConversations = searchArray.filter((conversation: any) => {
        return conversation?.ticket_subject?.toLowerCase().includes(searchValue?.toLowerCase());
      });
      const filteredConversations = [...agentFilteredConversations, ...subjectFilteredConversations];
      setUniqueAgentConversations(filteredConversations)
    }
  }

  const handleFilter = (filters: any) => {
    if (filters.length === 0) {
      setAutoSolveFilterLength(0)
      const { bot_id, ...remainingPagination } = autosolvePagination;
      if (!('feedback' in remainingPagination)) {
        remainingPagination.filter_type = 'default'
      }
      setAutosolvePagination(remainingPagination)
      dispatchGetAutosolveConversation(remainingPagination)
    } else {      
      delete autosolvePagination?.filter_type
      const resultObject = {
        ...autosolvePagination,
        bot_id: filters.map((item: any) => item.id),
      };
      setFilterName(filters[0]?.label)
      setAutosolvePagination(resultObject)
      dispatchGetAutosolveConversation(resultObject)
      setAutoSolveFilterLength(filters?.length)
    }
    dispatchUpdateChatdetailsToEmpty([])
    setSelectedConversation('')
  }

  const handleFeedbackFilter = (filters: any) => {
    if (filters.length === 0) {
      setFeedbackFilterLength(0)
      setFilterAdded(true)
      const { feedback, ...remainingPagination } = autosolvePagination;
      
      if (!('bot_id' in remainingPagination)) {
        remainingPagination.filter_type = 'default'
      }
      setAutosolvePagination(remainingPagination)
      dispatchGetAutosolveConversation(remainingPagination)
    } else {
      setFilterAdded(true)
      delete autosolvePagination?.filter_type
      const resultObject = {
        ...autosolvePagination,
        feedback: filters.map((item: any) => item.id),
      };
      setAutosolvePagination(resultObject)
      dispatchGetAutosolveConversation(resultObject)
      setFeedbackFilterLength(filters?.length)
      dispatchUpdateChatdetailsToEmpty([])
      setSelectedConversation('')
    }
  }

  const handleFeatureFilter = (filters: any) => {  
    console.log('filters', filters);
      
    if (filters.length === 0) {
      setFilterAdded(true)
      setAgentAssistFilterLength(0)
      const { app_setting_name, ...remainingPagination } = pagination;
      dispatchAgentConversationList(remainingPagination)
      setPagination(remainingPagination)
    } else {
      setFilterAdded(true)
      setAgentAssistFilterLength(filters?.length)
      const resultObject = {
        ...pagination,
        app_setting_name: filters.map((item: any) => item.label)
      };      
      setPagination(resultObject)
      dispatchAgentConversationList(resultObject)
    }
  }

  const handleAgentFilter = (filters: any) => {
    if (filters.length === 0) {
      setAgentNameFilterLength(0)
      setFilterAdded(true)
      const { agent_name, ...remainingPagination } = pagination;
      setPagination(remainingPagination)
      dispatchAgentConversationList(remainingPagination)
    } else {
      setFilterAdded(true)
      const resultObject = {
        ...pagination,
        agent_name: filters.map((item: any) => item.label),
        length: 0
      };
      setPagination(resultObject)
      dispatchAgentConversationList(resultObject)
      setAgentNameFilterLength(filters?.length)
      dispatchUpdateChatdetailsToEmpty([])
      setSelectedConversation('')
      dispatchUpdateAgentChatdetailsToEmpty([])
    }
  }

  const handleFeedbackFilterAgent = (filters: any) => {

    if (filters.length === 0) {
      setFilterAdded(true)
      const { feedback, ...remainingPagination } = pagination;
      dispatchAgentConversationList(remainingPagination)
      setPagination(remainingPagination)
      setAgentFeedbackFilterLength(0)
    } else {
      setFilterAdded(true)
      const resultObject = {
        ...pagination,
        feedback: filters.map((item: any) => item.id),
      };
      setPagination(resultObject)
      dispatchAgentConversationList(resultObject)
      setAgentFeedbackFilterLength(filters.length)
    }
  }

  const handleTicketsFilter = (filters: any) => {
    if (filters.length === 0) {
      setFilterAdded(true)
      const { ticket_id, ...remainingPagination } = pagination;
      dispatchAgentConversationList(remainingPagination)
      setPagination(remainingPagination)
      setTicketIdFilterLength(0)
    } else {
      setFilterAdded(true)
      const resultObject = {
        ...pagination,
        ticket_id: filters.map((item: any) => item.label)
      };
      setPagination(resultObject)
      dispatchAgentConversationList(resultObject)
      setTicketIdFilterLength(filters?.length)
    }
  }

  const [showDatePicker, setShowDatePicker] = useState(false);

  const getDateRangeFilter = async (name: IntervalName) => {
    // setInterval(name)
    let newRange: any;

    switch (name) {
      case '1 day':
        newRange = getDayRange();
        setShowDatePicker(false);
        break;
      case '1 week':
        setShowDatePicker(false);
        newRange = getWeekRange();
        break;
      case '1 month':
        setShowDatePicker(false);
        newRange = getMonthRangeConversation();
        break;
      case 'All':
        setShowDatePicker(false);
        newRange = getAllValues();
        break;
      default:
        throw new Error('Invalid date range specified');
    }

    const newPagination = {
      "limit": 200,
      "offset": 0,
      "start_date": newRange?.currentMonthStartDate,
      "end_date": newRange?.currentMonthEndDate,
    }

    
    if(tabName === 'autoSolveConversation'){
      const newObject = { ...autosolvePagination, ...newPagination }
      setAutosolvePagination(newObject)
      dispatchGetAutosolveConversation(newObject)
    }else{
      const newObject = { ...pagination, ...newPagination }
      setPagination(newObject)
      dispatchAgentConversationList(newObject)
    }
  }

  const clearAll = () => {

    if(tabName === 'autoSolveConversation'){      
      const data = {
        "limit": 200,
        "offset": 0,
        "start_date": 1719253800000,
        "end_date": 1724524200000,
        'filter_type': 'default'
      }
      setAutosolvePagination(data)
      dispatchGetAutosolveConversation(data)
      // to clear autosolve conversation details
      dispatchUpdateChatdetailsToEmpty([])
      setSelectedConversation('')
      setAutoSolveFilterLength(0)
    }else{
      const data = {
        "limit": 200,
        "offset": 0,
        "start_date": 1719253800000,
        "end_date": 1724524200000
      }
      setPagination(data)
      dispatchAgentConversationList(data)
    }  
  }

  return (
    // <WizrText type="sub1">customer interactions</WizrText>
    <WizrFlexLayout justifyContent='flex-start' style={{ flex: 'none' }}>
      <WizrFlexSpacing
        flexDirection='row'
        justifyContent='space-between'
        alignItems='flex-start'
        style={{ maxHeight: '72px' }}
      >
        <WizrFlexLayout flexDirection='row' style={{ gap: '8px' }}>
          <WizrUserJourney userJourney={[{ title: 'Customer Interactions' }]} />
          <StyledTabs
            defaultActiveKey='1'
            destroyInactiveTabPane={false}
            onChange={(tabName: string) => setTabName(tabName)}
          >
            {interactionTabs.map((appMenuItem: any) => (
              <TabPane
                tab={<WizrText type='body2'>{appMenuItem.text}</WizrText>}
                key={appMenuItem.key}
              ></TabPane>
            ))}
          </StyledTabs>
        </WizrFlexLayout>
        <WizrFlexLayout
          flexDirection='row'
          alignContent='flex-end'
          alignItems='center'
          justifyContent='end'
          style={{ padding: '16px' }}
        >
          <WizrTag
            checkedValue={2}
            data={uniqueTagList}
            onClick={(name: any) => {
              getDateRangeFilter(name)
            }}
            background={'#EAECF1'}
            textColor='1D1F22'
            checkable={true}
          />
          <WizrIcon name='settings' />
        </WizrFlexLayout>
      </WizrFlexSpacing>
      <WizrFlexLayout
        flexDirection='row'
        justifyContent='space-between'
        alignItems='flex-start'
        style={{ maxHeight: '64px', padding: '8px' }}
      >
        <StyledWizrInput
          placeholder='Search for Conversation'
          value={searchValue}
          onChange={(value: any) => {
            setSearchValue(value)
            searchedApps(value)
          }}
          preComponent={<Search />}
          background='#FFF'
        />
        {tabName === 'autoSolveConversation' ? (
          <WizrFlexLayout
            flexDirection='row'
            justifyContent='flex-end'
            alignItems='flex-start'
            style={{ gap: '4px' }}
          >
            <WizrButton
              text='clear all'
              type='text'
              onClick={clearAll}
              style={{ borderRadius: '30px' }}
            />
            <FilterPopover
              key="autoSolveFilter"
              items={autoSolveFilter}
              applyFilters={handleFilter}
              placeHolder={'Bot'}
              singleSelect={true}
              filterName={filterName}
              appliedFilterLength={autoSolveFilterLength}
            />
            <FilterPopover
              items={feedbackItems}
              applyFilters={handleFeedbackFilter}
              placeHolder={'Feedback'}
              appliedFilterLength={feedbackFilterLength}
            />
            <FilterPopover
              items={channelItems}
              applyFilters={() => {}}
              placeHolder={'Channels'}
              appliedFilterLength={channelFilterLength}
            />
            {/* <FilterPopover items={ticketIds} applyFilters={handleFilter}  placeHolder={'TicketId'} /> */}
          </WizrFlexLayout>
        ) : (
          <WizrFlexLayout
            flexDirection='row'
            justifyContent='flex-end'
            alignItems='flex-start'
            style={{ gap: '4px' }}
          >
            <WizrButton
              text='clear all'
              type='text'
              onClick={clearAll}
              style={{ borderRadius: '30px' }}
            />
            <FilterPopover
              key="agentAssistFilter"
              items={agentAssistFilter}
              applyFilters={handleFeatureFilter}
              placeHolder={'Feature'}
              appliedFilterLength={agentAssistFilterLength}
            // agentAssistFilterName={agentAssistFilterName}
            />
            <FilterPopover
              items={feedbackItems}
              applyFilters={handleFeedbackFilterAgent}
              placeHolder={'Feedback'}
              appliedFilterLength={agentFeedbackFilterLength}
            />
            <FilterPopover
              items={agentNames}
              applyFilters={handleAgentFilter}
              placeHolder={'Agent Name'}
              appliedFilterLength={agentNameFilterLength}
            />
            <FilterPopover
              items={ticketIds}
              applyFilters={handleTicketsFilter}
              placeHolder={'TicketId'}
              appliedFilterLength={ticketIdFilterLength}
            />
          </WizrFlexLayout>
        )}
      </WizrFlexLayout>
      {tabName === 'autoSolveConversation' ? (
        <>
          {!loading ? (
            <WizrFlexLayout
              flexDirection='row'
              background='#FFF'
              justifyContent='flex-start'
              style={{ gap: '16px', padding: '16px', maxHeight: '80vh', borderRadius: '16px' }}
            >
              <WizrFlexLayout
                background='#FFF'
                justifyContent='flex-start'
                textAlign='left'
                style={{ flex: '1' }}
              >
                <Conversations
                  conversationData={autosolveConvoHistory}
                  conversationSelected={(details: any) => getConversationDetails(details)}
                />
              </WizrFlexLayout>
              <Divider type='vertical' style={{ margin: '10px 0px', height: 'auto' }} />
              <WizrFlexLayout style={{ flex: '2' }}>
                {selectedConversation ? (
                  <Chat selectedConversation={selectedConversation} chatHistory={chatHistory} />
                ) : (
                  <WizrFlexLayout background='#FFF' alignContent='center' justifyContent='center'>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description='Select a conversation for viewing history'
                    />
                  </WizrFlexLayout>
                )}
              </WizrFlexLayout>
            </WizrFlexLayout>
          ) : (
            <Spin />
          )}
        </>
      ) : (
        <></>
      )}
      {tabName === 'agentAssistTickets' ? (
        <>
          <WizrFlexLayout
            flexDirection='row'
            background='#FFF'
            justifyContent='flex-start'
            style={{ gap: '16px', padding: '16px', borderRadius: '16px' }}
          >
            <WizrFlexLayout
              background='#FFF'
              justifyContent='flex-start'
              textAlign='left'
              style={{ flex: '1' }}
            >
              <AgentAssistConversations
                conversationData={uniqueAgentConversations}
                conversationSelected={getAgentConversationDetails}
              />
            </WizrFlexLayout>
            <Divider type='vertical' style={{ margin: '10px 0px', height: 'auto' }} />
            <WizrFlexLayout style={{ flex: '2' }}>
              {selectedAgentConversation ? (
                <AgentAssistDetails
                  selectedConversation={selectedAgentConversation}
                  conversationDetail={agentHistoryDetail}
                />
              ) : (
                <WizrFlexLayout background='#FFF' alignContent='center' justifyContent='center'>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description='Select a conversation for viewing history'
                  />
                </WizrFlexLayout>
              )}
            </WizrFlexLayout>
          </WizrFlexLayout>
        </>
      ) : (
        <></>
      )}
    </WizrFlexLayout>
  )
}

export default CustomerInteractions
