import styled from "styled-components"
import WizrFlexLayout from "../../../components/WizrFlexLayout"
import { Card, Dropdown, MenuProps, Space, Tabs, TabsProps } from "antd"
import TabPane from "antd/es/tabs/TabPane"
import WizrText from "../../../components/WizrText"
import CustomerAnalytics from "./CustomerAnalytics"
import WizrImpactScreen from "./WIzrImpactScreen"
import CustomerHealth from "./CustomerHealth"
import { DownOutlined } from "@ant-design/icons"
import { useState } from "react"
import { dispatchCustomerHealthDetails } from "../../../redux/actions/content"
import AgentInsights from "./AgentInsights"
import TicketResolutionAnalytics from "./TicketResolutionAnalytics"


const StyledTabs = styled(Tabs)`
  width: 100%;
  flex: 1;
  justify-content: space-between;
`

const StyledTicketCard = styled(Card) <any>`
  background-color: #ffffff;
  width: 100%;
  .ant-card-meta-detail {
    border-bottom: none;
    border-top: none;
  }
  .ant-card-head {
    border-bottom: none;
    margin-bottom: 4px;
    text-align: start
  }
  .ant-card-body {
    text-align: center;
  }
`

const DashboardScreen = ({ onClickIntentsandsentiments, range, interval }: any) => {

  const TabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Customer Analytics',
      children: <CustomerAnalytics onClickIntentsandsentiments={onClickIntentsandsentiments} range={range} interval={interval} />
    },
    {
      key: '2',
      label: 'Ticket Resolution Analytics',
    },
    {
      key: '3',
      label: 'Agent Insights',
      children: <AgentInsights />
    },
    {
      key: '4',
      label: 'Customer Health',
      children: <CustomerHealth />
    },
    {
      key: '5',
      label: 'Wizr Impact',
      children: <WizrImpactScreen />
    },
  ];


  const sentimentDropdownItems: MenuProps['items'] = [
    {
      key: '1',
      label: 'All',
    },
    {
      key: '2',
      label: 'Group',
    },
    {
      key: '3',
      label: 'My Data',
    },
    {
      key: '4',
      label: 'Amazon',
    },
    {
      key: '5',
      label: 'Facebook',
    },
  ]

  const [selectedTab, setSelectedTab] = useState('1')

  return (<>
    <WizrFlexLayout alignContent='flex-start' flexDirection='row' justifyContent='space-between' style={{ gap: '20px', width: '100%' }}>
      <WizrFlexLayout flex={2}>
        <StyledTabs
          defaultActiveKey='1'
          onChange={(e: any) => { setSelectedTab(e); dispatchCustomerHealthDetails({}) }}
          destroyInactiveTabPane={false}
        >
          {TabItems && TabItems.map((promptMenuItem: any) => (
            <TabPane
              tab={<WizrText type='sub2'>{promptMenuItem.label}</WizrText>}
              key={promptMenuItem.key}
            >
            </TabPane>
          ))}

        </StyledTabs>
      </WizrFlexLayout>
      {selectedTab !== '4' ? <WizrFlexLayout flex={1} alignItems='center' flexDirection='row' alignContent='flex-end' justifyContent='end'>
        <WizrText type="body2" style={{ padding: '4px 8px 0px 0px' }}>Filtered by:</WizrText>
        <Dropdown trigger={['click']} menu={{ items: sentimentDropdownItems, selectable: true }} >
          <a onClick={(e) => e.preventDefault()}>
            <Space style={{ color: '#707276' }}>
              <WizrText type="sub2">{'My Accounts'}</WizrText>
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      </WizrFlexLayout> : <></>}
    </WizrFlexLayout>

    <WizrFlexLayout >
      {
      selectedTab === '1' ? <CustomerAnalytics onClickIntentsandsentiments={onClickIntentsandsentiments} range={range} interval={interval} /> :
      selectedTab === '2' ? <TicketResolutionAnalytics /> :
      selectedTab === '3' ? <AgentInsights /> :
      selectedTab === '4' ? <CustomerHealth /> : <WizrImpactScreen onClickIntentsandsentiments={onClickIntentsandsentiments} range={range} interval={interval} />
      }
    </WizrFlexLayout></>
  )
}

export default DashboardScreen