import { useState, useEffect } from 'react';
import styled from 'styled-components';
import WizrFlexLayout from '../../../../components/WizrFlexLayout';
import WizrText from '../../../../components/WizrText';
import WizrButton from '../../../../components/WizrButton';
import CreateNew from '../../../../assets/icons/CreateNew';
import { Card } from 'antd';
import AppActionPopOver from '../../../../components/popovers/AppAction';
import CreateAgentPopover from '../../../../components/popovers/CreateAgent';
import WizrTag from '../../../../components/WizrTags';
import { useNavigate, useParams } from 'react-router-dom';
import { dispatchBotDetails, dispatchCreateBot, dispatchDeleteAgent, dispatchFeatureSettings, dispatchGetAgentList, dispatchGetWidgetTypes, dispatchListWidgets, dispatchUpdateBot } from '../../../../redux/actions/content';
import { useAgentListSelector, useBotDetailsSelector, useFeatureSettingSelector, useWidgetListSelector } from '../../../../redux/selectors/content';
import { v4 as uuidv4 } from 'uuid'

const StyledPlusButton = styled(WizrButton)`
  background-color: black;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding-right: 8px;
  justify-content: center;
  align-items: center;
`;

const StyledTicketCard = styled(Card) <any>`
  background-color: ${(props) => (props.isClicked ? 'rgba(74, 222, 128, 0.3)' : '#ffffff')};
  border: ${(props) => (props.isClicked ? '1px solid #4ADE80' : '')};
  min-width: 302px;
  max-width: 302px;
  .ant-card-meta-detail {
    border-bottom: none;
    border-top: none;
  }
  .ant-card-head {
    border-bottom: none;
    text-align: start;
    padding: 10px !important;
  }
  .ant-card-body {
    text-align: center;
    padding: 15px;
  }
`;

const SetupBot = (): JSX.Element => {

  const params = useParams();

  const [clickedCardIndex, setClickedCardIndex] = useState<number | null>(null);
  const tags = ['Order Status', 'Ticket Tracking', 'Ticket Tracking', 'name'];
  const navigate = useNavigate();
  const agentList = useAgentListSelector();
  const widgetsList = useWidgetListSelector();


  const botDetails = useBotDetailsSelector()

  const featureSettings = useFeatureSettingSelector()
  const tenant_feature_id = featureSettings?.id
  const app_feature_id = params?.id


  const [currentFeatureSettings, setCurrentFeatureSetttings] = useState<{ [key: string]: any }>({})
  const CreateAgent = () => {
    navigate(`/createAgent/${params?.id}`);
  };

  useEffect(() => {
    dispatchFeatureSettings()
    dispatchListWidgets();
  }, [])

  const ListAgents = () => {
    const payload = {
      tenant_feature_id: tenant_feature_id,
      app_feature_id: app_feature_id
    }
    dispatchGetAgentList(payload);
  }

  useEffect(() => {
    if (featureSettings) {
      ListAgents();
    }
    dispatchGetWidgetTypes()

  }, [featureSettings]);


  const onDelete = async (details: any) => {
    await dispatchDeleteAgent(details?.id)
    const { agent_config, ...remainingPayload } = botDetails
    const botPayload = {
      bot_config: {
        ...remainingPayload,
        agent_id: '',
      },
      app_feature_id: params?.id,
      tenant_feature_id: featureSettings?.id,
    }
    await dispatchUpdateBot(botPayload)
    ListAgents();
  };


  const updateAgent = async (index: any) => {
    if (currentFeatureSettings?.bot_id) {
      const botPayload = {
        bot_config: {
          ...botDetails,
          agent_id: index,
        },
        app_feature_id: params?.id,
        tenant_feature_id: featureSettings?.id,
      }
      dispatchUpdateBot(botPayload, 'setupBot')
    }
    else {
      const payload = {
        bot_config: {
          id: uuidv4(),
          status: "active",
          bot_personality: currentFeatureSettings?.bot_config?.bot_personality,
          agent_id: index

        },
        app_feature_id: params?.id,
        tenant_feature_id: featureSettings?.id
      }

      await dispatchCreateBot(payload)
      dispatchFeatureSettings()
    }

  }

  const handleCardClick = (index: number) => {
    updateAgent(index)
    setClickedCardIndex(index);
  };

  const onEdit = (details: any) => {
    navigate(`/createAgent/${params?.id}`, {
      state: {
        data: details,
        action: 'edit'
      }
    })
  }

  const onView = (details: any) => {
    navigate(`/createAgent/${params?.id}`, {
      state: {
        data: details,
        action: 'view'
      }
    })
  }

  useEffect(() => {
    const featureSettingsData = featureSettings?.feature_settings
    // setTenantFeatureId(featureSettings?.id)
    const currentFeatureData = featureSettingsData?.find(
      (feature: any) => feature.id === params?.id,
    )
    setCurrentFeatureSetttings(currentFeatureData)
    if (currentFeatureData?.bot_id) {
      dispatchBotDetails(currentFeatureData?.bot_id)
    }
  }, [featureSettings])

  useEffect(() => {
    if (botDetails?.agent_id) {
      setClickedCardIndex(botDetails?.agent_id)
    }
  }, [botDetails])

  const findTheToolNames = (array1: any, array2: any) => {
    const tools = array1[0].tools;
    // Create a new array of tool names
    const toolNames = array2
      .filter((tool: any) => tools.includes(tool.id))
      .map((tool: any) => tool.tool_name);
    return toolNames
  }

  return (
    <WizrFlexLayout background="white" textAlign="left" style={{ gap: '10px', padding: '20px', height: '100vh' }}>
      <WizrFlexLayout
        justifyContent="flex-start"
        flexDirection="row"
        style={{ gap: '10px', paddingTop: '5px' }}
        background="#FFF"
      >
        <WizrText type="sub1">Agents</WizrText>
        {agentList?.length < 1 ? <StyledPlusButton onClick={CreateAgent} type="primary" icon={<CreateNew fill="white" />} /> : <></>}
      </WizrFlexLayout>
      <WizrFlexLayout
        flexDirection="row"
        background="#FFF"
        justifyContent="flex-start"
        style={{ gap: '20px', display: 'flex', flex: '1', flexWrap: 'wrap' }}
      >
        {agentList &&
          agentList.map((agent: any, index: any) => (
            <StyledTicketCard
              key={agent?.id}
              isClicked={clickedCardIndex === agent?.id}
              onClick={() => handleCardClick(agent?.id)}
              title={
                <WizrFlexLayout background={clickedCardIndex === agent?.id ? 'rgba(74, 222, 128, 0)' : "#FFFFFF"} flexDirection="row">
                  <WizrText type="sub2" style={{ padding: '10px 0px' }}>
                    {agent?.agent_name}
                  </WizrText>
                  <CreateAgentPopover details={agent} onEdit={onEdit} onDelete={onDelete} onView={onView} />
                </WizrFlexLayout>
              }
              bordered={true}
            >
              <WizrFlexLayout background={clickedCardIndex === agent?.id ? 'rgba(74, 222, 128, 0)' : "#FFFFFF"} textAlign="left">
                <WizrText type="overline">Tools</WizrText>
                <WizrFlexLayout
                  background={clickedCardIndex === agent?.id ? 'rgba(74, 222, 128, 0)' : "#FFFFFF"}
                  textAlign="left"
                  flexDirection="row"
                  justifyContent="flex-start"
                  style={{ display: 'flex', flexWrap: 'wrap' }}
                >
                  <WizrTag type="colorfull" checkable={false} data={findTheToolNames(agentList, widgetsList)} />
                </WizrFlexLayout>
              </WizrFlexLayout>
            </StyledTicketCard>
          ))}
      </WizrFlexLayout>
    </WizrFlexLayout>
  );
};

export default SetupBot;
