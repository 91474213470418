import React from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, applyEdgeChanges, getBezierPath } from 'reactflow';
import WizrButton from '../../../components/WizrButton';
import WizrText from '../../../components/WizrText';
import styled from 'styled-components'
import CloseIcon from '../../../assets/icons/Close';
import CreateAppPopover from '../../../components/popovers/CreateApp';
import AppDesignerPopover from '../../../components/popovers/appDesigner';
import useEdgeClick from '../AppDesignerHooks/useEdgeClick';

// import './buttonedge.css';

const StyledTextButton = styled(WizrText)`
  text-decoration: underline !important;
`

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  updateEdgeData,
  updateNodeFromEdge,
  isDisabled,
  setUnSavedDataPresent

}: any) {

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const handleEdgeClick = useEdgeClick(id);

  const onToolBarItemClick = (item: any) => {
    setUnSavedDataPresent(true)
    if (item === 'delete') {
      updateEdgeData((edges: any) => edges.filter((edge: any) => edge.id !== id))
    }else{
      handleEdgeClick(item);
    }
  }



  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all',
          }}
          className="nodrag nopan"
        >
          <AppDesignerPopover onToolBarItemClick={onToolBarItemClick}  isDisabled={isDisabled}/>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
