import { Button, Card, Collapse, Divider, Dropdown, Empty, Input, Pagination, Skeleton, Space } from 'antd'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrText from '../../../components/WizrText'
import styled from 'styled-components'
import WizrIcon from '../../../components/WizrIcon'
import WizrButton from '../../../components/WizrButton'
import { DownOutlined } from '@ant-design/icons'
import WizrTag from '../../../components/WizrTags'
import WizrFlexSpacing from '../../../components/WizrFlexSpacing'
import { useEffect, useMemo, useState } from 'react'
import { useContentLoadingSelector, useTicketsBasedonIntent } from '../../../redux/selectors/content'
import { dispatchTicketBasedOnIntent } from '../../../redux/actions/content'
import Search from '../../../assets/icons/Search'
import SearchSetting from '../../../assets/icons/SearchSetting'
import WizrAvatar from '../../../components/WizrAvatar'

const StyledCard = styled(Card) <any>`
  width: 85% !important;
  display: flex;
  flex-direction: column;
  .ant-card-body {
    padding: 16px;
  }
  background: white !important;
`
const StyledTextButton = styled(WizrText)`
  text-decoration: underline !important;
`
const StyledCollapse = styled(Collapse) <any>`
.ant-collapse-header{
  background: white !important;

}
.ant-collapse-content ant-collapse-content-active{
  background: white !important;

}`


const StyledFlexSpacing = styled(WizrFlexSpacing)`
  .ant-space-item {
    display: flex !important;
    width: 100% !important;
    flex-wrap: wrap;
  }
`

const SearchInput = styled(Input)`
  border-radius: 30px;
  border: 0px solid var(--surface-overlay, #f9f9f9);
  background: #fff;
  width: 512px;
  height: 40px;
`

const TicketListing = ({ filterModal }: any) => {
  const [ticketSort, setTicketSort] = useState('DESC')
  const [activeCollaps, setActiveCollapse] = useState([])
  const [currentPage, setCurrentPage] = useState(1); // Initialize current page to 1

  useEffect(() => {
    const allTicketsQuery = {
      sql: `SELECT *  FROM ecommerce_analytics  ORDER BY Created_date_epoch_millis ${ticketSort} LIMIT 20 `,
    }
    // const totalTicketNumber = {
    //   sql: `SELECT COUNT(*) AS total_count FROM ecommerce_analytics;`,
    // }
    // dispatchTotalTicketCount(totalTicketNumber)
    dispatchTicketBasedOnIntent(allTicketsQuery)

  }, [ticketSort])

  const handlePageChange = (page: any) => {
    setCurrentPage(page)
    const offset = page * 20
    const allTicketsQuery = {
      sql: `SELECT *  FROM ecommerce_analytics  ORDER BY Created_date_epoch_millis ${ticketSort} LIMIT 20 OFFSET ${offset}`,
    }
    dispatchTicketBasedOnIntent(allTicketsQuery)

  }

  const ticketsBasedOnIntents = useTicketsBasedonIntent() || []
  const totalTickets = ticketsBasedOnIntents?.totalDocs
  const loader = useContentLoadingSelector() ?? false
  const ticketsBasedOnIntentsArray = useMemo(() => {
    // Extract rows and columns inside useMemo
    const rows = ticketsBasedOnIntents?.resultTable?.rows || []
    const columns = ticketsBasedOnIntents?.resultTable?.dataSchema?.columnNames || []

    return rows.map((row: any) => {
      const obj: any = {}
      columns.forEach((columnName: any, index: any) => {
        if (columnName === 'Conversations') {
          const data = JSON.parse(row[index])
          obj[columnName] = data && data?.data

        } else {
          obj[columnName] = row[index]
        }

      })

      return obj
    })
  }, [ticketsBasedOnIntents])

  const collapseStructure =
    ticketsBasedOnIntentsArray &&
    ticketsBasedOnIntentsArray.map((item: any) => ({
      key: item?.Ticket_ID,
      label: (
        <WizrFlexLayout key={item?.Ticket_ID}>
          <WizrFlexLayout
            flexDirection='row'
            alignItems='space-between'
            background='white'
            justifyContent='space-between'
          >
            <WizrFlexLayout
              background='white'
              flexDirection='row'
              alignItems='flex-start'
              justifyContent='flex-start'
              flex={2}
              style={{ gap: '10px' }}
            >
              <WizrText type='sub1'>{`Ticketid : ${item?.Ticket_ID}`}</WizrText>
              <WizrText type='body2'>{item?.Category}</WizrText>
            </WizrFlexLayout>
            <WizrFlexLayout background='white' alignItems='flex-end' flex={1}>
              <WizrText type='body2'>{item?.Created_date_timestamp}</WizrText>
              <WizrText type='sub1'>{item?.Type}</WizrText>
            </WizrFlexLayout>
          </WizrFlexLayout>
          <WizrFlexLayout background='white'>
            <WizrFlexLayout flexDirection='row' justifyContent='flex-start' background='white'>
              <WizrTag
                background={
                  item.Ticket_Priority === 'Low'
                    ? '#22C55E'
                    : item.Ticket_Priority === 'Medium'
                      ? '#FBBF24'
                      : '#EF4444'
                }
                data={item?.Ticket_Priority?.split(',')}
                borderradius='4px'
              />
              <WizrTag
                background={
                  item.Sentiment === 'Positive'
                    ? '#22C55E'
                    : item.Sentiment === 'Neutral'
                      ? '#FBBF24'
                      : '#EF4444'
                }
                data={item?.Sentiment?.split(',')}
                borderradius='4px'
              />
            </WizrFlexLayout>
            <WizrFlexLayout
              flexDirection='column'
              alignItems='flex-start'
              background='white'
              textAlign='left'
              style={{ paddingTop: '16px' }}
            >
              <WizrText type='body2'>{item?.Ticket_Subject}</WizrText>
              <WizrText type='caption'>{item?.Description}</WizrText>
              <StyledFlexSpacing
                justifyContent='space-between'
                flexDirection='column'
                alignItems='center'
                size={20}
                background='white'
              >
                <WizrTag background='grey' data={item.Tags} onClick={(e: any) => { }} />
              </StyledFlexSpacing>
            </WizrFlexLayout>
            <Divider style={{ margin: '10px 0px' }} />
          </WizrFlexLayout>
        </WizrFlexLayout>
      ),
      showArrow: false,
      children: (
        <WizrFlexLayout key={item?.Ticket_ID} style={{gap:"10px"}} background='white'>
          {item?.Conversations && item?.Conversations.map((item: any, index: any) => {
            return (
              <WizrFlexLayout background='white' textAlign='left' key={index} style={{gap:"5px"}}>
                <WizrAvatar name={item?.comment_username} background='transparent'></WizrAvatar>

                <WizrText type='caption'>
                  {item?.comment_text}
                </WizrText>
              </WizrFlexLayout>
            )
          })}


        </WizrFlexLayout>
      ),
    }))

  const handleCollapseChange = (activeKeys: any) => {
    setActiveCollapse(activeKeys)
  }
  return (
    <WizrFlexLayout overFlow='hidden' alignItems='center'>


      <WizrFlexLayout alignItems='center' flex={2} style={{ padding: '10px' }}>
        <SearchInput
          placeholder='Search'
          prefix={<Search />}
          suffix={
            <Button shape='circle' icon={<SearchSetting />} onClick={() => filterModal()}></Button>
          }
        />
      </WizrFlexLayout>
      {loader ?
        (
          <WizrFlexLayout alignItems='center' justifyContent='center'>
            <Skeleton active />
          </WizrFlexLayout>
        ) :
        (
          <StyledCard>
            {ticketsBasedOnIntentsArray && ticketsBasedOnIntentsArray?.length !== 0 ? <WizrFlexLayout background='white' alignItems='flex-start' justifyContent='flex-start'>
              <WizrFlexLayout flexDirection='row' background='white'>
                <WizrFlexLayout flexDirection='row' background='white' justifyContent='flex-start'>
                  <WizrText type='sub2' style={{ padding: '4px 0px' }}>
                    All conversation{' '}
                  </WizrText>
                  <WizrIcon name='breakCrumbArrow' />
                </WizrFlexLayout>
                <WizrButton
                  text={<StyledTextButton type='link'>Clear all filters</StyledTextButton>}
                  type='link'
                />
              </WizrFlexLayout>

              <Divider style={{ margin: '10px 0px' }} />

              <WizrFlexLayout flexDirection='row' background='white'>
                <WizrText type='sub2'>{`${ticketsBasedOnIntentsArray && ticketsBasedOnIntentsArray?.length
                  } Conversations`}</WizrText>
                <Dropdown
                  trigger={['click']}
                  menu={{
                    items: [
                      { key: 'DESC', label: 'Latest' },
                      { key: 'ASC', label: 'Oldest' },
                    ],
                    selectable: true,
                    onSelect: (selectedItem) => setTicketSort(selectedItem.key),
                  }}
                >
                  <Space style={{ color: '#707276' }}>
                    <WizrText type='sub2'>{`${ticketSort === 'DESC' ? `Latest` : `Oldest`}`}</WizrText>
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </WizrFlexLayout>
              <Divider style={{ margin: '10px 0px' }} />

              <StyledCollapse
                accordion
                bordered={false}
                onChange={handleCollapseChange}
                items={collapseStructure}
                defaultActiveKey={activeCollaps}
              />

            </WizrFlexLayout> :
              <Empty style={{ height: '70vh' }} />

            }
          </StyledCard>)}
      {loader && ticketsBasedOnIntentsArray && ticketsBasedOnIntentsArray?.length !== 0 ? <></> :
        <Pagination defaultCurrent={currentPage} total={totalTickets} onChange={handlePageChange} showSizeChanger={false} showTotal={(total) => `Total ${total} items`}
        />}
    </WizrFlexLayout>
  )
}
export default TicketListing
