import styled from "styled-components";
// import WizrFlexLayout from "../components/WizrFlexLayout";
// import WizrInput from "../components/WizrInput";
// import WizrButton from "../components/WizrButton";
// import SentIcon from "../assets/icons/sent";
import { Card, Spin } from "antd";
import WizrText from "../../../../../components/WizrText";
import WizrFlexLayout from "../../../../../components/WizrFlexLayout";
import WizrButton from "../../../../../components/WizrButton";
import SentIcon from "../../../../../assets/icons/sent";
import WizrInput from "../../../../../components/WizrInput";
import WizrIconLogo from "../../../../../assets/icons/WizrIcon";
// import WizrText from "../components/WizrText";
// import WizrIcon2 from "../assets/icons/WizrIcon2";
// import Questions from "../assets/icons/Questions";

const StyledInput = styled(WizrInput)<{width:any}>`
  .ant-input {
    background-color: #f2f4f9 !important;
    font-family: Montserrat, cursive;
  }
  background-color: #f2f4f9;
  background: #f2f4f9 !important;

  border-width: 0.0625rem;
  border-radius: 2rem;
  box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  width:  ${({ width }) => (width  ? '68%' : '86%')};
  .ant-input-affix-wrapper-disabled {
    background: #f2f4f9 !important;
  }
`;

const StyledHelpCard = styled(Card)`
  background: #f2f4f9;
  .ant-card-body {
    padding: 0rem;
  }
`;

const ScreenWidth= window.innerWidth;

const WebFormatLargeTestArea = ({ errorMessage, testChatBot, setUserInput, userInput, streamingDataState,isStreaming,url,urlEnabled }: any) => {

  const openURL = (url: string): void => {
    let newURL = url;
  
    // Add protocol if it's missing
    if (!/^https?:\/\//i.test(newURL)) {
      newURL = 'http://' + newURL;
    }
  
    // Validate URL
    try {
      new URL(newURL);
    } catch (e) {
      console.error('Invalid URL:', newURL);
      return; // Don't open invalid URLs
    }
  
    // Open the URL in a new tab/window
    window.open(newURL, '_blank', 'noopener,noreferrer');
  };
  return (
    <div>
      {streamingDataState && streamingDataState.length > 0 ? (
        <WizrFlexLayout
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            background: errorMessage ? "white" : "transparent",
            padding: ScreenWidth<600
             ? "0.625rem 0rem 0.3125rem .5rem":errorMessage ? "0.625rem 0.625rem 0.3125rem 1.875rem" : "0.625rem 0.625rem 1.25rem 1.875rem",
          }}
        >
          <WizrFlexLayout
            flexDirection="row"
            background="transparent"
            style={{ padding: ScreenWidth >600?"0.125rem 0.125rem 0.3125rem 0.125rem":"0.125rem 0rem 0.3125rem 0.125rem", maxWidth: "97%" }}
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <StyledInput
            width={ScreenWidth>600}
              onPressEnter={testChatBot}
              value={userInput}
              onChange={(e: any) => {setUserInput(e)}}
              placeholder={"Ask Followup Query"}
              suffix={
                isStreaming?<Spin />:
                <WizrButton
                  style={{ paddingLeft: "10px" }}
                  shape="circle"
                  onClick={testChatBot}
                  icon={<SentIcon />}
                ></WizrButton>
              }
              disabled={isStreaming}
            ></StyledInput>
         
         {urlEnabled==='active' ? <>
            {ScreenWidth >600?<StyledHelpCard>
            <WizrFlexLayout style={{ padding: "0.625rem", gap: "0.625rem", borderRadius: "0.5rem" }}>
            <WizrText type="captionLarge" textColor="#0072EC">
                  Need more help ?{" "}
                </WizrText>
                <WizrButton
                  type="link"
                  style={{
                    borderWidth: "0.0625rem",
                    borderColor: "#1D1F22",
                    borderRadius: "1rem",
                  }}
                  
                  text={
                    <WizrText type="hint" textColor="#1D1F22">
                      Submit a Request
                    </WizrText>
                  }
                  onClick={() =>
                    // window.open(url, "_blank")
                    openURL(url)
                  }
                ></WizrButton>
              </WizrFlexLayout>
            </StyledHelpCard>:
           <WizrFlexLayout flexDirection="row" background="transparent" onClick={()=>{openURL(url)}} overFlow="hidden">
<WizrText type="captionLarge" >  
  {/* <Questions/> */}
  help
</WizrText>
           </WizrFlexLayout>
                // <WizrButton
                //   type="link"
                //   style={{
                //     // borderWidth: "0.0625rem",
                //     // borderColor: "#1D1F22",
                //     borderRadius: "50%", // Makes the button round
                //     height: "3.25rem",
                //     width: "3.25rem",
                //     background:"white" // Ensure the width is equal to the height for a perfect circle
                //   }}
                //   icon={<Questions/>}
                  
                //   onClick={() =>
                //     window.open(url, "_blank")
                //   }
                // ></WizrButton>
              }</>:<></>}
          </WizrFlexLayout>

          <WizrFlexLayout background="white" textAlign="center" style={{ maxWidth: "65%" }}>
            {errorMessage ? (
              <div style={{ color: "red", marginTop: "0.3125rem" }}>
              {errorMessage}
              </div>
            ) : (
              <></>
            )}
          </WizrFlexLayout>
        </WizrFlexLayout>
      ) : (
        <WizrFlexLayout
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            background: errorMessage ? "white" : "transparent",
            padding: ScreenWidth >600?"3.125rem":"1.7rem",
          }}
        >
          <WizrFlexLayout
            flexDirection="row"
            background="white"
            justifyContent="flex-end"
            alignItems="flex-end"
            style={{ gap: "0.25rem", maxHeight: "3.125rem" }}
            >
            <WizrText type="body3">Powered by</WizrText>
            <WizrIconLogo />
          </WizrFlexLayout>
        </WizrFlexLayout>
      )}
    </div>
  );
}

export default WebFormatLargeTestArea;
