import { useLocation, useNavigate, useParams } from "react-router-dom"
import WizrButton from "../components/WizrButton"
import WizrFlexLayout from "../components/WizrFlexLayout"
import WizrText from "../components/WizrText"
import WizrUserJourney from "../components/WizrUserJourney"
import WizrTable from "../components/WizrTable"
import { useEffect, useState } from "react"
import { dispatchAppPublished, dispatchbulkrunStatusApp } from "../redux/actions/content"
import { useBulkRunstatus, useappDataSelector } from "../redux/selectors/content"
import styled from "styled-components"

const StyledWizrTable = styled(WizrTable)`
  .ant-table-wrapper {
    width: 100% !important;
  }
  .ant-table-thead {
    background-color: #F2F4F9;
  }
  .green-row {
    background-color: #F0FDF4; /* Set your desired green color */
  }
  
  .red-row {
    background-color: #FEF2F2; /* Set your desired red color */
  }
  .white-row {
    background-color: #FFF; /* Set your desired red color */
  }

 
  `

const BulkRunList = ():JSX.Element=>{
    const navigate=useNavigate()
            const location = useLocation()
         const Id = useParams()
const app_id=Id?.id
const previousRunStatus = useBulkRunstatus()

const data = previousRunStatus?.map((item:any)=>{
    
})
const [name,setName]= useState('')

useEffect(() => {

    const payload = {
      app_id: app_id
    }
    dispatchbulkrunStatusApp(payload)
    dispatchAppPublished()
    
  }, [])
  const appData = useappDataSelector()

  useEffect(() => {
    const name = appData?.find((item: any) => item.app_id === app_id)?.app_name
    setName(name)
  }, [])
  const getRowClassName = (record:any) => {
    if (record.execution_status === 'Completed') {
      return 'green-row';
    } else if (record.execution_status === 'failed') {
      return 'red-row';
    }
    // Add more conditions if needed
    return "white-row"; // Default row class
  };
    return(
        <WizrFlexLayout alignContent="flex-start" justifyContent="flex-start">
                  <WizrFlexLayout >
        <WizrUserJourney userJourney={[{
          title: 
          <WizrButton
            padding='0px'
            type='text'
            text={<WizrText type='h8'>Apps</WizrText>}
            onClick={() =>
                navigate(-1)

            }
          />
        }, 
        { title: <WizrText type='h8'>{name}</WizrText>},
        { title: <WizrText type='h6'>{"View Bulk Runs" }</WizrText>}
        ]} />
      </WizrFlexLayout>
      <WizrFlexLayout flex={6}>
      <StyledWizrTable  type="bulkrun" dataSource={previousRunStatus} pagination={false} rowClassName={getRowClassName}
/>
      </WizrFlexLayout>
        </WizrFlexLayout>
    )
}

export default BulkRunList