import styled from 'styled-components'
import WizrFlexLayout from '../WizrFlexLayout'
import WizrText from '../WizrText'

const RoundTag = styled.div<{ color: string }>`
  width: 48px;
  height: 48px;
  gap: 0px;
  border-radius: 50px;
  opacity: 0px;
  background-color: ${(props) => props.color}; /* Set background color dynamically */
  display: flex;
  justify-content: center;
  align-items: center;
`

const CustomerHealthRoundTag = ({ text,type }: any): JSX.Element => {

    const getColorFromText = (text: number, type: string): string => {
        switch (type) {
          case 'sentiment':
            if (text >= 75) {
              return '#22C55E'; // Green color for text >= 75
            } else if (text >= 50) {
              return '#FBBF24'; // Yellow color for text >= 50 and < 75
            } else {
              return '#EF4444'; // Red color for text < 50
            }
          case 'escalation':
            if (text >= 20) {
              return '#EF4444'; // Red color for text >= 20 (bad escalation)
            } else if (text >= 5) {
              return '#FBBF24'; // Yellow color for text >= 5 and < 20 (fair escalation)
            } else {
              return '#22C55E'; // Green color for text < 5 (good escalation)
            }
          case 'resolution':
            if (text >= 36) {
              return '#EF4444'; // Yellow color for text >= 8 and < 36 (fair resolution)
            }  else if (text >= 8) {
                return '#FBBF24'; // Yellow color for text >= 5 and < 20 (fair escalation)
              }
            else {
              return '#22C55E'; // Green color for text < 8 (good resolution)
            }
          default:
            return 'white'; // Default color if no specific type matches
        }
      };
  
  const tagColor = getColorFromText(text,type)
  return (
    <WizrFlexLayout background='white' flexDirection='row' justifyContent='flex-start' alignItems='center' style={{gap:"10px"}}>
      <RoundTag color={tagColor} >
        <WizrText type='h5' textColor='#FFFFFF'>
          {text}
        </WizrText>
      </RoundTag>
      {type==='resolution'?<WizrText type='h5' textColor={tagColor}>hrs</WizrText>:<></>}
    </WizrFlexLayout>
  )
}
export default CustomerHealthRoundTag
