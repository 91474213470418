import NodeContainer from './NodeContainer'
import WizrButton from '../../../components/WizrButton'
import WizrInput from '../../../components/WizrInput'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import CreateNew from '../../../assets/icons/CreateNew'
import WizrText from '../../../components/WizrText'
import styled from 'styled-components'
import { useCallback, useEffect, useState } from 'react'
import WizrFlexSpacing from '../../../components/WizrFlexSpacing'
import { parameterOptions } from '../../../utils/appDesignConfigs'
import WizrTagInput from '../../../components/WizrTagInput'
import EditIcon from '../../../assets/icons/EditIcon'
import Copy from '../../../assets/icons/Copy'
import DeleteIcon from '../../../assets/icons/DeleteIcon'
import ReactFlow, { useNodes } from 'reactflow';
import { Button, Form ,Alert} from 'antd'
import WizrDropDown from '../../../components/WizrDropDown'
// import StartNodeEdit from '../AppEditTools/StartNodeEdit'

const TextInputSelection = ({ currentInputState, setCurrentInputState,setSaveEnabled,parameterAlert }: any): JSX.Element => {
  
  return (
      
  <ParameterHolder textAlign='left'>
    <WizrText type='body2'>Label</WizrText>

    <WizrInput
      value={currentInputState?.name}
      placeholder='Label'
      onChange={(e: string) => {setCurrentInputState({ ...currentInputState, name: e })
    setSaveEnabled(true)}}
    />
  </ParameterHolder>
   
  )
}

const SelectInputSelection = ({ currentInputState, setCurrentInputState ,setSaveEnabled,parameterAlert}: any): JSX.Element => {  
  
  return (
    <ParameterHolder textAlign='left'>
      <WizrText type='body2'>Label</WizrText>
      
      <WizrInput
        value={currentInputState?.name}
        placeholder='Label'
        onChange={(e: string) =>{ setCurrentInputState({ ...currentInputState, name: e })
        setSaveEnabled(true)}}
      />
      <WizrText type='body2'>Select Options </WizrText>
      <WizrTagInput
        onChange={(e: string[]) => setCurrentInputState({ ...currentInputState, modalTags: e })}
        open={false}
        value={currentInputState?.modalTags}
      />
    </ParameterHolder>
  )
}

const ShowParameters = ({ parameters, editParameter }: any): JSX.Element => {
  const parameterFinder=(key:any)=>{
    switch (key) {
      case 1:
        return "Short Text";
      case 2:
        return "Long Text";
      case 3:
        return "File Upload";
      case 4:
        return "Single Select";
      case 5:
        return "Multi Select"
      case 6:
        return "Image"
      case 7:
        return "Media upload"
      default:
        return null

    }

  }
  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item,
        value: item,
      }))
    return result
  }
  
  
  return (
    <ParametersContainer>
      {/* {parameters?.map((parameter: any, key: number) => {
        return (
          <ParameterHolder key={key}>
            <WizrFlexLayout flexDirection='row' justifyContent='space-between'>
              <WizrFlexLayout textAlign='left' flex={4}>
                <WizrText type='body2'>{parameter?.name}</WizrText>
              </WizrFlexLayout>
              <WizrFlexLayout flexDirection='row'>
                <Button type='text' icon={<EditIcon />} onClick={editParameter('edit', parameter, key)}/>
                <Button type='text' icon={<DeleteIcon />} onClick={editParameter('delete', parameter, key)}/>
              </WizrFlexLayout>
            </WizrFlexLayout>
          </ParameterHolder>
        )
      })} */}
      {parameters?.length > 0 &&
                parameters?.map((item: any, key: number) => {
                  const isUserQuery = item.name === "user_query";
                  switch (item.type) {
                    case 1:
                    case 2:
                    case 3:
                    case 6:
                    case 7:
                      return (
                        <>
                        {!isUserQuery  ? 
                        <>
                          <WizrFlexLayout
                            alignContent='left'
                            flexDirection='row'
                            justifyContent='space-between'
                          >
                            <WizrText type='body2'>{item.name}</WizrText>
                            <WizrText type='hint'>{parameterFinder(item?.type)}*</WizrText>
                          </WizrFlexLayout>
                          <WizrFlexLayout
                            flexDirection='row'
                            alignItems='center'
                            justifyContent='space-around'
                            style={{ gap: '3px' }}
                          >
                            <WizrInput value={item.name} disabled={true}  style={{height:"10px"}}/>
                          {/* <Button type='text' icon={<EditIcon />} onClick={editParameter('edit', item, key)}/>
                <Button type='text' icon={<DeleteIcon />} onClick={editParameter('delete', item, key)}/> */}
                          </WizrFlexLayout>
                        </>
                        : ' '}
                        </>
                      )
                    case 4:
                    case 5:
                      return (
                        <>
                          <WizrFlexLayout
                            alignItems='flex-start'
                            flexDirection='row'
                            justifyContent='space-between'
                          >
                            <WizrText type='body2'>{item.name}</WizrText>
                            <WizrText type='hint'>{parameterFinder(item?.type)}*</WizrText>
                          </WizrFlexLayout>
                          <WizrFlexLayout
                            flexDirection='row'                            
                            alignItems='center'
                            justifyContent='space-around'
                            style={{ gap: '3px' }}
                          >
                            <WizrDropDown
                              placeHolder='See Options'
                              options={dropDownConverter(item.modalTags)}
                              className="nodrag nopan"
                              value={{}}
                            />
                             {/* <Button type='text' icon={<EditIcon />} onClick={editParameter('edit', item, key)}/>
                <Button type='text' icon={<DeleteIcon />} onClick={editParameter('delete', item, key)}/> */}
                          </WizrFlexLayout>
                        </>
                      )
                  }
                })}
    </ParametersContainer>
  )
}

const StartNode = (props: any): JSX.Element => {
  const { id, updateNodeData, data, updateEdgeData, onEdit, isDisabled } = props  

  const nodes = useNodes()
  const startNode: any = nodes[0]?.data 
  // adding static code for getting the llm config from llm nodes. when multiple nodes come we have to change this
  const promptConfig: any = nodes[1]?.data
  const promptModal: any = promptConfig?.config?.llm_model_config?.model_type  

  const startnodedata: any = nodes && nodes[0]?.data  
  
  const [isEditMode, setIsEditMode] = useState(false)
  const [selectedType, setSelectedType] = useState(0)

  //to define which action to be taken when saving the parameters(edit || add)
  const [actionForParameter, setActionForParameter] = useState('')
  const [editParameterKey, setEditParameterKey] = useState(0)

  const [currentInputState, setCurrentInputState] = useState<any>(null)
  const [parametersList, setParametersList] = useState<any>(startnodedata.config?.parameters || [])
  const [saveEnabled,setSaveEnabled]=useState<boolean>(false)
  const [parameterAlert,setParameterAlert]=useState<boolean>(false)  

  useEffect(()=>{

    if ( !parametersList.some((item: any) => item.name === 'user_query')) {      
      const newParameter = { name: 'user_query', type: 2 };
      setParametersList([...parametersList, newParameter]);
      updateNodeData((nds: any) =>
      nds && nds.map((node: any) => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            config: {
              parameters: [...parametersList, newParameter],
            },
          }
        }        
        return node
      }),
    )
    }
    else{
      setParametersList(startnodedata.config?.parameters)
    }

  },[startnodedata.config?.parameters])

  const renderSelectedTypeForm = useCallback(() => {
    if (selectedType === 1 || selectedType === 2 || selectedType === 3 || selectedType === 6 || selectedType === 7)
      return (
        <TextInputSelection
          currentInputState={currentInputState}
          setCurrentInputState={setCurrentInputState}
          setSaveEnabled={setSaveEnabled}
          parameterAlert={parameterAlert}
          
        />
      )
    if (selectedType === 4 || selectedType === 5)
      return (
        <SelectInputSelection
          currentInputState={currentInputState}
          setCurrentInputState={setCurrentInputState}
          setSaveEnabled={setSaveEnabled}
          parameterAlert={parameterAlert}
        />
      )
    else return <></>
  }, [selectedType, currentInputState])


  const onSaveAction = () => { 
    
    // if (!parametersList.some((item:any)=>item.name===currentInputState?.name)){
      // setParameterAlert(false)
      const currentParameters = [...parametersList]
    if(actionForParameter === 'edit'){
      currentParameters[editParameterKey] = { ...currentInputState, type: selectedType }
    }else{
      currentParameters.push({ ...currentInputState, type: selectedType })
    }
    
    setParametersList(currentParameters)
    updateNodeData((nds: any) =>
      nds.map((node: any) => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            config: {
              parameters: currentParameters,
            },
          }
        }        
        return node
      }),
    )
   
    setIsEditMode(!isEditMode)
  // }
  // else{
  //   setParameterAlert(true)
  // }
  
    }
    

  const onTypeChange = (typeKey: number) => {
    setCurrentInputState(null)
    setSelectedType(typeKey)
  }

  const editParameter = (type: string, parameter: any, key: number) => () => {    
    switch (type) {
      case 'edit':
        setIsEditMode(true)
        setSelectedType(parameter?.type)
        setEditParameterKey(key)
        setActionForParameter('edit')
        setCurrentInputState({name: parameter?.name,modalTags:parameter?.modalTags})
        break;
      case 'delete':
        setParametersList(parametersList.filter((item: any) => item.name !== parameter.name));
        updateNodeData((nds: any) =>
          nds.map((node: any) => {
            if (node.id === id) {
              node.data = {
                ...node.data,
                config: {
                  parameters: parametersList.filter((item: any) => item.name !== parameter.name),
                },
              }
            }        
        return node
      }),
    )
        break;
    }
  }

  const handleNameUpdate = (itemId: any, newName: any) => {

    updateNodeData((nds: any) =>
      nds.map((node: any) => {
        if (node.id === itemId) {
          node.data = {
            ...node.data,
            label: newName,
          }
        }
        return node
      }),
    )

};


  return (
    <NodeContainer
      item={{ nodeTitle: startNode?.label, id: 'start' }}
      data={data}
      isEditMode={isEditMode}
      saveCurrentAction={onSaveAction}
      startNode={{isStartNode:true,isSaveEnabled:saveEnabled}}
      onUpdateName={(newName: any) => handleNameUpdate('start', newName)}
      onEditNodeDetail={() => { onEdit(data?.label, props)}}  
      isDisabled={isDisabled} 
      nodeType={'10'}
      
    >
      {!isEditMode ? (
        <>
          {parametersList?.length>1 ? (
            <ShowParameters parameters={parametersList} editParameter={editParameter}/>
          ) : (
            <ContentContainer background='#FFF'>
              <WizrText type='body1' textColor='green'>
                Start input configurations
              </WizrText>
              <WizrText type='body3' textColor='green'>
                Set Input that can be used for the workflow
              </WizrText>
            </ContentContainer>
          )}
        </>
      ) : (
       <></>
      )}
      {/* { !isEditMode && }
      <WizrButton
        icon={!isEditMode ? <CreateNew fill='black' /> : null}
        text={!isEditMode ? "'Add field'" : 'Cancel'}
        onClick={() => {setIsEditMode(!isEditMode); setActionForParameter('add'); setCurrentInputState(null); setSelectedType(0); setSaveEnabled(false)}}
        style={{width: isEditMode ? '80px' : 'auto',borderWidth:isEditMode?"0":"1px",borderColor:"black",borderRadius:isEditMode?"0":"12px"}}
        color={isEditMode?"white":"black"}
        type={isEditMode?'primary':'link'}
      /> */}
    </NodeContainer>
  )
}

const ContentContainer = styled(WizrFlexLayout)`
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  gap: 8px;
`

const TypeHolder = styled(WizrFlexLayout)`
  border-radius: 8px;
  border: 1px solid var(--grayscale-500, #999b9f);
  padding: 10px;
  text-align: left;
`

const ParametersContainer = styled(WizrFlexLayout)`
  gap: 8px;
  margin-bottom: 20px;
`

const ParameterHolder = styled(WizrFlexLayout)`
  gap: 16px;
`
export default StartNode
