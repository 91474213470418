{/* <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.192 6H9.80802C7.42198 6.00717 5.49338 7.94696 5.50002 10.333V14.667C5.49338 17.053 7.42198 18.9928 9.80802 19H15.192C17.5781 18.9928 19.5067 17.053 19.5 14.667V10.333C19.5067 7.94696 17.5781 6.00717 15.192 6Z" stroke="#1D1F22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.25 15.5C8.25 15.9142 8.58579 16.25 9 16.25C9.41421 16.25 9.75 15.9142 9.75 15.5H8.25ZM9.75 11.5C9.75 11.0858 9.41421 10.75 9 10.75C8.58579 10.75 8.25 11.0858 8.25 11.5H9.75ZM11.75 15.5C11.75 15.9142 12.0858 16.25 12.5 16.25C12.9142 16.25 13.25 15.9142 13.25 15.5H11.75ZM13.25 9.5C13.25 9.08579 12.9142 8.75 12.5 8.75C12.0858 8.75 11.75 9.08579 11.75 9.5H13.25ZM15.25 15.5C15.25 15.9142 15.5858 16.25 16 16.25C16.4142 16.25 16.75 15.9142 16.75 15.5H15.25ZM16.75 11.5C16.75 11.0858 16.4142 10.75 16 10.75C15.5858 10.75 15.25 11.0858 15.25 11.5H16.75ZM9.75 15.5V11.5H8.25V15.5H9.75ZM13.25 15.5V9.5H11.75V15.5H13.25ZM16.75 15.5V11.5H15.25V15.5H16.75Z" fill="#1D1F22"/>
</svg> */}

const PollIcon = (props: any) => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.192 6H9.80802C7.42198 6.00717 5.49338 7.94696 5.50002 10.333V14.667C5.49338 17.053 7.42198 18.9928 9.80802 19H15.192C17.5781 18.9928 19.5067 17.053 19.5 14.667V10.333C19.5067 7.94696 17.5781 6.00717 15.192 6Z" stroke="#1D1F22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.25 15.5C8.25 15.9142 8.58579 16.25 9 16.25C9.41421 16.25 9.75 15.9142 9.75 15.5H8.25ZM9.75 11.5C9.75 11.0858 9.41421 10.75 9 10.75C8.58579 10.75 8.25 11.0858 8.25 11.5H9.75ZM11.75 15.5C11.75 15.9142 12.0858 16.25 12.5 16.25C12.9142 16.25 13.25 15.9142 13.25 15.5H11.75ZM13.25 9.5C13.25 9.08579 12.9142 8.75 12.5 8.75C12.0858 8.75 11.75 9.08579 11.75 9.5H13.25ZM15.25 15.5C15.25 15.9142 15.5858 16.25 16 16.25C16.4142 16.25 16.75 15.9142 16.75 15.5H15.25ZM16.75 11.5C16.75 11.0858 16.4142 10.75 16 10.75C15.5858 10.75 15.25 11.0858 15.25 11.5H16.75ZM9.75 15.5V11.5H8.25V15.5H9.75ZM13.25 15.5V9.5H11.75V15.5H13.25ZM16.75 15.5V11.5H15.25V15.5H16.75Z" fill="#1D1F22"/>
</svg>
  )
  export default PollIcon
  
