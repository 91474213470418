import { Popover } from 'antd'
import WizrButton from '../WizrButton'
import WizrFlexLayout from '../WizrFlexLayout'
import { useWizrModalContext, MODAL_TYPES } from '../../providers/modal/WizrModalProvider'
import CreateNew from '../../assets/icons/CreateNew'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import CloseIcon from '../../assets/icons/Close'
import Voice from '../../assets/icons/Voice'
import { edgeItems } from '../../utils/appDesignConfigs'
import AIModelIcon from '../../assets/icons/AIModelIcon'
import Code from '../../assets/icons/Code'
import IF from '../../assets/icons/IF'
import Iternation from '../../assets/icons/Iteration'
import AIIcon from '../../assets/icons/AIIcon'
import AIPlugins from '../../assets/icons/AIPlugins'
import App from '../../assets/icons/App'
import EdgeButton from '../../assets/icons/edgeClose'

const StyledDiv = styled.div`
  /* Add rotation transformation */
  transform: rotate(45deg); /* By default, no rotation */

  /* Transition for smooth rotation animation */
  transition: transform 0.3s ease-in-out;

  /* On hover, rotate the button */
  &:hover {
    transform: rotate(0deg); /* Adjust the degree for the rotation on hover */
  }
`;

const AppDesignerPopover = ({onToolBarItemClick, isDisabled}: any): JSX.Element => {
  
  const StyledWizrButton= styled(WizrButton)`
  border-radius: 20px;`
  return (
    <>
    {isDisabled === true ? <Popover content={<CreateAppContent onToolBarItemClick={onToolBarItemClick}/>} title='' placement='right'><StyledDiv><EdgeButton/></StyledDiv> </Popover> : <></>}
    </>
  )
}

export default AppDesignerPopover


const CreateAppContent = ({onToolBarItemClick}: any): JSX.Element => {

    return (
      <WizrFlexLayout
      justifyContent='space-between'
      background='transparent'
      style={{width:"100%",height:"100%",padding:"0px",margin:"0px", backgroundColor: 'white'}}
    >
        {edgeItems?.map((item: any, key: any) => {
          return (
          <WizrFlexLayout  key={key} style={{maxWidth: '55px', alignItems: 'start'}} >
            {item.key === 'delete' 
            ? <WizrButton style={{backgroundColor:"white" , borderRadius:"60px",width:"40px",height:"40px",paddingLeft:"20px"}} type='link' icon={<item.Icon />} onClick={() => onToolBarItemClick('delete')} />
            : <Popover content={<ToolbarContent content={item} onCreate={onToolBarItemClick}/>} title='' trigger='click'>
              <WizrButton style={{backgroundColor:"white" , borderRadius:"60px",width:"40px",height:"40px",paddingLeft:"20px"}} type='link' icon={<item.Icon />} />
              {/* <WizrFlexLayout style={{paddingRight:"12px"}}>
              <WizrText type='caption'textColor='#1D1F22'>{item.text}</WizrText>
              </WizrFlexLayout> */}
            </Popover>}
          </WizrFlexLayout>
          )
        }
        )}     
    </WizrFlexLayout>
    )
  }
  



const ToolbarContent = ({content, onCreate}: any): JSX.Element => {

    return (
      <WizrFlexLayout textAlign='left' background='#FFF'>
        {content.key === 'ai' ? (
          <>
            <WizrButton text='LLM' type='text' onClick={() => onCreate('llm')} justifyContent='flex-start' icon={<AIModelIcon/>}/>
            <WizrButton text='Vision' type='text' onClick={() => onCreate('vision')} justifyContent='flex-start' icon={<Voice/>}/>
            <WizrButton text='Voice' type='text' onClick={() => onCreate('transcription')} justifyContent='flex-start' icon={<Voice/>}/>
          </>
        ) : <></>}
        {content.key === 'logic' ? (
          <>
            <WizrButton text='Code' type='text' onClick={() => onCreate('code')} justifyContent='flex-start' icon={<Code/>}/>
            <WizrButton text='Condition' type='text' onClick={() => {}} justifyContent='flex-start' icon={<IF/>}/>
            <WizrButton text='Iteration' type='text' onClick={() => {}} justifyContent='flex-start' icon={<Iternation/>} />
          </>
        ) : <></>}
        {content.key === 'addon' ? (
          <>
            <WizrButton text='Api' type='text' onClick={() => onCreate('api')} justifyContent='flex-start' icon={<AIIcon/>}/>
            <WizrButton text='Past Tickets Search' type='text' onClick={() => {onCreate('Past Ticket Search')}} justifyContent='flex-start' icon={<App/>}/>
            <WizrButton text='FAQ Search' type='text' onClick={() => onCreate('FAQ Search')} justifyContent='flex-start' icon={<App/>}/>
            <WizrButton text='Ai Plugins' type='text' onClick={() => {}} justifyContent='flex-start' icon={<AIPlugins/>}/>
          </>
        ) : <></>}
        {content.key === 'export' ? (
          <></>
        ) : <></>}
        {content.key === 'help' ? (
          <></>
        ) : <></>}
      </WizrFlexLayout>
    )
  }
  
