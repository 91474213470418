import React from 'react'

const Mp4logoIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M15 5V11C15 13.2091 13.2091 15 11 15H5C2.79086 15 1 13.2091 1 11V5C1 2.79086 2.79086 1 5 1H11C13.2091 1 15 2.79086 15 5Z'
        stroke='#0C18AE'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.4 8.11698L9.3 9.54898C9.42438 9.63732 9.49826 9.78043 9.49826 9.93298C9.49826 10.0855 9.42438 10.2286 9.3 10.317L7.4 11.883C7.2396 12.0081 7.02387 12.0363 6.83674 11.9564C6.64961 11.8766 6.52064 11.7014 6.5 11.499V8.49898C6.52128 8.29694 6.65051 8.12235 6.83752 8.04297C7.02453 7.9636 7.23989 7.99193 7.4 8.11698V8.11698Z'
        stroke='#0C18AE'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15 5.75C15.4142 5.75 15.75 5.41421 15.75 5C15.75 4.58579 15.4142 4.25 15 4.25V5.75ZM8 4.25C7.58579 4.25 7.25 4.58579 7.25 5C7.25 5.41421 7.58579 5.75 8 5.75V4.25ZM1 4.25C0.585786 4.25 0.25 4.58579 0.25 5C0.25 5.41421 0.585786 5.75 1 5.75V4.25ZM8 5.75C8.41421 5.75 8.75 5.41421 8.75 5C8.75 4.58579 8.41421 4.25 8 4.25V5.75ZM7.25 5C7.25 5.41421 7.58579 5.75 8 5.75C8.41421 5.75 8.75 5.41421 8.75 5H7.25ZM8.75 1C8.75 0.585786 8.41421 0.25 8 0.25C7.58579 0.25 7.25 0.585786 7.25 1H8.75ZM15 4.25H8V5.75H15V4.25ZM1 5.75H8V4.25H1V5.75ZM8.75 5V1H7.25V5H8.75Z'
        fill='#0C18AE'
      />
    </svg>
  )
}

export default Mp4logoIcon
