import styled from "styled-components"
import InfoIcon from "../../assets/icons/Info"
import WizrFlexLayout from "../WizrFlexLayout"
import WizrInput from "../WizrInput"
import WizrText from "../WizrText"
import WizrFlexSpacing from "../WizrFlexSpacing"
import WizrButton from "../WizrButton"
import { useEffect, useState } from "react"
import { useContentLoadingSelector, useFeatureSettingSelector } from "../../redux/selectors/content"
import { useParams } from "react-router-dom"
import { dispatchFeatureSettings, dispatchUpdateTicketType } from "../../redux/actions/content"
import { updateTicketType } from "../../api/helpers/contents"

const StyledContainer = styled(WizrFlexLayout)`
  gap: 15px;
`
const StyledWizrInput = styled(WizrInput)`
background:#F2F4F9;`
const InputContainer = styled(WizrFlexLayout)`
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  background-color: #fff;
  
`
const Categories = ({onCancel,data,folder,action}:any):JSX.Element=>{
    const featureData =useFeatureSettingSelector()
   const loading = useContentLoadingSelector()

    const [intentState,setIntentState] = useState(data?{name:data?.name,intent:data?.intent,description:data?.description}:{name:'',intent:'',description:''})
    const updateTicketType = async()=>{
        let payload;
        if (action==='create'){
            payload = {
                id :featureData?.id,
                folder_id:folder,
                data:{
                    name:intentState?.name,
                    intent:intentState?.intent,
                    description:intentState?.description,
                    status:'active'
                }}
        }else
    {
         payload = {
        id :featureData?.id,
        folder_id:folder,
        data:{
          id:data?.id,
            name:intentState?.name,
            intent:intentState?.intent,
            description:intentState?.description,
            status:'active'
        }
    }}
    await dispatchUpdateTicketType(payload,'categories')
    dispatchFeatureSettings()
    onCancel()

    }
     
    return(
        <StyledContainer background='#FFF' justifyContent='space-evenly'>
        <InputContainer>
          <div style={{gap:"10px",display:"flex"}}>
            <WizrText type='body2'>Category</WizrText><InfoIcon/></div>
           <StyledWizrInput placeholder='Enter category '  value={intentState?.name} onChange={(value:any)=>{
            setIntentState({...intentState,
                                 name:value})
            }} ></StyledWizrInput>

          </InputContainer>
          <InputContainer>
          <div style={{gap:"10px",display:"flex"}}>
            <WizrText type='body2'>Intent</WizrText><InfoIcon/></div>
           <StyledWizrInput placeholder='Enter intent '  value={intentState?.intent} onChange={(value:any)=>{
              setIntentState({...intentState,
                intent:value})
           }} ></StyledWizrInput>

          </InputContainer>
          <InputContainer>
          <div style={{gap:"10px",display:"flex"}}>
            <WizrText type='body2'>Description</WizrText><InfoIcon/></div>
           <StyledWizrInput placeholder='Enter description '  value={intentState?.description} onChange={(value:any)=>{
              setIntentState({...intentState,
                description:value})
           }} ></StyledWizrInput>

          </InputContainer>
          <WizrFlexSpacing
            justifyContent='flex-end'
            flexDirection='row'
            size='large'
            background='#FFF'
          >
            <WizrButton type='text' text='Cancel' onClick={onCancel} style={{borderRadius: '20px'}}/>
            <WizrButton htmlType='submit' text='Save' onClick={()=>{updateTicketType()}} disabled={loading} style={{borderRadius: '20px'}}/>
          </WizrFlexSpacing>
        </StyledContainer>
    )
}

const TicketType = ({onCancel,data,folder,action}:any):JSX.Element=>{
    const featureData =useFeatureSettingSelector()
    const loading = useContentLoadingSelector()

       const [ticketType,setTicketType] = useState(data?{name:data?.name,description:data?.description}:{name:'',description:''})
    const updateTicketType = async()=>{
        let payload;
        if (action==='create'){
            payload = {
                id :featureData?.id,
                folder_id:folder,
                data:{
                    name:ticketType?.name,
                    description:ticketType?.description,
                    status:'active'
                }}
        }else
    {
         payload = {
        id :featureData?.id,
        folder_id:folder,
        data:{
          id:data?.id,
            name:ticketType?.name,
            description:ticketType?.description,
        }
    }}
    await dispatchUpdateTicketType(payload,'ticket-type')
    dispatchFeatureSettings()
    onCancel()

    }
     
    return(
        <StyledContainer background='#FFF' justifyContent='space-evenly'>
        <InputContainer>
          <div style={{gap:"10px",display:"flex"}}>
            <WizrText type='body2'>Name</WizrText><InfoIcon/></div>
           <StyledWizrInput placeholder='Enter name '  value={ticketType?.name} onChange={(value:any)=>{
            setTicketType({...ticketType,
                                 name:value})
            }} ></StyledWizrInput>

          </InputContainer>
          
          <InputContainer>
          <div style={{gap:"10px",display:"flex"}}>
            <WizrText type='body2'>Description</WizrText><InfoIcon/></div>
           <StyledWizrInput placeholder='Enter description '  value={ticketType?.description} onChange={(value:any)=>{
              setTicketType({...ticketType,
                description:value})
           }} ></StyledWizrInput>

          </InputContainer>
          <WizrFlexSpacing
            justifyContent='flex-end'
            flexDirection='row'
            size='large'
            background='#FFF'
          >
            <WizrButton type='text' text='Cancel' onClick={onCancel} style={{borderRadius: '20px'}}/>
            <WizrButton htmlType='submit' text='Save' onClick={()=>{updateTicketType()}}  disabled={loading} style={{borderRadius: '20px'}}/>
          </WizrFlexSpacing>
        </StyledContainer>
    )
}

const Sentiment = ({onCancel,data,folder,action}:any):JSX.Element=>{
  const featureData =useFeatureSettingSelector()
  const loading = useContentLoadingSelector()


  const [sentiment,setsentiment] = useState(data?{name:data?.name,description:data?.description}:{name:'',description:''})
  const updatesentiment = async()=>{
      let payload;
      if (action==='create'){
          payload = {
              id :featureData?.id,
              folder_id:folder,
              data:{
                  name:sentiment?.name,
                  description:sentiment?.description,
                  status:'active'
              }}
      }else
  {
       payload = {
      id :featureData?.id,
      folder_id:folder,
      data:{
        id:data?.id,
          name:sentiment?.name,
          description:sentiment?.description,
          status:data?.status
      }
  }}
  await dispatchUpdateTicketType(payload,'sentiments')
  dispatchFeatureSettings()
  onCancel()

  }
   
  return(
      <StyledContainer background='#FFF' justifyContent='space-evenly'>
      <InputContainer>
        <div style={{gap:"10px",display:"flex"}}>
          <WizrText type='body2'>Name</WizrText><InfoIcon/></div>
         <StyledWizrInput placeholder='Enter Sentiment '  value={sentiment?.name} onChange={(value:any)=>{
          setsentiment({...sentiment,
                               name:value})
          }} ></StyledWizrInput>

        </InputContainer>
        
        <InputContainer>
        <div style={{gap:"10px",display:"flex"}}>
          <WizrText type='body2'>Description</WizrText><InfoIcon/></div>
         <StyledWizrInput placeholder='Enter description '  value={sentiment?.description} onChange={(value:any)=>{
            setsentiment({...sentiment,
              description:value})
         }} ></StyledWizrInput>

        </InputContainer>
        <WizrFlexSpacing
          justifyContent='flex-end'
          flexDirection='row'
          size='large'
          background='#FFF'
        >
          <WizrButton type='text' text='Cancel' onClick={onCancel} style={{borderRadius: '20px'}}/>
          <WizrButton htmlType='submit' text='Save' onClick={()=>{updatesentiment()}}
           disabled={loading} style={{borderRadius: '20px'}}/>
        </WizrFlexSpacing>
      </StyledContainer>
  )
}

const TicketPriority = ({onCancel,data,folder,action}:any):JSX.Element=>{
  const featureData =useFeatureSettingSelector()
     const loading = useContentLoadingSelector()
   const [ticketPriority,setTicketPriority] = useState(data?{name:data?.name,description:data?.description}:{name:'',description:''})
  const updateTicketType = async()=>{
      let payload;
      if (action==='create'){
          payload = {
              id :featureData?.id,
              folder_id:folder,
              data:{
                  name:ticketPriority?.name,
                  description:ticketPriority?.description,
                  status:'active'
              }}
      }else
  {
       payload = {
      id :featureData?.id,
      folder_id:folder,
      data:{...data,
          name:ticketPriority?.name,
          description:ticketPriority?.description,
      }
  }}
  await dispatchUpdateTicketType(payload,'ticket-priority')
  dispatchFeatureSettings()
  onCancel()

  }
   
  return(
      <StyledContainer background='#FFF' justifyContent='space-evenly'>
      <InputContainer>
        <div style={{gap:"10px",display:"flex"}}>
          <WizrText type='body2'>Name</WizrText><InfoIcon/></div>
         <StyledWizrInput placeholder='Enter name '  value={ticketPriority?.name} onChange={(value:any)=>{
          setTicketPriority({...ticketPriority,
                               name:value})
          }} ></StyledWizrInput>

        </InputContainer>
        
        <InputContainer>
        <div style={{gap:"10px",display:"flex"}}>
          <WizrText type='body2'>Description</WizrText><InfoIcon/></div>
         <StyledWizrInput placeholder='Enter description '  value={ticketPriority?.description} onChange={(value:any)=>{
                      setTicketPriority({...ticketPriority,
              description:value})
         }} ></StyledWizrInput>

        </InputContainer>
        <WizrFlexSpacing
          justifyContent='flex-end'
          flexDirection='row'
          size='large'
          background='#FFF'
        >
          <WizrButton type='text' text='Cancel' onClick={onCancel} style={{borderRadius: '20px'}} />
          <WizrButton htmlType='submit' text='Save' onClick={()=>{updateTicketType()}}  disabled={loading} style={{borderRadius: '20px'}}/>
        </WizrFlexSpacing>
      </StyledContainer>
  )
}



const Tags = ({onCancel,data,folder,action}:any):JSX.Element=>{
  const featureData =useFeatureSettingSelector()
  const loading = useContentLoadingSelector()

   const [tags,setTags] = useState(data?data?.name:'')
  const updateTicketType = async()=>{
      let payload;
      if (action==='create'){
          payload = {
              id :featureData?.id,
              folder_id:folder,
              data:{
                  name:tags,                 
                  status:'active'
              }}
      }else
  {
       payload = {
      id :featureData?.id,
      folder_id:folder,
      data:{...data,
          name:tags,
      }
  }}
  await dispatchUpdateTicketType(payload,'tags')
  dispatchFeatureSettings()
  onCancel()

  }
   
  return(
      <StyledContainer background='#FFF' justifyContent='space-evenly'>
      <InputContainer>
        <div style={{gap:"10px",display:"flex"}}>
          <WizrText type='body2'>Name</WizrText><InfoIcon/></div>
         <StyledWizrInput placeholder='Enter name '  value={tags} onChange={(value:any)=>{setTags(value)  }} ></StyledWizrInput>

        </InputContainer>
        
        <WizrFlexSpacing
          justifyContent='flex-end'
          flexDirection='row'
          size='large'
          background='#FFF'
        >
          <WizrButton type='text' text='Cancel' onClick={onCancel} style={{borderRadius: '20px'}}/>
          <WizrButton htmlType='submit' text='Save' onClick={()=>{updateTicketType()}}  disabled={loading} style={{borderRadius: '20px'}}/>
        </WizrFlexSpacing>
      </StyledContainer>
  )
}

const TicketSettings = ({type,data,folder,action,onCancel}:any):JSX.Element=>{
    const renderForm=(type:any,action:any)=>{
        switch (type) {
            case 'categories_intent':
                return <Categories onCancel={onCancel} data={data} folder={folder} action={action}/>
            case 'sentiment':
                return <Sentiment onCancel={onCancel} data={data} folder={folder} action={action}/>
            case 'tags':
                return <Tags onCancel={onCancel} data={data} folder={folder} action={action}/>
            case 'priority':
                return <TicketPriority onCancel={onCancel} data={data} folder={folder} action={action}/>
            case 'type'  :
                return <TicketType onCancel={onCancel} data={data} folder={folder} action={action}/>
             default :
                return <></>
            
        }
    }
    return (
<WizrFlexLayout>
 {renderForm(type,action)}
</WizrFlexLayout>        )
}
export default TicketSettings