import WizrButton from "../WizrButton"
import { useNavigate } from "react-router-dom"

const AppRun=(appDetail:any):JSX.Element=>{
    const navigate = useNavigate()
   
    
    const onRunClick=()=>{
        navigate("/bulkrun",{state:{appDetail}})
    }
    return(
        <WizrButton type='primary' text='RUN' style={{borderRadius:"20px"}}  onClick={onRunClick}/>
    )
}
export default AppRun