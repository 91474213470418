import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CreateNew from '../../../assets/icons/CreateNew'
import Hamburger from '../../../assets/icons/Hamburger'
import WizrButton from '../../../components/WizrButton'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrText from '../../../components/WizrText'
import WizrUserJourney from '../../../components/WizrUserJourney'
import Analytics from '../../../assets/icons/AnalyticsIcon'
import WizrDropDown from '../../../components/WizrDropDown'
import styled from 'styled-components'
import WizrToggleButton from '../../../components/WizrToggleButton'
import WizrInput from '../../../components/WizrInput'
import { useEffect, useState } from 'react'
import {
  dispatchFeatureSettings,
  dispatchGetPackageDetails,
  dispatchGetTenantGroups,
  dispatchIntegrationList,
  dispatchListFolders,
  dispatchMasterIntegrationList,
  dispatchTempListFolders,
  dispatchUpdateFeatureSettings,
} from '../../../redux/actions/content'
import {
  useAgentAssistAppSelector,
  useContentLoadingSelector,
  useContentsSelector,
  useFaqDataSelector,
  useFeatureSettingSelector,
  useGetIntegrationList,
  useGetMasterIntegrationList,
  usePastTicketDataSelector,
  useTenantTeamSelectors,
  useappDataSelector,
} from '../../../redux/selectors/content'
import ZendeskIcon from '../../../assets/icons/ZendeskIcon'
import WizrTextArea from '../../../components/WizrTextArea'
import Category from '../../../assets/icons/Category'
import WizrTagInput from '../../../components/WizrTagInput'
import { set } from 'react-hook-form'
import { get } from 'http'
import { getTenantId } from '../../../utils'
import { Input, Popover, message as antdMessage } from 'antd'
import copy from 'copy-to-clipboard';
import Copy from '../../../assets/icons/Copy'
import FolderIcon from '../../../assets/icons/Folder'

const StyledDropDown = styled(WizrDropDown)`
  height: 40px;
  width: 24% !important;
  .ant-select-selector {
    height: 40px !important;
    background-color: #f2f4f9 !important;
    border-radius: 16px;
    border: 0px #f2f4f9 !important;
    .ant-select-selection-placeholder {
      margin-top: 6px;
    }
  }
`
const StyledWizrTagInput = styled(WizrTagInput)`
width:200px;
.ant-select-selector {
    height: 40px !important;
    background-color: #f2f4f9 !important;
    border-radius: 16px;
    border: 0px #f2f4f9 !important;
   
  } 

`

const StyledInput = styled(WizrInput)`
  height: 40px;
  width: 30%;
  background-color: #f2f4f9;
  border: 0px #f2f4f9 !important;
`

const StyledDropDownDefault = styled(WizrDropDown)`
  .ant-select-selector {
    height: 40px !important;
    background-color: #f2f4f9 !important;
    border-radius: 16px;
    border: 0px #f2f4f9 !important;
    padding-top: 5px !important;
    .ant-select-selection-placeholder {
      margin-top: 2px;
    }
  }
`

const StyledInputName = styled.input`
  padding-top: 4px;
  background: #F2F4F9;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #1F1D1D;
  width: 500px;

  &:placeholder {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #1F1D1D;
  }
`;

const GenerateExtension = ({ url }: any): JSX.Element => {


  return <WizrFlexLayout >
    <WizrFlexLayout flexDirection="row" background="white" justifyContent="flex-start" style={{ paddingBottom: '10px', alignItems: 'center', gap: '16px' }}>
      <WizrText type="sub2"  >Enable sharing</WizrText>

    </WizrFlexLayout >
    <WizrFlexLayout background="white">
      <Input
        value={url}
        //disabled={true}
        style={{
          width: '411px',
          height: '40px',
          flexShrink: 0,
          borderRadius: '16px',
        }}
        suffix={
          <WizrButton
            type="link"
            icon={<Copy color="#999B9F" />}
            onClick={() => {
              antdMessage.success('Copied to clipboard', 2);
              copy(url)
            }}
          />
        }
      />

    </WizrFlexLayout>
  </WizrFlexLayout>

}


const DataFields = ({
  currentFeatureData,
  doUpdateDataFields,
  dataField,
  featureSettings,
  doUpdateIntentCatogoriesFields
}: any) => {

  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item?.folder_name,
        value: item?.folder_id,
      }))
    return result
  }
 

  const getStatus = (key: any): boolean => {
    const item = dataField?.find((item: any) => item?.key_name === key)
    return item ? item.enabled : false
  }
  const getValue = (key: any, field: any) => {
    const item = dataField?.find((item: any) => item?.key_name === key)

    return item ? item?.[field] : ''
  }

  const intents = featureSettings?.ticket_settings?.categories_intent?.folders.filter(
    (item: any) => item?.status !== 'deleted',
  )
  const sentiment = featureSettings?.ticket_settings?.sentiment?.folders.filter(
    (item: any) => item?.status !== 'deleted',
  )
  const tags = featureSettings?.ticket_settings?.tags?.folders.filter(
    (item: any) => item?.status !== 'deleted',
  )
  const priority = featureSettings?.ticket_settings?.priority?.folders.filter(
    (item: any) => item?.status !== 'deleted',
  )
  const type = featureSettings?.ticket_settings?.type?.folders.filter(
    (item: any) => item?.status !== 'deleted',
  )


  return (
    <WizrFlexLayout background='white' style={{ gap: '10px' }}>
      <WizrFlexLayout background='white' textAlign='left' flexDirection='row'>
        <WizrFlexLayout style={{ maxWidth: '27%' }} background='white' textAlign='left'>
          <WizrText type='body2' textColor='#707276'>
            Categories{' '}
          </WizrText>
        </WizrFlexLayout>
        <WizrToggleButton
          isEnabled={getStatus("categories")}
          onChange={(e: boolean) =>
            doUpdateDataFields('categories', 'enabled', e)
          }
        />
        <StyledDropDown
          options={dropDownConverter(intents)}
          placeHolder='Select Group'
          value={getValue('categories', 'folder_id')}
          onChange={(value: any) => {
            // doUpdateDataFields('categories', 'folder_id', value)
            //doUpdateDataFields('intent', 'folder_id', value)
            doUpdateIntentCatogoriesFields('folder_id', value)
          }}
        />
        <StyledInput
          placeholder='Mention zendesk ID'
          value={getValue('categories', 'zendesk_id')}
          onChange={(value: any) =>
            doUpdateDataFields('categories', 'zendesk_id', value)
            //doUpdateIntentCatogoriesFields('zendesk_id',value)

          }
        />
      </WizrFlexLayout>
      <WizrFlexLayout background='white' textAlign='left' flexDirection='row'>
        <WizrFlexLayout style={{ maxWidth: '27%' }} background='white' textAlign='left'>
          <WizrText type='body2' textColor='#707276'>
            Intent
          </WizrText>
        </WizrFlexLayout>
        <WizrToggleButton
          isEnabled={getStatus('intent')}
          onChange={(e: boolean) => doUpdateDataFields('intent', 'enabled', e)}
        />
        <StyledDropDown
          options={dropDownConverter(intents)}
          placeHolder='Select Group'
          value={getValue('intent', 'folder_id')}
          onChange={(value: any) => {
            // doUpdateDataFields('intent', 'folder_id', value)
            // doUpdateDataFields('categories_intent', 'folder_id', value)
            doUpdateIntentCatogoriesFields('folder_id', value)

          }}
        />
        <StyledInput
          placeholder='Mention zendesk ID'
          value={getValue('intent', 'zendesk_id')}
          onChange={(value: any) =>
            doUpdateDataFields('intent', 'zendesk_id', value)
            //doUpdateIntentCatogoriesFields('folder_id',value)

          }
        />
      </WizrFlexLayout>
      <WizrFlexLayout background='white' textAlign='left' flexDirection='row'>
        <WizrFlexLayout style={{ maxWidth: '27%' }} background='white' textAlign='left'>
          <WizrText type='body2' textColor='#707276'>
            Type{' '}
          </WizrText>
        </WizrFlexLayout>
        <WizrToggleButton
          isEnabled={getStatus('type')}
          onChange={(e: boolean) => doUpdateDataFields('type', 'enabled', e)}
        />
        <StyledDropDown
          options={dropDownConverter(type)}
          placeHolder='Select Group'
          value={getValue('type', 'folder_id')}
          onChange={(value: any) => doUpdateDataFields('type', 'folder_id', value)}
        />
        <StyledInput
          placeholder='Mention zendesk ID'
          value={getValue('type', 'zendesk_id')}
          onChange={(value: any) => doUpdateDataFields('type', 'zendesk_id', value)}
        />
      </WizrFlexLayout>
      <WizrFlexLayout background='white' textAlign='left' flexDirection='row'>
        <WizrFlexLayout style={{ maxWidth: '27%' }} background='white' textAlign='left'>
          <WizrText type='body2' textColor='#707276'>
            {' '}
            Relevance{' '}
          </WizrText>
        </WizrFlexLayout>
        <WizrToggleButton
          isEnabled={getStatus('relevance')}
          onChange={(e: boolean) => doUpdateDataFields('relevance', 'enabled', e)}
        />
        <WizrFlexLayout
          style={{ maxWidth: '24%' }}
          background='white'
          textAlign='center'
          justifyContent='center'
        >
          <WizrText type='body2'> Yes/No</WizrText>
        </WizrFlexLayout>
        <StyledInput
          placeholder='Mention zendesk ID'
          value={getValue('relevance', 'zendesk_id')}
          onChange={(value: any) => doUpdateDataFields('relevance', 'zendesk_id', value)}
        />
      </WizrFlexLayout>
      <WizrFlexLayout background='white' textAlign='left' flexDirection='row'>
        <WizrFlexLayout style={{ maxWidth: '27%' }} background='white' textAlign='left'>
          <WizrText type='body2' textColor='#707276'>
            Auto tags{' '}
          </WizrText>
        </WizrFlexLayout>
        <WizrToggleButton
          isEnabled={getStatus('tags')}
          onChange={(e: boolean) => doUpdateDataFields('tags', 'enabled', e)}
        />
        <StyledDropDown
          options={dropDownConverter(tags)}
          placeHolder='Select Group'
          value={getValue('tags', 'folder_id')}
          onChange={(value: any) => doUpdateDataFields('tags', 'folder_id', value)}
        />
        <StyledInput
          placeholder='Mention zendesk ID'
          value={getValue('tags', 'zendesk_id')}
          onChange={(value: any) => doUpdateDataFields('tags', 'zendesk_id', value)}
        />
      </WizrFlexLayout>
      <WizrFlexLayout background='white' textAlign='left' flexDirection='row'>
        <WizrFlexLayout style={{ maxWidth: '27%' }} background='white' textAlign='left'>
          <WizrText type='body2' textColor='#707276'>
            Priority
          </WizrText>
        </WizrFlexLayout>
        <WizrToggleButton
          isEnabled={getStatus('priority')}
          onChange={(e: boolean) => doUpdateDataFields('priority', 'enabled', e)}
        />
        <StyledDropDown
          options={dropDownConverter(priority)}
          placeHolder='Select Group'
          value={getValue('priority', 'folder_id')}
          onChange={(value: any) => doUpdateDataFields('priority', 'folder_id', value)}
        />
        <StyledInput
          placeholder='Mention zendesk ID'
          value={getValue('priority', 'zendesk_id')}
          onChange={(value: any) => doUpdateDataFields('priority', 'zendesk_id', value)}
        />
      </WizrFlexLayout>
      <WizrFlexLayout background='white' textAlign='left' flexDirection='row'>
        <WizrFlexLayout style={{ maxWidth: '27%' }} background='white' textAlign='left'>
          <WizrText type='body2' textColor='#707276'>
            Sentiment
          </WizrText>
        </WizrFlexLayout>
        <WizrToggleButton
          isEnabled={getStatus('sentiment')}
          onChange={(e: boolean) => doUpdateDataFields('sentiment', 'enabled', e)}
        />
        <StyledDropDown
          options={dropDownConverter(sentiment)}
          placeHolder='Select Group'
          value={getValue('sentiment', 'folder_id')}
          onChange={(value: any) => doUpdateDataFields('sentiment', 'folder_id', value)}
        />
        <StyledInput
          placeholder='Mention zendesk ID'
          value={getValue('sentiment', 'zendesk_id')}
          onChange={(value: any) => doUpdateDataFields('sentiment', 'zendesk_id', value)}
        />
      </WizrFlexLayout>
      <WizrFlexLayout background='white' textAlign='left' flexDirection='row'>
        <WizrFlexLayout style={{ maxWidth: '27%' }} background='white' textAlign='left'>
          <WizrText type='body2' textColor='#707276'>
            Escalate{' '}
          </WizrText>
        </WizrFlexLayout>
        <WizrToggleButton
          isEnabled={getStatus('escalate')}
          onChange={(e: boolean) => doUpdateDataFields('escalate', 'enabled', e)}
        />
        <WizrFlexLayout
          style={{ maxWidth: '24%' }}
          background='white'
          textAlign='center'
          justifyContent='center'
        >
          <WizrText type='body2'> Yes/No</WizrText>
        </WizrFlexLayout>
        <StyledInput
          placeholder='Mention zendesk ID'
          value={getValue('escalate', 'zendesk_id')}
          onChange={(value: any) => doUpdateDataFields('escalate', 'zendesk_id', value)}
        />
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

const AgentAssist = () => {

  const location = useLocation()
  const params = useParams()
  const featureSettings = useFeatureSettingSelector()
  const integrationList = useGetIntegrationList()
  const [integrationName, SetIntegrationName] = useState('')

  const id = params?.id
  const currentFeature = featureSettings?.feature_settings?.filter((item: any) => item?.id === id)

  const [currentFeatureData, setCurrentFeatureData] = useState(currentFeature && currentFeature[0])
  const [dataField, setDataFields] = useState(currentFeature?.data_fields)
  const [featureApps, setFeatureApps] = useState(currentFeature?.feature_apps)

  const loading = useContentLoadingSelector()
  const navigate = useNavigate()
  const type = currentFeatureData?.setting_type
  useEffect(() => {
    dispatchGetPackageDetails('autotag')
    dispatchGetPackageDetails('agentassist')

    dispatchTempListFolders('past_tickets')
    dispatchTempListFolders('faq')
    // dispatchListFolders('past_tickets')
    // dispatchListFolders('faq')
    dispatchListFolders('knowledge_base')
    dispatchMasterIntegrationList()
    dispatchIntegrationList()
    dispatchFeatureSettings()

  }, [])
  const autoTagApps = useappDataSelector()
  // const pastTicketApps= usePastTicketAppsSelector()
  const pastTicketKnowledgeBase = usePastTicketDataSelector()
  const agentAssistApps = useAgentAssistAppSelector()
  const faqKnowledgeBase = useFaqDataSelector()
  const knowledgeBase = useContentsSelector()
  // const faqApps = useFaqAppsSelector()
  const teams: string[] = useTenantTeamSelectors()

  const persona = featureSettings?.company_settings?.persona

  const masterIntegrations = useGetMasterIntegrationList()
  const getIcon = (item: any) => {
    switch (item?.integration_key) {
      case 'zendesk':
        return <ZendeskIcon />
      default:
        return null
    }
  }

  useEffect(() => {
    // const id = currentFeatureData?.integration_id
    const tenandId = getTenantId()
    dispatchGetTenantGroups(tenandId)

    const name = integrationList?.find((item: any) => item?.id === id)?.display_name
    //SetIntegrationName(name)
    setDataFields(currentFeatureData?.data_fields)
    setFeatureApps(currentFeatureData?.feature_apps)
  }, [currentFeatureData])

  const dropDownConverterIntegration = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: (
          <div style={{ alignItems: 'center', display: 'flex', gap: '10px' }}>
            <div>
              {getIcon(item)}
              {/* Render the icon component */}
            </div>
            <div>
              {' '}
              {item.display_name} {/* Render the label text */}
            </div>
          </div>
        ),
        value: item.id,
      }))
    return result
  }

  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item?.app_name,
        value: item?.app_id,
      }))
    return result
  }

  const dropDownConverterFolders = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: <WizrFlexLayout flexDirection = 'row' justifyContent ='flex-start' alignItems='center' background='transparent' >
          <FolderIcon stroke ="#00C8CA"/>
          <WizrText type = 'body2'>{item?.folder_name}</WizrText>
          </WizrFlexLayout>,
        value: item?.folder_id,
      }))
    return result
  }

  const dropDownConverterPersona = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item?.name,
        value: item?.persona_id,
      }))
    return result
  }
  const dropDownConverterTeams = (value: string[]) => {
    const result: any =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item?.team_name,
        value: item?.team_key,
      }))
    const newKey: any = {
      label: 'All',
      value: 'all'
    }
    const a = result ? [...result, newKey] : []
    return a
  }
  const doUpdateFields = (name: any, value: any) => {

    setCurrentFeatureData({
      ...currentFeatureData,
      [name]: value,
    })
  }

  const doUpdateDataFields = (type: any, key: any, value: any) => {

    const data = dataField.map((item: any, index: any) => {
      if (item?.key_name === type) {
        // If the current index matches the one to update

        return {
          ...item, // Keep all properties unchanged
          [key]: value, // Update the enabled property
        }
      } else {
        // For other indexes, return the item unchanged
        return item
      }
    })

    setDataFields(data)
    setCurrentFeatureData({ ...currentFeatureData, data_fields: data })
  }
  // intents and catogories should have same value
  const doUpdateIntentCatogoriesFields = (key: any, value: any) => {
    const intents = dataField.find((item: any) => item?.key_name === "intent")
    const categories = dataField.find((item: any) => item?.key_name === "categories")
    const rest = dataField.filter((item: any) => (item?.key_name !== "categories" && item?.key_name !== "intent"))
    const intentUpdated = { ...intents, [key]: value }
    const categoriesUpdated = { ...categories, [key]: value }
    const data = [intentUpdated, categoriesUpdated, ...rest]

    setDataFields(data)
    setCurrentFeatureData({ ...currentFeatureData, data_fields: data })
  }

  const doUpdateFeatureApps = (type: any, key: any, value: any) => {

    

    let featureAppsUpdated = featureApps;
    // Filter out items from featureApps where app_key !== "auto_tag"
    if (key === "enabled" && value === false) {
      const autoTagApps = featureApps.filter((item: any) => item.app_key !== type);

      // Create a new array of auto_tag objects based on value
      const currentValue = {
        app_key: type,
        app_id: '',
        enabled: false,
        llm_folder_id_list: []
      }

      featureAppsUpdated = [...autoTagApps, currentValue];
    }
    else {
      const currentData = featureApps.find((item: any) => item.app_key === type);
      const restData = featureApps.filter((item: any) => item.app_key !== type);
      const updatedData = { ...currentData, [key]: value };
      featureAppsUpdated = [...restData, updatedData];
    }
    setFeatureApps(featureAppsUpdated);
    setCurrentFeatureData({ ...currentFeatureData, feature_apps: featureAppsUpdated });

  }

  // const enableApps = (type: any, key: any, value: any)=>{
  //   const currentData = featureApps.find((item: any) => item.app_key === type);
  //    const restData = featureApps.filter((item: any) => item.app_key !== type);
  //    const updatedData = { ...currentData, [key]: value };
  //     const featureAppsUpdated = [...restData, updatedData];


  //   setFeatureApps(featureAppsUpdated);
  //   setCurrentFeatureData({ ...currentFeatureData, feature_apps: featureAppsUpdated });
  // }
  const getFeatureAppValues = (type: any, field?: any) => {
    if (type === "auto_tag") {
      const value = featureApps && featureApps.filter((item: any) => item.app_key === type)
      const apps = value && value.map((item: any) => item?.app_id)
      return apps
    }
    else {
      const value = featureApps && featureApps.find((item: any) => item.app_key === type)
      return value?.[field]

    }
  }

  const onSaveAction = () => {
    const payload = {
      id: featureSettings?.id,
      feature_settings: currentFeatureData
    }
    dispatchUpdateFeatureSettings(payload)
  }


  useEffect(() => {
    const featureId = params?.id

    const currentFeature = featureSettings?.feature_settings?.filter((item: any) => item?.id === featureId)
    const feature = currentFeature && currentFeature[0]
    setCurrentFeatureData(feature)
    const id = feature?.integration_id
    const name = integrationList?.find((item: any) => item?.id === id)?.display_name
    SetIntegrationName(name)
    setDataFields(feature?.data_fields)
  }, [featureSettings, integrationList])


  const setIntegrations = (key: any, value: any) => {
    // doUpdateFields('integration_type', value)
    const integrationCurrent = integrationList?.find((item: any) => item?.id === value)
    SetIntegrationName(integrationCurrent?.display_name)
    if (integrationCurrent?.integration_key === 'zendesk') {
      const Zendeskdata = masterIntegrations.find((item: any) => item.integration_key === 'zendesk')?.feature_settings_config
      const agentAssistData = Zendeskdata?.find((item: any) => item.setting_type === 'agentassist')
      setDataFields(agentAssistData?.data_fields)
      setCurrentFeatureData({
        ...currentFeatureData,
        data_fields: agentAssistData?.data_fields,
        end_point_url: agentAssistData?.end_point_url,
        integration_type: integrationCurrent?.integration_key,
        integration_id: value
      })

      // setCurrentFeatureData({...currentFeatureData,endpoint_url:autoTagData?.endpoint_url})
      // doUpdateFields('endpoint_url',autoTagData?.endpoint_url)
    }
  }
  const generateEndPoint = () => {
    navigate('/endpoint', { state: { details: currentFeatureData } })
  }

  const getAppStatus = (key: any) => {
    const status = featureApps && featureApps.find((item: any) => item.app_key === key)
    return status?.enabled
  }

  return (
    <div
      style={{
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        overflow: 'auto',
        width: '100%',
      }}
    >
      <WizrFlexLayout justifyContent='space-between' flexDirection='row' flex={1.4}>
        <WizrUserJourney
          userJourney={[
            {
              title: (
                <WizrButton
                  padding='0px'
                  type='text'
                  text={
                    <WizrText type='h6' textColor='#707276'>
                      Cx Settings
                    </WizrText>
                  }
                  onClick={() => navigate(-1)}
                  icon={<Hamburger />}
                />
              ),
            },
            {
              title: (
                // <WizrButton
                //   padding='0px'
                //   type='text'
                //   text={
                //     <WizrText type='h6'>{`${currentFeatureData?.setting_name} - Settings`}</WizrText>
                //   }
                //   onClick={() => navigate(-1)}
                // />
                <StyledInputName type="text" value={currentFeatureData?.setting_name} onChange={(e: any) => { doUpdateFields('setting_name', e.target.value) }} />

              ),
              isCurrent: true,
            },
          ]}
        />
        <WizrFlexLayout flexDirection='row' justifyContent='flex-end' style={{ gap: '20px' }}>
          <WizrButton
            type='text'
            icon={<Analytics />}
            style={{ marginTop: '20px', borderRadius: '16px', backgroundColor: '#EAECF1' }}
            text={<WizrText textColor='black' type='button'>{`App Analytics`}</WizrText>}
            onClick={() => { }}
          >
            {' '}
          </WizrButton>
          <Popover content={<GenerateExtension url={currentFeatureData?.end_point_url} />} title='' trigger='click'>
            <WizrButton
              type='primary'
              style={{ marginTop: '20px', borderRadius: '16px' }}
              text={<WizrText type='button'>{'Generate Extension'}</WizrText>}
              onClick={() => { }}
            >
              {' '}
            </WizrButton></Popover>
        </WizrFlexLayout>
      </WizrFlexLayout>
      <WizrFlexLayout
        background='white'
        flex={11}
        style={{ borderRadius: '16px', padding: '20px 40px' }}
        textAlign='left'
      >
        <WizrText type='sub1'> Settings</WizrText>
        <WizrFlexLayout
          flexDirection='column'
          background='white'
          style={{ width: '75%', marginTop: '10px', gap: '40px' }}
          textAlign='left'
        >
          <WizrFlexLayout
            background='white'
            textAlign='left'
          >
            <WizrText type='body2' textColor='#707276'>
              {' '}
              Auto tag App
            </WizrText>
            <StyledDropDownDefault
              //  mode='multiple'
              placeHolder='Select App'
              options={dropDownConverter(autoTagApps)}
              value={getFeatureAppValues('auto_tag')}
              onChange={(value: any) => {
                doUpdateFeatureApps('auto_tag', 'app_id', value)
              }}
            />

          </WizrFlexLayout>
          <WizrFlexLayout background='white' >
            <WizrFlexLayout flexDirection='row' style={{ gap: '30px' }} background='white'>
              <WizrFlexLayout
                background='white'
                textAlign='left'
                flexDirection='row'
                style={{ marginTop: '10px' }}
              >
                <WizrText type='body2' textColor='#707276'>
                  {' '}
                  Past tickets search
                </WizrText>
                <WizrToggleButton
                  isEnabled={getAppStatus("past_tickets_search")}
                  onChange={(e: boolean) => {
                    doUpdateFeatureApps('past_tickets_search', 'enabled', e)
                  }}
                />
              </WizrFlexLayout>
              <WizrFlexLayout
                background='white'
                textAlign='left'
                flexDirection='row'
                style={{ marginTop: '10px' }}
              >
                <WizrText type='body2' textColor='#707276'>
                  {' '}
                  FAQ Search              </WizrText>
                <WizrToggleButton
                  isEnabled={getAppStatus('faq_search')}
                  onChange={(e: boolean) => {
                    doUpdateFeatureApps('faq_search', 'enabled', e)
                    // doUpdateFeatureApps('faq_search','app_id', '')
                    // doUpdateFeatureApps('faq_search','llm_folder_id_list', [])
                  }}
                />
              </WizrFlexLayout></WizrFlexLayout>
            <WizrFlexLayout flexDirection='row' style={{ gap: '30px' }} background='white'>
              <WizrFlexLayout
                background='white'
                textAlign='left'
                flexDirection='row'
                style={{ marginTop: '10px' }}
              >
                <StyledDropDownDefault
                  disabled={getAppStatus('past_tickets_search') ? false : true}
                  placeHolder='Select Past Ticket Apps'
                  options={dropDownConverter(agentAssistApps)}
                  value={getFeatureAppValues('past_tickets_search', 'app_id')}
                  onChange={(value: any) => {
                    doUpdateFeatureApps('past_tickets_search', 'app_id', value)
                  }}
                />

              </WizrFlexLayout>
              <WizrFlexLayout
                background='white'
                textAlign='left'
                flexDirection='row'
                style={{ marginTop: '10px' }}
              >
                <StyledDropDownDefault
                  disabled={getAppStatus('faq_search') ? false : true}
                  placeHolder='Select FAQ'
                  options={dropDownConverter(agentAssistApps)}
                  value={getFeatureAppValues('faq_search', 'app_id')}
                  onChange={(value: any) => {
                    doUpdateFeatureApps('faq_search', 'app_id', value)
                  }}
                />

              </WizrFlexLayout></WizrFlexLayout>
            <WizrFlexLayout flexDirection='row' style={{ gap: '30px' }} background='white'>
              <WizrFlexLayout
                background='white'
                textAlign='left'
                flexDirection='row'
                style={{ marginTop: '10px' }}
              >
                <StyledDropDownDefault
                  disabled={getAppStatus('past_tickets_search') ? false : true}
                  mode='multiple'
                  placeHolder='Select Past Ticket knowledge Base'
                  options={dropDownConverterFolders(pastTicketKnowledgeBase)}
                  value={getFeatureAppValues('past_tickets_search', 'llm_folder_id_list')}
                  onChange={(value: any) => {
                    doUpdateFeatureApps('past_tickets_search', 'llm_folder_id_list', value)
                  }}
                />

              </WizrFlexLayout>
              <WizrFlexLayout
                background='white'
                textAlign='left'
                flexDirection='row'
                style={{ marginTop: '10px' }}
              >
                <StyledDropDownDefault
                  disabled={getAppStatus('faq_search') ? false : true}
                  mode='multiple'
                  placeHolder='Select FAQ knowledge Base'
                  options={dropDownConverterFolders(faqKnowledgeBase)}
                  value={getFeatureAppValues('faq_search', 'llm_folder_id_list')}
                  onChange={(value: any) => {
                    doUpdateFeatureApps('faq_search', 'llm_folder_id_list', value)
                  }}
                />

              </WizrFlexLayout></WizrFlexLayout>
            <WizrFlexLayout flexDirection='row' style={{ gap: '30px' }} background='white'>
              <WizrFlexLayout
                background='white'
                textAlign='left'
                flexDirection='row'
                style={{ marginTop: '10px' }}
              >
                <WizrText type='body2' textColor='#707276'>
                  {' '}
                  Knowledge Assist Response               </WizrText>
                <WizrToggleButton
                  isEnabled={getAppStatus('knowledge_assist_response')}
                  onChange={(e: boolean) => {
                    doUpdateFeatureApps('knowledge_assist_response', 'enabled', e)
                  }}
                />
              </WizrFlexLayout>
              <WizrFlexLayout
                background='white'
                textAlign='left'
                flexDirection='row'
                style={{ marginTop: '10px' }}
              >
                <WizrText type='body2' textColor='#707276'>
                  {' '}
                  Response Generation using FAQ/Past tickets            </WizrText>
                <WizrToggleButton
                  isEnabled={getAppStatus("response_generation")}

                  onChange={(e: boolean) => {
                    doUpdateFeatureApps('response_generation', 'enabled', e)
                  }}
                />
              </WizrFlexLayout></WizrFlexLayout>
            <WizrFlexLayout flexDirection='row' style={{ gap: '30px' }} background='white'>
              <WizrFlexLayout
                background='white'
                textAlign='left'
                flexDirection='column'
                style={{ marginTop: '10px', gap: '10px' }}
              >
                <StyledDropDownDefault
                  disabled={getAppStatus('knowledge_assist_response') ? false : true}
                  placeHolder='Select KnowledgeAssist Response Apps'
                  options={dropDownConverter(agentAssistApps)}
                  value={getFeatureAppValues('knowledge_assist_response', 'app_id')}
                  onChange={(value: any) => {
                    doUpdateFeatureApps("knowledge_assist_response", 'app_id', value)
                  }}
                />

              </WizrFlexLayout>
              <WizrFlexLayout
                background='white'
                textAlign='left'
                flexDirection='row'
                style={{ marginTop: '10px' }}
              >
                <StyledDropDownDefault
                  disabled={getAppStatus('response_generation') ? false : true}
                  placeHolder='Select Response Generation Apps'
                  options={dropDownConverter(agentAssistApps)}
                  value={getFeatureAppValues("response_generation", 'app_id')}
                  onChange={(value: any) => {
                    doUpdateFeatureApps("response_generation", 'app_id', value)
                  }}
                />

              </WizrFlexLayout>
            </WizrFlexLayout>
            <WizrFlexLayout flexDirection='row' style={{ gap: '30px' }} background='white'>
              <WizrFlexLayout
                background='white'
                textAlign='left'
                flexDirection='row'
                style={{ marginTop: '10px' }}
              >
                <StyledDropDownDefault
                  disabled={getAppStatus('knowledge_assist_response') ? false : true}
                  mode='multiple'
                  placeHolder='Select KnowledgeAssist Response Knowledge Base'
                  options={dropDownConverterFolders(knowledgeBase)}
                  value={getFeatureAppValues('knowledge_assist_response', 'llm_folder_id_list')}
                  onChange={(value: any) => {
                    doUpdateFeatureApps("knowledge_assist_response", 'llm_folder_id_list', value)
                  }}
                />
              </WizrFlexLayout>
              <WizrFlexLayout
                background='white'
                textAlign='left'
                flexDirection='row'
                style={{ marginTop: '10px' }}
              >
                <></>
              </WizrFlexLayout></WizrFlexLayout>
              <WizrFlexLayout flexDirection='row' style={{ gap: '30px' }} background='white'>
            <WizrFlexLayout
              background='white'
              textAlign='left'
              flexDirection='row'
              style={{ marginTop: '10px' }}
            >
              <WizrText type='body2' textColor='#707276'>{' '}Tone and Length</WizrText>
              <WizrToggleButton
                isEnabled={getAppStatus('tone_and_length')}
                onChange={(e: boolean) => {
                  doUpdateFeatureApps('tone_and_length', 'enabled', e)
                }}
              />
            </WizrFlexLayout>
            <WizrFlexLayout
              background='white'
              textAlign='left'
              flexDirection='row'
              style={{ marginTop: '10px' }}
            >
              <WizrText type='body2' textColor='#707276'>{' '}Ticket summary</WizrText>
              <WizrToggleButton
                isEnabled={getAppStatus("ticket_summary")}
                onChange={(e: boolean) => {
                  doUpdateFeatureApps('ticket_summary', 'enabled', e)
                }}
              />
            </WizrFlexLayout>
          </WizrFlexLayout>
          <WizrFlexLayout flexDirection='row' style={{ gap: '30px' }} background='white'>
              <WizrFlexLayout
                background='white'
                textAlign='left'
                flexDirection='column'
                style={{ marginTop: '10px', gap: '10px' }}
              >
                <StyledDropDownDefault
                  disabled={getAppStatus('tone_and_length') ? false : true}
                  placeHolder='Select Tone and length'
                  options={dropDownConverter(agentAssistApps)}
                  value={getFeatureAppValues('tone_and_length', 'app_id')}
                  onChange={(value: any) => {
                    doUpdateFeatureApps("tone_and_length", 'app_id', value)
                  }}
                />

              </WizrFlexLayout>
              <WizrFlexLayout
                background='white'
                textAlign='left'
                flexDirection='row'
                style={{ marginTop: '10px' }}
              >
                <StyledDropDownDefault
                  disabled={getAppStatus('ticket_summary') ? false : true}
                  placeHolder='Select Ticket summary'
                  options={dropDownConverter(agentAssistApps)}
                  value={getFeatureAppValues("ticket_summary", 'app_id')}
                  onChange={(value: any) => {
                    doUpdateFeatureApps("ticket_summary", 'app_id', value)
                  }}
                />

              </WizrFlexLayout>
            </WizrFlexLayout>
          </WizrFlexLayout>

        </WizrFlexLayout>



        <WizrFlexLayout
          flexDirection='column'
          background='white'
          style={{ width: '75%', marginTop: '10px', gap: '40px' }}
          textAlign='left'
        >

          <WizrFlexLayout background='white' >
            <WizrFlexLayout flexDirection='row' style={{ gap: '30px' }} background='white'>
              <WizrFlexLayout
                background='white'
                textAlign='left'
                flexDirection='row'
                style={{ marginTop: '10px' }}
              >
                <WizrText type='body2' textColor='#707276'>
                  {' '}
                  Agent Assist Enable
                </WizrText>
                <WizrToggleButton
                  isEnabled={currentFeatureData?.status === 'active' ? true : false}
                  onChange={(e: boolean) => {
                    doUpdateFields('status', e ? 'active' : 'disabled')
                  }}
                />
              </WizrFlexLayout>
              <WizrFlexLayout
                background='white'
                textAlign='left'
                flexDirection='row'
                style={{ marginTop: '10px' }}
              >
                <></>
              </WizrFlexLayout></WizrFlexLayout>
            <WizrFlexLayout flexDirection='row' style={{ gap: '30px' }} background='white'>
              <WizrFlexLayout
                background='white'
                textAlign='left'
                flexDirection='column'
                style={{ marginTop: '10px', gap: '10px' }}
              >
                <WizrText type='body2' textColor='#707276'>
                  {' '}
                  Integration
                </WizrText>
                {/* <StyledDropDownDefault
              placeHolder='Select Integration'
              options={dropDownConverterIntegration(integrationList)}
              value={integrationName}
              onChange={(value: any) => {
                setIntegrations('integration_type', value)
              }}
            /> */}
                <StyledDropDownDefault
                  placeHolder='Select Integration'
                  options={dropDownConverterIntegration(integrationList)}
                  value={integrationName}
                  onChange={(value: any) => {
                    setIntegrations('integration_type', value)
                  }}
                />
                <WizrText type='body2' textColor='#707276'>
                  {' '}
                  Access
                </WizrText>
                <StyledDropDownDefault
                  mode='multiple'
                  placeHolder='Select Persona'
                  options={dropDownConverterTeams(teams)}
                  value={currentFeatureData?.teams}
                  onChange={(value: any) => {
                    doUpdateFields('teams', value)
                  }}
                />
              </WizrFlexLayout>
              <WizrFlexLayout
                background='white'
                textAlign='left'
                flexDirection='row'
                style={{ marginTop: '10px' }}
              >
                <></>

              </WizrFlexLayout></WizrFlexLayout>



          </WizrFlexLayout>
          {/* end */}
        </WizrFlexLayout>



        <WizrFlexLayout
          textAlign='left'
          style={{ minHeight: '400px', gap: '30px', marginTop: '40px' }}
          background='white'
        >
          <WizrText type='sub1'> Data Fields</WizrText>
          <WizrFlexLayout style={{ width: '70%' }}>
            {currentFeatureData?.integration_type || currentFeatureData?.integration_type !== '' ? (
              <DataFields
                currentFeatureData={currentFeatureData}
                doUpdateDataFields={doUpdateDataFields}
                dataField={dataField}
                featureSettings={featureSettings}
                doUpdateIntentCatogoriesFields={doUpdateIntentCatogoriesFields}
              />
            ) : (
              <></>
            )}
          </WizrFlexLayout>
          <WizrFlexLayout textAlign='left' background='white' style={{ width: '27%', gap: '10px' }}>
            <WizrText type='body2'>Personas</WizrText>

            <StyledDropDownDefault
              placeHolder='Select Persona'
              options={dropDownConverterPersona(persona)}
              value={currentFeatureData?.persona_id}
              onChange={(value: any) => {
                doUpdateFields('persona_id', value)
              }}
            />
          </WizrFlexLayout>
          <WizrFlexLayout textAlign='left' background='white' style={{ width: '80%', gap: '10px' }}>
            <WizrText type='body2'>About the Company</WizrText>

            {/* <WizrTextArea
              background='#F2F4F9'
              border='0px #F2F4F9'
              minRows={8}
              value={currentFeatureData?.feature_params?.about_the_company}
              onChange={(value: any) => {}}
              placeholder='Briefly explain what the company is and what it does in 3 to 5 lines. This will be used to set the context to the AI model'
            /> */}
            <WizrFlexLayout background='#F2F4F9' textAlign='left' style={{ padding: '20px', minHeight: "200px" }}>
              <WizrText type='body2' textColor='#999B9F'>{currentFeatureData?.feature_params?.about_the_company}</WizrText>
            </WizrFlexLayout>
          </WizrFlexLayout>
          <WizrFlexLayout alignItems='flex-end' background='white'>
            <WizrButton
              disabled={loading}
              type='primary'
              text={'Save'}
              style={{ width: '70px' }}
              onClick={() => {
                onSaveAction()
              }}
            />
          </WizrFlexLayout>
        </WizrFlexLayout>
      </WizrFlexLayout>
    </div>
  )
}
export default AgentAssist