import { Modal } from "antd"
import DoclogoIcon from "../../assets/icons/Doclogo"
import FileIcon from "../../assets/icons/FileIcon"
import Mp3logoIcon from "../../assets/icons/Mp3logo"
import Mp4logoIcon from "../../assets/icons/Mp4logo"
import PdflogoIcon from "../../assets/icons/Pdflogo"
import UrllogoIcon from "../../assets/icons/UrllogoIcon"
import { MODAL_TYPES, useWizrModalContext } from "../../providers/modal/WizrModalProvider"
import { dispatchNotificationState } from "../../redux/actions/common"
import { wizrAzureConnectorInstance } from "../../utils/wizrAzureConnector"
import WizrButton from "../WizrButton"
import { useState } from "react"
import { useAuth } from "../../authContext"

  
  const IconComponentMain = ({ fileType }: any) => {
    const folderId = localStorage.getItem('folderId')
    const iconList: any = {
        PDF: PdflogoIcon,
        DOC: DoclogoIcon,
        MP3: Mp3logoIcon,
        MP4: Mp4logoIcon,
        url: UrllogoIcon,
        URL: UrllogoIcon,
        TEXT: FileIcon,
      }
    if (iconList[fileType]) {
      const IconComponent = iconList[fileType]
      return <IconComponent />
    } else {
      // If the fileType is not in the list, render a default icon or handle the case accordingly.
      return <FileIcon />
    }
  }
const FileName =({details,text}:any):JSX.Element=>{
    //const { showModal, hideModal } = useWizrModalContext()
    const [visible, setVisible] = useState(false);
    const [file,setFile] = useState("")

    const { userPermission, hasAcces } = useAuth();


    const showModal = async () => {
      if (details?.content_type!=='url'){
      dispatchNotificationState({
        message: 'Success',
        description: `File Preview in progress..`,
        type: 'success',
      })
      const folderId = localStorage.getItem('folderId')
      // const timestamp = details?.file_path
      const blobResponse = await wizrAzureConnectorInstance.getBlob(folderId, details)
      if (blobResponse) {
        const fileURL = URL.createObjectURL(blobResponse)
        // showModal(MODAL_TYPES.PREVIEW_FILE, {
        //   fileUrl: fileURL,
        //   onClose: hideModal,
        // })
        setFile(fileURL)
        setVisible(true);

      }}
      
      else{
        const pageUrl= details?.file_name
        if (pageUrl) {
        window.open(pageUrl, '_blank');
        }      
}
    
    };
  
    const handleOk = () => {
      setVisible(false);
    };
  
    const handleCancel = () => {
      setVisible(false);
    };
    const FilePreviewer=async ()=>{
        try {
            dispatchNotificationState({
              message: 'Success',
              description: `File Preview in progress..`,
              type: 'success',
            })
            const folderId = localStorage.getItem('folderId')
            const blobResponse = await wizrAzureConnectorInstance.getBlob(folderId, details)
            if (blobResponse) {
              const fileURL = URL.createObjectURL(blobResponse)
              // showModal(MODAL_TYPES.PREVIEW_FILE, {
              //   fileUrl: fileURL,
              //   onClose: hideModal,
              // })
            }
          } catch (error) {
            dispatchNotificationState({
              message: 'error',
              description: `File Preview Failed`,
              type: 'error',
            })
          }
    }
  return (
    <div style={{ maxWidth: '800px', overflow: 'auto' }}>
      <WizrButton
      style={{whiteSpace: "normal", wordWrap: "break-word" ,height:"auto",textAlign:"left"}}
      onClick={()=>{showModal()}}
        padding={'0px'}
        icon={
          <IconComponentMain
            fileType={details.file_type !== '' ? details.file_type : details.content_type}
          />
        }
        text={text}
        type='text'
        disabled={hasAcces('view_file') === true ? false : true}
      />
      
       <Modal
        title="My Modal"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        style={{top:0,bottom:0}}
        bodyStyle={{ height: 'calc(100vh - 108px)', overflow: 'auto' }}

        //height={1000} // Set the height property here
      >
        <div style={{ height:"100%",width:"1000px" }}>
          <iframe
            width="940"
            height="100%"
            src={file}
          ></iframe>
        </div>
      </Modal>
    </div>
  )}
  export default FileName