import { Slider } from 'antd'
import type { SliderMarks } from 'antd/es/slider'
import { styled } from 'styled-components'
import WizrText from './WizrText'

interface IWizrSlider {
  minLabel: string
  maxLabel: string
  defaultValue?: number
  disabled?: boolean
  min: number
  max: number
  step?: number
  onSliderChange?: (value: any) => void
  value?: number
}

const StyledSlider = styled(Slider)`
  white-space: nowrap;
  width: 100% !important;
  .ant-slider-mark-text-active {
    margin-left: 35px;
  }
  padding-right: 10px;
  .ant-slider-mark-text {
    margin-right: 20px !important;
  }
`
const WizrSlider = ({
  value,
  minLabel,
  maxLabel,
  defaultValue,
  disabled,
  min,
  max,
  step = 0.0001,
  onSliderChange,
}: IWizrSlider): JSX.Element => {
  const marks: SliderMarks =
    max === 1
      ? {
          0: {
            label: <WizrText type='overline'>{minLabel}</WizrText>,
          },
          1: {
            label: <WizrText type='overline'>{maxLabel}</WizrText>,
          },
        }
      : {
          0: {
            label: <WizrText type='overline'>{minLabel}</WizrText>,
          },
          2: {
            label: <WizrText type='overline'>{maxLabel}</WizrText>,
          },
        }

  const formatTooltip = (value: any) => {
    return `${value.toFixed(2)}`
  }

  const trackStyle = {
    backgroundColor: 'black', // Set the desired background color
    height: 1, // Set the desired height of the track
  }

  return (
    <StyledSlider
      tipFormatter={formatTooltip}
      marks={marks}
      defaultValue={defaultValue}
      disabled={disabled}
      min={min}
      max={max}
      step={step}
      value={value}
      trackStyle={trackStyle}
      onChange={onSliderChange}
    />
  )
}

export default WizrSlider
