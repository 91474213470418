const HelpIcon = (props: any) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
    <path
      stroke='#1D1F22'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M1 9V7a6 6 0 0 1 6-6h2a6 6 0 0 1 6 6v2a6 6 0 0 1-6 6H7a6 6 0 0 1-6-6Z'
      clipRule='evenodd'
    />
    <path
      stroke='#1D1F22'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M6 5.64C6 4.55 6.9 3.95 8.247 4A1.816 1.816 0 0 1 10 5.8a2.607 2.607 0 0 1-1.137 2.29A1.922 1.922 0 0 0 8 10'
    />
    <path fill='#1D1F22' d='M8 12.5a.5.5 0 1 1 .001-1.001A.5.5 0 0 1 8 12.5Z' />
    <path fill='#1D1F22' d='M8 11a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z' />
  </svg>
)
export default HelpIcon
