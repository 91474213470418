import {
  dispatchListFolders,
  dispatchCreateFolder,
  dispatchTicketDetails,
  dispatchUploadFileUsingLink,
  dispatchListUserRows,
} from '../redux/actions/content'
import {
  useContentsSelector,
  useContentLoadingSelector,
  useTicketDetailsSelector,
  useFaqPtTicketDataSelector,
  useFaqDataSelector,
  usePastTicketDataSelector,
} from '../redux/selectors/content'
import { useEffect, useState } from 'react'
import WizrTable from '../components/WizrTable'
import { Card, Divider, Spin } from 'antd'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrUserJourney from '../components/WizrUserJourney'
import WizrButton from '../components/WizrButton'
import { useWizrModalContext, MODAL_TYPES } from '../providers/modal/WizrModalProvider'
import CreateNew from '../assets/icons/CreateNew'
import { dispatchNotificationState } from '../redux/actions/common'
import WizrText from '../components/WizrText'
import { useAuth } from '../authContext'
import styled from 'styled-components'
import { getTenantId, pastTickets, tickets, uploadCheck } from '../utils'
import CloseIcon from '../assets/icons/Close'
import WizrTag from '../components/WizrTags'
import WizrAvatar from '../components/WizrAvatar'
import WizrFlexSpacing from '../components/WizrFlexSpacing'
import WizrUploadContainer from '../components/WizrUploadButton'
import { useNavigate, useParams } from 'react-router-dom'
import ImportIcon from '../assets/icons/ImportIcon'
import { v4 as uuidv4 } from 'uuid'
import Tags from '../assets/icons/Tags'

const TicketDetails = ({ rowDetails }: any) => {


    const conversations = rowDetails?.conversation;
    const jsonWithSingleBackslash = conversations?.replace(/\\/g, '"');
    const data = JSON.parse(jsonWithSingleBackslash);
    const array = data[0]
    
    const extractedConversations = array.map((item:any) => ({
        question: item.comment_text,
        user: item.comment_username
      }));
      
  return (
    <StyledCard>
        <WizrFlexLayout>
      <WizrFlexLayout
        flexDirection='row'
        alignItems='space-between'
        background='white'
        justifyContent='space-between'
          >
        <WizrText type='sub1'>Ticket Details</WizrText>
        <WizrButton
          type='link'
          icon={<CloseIcon />}
          onClick={() => dispatchTicketDetails({})}
          style={{ padding: '0px' }}
        ></WizrButton>
      </WizrFlexLayout>
      <WizrFlexLayout background='white'>
        <WizrFlexLayout
          flexDirection='row'
          alignItems='space-between'
          background='white'
          justifyContent='space-between'
        >
          <WizrText type='body2'>Ticket ID :{rowDetails?.ticket_id}</WizrText>
          <WizrText type='body2'>Task</WizrText>
        </WizrFlexLayout>
        <WizrFlexLayout
          flexDirection='row'
          alignItems='space-between'
          background='white'
          justifyContent='space-between'
        >
          <WizrTag background='white' data={['DISABLED']} borderradius='4px' />
        </WizrFlexLayout>
        <WizrAvatar background='transparent' name={rowDetails?.raised_by}></WizrAvatar>
        <WizrFlexLayout flexDirection='row' justifyContent='flex-start' background='white'>
          <WizrTag background='#EF4444' data={[rowDetails?.priority]} borderradius='4px' />
          <WizrTag background='#22C55E' data={[rowDetails?.sentiment]} borderradius='4px' />
        </WizrFlexLayout>
        <WizrFlexLayout
          flexDirection='row'
          alignItems='space-between'
          background='white'
          justifyContent='space-between'
          style={{ marginTop: '5px' }}
        >
          <WizrText type='caption'>Created On</WizrText>
          <WizrText type='caption'>Closed On</WizrText>
        </WizrFlexLayout>
        <WizrFlexLayout
          flexDirection='row'
          alignItems='space-between'
          background='white'
          justifyContent='space-between'
        >
          <WizrText type='body2'>{rowDetails?.ticket_created_on}</WizrText>
          <WizrText type='body2'>{rowDetails?.ticket_closed_on}</WizrText>
        </WizrFlexLayout>
        <Divider style={{ margin: '10px 0px' }} />
        <WizrFlexLayout
          flexDirection='column'
          alignItems='flex-start'
          background='white'
          textAlign='left'
        >
          <WizrText type='body2'>{rowDetails?.subject}</WizrText>
          <WizrText type='caption'>
            {rowDetails?.description}
          </WizrText>
          <StyledFlexSpacing
            justifyContent='space-between'
            flexDirection='column'
            alignItems='center'
            size={20}
            background='white'
          >
            <StyledWizrTag
              background='grey'
              data={rowDetails?.tags.split(',')}
              onClick={(e: any) => {}}
            />
          </StyledFlexSpacing>
        </WizrFlexLayout>
        {extractedConversations?.map((item:any, index:number) => [
    <Divider key={`divider-${index}`} style={{ margin: '10px 0px' }} />,
    <WizrFlexLayout key={`layout-${index}`} flexDirection='column' alignItems='flex-start' background='white'>
        <WizrText type='body2'>{item?.user}</WizrText>
        <WizrFlexLayout flexDirection='row' justifyContent='flex-start' style={{ marginTop: '5px' }}>
            
            <WizrFlexLayout style={{ maxWidth: '100%' }} background='white'  textAlign='left'>
                <WizrText type='caption'>{item?.question}</WizrText>
            </WizrFlexLayout>
        </WizrFlexLayout>
    </WizrFlexLayout>
])}

        
      </WizrFlexLayout>
      </WizrFlexLayout>

    </StyledCard>
  )
}

const Tickets = (): JSX.Element => {
  const { hasAcces } = useAuth()
  const contentsListing = pastTickets
  const isContentLoading = useContentLoadingSelector()
  const { showModal, hideModal } = useWizrModalContext()
  const tableData = useFaqPtTicketDataSelector()
  const showSidebar = useTicketDetailsSelector()
  const params = useParams()
  const folderId = params?.id
  const type = location.pathname.split('/')[1]
  const navigate = useNavigate()
  useEffect(() => {
    dispatchListUserRows(type, folderId)
    dispatchTicketDetails({})
    dispatchListFolders(type)
  }, [])
  let data
  if (type === 'past_tickets') {
    data = usePastTicketDataSelector()
  } else {
    data = useFaqDataSelector()
  }
  const tableDataUpdated = tableData?.map((item: any) => {
    return{
        ...item,
        type: type,
        folderId: folderId
    }})
  const current = data?.find((item: any) => item?.folder_id === folderId)
  const uploadFile = async (fileInfo: any) => {

    const check = uploadCheck(3, fileInfo?.file)
    if(check){
      const name = fileInfo?.file?.name
      const fileUploadPayload = {
        container_id: 4,
        path: type,
        files: [`${name}`],
        metadata: {
          folder_id: folderId,
          file_id: uuidv4(),
          file_name: fileInfo?.file?.name,
          file_path: `${type}/inbox`,
        },
      }
      const formData = new FormData()
      const jsonData = JSON.stringify(fileUploadPayload)
      formData.append('data', jsonData)
      formData.append(name, fileInfo?.fileList[0]?.originFileObj)
      await dispatchUploadFileUsingLink(formData)
    }
  }

  const onImportClick = () => {
    showModal(MODAL_TYPES.IMPORT_PAST_TICKETS, {
      folderId: folderId,
      onCancel: hideModal,
    })
  }

  return (
    <WizrFlexLayout>
      <WizrFlexLayout justifyContent='space-between' alignItems='center' flexDirection='row'>
        <WizrUserJourney
          userJourney={[
            {
              title: (
                <WizrButton
                  type='text'
                  text={
                    <WizrText type='h6'>{type === 'past_tickets' ? 'Past Ticket' : 'FAQ'}</WizrText>
                  }
                  onClick={() => navigate(-1)}
                />
              ),
              isCurrent: true,
            },
            {
              title: (
                <WizrButton
                  type='text'
                  text={<WizrText type='h6'>{current?.folder_name}</WizrText>}
                />
              ),
              isCurrent: true,
            },
          ]}
        />
        <WizrFlexLayout flexDirection='row' justifyContent='flex-end' style={{ gap: '10px' }}>
          {type === 'past_tickets' ? (
            <StyledHeaderWizrButton
              type='link'
              icon={<ImportIcon />}
              onClick={() => onImportClick()}
            />
          ) : (
            <></>
          )}
          <WizrUploadContainer
            onChange={(e: any) => {
              uploadFile(e)
              //   setInputValue({ ...inputValue, [item.name]: e?.file?.name })
            }}
          >
            <WizrButton
              icon={<CreateNew fill='white' />}
              text='Upload CSV'
              // onClick={() => { }

              // }
            />
          </WizrUploadContainer>
        </WizrFlexLayout>
      </WizrFlexLayout>
      <WizrFlexLayout flex={10}>
        {isContentLoading ? (
          <Spin />
        ) : (
          <WizrFlexLayout flexDirection='row' style={{ gap: '5px' }}>
            <StyledWizrTable
              type='tickets'
              dataSource={tableDataUpdated}
              pagination={false}
              size='large'
            />
            {Object.keys(showSidebar).length !== 0 ? <TicketDetails rowDetails = {showSidebar}/> : <></>}
          </WizrFlexLayout>
        )}
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

export default Tickets

const StyledCard = styled(Card)<any>`
  display: flex;
  flex-direction: column;
  .ant-card-body {
    padding: 16px;
  }
  border-radius: 16px;
  width: 350px;
  height: 1200px;
`

const StyledWizrTable = styled(WizrTable)`
  width: -webkit-fill-available;
`

const StyledWizrTag = styled(WizrTag)`
  height: 42px;
  padding: 8px 11px;
  justify-content: space-between;
`

const StyledFlexSpacing = styled(WizrFlexSpacing)`
  .ant-space-item {
    display: flex !important;
    width: 100% !important;
    flex-wrap: wrap;
  }
`
const StyledHeaderWizrButton = styled(WizrButton)`
  background: #dcdee2;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding-right: 8px;
  justify-content: center;
  align-items: center;
  &:hover path {
    stroke: #1d1f22;
  }
`
