import React, { useEffect, useRef } from "react";

function Editor({ onChange, editorLoaded, name, value }: any) {
  const editorRef: any = useRef();
  const { CKEditor, ClassicEditor } = editorRef.current || {};

  useEffect(() => {
    editorRef.current = {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic")
    };
  }, []);

  return (
    <div>
      {editorLoaded ? (
        <CKEditor
        type=""
        name={name}
        editor={ClassicEditor}
        config={{
            ckfinder: {
                // Upload the images to the server using the CKFinder QuickUpload command
                // You have to change this address to your server that has the ckfinder php connector
                uploadUrl: "" // Enter your upload URL
            },
            toolbar: {
                items: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'underline',
                    '|',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'alignment',
                    '|',
                    'link',
                    'unlink',
                    '|',
                    'imageUpload',
                    '|',
                    'undo',
                    'redo',
                    '|',
                    // Add or remove buttons as needed
                ],
                shouldNotGroupWhenFull: true // Allow the toolbar to display all items
            },
        }}
        data={value}
        onChange={(event: any, editor: any) => {
            const data = editor.getData();
            onChange(data);
        }}
    />
      ) : (
        <div>Editor loading</div>
      )}
    </div>
  );
}

export default Editor;
