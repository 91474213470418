import styled from 'styled-components'
import WizrFlexLayout from '../components/WizrFlexLayout'
import { Avatar, Button, Input, Spin, message as antdMessage } from 'antd'
import WizrText from '../components/WizrText'
import WizrButton from '../components/WizrButton'
import RefreshIcon from '../assets/icons/refresh'
import { useEffect, useRef, useState } from 'react'
import {
  useChatBotHistorySelector,
  useChatMessageSelector,
  useContentLoadingSelector,
} from '../redux/selectors/content'
import RobotIcon from '../assets/icons/robot'
import Markdown from 'react-markdown'
import Copy from '../assets/icons/Copy'
import LikeIcon from '../assets/icons/LikeIcon'
import SentIcon from '../assets/icons/sent'
import DislikeIcon from '../assets/icons/DislikeIcon'
import copy from 'copy-to-clipboard'
import wizrlogo from '../assets/icons/wizrlogo'
import WizrIcon from '../components/WizrIcon'
import CloseIcon from '../assets/icons/Close'
import Maximize from '../assets/icons/Maximize'
import CloseIconOutlined from '../assets/icons/CloseIconOutlined'
import Minimize from '../assets/icons/Minimize'
import UploadIcon from '../assets/icons/Upload'
import WizrUploadContainer from '../components/WizrUploadButton'
import { v4 as uuidv4 } from 'uuid'
import { set } from 'react-hook-form'
import {
  dispatchChatMessage,
  dispatchChatMessageState,
  dispatchClearChat,
  dispatchClearChatBotHistory,
  dispatchTestChatBot,
  dispatchTestChatBotAdithyaBirla,
} from '../redux/actions/content'
import { FileImageOutlined, UploadOutlined } from '@ant-design/icons'
import AIIcon from '../assets/icons/AIIcon'
import AIModelIcon from '../assets/icons/AIModelIcon'
import PdflogoIcon from '../assets/icons/Pdflogo'
import { useParams } from 'react-router-dom'
import { Console } from 'console'
import WizrMarkDown from '../components/WizrMarkDown'

const StyledChatWrapper = styled.div<{ view: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: ${(p) => (p.view ? '100%' : '40%')};
  justify-content: center !important;
  border: 2px solid black; /* Set border style: 2px width, solid style, black color */
  border-radius: 16px;
`

const StyledChatHeader = styled.div`
  display: flex;
  color: black;
  text-align: left;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  padding: 0px 10px 10px 10px;
  background: linear-gradient(
    90.32deg,
    #a36bfe -23.96%,
    #568ffe 56.55%,
    #00bdfc 81.5%,
    #02f5db 97.71%
  );
  gap: 10px;
`

const StyledChatContainer = styled.div<{ bulkRun?: boolean }>`
  position: relative;
  flex: 1;

  padding: 0px 12px 12px 12px;
  background-color: #f2f4f9;
  align-content: flex-start;
  scrollbar-width: thin !important;
  scrollbar-color: #00c8ca white !important;

  &::-webkit-scrollbar {
    width: 8px !important; /* Width of the vertical scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00c8ca !important; /* Color of the scrollbar thumb */
    border-radius: 4px !important; /* Radius of the scrollbar thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #6240b4 !important; /* Color of the scrollbar thumb on hover */
  }

  &::-webkit-scrollbar-track {
    background-color: white !important; /* Color of the scrollbar track */
  }
`

const StyledMessage = styled.div<{ isSent: boolean }>`
  display: flex;
  flex-direction: ${({ isSent }) => (isSent ? 'row-reverse' : 'row')};
  align-items: flex-end;
  margin-bottom: 10px;
`

const StyledMessageBubble = styled.div<{ isSent: boolean }>`
  max-width: 90%;
  padding: 10px;
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  align-contents: flex-start;
  whiteSpace: 'nowrap'; /* Allows wrapping within words */
  align-self: flex-start;
  border-radius: ${({ isSent }) => (isSent ? '24px 0px 24px 24px' : '16px 16px 16px 0px')};
  background-color: ${({ isSent }) => (isSent ? '#00C8CA' : 'white')};
  color: ${({ isSent }) => (isSent ? 'white' : 'white')};
`

const StyledAvatar = styled(Avatar)`
  margin: 0 5px;
  background-color: #00c8ca;
`

const StyledInputContainer = styled.div`
  bottom: 0px;
  display: flex;
  align-items: center;
  padding: 0px 0px;
  justify-content: bottom;
`

const StyledInput = styled(Input)`
  flex: 1;
  margin-right: 10px;
  border-radius: 0px;
  border-width: 0px;
  length: 30px;
`
const WizrChatLayout = styled(WizrFlexLayout)`
max-width: 100%
border-bottom: 1px solid #ccc;
gap: 5px;
`

const StyledTextButton = styled(WizrText)`
  text-decoration: underline !important;
`
const StyledButton = styled(WizrButton)`
  width: 20px;
  height: 20px;
`

const ChatBot = (): JSX.Element => {
  const [threadId, setThreadId] = useState<string>('')
  useEffect(() => {
    const sessionId = uuidv4()
    setThreadId(sessionId)
    dispatchClearChat()
    dispatchClearChatBotHistory()
  }, [])
  const loading = useContentLoadingSelector()
  const chatBotHistory = useChatBotHistorySelector()
  const chatContainerRef = useRef<HTMLDivElement | null>(null)
  const chatMessage = useChatMessageSelector() || []
  const [width, setWidth] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [messageState, setMessageState] = useState(chatMessage)

  useEffect(() => {
    setMessageState(chatMessage)    
  }, [chatMessage])
  useEffect(() => {
    if (chatContainerRef?.current) {
      chatContainerRef.current.scrollTop = chatContainerRef?.current?.scrollHeight
    }
  }, [messageState])

  const startFileUpload = (file: any) => {
    console.log(file)
  }
  const params = useParams()
  const chatBotId= params?.id

  const testChatBot = () => {
    dispatchChatMessage({ text: inputValue ? inputValue : '', isSent: true })
    setInputValue('')
    const payload = {
      thread_id: threadId,
      user_query: inputValue,
      history: chatBotHistory.length !== 0 ? chatBotHistory : '',
    }
    if (chatBotId==='1'){
    dispatchTestChatBot(payload)}
    else{
      dispatchTestChatBotAdithyaBirla(payload)
    }
  }
  
  const like = (item: any) => {
    // chatMessage.forEach((message: any) => {
    //   if (message.text === item.text) {
    //       message.disliked = false;
    //       message.liked = true;
    //   }
    // });

    const newState = chatMessage.map((message: any) => {
      if (message.text === item.text) {
        return {
          ...message,
          disliked: false,
          liked: !message.liked, // Toggle the liked state
        }
      }
      return message
    })
    dispatchChatMessageState(newState)
  }
  const dislike = (item: any) => {
    const newState = chatMessage.map((message: any) => {
      if (message.text === item.text) {
        return {
          ...message,
          liked: false,
          disliked: !message.disliked, // Toggle the liked state
        }
      }
      return message
    })
    dispatchChatMessageState(newState)

    // If the message is found, update its 'liked' property to true
    // if (targetMessage) {
    //     targetMessage.disliked = true;
    // }
  }

  const handleButtonClick = (url: any) => {
    window.open(url, '_blank') // Opens the URL in a new tab/window for download
  }

  return (
    <WizrFlexLayout alignItems='center' style={{ padding: '20px' }}>
      <StyledChatWrapper view={width}>
        <WizrFlexLayout
          background='linear-gradient(90.32deg, #A36BFE -23.96%, #568FFE 56.55%, #00BDFC 81.5%, #02F5DB 97.71%);'
          justifyContent='flex-end'
          flexDirection='row'
          style={{
            padding: '5px 0px 0px 0px',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
          }}
        >
          <StyledButton
            icon={width ? <Minimize /> : <Maximize />}
            onClick={() => {
              width ? setWidth(false) : setWidth(true)
            }}
            type='link'
          />
          <StyledButton icon={<CloseIconOutlined color="black"/>} onClick={() => {}} type='link' />
        </WizrFlexLayout>
        <StyledChatHeader>
          {/* <WizrIcon name='wizrlogo' /> */}
          <WizrText type='h4' textColor='white'>
            Welcome to Wizr
          </WizrText>
        </StyledChatHeader>
        <WizrFlexLayout
          flexDirection='row'
          justifyContent='flex-end'
          style={{ minHeight: '25px' }}
          alignItems='center'
        >
          <WizrButton
            style={{ paddingRight: ' 0px' }}
            icon={<RobotIcon colour={'black'} />}
            text={<StyledTextButton type='link'>New Session</StyledTextButton>}
            type='link'
            onClick={() => {
              dispatchClearChatBotHistory()
              setThreadId(uuidv4())
              dispatchClearChat()
            }}
          />
          <WizrButton
            icon={<RefreshIcon />}
            text={<StyledTextButton type='link'>clear chat</StyledTextButton>}
            type='link'
            onClick={() => {
              dispatchClearChat()
            }}
          />
        </WizrFlexLayout>
        <StyledChatContainer bulkRun={false}>
          <div style={{ height: '68vh', overflow: 'auto', padding: '10px', }} ref={chatContainerRef}>
            {messageState?.length > 0 &&
              messageState?.map((message, index) => (
                <StyledMessage key={index} isSent={message.isSent}>
                  {!message.isSent && <StyledAvatar icon={<RobotIcon />} />}
                  <StyledMessageBubble isSent={message.isSent}>
                    {message.isSent ? (
                      <WizrText textColor='white' type='body2'>
                        <WizrMarkDown>{message.text ? message?.text : 'Generate'}</WizrMarkDown>
                      </WizrText>
                    ) : (
                      <>
                        <WizrText type='body2'>
                          <>
                            {message.text ? (
                              Array.isArray(message.text) ? (
                                message.text.map((item: any, index: any) => (
                                  <div key={index}>
                                    <WizrMarkDown>{item.data}</WizrMarkDown>
                                    <div>
                                      <strong>Category:</strong> {item.metadata.category}
                                      <br />
                                      <strong>Folder ID:</strong> {item.metadata.folder_id}
                                      <br />
                                      <strong>Intent:</strong> {item.metadata.intent}
                                      <br />
                                      <strong>Tags:</strong> {item.metadata.tags}
                                      <br />
                                      <strong>Type:</strong> {item.metadata.type}
                                      <br />
                                    </div>
                                    {index < message.text.length - 1 && <hr />}
                                  </div>
                                )) // Return item.data
                              ) : (
                                <WizrMarkDown>{message.text}</WizrMarkDown>
                              )
                            ) : (
                              <WizrMarkDown>Please try again</WizrMarkDown>
                            )}
                          </>
                        </WizrText>
                        {message.knowledgeBase  && (
                          <WizrFlexLayout
                            background='white'
                            flexDirection='row'
                            justifyContent='flex-start'
                            alignItems='center'
                            style={{ gap: '5px', flexWrap: 'wrap' }}
                            overFlow='hidden'
                          >
                            <WizrText type='body2'>Learn More:</WizrText>
                            {message.knowledgeBase &&
                              message.knowledgeBase.map((item: any) => {
                                // Accessing the keys of the object
                                const keys = Object.keys(item)

                                // Mapping over the keys to render buttons
                                return keys.map((key, index) => (
                                  <Button
                                    style={{ width: 'auto' }}
                                    key={index}
                                    type='link'
                                    icon={
                                      <WizrFlexLayout
                                        flexDirection='row'
                                        style={{
                                          padding: '5px 10px',
                                          borderRadius: '16px',
                                          borderWidth: '1px',
                                          gap: '5px',
                                        }}
                                      >
                                        <PdflogoIcon />
                                        <WizrText type='body2'>{key?key:item[key]}</WizrText>
                                      </WizrFlexLayout>
                                    }
                                    onClick={() => handleButtonClick(item[key])} // Pass URL to the handler function
                                  />
                                ))
                              })}
                          </WizrFlexLayout>
                        )}
                        <WizrFlexLayout
                          flexDirection='row'
                          justifyContent='space-between'
                          alignContent='baseline'
                        >
                          <WizrFlexLayout
                            flexDirection='row'
                            justifyContent='flex-end'
                            background='white'
                          >
                            <Button
                              type='link'
                              icon={<Copy color={'#999B9F'} />}
                              onClick={() => {
                                antdMessage.success('Copied to clipboard', 2)
                                copy(message.text)
                              }}
                            />

                            <Button
                              type='link'
                              shape='circle'
                              icon={<DislikeIcon colour={message?.disliked ? 'blue' : '#999B9F'} />}
                              onClick={() => {
                                dislike(message)
                              }}
                            />

                            <Button
                              type='link'
                              icon={<LikeIcon colour={message?.liked ? 'blue' : '#999B9F'} />}
                              onClick={() => {
                                like(message)
                              }}
                            />
                          </WizrFlexLayout>
                        </WizrFlexLayout>
                      </>
                    )}
                  </StyledMessageBubble>
                </StyledMessage>
              ))}
          </div>
          <div className='inputwrapper' style={{ width: '100%' }}>
            <StyledInputContainer style={{ width: '100%' }}>
              <StyledInput
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value)
                }}
                onPressEnter={() => {
                  testChatBot()
                }}
                placeholder={loading ? 'Generating.......' : 'Ask something'}
                suffix={
                  loading ? (
                    <Spin />
                  ) : (
                    <WizrFlexLayout flexDirection='row' background='white' alignItems='center'>
                      <WizrUploadContainer onChange={startFileUpload} fileTypes='.pdf,.csv,.docx,.txt'>
                        <StyledButton
                          icon={<UploadOutlined style={{ fontSize: '20px' }} />}
                          onClick={() => {}}
                          type='link'
                        />

                        {/* <StyledButton icon={<UploadIcon />} onClick={() => {}} type='link' /> */}
                      </WizrUploadContainer>
                      <WizrUploadContainer onChange={startFileUpload} fileTypes='.png,.jpeg,.gif,.jpg, .svg, .jfif, .pjpeg, .pjp,.webp	'>
                        <StyledButton
                          icon={<FileImageOutlined style={{ fontSize: '20px' }} />}
                          onClick={() => {}}
                          type='link'
                        />

                        {/* <StyledButton icon={<UploadIcon />} onClick={() => {}} type='link' /> */}
                      </WizrUploadContainer>
                      <WizrButton
                        style={{ paddingLeft: '10px' }}
                        shape='circle'
                        onClick={() => {
                          testChatBot()
                        }}
                        icon={<SentIcon />}
                      ></WizrButton>
                    </WizrFlexLayout>
                  )
                }
              />
            </StyledInputContainer>
          </div>
        </StyledChatContainer>
        <WizrFlexLayout
          background=' linear-gradient(90.32deg, #A36BFE -23.96%, #568FFE 56.55%, #00BDFC 81.5%, #02F5DB 97.71%)'
          justifyContent='center'
          style={{ borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px' }}
        >
          <WizrText type='caption' textColor='white'>
            Powered by Wizr AI
          </WizrText>
        </WizrFlexLayout>
      </StyledChatWrapper>
    </WizrFlexLayout>
  )
}

export default ChatBot
