import { styled } from 'styled-components'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'


import {
  disaptchPromptTest,
  dispatchClearChat,
  dispatchGetPromptDetails,
  dispatchLLMModels,
  dispatchListFolders,
  dispatchManagePrompt,
  dispatchManagePromptApp,
  dispatchPromptData,
  dispatchPublishPrompt,
  dispatchTags,
  dispatchVectorDb
} from '../../../redux/actions/content'
import { MODAL_TYPES, useWizrModalContext } from '../../../providers/modal/WizrModalProvider'
import PromptCopyEdit from '../../../components/popovers/PrompyCopyEdit'
import { Button, Collapse, CollapseProps, Input, Tooltip } from 'antd'
import InfoIcon from '../../../assets/icons/Info'
import WizrUserJourney from '../../../components/WizrUserJourney'
import {
  useChatMessageSelector,
  useContentLoadingSelector,
  useContentsSelector,
  useCurrentAppDetailsSelector,
  useLLMModelSelector,
  usePromptDetailsSelector,
  usePromtTemplateSelector,
  useTagsSelector,
  useVectorDb
} from '../../../redux/selectors/content'
import ExampleActionPopover from '../../../components/popovers/ExampleAction'
import EditIcon from '../../../assets/icons/EditIcon'
import DeleteIcon from '../../../assets/icons/DeleteIcon'
import { dispatchNotificationState } from '../../../redux/actions/common'
import { disableTenant, listLLMModels, uploadFile } from '../../../api/helpers/contents'
import WizrUploadContainer from '../../../components/WizrUploadButton'
import {
  WizrAzurOrchestratorInstance,
  wizrAzureConnectorInstance,
} from '../../../utils/wizrAzureConnector'
import { generateNodeSequenceResponse, getFileType } from '../../../utils'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrText from '../../../components/WizrText'
import WizrInput from '../../../components/WizrInput'
import WizrButton from '../../../components/WizrButton'
import WizrTextArea from '../../../components/WizrTextArea'
import WizrCheckbox from '../../../components/WizrCheckbox'
import WizrFlexSpacing from '../../../components/WizrFlexSpacing'
import WizrDropDown from '../../../components/WizrDropDown'
import WizrToggleButton from '../../../components/WizrToggleButton'
import WizrSlider from '../../../components/WizrSlider'
import WizrTagInput from '../../../components/WizrTagInput'
import { ArrowsAltOutlined, SettingOutlined, ShrinkOutlined } from '@ant-design/icons'
import { useEdges, useNodes } from 'reactflow'
import Chat from '../../../components/WizrChat'
import WizrTag from '../../../components/WizrTags'
import SettingLLM from './SettingLLM'
import WizrWholeTextArea from '../../../components/WizrWholeTextArea'
// import { set } from 'react-hook-form'


const StyledFlexSpacing = styled(WizrFlexSpacing)`
  .ant-space-item {
    display: flex !important;
    width: 100% !important;
    flex-wrap: wrap;
  }
`
const StyledWizrTag = styled(WizrTag)`
  height: 42px;
  padding: 8px 11px;
  justify-content: space-between;
`
const findllmModalType = (value: any, LLMModelData: any) => {
  const selectedModel = LLMModelData.find((obj: any) => obj.id === value)

  return selectedModel?.modal_type
}

const transformEditDataToPromptInput = (promptDetailsWhole: any, LLMModelData: any) => {
  const editPromptData = promptDetailsWhole?.prompt_config

  const prompt_id = editPromptData?.id  

  const promptList = editPromptData?.prompts ? editPromptData?.prompts : []
  const extractedPromptDetail = editPromptData?.prompts?.filter(
    (data: any) => data?.parent_prompt,
  )?.[0]

  const parameterData = editPromptData?.prompt_body?.message_params?.map((item: any) => {
    return { parameterType: item?.type, modalTags: item?.value, name: item?.name }
  })
  const transformedPromptData = {
    // id:uuidv4(),
    name:
      editPromptData?.name,
    description: editPromptData?.description,
    tags: editPromptData?.tags,
    systemMessage:
      editPromptData?.prompt_body?.system_message,
    zero_shot:
      editPromptData?.prompt_body?.zero_shot,
    examples: editPromptData?.prompt_body?.examples,
    knowledgebase_required:
      editPromptData?.prompt_body?.knowledgebase_required,
    folderList:
      editPromptData?.prompt_body?.folder_id_list,
    temperature:
      editPromptData?.prompt_body?.temperature,
    top_p: editPromptData?.prompt_body?.top_p,
    frequency_penalty:
      editPromptData?.prompt_body?.frequency_penalty,
    presence_penalty:
      editPromptData?.prompt_body?.presence_penalty,
    stop_sequences:
      editPromptData?.prompt_body?.stop_sequences,
    max_tokens:
      editPromptData?.prompt_body?.max_tokens,
    llm_model: {
      id: editPromptData?.prompt_body?.llm_model_id
        ? editPromptData.prompt_body.llm_model_id
        : '820b7f6f-9d64-4a8e-b82c-31b64570a2f2',
      type: editPromptData?.prompt_body?.llm_type
        ? editPromptData.prompt_body.llm_type
        : 'azureopenai',
      model_type: editPromptData?.prompt_body?.model_type
        ? editPromptData.prompt_body.model_type
        : 'chatcompletion',
    },
    llm_model_id: editPromptData?.prompt_body?.llm_model_id
      ? editPromptData.prompt_body.llm_model_id
      : '820b7f6f-9d64-4a8e-b82c-31b64570a2f2',
    llm_type: editPromptData?.prompt_body?.llm_type
      ? editPromptData.prompt_body.llm_type
      : 'azureopenai',
    model_type: editPromptData?.prompt_body?.model_type
      ? editPromptData.prompt_body.model_type
      : findllmModalType(editPromptData?.prompt_body?.llm_model_id, LLMModelData),
    tested: editPromptData?.response || editPromptData?.query ? true : false,
    message_params: parameterData,
    type: editPromptData?.type,
    published: editPromptData?.published,
    prompt_id: prompt_id,
    created_on: editPromptData?.created_on,
    prompt_list: localStorage.getItem('action') === 'edit' ? promptList : [],
    promptEdit_id: editPromptData?.action === 'edit' ? editPromptData?.prompt_id : null,
    action: editPromptData?.action,
    best_of: editPromptData?.prompt_body?.best_of,
    post_response_text: editPromptData?.prompt_body?.post_response_text,
    pre_response_text: editPromptData?.prompt_body?.post_response_text,
    user_query: editPromptData?.prompt_body?.user_query ? editPromptData?.prompt_body?.user_query : "{{user_query}}",
    embedding_model_id: editPromptData?.prompt_body?.embedding_model_id || '',
    match_threshold:promptDetailsWhole?.match_threshold ,
    top_k:promptDetailsWhole?.top_k,
  }

  return transformedPromptData
}

const StyledContainer = styled(WizrFlexLayout)`
  gap: 8px;
`

const ContainertWithDivider = styled(WizrFlexLayout)`
  border-bottom: 1px solid #ddd;
  padding: 10px 0px;
  width: 100%;
  text-align: left;
  height: 100%;
  flex: none;
`
const StyledWizrDropDown = styled(WizrDropDown)`
  width: 100%;
`

const ChatSettings = styled(WizrFlexLayout)`
  border-radius: 6px;
  margin-bottom: 1px;
  gap: 5px;
  padding-top: 0px;
  min-height: 20%;
  overflow: auto;
`

const StyledInputContainer = styled.div`
  padding: 0px 10px;
  width: 100%;
  text-align: left;
`

const StyledWizrButton = styled(WizrButton)`
  border-radius: 20px;
`

const StyledWizrCheckbox = styled(WizrCheckbox)`
  margin-top: 40px !important;
`

const PromptMainFormLayout = styled(WizrFlexLayout)`
  border-radius: 16px;
  padding: 15px;
  margin-bottom: 10px;
`

const PromptMainFormInputLayout = styled(WizrFlexLayout)`
  gap: 16px;
`

const PromptInputBox = styled(WizrFlexLayout)`
  gap: 10px;
`

const PromptSettingsContainer = styled(WizrFlexLayout)`
  padding: 15px;
  border-radius: 16px;
`

const TestAreaContainer = styled(WizrFlexLayout)`
  border-radius: 16px;
  padding: 15px;
  overflow-y: auto;
`
const PromptSettingsSectionContainer = styled(WizrFlexLayout)`
  padding: 0px 0px 0px 20px;
`
const IndividualSettingsBox = styled.div`
  width: 100%;
  text-align: left;
  height: auto;
  margin: 10px 0px 0px 0px;
  background-color:white;
  padding:0px;
`

const IndividualLlmSettingsBox = styled.div`
  width: 90%;
  text-align: left;
  height: auto;
  margin: 10px 0px 0px 0px;
`

const TextInputContainer = ({ promptInputState, doUpdateFields, promptTags }: any): JSX.Element => {

  // promptInputState?.type === 'template' || localStorage.getItem('action') === 'copy'
  // ? (promptInputState = { ...promptInputState, name: 'Copy of' + ' ' + promptInputState?.name })
  // : ''

  // const doManagePrompt = () => {    
  // const payload = {...promptInputState,
  // published:true,
  // published_on  : new Date().getTime()}

  //   dispatchManagePrompt(payload)
  // }

  const loading = useContentLoadingSelector()
  const tagList = promptTags?.map((item: any) => ({
    label: item,
    value: item,
  }))


  return (
    <PromptMainFormLayout
      flexDirection='row'
      justifyContent='flex-start'
      background='#FFF'
      alignItems='center'
    >
      <PromptMainFormInputLayout
        justifyContent='space-between'
        flexDirection='row'
        background='white'
        alignItems='center'
        flex={10}
      >
        <>
          <PromptInputBox flexDirection='column' background='transparent' alignItems='flex-start'>
            <WizrText textColor='#707276' type='body2'>
              Prompt Name
            </WizrText>
            <WizrInput
              placeholder='Name'
              value={promptInputState?.name}
              onChange={(value: any) => {
                doUpdateFields('type', '')
                doUpdateFields('name', value)
              }}
            />
          </PromptInputBox>
          <PromptInputBox flexDirection='column' background='transparent' alignItems='flex-start'>
            <WizrText textColor='#707276' type='body2'>
              Prompt Description
            </WizrText>
            <WizrInput
              placeholder='Description'
              value={promptInputState?.description}
              onChange={(value: any) => doUpdateFields('description', value)}
            />
          </PromptInputBox>

          <PromptInputBox flexDirection='column' background='transparent' alignItems='flex-start'>
            <WizrText textColor='#707276' type='body2'>
              Tags
            </WizrText>
            <WizrTagInput
              onChange={(value: any) => doUpdateFields('tags', value)}
              //open={true}
              value={promptInputState?.tags}
              options={tagList}
            />
          </PromptInputBox>
        </>
      </PromptMainFormInputLayout>

    </PromptMainFormLayout>
  )
}

const PromptSettingsSection1 = ({
  promptInputState,
  doUpdateFields,
  setPromptInputState, parameterList,
  parameterInputState,
  setParameterInputState,
  keyMappings,
  data,
  setViewFullSystemMessage

}: any): JSX.Element => {
  const [knowledgeBase, setKnowledgeBase] = useState<boolean>(
    promptInputState?.knowledgebase_required,
  )
  const [userQuery, setUserQuery] = useState<string>('{{user_query}}')
  const { showModal, hideModal } = useWizrModalContext()
  const [examplesData, setExamplesData] = useState<{ user: string; assistant: string }[]>([])
  const [zeroShot, setZeroShot] = useState<boolean>(true)
  const [parameterData, setParameterData] = useState<any>([])
  const [mergedParameters, setMergedParameters] = useState([...(data?.config?.prompt_config?.prompt_body?.message_params || [])])
  const [currentembeddingModel,setCurrentEmbeddingModel] = useState<any>(promptInputState?.embedding_model_id)
  useEffect(() => {
    // to map default default keymapping values to the parameters    
    let params = { ...parameterInputState }
    const parameters = data?.config?.prompt_config?.prompt_body?.message_params
    parameters?.map((param: any) => {
      const keyToGet = `{{${param?.name}}}`
      params = { ...params, [`{{${param?.name}}}`]: data?.config?.key_mapping?.[keyToGet] || '' }
    })
    
    setParameterInputState(params)

  }, [data, data?.config?.prompt_config?.prompt_body?.message_params])

  const onParameterUserInput = (key: string, value: string) => {
    setUserQuery(value)
    setParameterInputState({ ...parameterInputState, [`{{${key}}}`]: value })    
    keyMappings({ ...parameterInputState, [`{{${key}}}`]: value })
    setPromptInputState({ ...promptInputState, [key]: value })
  }

  const onParameterUserSelect = (key: string, value: string) => {
    setParameterInputState({ ...parameterInputState, [`{{${key}}}`]: parameterInputState[`{{${key}}}`] ? parameterInputState[`{{${key}}}`] + `{{${value}}}` : `{{${value}}}` })
    keyMappings({ ...parameterInputState, [`{{${key}}}`]: parameterInputState[`{{${key}}}`] ? parameterInputState[`{{${key}}}`] + `{{${value}}}` : `{{${value}}}` })
  }

  useEffect(() => {
    if (promptInputState?.examples) {
      setExamplesData(promptInputState?.examples)
    }
  }, [promptInputState?.examples])

  useEffect(() => {
    setParameterData(promptInputState?.message_params)
    keyMappings(parameterInputState)


  }, [promptInputState?.message_params])
  useEffect(() => {
    const folder = promptInputState?.folderList && promptInputState?.folderList[0]
    const embeddingId = folderData?.find((item: any) => item.folder_id === folder)?.embedding_model_id
    setCurrentEmbeddingModel(embeddingId)
  }, [])

  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item,
        value: item,
      }))
    return result
  }
  const getDisabledStatus = (item: any) => {
    if (currentembeddingModel) {
      if (item?.embedding_model_id === currentembeddingModel) {
        return false
      }
      else {
        return true
      }
    }
    else {
      return false
    }
  }

  const folderData = useContentsSelector()
  const folderList = folderData?.map((item: any) => ({
    label: item.folder_name,
    value: item.folder_id,
    disabled: getDisabledStatus(item)
  }))

  const onExampleEdit = (item: any) => {
    showModal(MODAL_TYPES.ADD_EXAMPLES, {
      exampleInput: item,
      onSubmit: (example: any) => {
        const indexToUpdate = examplesData?.findIndex(({ id }: any) => id === example?.id)
        const updatedExamples = [...examplesData]
        updatedExamples[indexToUpdate] = example
        doUpdateFields('examples', updatedExamples)
        setExamplesData(updatedExamples)
        hideModal()
      },
      onCancel: hideModal,
    })
  }

  const onExampleDelete = (item: any, type: string) => {
    showModal(MODAL_TYPES.CONFIRMATION, {
      buttontext: type === 'zeroshot' ? 'Proceed' : 'Delete',
      text:
        type === 'zeroshot' ? (
          <p>
            Enabling zeroshot will delete existing examples.
            <br />
            Are you sure you want to proceed?
          </p>
        ) : (
          'Are you sure you want to delete?'
        ),
      onConfirm: () => {
        let updatedExamples
        if (Array.isArray(item)) {
          const itemIds = item.map((i: any) => i.id)
          updatedExamples = examplesData?.filter(({ id }: any) => !itemIds.includes(id))
        } else {
          updatedExamples = examplesData?.filter(({ id }: any) => id !== item?.id)
        }
        doUpdateFields('examples', updatedExamples)
        setExamplesData(updatedExamples)
        hideModal()
      },
      onCancel: hideModal,
    })
  }

  const parameterFinder = (type: number) => {
    switch (type) {
      case 1:
        return 'Short text'
      case 2:
        return 'Long Text'
      case 3:
        return 'File Upload'
      case 4:
        return 'Single select'
      case 5:
        return 'Multi select'
    }
  }
  const selectKnowledgeBase = (value: any) => {
    if (value.length === 1) {
      const embeddingId = folderData?.find((item: any) => item.folder_id === value[0])?.embedding_model_id
      setCurrentEmbeddingModel(embeddingId)
      // doUpdateFields('embedding_model_id', embeddingId)
      setPromptInputState({ ...promptInputState, embedding_model_id: embeddingId, folderList: value })
    }
    else if (value.length === 0) {
      setCurrentEmbeddingModel('')
      // doUpdateFields('embedding_model_id', '')
      setPromptInputState({ ...promptInputState, embedding_model_id: '', folderList: value })


    } else {
      doUpdateFields('folderList', value)
    }
  }

  const EditDelete = (parameter: any, key: number) => {
    const onParameterDelete = (item: any) => {
      showModal(MODAL_TYPES.CONFIRMATION, {
        text: 'Are you sure?',
        buttontext: 'Delete',
        onConfirm: () => {
          const updatedParameter = parameterData?.filter(
            (data: any) => data.name !== item.parameter.name,
          )
          setParameterData(updatedParameter)
          doUpdateFields('message_params', updatedParameter)
          // setPromptInputState({...promptInputState, parameter: updatedParameter})
          hideModal()
        },
        onCancel: hideModal,
      })
    }

    const onParameterEdit = (item: any) => {
      showModal(MODAL_TYPES.EDIT_PARAMETER, {
        parameterInput: item,
        onSubmit: (value: any) => {
          const indexToUpdate = parameterData?.findIndex(
            (indexitem: any) => JSON.stringify(indexitem) === JSON.stringify(item.parameter),
          )
          // const indexToUpdate = key
          const updatedParameter = [...parameterData]
          updatedParameter[indexToUpdate] = value
          setParameterData(updatedParameter)
          doUpdateFields('message_params', updatedParameter)
          hideModal()
        },
        onCancel: hideModal,
      })
    }


    return (
      <>
        <WizrButton type='text' icon={<EditIcon />} onClick={() => onParameterEdit(parameter)} />
        <WizrButton
          type='text'
          icon={<DeleteIcon />}
          onClick={() => onParameterDelete(parameter)}
        />
      </>
    )
  }

  const [cursorPosition, setCursorPosition] = useState<number | null>(null);


  const handleCursorChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCursorPosition(event.target.selectionStart);
  };

  return (
    <PromptSettingsSectionContainer
      background='#FFF'
      alignItems='flex-start'
      justifyContent='flex-start'
      flex={1.5}
    >
      <IndividualSettingsBox>
        <WizrFlexLayout flexDirection='row' background='white'>
          <WizrText type='sub2'>System</WizrText>
          <Tooltip title="Maximize System Message section">
            <WizrButton icon={<ArrowsAltOutlined style={{ fontSize: "20px" }} />} onClick={() => { setViewFullSystemMessage(true) }} type='link' />
          </Tooltip>
        </WizrFlexLayout>
        <IndividualSettingsBox>
          <WizrTextArea
            value={promptInputState?.systemMessage}
            onSelect={handleCursorChange}
            onChange={(value: any) => doUpdateFields('systemMessage', value)}
          ></WizrTextArea>
          <StyledFlexSpacing justifyContent='space-between' flexDirection='column' alignItems='center' size={20} background='white'>
            <StyledWizrTag
              background="grey"
              data={parameterList}
              onClick={(e: any) => {
                const clickedTagValue = e; // Replace this with the actual way to get the clicked tag value

                if (cursorPosition !== null) {
                  // Insert the clicked tag value at the cursor position
                  const updatedSystemMessage =
                    `${promptInputState?.systemMessage.slice(0, cursorPosition)}{{${clickedTagValue}}}${promptInputState?.systemMessage.slice(cursorPosition)}`

                  // Update the systemMessage state with the modified value
                  doUpdateFields('systemMessage', updatedSystemMessage);

                  // Move the cursor position to the end of the inserted value
                  setCursorPosition(cursorPosition + clickedTagValue.length + 4);
                }
              }}
            />
          </StyledFlexSpacing>
        </IndividualSettingsBox>
      </IndividualSettingsBox>
      {promptInputState?.llm_model?.model_type !== 'completion' ? <IndividualSettingsBox>
        <WizrFlexLayout
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          background='#FFF'
          flex='none'
        >
          <WizrFlexSpacing
            size='small'
            flexDirection='row'
            justifyContent='flex-start'
            background='white'
          >
            <WizrText type='sub2'>Examples </WizrText>
            <WizrCheckbox
              text='Zero Shot'
              isEnabled={promptInputState?.zero_shot ?? true}
              onChange={(e: any) => {
                if (promptInputState?.examples?.length > 0 && e.target.checked === true) {
                  onExampleDelete(promptInputState?.examples, 'zeroshot')
                }
                doUpdateFields('zero_shot', e.target.checked)
                setZeroShot(e.target.checked)
              }}
            />
          </WizrFlexSpacing>
          <WizrButton
            type='text'
            disabled={zeroShot || promptInputState?.zero_shot}
            text={<WizrText type='link'>Add Examples</WizrText>}
            onClick={() =>
              showModal(MODAL_TYPES.ADD_EXAMPLES, {
                parameterList,
                onSubmit: (comment: any) => {
                  hideModal()
                  setExamplesData([...examplesData, { id: examplesData?.length + 1, ...comment }])
                  doUpdateFields('examples', [
                    ...examplesData,
                    { id: examplesData?.length + 1, ...comment },
                  ])
                },
                onCancel: hideModal,
              })
            }
          />
        </WizrFlexLayout>
        <WizrFlexLayout background='#FFF' flex='none'>
          {examplesData?.length > 0 ? (
            examplesData?.map((item: any, key: number) => {
              return (
                <ContainertWithDivider
                  key={key}
                  background='#FFF'
                  flexDirection='row'
                  alignItems='flex-start'
                >
                  <WizrFlexLayout
                    background='#FFF'
                    alignItems='flex-start'
                    flex={3}
                    textAlign='left'
                  >
                    <WizrText type='body2' key={key}>
                      <b>Human input</b>: {item.user}
                    </WizrText>
                    <WizrText type='body2' key={key}>
                      <b>Assistant output</b>: {item.assistant}
                    </WizrText>
                  </WizrFlexLayout>
                  <WizrFlexLayout background='#FFF' alignItems='flex-end'>
                    <ExampleActionPopover
                      onDeleteAction={() => onExampleDelete(item, '')}
                      onEditAction={() => onExampleEdit(item)}
                    />
                  </WizrFlexLayout>
                </ContainertWithDivider>
              )
            })
          ) : (
            <></>
          )}

        </WizrFlexLayout>
      </IndividualSettingsBox> : <> </>}
      <IndividualSettingsBox>
        <WizrFlexLayout alignItems='center'>
          {/* <WizrFlexLayout flexDirection='row' background='#FFF' alignItems='center'>
            <WizrText type='sub2'>Parameter </WizrText>
          </WizrFlexLayout> */}
          <WizrFlexLayout background='#FFF' flex='none'>
            <WizrFlexLayout
              alignContent='left'
              background='#FFF'
              flexDirection='row'
              justifyContent='space-around'
            >
              <WizrText type='body2'>User Query</WizrText>
              <WizrText type='hint'>text*</WizrText>
            </WizrFlexLayout>
            <WizrFlexLayout
              flexDirection='row'
              background='#FFF'
              alignItems='center'
              justifyContent='space-around'
              style={{ gap: '3px' }}
            >
              <WizrInput value={promptInputState?.user_query} 
                onChange={(text: string) => { onParameterUserInput('user_query', text) }} 
              />
            </WizrFlexLayout>
          </WizrFlexLayout>
        </WizrFlexLayout>
      </IndividualSettingsBox>
      {promptInputState?.llm_model?.model_type !== 'completion' ? <IndividualSettingsBox>
        <WizrFlexLayout>
          <WizrFlexLayout
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            background='#FFF'
          >
            <WizrText type='sub1'>Knowledge base </WizrText>
            <WizrToggleButton
              isEnabled={promptInputState?.knowledgebase_required}
              onChange={(e: boolean) => {
                setKnowledgeBase(e)
                doUpdateFields('knowledgebase_required', e)
              }}
            />
          </WizrFlexLayout>
        </WizrFlexLayout>
        {knowledgeBase || promptInputState?.knowledgebase_required ? (
          <WizrFlexLayout alignItems='center' background='#FFF'>
            <IndividualSettingsBox>
              <WizrTagInput
                options={folderList}
                value={promptInputState?.folderList}
                onChange={(value: any) => selectKnowledgeBase(value)}
                placeHolder='Select folder(s)'
              />
            </IndividualSettingsBox>
          </WizrFlexLayout>
        ) : (
          <></>
        )}
      </IndividualSettingsBox> : <></>}
    </PromptSettingsSectionContainer>
  )
}

const PromptSettingsSection2 = ({ promptInputState, doUpdateFields, setPromptInputState }: any): JSX.Element => {

  const LLMModelData = useLLMModelSelector()
  const loading = useContentLoadingSelector()
  const [modelName, setModelName] = useState<any>({})
  const [selectedLLMSetting, setSelectedLLMSetting] = useState([])

  const normalOptions = LLMModelData?.map((item: any) => {
    return { label: item.display_name, value: item.display_name, model_type: item.model_type }
  })

  useEffect(() => {
    if (!loading && promptInputState?.llm_type) {
      const defaultModel = LLMModelData.find(
        (obj: any) =>
          obj.id === (promptInputState?.llm_model?.id || promptInputState?.llm_model_id),
      )
      if (defaultModel) {
        setSelectedLLMSetting(defaultModel?.llm_settings)
        setModelName({ label: defaultModel.display_name, value: defaultModel.display_name, model_type: defaultModel.model_type })
      }
    } else {
      setSelectedLLMSetting(LLMModelData[0]?.llm_settings)
      setModelName({ label: LLMModelData[0]?.display_name, value: LLMModelData[0]?.display_name, model_type: LLMModelData[0]?.model_type })
    }
  }, [LLMModelData])

  const selectLLMModel = (value: any) => {

    const selectedModel = LLMModelData.find((obj: any) => obj.display_name === value)
    // const valuenew = {...promptInputState,...selectedModel?.llm_settings
    // }    
    setSelectedLLMSetting(selectedModel?.llm_settings)

    setModelName({ label: value, value: value, model_type: selectedModel.model_type })
    doUpdateFields('llm_model', { id: selectedModel.id, type: selectedModel.llm_type, model_type: selectedModel.model_type })

  }
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "LLM Settings",
      //children: <Settings doUpdateFields={doUpdateFields} promptInputState={promptInputState  }/>,
      children: <SettingLLM doUpdateFields={doUpdateFields} promptInputState={promptInputState} selectedLLMSetting={selectedLLMSetting} setPromptInputState={setPromptInputState} />
    },
  ];
  const customExpandIcon = (panelProps: any) => {
    const { isActive } = panelProps;
    return <SettingOutlined style={{ fontSize: '16px', marginRight: '8px', transform: `rotate(${isActive ? 90 : 0}deg)` }} />;
  };

  return (
    <PromptSettingsSectionContainer background='#FFF' justifyContent='flex-start' style={{ overflow: 'auto' }}>
      <IndividualSettingsBox>
        <WizrText type='sub1'>LLM Settings </WizrText>
        <IndividualSettingsBox>
          <WizrDropDown
            placeHolder='Model'
            options={normalOptions}
            onChange={(value: any) => selectLLMModel(value)}
            value={modelName}
          />
        </IndividualSettingsBox>
      </IndividualSettingsBox>
      <Collapse items={items} defaultActiveKey={['1']} bordered={false} style={{ backgroundColor: "white" }} />
      {/* <WizrFlexLayout flex='none' background='#FFF' overFlow='hidden'>
        
       
      </WizrFlexLayout> */}
    </PromptSettingsSectionContainer>
  )
}


const PromptEdit = (props: any): JSX.Element => {

  const { id, allNodeData, editData, updateNodeData, data, nodeUpdate, closeEditArea, promptSave, setPromptSave } = props  



  const [parameterList, setParameterList] = useState<any>()
  const vectorDb = useVectorDb()
  const [promptInputState, setPromptInputState] = useState<{ [key: string]: any }>()
  const loading = useContentLoadingSelector()
  // const doManagePrompt = () => dispatchManagePrompt(promptInputState)
  const [action, setAction] = useState<string>('')
  const LLMModelData = useLLMModelSelector()
  const parameters = allNodeData[0]?.data?.config?.parameters?.map((item: any) => item?.name)
  const [viewFullSystemMessage, setViewFullSystemMessage] = useState<boolean>(false)
  useEffect(() => {
    dispatchListFolders('knowledge_base')
    dispatchLLMModels()
    dispatchTags()
    dispatchVectorDb()
  }, [])

  const edges = useEdges()
  const nodes = useNodes()

  function elementsBeforeSpecificElement(arr: any, specificElement: any) {
    const index = arr.indexOf(specificElement);
    if (index === -1) {
      return arr;
    }
    const newArray = arr.slice(0, index).slice(1).map((item: any) => {
      return `${item}.output`
    })
    return newArray

  }
  const startnodedata: any = nodes && nodes[0]?.data

  useEffect(() => {
    const saveAppPayload = {
      edges: edges,
      nodes: nodes,
    };
    const sequence = generateNodeSequenceResponse(saveAppPayload);
    const trimmedNodeSequence = elementsBeforeSpecificElement(sequence, id);
    const defaultParameters = startnodedata?.config?.parameters.map((param: any) => param?.name) || []
    setParameterList((prevList: any) => [...defaultParameters, ...trimmedNodeSequence]);

  }, [nodes, edges, setParameterList, id]);  


  useEffect(() => {
    if (promptSave === true) {
      doManagePrompt()
      setPromptSave(false)
    }
  }, [promptSave])


  const promptDetails = editData?.data?.config?.prompt_config
  const promptDetailsWhole = editData?.data?.config


  const promptTags = useTagsSelector()


  const changePromptStructure = async (payload: any) => {
    // const payloadvectordb = {
    //   query: "SELECT * FROM c where c.tenant_id ='WIZR-BASIC-TENANT' and c.active =true",
    //   container: 'system_config_vector_db',
    // }
    // const responseVectorDB = await listLLMModels(payloadvectordb)
    // const responseVectorDBKey = responseVectorDB?.data?.data[0]?.id
    const managePromptPayload = {
      id: payload?.id ? payload?.id : uuidv4(),
      name: payload?.name,
      tags: payload?.tags ? payload?.tags : [],
      description: payload?.description ? payload?.description : "",
      published: payload?.published === true ? true : false,
      //published:payload?.published,    
      published_on: null,
      created_on: payload?.created_on ? payload?.created_on : Date.now(),
      updated_on: Date.now(),

      prompt_body: {
        system_message: payload?.systemMessage,
        temperature: payload?.temperature === undefined ? 0.5 : payload?.temperature,
        max_tokens: payload?.max_tokens ? parseInt(payload?.max_tokens) : 1000,
        top_p: payload?.top_p === undefined ? 0.5 : payload?.top_p,
        frequency_penalty: payload?.frequency_penalty ? payload?.frequency_penalty : 0,
        presence_penalty: payload?.presence_penalty ? payload?.presence_penalty : 0,
        zero_shot: payload?.examples?.length > 0 ? false : true,
        examples: payload?.zero_shot || payload?.llm_model?.model_type === 'completion' ? [] : payload?.examples ? payload?.examples : [],
        knowledgebase_required: payload?.knowledgebase_required
          ? payload?.knowledgebase_required
          : false,
        stop_sequences: payload?.stop_sequences ? payload?.stop_sequences : [],
        llm_model_id: payload?.llm_model?.id
          ? payload?.llm_model?.id
          : '820b7f6f-9d64-4a8e-b82c-31b64570a2f2',
        llm_type: payload?.llm_model?.type ? payload?.llm_model?.type : 'azureopenai',
        model_type: payload?.llm_model?.model_type ? payload?.llm_model?.model_type : 'chatcompletion',
        message_params:
          payload?.message_params?.length > 0
            ? payload?.message_params?.map((item: any) => {
              return {
                name: item.name,
                type: item.parameterType,
                value: item.modalTags || '',
              }
            })
            : [],
        embedding_model_id: payload?.embedding_model_id ? payload?.embedding_model_id : '',
        folder_id_list: payload?.folderList ? payload?.folderList : [],
        best_of: payload?.best_of ? payload?.best_of : 1,
        post_response_text: payload?.post_response_text ? payload?.post_response_text : "",
        pre_response_text: payload?.pre_response_text ? payload?.pre_response_text : "",
        user_query: payload?.user_query ? payload?.user_query : ""
      },
    }
    return managePromptPayload

  }
  useEffect(() => {
    if (nodeUpdate === 'llm') {
      updateCurrentNodeData()
    }
  }, [nodeUpdate])

  const updateCurrentNodeData = async () => {

    const inputString = promptInputState?.systemMessage
    const matches = inputString?.match(/\{\{(.*?)\}\}/g) || [];

    // to find the mapped keys inside the system message
    const wordsInsideBraces = matches?.map((match: any) => match.replace(/\{\{|\}\}/g, ''));
    const resultObject: any = {};

    wordsInsideBraces.forEach((word: any) => {
      const key = `{{${word}}}`;
      resultObject[key] = `{{${word}}}`;
    });
    
    const keyMappingValues = {...resultObject, ...parameterInputState}

    const promptConfig = await changePromptStructure(promptInputState)

  //   <WizrInput value={promptInputState?.user_query} 
  //   onChange={(text: string) => { onParameterUserInput('user_query', text) }} 
  // />
  // setPromptInputState({ ...promptInputState, [key]: value })
    

    updateNodeData((nds: any) =>
      nds.map((node: any) => {

        if (node.id === id) {
          node.data = {
            ...node.data,
            config: {
              ...node.data.config,
              memory: false,
              prompt_config: promptConfig,
              llm_folder_id_list: promptInputState?.folderList ? promptInputState?.folderList : [],
              key_mapping: keyMappingValues,
              top_k:promptInputState?.top_k,
              match_threshold:promptInputState?.match_threshold,
            },
          }
        }
        return node
      }),
    )
    closeEditArea()

  }

  useEffect(() => {
    
    const editTransformedPromptData = transformEditDataToPromptInput(promptDetailsWhole, LLMModelData)
    setPromptInputState(editTransformedPromptData)
    setParameterInputState(editData?.data?.config?.key_mapping)
  }, [promptDetails, editData])

  const doUpdateFields = (fieldName: string, fieldValue: any) => {

    setPromptInputState({
      ...promptInputState,
      [fieldName]: fieldValue,
    })
  }
  const changePrompt = (id: any) => {
    // setPromptId(id)
    const promptDetails = publishedPrompts?.filter((prompt: any) => prompt?.id === id)?.[0]
    const editTransformedPromptData = transformEditDataToPromptInput(promptDetails, LLMModelData)
    setPromptInputState(editTransformedPromptData)
    // hided this coz when edited system message keyvalues gone missing without editing userquery
    // setKeyMapping('')
  }
  const publishedPrompts = usePromtTemplateSelector()

  const currentAppDetails = useCurrentAppDetailsSelector()

  const testParams = currentAppDetails?.nodes && currentAppDetails?.nodes[0]?.data?.config?.parameters.filter((item: any) => item.name !== 'user_query')
  const prompt_id = promptInputState?.prompt_id || ""
  // const [promptId,setPromptId] = useState(promptDetails?.id)
  const [parameterInputState, setParameterInputState] = useState<{ [key: string]: string }>({})
  const [keyMapping, setKeyMapping] = useState<any>()

  const keyMappings = (userInput: any) => {

    setKeyMapping(userInput)
    updateNodeData((nds: any) =>
      nds.map((node: any) => {
        if (node.id === id) {

          node.data = {
            ...node.data,
            config: {
              ...node.data.config,
              key_mapping: userInput ? userInput : {}
            },
          }

        }
        return node
      }),
    )
  }

  // const resetPromptInputState = ()=>{

  //   setPromptInputState({})
  // }
  const doManagePrompt = () => {
    const payload = {
      ...promptInputState,
      published: true,
      published_on: Date.now()
    }

    dispatchManagePromptApp(payload)
  }
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);

  const handleCursorChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCursorPosition(event.target.selectionStart);
  };

  return (
    <WizrFlexLayout background='white' flexDirection='row' >

      <WizrFlexLayout justifyContent='space-between' flex={1} background='white'>
        <WizrFlexLayout flex='none' background='white' textAlign='left' style={{ gap: "10px", padding: " 0px 20px" }} flexDirection='coumn'>
          <WizrText type='sub2'>Import</WizrText>
          <WizrFlexLayout background='white' textAlign='left' style={{ gap: "10px" }} flexDirection='row'>

            <WizrDropDown

              placeHolder='Select one option'
              value={promptInputState?.name}
              options={publishedPrompts?.sort((a: any, b: any) => b.updated_on - a.updated_on)?.map((item: any) => ({
                label: item?.name,
                value: item?.id,
              }))}
              onChange={(value: any) => { changePrompt(value) }}
              className="nodrag nopan"
            />
            {/* <WizrButton text={"reset"} type='primary' onClick={()=>{resetPromptInputState()}} style={{gap:"10px",margin:" 0px 20px",borderRadius:"16px"}}></WizrButton> */}
          </WizrFlexLayout>

        </WizrFlexLayout>
        {!viewFullSystemMessage ?
          <>
            <WizrFlexLayout flex='none' background='white'>
              <TextInputContainer promptInputState={promptInputState} doUpdateFields={doUpdateFields} promptTags={promptTags} />
            </WizrFlexLayout>
            <StyledContainer flexDirection='row' justifyContent='space-between' background='white'>
              <PromptSettingsContainer flexDirection='row' background='#FFF' flex={2}>
                <PromptSettingsSection1
                  data={data}
                  parameterInputState={parameterInputState}
                  setParameterInputState={setParameterInputState}
                  parameterList={parameterList}
                  promptInputState={promptInputState}
                  doUpdateFields={doUpdateFields}
                  setPromptInputState={setPromptInputState}
                  keyMappings={keyMappings}
                  setViewFullSystemMessage={setViewFullSystemMessage}
                />
                <PromptSettingsSection2
                  promptInputState={promptInputState}
                  doUpdateFields={doUpdateFields}
                  setPromptInputState={setPromptInputState}
                />
              </PromptSettingsContainer>

            </StyledContainer>
          </> :
          <WizrFlexLayout style={{ padding: '20px' }} background='white'>
            <WizrFlexLayout flexDirection='row' justifyContent='space-between' background='white'>
              <WizrText type='sub2'>System</WizrText>
              <Tooltip title="Restore System Message section">
                <WizrButton icon={<ShrinkOutlined style={{ fontSize: "20px" }} />} onClick={() => { setViewFullSystemMessage(false) }} type='link' />
              </Tooltip>

            </WizrFlexLayout>
            <WizrWholeTextArea
              value={promptInputState?.systemMessage}
              onSelect={handleCursorChange}
              onChange={(value: any) => doUpdateFields('systemMessage', value)} />
            <StyledFlexSpacing justifyContent='space-between' flexDirection='column' alignItems='center' size={20} background='white'>
              <StyledWizrTag
                background="grey"
                data={parameterList}
                onClick={(e: any) => {
                  const clickedTagValue = e; // Replace this with the actual way to get the clicked tag value
                  if (cursorPosition !== null) {
                    // Insert the clicked tag value at the cursor position
                    const updatedSystemMessage =
                      `${promptInputState?.systemMessage.slice(0, cursorPosition)}{{${clickedTagValue}}}${promptInputState?.systemMessage.slice(cursorPosition)}`

                    // Update the systemMessage state with the modified value
                    doUpdateFields('systemMessage', updatedSystemMessage);

                    // Move the cursor position to the end of the inserted value
                    setCursorPosition(cursorPosition + clickedTagValue.length + 4);
                  }
                }}
              />
            </StyledFlexSpacing>
          </WizrFlexLayout>
        }
      </WizrFlexLayout>
      {/* <TestAreaContainer flex={1} background='#FFF'>
            <TestAreaSection id={promptId} promptInputState={promptInputState} />
          </TestAreaContainer> */}
    </WizrFlexLayout>
  )
}
export default PromptEdit




