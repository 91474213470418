import { Tabs, Spin } from 'antd'
import Search from '../assets/icons/Search'
import styled from 'styled-components'
import WizrTag from '../stories/WizrTag/WizrTag'
import WizrPromptsMenu from '../stories/WizrPromptMenuList/WizrPromtMenuList'
import WizrInput from '../components/WizrInput'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrText from '../components/WizrText'
import { useEffect, useState } from 'react'
import { dispatchTemplates,  dispatchPromptPublished, dispatchPromptDraft } from '../redux/actions/content'
import { useContentLoadingSelector, usePromtTemplateSelector } from '../redux/selectors/content'
import WizrButton from '../components/WizrButton'
import CreateNew from '../assets/icons/CreateNew'
import WizrTable from '../components/WizrTable'
import { useNavigate } from 'react-router-dom'
import WizrFlexSpacing from '../components/WizrFlexSpacing'
import WizrUserJourney from '../components/WizrUserJourney'
import { useAuth } from '../authContext'

const TabPane = Tabs.TabPane

const StyledInputHolder = styled(WizrFlexLayout)`
  padding: 0px 33%;
  overflow: hidden;
  .liofSj {
    border-radius: 30px;
  }
`
const StyledWizrButton = styled(WizrButton)`
  border-radius: 20px;
  height: 30px;
  margin-bottom: 3px;
  .anticon {
    color: white;
  }
`

const StyledFlexSpacing = styled(WizrFlexSpacing)`
  .ant-space-item {
    display: flex !important;
    width: 100% !important;
  }
`
const StyleWizrPromptsMenu = styled(WizrPromptsMenu)`
  background-color: #ffffff;
  border-radius: 14px;
  width: 960px;
  margin: 4px auto;
`

const StyledTabs = styled(Tabs)`
  width: 100%;
  flex: 1;
  justify-content: space-between;
`

const StyledWizrTable = styled(WizrTable)`
  .ant-table-wrapper {
    width: 100% !important;
  }
  .ant-table-thead {
    background-color: #f2f4f9;
    border-spacing: 0px;
  }
  .ant-table-tbody > tr {
    background-color: #ffffff;
    margin-bottom: 0px;
  }

  .ant-table table {
    border-spacing: 0 5px;
  }
`
const StyledWizrTag = styled(WizrTag)`
  height: 42px;
  padding: 8px 11px;
  justify-content: space-between;
`

const prompTabs = [
  {
    key: 'templates',
    text: 'Templates',
  },
  {
    key: 'published',
    text: 'Published Prompts',
  },
  {
    key: 'draft',
    text: 'Drafts',
  },
]

const PromptCategory = ({
  prompTemplates,
  tab,
}: {
  prompTemplates: Array<any>
  tab: string
}): JSX.Element => {
  const [promptListData, setPromptListData] = useState<any>()
  const [promptSearch, setPromptSearch] = useState<string>()

  useEffect(() => {
    setPromptListData(prompTemplates)
  }, [prompTemplates])

  const uniqueTagList = prompTemplates?.length
    ? prompTemplates
        .map((item: any) => item.tags)
        .flat()
        .reduce((accumulator: string[], tag: string) => {
          if (tag && !accumulator.includes(tag)) {
            accumulator.push(tag)
          }
          return accumulator
        }, [])
    : []

  uniqueTagList.unshift('All')
  const isContentLoading = useContentLoadingSelector()

  const doFilteringBasedOnTag = (tagName: string) => {
    if (tagName !== 'All') {
      setPromptListData(prompTemplates?.filter((field) => field?.tags?.includes(tagName)))
    } else {
      setPromptListData(prompTemplates)
    }
  }

  const doPromptSearch = (searchText: string) => {
    setPromptSearch(searchText)
    if (searchText?.length) {
      const searchResults = promptListData?.filter(
        (field: any) => field?.name?.toLowerCase().includes(searchText?.toLowerCase()),
      )
      setPromptListData(searchResults)
    } else {
      setPromptListData(prompTemplates)
    }
  }

  return (
    <WizrFlexLayout>
      {isContentLoading ? (
        <Spin />
      ) : (
        <StyledFlexSpacing
          justifyContent='space-between'
          flexDirection='column'
          alignItems='center'
          size={20}
        >
          <StyledInputHolder alignItems='center' justifyContent='space-between'>
            <WizrInput
              value={promptSearch}
              placeholder='Search'
              preComponent={<Search />}
              background='#FFF'
              onChange={doPromptSearch}
            />
            <br />
            <StyledWizrTag
              data={uniqueTagList}
              onClick={(name: any) => doFilteringBasedOnTag(name)}
            />
          </StyledInputHolder>
          <WizrFlexLayout>
            {tab === 'templates' ? (
              <StyleWizrPromptsMenu menuList={promptListData} onSelected={() => {}} />
            ) : (
              <StyledWizrTable
                dataSource={
                  promptListData
                    ? promptListData.sort((a: any, b: any) => b?.updated_on - a?.updated_on)
                    : []
                }
                type='prompt'
                tabSelected={tab}
                scroll={{ y: 500 }}
                pagination={false}
              />
            )}
          </WizrFlexLayout>
        </StyledFlexSpacing>
      )}
    </WizrFlexLayout>
  )
}

const Prompts = (): JSX.Element => {
  const navigate = useNavigate()
  const prompTemplatesData = usePromtTemplateSelector()
  const [tabName, setTabname] = useState<string>('templates')

  useEffect(() => {
    dispatchTemplates()
  }, [])

  const goToCreatePrompt = () => {
    localStorage.setItem('action', '')
    localStorage.setItem('prompt_id', '')
    navigate('/prompt-view')
  }
  const onTabChange = (key: string) => {
    key === 'templates' ? dispatchTemplates() : key ==="published" ?  dispatchPromptPublished(): dispatchPromptDraft()
    setTabname(key)
  }

  const { userPermission, hasAcces } = useAuth();

  return (
    <WizrFlexLayout alignItems='flex-start' justifyContent='space-between'>
      <WizrFlexLayout flexDirection='row' justifyContent='space-between' alignItems='center'>
        <WizrUserJourney userJourney={[{ title: 'AI Studio' }]} />
        <StyledWizrButton
          type='primary'
          icon={<CreateNew fill={'white'} />}
          text='Create New'
          disabled={hasAcces('create_prompt') === true ? false : true}
          onClick={goToCreatePrompt}
        />
      </WizrFlexLayout>
      <WizrFlexLayout flex={10}>
        <StyledTabs defaultActiveKey='1' onChange={onTabChange} destroyInactiveTabPane={false}>
          {prompTabs.map((promptMenuItem: any) => (
            <TabPane
              tab={<WizrText type='body2'>{promptMenuItem.text}</WizrText>}
              key={promptMenuItem.key}
            >
              <PromptCategory prompTemplates={prompTemplatesData} tab={tabName} />
            </TabPane>
          ))}
        </StyledTabs>
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

export default Prompts
