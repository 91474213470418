import styled from "styled-components";

import { AutoComplete, Card, Button } from "antd";
import WizrFlexLayout from "../../../../../components/WizrFlexLayout";
import WizrText from "../../../../../components/WizrText";
import WizrButton from "../../../../../components/WizrButton";
import SentIcon from "../../../../../assets/icons/sent";
import QuestionCards from "./QuestionCards";


const StyledAutoComplete = styled(AutoComplete)`
  height: 52px !important; 
  width: 100%;
  .ant-select-selector {
    height: 52px !important; 
    border-radius: 2rem;
    background-color: #f2f4f9 !important;
    text-align: left;
    padding-right: 40px !important; /* Make room for the button */
    padding-top:10px !important; ;
  }
    .ant-select-selection-search-input{
    margin-top: 10px !important;
    }
`;



const StyledCard = styled(Card)<{ height: any }>`
  border-radius: 1rem; /* 16px */
  background-color: #f2f4f9;
  width:  ${({ height }) => (height > 700 ? "49%" : "100%")};
  .ant-card-body {
    padding: 0;
    border-radius: 1rem; /* 16px */
    background-color: #f2f4f9;
  }
`;

const AutoCompleteWrapper = styled.div`
  position: relative;
  width: 99%;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;
const questions = [
  'How often does project44 ping the data provider for shipment updates? ',
  'Can I get shipment status updates sent to me via email or text message?',
  'Why are shipments not showing up in the VOC for a specific carrier?',
  'If I submit geo-coordinates and a full address for a stop, which one takes priority?'
]

console.log("WelcomeScreen.tsx",window.innerWidth);
console.log("WelcomeScreen.tsx",window.innerWidth>1000);
const data = window.innerWidth


const WelcomeScreen = ({
  userInput,
  handleInputChange,
  handleSearch,
  handleSelect,
  handleKeyDown,
  options,
  testChatBot,
  errorMessage,
  setUserInput,
  testWithQuery,
  language,
  onLanguageChange,
  settingsArray
}: any) => {
  const languages = [
    "English",
    "German",
    "French",
    "Spanish",
    "Portuguese",
    "Italian",
    "Turkish",
    "Japanese",
    "Simplified Chinese"
  ];

  return (
    <WizrFlexLayout
      justifyContent="center"
      alignContent="center"
      background="white"
      style={{
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
      }}
    >
      <WizrFlexLayout
        background="white"
        style={{
          width: data>700?"60%":"100%",
          left: "20%",
          margin: "auto",
          gap: "1.25rem", /* 20px */
          paddingLeft: "0.125rem", /* 2px */
          padding: "1.25rem 2.5rem 1.25rem 2.5rem"
        }}
      >
        <WizrText type="h4" style={{fontFamily:settingsArray?.title_font_style}} sizeFont={settingsArray?.title_font_size}>{settingsArray?.title}</WizrText>
        {/* <p className="web-format-title">Knowledge Search</p> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.125rem", /* 2px */
          }}
        >
        <AutoCompleteWrapper>
  <StyledAutoComplete
    value={userInput}
    onChange={handleInputChange}
    onSearch={handleSearch}
    onSelect={handleSelect}
    onKeyDown={handleKeyDown}
    placeholder="Let's get started"
    options={settingsArray?.most_likely_questions?.status==='active'?options:[]}
  />
  <ButtonWrapper>
    <WizrButton
      style={{ paddingLeft: "0.625rem" }}
      shape="circle"
      onClick={testChatBot}
      icon={<SentIcon />}
    />
  </ButtonWrapper>
</AutoCompleteWrapper>
          {errorMessage && (
            <div style={{ color: "red", marginTop: "0.3125rem" }}> {/* 5px */}
              {errorMessage}
            </div>
          )}
        </div>
      {settingsArray?.predifined_questions?.status==='active'?
      <QuestionCards 
        width={data}
      questions={settingsArray?.predifined_questions?.question_list}
      setUserInput={setUserInput} 
      testWithQuery={testWithQuery}
    />:<></>}
      </WizrFlexLayout>
     { settingsArray?.languages?.status==='active'?<WizrFlexLayout
        flexDirection="row"
        background="white"
        alignItems="center"
        justifyContent="center"
        style={{ gap: "1.25rem", paddingTop: "0.4375rem",paddingRight:data > 600?"0px":"1.5rem",padding:"0.4375rem 1.5rem 0.4375rem 1.5rem" }} /* 7px */
      >
        <WizrText type="hint" textColor="#5D5F62">
          Project 44 offered in:
        </WizrText>
        <div
  style={{
    display: "flex",
    flexDirection: "row",
    gap: "0.625rem", /* 10px */
    padding: "0px 0.1875rem", /* 3px */
    overflowX: "auto", /* Allow horizontal scrolling */
    whiteSpace: "nowrap", /* Prevent wrapping */
    maxWidth: "100%", /* Ensure it doesn’t exceed the parent width */
  }}
>
  
  {settingsArray&& settingsArray?.languages?.language_list.map((item:any) => (
    <div
      key={item}
      style={{
        borderBottom: language === item ? "0.125rem solid black" : "", /* 2px */
        cursor: "pointer",
        display: "inline-block", /* Ensure items don’t wrap */
      }}
      onClick={() => {
        onLanguageChange(item);
      }}
    >
      <WizrText
        type="hint"
        textColor={language === item ? "black" : "#5D5F62"}
      >
        {item}
      </WizrText>
    </div>
  ))}
</div>

      </WizrFlexLayout>:<></>}
    </WizrFlexLayout>
  );
};

export default WelcomeScreen;
