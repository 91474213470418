import styled from 'styled-components'
import Copy from '../../../assets/icons/Copy'
import DeleteIcon from '../../../assets/icons/DeleteIcon'
import EditIcon from '../../../assets/icons/EditIcon'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrText from '../../../components/WizrText'
import { Handle, Position } from 'reactflow'
import WizrButton from '../../../components/WizrButton'
import { Typography } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import input from 'antd/es/input'
import { useState } from 'react'
import { redirect } from 'react-router-dom'
import MoreIcon from '../../../assets/icons/More'
import ImageIcon from '../../../assets/icons/ImageIcon'
import Image from '../../../assets/icons/Image'
import AIIcon from '../../../assets/icons/AIIcon'
import AIModelIcon from '../../../assets/icons/AIModelIcon'
import CodeIcon from '../../../assets/icons/CodeIcon'
import Voice from '../../../assets/icons/Voice'
import TickIcon from '../../../assets/icons/TickIcon'
import QuestionIcon from '../../../assets/icons/Question'
import TicketType from '../../../assets/icons/TicketType'
import Code from '../../../assets/icons/Code'
import StartblockIcon from '../../../assets/icons/StartblockIcon'

interface INodeContainer {
  children: any
  item: any
  data: any
  isEditMode?: boolean
  saveCurrentAction?: any
  onEditNodeDetail?: any
  onCopyNodeDetail?: any
  onDeleteNodeDetail?: any
  startNode?: any
  onUpdateName?: any
  isDisabled?: boolean
  nodeType?:any
}
const StyledInput = styled.input<{ background?: any}>`
  background: ${props => props.background} !important;
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: var(--grayscale-800, #1D1F22);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.1px;

  &::placeholder {
    /* Apply styles to the placeholder text */
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: /* Your desired placeholder text color */;

  }
`;

const NodeContainer = ({
  item,
  children,
  data,
  isEditMode,
  saveCurrentAction,
  onEditNodeDetail,
  onCopyNodeDetail,
  onDeleteNodeDetail,
  startNode,
  onUpdateName,
  isDisabled,
  nodeType
}: INodeContainer): JSX.Element => {
 
  const [inputValue, setInputValue] = useState(item?.nodeTitle || '');

  const getColour = ()=>{
    switch(nodeType){
      case '1':
        return '#99F1A7';
      case '2':
        return '#FFB790'
      case '3':
        return '#C49AF7'
      case '5':
        return '#FF9B9B' 
      case '6':
        return '#82CBFF'     
      case '7':
        return '#FFE350'
      case '8':
        return '#8C91FC' 
      case '9':
        return '#FEABD8'
      case '10':
        return   '#DCDEE2'     

      default :
        return '#F2F4F9'
      
    }
    
  }
  const getIcon = ()=>{
    
    switch(nodeType){
      case '2':
        return <Image stroke = '#1D1F22'/>
      case '3':
        return <AIIcon /> 
      case '5':
        return <AIModelIcon/>
      case '6':
        return <Code />
      case '7':
        return <QuestionIcon stroke = '#1D1F22'/>  
      case '8':
        return <TicketType stroke = '#1D1F22'/>  
      case '9':
        return <Voice /> 
      case '10':
        return <StartblockIcon stoke= '#1D1F22' />       
      default:
        return <></>
    }
  }   
  return (
    <Container type={startNode?.isStartNode}>
      <WizrFlexLayout flexDirection='row' justifyContent='space-between' background={getColour()} >
        <WizrFlexLayout
          alignItems='center'
          justifyContent='space-between'
          flexDirection='row'
          flex={2}
          style={{padding:"10px"}}
          background={getColour()}
        >
          <WizrFlexLayout flexDirection='row' style={{gap:'4px',paddingTop:"4px"}} background='transparent' justifyContent='flex-start' alignItems= 'flex-end'>
            {getIcon()}
          <StyledInput background={getColour()} type="text"  value={inputValue} onChange={(e:any) => {setInputValue(e.target.value); onUpdateName(e.target.value)}}/>
          </WizrFlexLayout>
          <StepContainer>
            <WizrText type='body3'>{item?.id}</WizrText>
          </StepContainer>
        </WizrFlexLayout>

            {startNode?.isStartNode && isEditMode && startNode?.isSaveEnabled && (
  <WizrFlexLayout alignItems='flex-end' style={{borderRadius:"20px", height: '100%'}}>
    <WizrButton text='Save' onClick={saveCurrentAction} />
  </WizrFlexLayout>
)}
      
{
// !startNode?.isStartNode ? (
  isEditMode ? (
    <WizrFlexLayout alignItems='flex-end' style={{ background: '#fff' }}>
      <WizrButton text='Save' onClick={saveCurrentAction}  />
    </WizrFlexLayout>
  ) : (
    <WizrFlexLayout flexDirection='row' justifyContent='flex-end' background={getColour()} style={{padding:"6px 0px 0px 0px"}} >
      <WizrButton icon={<EditIcon />} onClick={onEditNodeDetail} type='link' disabled={isDisabled} style={{padding:'4px 4px'}}/>
      <WizrButton icon={<Copy />} onClick={onCopyNodeDetail} type='link' disabled={isDisabled}/>
      {nodeType!=='10'?  <WizrButton icon={<DeleteIcon />} onClick={() => onDeleteNodeDetail(item.id)} type='link' disabled={isDisabled}/>:<></>}
     
    </WizrFlexLayout>
  )
// ) : <></>
}
      </WizrFlexLayout>
      <WizrFlexLayout  style={{padding:"10px 16px 16px 16px"}}>{children}</WizrFlexLayout>
      <Handle type='source' position={Position.Right} id={`${data?.id}-b`} isConnectable={true} style={{ background: 'rgb(112, 114, 118)', borderRadius: '50%', height: '20px', width: '20px', margin: '0px -20px'  }}/>
      <Handle type='target' position={Position.Left} id={`${data?.id}-d`} isConnectable={true} style={{ background: 'rgb(112, 114, 118)', borderRadius: '50%', height: '20px', width: '20px', margin: '0px -20px'  }}/>
    </Container>
  )
}

const Container = styled(WizrFlexLayout)<{type:boolean}>`
  border-radius: 16px;
  border: 2px solid var(--generic-white, #fff);
  background: var(--grayscale-100, #f2f4f9);
  gap: 16px;
  width:  ${({ type }) => (type ? "395px" : "450px")};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
`

const StepContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 0px 10px;
  margin-left: 10px;
  background: #F2F4F9;
`
export default NodeContainer
