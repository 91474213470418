import { ArrowLeftOutlined } from '@ant-design/icons'
import WizrButton from '../../../../components/WizrButton'
import WizrFlexLayout from '../../../../components/WizrFlexLayout'
import WizrUserJourney from '../../../../components/WizrUserJourney'
import WizrText from '../../../../components/WizrText'
import { useNavigate } from 'react-router-dom'
import WizrInput from '../../../../components/WizrInput'
import styled from 'styled-components'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import CreateNew from '../../../../assets/icons/CreateNew'
import { Button, Radio, RadioChangeEvent, Tooltip } from 'antd'
import DeleteIcon from '../../../../assets/icons/DeleteIcon'
import { useEffect, useState } from 'react'
import WizrTextArea from '../../../../components/WizrTextArea'

const StyledWizrInput = styled(WizrInput)`
width: 47%;
background:#F2F4F9;`

const StyledWizrButton = styled(WizrButton)`
  height:20px;
  width:20px;
  border-radius:50px;
  padding: 5.833px ;
  justify-content: center;
  align-items: center;
  padding-left:13px;
`

const FormSection1 = ({ form1Methods, onHeadersChange }: any) => {

    const { register, setValue, control, getValues } = form1Methods;
    const { fields, append, remove } = useFieldArray({ control, name: "headers" });

    const handleHeaderChange = (index: number, field: string, value: string) => {
        const updatedFields = getValues();
        onHeadersChange(updatedFields);
    };


    

    return (
        <WizrFlexLayout flexDirection='column' justifyContent='flex-start' background='#fff' alignItems='flex-start' style={{ gap: '7px' }}>
            <form style={{ gap: '7px', width: '100%' }}>
                <WizrFlexLayout flexDirection='row' style={{ gap: '5px', height: '25px' }} background='#fff' justifyContent='flex-start' >
                    <WizrText type='body2' >
                        Header
                    </WizrText>
                    <StyledWizrButton icon={<CreateNew fill={'white'} />} style={{}} onClick={() => append({ key: "KEY", value: "VALUE" })} />
                </WizrFlexLayout>
                {fields.map((item: any, index: any) => {
                    return (
                        <WizrFlexLayout flexDirection='row' justifyContent='flex-start' background='#fff' alignItems='flex-start' style={{ gap: '10px', margin: '5px 0px' }} key={item.id}>
                            <Controller
                                render={({ field }) => <WizrInput {...field} value={field.value} onChange={(e: any) => {
                                    field.onChange(e);
                                    handleHeaderChange(index, 'key', e);
                                }}/>}
                                name={`headers.${index}.key`}
                                control={control}

                            />
                            <Controller
                                render={({ field }) => <WizrInput {...field} value={field.value} onChange={(e: any) => {
                                    field.onChange(e);
                                    handleHeaderChange(index, 'value', e);
                                }}/>}
                                name={`headers.${index}.value`}
                                control={control}
                            />
                            <Button type='text' icon={<DeleteIcon />} onClick={() => remove(index)} style={{ minHeight: '50px' }} />
                        </WizrFlexLayout>
                    )
                })}
            </form>
        </WizrFlexLayout>
    )
}

const FormSection2 = ({ form2Methods }: any) => {

    const { register, setValue, control } = form2Methods;
    const {
        fields,
        append,
        remove,
    } = useFieldArray({
        control,
        name: "parameters"
    });

    return (
        <WizrFlexLayout flexDirection='column' justifyContent='flex-start' background='#fff' alignItems='flex-start' style={{ gap: '7px' }}>
            <form style={{ gap: '7px', width: '100%' }}>
                <WizrFlexLayout flexDirection='row' style={{ gap: '5px', height: '25px' }} background='#fff' justifyContent='flex-start' >
                    <WizrText type='body2' >
                        Parameter
                    </WizrText>
                    <StyledWizrButton icon={<CreateNew fill={'white'} />} style={{}} onClick={() => append({ key: "KEY", value: "VALUE" })} />
                </WizrFlexLayout>
                {fields.map((item: any, index: any) => {
                    return (
                        <WizrFlexLayout flexDirection='row' justifyContent='flex-start' background='#fff' alignItems='flex-start' style={{ gap: '10px', margin: '5px 0px' }} key={item.id}>
                            <Controller
                                render={({ field }) => <WizrInput {...field} value={field.value} />}
                                name={`parameter.${index}.key`}
                                control={control}

                            />
                            <Controller
                                render={({ field }) => <WizrInput {...field} />}
                                name={`parameter.${index}.value`}
                                control={control}
                            />
                            <Button type='text' icon={<DeleteIcon />} onClick={() => remove(index)} style={{ minHeight: '50px' }} />
                        </WizrFlexLayout>
                    )
                })}
            </form>
        </WizrFlexLayout>
    )
}

const FormSection3 = ({ form3Methods }: any) => {

    const { register, setValue, control } = form3Methods;
    const [bodyType, setBodyType] = useState(form3Methods.control._formValues && form3Methods.control._formValues.bodyType
        ? form3Methods.control._formValues.bodyType
        : 'url_encoded')

    const {
        fields,
        append,
        remove,
    } = useFieldArray({
        control,
        name: "formSection3"
    });

    const onChangeBody = (e: any) => {
        const selectedBodyType = e.target.value;
        setValue('bodyType', selectedBodyType); // Update 'bodyType' in form state
        setBodyType(selectedBodyType); // Update local state

        if (selectedBodyType !== 'body') {
            setValue('body', ''); // Clear 'body' value when 'bodyType' is not 'body'
        }
    }

    return (
        <WizrFlexLayout flexDirection='row' justifyContent='flex-start' background='#fff' alignItems='flex-start' >
            <form style={{ gap: '7px', width: '100%' }}>
                <WizrFlexLayout flexDirection='row' style={{ gap: '5px', height: '25px' }} background='#fff' justifyContent='flex-start'>
                    <WizrText type='body2' >
                        Body
                    </WizrText>
                    <StyledWizrButton icon={<CreateNew fill={'white'} />} onClick={() => append({ key: "KEY", value: "VALUE" })} />
                </WizrFlexLayout>
                <WizrFlexLayout style={{ height: '25px' }} background='#fff' justifyContent='flex-start' alignItems='flex-start'>
                    <Radio.Group onChange={onChangeBody} value={bodyType}>
                        <Radio value={'url_encoded'}>URL Encoded</Radio>
                        <Radio value={'form_data'}>Form Data</Radio>
                        <Radio value={'body'}>Body</Radio>
                    </Radio.Group>
                </WizrFlexLayout>

                {bodyType === 'body' ?
                    <WizrFlexLayout background='#fff' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' style={{ gap: '10px', marginBottom: '10px', width: '100%' }}>
                        <Controller
                            render={({ field }) => <WizrTextArea {...field} value={field.value} />}
                            name={`body`}
                            control={control}
                        />
                    </WizrFlexLayout>
                    : <WizrFlexLayout background='#fff'>
                        {fields.map((item: any, index: any) => {
                            return (
                                <WizrFlexLayout background='#fff' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' style={{ gap: '10px', marginBottom: '10px' }} key={item.id}>
                                    <Controller
                                        render={({ field }) => <WizrInput {...field} value={field.value} />}
                                        name={`formSection3.${index}.key`}
                                        control={control}

                                    />
                                    <Controller
                                        render={({ field }) => <WizrInput {...field} />}
                                        name={`formSection3.${index}.value`}
                                        control={control}
                                    />
                                    <Button type='text' icon={<DeleteIcon />} onClick={() => remove(index)} style={{ minHeight: '50px' }} />
                                </WizrFlexLayout>
                            )
                        })}
                    </WizrFlexLayout>}
            </form>
        </WizrFlexLayout>
    )
}

const APIWidget = ({ promptInputState, setPromptInputState }: any) => {

    const { register: registerHeader, setValue: setValueHeader, getValues: getValuesHeader, control: controlHeader } = useForm({
        defaultValues: {
            headers: [{ key: "", value: "" }],
        }
    });
    const { register: registerParameter, setValue: setValueParameter, getValues: getValuesParameter, control: controlParameter } = useForm({
        defaultValues: {
            parameters: [{ key: "", value: "" }],
        }
    });
    const { register: register3, setValue: setValue3, getValues: getValues3, control: control3 } = useForm({
        defaultValues: {
            formSection3: [{ key: "", value: "" }],
            body: '',
            bodyType: 'url_encoded'
        }
    });

    const [apiMethod, setApiMethod] = useState<any>("get")
    const [url, setUrl] = useState<any>("")

    const handleModeChange = (e: RadioChangeEvent) => {
        setApiMethod(e.target.value)
    };

    const handleUrl = (value: any) => {
        setUrl(value)
    }

    const fieldArrayFormValues = getValuesHeader().headers

    const handleHeadersChange = (headers: any) => {
        console.log('Updated headers:', headers);
        // Handle the updated headers here in the parent component
    };
    


    return (
        <WizrFlexLayout background='#FFF' textAlign='left' >
            <WizrFlexLayout background='#fff' justifyContent='center' alignItems='center'>
                <Radio.Group onChange={handleModeChange} style={{ flex: 1, display: 'flex', width: '500px' }}>
                    <Radio.Button value="get" style={{ flex: 1, display: 'flex', background: apiMethod === 'post' ? '#E5E7EB' : '#ffff' }}  >Get </Radio.Button>
                    <Radio.Button value="post" style={{ flex: 1, display: 'flex', background: apiMethod === 'get' ? '#E5E7EB' : '#ffff' }}>Post </Radio.Button>
                </Radio.Group>
            </WizrFlexLayout>
            <WizrFlexLayout alignItems='flex-start' background='#fff' style={{ margin: "10px 0px" }}>
                <WizrText type='body2' >
                    URL
                </WizrText>
                <WizrInput onChange={handleUrl} value={url}></WizrInput>
            </WizrFlexLayout>
            <FormSection1 form1Methods={{ register: registerHeader, setValue: setValueHeader, getValues: getValuesHeader, control: controlHeader }} onHeadersChange={handleHeadersChange}/>
            <FormSection2 form2Methods={{ register: registerParameter, setValue: setValueParameter, getValues: getValuesParameter, control: controlParameter }} />
            {apiMethod === 'post' ? <FormSection3 form3Methods={{ register: register3, setValue: setValue3, getValues: getValues3, control: control3 }} /> : <></>}
        </WizrFlexLayout>
    );
};

export default APIWidget;
