import { dispatchListFolders, dispatchCreateFolder, dispatchListAutoTags, dispatchCreateFodlerFaqPt, dispatchListUserRows } from '../redux/actions/content'
import { useContentsSelector, useContentLoadingSelector, useAutoTagListSelector, usePastTicketDataSelector } from '../redux/selectors/content'
import { useEffect, useState } from 'react'
import WizrTable from '../components/WizrTable'
import { Card, Spin } from 'antd'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrUserJourney from '../components/WizrUserJourney'
import WizrButton from '../components/WizrButton'
import { useWizrModalContext, MODAL_TYPES } from '../providers/modal/WizrModalProvider'
import CreateNew from '../assets/icons/CreateNew'
import { dispatchNotificationState } from '../redux/actions/common'
import WizrText from '../components/WizrText'
import { useAuth } from '../authContext';
import styled from 'styled-components'
import { pastTickets } from '../utils'
import { on } from 'events'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'


const PastTickets = (): JSX.Element => {
  const { hasAcces } = useAuth();
  const contentsListing = usePastTicketDataSelector()
  const isContentLoading = useContentLoadingSelector()
  const { showModal, hideModal } = useWizrModalContext()
  const autoTagData = useAutoTagListSelector()
  
  const navigate = useNavigate()

  useEffect(() => {
    dispatchListAutoTags()
    dispatchListFolders('past_tickets')
  }, [])
 
  const onAddGroup = () =>
  showModal(MODAL_TYPES.CREATE_UPDATE_PASTTICKKETS, {
    onFormSubmission: async ({ TicketFolderName, TicketFolderDescription, AutoTag ,embeddingModel}: any) => {
      
      console.log('AutoTag', AutoTag);
      console.log('autoTagData', autoTagData);

      const featureItem = autoTagData?.find((item:any)=>item?.app_feature_id===AutoTag)
      const tenantFeatureId = featureItem?.tenant_feature_id
      

      const isExistingName = contentsListing?.find(
        (content: any) => TicketFolderName === content?.folder_name,
      )
      if (!isExistingName) {
        const payload ={
          folder_id: uuidv4(),
          app_feature_id:AutoTag,
          folder_name: TicketFolderName,
          folder_description: TicketFolderDescription ? TicketFolderDescription : '',
          tenant_feature_id: tenantFeatureId,
          embedding_model_id:embeddingModel,
          created_at: Date.now(),
          type: 'past_tickets',
        }
        await dispatchCreateFodlerFaqPt(payload)
        dispatchListFolders('past_tickets')

        hideModal()
      } else {
        dispatchNotificationState({
          message: 'Error',
          description: `${TicketFolderName} already exists. Please try different name.`,
          type: null,
        })
      }
    },
    onCancel: hideModal,
  })



  return (
    <WizrFlexLayout>
    <WizrFlexLayout justifyContent='space-between' alignItems='center' flexDirection='row' flex={1.5}>
      <WizrUserJourney
        userJourney={[
          {
            title: <WizrButton type='text' text={<WizrText type='h6'>Past Ticket</WizrText>} onClick={()=>navigate(-1)}/>,
            isCurrent: true,
          },
        ]}
      />
        <WizrButton
        style={{borderRadius:"18px"}}
        type='primary'
        icon={<CreateNew fill='white' />}
        text='Create New'
        disabled={hasAcces('create_folder') === true ? false : true}
        onClick={() =>
          onAddGroup()
        }
      />        
    </WizrFlexLayout>
    <WizrFlexLayout flex={11}>
      {isContentLoading ? (
        <Spin />
      ) : (
        <WizrTable type='past_tickets' dataSource={contentsListing} pagination={false} size='small' />
      )}
    </WizrFlexLayout>
  </WizrFlexLayout>
  )
}

export default PastTickets
