import { Tooltip } from 'antd'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrText from '../../../components/WizrText'
import InfoIcon from '../../../assets/icons/Info'
import WizrSlider from '../../../components/WizrSlider'
import styled from 'styled-components'
import WizrTagInput from '../../../components/WizrTagInput'
import WizrInput from '../../../components/WizrInput'
import { useEffect } from 'react'
const IndividualSettingsBox = styled.div`
  width: 100%;
  text-align: left;
  height: auto;
  margin: 10px 0px 0px 0px;
  background-color: white;
  padding: 0px;
`

const IndividualLlmSettingsBox = styled.div`
  width: 90%;
  text-align: left;
  height: auto;
  margin: 10px 0px 0px 0px;
`

const SliderData = ['frequency_penalty', 'presence_penalty', 'temperature', 'top_p']
const InputData = ['best_of', 'max_tokens', 'post_response_text', 'pre_response_text']

const SettingLLM = ({
  doUpdateFields,
  promptInputState,
  selectedLLMSetting,
  setPromptInputState,
}: any): JSX.Element => {
  //     const data = LLMModelData.find((obj: any) => obj.display_name === value)

  const findName = (name: any) => {
    switch (name) {
      case 'temperature':
        return 'Temperature'
      case 'frequency_penalty':
        return 'Frequency Penalty'
      case 'presence_penalty':
        return 'Presence Penalty'
      case 'top_p':
        return 'Top p'
      case 'best_of':
        return 'Best of'
      case 'max_tokens':
        return 'Max Tokens'
      case 'post_response_text':
        return 'Post Response Text'
      case 'pre_response_text':
        return 'Pre Response Text'
    }
  }
  const findDescription = (des: any) => {
    switch (des) {
      case 'temperature':
        return " It controls the randomness of the response .A lower value makes answers more focused and consistent, while a higher value adds variety and creativity. It is advisable to modify either the temperature or the Top P value, but not both simultaneously, to achieve desired outcomes.{' '}"
      case 'frequency_penalty':
        return ' It reduces the likelihood of generating words that are common in the training  data. A higher frequency penalty value will result in the model being more conservative in its use of repeated tokens. A lower frequency penalty value will  result in the model being more likely to use common words or phrases'
      case 'presence_penalty':
        return 'A higher frequency penalty value will result in the model being more conservative in its use of repeated tokens. A lower frequency penalty value will result in the  model being more likely to use common words or phrases'
      case 'top_p':
        return ' It is also used to control randomness. When you decrease Top P, the model sticksto more expected words. On the flip side, raising Top P lets the model choose fromboth common and uncommon words. Remember, it is best to tweak either temperature or Top P, but not both, for effective adjustments.'
      // case "best_of":
      //     return "Best of"
      // case "max_tokens":
      //     return "Max Tokens"
      // case "post_response_text":
      //     return "Post Response Text"
      // case "pre_response_text":
      //     return "Pre Response Text"
    }
  }
  const findMaxValue = (name: any) => {
    switch (name) {
      case 'temperature':
      case 'top_p':
        return 1
      case 'frequency_penalty':
      case 'presence_penalty':
        return 2
      default:
        return 0 // Default value, you might want to adjust it based on your requirements
    }
  }
  const findMinLabel = (name: any) => {
    switch (name) {
      case 'temperature':
        return 'FOCUSED'
      case 'top_p':
        return 'NARROW'
      case 'frequency_penalty':
        return 'REPETITIVE'
      case 'presence_penalty':
        return 'CONSISTENT'
      default:
        return ''
    }
  }
  const findMaxLabel = (name: any) => {
    switch (name) {
      case 'temperature':
        return 'CREATIVE'
      case 'top_p':
        return 'BROAD'
      case 'frequency_penalty':
        return 'DIVERSE'
      case 'presence_penalty':
        return 'NOVAL'
      default:
        return ''
    }
  }

  return (
    <WizrFlexLayout background='white' style={{ padding: '0px', overflowX: 'hidden' }}>
      {selectedLLMSetting &&
        selectedLLMSetting?.map((item: any, index: any) => {
          return (
            SliderData.includes(item?.key) && (
              <IndividualSettingsBox style={{ margin: '2px' }} key={index}>
                <WizrFlexLayout
                  flexDirection='row'
                  justifyContent='space-between'
                  background='white'
                  flex='none'
                  style={{ width: '98%' }}
                >
                  <WizrText type='body2'>{findName(item?.key)}</WizrText>
                  <Tooltip
                    title={
                      <WizrText textColor='white' type='caption'>
                        {findDescription(item?.key)}
                      </WizrText>
                    }
                  >
                    {' '}
                    <InfoIcon />{' '}
                  </Tooltip>
                </WizrFlexLayout>
                <IndividualLlmSettingsBox>
                  <WizrSlider
                    min={0}
                    max={findMaxValue(item?.key)}
                    minLabel={findMinLabel(item?.key)}
                    maxLabel={findMaxLabel(item?.key)}
                    value={promptInputState[item?.key]}
                    defaultValue={item?.value}
                    onSliderChange={(value: any) => doUpdateFields(`${item?.key}`, value)}
                  />
                </IndividualLlmSettingsBox>
              </IndividualSettingsBox>
            )
          )
        })}

      {selectedLLMSetting &&
        selectedLLMSetting?.map((item: any, index: any) => {
          return (
            InputData.includes(item?.key) && (
              <>
                {/* <IndividualSettingsBox>
                <WizrText type='body2'>{item?.key}</WizrText>
              </IndividualSettingsBox>
              <IndividualSettingsBox>
                <WizrTagInput
                  open={false}
                  onChange={(e: any) => doUpdateFields(`${item?.key}`, e)}
                  placeHolder='Stop sequence'
                  value={promptInputState?.stop_sequences}
                />
                
              </IndividualSettingsBox> */}
                <IndividualSettingsBox>
                  <WizrText type='body2'>{findName(item?.key)}</WizrText>
                </IndividualSettingsBox>
                <IndividualSettingsBox>
                  <WizrInput
                    placeholder={findName(item?.key)}
                    value={promptInputState[item?.key]}
                    onChange={(value: any) => doUpdateFields(`${item?.key}`, value)}
                  />
                </IndividualSettingsBox>
              </>
            )
          )
        })}

      {/* <IndividualSettingsBox style={{margin:"2px"}}>
            <WizrFlexLayout
              flexDirection='row'
              justifyContent='space-between'
              background='white'
              flex='none'
              style={{width:"98%"}}
            >
              <WizrText type='body2'>Top_p</WizrText>
              <Tooltip
                title={
                  <WizrText textColor='white' type='caption'>
                    It is also used to control randomness. When you decrease Top P, the model sticks
                    to more expected words. On the flip side, raising Top P lets the model choose from
                    both common and uncommon words. Remember, it is best to tweak either temperature
                    or Top P, but not both, for effective adjustments.
                  </WizrText>
                }
              >
                {' '}
                <InfoIcon />{' '}
              </Tooltip>
            </WizrFlexLayout>
            <IndividualLlmSettingsBox>
              <WizrSlider
                min={0}
                max={1}
                minLabel='NARROW'
                maxLabel='BROAD'
                defaultValue={0.5}
                value={promptInputState?.top_p}
                onSliderChange={(value: any) => doUpdateFields('top_p', value)}
              />
            </IndividualLlmSettingsBox>
          </IndividualSettingsBox>
          <IndividualSettingsBox style={{margin:"2px"}}>
            <WizrFlexLayout
              flexDirection='row'
              justifyContent='space-between'
              background='white'
              flex='none'
              style={{width:"98%"}}
            >
              <WizrText type='body2'>Frequency Penalty</WizrText>
              <Tooltip
                title={
                  <WizrText textColor='white' type='caption'>
                    It reduces the likelihood of generating words that are common in the training
                    data. A higher frequency penalty value will result in the model being more
                    conservative in its use of repeated tokens. A lower frequency penalty value will
                    result in the model being more likely to use common words or phrases
                  </WizrText>
                }
              >
                {' '}
                <InfoIcon />{' '}
              </Tooltip>
            </WizrFlexLayout>
            <IndividualLlmSettingsBox>
              <WizrSlider
                min={0}
                max={2}
                minLabel='REPETITIVE'
                maxLabel='DIVERSE'
                defaultValue={0}
                value={promptInputState?.frequency_penalty}
                onSliderChange={(value: any) => doUpdateFields('frequency_penalty', value)}
              />
            </IndividualLlmSettingsBox>
          </IndividualSettingsBox>
          <IndividualSettingsBox style={{margin:"2px"}}>
            <WizrFlexLayout
              flexDirection='row'
              justifyContent='space-between'
              background='white'
              flex='none'
              style={{width:"98%"}}
            >
              <WizrText type='body2'>Presence Penalty</WizrText>
              <Tooltip
                title={
                  <WizrText textColor='white' type='caption'>
                    A higher frequency penalty value will result in the model being more conservative
                    in its use of repeated tokens. A lower frequency penalty value will result in the
                    model being more likely to use common words or phrases
                  </WizrText>
                }
              >
                {' '}
                <InfoIcon />{' '}
              </Tooltip>
            </WizrFlexLayout>
            <IndividualLlmSettingsBox>
              <WizrSlider
                min={0}
                max={2}
                minLabel='CONSISTENT'
                maxLabel='NOVAL'
                defaultValue={0}
                value={promptInputState?.presence_penalty}
                onSliderChange={(value: any) => doUpdateFields('presence_penalty', value)}
              />
            </IndividualLlmSettingsBox>
          </IndividualSettingsBox> */}
      {/* <IndividualSettingsBox>
            {/* <WizrText type='body2'>Stop Sequence</WizrText> */}
      {/* </IndividualSettingsBox>
          <IndividualSettingsBox>
            <WizrTagInput
              open={false}
              onChange={(e: any) => doUpdateFields('stop_sequences', e)}
              placeHolder='Stop sequence'
              value={promptInputState?.stop_sequences}
            />
          </IndividualSettingsBox> */}
      {/* <IndividualSettingsBox>
            <WizrText type='body2'>Token Limit</WizrText>
          </IndividualSettingsBox>
          <IndividualSettingsBox>
            <WizrInput
              placeholder='Token Limit'
              value={promptInputState?.max_tokens}
              onChange={(value: any) => doUpdateFields('max_tokens', value)}
            />
          </IndividualSettingsBox> */}
      <IndividualSettingsBox>
        {/* <WizrFlexLayout background='#FFF' flexDirection='row' justifyContent='flex-end'>
              <WizrButton text='Save' disabled={loading} onClick={doManagePrompt} />
            </WizrFlexLayout> */}
        <WizrText type='body2'>Match Threshold</WizrText>
        <WizrInput
          type='number'
          max={1.0}
          min={0.0}
          placeholder='Token Limit'
          step={0.1}
          value={promptInputState?.match_threshold}
          onChange={(value: number) => {
            const numericValue = Number(value); // Convert to a number
            const sanitizedValue = numericValue > 1 ? 1 : numericValue < 0 ? 0 : numericValue;

            doUpdateFields('match_threshold', sanitizedValue)}}
        />
              </IndividualSettingsBox>
<IndividualSettingsBox>
  
        <WizrText type='body2'>Top K</WizrText>
        <WizrInput
          type='number'
          placeholder='Token Limit'
          max={15}
          min={1}
          step={1}
          value={promptInputState?.top_k}
          onChange={(value: number) => {
            const numericValue = Number(value); // Convert to a number
    const sanitizedValue = numericValue > 15 ? 15 : numericValue < 1 ? 1 : numericValue;
    doUpdateFields('top_k', sanitizedValue);
          }}
         />{' '}
      </IndividualSettingsBox>
    </WizrFlexLayout>
  )
}
export default SettingLLM
