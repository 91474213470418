const SlackIcon = ()=>{
    return(
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_12639_45634)">
<rect width="24" height="24" fill="white"/>
<path d="M5.11035 15.1337C5.11035 16.508 3.98948 17.6306 2.61491 17.6306C1.24054 17.6306 0.118164 16.508 0.118164 15.1337C0.118164 13.7593 1.24073 12.6367 2.6151 12.6367H5.11054L5.11035 15.1337ZM6.36829 15.1337C6.36829 13.7593 7.49085 12.6367 8.86523 12.6367C10.2396 12.6367 11.3622 13.7591 11.3622 15.1337V21.384C11.3622 22.7583 10.2398 23.8809 8.86523 23.8809C7.49085 23.8809 6.36829 22.7583 6.36829 21.384V15.1337Z" fill="#DE1C59"/>
<path d="M8.86523 5.10938C7.49085 5.10938 6.36829 3.9885 6.36829 2.61394C6.36829 1.23956 7.49085 0.117188 8.86523 0.117188C10.2396 0.117188 11.3622 1.23975 11.3622 2.61412V5.10956L8.86523 5.10938ZM8.86523 6.36731C10.2396 6.36731 11.3622 7.48987 11.3622 8.86425C11.3622 10.2386 10.2398 11.3612 8.86523 11.3612H2.61491C1.24054 11.3612 0.118164 10.2388 0.118164 8.86425C0.118164 7.48987 1.24073 6.36731 2.6151 6.36731H8.86523Z" fill="#35C5F0"/>
<path d="M18.8895 8.86425C18.8895 7.48987 20.0104 6.36731 21.3849 6.36731C22.7593 6.36731 23.8819 7.48987 23.8819 8.86425C23.8819 10.2386 22.7593 11.3612 21.3849 11.3612H18.8895V8.86425ZM17.6316 8.86425C17.6316 10.2386 16.509 11.3612 15.1346 11.3612C13.7603 11.3612 12.6377 10.2388 12.6377 8.86425V2.61394C12.6377 1.23956 13.7601 0.117188 15.1346 0.117188C16.509 0.117188 17.6316 1.23975 17.6316 2.61412V8.86425Z" fill="#2EB57D"/>
<path d="M15.1346 18.8885C16.509 18.8885 17.6316 20.0094 17.6316 21.384C17.6316 22.7583 16.509 23.8809 15.1346 23.8809C13.7603 23.8809 12.6377 22.7583 12.6377 21.384V18.8885H15.1346ZM15.1346 17.6306C13.7603 17.6306 12.6377 16.508 12.6377 15.1337C12.6377 13.7593 13.7601 12.6367 15.1346 12.6367H21.3849C22.7593 12.6367 23.8819 13.7591 23.8819 15.1337C23.8819 16.508 22.7593 17.6306 21.3849 17.6306H15.1346Z" fill="#EBB02E"/>
</g>
<defs>
<clipPath id="clip0_12639_45634">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

    )
}
export default SlackIcon;