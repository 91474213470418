import { Typography } from 'antd'
import { styled } from 'styled-components'

const { Text } = Typography

type ITextType =
  | 'h6'
  | 'sub1'
  | 'sub2'
  | 'body1'
  | 'body2'
  | 'button'
  | 'body3'
  | 'overline'
  | 'caption'

interface IWizrText {
  children: any
  type: ITextType
  textColor?: string | undefined
}

const titleMapping: { [key: string]: any } = {
  h6: {
    color: '#1F1D1D',
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    fontWeight: 500,
  },
  sub1: {
    color: '#1F1D1D',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.15px',
    fontWeight: 600,
  },
  sub2: {
    color: '#1F1D1D',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.1px',
    fontWeight: 600,
  },
  body1: {
    color: '#1F1D1D',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    fontWeight: 500,
  },
  body2: {
    color: '#1F1D1D',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.25px',
    fontWeight: 500,
  },
  button: {
    color: '#FFF',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0px',
    fontWeight: 500,
  },
  body3: {
    color: '#1F1D1D',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    fontWeight: 500,
  },
  overline: {
    color: '#1F1D1D',
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '1.5px',
    fontWeight: 500,
  },
  caption: {
    color: '#999B9F',
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    fontWeight: 400,
  },
}

const StyledText = styled(Text)<any>`
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Montserrat;
  font-style: normal;
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : 300)};
  color: ${(p) => (p.color ? p.color : '#000')};
  font-size: ${(p) => (p.fontSize ? p.fontSize : '12px')};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : '0px')};
  letter-spacing: ${(p) => (p.letterSpacing ? p.letterSpacing : '0px')};
`

const WizrText = ({ children, type, textColor }: IWizrText) => {
  const headerTextProps = titleMapping[type]
  const { color, fontSize, lineHeight, letterSpacing, fontWeight } = headerTextProps

  return (
    <StyledText
      color={textColor || color}
      fontSize={fontSize}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      fontWeight={fontWeight}
    >
      {children}
    </StyledText>
  )
}

export default WizrText
