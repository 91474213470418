import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CreateNew from '../../../assets/icons/CreateNew'
import Hamburger from '../../../assets/icons/Hamburger'
import WizrButton from '../../../components/WizrButton'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrText from '../../../components/WizrText'
import WizrUserJourney from '../../../components/WizrUserJourney'
import Analytics from '../../../assets/icons/AnalyticsIcon'
import WizrDropDown from '../../../components/WizrDropDown'
import styled from 'styled-components'
import WizrToggleButton from '../../../components/WizrToggleButton'
import WizrInput from '../../../components/WizrInput'
import { useEffect, useState } from 'react'
import {
  dispatchBotDetails,
  dispatchClearBotDetails,
  dispatchClearChat,
  dispatchDeleteWidget,
  dispatchFeatureSettings,
  dispatchGetPackageDetails,
  dispatchGetWidgetTypes,
  dispatchIntegrationList,
  dispatchListWidgets,
  dispatchMasterIntegrationList,
  dispatchUpdateFeatureSettings,
  dispatchUpdateFeatureSettingsStatus,
  dispatchUpdateSelectedTools,
} from '../../../redux/actions/content'
import {
  useAgentListSelector,
  useContentLoadingSelector,
  useFeatureSettingSelector,
  useGetIntegrationList,
  useGetMasterIntegrationList,
  useWidgetListSelector,
  useappDataSelector,
} from '../../../redux/selectors/content'
import ZendeskIcon from '../../../assets/icons/ZendeskIcon'
import WizrTextArea from '../../../components/WizrTextArea'
import Category from '../../../assets/icons/Category'
import { setInterval } from 'timers'
import { set } from 'react-hook-form'
import { Card, Tabs, TabsProps, Tooltip } from 'antd'
import TabPane from 'antd/es/tabs/TabPane'
import BotPersonality from './AutoSolveComponents.tsx/BotPersonality'
import SetupBot from './AutoSolveComponents.tsx/setupbot'
import Installations from './AutoSolveComponents.tsx/Installations'
import CreateAgentPopover from '../../../components/popovers/CreateAgent'
import Toolbox from '../../../assets/icons/Toolbox'

const StyledDropDown = styled(WizrDropDown)`
  height: 40px;
  width: 24% !important;
  .ant-select-selector {
    height: 40px !important;
    background-color: #f2f4f9 !important;
    border-radius: 16px;
    border: 0px #f2f4f9 !important;
    .ant-select-selection-placeholder {
      margin-top: 6px;
    }
  }
`
const StyledTextButton = styled(WizrText)`
  text-decoration: underline !important;
`

const StyledInput = styled(WizrInput)`
  height: 40px;
  width: 30%;
  background-color: #f2f4f9;
  border: 0px #f2f4f9 !important;
`

const StyledDropDownDefault = styled(WizrDropDown)`
  height: 40px;
  .ant-select-selector {
    height: 40px !important;
    background-color: #f2f4f9 !important;
    border-radius: 16px;
    border: 0px #f2f4f9 !important;
    padding-top: 5px !important;
    .ant-select-selection-placeholder {
      margin-top: 2px;
    }
  }
`
const StyledInputName = styled.input`
  padding-top: 4px;
  background: #F2F4F9;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #1F1D1D;
  width: 100%;

  &:placeholder {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #1F1D1D;
  }
`;

const StyledTabs = styled(Tabs)`
  width: 100%;
  flex: 1;
  justify-content: space-between;
  .ant-tabs-nav{
    margin-bottom: 0px;
    }
`

// const StyledTicketCard = styled(Card) <any>`
//   border: ${(props) => (props.isClicked ? '1px solid #4ADE80' : '')};
//   background-color: #ffffff;
//   min-width: 302px;
//   max-width: 302px;
//   .ant-card-meta-detail {
//     border-bottom: none;
//     border-top: none;
//   }
//   .ant-card-head {
//     border-bottom: none;
//     text-align: start;
//     padding: 10px !important;
//   }
//   .ant-card-body {
//     text-align: center;
//     padding: 15px;
//   }
// `

const StyledTicketCard = styled(Card) <any>`
  background-color: #ffffff;
  min-width: 23%;
  max-width: 23%;
  border-radius:16px;
  .ant-card-meta-detail {
    border-bottom: none;
    border-top: none;
  }
  .ant-card-head {
    border-bottom: none;
    text-align: start;
    padding: 8px 8px 0px 24px !important;
  }
  .ant-card-body {
    text-align: center;
    padding: 0px 24px 24px 24px;
  }
`
const WidgetListing = () => {

  const navigate = useNavigate();
  const agentList = useAgentListSelector();

  useEffect(() => {
    dispatchListWidgets()
    dispatchGetWidgetTypes()
  }, []);

  const onDelete = async (item: any) => {
    await dispatchDeleteWidget(item?.id)
    await dispatchListWidgets()
  }

  const onEdit = (item: any) => {    
    navigate(`/widgets/${item?.tool_type}`, { state: { id: item?.id } })
  }

  const colors = ['#FFAF14', '#FF7FC4', '#C6E504'];
  const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

  const widgetsList = useWidgetListSelector();
  return (
    <WizrFlexLayout flexDirection="row" justifyContent='flex-start' style={{ gap: '30px', padding: "12px", display: 'flex', flex: '1', flexWrap: 'wrap' }}>
      {widgetsList &&
        widgetsList?.map((item: any, index: any) => {
          return (
            
            <StyledTicketCard
              key={index}
              title={<WizrFlexLayout
                background='#FFFFFF'
                flexDirection='row'
                style={{
                  padding: '10px 0px',
                  alignItems: 'center'
                }}
                textAlign='left'
              >
                <WizrFlexLayout background='#FFFFFF'
                  flexDirection='row' justifyContent='flex-start' textAlign='left' alignItems='center' style={{ gap: '10px' }}>
                  {/* <Toolbox stroke={getRandomColor()}/> */}
                  <div style={{ width: '24px', height: '24px' }}> {/* Adjust width and height as needed */}
                    <Toolbox stroke={getRandomColor()} style={{ width: '100%', height: '100%' }} />
                  </div>



                  <WizrText type='sub1' textColor="#000000" style={{

                    whiteSpace: 'normal', // Allows the text to wrap
                    overflowWrap: 'break-word' // Breaks long words if necessary
                  }}>{item?.tool_name}</WizrText>
                </WizrFlexLayout>



                <CreateAgentPopover details={item} onEdit={onEdit} onDelete={onDelete} type={'widget'} />




              </WizrFlexLayout>}
              bordered={true}
              hoverable>
              <WizrFlexLayout background="#FFF" textAlign="left">
                {/* <WizrText type='cardData' >{item?.params?.fn_params?.description}</WizrText> */}
                <WizrText type="cardData">
                  <Tooltip
                    title={item?.params?.fn_params?.description} // Full description shown on hover
                    color="grey"
                  >
                    {item?.params?.fn_params?.description
                      ? (() => {
                        const words = item.params.fn_params.description.split(' '); // Split description into words
                        const truncatedDescription = words.slice(0, 12).join(' '); // Take the first 12 words
                        return words.length > 12 ? `${truncatedDescription} ...` : truncatedDescription; // Append " ..." if truncated
                      })()
                      : ""}
                  </Tooltip>
                </WizrText>





              </WizrFlexLayout>
            </StyledTicketCard>




            // <StyledTicketCard
            //   key={index}
            //   isClicked={item?.id === agentList[0]?.tools[0]}
            //   title={<WizrFlexLayout
            //     background='#FFFFFF'
            //     flexDirection='row'
            //   >
            //     <WizrText type='sub1' textColor="#000000" style={{ padding: '10px 0px' }}>{item?.tool_name}</WizrText>
            //     <CreateAgentPopover details={item} onEdit={onEdit} onDelete={onDelete} />
            //   </WizrFlexLayout>}
            //   bordered={true}
            //   hoverable>
            //   <WizrFlexLayout background="#FFF" textAlign="left">
            //     <WizrText type='cardData' >{item?.params?.fn_params?.description}</WizrText>
            //   </WizrFlexLayout>
            // </StyledTicketCard>
          )
        })}
    </WizrFlexLayout>
  );
}


const AutoSolve = () => {

  const navigate = useNavigate()

  useEffect(() => {
    dispatchFeatureSettings()
  }, [])

  const featureSettings = useFeatureSettingSelector()
  const params = useParams()
  const [currentFeatureData, setCurrentFeatureData] = useState<any>({})
  
  // const [tenantFeatureId, setTenantFeatureId] = useState<any>(featureSettings?.id)
  const [currentTab, setCurrentTab] = useState('1')
  const id = params.id

  useEffect(() => {
    const featureSettingsData = featureSettings?.feature_settings
    // setTenantFeatureId(featureSettings?.id)
    const currentFeatureData = featureSettingsData?.find((feature: any) => feature.id === id)
    setCurrentFeatureData(currentFeatureData)
    if (currentFeatureData?.bot_id) {
      dispatchBotDetails(currentFeatureData?.bot_id)
    }
    dispatchUpdateSelectedTools([])
  }, [featureSettings])

  const TabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Setup Bot',
      children: <SetupBot />
    },
    {
      key: '2',
      label: 'Widgets/Tools',
      children: <></>
    },
    {
      key: '3',
      label: 'Bot Personality',
      children: <BotPersonality />
    },
    {
      key: '4',
      label: 'Installations',
      children: <></>
    },

  ];


  const doUpdateFields = (name: any, value: any) => {

    setCurrentFeatureData({
      ...currentFeatureData,
      [name]: value,
    })
  }

  const onTestAction = () => {
    dispatchClearBotDetails()
    dispatchClearChat()
    navigate(`/autosolvechatbot/${currentFeatureData?.bot_id}`)
  }

  const featureDetailsWhole = useFeatureSettingSelector()
  const changeFeatureStatus = async (e: any, value: any) => {
    const payload = {
      id: featureDetailsWhole?.id,
      feature_settings: {
        status: e ? 'active' : 'disabled',
        id: value?.id,
        updated_on: Date.now(),
      },
    }
    await dispatchUpdateFeatureSettingsStatus(payload)
    dispatchFeatureSettings()
  }

  return (
    <div
      style={{
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        overflow: 'auto',
        width: '100%',
        padding: "0px 0px"
      }}
    >
      <WizrFlexLayout justifyContent='space-between' flexDirection='row' flex={1.4}>
        <WizrUserJourney
          userJourney={[
            {
              title: (
                <WizrButton
                  padding='0px'
                  type='text'
                  text={
                    <WizrText type='h6' textColor='#707276'>
                      Cx Settings
                    </WizrText>
                  }
                  onClick={() => navigate(-1)}
                  icon={<Hamburger />}
                />
              ),
            },
            {
              title: (
                // <WizrButton
                //   padding='0px'
                //   type='text'
                //   text={
                //     <WizrText type='h6'>{`${currentFeatureData?.setting_name} - Settings`}</WizrText>
                //   }
                //   onClick={() => navigate(-1)}
                // />
                <StyledInputName type="text" value={currentFeatureData?.setting_name} onChange={(e: any) => { doUpdateFields('setting_name', e.target.value) }} />

              ),
              isCurrent: true,
            },
          ]}
        />
        <WizrFlexLayout
          textAlign='left'
          flexDirection='row'
          style={{ gap: '15px' }}
          justifyContent='flex-end'
          alignItems='center'
        >
          <WizrText type='body2' textColor='#707276'>
            {' '}
            Auto Solve Enabled
          </WizrText>
          <WizrToggleButton
            isEnabled={currentFeatureData?.status === 'active' ? true : false}
            onChange={(e: any) => {
              changeFeatureStatus(e, currentFeatureData)
            }}
          />
        </WizrFlexLayout>
      </WizrFlexLayout>
      <WizrFlexLayout
        flex={11}
        textAlign='left'
      >
        <WizrFlexLayout flexDirection='row' style={{ marginLeft: "7px" }}>
          <StyledTabs
            defaultActiveKey='1'
            onChange={(e: any) => { setCurrentTab(e); }}
            destroyInactiveTabPane={false}
          >
            {TabItems && TabItems.map((promptMenuItem: any) => (
              <TabPane
                tab={<WizrText type='sub2'>{promptMenuItem.label}</WizrText>}
                key={promptMenuItem.key}
              >
                {/* {promptMenuItem.children} */}
              </TabPane>
            ))}

          </StyledTabs>
          <WizrButton text={'Test'} style={{ borderRadius: '20px', marginRight: "7px" }} onClick={onTestAction}></WizrButton>
          </WizrFlexLayout>
        <WizrFlexLayout background='white ' style={{ borderRadius: "16px" }}>
          {currentTab === '1' ? <SetupBot /> : <></>}
          {currentTab === '2' ? <WidgetListing /> : <></>}
          {currentTab === '3' ? <BotPersonality /> : <></>}
          {currentTab === '4' ? <Installations currentFeatureData={currentFeatureData}/> : <></>}
        </WizrFlexLayout>
      </WizrFlexLayout>
    </div>
  )
}
export default AutoSolve
