const Agentassist = ({stroke="999B9F"}: any):JSX.Element=>{
    return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_11071_60905" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill={stroke}/>
        </mask>
        <g mask="url(#mask0_11071_60905)">
        <path d="M2 23V21H22V23H2ZM4 20V14C3.45 13.1 3.025 12.1458 2.725 11.1375C2.425 10.1292 2.275 9.1 2.275 8.05C2.275 7.03333 2.40417 6.03333 2.6625 5.05C2.92083 4.06667 3.225 3.1 3.575 2.15C3.70833 1.8 3.925 1.52083 4.225 1.3125C4.525 1.10417 4.85833 1 5.225 1C5.74167 1 6.18333 1.175 6.55 1.525C6.91667 1.875 7.06667 2.29167 7 2.775L6.725 5.05C6.625 5.85 6.69583 6.60833 6.9375 7.325C7.17917 8.04167 7.54167 8.67083 8.025 9.2125C8.50833 9.75417 9.09167 10.1875 9.775 10.5125C10.4583 10.8375 11.2 11 12 11C13 11 14.0042 11.1042 15.0125 11.3125C16.0208 11.5208 16.9 11.8167 17.65 12.2C18.4 12.5833 18.9792 13.0708 19.3875 13.6625C19.7958 14.2542 20 14.9833 20 15.85V20H4ZM6 18H18V15.85C18 15.45 17.9 15.0958 17.7 14.7875C17.5 14.4792 17.2167 14.2333 16.85 14.05C16.1667 13.7167 15.375 13.4583 14.475 13.275C13.575 13.0917 12.75 13 12 13C10.9 13 9.87917 12.775 8.9375 12.325C7.99583 11.875 7.19583 11.2708 6.5375 10.5125C5.87917 9.75417 5.38333 8.87917 5.05 7.8875C4.71667 6.89583 4.61667 5.86667 4.75 4.8C4.58333 5.3 4.4625 5.83333 4.3875 6.4C4.3125 6.96667 4.275 7.51667 4.275 8.05C4.275 9.01667 4.44583 9.94583 4.7875 10.8375C5.12917 11.7292 5.53333 12.6 6 13.45V18ZM12 10C10.9 10 9.95833 9.60833 9.175 8.825C8.39167 8.04167 8 7.1 8 6C8 4.9 8.39167 3.95833 9.175 3.175C9.95833 2.39167 10.9 2 12 2C13.1 2 14.0417 2.39167 14.825 3.175C15.6083 3.95833 16 4.9 16 6C16 7.1 15.6083 8.04167 14.825 8.825C14.0417 9.60833 13.1 10 12 10ZM12 8C12.55 8 13.0208 7.80417 13.4125 7.4125C13.8042 7.02083 14 6.55 14 6C14 5.45 13.8042 4.97917 13.4125 4.5875C13.0208 4.19583 12.55 4 12 4C11.45 4 10.9792 4.19583 10.5875 4.5875C10.1958 4.97917 10 5.45 10 6C10 6.55 10.1958 7.02083 10.5875 7.4125C10.9792 7.80417 11.45 8 12 8ZM8 20V19.075C8 17.9583 8.3875 17 9.1625 16.2C9.9375 15.4 10.8833 15 12 15H16V17H12C11.4333 17 10.9583 17.2042 10.575 17.6125C10.1917 18.0208 10 18.5083 10 19.075V20H8Z" fill={stroke}/>
        </g>
        </svg>)
}
export default Agentassist