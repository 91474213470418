import { Popover } from 'antd'
import WizrFlexLayout from '../WizrFlexLayout'
import WizrButton from '../WizrButton'
import MoreIcon from '../../assets/icons/More'
import EditIcon from '../../assets/icons/EditIcon'
import DeleteIcon from '../../assets/icons/DeleteIcon'
import { MODAL_TYPES, useWizrModalContext } from '../../providers/modal/WizrModalProvider'
import { dispatchDeleteRole, dispatchRolesList } from '../../redux/actions/content'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import ViewIcon from '../../assets/icons/ViewIcon'
import { useAuth } from '../../authContext'
const RolesActionPopoverContent = (rolesDetails: any): JSX.Element => {
  const { showModal, hideModal } = useWizrModalContext()
  const { userPermission, hasAcces } = useAuth();
  const navigate = useNavigate()
  const editRole = () => {
    //     showModal(MODAL_TYPES.UPDATE_ROLES,{
    //         roleDetails:rolesDetails?.rolesDetails,
    //       onCancel: hideModal,
    // })
    navigate('/userroles', { state: { role: rolesDetails?.rolesDetails, view: false } })
  }
  const deleteRole = async () => {
    hideModal()
    await dispatchDeleteRole(rolesDetails?.rolesDetails?.id)
    dispatchRolesList()
  }
  const onDeleteAction = async () => {
    showModal(MODAL_TYPES.CONFIRMATION, {
      text: `Are you sure you want to delete ${rolesDetails?.rolesDetails?.role_name}`,
      buttontext: 'Confirm',
      onConfirm: deleteRole,
      onCancel: hideModal,
    })
  }
  const onViewAction = () => {
    navigate('/userroles', { state: { role: rolesDetails?.rolesDetails, view: true } })
  }
  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      <WizrButton
        icon={<ViewIcon />}
        text='View'
        type='text'
        onClick={() => {
          onViewAction()
        }}
        justifyContent='flex-start'
        disabled={hasAcces('view_roles')  === true ? false : true}
      />
      <WizrButton
        icon={<EditIcon />}
        text='Edit'
        type='text'
        onClick={() => {
          editRole()
        }}
        justifyContent='flex-start'
        disabled={hasAcces('editroles_settings')  === true ? false : true}
      />

      {/* <WizrButton
        disabled={rolesDetails?.rolesDetails?.is_default}
          icon={<DeleteIcon />}
          text='Delete'
          type='text'
          onClick={()=>{onDeleteAction()}}
          justifyContent='flex-start'
        /> */}
    </WizrFlexLayout>
  )
}

const RolesActionPopover = (rolesDetails: any): JSX.Element => {
  return (
    <Popover
      content={<RolesActionPopoverContent rolesDetails={rolesDetails?.rolesDetails} />}
      trigger='click'
    >
      <StyledButton
        icon={<MoreIcon />}
        text=''
        type='text'
        style={{ height: '40px', width: '40px', borderRadius: '50px' }}
      />
    </Popover>
  )
}
export default RolesActionPopover

const StyledButton = styled(WizrButton)`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding-right: 8px;
  justify-content: center;
  align-items: center;
  /* Default styles */
  path {
    stroke: #999b9f; /* Default stroke color */
    transition: stroke 0.3s ease; /* Smooth transition for color change */
  }

  /* Hover styles */
  &:hover path {
    stroke: #1d1f22;
  }
`
