import WizrFlexLayout from "../components/WizrFlexLayout";
import WizrText from "../components/WizrText";

const NewFeatureScreen = () => {  

    const figmaUrl = "https://www.figma.com/proto/jkgWaqhUGjFI1WuRxjPT2Z/Wizr?type=design&node-id=5321-28983&t=IGViH86cpdaXZzpz-9&scaling=scale-down-width&page-id=412%3A87&starting-point-node-id=5321%3A28983&hide-ui=1";

    const redirectToFigma = () => {
        window.open(figmaUrl, '_blank')
    }

    return (    
        <WizrFlexLayout justifyContent="center">
            <WizrText type='h4' > 
                <div onClick={redirectToFigma}>
                    Feature Coming Soon.......
                </div>
            </WizrText>
        </WizrFlexLayout>
    )
  }
export default NewFeatureScreen;
