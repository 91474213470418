const CodeIcon = (props: any) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={17} fill='none' {...props}>
    <path
      stroke='#999B9F'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='m11.691 8.491-3.5 2.333v4.667l7-4.667V6.158l-7-4.667v4.667l3.5 2.333Z'
      clipRule='evenodd'
    />
    <path
      fill='#999B9F'
      d='M1.607 5.534a.75.75 0 1 0-.832 1.248l.832-1.248Zm6.168 5.914a.75.75 0 1 0 .832-1.248l-.832 1.248Zm-5.834-5.29a.75.75 0 0 0-1.5 0h1.5Zm-1.5 4.666a.75.75 0 0 0 1.5 0h-1.5Zm.334-5.29a.75.75 0 1 0 .832 1.248L.775 5.534Zm7.832-3.419A.75.75 0 1 0 7.775.867l.832 1.248Zm0 4.667a.75.75 0 0 0-.832-1.248l.832 1.248ZM.775 10.2a.75.75 0 0 0 .832 1.248L.775 10.2Zm.832 0a.75.75 0 1 0-.832 1.248l.832-1.248Zm6.168 5.915a.75.75 0 1 0 .832-1.248l-.832 1.248Zm7.832-9.333a.75.75 0 1 0-.832-1.248l.832 1.248Zm-3.916 1.71-.416-.625a.75.75 0 0 0 0 1.248l.416-.624Zm3.084 2.956a.75.75 0 0 0 .832-1.248l-.832 1.248Zm-14-4.666 7 4.666.832-1.248-7-4.666-.832 1.248Zm-.334-.624v4.666h1.5V6.158h-1.5Zm1.166.624 7-4.667L7.775.867l-7 4.667.832 1.248Zm6.168-1.248-7 4.666.832 1.248 7-4.666-.832-1.248Zm-7 5.914 7 4.667.832-1.248-7-4.667-.832 1.248Zm14-5.914-3.5 2.333.832 1.248 3.5-2.333-.832-1.248Zm-3.5 3.581 3.5 2.333.832-1.248-3.5-2.333-.832 1.248Z'
    />
  </svg>
)
export default CodeIcon
