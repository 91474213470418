import { Button, Input, Radio } from "antd"
import WizrFlexLayout from "../../../components/WizrFlexLayout"
import { useState } from "react";
import WizrText from "../../../components/WizrText";
import WizrTable from "../../../components/WizrTable";
import styled from "styled-components";
import Search from "../../../assets/icons/Search";
import SearchSetting from "../../../assets/icons/SearchSetting";

const StyledWizrTable = styled(WizrTable)`
  .ant-table-wrapper {
    width: 100% !important;
  }
  .ant-table-thead {
    background-color: #f2f4f9;
    border-spacing: 0px;
  }
  .ant-table-tbody > tr {
    background-color: #ffffff;
    margin-bottom: 0px;
  }

  .ant-table table {
    border-spacing: 0 5px;
  }
`
const SearchInput = styled(Input)`
  border-radius: 30px;
  border: 0px solid var(--surface-overlay, #f9f9f9);
  background: #fff;

`
const Billing = (): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState("usage")
  const getButtonStyle = (value: any) => {
    if (value === selectedTab) {
      return {
        backgroundColor: '#1D1F22',
        borderColor: '#1D1F22',
        color: '#fff',
      };
    }
    return {};
  };
  const onTabChange = (e: any) => {
    setSelectedTab(e?.target?.value)

  }

  const data = [{
    date: 1706610300,
    company: "google",
    app: "auto solve",
    model: "GPT 4",
    input: 1000,
    output: 1000,
    request: 1000,
    cost: 100,
    price: 100

  },
  {
    date: 1706610300,
    company: "amazon",
    app: "title generator",
    model: "GPT 4",
    input: 2000,
    output: 2000,
    request: 2000,
    cost: 200,
    price: 200

  }]
  const invoiceData = [{
    date: 1706610300,
    company: "google",
    app: "auto solve",
    invoice: "A122644",
    recepient: "GPT 4",
    status: 1000,
    amount: 1000,
    paid: 1706610300,

  },
  {
    date: 1706610300,
    company: "amazon",
    app: "title generator",
    invoice: "A122644",
    recepient: "GPT 4",
    status: 2000,
    amount: 2000,
    paid: 1706610300,

  }]
  return (
    <WizrFlexLayout style={{ padding: "20px" }}>
      <WizrFlexLayout flexDirection="row" flex={1}>
        <WizrFlexLayout flex={2} alignItems="flex-start">
          <Radio.Group onChange={onTabChange} value={selectedTab} style={{ marginBottom: 16 }}>
            <Radio.Button value="usage" style={getButtonStyle('usage')}>
              Usage
            </Radio.Button>
            <Radio.Button value="invoice" style={getButtonStyle('invoice')}>
              Invoice
            </Radio.Button>
          </Radio.Group></WizrFlexLayout>
        <WizrFlexLayout flex={1} alignItems="flex-end">
          {selectedTab === "usage" ? <WizrText type="h6" textColor="#22C55E">Total:$1000</WizrText> : <SearchInput
            placeholder='Search'
            prefix={<Search />}
          />}</WizrFlexLayout>
      </WizrFlexLayout>
      <WizrFlexLayout flex={11}>
        {selectedTab === "usage" ?
          <StyledWizrTable
            type="billing"
            dataSource={data}
            scroll={{ y: 500 }}
            pagination={false}
          /> : <StyledWizrTable
            type="invoice"
            dataSource={invoiceData}
            scroll={{ y: 500 }}
            pagination={false}
          />}
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

export default Billing