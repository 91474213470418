const ViewIcon = () => {
    return(
        <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 5.5C15.5 7.985 12.366 10 8.5 10C4.634 10 1.5 7.985 1.5 5.5C1.5 3.015 4.634 1 8.5 1C12.366 1 15.5 3.015 15.5 5.5Z" stroke="#1D1F22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.25 5.49962C10.2716 6.13895 9.9429 6.73918 9.39256 7.06524C8.84221 7.39131 8.15785 7.39131 7.6075 7.06524C7.05715 6.73918 6.72841 6.13895 6.75003 5.49962C6.72841 4.8603 7.05715 4.26007 7.6075 3.934C8.15785 3.60794 8.84221 3.60794 9.39256 3.934C9.9429 4.26007 10.2716 4.8603 10.25 5.49962V5.49962Z" stroke="#1D1F22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        

    )
}
export default ViewIcon