const OutlookIcon = ()=>{
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6133 5.95312V10.0609L16.0503 10.9646C16.1022 10.976 16.1559 10.976 16.2078 10.9646L22.3863 6.79912C22.3818 6.59696 22.3078 6.40251 22.1768 6.24845C22.0459 6.09439 21.8658 5.99011 21.667 5.95312H14.6133Z" fill="#0072C6"/>
<path d="M14.6127 11.5944L15.9229 12.4944C15.9843 12.5318 16.0547 12.5516 16.1265 12.5516C16.1984 12.5516 16.2688 12.5318 16.3302 12.4944C16.1052 12.6302 22.3849 8.46094 22.3849 8.46094V16.0104C22.4013 16.1634 22.3841 16.3181 22.3344 16.4637C22.2847 16.6094 22.2038 16.7424 22.0974 16.8534C21.9909 16.9645 21.8615 17.051 21.7182 17.1069C21.5748 17.1627 21.421 17.1865 21.2674 17.1767H14.6119L14.6127 11.5944ZM7.82967 9.70069C7.61197 9.69964 7.39804 9.7575 7.21055 9.86815C7.02306 9.9788 6.86899 10.1381 6.76467 10.3292C6.47976 10.8357 6.34305 11.4122 6.37017 11.9927C6.34056 12.572 6.47746 13.1477 6.76467 13.6517C6.86855 13.8349 7.01883 13.9876 7.20039 14.0943C7.38196 14.201 7.58842 14.2581 7.79903 14.2598C8.00965 14.2615 8.21699 14.2077 8.40023 14.1038C8.58347 14 8.73615 13.8497 8.84292 13.6682C9.12701 13.1665 9.26107 12.5938 9.22917 12.0182C9.2617 11.4245 9.13207 10.8331 8.85417 10.3074C8.75486 10.1226 8.60694 9.96837 8.42639 9.86144C8.24584 9.75451 8.03951 9.69892 7.82967 9.70069Z" fill="#0072C6"/>
<path d="M1.61523 3.86625V19.9365L13.8402 22.5V1.5L1.61523 3.86625ZM9.79623 14.6182C9.56755 14.9406 9.26347 15.2022 8.91051 15.3801C8.55755 15.558 8.16642 15.6469 7.77123 15.639C7.38599 15.6458 7.0048 15.5595 6.66007 15.3874C6.31534 15.2153 6.01728 14.9625 5.79123 14.6505C5.25512 13.902 4.98694 12.9947 5.02998 12.075C4.98438 11.1102 5.25741 10.1572 5.80698 9.363C6.03856 9.03445 6.34741 8.76791 6.70631 8.5869C7.06521 8.40588 7.46312 8.31594 7.86498 8.325C8.24743 8.31733 8.62592 8.40365 8.96722 8.57639C9.30852 8.74913 9.6022 9.00302 9.82248 9.31575C10.3532 10.0805 10.6169 10.9989 10.5725 11.9288C10.6192 12.8865 10.3462 13.8327 9.79623 14.6182Z" fill="#0072C6"/>
</svg>

    )
}

export default OutlookIcon;