import { useParams, useNavigate } from 'react-router-dom'
import {
  dispatchListFiles,
  dispatchDeleteFolder,
  dispatchUpdateFolder,
  dispatchTags,
} from '../redux/actions/content'
import { useEffect, useState } from 'react'
import { MODAL_TYPES, useWizrModalContext } from '../providers/modal/WizrModalProvider'
import FolderCreate from '../assets/icons/FolderCreate'
import {
  useContentFilesSelector,
  useContentLoadingSelector,
  useCurrentFolderSelector,
} from '../redux/selectors/content'
import WizrTable from '../components/WizrTable'
import { Spin, Popover } from 'antd'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrUploadContainer from '../components/WizrUploadButton'
import WizrUserJourney from '../components/WizrUserJourney'
import UploadActionPopOver from '../components/popovers/UploadAction'
import { dispatchNotificationState } from '../redux/actions/common'
import WizrButton from '../components/WizrButton'
import EditIcon from '../assets/icons/EditIcon'
import DeleteIcon from '../assets/icons/DeleteIcon'
import WizrText from '../components/WizrText'

const UserJourneyPopoverAction = ({ folderDetail }: any): JSX.Element => {
  const { showModal, hideModal } = useWizrModalContext()
  const navigate = useNavigate()
  const onEdit = () =>
    showModal(MODAL_TYPES.CREATE_UPDATE_FOLDER, {
      folderName: folderDetail?.folder_name,
      folderDescription: folderDetail?.folder_description,
      onFormSubmission: ({ currentFolderName, currentFolderDescription }: any) => {
        const isExistingName = false
        const type = 'knowledge_base'
        const payload = {
          ...folderDetail,
          folder_name: currentFolderName,
          folder_description: currentFolderDescription,
        }
        if (!isExistingName) {
          hideModal()
          dispatchUpdateFolder({payload,type})
          navigate(-1)
        } else {
          dispatchNotificationState({
            message: 'Error',
            description: `${currentFolderName} already exists. Please try different name.`,
            type: null,
          })
        }
      },
      onCancel: hideModal,
    })

  const onDelete = () =>
    showModal(MODAL_TYPES.DELETE_FOLDER, {
      folderSize:folderDetail?.file_count,
      folderName: folderDetail?.folder_name,
      onDeleteConfirm: () => {
        hideModal()
        const type = 'knowledge_base'
        const folderId = folderDetail?.folder_id
        dispatchDeleteFolder({folderId,type})
        navigate(-1)
      },
      onCancel: hideModal,
    })

  return (
    <Popover
      content={
        <WizrFlexLayout textAlign='left' background='#FFF'>
          <WizrButton
            icon={<EditIcon />}
            text='Edit'
            type='text'
            onClick={onEdit}
            justifyContent='flex-start'
          />
          <WizrButton
            icon={<DeleteIcon />}
            text='Delete'
            type='text'
            onClick={onDelete}
            justifyContent='flex-start'
          />
        </WizrFlexLayout>
      }
      title=''
      trigger='click'
    >
      <WizrButton
        padding='0px'
        text={<WizrText type='h6'>{folderDetail?.folder_name}</WizrText>}
        type='text'
      />
    </Popover>
  )
}

const Files = (): JSX.Element => {
  const params = useParams()
  const filesData = useContentFilesSelector()
  const isContentLoading = useContentLoadingSelector()
  const currentFolder = useCurrentFolderSelector()
  const navigate = useNavigate()
  const { showModal, hideModal } = useWizrModalContext()

  useEffect(() => {
    params?.id && dispatchListFiles(params.id)
    localStorage.setItem('folderId', params.id || '')
  }, [params?.id])
  useEffect(() => {
    dispatchTags()
    
  }, [])

  const showMultiFileUpload = (fileInfo: any) => {
    showModal(MODAL_TYPES.ADD_TAGS, {
      fileDetail: fileInfo,
      onSubmit: (tagValues: any) => {
        showModal(MODAL_TYPES.MULTI_FILE_UPLOAD, {
          filesList: fileInfo?.fileList,
          folderId: params?.id,
          fileTags: tagValues,
          onDone: () => {
            hideModal()
            params?.id && dispatchListFiles(params.id)
          },
          onCancel: hideModal,
        })
      },
    })
  }

  return (
    <WizrFlexLayout>
      <WizrFlexLayout justifyContent='space-between' alignItems='center' flexDirection='row'>
        <WizrUserJourney
          userJourney={[
            {
              title: (
                <WizrButton
                  padding='0px'
                  type='text'
                  text={<WizrText type='h6'>Folders</WizrText>}
                  onClick={() => navigate(-1)}
                />
              ),
            },
            {
              title: <UserJourneyPopoverAction folderDetail={currentFolder} />,
              isCurrent: true,
            },
          ]}
        />
        <UploadActionPopOver detail={currentFolder} />
      </WizrFlexLayout>
      <WizrFlexLayout flex={10}>
        {isContentLoading ? (
          <Spin />
        ) : !filesData?.length ? (
          <WizrUploadContainer dragDropEnabled={true} onChange={showMultiFileUpload}>
            <FolderCreate />
          </WizrUploadContainer>
        ) : (
          <WizrTable type='file' dataSource={filesData} pagination={false} size='small' />
        )}
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

export default Files
