import { Layout, LayoutProps } from 'antd'
import React from 'react'
import { styled } from 'styled-components'

interface IStyledLayout {
  justifyContent?: string
  alignItems?: string
  alignContent?: string
  flexDirection?: string
  background?: string
  textAlign?: string
  flex?: number | string
  alignSelf?: string
  overFlow?: string
}

interface IWizrFlexLayout extends LayoutProps, IStyledLayout {
  children: JSX.Element | JSX.Element[]
}

const StyledLayout = styled(Layout)<IStyledLayout>`
  flex: ${(p) => (p.flex ? p.flex : 1)};
  justify-content: ${(p) => (p.justifyContent ? p.justifyContent : 'space-between')};
  align-content: ${(p) => (p.alignContent ? p.alignContent : 'center')};
  align-items: ${(p) => (p.alignItems ? p.alignItems : 'space-between')};
  flex-direction: ${(p) => (p.flexDirection ? p.flexDirection : 'column')};
  background: ${(p) => (p.background ? p.background : '#F2F4F9')};
  text-align: ${(p) => (p.textAlign ? p.textAlign : 'center')};
  align-self: ${(p) => (p.alignSelf ? p.alignSelf : 'stretch')};
  width: 100%;
  overflow: ${(p) => (p.overFlow ? p.overFlow : 'auto')};
`

const WizrFlexLayout = ({ children, ...props }: IWizrFlexLayout): JSX.Element => {
  return <StyledLayout {...props}>{children}</StyledLayout>
}

export default WizrFlexLayout
