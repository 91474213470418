import { Popover } from 'antd'
import WizrButton from '../WizrButton'
import MoreIcon from '../../assets/icons/More'
import EditIcon from '../../assets/icons/EditIcon'
import CircleCancel from '../../assets/icons/CircleCancel'
import { dispatchPublishPrompt, dispatchUnpublishPrompt } from '../../redux/actions/content'
import Check from '../../assets/icons/Check'
import Copy from '../../assets/icons/Copy'
import { useNavigate } from 'react-router-dom'
import WizrFlexLayout from '../../stories/WizrFlexLayout/WizrFlexLayout'
import { useAuth } from '../../authContext'
import styled from 'styled-components'

interface IPromptActionPopOver {
  detail?: any
  tabSelected: boolean
}

interface IPromptActionPopOverContent {
  onEditAction: () => void
  onCopyAction: () => void
  isSelected: boolean
  onPromptPublishOrUnpublish: () => void
}

const PromptActionPopOverContent = ({
  onEditAction,
  onCopyAction,
  isSelected,
  onPromptPublishOrUnpublish,
}: IPromptActionPopOverContent): JSX.Element => {  
  const { userPermission, hasAcces } = useAuth();

  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      <WizrButton
        icon={<EditIcon />}
        text='Edit'
        type='text'
        onClick={onEditAction}
        disabled={isSelected === true ? hasAcces('editpublished_prompt') === true ? false : true : hasAcces('editdraft_prompt') === true ? false : true}
        justifyContent='flex-start'
      />
      <WizrButton
        icon={<Copy />}
        text='Clone'
        type='text'
        onClick={onCopyAction}
        disabled={isSelected === true ? hasAcces('copypublished_prompt') === true ? false : true : hasAcces('copydraft_prompt') === true ? false : true}
        justifyContent='flex-start'
      />
      <WizrButton
        icon={isSelected ? <CircleCancel /> : <Check />}
        text={isSelected ? 'Unpublish' : 'Publish'}
        type='text'
        onClick={onPromptPublishOrUnpublish}
        disabled={isSelected === true ? hasAcces('unpublish_prompt') === true ? false : true : hasAcces('publish_prompt') === true ? false : true}
        justifyContent='flex-start'
      />
    </WizrFlexLayout>
  )
}

const PromptActionPopOver = ({ detail, tabSelected }: IPromptActionPopOver): JSX.Element => {

  const { userPermission, hasAcces } = useAuth();
  
  const navigate = useNavigate()
  detail.type = 'prompt'

  const goToCreatePrompt = (action: string) => {
    //detail.action = action
    localStorage.setItem('prompt_id', detail.id)
    localStorage.setItem('action', action)
    navigate('/prompt-view', { state: {...detail,testHistory:false }})
  }

  const onCopy = () => {
    goToCreatePrompt('copy')
  }

  const onEdit = () => {
    goToCreatePrompt('edit')
  }

  const onPromptPublishOrUnpublish = () =>
    tabSelected ? dispatchUnpublishPrompt(detail.id) : dispatchPublishPrompt(detail.id)
  

  return (
    <Popover
      content={
        <PromptActionPopOverContent
          onCopyAction={onCopy}
          onEditAction={onEdit}
          isSelected={tabSelected}
          onPromptPublishOrUnpublish={onPromptPublishOrUnpublish}
        />
      }
      trigger='click'
    >
      <StyledButton icon={<MoreIcon />} text='' type='text' style={{height: '40px', width: '40px', borderRadius: '50px'}}/>
    </Popover>
  )
}

export default PromptActionPopOver

const StyledButton = styled(WizrButton)`
border-radius: 50%;
width: 20px;
height: 20px;
padding-right: 8px;
justify-content: center;
align-items: center;
  /* Default styles */
  path {
    stroke: #999B9F; /* Default stroke color */
    transition: stroke 0.3s ease; /* Smooth transition for color change */
  }

  /* Hover styles */
  &:hover path {
    stroke: #1D1F22
  }
`;
