import { Form } from 'antd'
import { useEffect, useState } from 'react'
import WizrInput from '../WizrInput'
import WizrTextArea from '../WizrTextArea'
import WizrFlexSpacing from '../WizrFlexSpacing'
import WizrButton from '../WizrButton'
import WizrFlexLayout from '../WizrFlexLayout'
import { styled } from 'styled-components'
import WizrDropDown from '../WizrDropDown'
import { dispatchEmbeddingModelList } from '../../redux/actions/content'
import { useEmbeddingModelListSelector } from '../../redux/selectors/content'

const StyledContainer = styled(WizrFlexLayout)`
  gap: 8px;
`
const StyledDropDown = styled(WizrDropDown)`
  height: 50px;
  .ant-select-selector {
    height: 50px !important;
    .ant-select-selection-placeholder {
      margin-top: 10px;
    }
  }
`
interface ICreateUpdateFolderForm {
  folderName?: string
  folderDescription?: string
  embeddingModel?: string
  onFormSubmission: (params: { [key: string]: any }) => void
  onCancel: () => void
}

const CreateUpdateFolderForm = ({
  folderName,
  folderDescription,
  embeddingModel,
  onFormSubmission,
  onCancel,
}: ICreateUpdateFolderForm): JSX.Element => {
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldValue('currentFolderName', folderName)
    form.setFieldValue('currentFolderDescription', folderDescription)
    form.setFieldValue('currentembeddingModel', embeddingModel)
  }, [folderName, folderDescription,embeddingModel])

  useEffect(() => {
    dispatchEmbeddingModelList()
    },[])

    const embeddingModelData = useEmbeddingModelListSelector()

  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item.display_name,
        value: item.id,
      }))
    return result
  }

  return (
    <StyledContainer background='#FFF' justifyContent='space-evenly'>
      <Form
        name='basic'
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFormSubmission}
        onFinishFailed={() => null}
        autoComplete='off'
      >
        <Form.Item
          name='currentFolderName'
          rules={[{ required: true, message: 'Please Enter a Folder Name', whitespace: true }]}
        >
          <WizrInput
            placeholder='Enter Folder Name'
            onChange={(value: string) => form.setFieldsValue({ currentFolderName: value })}
          />
        </Form.Item>
        <Form.Item name='currentFolderDescription' rules={[{ required: false, whitespace: true }]}>
          <WizrTextArea
            border='1px solid var(--grayscale-300, #DCDEE2)'
            placeholder='Description'
            onChange={(value: string) => form.setFieldsValue({ currentFolderDescription: value })}
          />
        </Form.Item>
       {!folderName? <Form.Item
          name='currentembeddingModel'
          rules={[{ required: true, message: 'Please Enter a Folder Name', whitespace: true }]}
        >
           <StyledDropDown
              value={embeddingModel}
              options={dropDownConverter(embeddingModelData)}
              onChange={(value: string) => { form.setFieldsValue({ currentembeddingModel: value })}}
            />
        </Form.Item>:<></>}
        <Form.Item>
          <WizrFlexSpacing
            justifyContent='flex-end'
            flexDirection='row'
            size='large'
            background='#FFF'
          >
            <WizrButton type='text' text='Cancel' onClick={onCancel} style={{borderRadius: '20px'}}/>
            <WizrButton htmlType='submit' text={folderName ? 'Update' : 'Create'} style={{borderRadius: '20px'}}/>
          </WizrFlexSpacing>
        </Form.Item>
      </Form>
    </StyledContainer>
  )
}

export default CreateUpdateFolderForm
