import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import WizrText from './components/WizrText';
import WizrIcon from './components/WizrIcon';
import AIIcon from './assets/icons/AIIcon';
import EndUser from './assets/icons/EndUser';
import { NavLink, useParams } from 'react-router-dom';
import { useFeatureSettingSelector, useGetTenantDetails, usePermissionValues } from './redux/selectors/content'
import { dispatchFeatureSettings, dispatchSetPermissionValues } from './redux/actions/content';
import CXHubIcon from './assets/icons/CXHubIcon';

interface AuthContextType {
    userPermission: string | null;
    hasAcces: (role: string) => any;
    filteredMenuItems: any[];
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};


export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {

    const [userPermission, setUserPermission] = useState<string | null>(null);
    // const permissionValues = ['view_folder', 'delete_folder1', 'view_home', 'view_prompt', 'view_app-designer', 'view_settings', 'view_euserapplisting', 'editdraft_prompt', 'editdraft_app', 'history_app', 'create_app', 'viewrunhistory_app']
    const permissionValues: any = usePermissionValues();
    
    useEffect(() => {
        const appshare = location?.pathname?.includes('/appshare')
        const login = location?.pathname?.includes('/login') || location?.pathname?.includes('/')
        const extension = location?.pathname?.includes('/extension')
        const errorPage = location?.pathname?.includes('./error')

        !appshare && !extension && !login && !errorPage && dispatchFeatureSettings()
    }, [])
    const settings = useFeatureSettingSelector()
    const featureSettings = settings?.feature_settings
    const autoTag = featureSettings?.filter((item: any) => item?.setting_type === 'autotag')
    const agentassist = featureSettings?.filter((item: any) => item?.setting_type === 'agentassist')
    
    const autosolve = featureSettings?.filter((item: any) => item?.setting_type === 'autosolve')
    const triage = featureSettings?.filter((item: any) => item?.setting_type === 'triage')
    const tenantDetails = useGetTenantDetails()
    const topMenuItems = [
        {
            key: 'home',
            label: <WizrText type='body2'>Home</WizrText>,
            icon: <WizrIcon name='home' />,
            navigateTo: '/dashboard',
        },
        {
            key: 'cxhub',
            label: <WizrText type='body2'>CX Hub</WizrText>,
            icon: <CXHubIcon />,
            children: [
                {
                    key: 'cxcontrol',
                    label: <WizrText type='body2'>Control Room</WizrText>,
                    navigateTo: '/cxcontrol',
                },
                {
                    key: 'cxsettings/agentassist',
                    label: <WizrText type='body2'>Agent Assist</WizrText>,
                    navigateTo: 'cxsettings/agentassist',
                },
                // {
                //     key: autosolve && autosolve?.length>1?'cxsettings/autosolve':`/autosolve/${autosolve && autosolve[0]?.id}`,
                //     label: <WizrText type='body2'>Auto Solve</WizrText>,
                //     navigateTo: autosolve && autosolve?.length>1?'cxsettings/autosolve':`/autosolve/${autosolve &&autosolve[0]?.id}`,
                // },
                {
                    key: 'cxsettings/autosolve',
                    label: <WizrText type='body2'>Auto Solve</WizrText>,
                    navigateTo: 'cxsettings/autosolve',
                },
                // {
                //     key: 'cxsettings/triage',
                //     label: <WizrText type='body2'>Triage</WizrText>,
                //     navigateTo: 'cxsettings/triage',
                // },
                {
                    key: 'cxsettings/autotag',
                    label: <WizrText type='body2'>Auto Tag</WizrText>,
                    navigateTo: 'cxsettings/autotag',
                },
                {
                    key: 'cxsettings',
                    label: <WizrText type='body2'>CX Settings</WizrText>,
                    navigateTo: '/cxsettings',
                },
                {
                    key: 'customerInteractions',
                    label: <WizrText type='body2'>Customer Interactions</WizrText>,
                    navigateTo: '/customerInteractions',
                },
                // {
                //     key: autoTag && autoTag?.length>1?'cxsettings/autotag':`/autotag/${autoTag && autoTag[0]?.id}`,
                //     label: <WizrText type='body2'>Auto Tag</WizrText>,
                //     navigateTo: autoTag && autoTag?.length>1?'cxsettings/autotag':`/autotag/${autoTag &&autoTag[0]?.id}`,
                // },
            ],
        },

        {
            key: 'folder',
            label: <WizrText type='body2'>Content</WizrText>,
            icon: <WizrIcon name='notes' />,
            children: [
                {
                    key: 'folder',
                    label: <WizrText type='body2'>Knowledge Base</WizrText>,
                    navigateTo: '/folder',
                },
                {
                    key: 'past_tickets',
                    label: <WizrText type='body2'>Past Tickets</WizrText>,
                    navigateTo: '/past_tickets',
                },
                {
                    key: 'faq',
                    label: <WizrText type='body2'>FAQ</WizrText>,
                    navigateTo: '/folder',
                },
            ],
            // navigateTo: '/folder',
        },
        {
            key: 'prompt',
            label: <WizrText type='body2'>AI Studio</WizrText>,
            icon: <AIIcon stroke='#999B9F' />,
            children: [
                {
                    key: 'prompt',
                    label: <WizrText type='body2'>Prompts</WizrText>,
                    navigateTo: '/prompt',
                },
                {
                    key: 'app-designer',
                    label: <WizrText type='body2'>App Designer</WizrText>,
                    navigateTo: '/app-designer',
                },
            ],
        },
        {
            key: 'settings',
            label: <WizrText type='body2'>Settings</WizrText>,
            icon: <WizrIcon name='settings' />,
            navigateTo: '/settings',
        },
        ...(tenantDetails?.master_tenant ? [
            {
                key: 'euserapplisting',
                label: <WizrText type='body2'>End User</WizrText>,
                icon: <EndUser />,
                children: [
                    {
                        key: 'euserapplisting',
                        label: <WizrText type='body2'>Content Generation</WizrText>,
                        navigateTo: '/euserapplisting',
                    },
                    {
                        key: 'chatbotlisting',
                        label: <WizrText type='body2'>Chat Bot</WizrText>,
                        navigateTo: '/chatbotlisting',
                    },
                ],
                navigateTo: '/euserapplisting',
            },] : []),
    ]

    const hasAcces = (permission: string) => {
        return permissionValues.includes(permission)
    };

    const filteredMenuItems = topMenuItems.filter(item => {
        if (item.key) {
            const permissionKey = `view_${item.key}`;
            return permissionValues.includes(permissionKey);
        }

        if (item.children) {
            item.children = item.children.filter(child => {
                if (child.key) {
                    const permissionKey = `view_${child.key}`;
                    return permissionValues.includes(permissionKey);
                }
                return true; // Include items without a key
            });

            return item.children.length > 0;
        }

        return true; // Include items without a key
    });

    useEffect(() => {
        if (localStorage.getItem('id_token')) {
            const idToken: any = localStorage.getItem('id_token');
            dispatchSetPermissionValues(idToken)
        }

    }, [])

    return (
        <AuthContext.Provider value={{ userPermission, hasAcces, filteredMenuItems }}>
            {children}
        </AuthContext.Provider>
    );
};
