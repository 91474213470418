const Autotag = ({stroke='#999B9F'}:any):JSX.Element=>{
    return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_11071_36024" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill={stroke}/>
        </mask>
        <g mask="url(#mask0_11071_36024)">
        <path d="M10.95 14L15.9 9.05L14.475 7.65L10.95 11.175L9.525 9.75L8.1 11.175L10.95 14ZM5 21V5C5 4.45 5.19583 3.97917 5.5875 3.5875C5.97917 3.19583 6.45 3 7 3H17C17.55 3 18.0208 3.19583 18.4125 3.5875C18.8042 3.97917 19 4.45 19 5V21L12 18L5 21ZM7 17.95L12 15.8L17 17.95V5H7V17.95Z" fill={stroke}/>
        </g>
        </svg>)  
}
export default Autotag