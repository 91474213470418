import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrText from '../../../components/WizrText'
import { Button, Card, Dropdown, Input, Select, Space, Tooltip } from 'antd'
import SearchSetting from '../../../assets/icons/SearchSetting'
import styled from 'styled-components'
import Search from '../../../assets/icons/Search'
import WizrTable from '../../../components/WizrTable'
import { healthTable } from '../../../utils'
import { useCustomerHealthSelector, useTopIntentsSelector } from '../../../redux/selectors/content'
import { useEffect, useMemo, useState } from 'react'
import { dispatchCustomerHealthDetails } from '../../../redux/actions/content'
import WizrButton from '../../../components/WizrButton'
import { ArrowLeftOutlined, DownOutlined } from '@ant-design/icons'
import HealthCardIcon from '../../../assets/icons/HealthCardIcon'
import Bars from '../../../assets/icons/Bars'
import WizrTag from '../../../components/WizrTags'
import { CartesianGrid, Cell, Legend, Line, LineChart, Pie, PieChart, XAxis, YAxis } from 'recharts'
import GaugeChart from '../charts/GaugeChart'
import WizrIcon from '../../../components/WizrIcon'
import LineChartComponent from '../charts/Linechart'

const SearchInput = styled(Input)`
  border-radius: 30px;
  border: 0px solid var(--surface-overlay, #f9f9f9);
  background: #fff;
  width: 300px;
  height: 40px;
`
const StyledWizrTable = styled(WizrTable)`
  .ant-table-wrapper {
    width: 100% !important;
  }
  .ant-table-thead {
    background-color: #f2f4f9;
    border-spacing: 0px;
  }
  .ant-table-tbody > tr {
    background-color: #ffffff;
    margin-bottom: 0px;
  }

  .ant-table table {
    border-spacing: 0 5px;
  }
  .ant-table-tbody > tr:hover {
    cursor: pointer !important;
  }
`

const StyledHeaderWizrButton = styled(WizrButton)`
  border-radius: 20px;
  background: var(--grayscale-200, #eaecf1);
  width: 100px;
`
const StyledTicketCard = styled(Card)<any>`
  background-color: #ffffff;
  width: 100%;
  .ant-card-meta-detail {
    border-bottom: none;
    border-top: none;
  }
  .ant-card-head {
    border-bottom: none;
    margin-bottom: 4px;
    text-align: start;
  }
  .ant-card-body {
    text-align: center;
  }
`


const StyledWizrTagInput = styled(Select)`
  /* Add your custom styles here */
  .ant-select-selection-placeholder {
    text-align: left;
  }
  width: 30%;
  .ant-select-selector {
    min-height: 50px;
    border: 0px solid black !important;
  }
  .ant-select-selection-item {
    background: #eaecf1 !important;
    border-radius: 20px !important;
    gap: 8px !important;
    padding-right: 8px !important;
  }
  .ant-select-selection-overflow {
    gap: 8px !important;
  }
  .ant-select-selection-item-remove {
    color: black !important;
  }
  .ant-select-arrow {
    color: black !important;
  }
  overflow: auto; /* Hide any overflow content */
`
const CardComponent = ({ details }: any): JSX.Element => {
  const getSentimentColor = (text: any) => {
    if (text >= 75) {
      return '#22C55E' // Green color for text >= 75
    } else if (text >= 50) {
      return '#F59E0B' // Yellow color for text >= 50 and < 75
    } else {
      return '#EF4444' // Red color for text < 50
    }
  }
  const getSentimentText = (text: any) => {
    if (text >= 75) {
      return ['Good'] // Green color for text >= 75
    } else if (text >= 50) {
      return ['Fair'] // Yellow color for text >= 50 and < 75
    } else {
      return ['Bad'] // Red color for text < 50
    }
  }

  const getTimeResolutinColour = (text: any) => {
    if (text >= 36) {
      return '#EF4444' // Red color for text < 50
    } else if (text >= 8) {
      return '#F59E0B' // Yellow color for text >= 50 and < 75
    } else {
      return '#22C55E' // Green color for text >= 75
    }
  }
  const getTimeResolutionContent = (text: any) => {
    if (text >= 36) {
      return ['Bad'] // Red color for text < 50
    } else if (text >= 8) {
      return ['Fair'] // Yellow color for text >= 50 and < 75
    } else {
      return ['Good'] // Green color for text >= 75
    }
  }

  const getEscalationColor = (text: number): string => {
    if (text >= 20) {
      return '#EF4444' // Red color for text >= 20 (bad escalation)
    } else if (text >= 5) {
      return '#FBBF24' // Yellow color for text >= 5 and < 20 (fair escalation)
    } else {
      return '#22C55E' // Green color for text < 5 (good escalation)
    }
  }

  const getEscalationText = (text: any) => {
    if (text >= 20) {
      return ['Bad'] // Red color for text >= 20 (bad escalation)
    } else if (text >= 5) {
      return ['Fair'] // Yellow color for text >= 5 and < 20 (fair escalation)
    } else {
      return ['Good'] // Green color for text < 5 (good escalation)
    }
  }

  return (
    <WizrFlexLayout
      justifyContent='space-between'
      alignItems='stretch'
      style={{
        display: 'flex',
        // flex: '1',
        flexDirection: 'row',
        textAlign: 'left',
        justifyContent: 'flex-start',
        gap: '30px',
        overflowX: 'auto',
        padding: '20px',
      }}
    >
      <StyledTicketCard bordered={false} style={{ flexGrow: 1 }} hoverable>
        <WizrFlexLayout
          flexDirection='column'
          background='white'
          textAlign='left'
          style={{ gap: '10px' }}
        >
          <WizrText type='sub2' textColor='#1D1F22' style={{ fontSize: '14px' }}>
            Sentiment
          </WizrText>
          <div style={{ width: '40px' }}>
            <WizrTag
              padding='0px 5px'
              textType='tagData'
              marginTop='0'
              background={getSentimentColor(details?.sentiment)}
              data={getSentimentText(details?.sentiment)}
              borderradius='4px'
            />
          </div>
          <WizrText type='h3' textColor={getSentimentColor(details?.sentiment)}>
            {details?.sentiment}
          </WizrText>
          <WizrText type='sub2' textColor='#999B9F'>
            vs 78 (Org Avg)
          </WizrText>
        </WizrFlexLayout>
      </StyledTicketCard>
      <StyledTicketCard bordered={false} style={{ flexGrow: 1 }} hoverable>
        <WizrFlexLayout
          flexDirection='column'
          background='white'
          textAlign='left'
          style={{ gap: '10px' }}
        >
          <WizrText type='sub2' textColor='#1D1F22' style={{ fontSize: '14px' }}>
            Time to Resolve
          </WizrText>
          <div style={{ width: '40px' }}>
            <WizrTag
              padding='0px 5px'
              textType='tagData'
              marginTop='0'
              background={getTimeResolutinColour(details?.time_for_resolve)}
              data={getTimeResolutionContent(details?.time_for_resolve)}
              borderradius='4px'
            />
          </div>
          <WizrText type='h3' textColor={getTimeResolutinColour(details?.time_for_resolve)}>
            {details?.time_for_resolve}hrs
          </WizrText>
          <WizrText type='sub2' textColor='#999B9F'>
            vs 78 (Org Avg)
          </WizrText>
          <WizrFlexLayout
            background='white'
            alignItems='flex-start'
            flexDirection='row'
            justifyContent='flex-start'
            style={{ gap: '10px' }}
          >
            <WizrIcon name='trendUpRed' />
            <WizrText type='caption'>10%</WizrText>
          </WizrFlexLayout>
        </WizrFlexLayout>
      </StyledTicketCard>
      <StyledTicketCard bordered={false} style={{ flexGrow: 1 }} hoverable>
        <WizrFlexLayout
          flexDirection='column'
          background='white'
          textAlign='left'
          style={{ gap: '10px' }}
        >
          <WizrText type='sub2' textColor='#1D1F22' style={{ fontSize: '14px' }}>
            Escalations
          </WizrText>
          <div style={{ width: '40px' }}>
            <WizrTag
              padding='0px 5px'
              textType='tagData'
              marginTop='0'
              background={getEscalationColor(details?.escalation)}
              data={getEscalationText(details?.escalation)}
              borderradius='4px'
            />
          </div>
          <span>
            <WizrText type='h3' textColor={getEscalationColor(details?.escalation)}>
              {details?.escalation}
            </WizrText>
            <WizrText type='sub2' textColor='#707276'>
              vs 12 Last month
            </WizrText>
          </span>
        </WizrFlexLayout>
      </StyledTicketCard>
      <StyledTicketCard bordered={false} style={{ flexGrow: 1 }} hoverable>
        <WizrFlexLayout
          flexDirection='column'
          background='white'
          textAlign='left'
          style={{ gap: '20px' }}
        >
          <WizrText type='sub2' textColor='#1D1F22' style={{ fontSize: '14px' }}>
            Total Tickets
          </WizrText>

          <WizrText type='h3' textColor='#22C55E'>
            {details?.total_tickets}
          </WizrText>
        </WizrFlexLayout>
      </StyledTicketCard>
      <StyledTicketCard bordered={false} style={{ flexGrow: 1 }} hoverable>
        <WizrFlexLayout
          flexDirection='column'
          background='white'
          textAlign='left'
          style={{ gap: '20px' }}
        >
          <WizrText type='sub2' textColor='#1D1F22' style={{ fontSize: '14px' }}>
            Open Tickets
          </WizrText>

          <WizrText type='h3' textColor='#22C55E'>
            {details?.open_tickets}
          </WizrText>
        </WizrFlexLayout>
      </StyledTicketCard>
    </WizrFlexLayout>
  )
}

const CustomerHealthList = (): JSX.Element => {
  return (
    <WizrFlexLayout>
      <WizrFlexLayout justifyContent='space-between' flexDirection='row'>
        <SearchInput
          placeholder='Search'
          prefix={<Search />}
          suffix={<Button shape='circle' icon={<SearchSetting />} onClick={() => {}}></Button>}
        />
        <Dropdown trigger={['click']} menu={{ items: [], selectable: true }}>
          <a onClick={(e) => e.preventDefault()}>
            <Space style={{ color: '#707276' }}>
              <span>
                <WizrText type='body2' textColor='#707276'>
                  Filtered by :{' '}
                </WizrText>
                <WizrText textColor='#1D1F22' type='body2'>
                  My Accounts
                </WizrText>
              </span>
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      </WizrFlexLayout>
      <WizrFlexLayout>
        <StyledWizrTable type='customerHealth' dataSource={healthTable} pagination={false} />
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

const CustomerHealthDetails = ({ details }: any): JSX.Element => {
  const topIntents = useTopIntentsSelector()
  const [selectedGraphValues, setSelectedGraphValues] = useState([
    'health',
    'escalation',
    'sentiment',
  ])
  const handleChange = (value: any) => {
    setSelectedGraphValues(value)
  }
  const GraphValues = [
    {
      label: 'Overall Health',
      value: 'health',
    },
    {
      label: 'Escalation',
      value: 'escalation',
    },
    {
      label: 'Sentiment',
      value: 'sentiment',
    },
  ]
  const topIntentsArray = useMemo(() => {
    // Extract rows and columns inside useMemo
    const rows = topIntents?.resultTable?.rows || []
    const columns = topIntents?.resultTable?.dataSchema?.columnNames || []

    return rows.map((row: any) => {
      const obj: any = {}
      columns.forEach((columnName: any, index: any) => {
        obj[columnName] = row[index]
      })
      return obj
    })
  }, [topIntents])

  const data = [
    { name: 'Jan 24', escalation: 40, health: 24, sentiment: 24 },
    { name: 'Feb 24', escalation: 30, health: 13, sentiment: 22 },
    { name: 'Mar 24', escalation: 20, health: 98, sentiment: 22 },
    { name: 'Apr 24', escalation: 27, health: 39, sentiment: 20 },
    { name: 'May 24', escalation: 18, health: 48, sentiment: 21 },
    { name: 'June 24', escalation: 23, health: 38, sentiment: 25 },
    { name: 'July 24', escalation: 34, health: 43, sentiment: 21 },
    { name: 'Aug 24', escalation: 40, health: 24, sentiment: 24 },
    { name: 'Sep 24', escalation: 30, health: 13, sentiment: 22 },
    { name: 'Oct 24', escalation: 20, health: 98, sentiment: 22 },
    { name: 'Nov 24', escalation: 27, health: 39, sentiment: 20 },
    { name: 'Dec 24', escalation: 18, health: 48, sentiment: 21},
  ]
  const getStrokeColor = (dataKey: any) => {
    // You can define a function to return different stroke colors based on the dataKey
    switch (dataKey) {
      case 'health':
        return '#FBBF24'
      case 'escalation':
        return '#EF4444'
      case 'sentiment':
        return '#4ADE80'
      default:
        return '#000000' // Default stroke color
    }
  }
  const renderLines = () => {
    return selectedGraphValues.map((dataKey: any, index: any) => (
      <Line key={index} type='monotone' dataKey={dataKey} stroke={getStrokeColor(dataKey)} />
    ))
  }
  return (
    <WizrFlexLayout>
      <WizrFlexLayout justifyContent='space-between' flexDirection='row'>
        <WizrFlexLayout
          justifyContent='flex-start'
          flexDirection='row'
          alignItems='center'
          style={{ padding: '0px' }}
        >
          <WizrButton
            type='link'
            onClick={() => dispatchCustomerHealthDetails({})}
            icon={
              <WizrFlexLayout flexDirection='row' style={{ gap: '10px' }}>
                <ArrowLeftOutlined />
                <WizrText type='body2' textColor='#707276'>
                  Health Card
                </WizrText>
                <HealthCardIcon />
              </WizrFlexLayout>
            }
          ></WizrButton>
          <Dropdown trigger={['click']} menu={{ items: [], selectable: true }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space style={{ color: '#707276' }}>
                <span>
                  <WizrText type='body2' textColor='#707276'>
                    Filtered by :{' '}
                  </WizrText>
                  <WizrText textColor='#1D1F22' type='body2'>
                    {details?.customer}
                  </WizrText>
                </span>
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </WizrFlexLayout>
        <WizrFlexLayout alignItems='flex-end'>
          <StyledHeaderWizrButton
            type='link'
            icon={<Bars />}
            text={
              <WizrText type='button' textColor='#1D1F22'>
                Analyze
              </WizrText>
            }
          ></StyledHeaderWizrButton>
        </WizrFlexLayout>
      </WizrFlexLayout>
      <WizrFlexLayout
        flexDirection='row'
        style={{ padding: '20px', gap: '10px', maxHeight: '280px' }}
      >
        <WizrFlexLayout
          background='white'
          flex={3}
          style={{ padding: '20px ', borderRadius: '16px' }}
        >
          <WizrFlexLayout
            justifyContent='flex-start'
            alignItems='flex-start'
            flexDirection='row'
            background='white'
            style={{ gap: '20px', maxHeight: '20px' }}
          >
            <WizrText type='sub2' textColor='#1D1F22'>
              Customer Health Score{' '}
            </WizrText>
            {details?.health < 50 ? (
              <WizrTag
                padding='0px 5px'
                textType='tagData'
                marginTop='0'
                background='#EF4444'
                data={['CHURN RISK']}
                borderradius='4px'
              />
            ) : (
              <></>
            )}
          </WizrFlexLayout>
          <WizrFlexLayout background='white' justifyContent='flex-end' alignItems='center'>
            <GaugeChart value={details?.health} />
            <WizrText type='h6'>
              {details?.health >= 70 ? 'Good' : details?.health >= 30 ? 'Fair' : 'Bad'}
            </WizrText>
          </WizrFlexLayout>
        </WizrFlexLayout>
        <WizrFlexLayout background='white' flex={2} style={{ padding: '0', borderRadius: '16px' }}>
          <WizrFlexLayout
            flexDirection='column'
            background='white'
            style={{ padding: '20px', borderRadius: '16px', gap: '10px', cursor: 'pointer' }}
            justifyContent='flex-start'
          >
            <WizrFlexLayout
              background='#FFFFFF'
              flexDirection='row'
              style={{ maxHeight: '52px', paddingBottom: '0px' }}
            >
              <WizrText type='sub2'>Top Intents</WizrText>
              <WizrText type='sub2'>Sentiments</WizrText>
            </WizrFlexLayout>
            {topIntentsArray?.slice(0, 3).map((value: any, key: any) => {
              return (
                <WizrFlexLayout
                  background='#FFFFFF'
                  flexDirection='row'
                  key={key}
                  onClick={() => {}}
                  style={{ minHeight: '44px' }}
                >
                  <WizrFlexLayout
                    flexDirection='column'
                    background='#FFFFFF'
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    style={{ textAlign: 'start', flex: '3' }}
                  >
                    <WizrText type='body2'>{value.Intent}</WizrText>
                    <WizrFlexLayout
                      flexDirection='row'
                      background='#FFFFFF'
                      alignItems='flex-start'
                      justifyContent='flex-start'
                      style={{ gap: '5px' }}
                    >
                      <WizrText type='sub2'>{value.CurrentMonthCount}</WizrText>
                      <WizrIcon name='trendUpRed' />
                      <WizrText type='sub2' textColor='#EF4444'>
                        {value?.IncreasePercentage?.toFixed(1)}%
                      </WizrText>
                    </WizrFlexLayout>
                  </WizrFlexLayout>
                  <WizrFlexLayout background='#FFFFFF' alignItems='flex-end'>
                    <WizrText
                      type='sub2'
                      textColor={
                        value?.OverallSentimentScore?.toFixed(1) > 55
                          ? '#22C55E'
                          : value?.OverallSentimentScore?.toFixed(1) < 55 &&
                            value?.OverallSentimentScore?.toFixed(1) > 45
                          ? '#FBBF24'
                          : '#EF4444'
                      }
                      style={{ fontSize: '26px', marginTop: '8px' }}
                    >
                      {value?.OverallSentimentScore?.toFixed(1)}
                    </WizrText>
                  </WizrFlexLayout>
                </WizrFlexLayout>
              )
            })}
          </WizrFlexLayout>
          <WizrFlexLayout alignItems='flex-end' background='white' style={{ maxHeight: '20px' }}>
            <WizrButton
              type='link'
              icon={
                <WizrText type='body2' textColor='#707276'>
                  View all
                </WizrText>
              }
            ></WizrButton>
          </WizrFlexLayout>
        </WizrFlexLayout>
      </WizrFlexLayout>
      <WizrFlexLayout>
        <CardComponent details={details} />
      </WizrFlexLayout>
      <WizrFlexLayout style={{ padding: '20px' }}>
        <WizrFlexLayout background='white' style={{ borderRadius: '16px', padding: '20px' }}>
          <WizrFlexLayout
            flexDirection='row'
            justifyContent='flex-start'
            style={{ gap: '10px' }}
            alignItems='center'
            background='white'
          >
            <WizrText type='sub2'>Customer Health Trend</WizrText>
            <Dropdown trigger={['click']} menu={{ items: [], selectable: true }}>
              <a onClick={(e) => e.preventDefault()}>
                <Space style={{ color: '#707276' }}>
                  <span>
                    <WizrText type='body2' textColor='#707276'>
                      12 months :{' '}
                    </WizrText>
                  </span>
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </WizrFlexLayout>
          <StyledWizrTagInput
            mode='multiple'
            options={GraphValues}
            defaultValue={selectedGraphValues}
            onChange={handleChange}
            maxTagCount={'responsive'}
          ></StyledWizrTagInput>
          <WizrFlexLayout style={{ minHeight: '400px', paddingTop: '30px' }} background='white'>
            <LineChart
              width={1300}
              height={370}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              <YAxis domain={[0, 90]} tickCount={10} /> {/* Custom tick count and formatter */}
              <Tooltip />
              <Legend />
              {renderLines()}
              {/* <Line type="monotone" dataKey="health" stroke='#FBBF24'  />
          <Line type="monotone" dataKey="escalation" stroke="#EF4444" />
          <Line type="monotone" dataKey="sentiment" stroke='#4ADE80' /> */}
            </LineChart>
          </WizrFlexLayout>
        </WizrFlexLayout>
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

const CustomerHealth = () => {
  const details = useCustomerHealthSelector()
  return (
    <WizrFlexLayout>
      {Object.keys(details).length === 0 ? (
        <CustomerHealthList />
      ) : (
        <CustomerHealthDetails details={details} />
      )}
    </WizrFlexLayout>
  )
}
export default CustomerHealth
