import NodeContainer from './NodeContainer'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import WizrText from '../../../components/WizrText'
import WizrDropDown from '../../../components/WizrDropDown'
import WizrTagInput from '../../../components/WizrTagInput'
import { usePromtTemplateSelector, useContentsSelector, useVisionModelSelector } from '../../../redux/selectors/content'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrInput from '../../../components/WizrInput'
import WizrTag from '../../../components/WizrTags'
import WizrFlexSpacing from '../../../components/WizrFlexSpacing'
import { useEdges, useNodes } from 'reactflow'
import { GroupOutlined } from '@ant-design/icons'
import WizrTextArea from '../../../components/WizrTextArea'
import CodeEditor from '@uiw/react-textarea-code-editor';
import { generateNodeSequenceResponse, getTenantId } from '../../../utils'
import WizrButton from '../../../components/WizrButton'
import DeleteIcon from '../../../assets/icons/DeleteIcon'
import { dispatcVisionMModels } from '../../../redux/actions/content'

import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";


const VisionNode = (props: any): JSX.Element => {

  const { id, dataCode, updateNodeData, data, onEdit, onCopyNodeDetail, isDisabled } = props

  const [selectedModel, setSelectedModel] = useState<any>("")
  const [token, setToken] = useState<any>("")
  const [userQuery, setUserQuery] = useState<any>("")
  const [fileNumbers, setFileNumbers] = useState<number>(0)
  const [parameterList, setParameterList] = useState<any>()
  const [files, setFiles] = useState<any>([])
  const [parameterInputState, setParameterInputState] = useState<{ [key: string]: string }>({})

  const { register, control, setValue, getValues } = useForm({
    defaultValues: {
      visionBlock: [{ file: "" }]
    }
  });
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "visionBlock"
  });

  const visionModelData = useVisionModelSelector()

  const edges = useEdges()
  const nodes = useNodes()

  const startnodedata: any = nodes && nodes[0]?.data


  useEffect(() => {
    const saveAppPayload = {
      edges: edges,
      nodes: nodes,
    };
    const sequence = generateNodeSequenceResponse(saveAppPayload);
    const trimmedNodeSequence = elementsBeforeSpecificElement(sequence, id);
    const defaultParameters = startnodedata?.config?.parameters.map((param: any) => param?.name)
    setParameterList((prevList: any) => [...defaultParameters, ...trimmedNodeSequence]);

  }, [nodes, edges, setParameterList, id]);

  useEffect(() => {
    const tenantId =  getTenantId()
    dispatcVisionMModels()
  }, [])

  useEffect(() => {
    setSelectedModel(data?.config?.llm_type)
    setToken(data?.config?.max_tokens)
    setUserQuery(data?.config?.user_query)
    // setFiles(data?.config?.files ? data?.config?.files : [])
    // setFileNumbers(data?.config?.files ? data?.config?.files?.length : 0)
    // setParameterInputState(data?.config?.files ? data?.config?.files?.reduce((acc: any, curr: any, index: any) => {
    //   acc[`file-${index + 1}`] = curr
    //   return acc
    // }, {}) : {})
    const newArray = data?.config?.files.map((value: any, index: any) => ({ file: value }));
    setValue("visionBlock", newArray);
  }, [data])
  



  function elementsBeforeSpecificElement(arr: any, specificElement: any) {
    const index = arr.indexOf(specificElement);
    if (index === -1) {
      return arr;
    }
    const newArray = arr.slice(0, index).slice(1).map((item: any) => {
      return `${item}.output`
    })
    return newArray

  }

  const [isEditView, setIsEditView] = useState(false)

  const updateCurrentNodeData = () => {

    const fieldArrayFormValues = getValues().visionBlock
    updateNodeData((nds: any) =>
      nds.map((node: any) => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            config: {
              user_query: userQuery,
              max_tokens: token,
              llm_type: selectedModel,
              vision_llm_model_id: "7ea39ddd-4430-4089-b7b5-6bb12fb434cd",
              files: fieldArrayFormValues?.length > 0 ? fieldArrayFormValues.map((item: any) => item.file) : []
            },
          }
        }
        return node
      }),
    )
    setIsEditView(!isEditView)
  }

  const onDeleteNodeDetail = (e: any) => {
    updateNodeData((nds: any) =>
      nds.filter((node: any) => node.id !== e)
    );
  }

  const drodownConverter = () => {
    const normalOptions = visionModelData?.map((item: any) => {
      return { label: item.display_name, value: item.llm_type, model_type: item.model_type }
    })
    return normalOptions
  }

  // const onParameterUserSelect = (key: string, value: string) => {
  //   const a = files ? files : [];
  //   a.push(`{{${value}}}`)


  //   setFiles(a)
  //   // files && files.push(`{{${value}}}`)
  //   setParameterInputState({ ...parameterInputState, [key]: parameterInputState[key] ? parameterInputState[key] + `{{${value}}}` : `{{${value}}}` })
  // }

  // const deleteFile = (e: any) => {

  
  //   setFiles(valuesArray)
  //   setParameterInputState(parameterInputState)
  //   setFileNumbers(fileNumbers - 1)
  // }


  return (
    <NodeContainer
      item={{ nodeTitle: data?.label, id: id, icon: <GroupOutlined style={{ marginRight: 10 }} /> }}
      data={dataCode}
      isEditMode={isEditView}
      saveCurrentAction={updateCurrentNodeData}
      onEditNodeDetail={() => { onEdit(data?.label, props)}}
      onDeleteNodeDetail={onDeleteNodeDetail}
      onCopyNodeDetail={() => onCopyNodeDetail(data, 'vision')}
      isDisabled={isDisabled}
      nodeType={'2'}
    >
      <VisionContainer alignItems='flex-start'>
        {isEditView ? (
          <>
            <WizrText type='body2'>Model</WizrText>
            <WizrDropDown
              placeHolder='Select one option'
              value={selectedModel}
              options={drodownConverter()}
              onChange={(value: any) => { setSelectedModel(value) }}
              className="nodrag nopan"
            />
            <WizrText type='body2'>User query</WizrText>
            <WizrInput placeholder='user query' onChange={(text: string) => setUserQuery(text)} value={userQuery} />
            <StyledFlexSpacing
              justifyContent='space-between'
              flexDirection='column'
              alignItems='center'
              size={20}
            >
              <StyledWizrTag
                background="grey"
                data={parameterList}
                onClick={(e: any) => setUserQuery(`{{${e}}}`)} />
            </StyledFlexSpacing>
            <WizrText type='body2'>Max Token</WizrText>
            <WizrInput placeholder='token' onChange={(text: string) => setToken(parseInt(text))} value={token} />
            <StyledFlexSpacing
              justifyContent='space-between'
              flexDirection='column'
              alignItems='center'
              size={20}
            >
              <StyledWizrTag
                background="grey"
                data={parameterList}
                onClick={(e: any) => { }} />
            </StyledFlexSpacing>
            {fields.map((item, index) => {
              return (
                <WizrFlexLayout key={item.id} >
                  <WizrText type='body3' textColor='#707276'>
                    File
                  </WizrText>
                  <WizrFlexLayout flexDirection='row' justifyContent='flex-start' alignItems='flex-start' style={{ gap: '10px', margin: '5px 0px' }}>
                    <Controller
                      render={({ field }) => <WizrInput {...field} />}
                      name={`visionBlock.${index}.file`}
                      control={control}
                    />
                    <WizrButton type='text' icon={<DeleteIcon />} onClick={() => remove(index)} style={{ minHeight: '50px' }} />
                  </WizrFlexLayout>
                  <StyledFlexSpacing justifyContent='space-between' flexDirection='column' alignItems='center' size={20}>
                    <StyledWizrTag
                      background="grey"
                      data={parameterList}
                      onClick={(e: any) => {
                        setValue(`visionBlock.${index}.file`, `{{${e}}}`);
                      }}
                    />
                  </StyledFlexSpacing>
                </WizrFlexLayout>
              );
            })}
            <WizrButton type='text' text='Add files' onClick={() => append({ file: '' })} style={{ borderWidth: '2px', borderColor: 'black', borderRadius: '20px', width: '100%' }} />

            {/* {Object.keys(parameterInputState).map((item, index) => {              
              // const uniqueIdentifier = `file-${index + 1}`
              
              return (
                <>
                  <WizrText type='body3' textColor='#707276' key={index}>
                    File 
                  </WizrText>
                  <WizrFlexLayout flexDirection='row' justifyContent='flex-start' alignItems='flex-start' style={{ gap: '10px', margin: '5px 0px' }}>
                    <WizrInput placeholder='files' name={item}  value={parameterInputState && parameterInputState[item]} onChange={(value: any) => {}}/>
                    <WizrButton type='text' icon={<DeleteIcon />} onClick={(e: any) => deleteFile(`file-${index + 1}`)} style={{ minHeight: '50px' }} onChange={() => {}}/>
                  </WizrFlexLayout>
                  <StyledFlexSpacing
                    justifyContent='space-between'
                    flexDirection='column'
                    alignItems='center'
                    size={20}
                  >
                    <StyledWizrTag
                      background="grey"
                      data={parameterList}
                      onClick={(e: any) => {

                        onParameterUserSelect(item, e)
                      }}
                    />
                  </StyledFlexSpacing>

                </>
              )
            })} */}
            {/* <WizrButton type='text' text='Add files' onClick={() => setFileNumbers(fileNumbers + 1)} style={{ borderWidth: "2px", borderColor: "black", borderRadius: "20px", width: "100%" }}></WizrButton> */}
          </>
        ) : (
          <>
            <WizrText type='body3' textColor='#707276'>
              Model
            </WizrText>
            <WizrText type='body2' textColor='#1D1F22' >
              {selectedModel}
            </WizrText>
            <WizrText type='body3' textColor='#707276'>
              User query
            </WizrText>
            <WizrText type='body2' textColor='#1D1F22' >
              {userQuery}
            </WizrText>
            <WizrText type='body3' textColor='#707276'>
              Token
            </WizrText>
            <WizrText type='body2' textColor='#1D1F22' >
              {token}
            </WizrText>
            {fields.map((item, index) => {
              return (
                <WizrFlexLayout key={item.id} flexDirection='row' alignContent='flex-start'>
                  <WizrText type='body3' textColor='#707276'>
                    File
                  </WizrText>
                  <WizrText type='body2' textColor='#1D1F22' >
                    {item.file}
                  </WizrText>
                </WizrFlexLayout>
              );
            })}
          </>
        )}
      </VisionContainer>
    </NodeContainer>
  )
}

const VisionContainer = styled(WizrFlexLayout)`
  gap: 16px;
`
const StyledWizrButton = styled(WizrButton)`
margin-left:610px;
margin-bottom:7px;`

const StyledWizrTag = styled(WizrTag)`
  height: 42px;
  padding: 8px 11px;
  justify-content: space-between;
`

const StyledFlexSpacing = styled(WizrFlexSpacing)`
  .ant-space-item {
    display: flex !important;
    width: 100% !important;
    flex-wrap: wrap;
  }
`

export default VisionNode
