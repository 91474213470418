import styled from 'styled-components'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrInput from '../../../components/WizrInput'
import WizrText from '../../../components/WizrText'
import Copy from '../../../assets/icons/Copy'
import WizrButton from '../../../components/WizrButton'
import { get } from 'http'
import { getTenantId } from '../../../utils'
import copy from 'copy-to-clipboard';
import { Select, Spin, message as antdMessage } from 'antd'
import { useEffect, useState } from 'react'
import { dispatchModels, dispatchSubscriptionDetails } from '../../../redux/actions/content'
import { useContentLoadingSelector, useGetTenantDetails, useLLMModelSelector, useSubscriptionDetailsSelector } from '../../../redux/selectors/content'
import WizrTagInput from '../../../components/WizrTagInput'

const StyledInputField = styled(WizrInput)`
  background: #f2f4f9;
  .ant-input {
    background: #f2f4f9;
  }
`
const StyledWizrTagInput = styled(Select)`
  /* Add your custom styles here */
  .ant-select-selection-placeholder {
    text-align: left;
  }
  width: 100%;
  .ant-select-selector {
    min-height: 50px;
    background: #f2f4f9 !important;
    border-radius: 10px !important;
    padding-left: 20px !important;
  }
  .ant-select-selection-item {
    background: white !important;
    border-radius: 10px !important;
    border: 1px solid black !important;
    gap: 6px !important;
    padding-right: 6px !important;
  }
  .ant-select-selection-overflow {
    gap: 6px !important;
  }
  overflow: auto; /* Hide any overflow content */

  .ant-select-selection-item-remove{
    display:none !important;
  }
`

const Accounts = (): JSX.Element => {
    const tenantDetails = useGetTenantDetails()    
    const subscriptionKey = tenantDetails?.product_details?.product_name
    const aimodels = tenantDetails?.product_details?.product_llm_models
    useEffect(() => { 
        dispatchSubscriptionDetails()
        dispatchModels()

      },[])

    const llmModels = useLLMModelSelector()
    const [mappedModels, setMappedModels] = useState([]);

    useEffect(() => {
      if (aimodels && llmModels) {
        // Map through aimodels and find corresponding objects in llmModels
        const mappedData = aimodels.map((model:any) => {
          const foundModel = llmModels.find(llm => llm.id === model);
          return foundModel?.display_name; // This will be an object from llmModels or undefined if not found
        });
  const filteredModels = mappedData.filter((model:any) => model !== undefined);

  filteredModels.sort((a:any, b:any) => {
    // Compare the lengths of name property of objects a and b
    const nameA = a.toLowerCase(); // Convert to lowercase for case-insensitive comparison
    const nameB = b.toLowerCase();
    return nameA.length - nameB.length; // Sort by ascending length
  });
        setMappedModels(filteredModels); // Update state with the mapped data
      }
    }, [aimodels, llmModels]); 
      
   
 
  const tenantId = getTenantId()
  const subscriptionDetails = useSubscriptionDetailsSelector()
  const loading = useContentLoadingSelector()

  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item?.display_name,
        value: item?.id,
      }))
    return result || []
  }

  return (
    <WizrFlexLayout background='white' flexDirection='row'>
      <WizrFlexLayout flex={2} background='white'>
        <></>
      </WizrFlexLayout>
      <WizrFlexLayout flex={8} background='white' flexDirection='row' style={{ gap: '30px' }}>
        <WizrFlexLayout
          background='white'
          justifyContent='flex-start'
          style={{ padding: '50px 20px', gap: '15px' }}
          textAlign='left'
        >
          <WizrText type='body2'>Tenant id</WizrText>
          <StyledInputField
            value={tenantId}
            suffix={<WizrButton type='link' icon={<Copy />}
            onClick={()=>{
                antdMessage.success('Tenant id copied to clipboard', 2);
                copy(tenantId)
            }}
            ></WizrButton>}
          />
          <WizrText type='body2'>Access Key</WizrText>
          <StyledInputField value={subscriptionDetails} suffix={<WizrButton type='link' icon={loading?<Spin /> :<Copy />}
          onClick={()=>{
            antdMessage.success('Access key copied to clipboard', 2);
            copy(tenantId)
        }}
          ></WizrButton>} />
        </WizrFlexLayout>
        <WizrFlexLayout
          background='white'
          justifyContent='flex-start'
          style={{ padding: '50px 20px', gap: '15px' }}
          textAlign='left'
        >
          <WizrFlexLayout
            flexDirection='row'
            alignItems='center'
            background='white'
            style={{ maxHeight: '90px',gap:"100px" }}
            justifyContent='flex-start'

          >
            <WizrText type='body2'>Subscription Plan:</WizrText>
            <WizrText type='body2'>{subscriptionKey}</WizrText>
          </WizrFlexLayout>
          {/* <WizrFlexLayout
            flexDirection='column'
            alignItems='center'
            background='white'
            style={{ maxHeight: '100px',gap:"100px" }}
            // justifyContent='flex-start'
            textAlign='left'
          >
            <WizrText type='body2'>AI models</WizrText>
          </WizrFlexLayout> */}
            <WizrText type='body2'>AI MODELS</WizrText>
          {/* <StyledInputField value={subscriptionDetails} suffix={<WizrButton type='link' icon={loading?<Spin /> :<Copy />}
          onClick={()=>{
            antdMessage.success('Access key copied to clipboard', 2);
            copy(tenantId)
        }}
          ></WizrButton>} /> */}
          {/* <WizrTagInput value={mappedModels}></WizrTagInput> */}
          
          <StyledWizrTagInput
              mode='multiple'
              options={dropDownConverter(llmModels)}
              value={mappedModels}
              //   onChange={(value: any) => {
              //     console.log('value', value)
              //     doUpdateFields('product_llm_models', value)
              //   }}
              maxTagCount={2}
            ></StyledWizrTagInput>
        </WizrFlexLayout>
      </WizrFlexLayout>
      <WizrFlexLayout flex={2} background='white'>
        <></>
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

export default Accounts
