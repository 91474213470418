import React, { useEffect, useState } from "react";
import WizrText from "../components/WizrText";
import WizrFlexLayout from "../components/WizrFlexLayout";
import WizrUserJourney from "../components/WizrUserJourney";
import WizrButton from "../components/WizrButton";
import WizrTag from "../components/WizrTags";
import { getDayRange, getMonthRange, getWeekRange } from "../utils";
import { Button, Card, DatePicker, Input, Popover } from "antd";
import WizrIcon from "../components/WizrIcon";
import styled from "styled-components";
import Search from "../assets/icons/Search";
import SearchSetting from "../assets/icons/SearchSetting";
import WizrTable from "../components/WizrTable";
import { useAppInsights } from "../redux/selectors/content";
import { dispatchAppInsights } from "../redux/actions/content";
import { useLocation, useParams } from "react-router-dom";

const StyledTicketCard = styled(Card) <any>`
  background-color: #ffffff;
  width: 100%;
  height: 160px;
  border-radius: 8px;
  .ant-card-meta-detail {
    border-bottom: none;
    border-top: none;
  }
  .ant-card-head {
    border-bottom: none;
    text-align: start
  }
  .ant-card-body {
    text-align: center;
    padding: 24px;
  }
`

const StyledWizrTable = styled(WizrTable)`
  .ant-table-wrapper {
    width: 100% !important;
  }
  
  .ant-table-thead {
    background-color: #f2f4f9;
    border-spacing: 0px;
  }

  .ant-table-tbody > tr {
    background-color: #ffffff;
    margin-bottom: 0px;
  }

  .ant-table table {
    border-spacing: 0 5px;
  }

  .ant-table-filter-dropdown {
    min-width: 263px !important;
    background-color: red;
  }
`

type IntervalName = '1 day' | '1 week' | '1 month' | 'Custom';

const AppInsights = () => {

    const location = useLocation();
    const { app_id } = location.state
    
    
    const uniqueTagList = ['1 day', '1 week', '1 month', 'Custom']
    const [showDatePicker, setShowDatePicker] = useState(false);

    const [range, setRange] = useState({
        currentMonthStartDate: 1675189800000,
        currentMonthEndDate: 1682879400000,
        previousMonthStartDate: 1669833000000,
        previousMonthEndDate: 1675189800000
    });

    const getDateRangeFilter = (name: IntervalName) => {

        // setInterval(name)
        // let newRange: any;

        // switch (name) {
        //   case '1 day':
        //     newRange = getDayRange();
        //     setShowDatePicker(false);
        //     break;
        //   case '1 week':
        //     setShowDatePicker(false);
        //     newRange = getWeekRange();
        //     break;
        //   case '1 month':
        //     setShowDatePicker(false);
        //     newRange = getMonthRange();
        //     break;
        //   case 'Custom':
        //     setShowDatePicker(true);
        //     break;
        //   default:
        //     throw new Error('Invalid date range specified');
        // }
        // setRange(newRange);
    }

    const insightsData = [
        {
            name: 'app1',
            id: '1',
            count: '1',
            average: '1',
            error_rate: '1'
        },
        {
            name: 'app2',
            id: '2',
            count: '2',
            average: '2',
            error_rate: '2'
        },
        {
            name: 'app3',
            id: '3',
            count: '3',
            average: '3',
            error_rate: '3'
        },
    ]

    const appValues = useAppInsights();


    useEffect(() => {

        const payload = {
            "start_time": 1713778746781,
            "end_time": 1713855533095,
            "filters": {
                "app_version": "1"
            },
            "app_id": app_id,
            "version": "1"
        }

        dispatchAppInsights(payload)
    }, [])

    return (
        <div style={{ minHeight: '100vh', overflow: 'auto', overflowY: 'auto', paddingBottom: '20px', width: '100%' }} >
            <WizrFlexLayout style={{ gap: '16px' }}>
                <WizrFlexLayout alignContent='flex-start' flexDirection='row' justifyContent='space-between'>
                    <WizrFlexLayout>
                        <WizrUserJourney
                            userJourney={[
                                {
                                    title: <WizrButton type='text' text={<WizrText type='h6'>App Insights</WizrText>} />,
                                    isCurrent: true,
                                },
                            ]}
                        />
                    </WizrFlexLayout>
                    <WizrFlexLayout alignItems='center' flexDirection='row' alignContent='flex-end' justifyContent='end' >
                        <WizrTag
                            data={uniqueTagList}
                            onClick={(name: any) => {
                                getDateRangeFilter(name)
                            }}
                            background={'#EAECF1'}
                            textColor='1D1F22'
                            checkable={true}
                        />
                        {/* {showDatePicker ? <Popover content={<CreateAppContent />} title='' trigger='click'>
                            <DatePicker.RangePicker onChange={handleCustomDateRangeChange} />
                        </Popover> : <></>} */}
                        <WizrIcon name='settings' />
                    </WizrFlexLayout>
                </WizrFlexLayout>
                <WizrFlexLayout alignItems='center' flex={2} style={{ padding: '10px' }}>
                    <SearchInput placeholder='Search' prefix={<Search />} suffix={<Button shape='circle' icon={<SearchSetting />} onClick={() => { }}></Button>} />
                </WizrFlexLayout>
                <WizrFlexLayout flexDirection='row' style={{ gap: '16px', padding: '4px' }}>
                    <StyledTicketCard
                        title={<WizrFlexLayout
                            background='#FFFFFF'
                            flexDirection='row'
                            style={{ maxHeight: '52px' }}
                        >
                            <WizrText type='sub2' >Total Exection Count</WizrText>
                        </WizrFlexLayout>}
                        bordered={false}
                        style={{ flexGrow: 1 }}
                        hoverable>
                        <WizrText type='sub2' textColor='#22C55E' style={{ fontSize: '28px' }}>30 mins</WizrText>
                        <WizrFlexLayout justifyContent='center' alignContent='center' alignItems='center' flexDirection='row' background='white' style={{ marginTop: '8px', gap: '4px' }}>
                            <WizrIcon name='trendUpRed' />
                            <WizrText type='sub2' textColor='#EF4444'>8%</WizrText>
                            <WizrText type='sub2'>v.s last month</WizrText>
                        </WizrFlexLayout>
                    </StyledTicketCard>
                    <StyledTicketCard
                        title={<WizrFlexLayout
                            background='#FFFFFF'
                            flexDirection='row'
                            style={{ maxHeight: '52px' }}
                        >
                            <WizrText type='sub2' >Overall Average Time to Execute</WizrText>
                        </WizrFlexLayout>}
                        bordered={false}
                        style={{ flexGrow: 1 }}
                        hoverable>
                        <WizrText type='sub2' textColor='#22C55E' style={{ fontSize: '28px' }}>26 hrs</WizrText>
                        <WizrFlexLayout justifyContent='center' alignContent='center' alignItems='center' flexDirection='row' background='white' style={{ marginTop: '8px', gap: '4px' }}>
                            <WizrIcon name='trendUpRed' />
                            <WizrText type='sub2' textColor='#EF4444'>12%</WizrText>
                            <WizrText type='sub2'>v.s last month</WizrText>
                        </WizrFlexLayout>
                    </StyledTicketCard>
                    <StyledTicketCard
                        title={<WizrFlexLayout
                            background='#FFFFFF'
                            flexDirection='row'
                            style={{ maxHeight: '52px' }}
                        >
                            <WizrText type='sub2' >Total Error Rate</WizrText>
                        </WizrFlexLayout>}
                        bordered={false}
                        style={{ flexGrow: 1 }}
                        hoverable>
                        <WizrText type='sub2' textColor='#22C55E' style={{ fontSize: '28px' }}>46</WizrText>
                        <WizrFlexLayout justifyContent='center' alignContent='center' alignItems='center' flexDirection='row' background='white' style={{ marginTop: '8px', gap: '4px' }}>
                            <WizrIcon name='trendDownGreen' />
                            <WizrText type='sub2' textColor='#22C55E'>10%</WizrText>
                            <WizrText type='sub2'>v.s last month</WizrText>
                        </WizrFlexLayout>
                    </StyledTicketCard>
                </WizrFlexLayout>
                {/* <StyledWizrTable
                    dataSource={appValues}
                    type='appInsights'
                    //   tabSelected={key}
                    scroll={{ y: 500 }}
                    pagination={false}
                /> */}
                <StyledWizrTable
                    dataSource={appValues}
                    type='appInsightsSingle'
                    //   tabSelected={key}
                    scroll={{ y: 500 }}
                    pagination={false}
                />
            </WizrFlexLayout>
        </div>
    )
}

export default AppInsights


const SearchInput = styled(Input)`
  border-radius: 30px;
  border: 0px solid var(--surface-overlay, #f9f9f9);
  background: #fff;
  width: 512px;
  height: 40px;
`