import { ArrowLeftOutlined } from '@ant-design/icons'
import WizrButton from '../../../components/WizrButton'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrUserJourney from '../../../components/WizrUserJourney'
import WizrText from '../../../components/WizrText'
import { useLocation, useNavigate } from 'react-router-dom'
import WizrInput from '../../../components/WizrInput'
import styled from 'styled-components'
import WizrDropDown from '../../../components/WizrDropDown'
import WizrTagInput from '../../../components/WizrTagInput'
import { useEffect, useState } from 'react'
import { dispatchCreateTenants, dispatchListAppGeneral, dispatchPackageList, dispatchProuctsList, dispatchUpdateTenants, dispatchVectorDb, dispatchupdateUserView } from '../../../redux/actions/content'
import { useContentLoadingSelector, useListPackagesSelectors, useListProductsSelectors, useVectorDb, useappDataSelector } from '../../../redux/selectors/content'
import { Select } from 'antd'


 const StyledFormContainer = styled(WizrFlexLayout)`
 align-items:flex-start;
 justify-content:flex-start;
 background:white;
 gap:10px;

 `
 const StyledDropDown = styled(WizrDropDown)`
 height:50px;
.ant-select-selector{
    height:50px !important;
    background-color:#F2F4F9 !important;
   .ant-select-selection-placeholder{
    margin-top:10px;
   }
}
 `

const StyledWizrTagInput = styled(WizrTagInput)`
.ant-select-selector{
    background-color:#F2F4F9 !important;
}
`

const TenantDetails = (): JSX.Element => {
  const [tenantDetails,setTenantDetails]=useState({
    tenant_name: "",
    tenant_type: "b2c_virtual",
    user_domains: [],
    tenant_domain: "" ,
app_domain_prefix: "app",
    region: "",
    packages: [ ],
    product_key:"",
    status:"active",
    product_name:"",
    industry_type:'',
    vector_config_id:""
    })

  // const [packageList,setPackageList] = useState([])
  const [productList,setProductList] = useState([])
  interface VectorDbItem {
    id: number;
    default: boolean;
    // Other properties...
}
  

  const navigate = useNavigate()
  const location = useLocation()
  const loading = useContentLoadingSelector()
  const action = location?.state?.action

  useEffect(()=>{
if (action==="edit"){
  const tenantDetails = location?.state?.details
  setTenantDetails(tenantDetails)
  if (tenantDetails && (tenantDetails.general_apps || tenantDetails.packages)) {
    setSelectedPackageAndApps([...tenantDetails.general_apps, ...tenantDetails.packages]);
  }}
  },[])
  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item,
        value: item,
      }))
    return result
  }
  const dropDownConverterPackage= (value: string[]) => {
      if (Array.isArray(packages)) {

    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item?.package_name,
        value: item?.package_key,
      }))
    return result} 
    else{
      return []
    }
  }

  const dropDownConverterDb= (value: string[]) => {
    
  
    if (Array.isArray(vectorDbList)) {

    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item?.vector_db_type,
        value: item?.id,
      }))
      return result}
      else{
        return []
      }
    } 
      

  const dropDownConverterProduct= (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item?.product_name,
        value: item?.product_key,
      }))
    return result
  }
  const products= useListProductsSelectors()
  const packages :string[] = useListPackagesSelectors()
  const appData :string[] = useappDataSelector()
  const vectorDbList :VectorDbItem []= useVectorDb()

  useEffect(()=>{
    const productList = products?.map((item:any)=>item?.product_name)
    setProductList(productList)
    // const packageList = packages?.map((item:any)=>item?.package_name)    
    // setPackageList(packageList)
  },[products,packages])
 
  
  useEffect(() => {

    dispatchProuctsList()
    dispatchPackageList()
    dispatchListAppGeneral()
    dispatchVectorDb()
  }, [])

  const types = [{label:"B2C VIRTUAL",value:"b2c_virtual"} ]


  const region = ["India","US West","US East"]
  const industry = ["saas","ecommerce","others"]

  const setDetails= (value:any,key:any)=>{
      
    setTenantDetails({
      ...tenantDetails,
      [key]:value      
    })

  }
  const createTenant=async()=>{
    const {product_name,  ...restOfItem } = tenantDetails;
    const apps: any[] = [];
    const packages: any[] = [];
    
    selectedPackageAndApps.forEach((item: any) => {
      // Regular expression pattern for UUID (assuming UUID is in standard format)
      const uuidPattern = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;
    
      // Check if item matches the UUID pattern
      if (uuidPattern.test(item)) {
        apps.push(item);
      } else {
        packages.push(item);
      }
    });
    const payload = {
      ...restOfItem,
      general_apps: apps,
      packages: packages
    }
    if (action === 'create') {
      const vectorconfigid = vectorDbList?.find((item: any) => item.default===true )?.id
      const payloadFinal = { ...payload, master_tenant: false, teams: [], vector_config_id: vectorconfigid};
      await dispatchCreateTenants(payloadFinal);
  } else {
      await dispatchUpdateTenants(payload);
  }
  
    navigate(-1)
    dispatchupdateUserView({
      activeKey:"tenant"
    })
   
  }

 
  const [selectedPackageAndApps, setSelectedPackageAndApps] = useState<string[]>([]);

  const handleSelectChange = (selectedValues:any) => {
    setSelectedPackageAndApps(selectedValues)
  };

  const { Option, OptGroup } = Select;
  
  return (
    <WizrFlexLayout style={{ padding: '20px' }}>
      <WizrFlexLayout flex={1}>
        <WizrUserJourney
          userJourney={[
            {
              title: (
                <WizrButton
                  style={{ paddingBottom: '8px' }}
                  icon={<ArrowLeftOutlined style={{ fontSize: '22px', marginRight: '10px' }} />}
                  type='text'
                  text={<WizrText type='h6'>{'Tenants'}</WizrText>}
                  onClick={() => navigate(-1)}
                />
              ),
            },
            {
              title: action === 'create' ? 'Create New Tenants' : 'Edit Tenants',
            },
          ]}
        ></WizrUserJourney>
      </WizrFlexLayout>
      <WizrFlexLayout
      flexDirection='\row'
        flex={11}
        background='white'
        style={{ borderRadius: '8px', margin: '20px 0px'}}
        
      >
         <WizrFlexLayout background='white' flex={1} >
<></>
</WizrFlexLayout>
       <WizrFlexLayout background='white' flex={6} style={{padding:"30px 20px"}} alignItems='flex-start' justifyContent='flex-start' alignContent='flex-start'>
<StyledFormContainer flexDirection='row' style={{maxHeight:"100px",gap:"30px"}}>
    <StyledFormContainer flexDirection='column' >
<WizrText type='body2'textColor='#707276'> Tenant Name</WizrText>
<WizrInput placeholder='Enter Tenant Name ' background={"#F2F4F9"} value={tenantDetails?.tenant_name} onChange={(value:any)=>{setDetails(value,"tenant_name")}}></WizrInput>
</StyledFormContainer>
<StyledFormContainer flexDirection='column'>
<WizrText type='body2'> Type</WizrText>
{/* <StyledDropDown options={types} placeHolder='Select type' value={tenantDetails?.tenant_type} onChange={(value:any)=>{setDetails(value,"tenant_type")}}/> */}
<WizrInput placeholder='Enter Tenant Name ' background={"#F2F4F9"} value={"B2C VIRTUAL"} onChange={(value:any)=>{setDetails(value,"tenant_type")}}></WizrInput>

     </StyledFormContainer>
</StyledFormContainer>
<StyledFormContainer flexDirection='row' style={{maxHeight:"100px",gap:"30px"}}>
    <StyledFormContainer flexDirection='column'>
<WizrText type='body2'>Domain</WizrText>
<WizrInput placeholder='Enter domain' background={"#F2F4F9"} value={tenantDetails?.user_domains} onChange={(value:any)=>{setDetails(value.split(","),"user_domains")}}></WizrInput>
</StyledFormContainer>
<StyledFormContainer flexDirection='column'>
<WizrText type='body2'> Products</WizrText>
<StyledDropDown options={dropDownConverterProduct(products)} placeHolder='Select products' value={tenantDetails?.product_key} onChange={(value:any)=>{setDetails(value,"product_key")}}/>
    
</StyledFormContainer>
</StyledFormContainer>
<StyledFormContainer flexDirection='row' style={{maxHeight:"100px",gap:"30px"}}>
    <StyledFormContainer flexDirection='column'>
<WizrText type='body2'> Region</WizrText>
<StyledDropDown options={dropDownConverter(region)} placeHolder='Select Region' value={tenantDetails?.region}  onChange={(value:any)=>{setDetails(value,"region")}}/>

</StyledFormContainer>
<StyledFormContainer>
<WizrText type='body2'> App Domain</WizrText>
<WizrInput placeholder='Enter domain' background={"#F2F4F9"} value={tenantDetails?.tenant_domain} onChange={(value:any)=>{setDetails(value,"tenant_domain")}}></WizrInput>
</StyledFormContainer>
</StyledFormContainer>
<StyledFormContainer flexDirection='row' style={{maxHeight:"100px",gap:"30px"}}>
    <StyledFormContainer flexDirection='column'>
<WizrText type='body2'> Industry</WizrText>
<StyledDropDown options={dropDownConverter(industry)} placeHolder='Select Industry' value={tenantDetails?.industry_type}  onChange={(value:any)=>{setDetails(value,"industry_type")}}/>

</StyledFormContainer>
<StyledFormContainer>
  <></>
{/* <WizrText type='body2'> Vector Db</WizrText>
<StyledDropDown options={dropDownConverterDb(vectorDbList)} placeHolder='Select VectorDb' value={tenantDetails?.vector_config_id}  onChange={(value:any)=>{setDetails(value,"vector_config_id")}}/> */}
  </StyledFormContainer>
</StyledFormContainer>
<StyledFormContainer flexDirection='row' style={{maxHeight:"100px"}}>
<StyledFormContainer flexDirection='column'>
<WizrText type='body2'>Apps/Packages</WizrText>
{/* <WizrTagInput  value={tenantDetails?.packages} options={dropDownConverterPackage(packages)}  onChange={(value:any)=>{setDetails(value,"packages")}}></WizrTagInput> */}
<Select
      mode="multiple"
      style={{ width: "100%" }}
      value={selectedPackageAndApps}
      onChange={(selectedValues:any) => handleSelectChange(selectedValues)}
    >
      <OptGroup label="Packages">
        {packages?.map((item:any, key: any)=>(
          <Option value={item?.package_key} key={key}>{item?.package_name}</Option>
        ))}
    </OptGroup>
    <OptGroup label="APPS">
      {appData?.map((item:any, key: any)=>(
          <Option value={item?.app_id} key={key}>{item?.app_name}</Option>
        ))}
    </OptGroup>
    </Select>
      
    {/* // options={[
      //   {
      //     label: <span>PACKAGES</span>,
      //     title: 'pacakages',
      //     options: dropDownConverterPackage(packages),
      //     group: 'pacakages',
      //   },
      //   {
      //     label: <span>APPS</span>,
      //     title: 'apps',
      //     options: dropDownConverterApp(appData),
      //     group: 'apps',
      //   },
      //   // Add more groups/options as needed
      // ]} */}

</StyledFormContainer>

</StyledFormContainer>
<WizrFlexLayout justifyContent='flex-end' alignContent='flex-end' alignItems="flex-end" background='white' style={{maxHeight:"40px"}}>
    <WizrButton style={{width:"100px"}} type='primary' text={action === 'create'?"Create":"Update"} onClick={()=>{createTenant()}} disabled={loading}></WizrButton>
</WizrFlexLayout>

       </WizrFlexLayout>
       <WizrFlexLayout background='white' flex={1} >
<></>
</WizrFlexLayout>
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}
export default TenantDetails
