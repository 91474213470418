import { useEffect, useRef, useState } from 'react'
import { Input, Button, Avatar, Spin, message as antdMessage } from 'antd'
import styled from 'styled-components'
import WizrText from './WizrText'
import RobotIcon from '../assets/icons/robot'
import SentIcon from '../assets/icons/sent'
import PlayIcon from '../assets/icons/PlayButton'
import WizrFlexLayout from './WizrFlexLayout'
import WizrButton from './WizrButton'
import LikeIcon from '../assets/icons/LikeIcon'
import DislikeIcon from '../assets/icons/DislikeIcon'
import { MODAL_TYPES, useWizrModalContext } from '../providers/modal/WizrModalProvider'
import {
  dispatchAddComments,
  dispatchChatMessage,
  dispatchClearChat,
  dispatchQueryUrls,
} from '../redux/actions/content'
import { useContentLoadingSelector, useQuerySelector, useTestStatusSelector } from '../redux/selectors/content'
import RepeatIcon from '../assets/icons/Repeat'
import RefreshIcon from '../assets/icons/refresh'
import Markdown from 'react-markdown'
import copy from 'copy-to-clipboard';
import Copy from '../assets/icons/Copy'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { log } from 'console'
import BulkRunTest from '../pages/components/AppDesignerTool.tsx/BulkRunTest'
import { useAuth } from '../authContext'
import PdflogoIcon from '../assets/icons/Pdflogo'
import CloseIcon from '../assets/icons/Close'
import WizrMarkDown from './WizrMarkDown'

const StyledChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
`

const StyledChatHeader = styled.div`
  display: flex;
  color: black;
  text-align: left;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const StyledChatContainer = styled.div<{ bulkRun?: boolean }>`
position:relative;
  flex: 1;
  margin:10px;
  padding: 20px;
  
  background-color: #f2f4f9;
  border-radius: 16px;
  align-content: flex-start;
  scrollbar-width: thin;
  scrollbar-color: #00C8CA white;
  // ${(props) => (props.bulkRun ? "" : "min-height: 50vh; max-height: 50vh;")}
  // ${(props) => (props.bulkRun ? "min-height: 100vh; max-height: 100vh;" : "")}


  &::-webkit-scrollbar {
    width: 8px; /* Width of the vertical scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00C8CA; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Radius of the scrollbar thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #6240b4; /* Color of the scrollbar thumb on hover */
  }

  &::-webkit-scrollbar-track {
    background-color: white; /* Color of the scrollbar track */
  }
`

const StyledMessage = styled.div<{ isSent: boolean }>`

  display: flex;
  flex-direction: ${({ isSent }) => (isSent ? 'row-reverse' : 'row')};
  align-items: flex-end;
  margin-bottom: 10px;
`

const StyledMessageBubble = styled.div<{ isSent: boolean }>`
  max-width: 90%;
  padding: 10px;
  display: flex;
  gap: 10px;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  align-contents: flex-start;
  white-space: pre-wrap; /* Allows wrapping within words */
  overflow-wrap: break-word; /* Breaks long words if necessary */
  align-self: flex-start;
  border-radius: ${({ isSent }) => (isSent ? '24px 0px 24px 24px' : '16px 16px 16px 0px')};
  background-color: ${({ isSent }) => (isSent ? "#00C8CA" : 'white')};
  color: ${({ isSent }) => (isSent ? 'white' : 'white')};
`

const StyledAvatar = styled(Avatar)`
  margin: 0 5px;
  background-color: #00C8CA;
`

const StyledInputContainer = styled.div`

bottom:0px;
  display: flex;
  align-items: center;
  padding: 0px 0px;
  justify-content: bottom;
 
`

const StyledInput = styled(Input)`
  flex: 1;
  margin-right: 10px;
  border-radius: 0px;
  border-width:0px;
  length:30px;
  
`
const WizrChatLayout = styled(WizrFlexLayout)`
max-width: 100%
border-bottom: 1px solid #ccc;
gap: 5px;
`

const StyledTextButton = styled(WizrText)`
  text-decoration: underline !important;
`

interface IWizrChat {
  onHistoryClick?: any
  onChatClick?: any
  chatMessage: any[]
  enableChat?: boolean
  promptInputState?: any
  mapValueToUserQuery?: any
  onRunBatchClick?: any
  bulkRun?: boolean
  appshare?: boolean
  runtype?: string
  setRuntype?: any
  appDetails?: any
  apptest?: any
  page?: string
  onTestAreaClose?: any
}


const Chat = ({ enableChat = false, apptest = false, onHistoryClick, onChatClick, chatMessage, promptInputState, mapValueToUserQuery, onRunBatchClick, bulkRun, appshare, runtype = "single", setRuntype, appDetails, page, onTestAreaClose }: IWizrChat) => {

  const { userPermission, hasAcces } = useAuth();
  
  const openingLinksMarkDown = {
    a: ({ href, children }:any) => (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ),
  }
  

  const [inputValue, setInputValue] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const chatContainerRef = useRef<HTMLDivElement | null>(null)
  const loading = useContentLoadingSelector()
  const stopGenerate = useTestStatusSelector()
  const urls = useQuerySelector()
  const prompt_id = localStorage.getItem('prompt_id') || ''
  const navigate = useNavigate()

  useEffect(() => {
    if (chatContainerRef?.current) {
      chatContainerRef.current.scrollTop = chatContainerRef?.current?.scrollHeight
    }
  }, [chatMessage])

  const handleSendMessage = (type: any) => {

    // if ( inputValue) {
    dispatchChatMessage({ text: inputValue ? inputValue : '', isSent: true })
    setInputValue('')
    onChatClick(inputValue)
    // }


  }
  const { showModal, hideModal } = useWizrModalContext()

  const handleAddComments = (comments: string, status: string) => {
    const payload = {
      id: '664b078c-1541-4728-89d6-f82181464250',
      prompt_id: 'e6231728-e167-44f0-81c5-212cd4f70e2b',
      comment: comments,
      feedback: status === 'like' ? 0 : 1,
    }
    dispatchAddComments(payload)
  }

  const stopTestGeneration = (xyz: any) => {
    dispatchQueryUrls(urls?.terminatePostUri)

  }
  const handleButtonClick = (url: any) => {
    window.open(url, '_blank'); // Opens the URL in a new tab/window for download

  };  


  return (
    <StyledChatWrapper>
      <StyledChatHeader>
        {!(bulkRun || appshare) ? <>
          <WizrText type='sub1'>Test Area</WizrText>
          <WizrFlexLayout flexDirection='row' background='#FFF' justifyContent='flex-end'>
            <WizrButton
              icon={<RefreshIcon />}
              text={<StyledTextButton type='link'>refresh</StyledTextButton>}
              type='link'
              onClick={() => dispatchClearChat()}
            />
            { page === 'autosolve' ? <></> : <WizrButton
              text={<StyledTextButton type='link' >History</StyledTextButton>}
              type='link'
              onClick={onHistoryClick}
              disabled={hasAcces('view_prompt-history') === true || hasAcces('view_app-history') === true ? false : true}
            />}
            {page === 'autosolve' ? <WizrButton
              icon={<CloseIcon />}
              // text={<StyledTextButton type='link'>close</StyledTextButton>}
              type='link'
              onClick={onTestAreaClose}
            /> : <></>}
            {apptest ?
               page === 'autosolve' ? <></> : runtype === "single"  ? <Button shape='round' icon={<PlayIcon />} onClick={() => { setRuntype("bulk") }}>
                Run Batch
              </Button> : <Button shape='round' onClick={() => { setRuntype("single") }}>
                Single run
              </Button> : 
              <Button shape='round' icon={<PlayIcon />} onClick={() => { }}>
                Run Batch
              </Button>}
          </WizrFlexLayout></> : <></>}
      </StyledChatHeader>
      {runtype === "single" ? <StyledChatContainer bulkRun={bulkRun}>
        <div style={{ height: bulkRun ? "55vh" : appshare ? "67vh" : "40vh", overflow: "auto", padding: "10px" }} ref={chatContainerRef} >
          {chatMessage?.length > 0 &&
            chatMessage?.map((message, index) => (
              <StyledMessage key={index} isSent={message.isSent}>
                {!message.isSent && <StyledAvatar icon={<RobotIcon />} />}
                <StyledMessageBubble isSent={message.isSent}>
                  {message.isSent ? (
                    <WizrText textColor='white' type='body2'>
                      <WizrMarkDown>{message.text ? message?.text : "Generate"}</WizrMarkDown>
                    </WizrText>
                  ) : (
                    <>
                      <WizrText type='body2'>
                        {/* <div id='markDownContainer'> */}
                          {message.text
                            ? Array.isArray(message.text)
                              ? message.text.map((item: any, index: any) => <div key={index}>
                                 <WizrMarkDown>{item.data}</WizrMarkDown>

                                <div>
                                  <strong>Category:</strong> {item.metadata.category}<br />
                                  <strong>Folder ID:</strong> {item.metadata.folder_id}<br />
                                  <strong>Intent:</strong> {item.metadata.intent}<br />
                                  <strong>Tags:</strong> {item.metadata.tags}<br />
                                  <strong>Type:</strong> {item.metadata.type}<br />
                                </div>
                                {index < message.text.length - 1 && <hr />}</div>)   // Return item.data
                              :page === 'autosolve' ? 
                              <WizrMarkDown components={openingLinksMarkDown}>{String(message.text.response)}</WizrMarkDown> : <WizrMarkDown components={openingLinksMarkDown}>{String(message.text)}</WizrMarkDown>
                            : <WizrMarkDown>Please try again</WizrMarkDown>}
                        {/* </div> */}

                      </WizrText>
                      <WizrFlexLayout background='white' textAlign='flex-start' alignContent='flex-start' justifyContent='flex-start' alignItems='start' style={{ gap: '20px', flexWrap: 'wrap' }} overFlow='hidden'>
                        <WizrText type='body2'>Learn More:</WizrText>
                        {message.knowledgeBase && message.knowledgeBase.map((item: any) => {
                          // Accessing the keys of the object
                          const keys:any = Object.keys(item);
                          const keysToMap = keys.filter((key:any)=>key!=='score');
                          // Mapping over the keys to render buttons
                          return keysToMap.map((key:any, index:any) => (
                            <Button
                              style={{ width: 'auto' }}
                              key={index}
                              type='link'
                              icon={<WizrFlexLayout flexDirection='row' style={{ padding: '5px 10px', borderRadius: "16px", borderWidth: '1px', gap: '4px' }}>
                                <PdflogoIcon />
                                <WizrText type='body2'>{key ? key : item[key]}</WizrText>
                              </WizrFlexLayout>
                              }
                              onClick={() => handleButtonClick(item[key])} // Pass URL to the handler function

                            />
                          ));
                        })}

                      </WizrFlexLayout>
                      <WizrFlexLayout
                        flexDirection='row'
                        justifyContent='space-between'
                        alignContent='baseline'
                      >
                        <WizrFlexLayout flexDirection='row' justifyContent='flex-end' background='white' >
                          <Button
                            type='link'
                            icon={<Copy color={"#999B9F"} />}
                            onClick={() => {
                              antdMessage.success('Copied to clipboard', 2);
                              copy(message.text)
                            }} />

                          <Button
                            type='link'
                            shape='circle'
                            icon={<DislikeIcon />}
                            onClick={() => {
                              showModal(MODAL_TYPES.ADD_COMMENTS, {
                                onSubmit: (comment: string) => {
                                  handleAddComments(comment, 'dislike')
                                  hideModal()
                                },
                                onCancel: hideModal,
                              })
                            }}
                          />
                          <Button
                            type='link'
                            icon={<LikeIcon />}
                            onClick={() => {
                              showModal(MODAL_TYPES.ADD_COMMENTS, {
                                onSubmit: (comment: string) => {
                                  handleAddComments(comment, 'like')
                                  hideModal()
                                },
                                onCancel: hideModal,
                              })
                              setStatus('like')
                            }}
                          />
                        </WizrFlexLayout>
                      </WizrFlexLayout>
                    </>
                  )}
                </StyledMessageBubble>
              </StyledMessage>
            ))}</div>
        { page !== 'autosolve' ? <div className='inputwrapper' style={{ width: "100%" }}>
          <StyledInputContainer style={{ width: "100%" }}>
            {!(promptInputState?.llm_model?.model_type === 'completion') ?
              <StyledInput
                value={inputValue}
                disabled={
                  enableChat
                    ? false
                    : loading ||
                      (localStorage.getItem('prompt_id') && localStorage.getItem('action') !== 'copy') || bulkRun || appshare
                      ? false
                      : true
                }
                onChange={(e) => { setInputValue(e.target.value); mapValueToUserQuery(e.target.value) }}
                onPressEnter={() => handleSendMessage('user_query')}
                placeholder={loading ? 'Generating...' : 'Type a message...'}
                suffix={
                  stopGenerate && !appshare ?
                    (<Button icon={<Spin />} onClick={stopTestGeneration}>Stop Generate</Button>) :
                    appshare && stopGenerate ? <Button icon={<Spin />}></Button> :
                      <WizrButton
                        style={{ paddingLeft: "10px" }}
                        shape='circle'
                        disabled={
                          enableChat
                            ? false
                            : loading ||
                              (localStorage.getItem('prompt_id') && localStorage.getItem('action') !== 'copy') || bulkRun || appshare
                              ? false
                              : true
                        }
                        onClick={() => handleSendMessage('user_query')}
                        icon={<SentIcon />}
                      ></WizrButton>

                }
              /> : <></>}
          </StyledInputContainer></div>: <></>}
      </StyledChatContainer> : <BulkRunTest appDetails={appDetails} />}
      {/* </Markdown> */}
      {promptInputState?.llm_model?.model_type === 'completion' ? <WizrButton icon={<SentIcon />} text='Generate' onClick={() => handleSendMessage('button')}
        disabled={enableChat
          ? false
          : loading ||
            (localStorage.getItem('prompt_id') && localStorage.getItem('action') !== 'copy')
            ? false
            : true} style={{ width: "150px", marginLeft: "280px", marginBottom: "10px" }} />
        : <></>}
    </StyledChatWrapper>
  )
}

export default Chat
