import { Typography } from 'antd'
import { on } from 'events'
import { styled } from 'styled-components'

const { Text } = Typography

type ITextType =
  | 'h6'
  | 'sub1'
  | 'sub2'
  | 'body1'
  | 'body2'
  | 'button'
  | 'body3'
  | 'overline'
  | 'caption'
  | 'link'
  | 'body4'
  | 'hint'
  | 'h7'
  | "h4"
  | "body6"
  | "h8"
  | "cardData"
  | "caption2"
  | 'tagData'
  | 'h5'
  | 'h3'
  | 'caption_large'
  | 'caption_normal'
  | 'captionLarge'

interface IWizrText {
  children: any
  type: ITextType
  textColor?: string | undefined
  style?: any
  onClick?: any
  sizeFont?: string
}

const titleMapping: { [key: string]: any } = {
  h6: {
    color: '#1F1D1D',
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    fontWeight: 500,
  },
  sub1: {
    color: '#1F1D1D',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.15px',
    fontWeight: 600,
  },
  sub2: {
    color: '#1F1D1D',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.1px',
    fontWeight: 600,
  },
  body1: {
    color: '#1F1D1D',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    fontWeight: 500,
  },
  body2: {
    color: '#1F1D1D',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.25px',
    fontWeight: 500,
  },
  button: {
    color: '#FFF',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0px',
    fontWeight: 500,
  },
  body3: {
    color: '#1F1D1D',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    fontWeight: 500,
  },
  overline: {
    color: '#1F1D1D',
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '1.5px',
    fontWeight: 500,
  },
  caption: {
    color: '#999B9F',
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    fontWeight: 400,
  },
  link: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '0.25px',
    textDecoration: 'underline',
  },
  body4: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '18px',
    letterSpacing: '0.25px',
  },
  hint: {
    fontSize: '12px',
    fontStyle: 'italic',
    fontWeight: '400',
    lineHeight: '16px' /* 133.333% */,
    letterSpacing: '0.4px',
  },
  h7: {
    color: 'var(--grayscale-800, #1D1F22)',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '22px',
    letterSpacing: '0.15px',
  },
  h4: {
    color: 'var(--grayscale-800, #1D1F22)',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Montserrat",
    fontSize: "34px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "48px",
    letterSpacing: "0.25px"
  },
  body6: {
    color: 'blue',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.25px',
    fontWeight: 400,
  },
  h8: {
    color: '#1F1D1D',
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    fontWeight: 550,
  },
  cardData: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    letterSpacing: " 0.25px",
    textAlign: "left",
  },
  caption2: {
    color: '#1D1F22',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    fontWeight: 400,
  },
  caption_normal: {
    color: '#1D1F22',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    fontWeight: 400,
  },
  tagData: {
    fontFamily: 'Montserrat',
    fontSize: '10px',
    fontWeight: '700',
    lineHeight: '16px',
    letterSpacing: '1.5px',
    textAlign: 'right',

  },
  h5: {
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'center'


  },
  h3: {
    fontFamily: 'Montserrat',
    fontSize: '48px',
    fontWeight: '400',
    lineHeight: '56px',
    textAlign: 'left'

  },
  caption_large: {
    //styleName: Caption/Large;
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "16px",
    letterSpacing: "0.4000000059604645px",
    textAlign: "center"

  },
  captionLarge: {
    fontSize: '0.75rem', // 12px
    fontWeight: 600,
    lineHeight: '1rem', // 16px
    letterSpacing: '0.025rem', // 0.4px
  }
}
const StyledText = styled(Text) <any>`
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Montserrat;
  font-style: ${(p) => (p.fontStyle ? p.fontStyle : 'normal')};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : 300)};
  color: ${(p) => (p.color ? p.color : '#000')};
  font-size: ${(p) => (p.fontSize ? p.fontSize : '12px')};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : '0px')};
  letter-spacing: ${(p) => (p.letterSpacing ? p.letterSpacing : '0px')};
  text-decoration: ${(p) => (p.textDecoration ? p.textDecoration : 'none')};
`

const WizrText = ({ children, type, textColor, style, onClick, sizeFont }: IWizrText) => {
  const headerTextProps = titleMapping[type]
  const { color, fontSize, lineHeight, letterSpacing, fontWeight, textDecoration, fontStyle } =
    headerTextProps

  return (
    <StyledText
      style={style}
      color={textColor || color}
      fontSize={fontSize}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      fontWeight={fontWeight}
      textDecoration={textDecoration}
      fontStyle={fontStyle}
      onClick={onClick}
      sizeFont={sizeFont}
    >
      {children}
    </StyledText>
  )
}

export default WizrText
