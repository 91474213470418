import { useLocation, useNavigate, useParams } from "react-router-dom"
import WizrButton from "../../../components/WizrButton"
import WizrFlexLayout from "../../../components/WizrFlexLayout"
import WizrText from "../../../components/WizrText"
import WizrUserJourney from "../../../components/WizrUserJourney"
import Hamburger from "../../../assets/icons/Hamburger"
import CreateNew from "../../../assets/icons/CreateNew"
import WizrTable from "../../../components/WizrTable"
import { useFeatureSettingSelector } from "../../../redux/selectors/content"
import styled from "styled-components"
import { useEffect } from "react"
import { dispatchFeatureSettings } from "../../../redux/actions/content"
import { MODAL_TYPES, useWizrModalContext } from "../../../providers/modal/WizrModalProvider"

const StyledWizrTable = styled(WizrTable)`
  .ant-table-wrapper {
    width: 100% !important;
  }
  .ant-table-thead {
    background-color: #f2f4f9;
    border-spacing: 0px;
  }
  .ant-table-tbody > tr {
    background-color: #ffffff;
    margin-bottom: 0px;
  }

  .ant-table table {
    border-spacing: 0 5px;
  }
`

const DataListingGroup= () => {
  useEffect (()=>
  {
    dispatchFeatureSettings()
  },[])
    const navigate = useNavigate()
    const location =useLocation()
    // const folder = location.state.files
    const featureSetting = useFeatureSettingSelector()
    const { showModal, hideModal } = useWizrModalContext()

    const ticketData = featureSetting?.ticket_settings
    const params = useParams()
    const title = params?.title   
    const folderId = params?.id // const display_name = 
    const foldersArray :any=ticketData && Object.values(ticketData).filter((item:any) => {
        if (item.ticket_type===title){
            return item.folders
         }});
         const data = foldersArray && foldersArray[0]?.folders?.map((item: any) => ({
          ...item,
          group: foldersArray[0]?.ticket_type,
        }))
        const FolderData = data && data?.filter((item: any) => {
          if (item.folder_id===folderId){
            return item
         }
        })
        const tableData = FolderData && FolderData[0]?.data
        const dataFinal  = FolderData && FolderData[0]?.data.filter((item:any)=>{
          if(item?.status==="active"){
            return item
          }
         
        })
   const renderBody =( ) :JSX.Element=>{
    switch (title) {
        case 'categories_intent':
            return <StyledWizrTable type='intentcategorytable' dataSource={dataFinal && dataFinal.sort((a:any,b:any)=>b.created_on-a.created_on)} pagination={false} size='small' />
        case 'sentiment':
            return <StyledWizrTable type='sentimenttable' dataSource={dataFinal &&dataFinal.sort((a:any,b:any)=>b.created_on-a.created_on)} pagination={false} size='small' />
        case 'tags':
            return <StyledWizrTable type='tagkeywordtable' dataSource={dataFinal &&dataFinal.sort((a:any,b:any)=>b.created_on-a.created_on)} pagination={false} size='small' />
        case 'priority':
            return <StyledWizrTable type='tickettable' dataSource={dataFinal &&dataFinal.sort((a:any,b:any)=>b.created_on-a.created_on)} pagination={false} size='small' />
        case 'type'  :
            return <StyledWizrTable type='tickettypetable' dataSource={dataFinal &&dataFinal.sort((a:any,b:any)=>b.created_on-a.created_on)} pagination={false} size='small' />
         default :
            return <></>
        
    }

 }
 const onCreateAction = ()=>{
  showModal(MODAL_TYPES.CREATE_TICKET_TYPE, {
    type:title,
    folder:folderId,
    action:'create',
    onCancel:hideModal
  })

 }
    return (
        <WizrFlexLayout alignContent="flex-start" >
        <WizrFlexLayout justifyContent="space-between" flexDirection="row" flex={1.4}>
<WizrUserJourney
      userJourney={[
        {
          title: (
            <WizrButton
              padding='0px'
              type='text'
              text={<WizrText type='h6' textColor="#707276">Cx Settings</WizrText>}
              onClick={() => navigate(-2)}
            icon={<Hamburger  />}
            />
          ),
        },
        {
          title:  (
            <WizrButton
              padding='0px'
              type='text'
              text={<WizrText type='h6' textColor="#707276">{foldersArray && foldersArray[0]?.display_name}</WizrText>}
              onClick={() => navigate(-1)}
            />
          ),
          isCurrent: true,
        },
        {
            title:  (
              <WizrButton
                padding='0px'
                type='text'
                text={<WizrText type='h6'>{FolderData && FolderData[0]?.folder_name}</WizrText>}
                onClick={() => {}}
              />
            ),
            isCurrent: true,
          },
      ]}
    />               
    <WizrButton type="primary" icon={<CreateNew fill={'white'}/>} style={{marginTop:"10px",borderRadius:"25px"}} 
                  text={<WizrText type='button'>{`Add new`}</WizrText>} onClick={()=>{
                    onCreateAction()
                  }}> </WizrButton>
     </WizrFlexLayout>
     <WizrFlexLayout flex={11} >
{renderBody()}
     </WizrFlexLayout>

      </WizrFlexLayout>
    )
}

export default DataListingGroup