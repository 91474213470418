import React, { useEffect, useMemo, useState } from 'react'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrText from '../components/WizrText'
import WizrUserJourney from '../components/WizrUserJourney'
import WizrButton from '../components/WizrButton'
import styled from 'styled-components'
import { Card, Radio, RadioChangeEvent,  MenuProps, Popover, DatePicker } from 'antd'
import WizrTag from '../components/WizrTags'
import WizrIcon from '../components/WizrIcon'
import { dispatchCommonTags, dispatchFiltervalues, dispatchFirstContactResolution, dispatchIntentsGrpahData, dispatchIntentsList, dispatchTicketBasedOnIntent, dispatchTicketEscallation, dispatchTimetoResolve, dispatchTimetoResponse, dispatchTopDecreasedIntentQuery, dispatchTopIncreasedIntenetQuery, dispatchTopIntentsQuery, dispatchTotalIntentsCountQuery } from '../redux/actions/content'
import { useTicketsBasedonIntent, useControlroomFilter } from '../redux/selectors/content'
import { getDayRange, getMonthRange, getWeekRange } from '../utils'
import { MODAL_TYPES, useWizrModalContext } from '../providers/modal/WizrModalProvider'
import DashboardScreen from './components/CXControlHubComponents/DashboardScreen'
import DiscoverScreen from './components/CXControlHubComponents/DiscoverScreen'
import TicketListing from './components/CXControlHubComponents/TicketListing'
import { useNavigate } from 'react-router-dom'
import { QueryForCommonTags, QueryForFirstContactResolution, QueryForIntentsDetailedView, QueryForTicketResolution, QueryForTimeToResolve, QueryForTimeToResponse, QueryForTopDecreasedIntents, QueryForTopIncreasedIntents, QueryForTopIntents, QueryForTotalIntentsCount, graphData, } from '../utils/controlRoomQueries/queries'


type IntervalName = '1 day' | '1 week' | '1 month' | 'Custom';

const ControlRoom = () => {


  const { showModal, hideModal } = useWizrModalContext()
  const uniqueTagList = ['1 day', '1 week', '1 month', 'Custom']

  const [selectedTab, setSelectedTab] = useState('dashboards');
  const [ticketSort, setTicketSort] = useState('DESC')
  const [interval, setInterval] = useState('1 month');

  //view the ticket listing screen when 'viewFullTicketSetting' true
  const [viewFullTicketSetting, setViewFullTicketSetting] = useState(false)

  const [activeCollaps, setActiveCollapse] = useState(['Product Reviews'])
  const [showDatePicker, setShowDatePicker] = useState(false);

  const getButtonStyle = (value: any) => {
    if (value === selectedTab) {
      return {
        backgroundColor: '#1D1F22',
        borderColor: '#1D1F22',
        color: '#fff',
      };
    }
    return {};
  };

  const onTabChange = (e: RadioChangeEvent) => {
    setViewFullTicketSetting(true)
    setSelectedTab(e.target.value);
  }

  const onClickIntentsandsentiments = (IntentValue: any) => {
    if (IntentValue) {
      setActiveCollapse([IntentValue?.Intent])
      setViewFullTicketSetting(false)
      const defaultFilter = {
        Intent: [`${IntentValue?.Intent}`]
      }
      dispatchFiltervalues(defaultFilter)
    } else {
      setActiveCollapse([])
    }
    setSelectedTab('discover')
  }

  const [range, setRange] = useState({
    currentMonthStartDate: 1675189800000,
    currentMonthEndDate: 1682879400000,
    previousMonthStartDate: 1669833000000,
    previousMonthEndDate: 1675189800000
  });


  const getDateRangeFilter = (name: IntervalName) => {

    setInterval(name)
    let newRange: any;

    switch (name) {
      case '1 day':
        newRange = getDayRange();
        setShowDatePicker(false);
        break;
      case '1 week':
        setShowDatePicker(false);
        newRange = getWeekRange();
        break;
      case '1 month':
        setShowDatePicker(false);
        newRange = getMonthRange();
        break;
      case 'Custom':
        setShowDatePicker(true);
        break;
      default:
        throw new Error('Invalid date range specified');
    }
    setRange(newRange);
  }


  const ticketsBasedOnIntents = useTicketsBasedonIntent() || []
  
  const ticketsBasedOnIntentsArray = useMemo(() => {
    // Extract rows and columns inside useMemo
    const rows = ticketsBasedOnIntents?.resultTable?.rows || [];
    const columns = ticketsBasedOnIntents?.resultTable?.dataSchema?.columnNames || [];

    return rows.map((row: any) => {
      const obj: any = {};
      columns.forEach((columnName: any, index: any) => {
        obj[columnName] = row[index];
      });
      return obj;
    });
  }, [ticketsBasedOnIntents])

  const controlrromFilter = useControlroomFilter();
  

  // useEffect(() => {
  //   console.log('use');
  //   onFormSubmission(controlrromFilter)
  // }, [ticketSort])



  const handleCollapseChange = (activeKeys: any) => {
    setActiveCollapse(activeKeys)
    const filteredValue = {
      Intent: [`${activeKeys}`]
    }
    dispatchFiltervalues(filteredValue)
  }

  const filterModal = () => {
    showModal(MODAL_TYPES.CONTROLROOM_FILTER, {
      onCancel: hideModal,
    })
  }

  const sentimentDropdownItems: MenuProps['items'] = [
    {
      key: '1',
      label: 'Positive',
    },
    {
      key: '2',
      label: 'Neutral',
    },
    {
      key: '3',
      label: 'Negative',
    },
  ]

  const handleCustomDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    const millisecDates = dateStrings.map((date: any) => {
      const a = new Date(date)
      a.setHours(0, 0, 0, 0);
      return a.getTime();
    });
  };


  useEffect(() => {
    //apis for customer intents and sentiments
    //apis are called for every chnages in the 'range', 
    dispatchTopIntentsQuery(QueryForTopIntents(range))
    dispatchTopIncreasedIntenetQuery(QueryForTopIncreasedIntents(range))
    dispatchTopDecreasedIntentQuery(QueryForTopDecreasedIntents(range))
    dispatchTotalIntentsCountQuery(QueryForTotalIntentsCount(range))
  }, [range])

  useEffect(() => {
    //apis for ticket resolution analytics
    //called only once
    dispatchTimetoResponse(QueryForTimeToResponse())
    dispatchTimetoResolve(QueryForTimeToResolve())
    dispatchTicketEscallation(QueryForTicketResolution())
    dispatchFirstContactResolution(QueryForFirstContactResolution())
  }, [])

  useEffect(() => {
    dispatchIntentsGrpahData(graphData(activeCollaps))
    dispatchIntentsList(QueryForIntentsDetailedView(range))
    dispatchCommonTags(QueryForCommonTags(activeCollaps))
    onFormSubmission(controlrromFilter)
  }, [activeCollaps])

  const onFormSubmission = (newFilterValues: any) => {
    let sqlQuery = "SELECT * FROM ecommerce_analytics WHERE (Created_date_epoch_millis BETWEEN 1711909800000 AND 1712514600000) AND ";
    //the field values
    const queryObj = newFilterValues;
    // Loop through the keys and values in the object
    Object.entries(queryObj).forEach(([key, values]: any, index) => {
      // Add the opening parenthesis for each key
      sqlQuery += "(";
      // Loop through the values for each key and join them with OR condition
      values.forEach((value: any, i: any) => {
        // Add the key and value to the query
        sqlQuery += `${key} = '${value}'`;
        // Add OR condition if it's not the last value
        if (i < values.length - 1) {
          sqlQuery += " OR ";
        }
      });
      // Add the closing parenthesis for each key
      sqlQuery += ")";
      // Add AND condition if it's not the last key
      if (index < Object.keys(queryObj).length - 1) {
        sqlQuery += " AND ";
      }
    });
    const filteredQuery = {
      sql: `${sqlQuery} ORDER BY Created_date_epoch_millis ${ticketSort}`
    }
    dispatchTicketBasedOnIntent(filteredQuery)
    dispatchFiltervalues(queryObj)
  }


  return (
    <div style={{ minHeight: '100vh', overflow: 'auto', overflowY: 'auto', paddingBottom: '20px', width: '100%' }} >
      <div >
        <WizrFlexLayout alignContent='flex-start' flexDirection='row' justifyContent='space-between'>
          <WizrFlexLayout>
            <WizrUserJourney
              userJourney={[
                {
                  title: <WizrButton type='text' text={<WizrText type='h6'>CX Control Room</WizrText>} />,
                  isCurrent: true,
                },
                {

                }
              ]}
            />
          </WizrFlexLayout>
          <WizrFlexLayout alignItems='flex-end' flexDirection='row' alignContent='flex-end' justifyContent='center'>
            <Radio.Group onChange={onTabChange} value={selectedTab} style={{ marginBottom: 16 }}>
              <Radio.Button value="dashboards" style={getButtonStyle('dashboards')}>
                Dashboard
              </Radio.Button>
              <Radio.Button value="discover" style={getButtonStyle('discover')}>
                Discover
              </Radio.Button>
            </Radio.Group>
          </WizrFlexLayout>
          <WizrFlexLayout alignItems='center' flexDirection='row' alignContent='flex-end' justifyContent='end' >
            <WizrTag
            checkedValue={2}
              data={uniqueTagList}
              onClick={(name: any) => {
                getDateRangeFilter(name)
              }}
              background={'#EAECF1'}
              textColor='1D1F22'
              checkable={true}
            />
            {showDatePicker ? <Popover content={<CreateAppContent />} title='' trigger='click'>
              <DatePicker.RangePicker onChange={handleCustomDateRangeChange} />
            </Popover> : <></>}
            <WizrIcon name='settings' />
          </WizrFlexLayout>
        </WizrFlexLayout>
      </div>
      {selectedTab === 'dashboards' ? (
        <DashboardScreen
          onClickIntentsandsentiments={onClickIntentsandsentiments}
          range={range}
          interval={interval}
        />
      ) : (
        viewFullTicketSetting ? (
          <TicketListing activeCollaps={activeCollaps} ticketSort={ticketSort} setTicketSort={setTicketSort} filterModal={filterModal} />
        ) : (
          <DiscoverScreen
            setSelectedTab={setSelectedTab}
            activeCollaps={activeCollaps}
            filterModal={filterModal}
            sentimentDropdownItems={sentimentDropdownItems}
            handleCollapseChange={handleCollapseChange}
            ticketsBasedOnIntentsArray={ticketsBasedOnIntentsArray}
            ticketSort={ticketSort}
            setTicketSort={setTicketSort}
            setViewFullTicketSetting={setViewFullTicketSetting}
            onFormSubmission={onFormSubmission}
          />
        )
      )}

    </div>
  )
}

export default ControlRoom

const CreateAppContent = (): JSX.Element => {
  const { showModal, hideModal } = useWizrModalContext()
  const navigate = useNavigate()

  const handleCustomDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    // Handle custom date range change here
  };

  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      <DatePicker.RangePicker onChange={handleCustomDateRangeChange} />
      {/* <WizrButton text='Tools' type='text' onClick={onCreate} justifyContent='flex-start' />
      <WizrButton text='Chat Bot' type='text' onClick={() => {}} justifyContent='flex-start' /> */}
    </WizrFlexLayout>
  )
}