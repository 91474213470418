// import { dispatchListFolders, dispatchCreateFolder } from '../redux/actions/content'
// import { useContentsSelector, useContentLoadingSelector, useFaqDataSelector } from '../redux/selectors/content'
// import { useEffect } from 'react'
// import WizrTable from '../components/WizrTable'
// import { Card, Spin } from 'antd'
// import WizrFlexLayout from '../components/WizrFlexLayout'
// import WizrUserJourney from '../components/WizrUserJourney'
// import WizrButton from '../components/WizrButton'
// import { useWizrModalContext, MODAL_TYPES } from '../providers/modal/WizrModalProvider'
// import CreateNew from '../assets/icons/CreateNew'
// import { dispatchNotificationState } from '../redux/actions/common'
// import WizrText from '../components/WizrText'
// import { useAuth } from '../authContext';
// import styled from 'styled-components'
// import { pastTickets } from '../utils'

// const FAQ = (): JSX.Element => {
//   const { hasAcces } = useAuth();
//   const contentsListing = pastTickets
//   const isContentLoading = useContentLoadingSelector()
//   const { showModal, hideModal } = useWizrModalContext()

//   useEffect(() => {
//      dispatchListFolders('faq')
//   }, [])

// const faqData =useFaqDataSelector()

//   return (
//     <WizrFlexLayout>
//       <WizrFlexLayout justifyContent='space-between' alignItems='center' flexDirection='row'>
//         <WizrUserJourney
//           userJourney={[
//             {
//               title: <WizrButton type='text' text={<WizrText type='h6'>FAQ</WizrText>} />,
//               isCurrent: true,
//             },
//           ]}
//         />
//           <WizrButton
//           icon={<CreateNew fill='white' />}
//           text='Add group'
//           disabled={hasAcces('create_folder') === true ? false : true}
//           onClick={() => {}
//             // showModal(MODAL_TYPES.CREATE_UPDATE_FOLDER, {
//             //   onFormSubmission: ({ currentFolderName, currentFolderDescription }: any) => {
//             //     const isExistingName = contentsListing?.find(
//             //       (content: any) => currentFolderName === content?.folder_name,
//             //     )
//             //     if (!isExistingName) {
//             //       hideModal()
//             //       dispatchCreateFolder({ currentFolderName, currentFolderDescription })
//             //     } else {
//             //       dispatchNotificationState({
//             //         message: 'Error',
//             //         description: `${currentFolderName} already exists. Please try different name.`,
//             //         type: null,
//             //       })
//             //     }
//             //   },
//             //   onCancel: hideModal,
//             // })
//           }
//         />        
//       </WizrFlexLayout>
//       <WizrFlexLayout flex={10} >
//         {isContentLoading ? (
//           <Spin />
//         ) : (
//           <WizrFlexLayout flexDirection='row' style={{gap: '5px'}}>
//           <WizrTable type='past_tickets' dataSource={faqData} pagination={false} size='small' />
//           {/* <StyledCard>hii</StyledCard> */}
//           </WizrFlexLayout>
//         )}
//       </WizrFlexLayout>
//     </WizrFlexLayout>
//   )
// }

// export default FAQ

// const StyledCard = styled(Card)`
// display: flex;
// width: 23%;

// flex-direction: row;

// border-radius: 16px;
// background: var(--generic-white, #FFF);
// `



import { dispatchListFolders, dispatchCreateFolder, dispatchCreateFodlerFaqPt } from '../redux/actions/content'
import { useContentsSelector, useContentLoadingSelector, useFaqDataSelector, useAutoTagListSelector } from '../redux/selectors/content'
import { useEffect } from 'react'
import WizrTable from '../components/WizrTable'
import { Spin } from 'antd'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrUserJourney from '../components/WizrUserJourney'
import WizrButton from '../components/WizrButton'
import { useWizrModalContext, MODAL_TYPES } from '../providers/modal/WizrModalProvider'
import CreateNew from '../assets/icons/CreateNew'
import { dispatchNotificationState } from '../redux/actions/common'
import WizrText from '../components/WizrText'
import { useAuth } from '../authContext';
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'


const FAQ = (): JSX.Element => {
  const { hasAcces } = useAuth();
  const contentsListing = useFaqDataSelector()
  const isContentLoading = useContentLoadingSelector()
  const { showModal, hideModal } = useWizrModalContext()
  const autoTagData = useAutoTagListSelector()
  const navigate = useNavigate()

  useEffect(() => {
    dispatchListFolders('faq')

  }, [])

const onAddGroup =async () => {
  showModal(MODAL_TYPES.CREATE_UPDATE_PASTTICKKETS, {
    onFormSubmission: async ({ TicketFolderName, TicketFolderDescription, AutoTag,embeddingModel }: any) => {
        
      const tenantFeatureId = autoTagData?.find((item:any)=>item?.app_feature_id===AutoTag)?.tenant_feature_id
      const isExistingName = contentsListing?.find(
        (content: any) => TicketFolderName === content?.folder_name,
      )
      if  (!isExistingName) {
        const payload ={
          folder_id: uuidv4(),
          app_feature_id:AutoTag,
          folder_name: TicketFolderName,
          tenant_feature_id:tenantFeatureId,
          folder_description: TicketFolderDescription ? TicketFolderDescription : '',
          embedding_model_id:embeddingModel,
          created_at: Date.now(),
          type: 'faq',
        }
        await dispatchCreateFodlerFaqPt(payload)
        dispatchListFolders('faq')

        hideModal()
      } else {
        dispatchNotificationState({
          message: 'Error',
          description: `${TicketFolderName} already exists. Please try different name.`,
          type: null,
        })
      }
    },
    onCancel: hideModal,
  })

}

  return (
    <WizrFlexLayout>
      <WizrFlexLayout justifyContent='space-between' alignItems='center' flexDirection='row' flex={1.5}>
        <WizrUserJourney
          userJourney={[
            {
              title: <WizrButton type='text' text={<WizrText type='h6'>FAQ</WizrText>} onClick={()=>navigate(-1)}/>,
              isCurrent: true,
            },
          ]}
        />
          <WizrButton
          style={{borderRadius:"18px"}}
          type='primary'
          icon={<CreateNew fill='white' />}
          text='Create New'
          disabled={hasAcces('create_folder') === true ? false : true}
          onClick={() =>
            onAddGroup()
            }
        />        
      </WizrFlexLayout>
      <WizrFlexLayout flex={11}>
        {isContentLoading ? (
          <Spin />
        ) : (
          <WizrTable type='faq' dataSource={contentsListing} pagination={false} size='small' />
        )}
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

export default FAQ
