import { Checkbox, Popover } from 'antd'
import WizrButton from '../WizrButton'
import WizrFlexLayout from '../WizrFlexLayout'
import { useWizrModalContext, MODAL_TYPES } from '../../providers/modal/WizrModalProvider'
import CreateNew from '../../assets/icons/CreateNew'
import { useNavigate } from 'react-router-dom'
import { dispatchCreateApp } from '../../redux/actions/content'
import styled from 'styled-components'
import { useAuth } from '../../authContext'
import WizrInput from '../WizrInput'
import WizrIcon from '../WizrIcon'
import { useEffect, useState } from 'react'
import Search from '../../assets/icons/Search'
import WizrCheckbox from '../WizrCheckbox'
import WizrFlexSpacing from '../WizrFlexSpacing'
import { set } from 'react-hook-form'

interface IActionPopoverContent {
  items?: { id: number, label: string, checked: boolean }[];
  applyFilters?: any;
  placeHolder?: any;
  appliedFilterLength?: any
  singleSelect?: any
  filterName?: any
}

const FilterContent = ({ items, applyFilters, selectedItems, singleSelect }: any): JSX.Element => {  

  const [searchValue, setSearchValue] = useState('')
  const [checkboxItems, setCheckboxItems] = useState<any[]>(items);
  const [filteredItems, setFilteredItems] = useState<any[]>([])

  const handleCheckboxChange = (id: any) => {
    let updatedItems
    let updatedFilterItems
    if (singleSelect) {
      updatedItems = checkboxItems.map((item: any) =>
        item.id === id && item.checked === false ? { ...item, checked: true } : { ...item, checked: false }
      );
      updatedFilterItems = filteredItems.map((item: any) =>
        item.id === id && item.checked === false ? { ...item, checked: !item.checked } : { ...item, checked: false }
      );
    }
    else {
      console.log('else', checkboxItems);
      
      updatedItems = checkboxItems.map((item: any) =>
        item.id === id ? { ...item, checked: !item.checked } : item
      );
      updatedFilterItems = filteredItems.map((item: any) =>
        item.id === id ? { ...item, checked: !item.checked } : item
      );
    }
    console.log('updatedItems', updatedItems);
    setCheckboxItems(updatedItems);
    setFilteredItems(updatedFilterItems);
  };

  useEffect(() => {
    setFilteredItems(items)
  }, [items])

  const applyFilter = () => {
    const selectedItems = checkboxItems.filter((item: any) => item.checked);
    applyFilters(selectedItems)
  }

  const searchInsisdePopover = (value: any) => {
    setSearchValue(value)
    if (value === '') {
      setFilteredItems(checkboxItems);
      return;
    }
    const filteredValues = checkboxItems.filter((item: any) =>
      item.label.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filteredValues);
  }

  const reset = () => {
    setSearchValue('')
    const resetItems = checkboxItems.map((item: any) => ({ ...item, checked: false }));
    setCheckboxItems(resetItems);
    setFilteredItems(resetItems);
    setSearchValue('');
  }

  return (
    <WizrFlexLayout textAlign='left' background='#FFF' style={{ gap: '16px', width: '100%' }}>
      <StyledWizrInputSearch placeholder={singleSelect ? 'You can select only one feature here' : 'Search for filters'} value={searchValue} onChange={(value: any) => { searchInsisdePopover(value) }} preComponent={<Search />} background='#FFF' />
      <WizrFlexLayout background='#FFF' justifyContent='flex-start' style={{ gap: '8px', maxHeight: '90px', overflow: 'auto' }}>
        {filteredItems?.map((item: any, key: number) => {
          return (
            <WizrCheckbox key={key} text={item.label} isEnabled={item.checked} onChange={() => handleCheckboxChange(item.id)} />
          )
        })}
      </WizrFlexLayout>
      <WizrFlexLayout justifyContent='flex-end' flexDirection='row' background='#FFF' style={{ padding: '8px' }}>
        <WizrButton type='text' text='Reset' onClick={() => { reset() }} style={{ borderRadius: '20px' }} />
        <WizrButton text={'Apply Filters'} onClick={() => applyFilter()} style={{ borderRadius: '20px' }} />
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

const FilterPopover = ({ items, applyFilters, placeHolder, appliedFilterLength, singleSelect = false, filterName }: IActionPopoverContent): JSX.Element => {

  console.log('items', items);
  
  // const [appliedFilterLength, setAppliedFilterLength] = useState(0)
  const [open, setOpen] = useState(false);

  const handleAppliedFilters = (value: any) => {
    setOpen(false);
    applyFilters(value)
    // setAppliedFilterLength(value?.length);
  }

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const trimFilterName = (filterName: string) => { 
    return filterName.length > 8 ? filterName.substring(0, 8) + '...' : filterName;
  };


  return (
    <Popover content={<FilterContent items={items} applyFilters={handleAppliedFilters} singleSelect={singleSelect} />} title='' trigger='click' open={open} style={{ padding: '8px' }} onOpenChange={handleOpenChange}>
      <StyledWizrInputWrapper  >
        {appliedFilterLength > 0 ? (
          <div style={{ display: "flex", justifyContent: "flex-start", gap: '5px' }}>
            <StyledPlaceHolder>{placeHolder}: </StyledPlaceHolder>
            {singleSelect ? <StyledFilterCount style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '90%' }}>{trimFilterName(filterName)}</StyledFilterCount>
              : <StyledFilterCount>{appliedFilterLength === items?.length ? 'All' : appliedFilterLength}</StyledFilterCount>}
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "flex-start", gap: '5px' }}>
            <StyledPlaceHolder>{placeHolder} </StyledPlaceHolder>
          </div>
        )}
        <WizrIcon name='arrowDown' />

      </StyledWizrInputWrapper>
    </Popover>
  )
}

export default FilterPopover

const StyledWizrInput = styled(WizrInput)`
width: 125px;
height: 32px;
padding: 8px 8px 8px 8px;
border-radius: 8px;
background: #FFF;
&.ant-input-affix-wrapper{
border: 0px solid var(--grayscale-300, #DCDEE2);
height:20px;
}
`

const StyledWizrInputSearch = styled(WizrInput)`
height: 40px;
padding: 8px 8px 8px 8px;
border-radius: 0px;
border: 1px solid var(--grayscale-300, #DCDEE2);
background: #fff;
`

const StyledWizrInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 132px;
  height: 32px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid var(--grayscale-300, #DCDEE2);
  background: #FFF;
  justify-content:space-between;
`;

const StyledPlaceHolder = styled.span`
  color: var(--placeholder-color, #666); /* Define your placeholder color */
`;

const StyledFilterCount = styled.span`
  color: var(--filter-count-color,  #00C8CA); /* Define your filter count color */
`;
