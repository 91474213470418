import { styled } from 'styled-components'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import WizrButton from '../components/WizrButton'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrFlexSpacing from '../components/WizrFlexSpacing'
import WizrInput from '../components/WizrInput'
import WizrTagInput from '../components/WizrTagInput'
import WizrText from '../components/WizrText'
import WizrTextArea from '../components/WizrTextArea'
import WizrCheckbox from '../components/WizrCheckbox'
import WizrToggleButton from '../components/WizrToggleButton'
import WizrDropDown from '../components/WizrDropDown'
import WizrSlider from '../components/WizrSlider'
import Chat from '../components/WizrChat'
import {
  disaptchPromptTest,
  dispatchClearChat,
  dispatchGetPromptDetails,
  dispatchGetPromptTemplateDetails,
  dispatchLLMModels,
  dispatchListFolders,
  dispatchManagePrompt,
  dispatchPromptData,
  dispatchPublishPrompt,
  dispatchTags,
  dispatchUploadFileUsingLink
} from '../redux/actions/content'
import { MODAL_TYPES, useWizrModalContext } from '../providers/modal/WizrModalProvider'
import PromptCopyEdit from '../components/popovers/PrompyCopyEdit'
import { Button, Input, Tooltip } from 'antd'
import InfoIcon from '../assets/icons/Info'
import WizrUserJourney from '../components/WizrUserJourney'
import {
  useChatMessageSelector,
  useContentLoadingSelector,
  useContentsSelector,
  useFileParams,
  useLLMModelSelector,
  usePromptDetailsSelector,
  useTagsSelector
} from '../redux/selectors/content'
import ExampleActionPopover from '../components/popovers/ExampleAction'
import EditIcon from '../assets/icons/EditIcon'
import DeleteIcon from '../assets/icons/DeleteIcon'
import { dispatchNotificationState } from '../redux/actions/common'
import { uploadFile } from '../api/helpers/contents'
import WizrUploadContainer from '../components/WizrUploadButton'
import {
  WizrAzurOrchestratorInstance,
  wizrAzureConnectorInstance,
} from '../utils/wizrAzureConnector'
import { getFileType } from '../utils'
import { FilePdfFilled } from '@ant-design/icons'

const findllmModalType = (value: any, LLMModelData: any) => {
  const selectedModel = LLMModelData.find((obj: any) => obj.id === value)
  
  return selectedModel?.modal_type
}

const transformEditDataToPromptInput = (editPromptData: any, LLMModelData: any) => {
  
  const prompt_id = localStorage.getItem('prompt_id')

  const promptList = editPromptData?.prompts ? editPromptData?.prompts : []
  const extractedPromptDetail = editPromptData?.prompts?.filter(
    (data: any) => data?.parent_prompt,
  )?.[0]

  const parameterData = editPromptData?.prompt_body?.message_params?.map((item: any) => {
    return { parameterType: item?.type, modalTags: item?.value, name: item?.name }
  })
  const transformedPromptData = {
    name:
      localStorage.getItem('action') === 'copy'
        ? 'Copy of ' + editPromptData?.name
        : editPromptData?.name,
    description: editPromptData?.description,
    tags: editPromptData?.tags,
    systemMessage:
      editPromptData?.prompt_body?.system_message ,
    zero_shot:
      editPromptData?.prompt_body?.zero_shot ,
    examples: editPromptData?.prompt_body?.examples,
    knowledgebase_required:
      editPromptData?.prompt_body?.knowledgebase_required ,
    folderList:
      editPromptData?.prompt_body?.folder_id_list ,
    temperature:
      editPromptData?.prompt_body?.temperature ,
    top_p: editPromptData?.prompt_body?.top_p ,
    frequency_penalty:
      editPromptData?.prompt_body?.frequency_penalty ,
    presence_penalty:
      editPromptData?.prompt_body?.presence_penalty ,
    stop_sequences:
      editPromptData?.prompt_body?.stop_sequences ,
    max_tokens:
      editPromptData?.prompt_body?.max_tokens ,
    llm_model: {
      id: editPromptData?.prompt_body?.llm_model_id
        ? editPromptData.prompt_body.llm_model_id
        : '820b7f6f-9d64-4a8e-b82c-31b64570a2f2',
      type: editPromptData?.prompt_body?.llm_type
        ? editPromptData.prompt_body.llm_type
        : 'azureopenai',
      model_type: editPromptData?.prompt_body?.model_type
        ? editPromptData.prompt_body.model_type
        : 'chatcompletion',
    },
    llm_model_id: editPromptData?.prompt_body?.llm_model_id
      ? editPromptData.prompt_body.llm_model_id
      : '820b7f6f-9d64-4a8e-b82c-31b64570a2f2',
    llm_type: editPromptData?.prompt_body?.llm_type
      ? editPromptData.prompt_body.llm_type
      : 'azureopenai',
    model_type: editPromptData?.prompt_body?.model_type
      ? editPromptData.prompt_body.model_type
      : findllmModalType(editPromptData?.prompt_body?.llm_model_id, LLMModelData),
    tested: editPromptData?.response || editPromptData?.query ? true : false,
    message_params: parameterData,
    embedding_model_id:editPromptData?.prompt_body?.embedding_model_id?editPromptData?.prompt_body?.embedding_model_id:'',
    type: editPromptData?.type,
    published: localStorage.getItem('action')==='copy'?false:editPromptData?.published,
    prompt_id: prompt_id,
    created_on: editPromptData?.created_on,
    prompt_list: localStorage.getItem('action') === 'edit' ? promptList : [],
    promptEdit_id: editPromptData?.action === 'edit' ? editPromptData?.prompt_id : null,
    action: editPromptData?.action,
  }
  return transformedPromptData
}

const StyledContainer = styled(WizrFlexLayout)`
  gap: 8px;
`

const ContainertWithDivider = styled(WizrFlexLayout)`
  border-bottom: 1px solid #ddd;
  padding: 10px 0px;
  width: 100%;
  text-align: left;
  height: 100%;
  flex: none;
`
const StyledWizrDropDown = styled(WizrDropDown)`
  width: 100%;
`

const ChatSettings = styled(WizrFlexLayout)`
  border-radius: 6px;
  margin-bottom: 1px;
  gap: 5px;
  padding-top: 0px;
  min-height: 20%;
  overflow: auto;
`

const StyledInputContainer = styled.div`
  padding: 0px 10px;
  width: 100%;
  text-align: left;
`

const StyledWizrButton = styled(WizrButton)`
  border-radius: 20px;
`

const StyledWizrCheckbox = styled(WizrCheckbox)`
  margin-top: 40px !important;
`

const PromptMainFormLayout = styled(WizrFlexLayout)`
  border-radius: 16px;
  padding: 15px;
  margin-bottom: 10px;
`

const PromptMainFormInputLayout = styled(WizrFlexLayout)`
  gap: 16px;
`

const PromptInputBox = styled(WizrFlexLayout)`
  gap: 10px;
`

const PromptSettingsContainer = styled(WizrFlexLayout)`
  padding: 15px;
  border-radius: 16px;
`

const TestAreaContainer = styled(WizrFlexLayout)`
  border-radius: 16px;
  padding: 15px;
  overflow-y: auto;
`
const PromptSettingsSectionContainer = styled(WizrFlexLayout)`
  padding: 0px 0px 0px 20px;
`
const IndividualSettingsBox = styled.div`
  width: 100%;
  text-align: left;
  height: auto;
  margin: 10px 0px 0px 0px;
`

const IndividualLlmSettingsBox = styled.div`
  width: 90%;
  text-align: left;
  height: auto;
  margin: 10px 0px 0px 0px;
`

const TextInputContainer = ({ promptInputState, doUpdateFields ,promptTags}: any): JSX.Element => {
  const location = useLocation()

  // promptInputState?.type === 'template' || localStorage.getItem('action') === 'copy'
  // ? (promptInputState = { ...promptInputState, name: 'Copy of' + ' ' + promptInputState?.name })
  // : ''

  const doManagePrompt = () => {    
 
    
    dispatchManagePrompt(promptInputState)
  }

  const loading = useContentLoadingSelector()
  const tagList = promptTags?.map((item: any) => ({
    label: item,
    value: item,
  }))
  

  return (
    <PromptMainFormLayout
      flexDirection='row'
      justifyContent='flex-start'
      background='#FFF'
      alignItems='center'
    >
      <PromptMainFormInputLayout
        justifyContent='space-between'
        flexDirection='row'
        background='transparent'
        alignItems='center'
        flex={10}
      >
        <>
          <PromptInputBox flexDirection='column' background='transparent' alignItems='flex-start'>
            <WizrText textColor='#707276' type='body2'>
              Prompt Name
            </WizrText>
            <WizrInput
              placeholder='Name'
              value={promptInputState?.name}
              onChange={(value: any) => {
                doUpdateFields('type', '')
                doUpdateFields('name', value)
              }}
            />
          </PromptInputBox>
          <PromptInputBox flexDirection='column' background='transparent' alignItems='flex-start'>
            <WizrText textColor='#707276' type='body2'>
              Prompt Description
            </WizrText>
            <WizrInput
              placeholder='Description'
              value={promptInputState?.description}
              onChange={(value: any) => doUpdateFields('description', value)}
            />
          </PromptInputBox>
          {/* <PromptInputBox flexDirection='column' background='transparent' alignItems='flex-start'>
            <WizrText textColor='#707276' type='body2'>
              Prompt Name
            </WizrText>
            <WizrInput
              placeholder='Description'
              value={promptInputState?.description}
              onChange={(value: any) => doUpdateFields('description', value)}
            />
          </PromptInputBox> */}
          <PromptInputBox flexDirection='column' background='transparent' alignItems='flex-start'>
            <WizrText textColor='#707276' type='body2'>
              Tags
            </WizrText>
            <WizrTagInput
              onChange={(value: any) => doUpdateFields('tags', value)}
              //open={true}
              value={promptInputState?.tags}
              options={tagList}
            />
          </PromptInputBox>
        </>
      </PromptMainFormInputLayout>
      <WizrFlexLayout
        flexDirection='row'
        justifyContent='space-evenly'
        background='transparent'
        alignItems='center'
        flex={2}
        style={{ marginTop: '25px' }} //to align the publish and more button to the input line
      >
        <StyledWizrButton text='Save' disabled={loading} onClick={doManagePrompt} />
        <PromptCopyEdit />
      </WizrFlexLayout>
    </PromptMainFormLayout>
  )
}

const PromptSettingsSection1 = ({
  promptInputState,
  doUpdateFields,
  setPromptInputState,
}: any): JSX.Element => {
  const [knowledgeBase, setKnowledgeBase] = useState<boolean>(
    promptInputState?.knowledgebase_required,
  )
  const { showModal, hideModal } = useWizrModalContext()
  const [examplesData, setExamplesData] = useState<{ user: string; assistant: string }[]>([])
  const [zeroShot, setZeroShot] = useState<boolean>(true)
  const [parameterData, setParameterData] = useState<any>([])  
  const [currentembeddingModel,setCurrentEmbeddingModel] = useState<any>('')

  useEffect(() => {
    const folder = promptInputState?.folderList && promptInputState?.folderList[0]
const embeddingId = (folderData?.find((item: any) => item.folder_id === folder) as any)?.embedding_model_id;
  setCurrentEmbeddingModel(embeddingId)
  }, [])

  useEffect(() => {
    if (promptInputState?.examples) {
      setExamplesData(promptInputState?.examples)
    }
  }, [promptInputState?.examples])

  useEffect(() => {
    setParameterData(promptInputState?.message_params)
  }, [promptInputState?.message_params])

  // useEffect(() => {
  //   doUpdateFields('message_params', parameterData)
  // }, [parameterData])

  // useEffect(() => {
  //   doUpdateFields('examples', examplesData)
  // }, [examplesData])

  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item,
        value: item,
      }))
    return result
  }

  const getDisabledStatus = (item: any) => { 
    if (currentembeddingModel)
    {if (item?.embedding_model_id === currentembeddingModel){
      return false
    }
    else{
      return true}}
      else{
        return false
      }
   }

  const folderData :string[] = useContentsSelector()
  const folderList = folderData?.map((item: any) => ({
    label: item.folder_name,
    value: item.folder_id,
    disabled : getDisabledStatus(item)

  }))

  

   const selectKnowledgeBase = (value:any)=>{
    if (value.length ===1) {
      const embeddingId = (folderData?.find((item: any) => item.folder_id === value[0]) as any)?.embedding_model_id;
      setCurrentEmbeddingModel(embeddingId)
    // doUpdateFields('embedding_model_id', embeddingId)
    setPromptInputState({...promptInputState, embedding_model_id:embeddingId,folderList:value})
      }
      else if (value.length===0){
        setCurrentEmbeddingModel('')
        // doUpdateFields('embedding_model_id', '')
        setPromptInputState({...promptInputState, embedding_model_id:'',folderList:value})
    
    
      }else{
        doUpdateFields('folderList', value)}
      }

  const onExampleEdit = (item: any) => {
    showModal(MODAL_TYPES.ADD_EXAMPLES, {
      exampleInput: item,
      onSubmit: (example: any) => {
        const indexToUpdate = examplesData?.findIndex(({ id }: any) => id === example?.id)
        const updatedExamples = [...examplesData]
        updatedExamples[indexToUpdate] = example
        doUpdateFields('examples', updatedExamples)
        setExamplesData(updatedExamples)
        hideModal()
      },
      onCancel: hideModal,
    })
  }

  const onExampleDelete = (item: any, type: string) => {
    showModal(MODAL_TYPES.CONFIRMATION, {
      buttontext: type === 'zeroshot' ? 'Proceed' : 'Delete',
      text:
        type === 'zeroshot' ? (
          <p>
            Enabling zeroshot will delete existing examples.
            <br />
            Are you sure you want to proceed?
          </p>
        ) : (
          'Are you sure you want to delete?'
        ),
      onConfirm: () => {
        let updatedExamples
        if (Array.isArray(item)) {
          const itemIds = item.map((i: any) => i.id)
          updatedExamples = examplesData?.filter(({ id }: any) => !itemIds.includes(id))
        } else {
          updatedExamples = examplesData?.filter(({ id }: any) => id !== item?.id)
        }
        doUpdateFields('examples', updatedExamples)
        setExamplesData(updatedExamples)
        hideModal()
      },
      onCancel: hideModal,
    })
  }

  const parameterFinder = (type: number) => {
    switch (type) {
      case 1:
        return 'Short text'
      case 2:
        return 'Long Text'
      case 3:
        return 'File Upload'
      case 4:
        return 'Single select'
      case 5:
        return 'Multi select'
    }
  }

  const EditDelete = (parameter: any, key: number) => {
    const onParameterDelete = (item: any) => {
      showModal(MODAL_TYPES.CONFIRMATION, {
        text: 'Are you sure?',
        buttontext: 'Delete',
        onConfirm: () => {
          const updatedParameter = parameterData?.filter(
            (data: any) => data.name !== item.parameter.name,
          )
          setParameterData(updatedParameter)
          doUpdateFields('message_params', updatedParameter)
          // setPromptInputState({...promptInputState, parameter: updatedParameter})
          hideModal()
        },
        onCancel: hideModal,
      })
    }

    const onParameterEdit = (item: any) => {
      showModal(MODAL_TYPES.EDIT_PARAMETER, {
        parameterInput: item,
        onSubmit: (value: any) => {
          const indexToUpdate = parameterData?.findIndex(
            (indexitem: any) => JSON.stringify(indexitem) === JSON.stringify(item.parameter),
          )
          // const indexToUpdate = key
          const updatedParameter = [...parameterData]
          updatedParameter[indexToUpdate] = value
          setParameterData(updatedParameter)
          doUpdateFields('message_params', updatedParameter)
          hideModal()
        },
        onCancel: hideModal,
      })
    }
    return (
      <>
        <WizrButton type='text' icon={<EditIcon />} onClick={() => onParameterEdit(parameter)} />
        <WizrButton
          type='text'
          icon={<DeleteIcon />}
          onClick={() => onParameterDelete(parameter)}
        />
      </>
    )
  }

  return (
    <PromptSettingsSectionContainer
      background='#FFF'
      alignItems='flex-start'
      justifyContent='flex-start'
      flex={1.5}
    >
      <IndividualSettingsBox>
        <WizrText type='sub2'>System</WizrText>
        <IndividualSettingsBox>
          <WizrTextArea
            value={promptInputState?.systemMessage}
            onChange={(value: any) => doUpdateFields('systemMessage', value)}
          ></WizrTextArea>
        </IndividualSettingsBox>
      </IndividualSettingsBox>
     { promptInputState?.llm_model?.model_type !== 'completion' ?  <IndividualSettingsBox>
        <WizrFlexLayout
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          background='#FFF'
          flex='none'
        >
          <WizrFlexSpacing
            size='small'
            flexDirection='row'
            justifyContent='flex-start'
            background='#FFF'
          >
            <WizrText type='sub2'>Examples </WizrText>
            <WizrCheckbox
              text='Zero Shot'
              isEnabled={promptInputState?.zero_shot??true}
              onChange={(e: any) => {
                if (promptInputState?.examples?.length > 0 && e.target.checked === true) {
                  onExampleDelete(promptInputState?.examples, 'zeroshot')
                }
                doUpdateFields('zero_shot', e.target.checked)
                setZeroShot(e.target.checked)
              }}
            />
          </WizrFlexSpacing>
          <WizrButton
            type='text'
            disabled={zeroShot || promptInputState?.zero_shot}
            text={<WizrText type='link'>Add Examples</WizrText>}
            onClick={() =>
              showModal(MODAL_TYPES.ADD_EXAMPLES, {
                onSubmit: (comment: any) => {
                  hideModal()
                  setExamplesData([...examplesData, { id: examplesData?.length + 1, ...comment }])
                  doUpdateFields('examples', [
                    ...examplesData,
                    { id: examplesData?.length + 1, ...comment },
                  ])
                },
                onCancel: hideModal,
              })
            }
          />
        </WizrFlexLayout>
        <WizrFlexLayout background='#FFF' flex='none'>
          {examplesData?.length > 0 ? (
            examplesData?.map((item: any, key: number) => {
              return (
                <ContainertWithDivider
                  key={key}
                  background='#FFF'
                  flexDirection='row'
                  alignItems='flex-start'
                >
                  <WizrFlexLayout
                    background='#FFF'
                    alignItems='flex-start'
                    flex={3}
                    textAlign='left'
                  >
                    <WizrText type='body2' key={key}>
                      <b>Human input</b>: {item.user}
                    </WizrText>
                    <WizrText type='body2' key={key}>
                      <b>Assistant output</b>: {item.assistant}
                    </WizrText>
                  </WizrFlexLayout>
                  <WizrFlexLayout background='#FFF' alignItems='flex-end'>
                    <ExampleActionPopover
                      onDeleteAction={() => onExampleDelete(item, '')}
                      onEditAction={() => onExampleEdit(item)}
                    />
                  </WizrFlexLayout>
                </ContainertWithDivider>
              )
            })
          ) : (
            <></>
          )}
          
        </WizrFlexLayout>
      </IndividualSettingsBox> : <> </>}
      <IndividualSettingsBox>
        <WizrFlexLayout alignItems='center'>
          <WizrFlexLayout flexDirection='row' background='#FFF' alignItems='center'>
            <WizrText type='sub2'>Parameter </WizrText>
            <WizrButton
              type='text'
              text={<WizrText type='link'>Add Parameter</WizrText>}
              onClick={() =>
                showModal(MODAL_TYPES.ADD_PARAMETER, {
                  onSubmit: (value: any) => {
                    hideModal()
                    if (parameterData?.length > 0) {
                      doUpdateFields('message_params', [...parameterData, value])
                      setParameterData([...parameterData, value])
                    } else {
                      doUpdateFields('message_params', [value])
                      setParameterData([value])
                    }
                  },
                  onCancel: hideModal,
                })
              }
            />
          </WizrFlexLayout>
          <WizrFlexLayout background='#FFF' flex='none'>
            <WizrFlexLayout
              background='#FFF'
              // key={key}
              overFlow='hidden'
              flexDirection='column'
              alignItems='flex-start'
              style={{ marginTop: '15px' }}
            >
              {parameterData?.length > 0 &&
                parameterData?.map((item: any, key: number) => {
                  switch (item.parameterType) {
                    case 1:
                    case 2:
                    case 3:
                      return (
                        <>
                          <WizrFlexLayout
                            alignContent='left'
                            background='#FFF'
                            flexDirection='row'
                            justifyContent='space-around'
                          >
                            <WizrText type='body2'>{item.name}</WizrText>
                            <WizrText type='hint'>{parameterFinder(item?.parameterType)}*</WizrText>
                          </WizrFlexLayout>
                          <WizrFlexLayout
                            flexDirection='row'
                            background='#FFF'
                            alignItems='center'
                            justifyContent='space-around'
                            style={{ gap: '3px' }}
                          >
                            <WizrInput value={item.name} disabled={true} />
                            <EditDelete parameter={item} key={key} />
                          </WizrFlexLayout>
                        </>
                      )
                    case 4:
                    case 5:
                      return (
                        <>
                          <WizrFlexLayout
                            alignItems='flex-start'
                            background='#FFF'
                            flexDirection='row'
                            justifyContent='space-around'
                          >
                            <WizrText type='body2'>{item.name}</WizrText>
                            <WizrText type='hint'>{parameterFinder(item?.parameterType)}*</WizrText>
                          </WizrFlexLayout>
                          <WizrFlexLayout
                            flexDirection='row'
                            background='#FFF'
                            alignItems='center'
                            justifyContent='space-evenly'
                            style={{ gap: '3px' }}
                          >
                            <WizrDropDown
                              placeHolder='See Options'
                              options={dropDownConverter(item.modalTags)}
                              value={{}}
                            />
                            <EditDelete parameter={item} key={key} />
                          </WizrFlexLayout>
                        </>
                      )
                  }
                })}
            </WizrFlexLayout>
          </WizrFlexLayout>
        </WizrFlexLayout>
      </IndividualSettingsBox>
      { promptInputState?.llm_model?.model_type !== 'completion' ? <IndividualSettingsBox>
        <WizrFlexLayout>
          <WizrFlexLayout
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            background='#FFF'
          >
            <WizrText type='sub1'>Knowledge base </WizrText>
            <WizrToggleButton
              isEnabled={promptInputState?.knowledgebase_required}
              onChange={(e: boolean) => {
                setKnowledgeBase(e)
                doUpdateFields('knowledgebase_required', e)
              }}
            />
          </WizrFlexLayout>
        </WizrFlexLayout>
        {knowledgeBase || promptInputState?.knowledgebase_required ? (
          <WizrFlexLayout alignItems='center' background='#FFF'>
            <IndividualSettingsBox>
              <WizrTagInput
                options={folderList}
                value={promptInputState?.folderList}
                onChange={(value: any) => selectKnowledgeBase(value) }
                placeHolder='Select folder(s)'
              />
            </IndividualSettingsBox>
          </WizrFlexLayout>
        ) : (
          <></>
        )}
      </IndividualSettingsBox> : <></>}
    </PromptSettingsSectionContainer>
  )
}

const PromptSettingsSection2 = ({ promptInputState, doUpdateFields }: any): JSX.Element => {
  const doManagePrompt = () => dispatchManagePrompt(promptInputState)
  const LLMModelData = useLLMModelSelector()
  const loading = useContentLoadingSelector()
  const [modelName, setModelName] = useState<any>({})

  const normalOptions = LLMModelData?.map((item: any) => {
    return { label: item.display_name, value: item.display_name, model_type: item.model_type }
  })

  useEffect(() => {
    if (!loading && promptInputState?.llm_type) {
      const defaultModel = LLMModelData.find(
        (obj: any) =>
          obj.id === (promptInputState?.llm_model?.id || promptInputState?.llm_model_id),
      )
      if (defaultModel) {
        setModelName({ label: defaultModel.display_name, value: defaultModel.display_name, model_type: defaultModel.model_type })
      }
    } else {
      setModelName({ label: LLMModelData[0]?.display_name, value: LLMModelData[0]?.display_name, model_type: LLMModelData[0]?.model_type })
    }
  }, [LLMModelData])

  const selectLLMModel = (value: any) => {

    const selectedModel = LLMModelData.find((obj: any) => obj.display_name === value)
    setModelName({ label: value, value: value, model_type: selectedModel.model_type })
    doUpdateFields('llm_model', { id: selectedModel.id, type: selectedModel.llm_type, model_type: selectedModel.model_type })

  }

  return (
    <PromptSettingsSectionContainer background='#FFF'>
      <IndividualSettingsBox>
        <WizrText type='sub1'>LLM Settings </WizrText>
        <IndividualSettingsBox>
          <WizrDropDown
            placeHolder='Model'
            options={normalOptions}
            onChange={(value: any) => selectLLMModel(value)}
            value={modelName}
          />
        </IndividualSettingsBox>
      </IndividualSettingsBox>
      <WizrFlexLayout flex='none' background='#FFF' overFlow='hidden'>
        <IndividualSettingsBox>
          <WizrFlexLayout
            flexDirection='row'
            justifyContent='space-between'
            background='#FFF'
            flex='none'
          >
            <WizrText type='body2'>Temperature</WizrText>
            <Tooltip
              title={
                <WizrText textColor='white' type='caption'>
                  It controls the randomness of the response .A lower value makes answers more
                  focused and consistent, while a higher value adds variety and creativity. It is
                  advisable to modify either the temperature or the Top P value, but not both
                  simultaneously, to achieve desired outcomes.{' '}
                </WizrText>
              }
            >
              {' '}
              <InfoIcon />{' '}
            </Tooltip>
          </WizrFlexLayout>
          <IndividualLlmSettingsBox>
            <WizrSlider
              min={0}
              max={1}
              minLabel='FOCUSED'
              maxLabel='CREATIVE'
              value={promptInputState?.temperature}
              defaultValue={0.5}
              onSliderChange={(value: any) => doUpdateFields('temperature', value)}
            />
          </IndividualLlmSettingsBox>
        </IndividualSettingsBox>
        <IndividualSettingsBox>
          <WizrFlexLayout
            flexDirection='row'
            justifyContent='space-between'
            background='#FFF'
            flex='none'
          >
            <WizrText type='body2'>Top_p</WizrText>
            <Tooltip
              title={
                <WizrText textColor='white' type='caption'>
                  It is also used to control randomness. When you decrease Top P, the model sticks
                  to more expected words. On the flip side, raising Top P lets the model choose from
                  both common and uncommon words. Remember, it is best to tweak either temperature
                  or Top P, but not both, for effective adjustments.
                </WizrText>
              }
            >
              {' '}
              <InfoIcon />{' '}
            </Tooltip>
          </WizrFlexLayout>
          <IndividualLlmSettingsBox>
            <WizrSlider
              min={0}
              max={1}
              minLabel='NARROW'
              maxLabel='BROAD'
              defaultValue={0.5}
              value={promptInputState?.top_p}
              onSliderChange={(value: any) => doUpdateFields('top_p', value)}
            />
          </IndividualLlmSettingsBox>
        </IndividualSettingsBox>
        <IndividualSettingsBox>
          <WizrFlexLayout
            flexDirection='row'
            justifyContent='space-between'
            background='#FFF'
            flex='none'
          >
            <WizrText type='body2'>Frequency Penalty</WizrText>
            <Tooltip
              title={
                <WizrText textColor='white' type='caption'>
                  It reduces the likelihood of generating words that are common in the training
                  data. A higher frequency penalty value will result in the model being more
                  conservative in its use of repeated tokens. A lower frequency penalty value will
                  result in the model being more likely to use common words or phrases
                </WizrText>
              }
            >
              {' '}
              <InfoIcon />{' '}
            </Tooltip>
          </WizrFlexLayout>
          <IndividualLlmSettingsBox>
            <WizrSlider
              min={0}
              max={2}
              minLabel='REPETITIVE'
              maxLabel='DIVERSE'
              defaultValue={0}
              value={promptInputState?.frequency_penalty}
              onSliderChange={(value: any) => doUpdateFields('frequency_penalty', value)}
            />
          </IndividualLlmSettingsBox>
        </IndividualSettingsBox>
        <IndividualSettingsBox>
          <WizrFlexLayout
            flexDirection='row'
            justifyContent='space-between'
            background='#FFF'
            flex='none'
            overFlow='hidden'
          >
            <WizrText type='body2'>Presence Penalty</WizrText>
            <Tooltip
              title={
                <WizrText textColor='white' type='caption'>
                  A higher frequency penalty value will result in the model being more conservative
                  in its use of repeated tokens. A lower frequency penalty value will result in the
                  model being more likely to use common words or phrases
                </WizrText>
              }
            >
              {' '}
              <InfoIcon />{' '}
            </Tooltip>
          </WizrFlexLayout>
          <IndividualLlmSettingsBox>
            <WizrSlider
              min={0}
              max={2}
              minLabel='CONSISTENT'
              maxLabel='NOVAL'
              defaultValue={0}
              value={promptInputState?.presence_penalty}
              onSliderChange={(value: any) => doUpdateFields('presence_penalty', value)}
            />
          </IndividualLlmSettingsBox>
        </IndividualSettingsBox>
        <IndividualSettingsBox>
          <WizrText type='body2'>Stop Sequence</WizrText>
        </IndividualSettingsBox>
        <IndividualSettingsBox>
          <WizrTagInput
            open={false}
            onChange={(e: any) => doUpdateFields('stop_sequences', e)}
            placeHolder='Stop sequence'
            value={promptInputState?.stop_sequences}
          />
        </IndividualSettingsBox>
        <IndividualSettingsBox>
          <WizrText type='body2'>Token Limit</WizrText>
        </IndividualSettingsBox>
        <IndividualSettingsBox>
          <WizrInput
            placeholder='Token Limit'
            value={promptInputState?.max_tokens}
            onChange={(value: any) => doUpdateFields('max_tokens', value)}
          />
        </IndividualSettingsBox>
        <IndividualSettingsBox>
          {/* <WizrFlexLayout background='#FFF' flexDirection='row' justifyContent='flex-end'>
            <WizrButton text='Save' disabled={loading} onClick={doManagePrompt} />
          </WizrFlexLayout> */}
        </IndividualSettingsBox>
      </WizrFlexLayout>
    </PromptSettingsSectionContainer>
  )
}

const TestAreaSection = ({ id, promptInputState }: any): JSX.Element => {
  const navigate = useNavigate()
  const chatMessage = useChatMessageSelector()
  const [memoryEnabled, setMemoryEnabled] = useState(false)
  const [maxMessages, setMaxMessages] = useState<any>()
  const prompt_id = localStorage.getItem('prompt_id') || id
  const [showReason, setShowReason] = useState<boolean>(false)
  const [messageParams, setMessageParams] = useState<any>({})
  const [inputValue, setInputValue] = useState<any>({})
  const [historyChats, setHistoryChats] = useState<{ user: string; assistant: string }[]>([])
  const [fileParams, setFfileParams] = useState<any>([])
  const { showModal, hideModal } = useWizrModalContext()
  const fileParamsTimestamp = useFileParams()

  useEffect(() => {
    const user = chatMessage?.filter((item: any) => item.isSent).map((item: any) => item.text)
    const assistant = chatMessage?.filter((item: any) => !item.isSent).map((item: any) => item.text)
    if (user?.length === assistant?.length) {
      const result = user.map((value:any, index:any) =>
        Object.assign({}, { user: value, assistant: assistant?.[index] }),
      )
      setHistoryChats(result)
    }
  }, [chatMessage])

  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item,
        value: item,
      }))
    return result
  }

  const updateMessageParams = (name: string, value: any) => {
    const names = `{{${name}}}`
    const finalValue = value?.length > 0 ? value.toString() : value
    setMessageParams({ ...messageParams, [names]: finalValue })
  }
  const updateFileParams = (name: any, fileInfo: any) => {
    const type = getFileType(fileInfo?.file?.name)
const currentFile = fileParamsTimestamp?.filter((item:any)=>item?.input_file_name===name)
    const fileData = {
      param_name: name,
      file_name: `${fileInfo?.file?.name}`,
      file_location: 'promptbox',
      file_type: type,
    }

    setFfileParams([...fileParams, fileData])
  }

  const onChatEnter = (message: string) => {
    const newFileParams: { param_name: any; file_name: string; file_location: string; file_type: string }[] = [];
    fileParamsTimestamp?.map((item:any)=>{
     if (prompt_id===item[0]?.path){ 
      const type = getFileType(item[0]?.file_name)
      const paramData = {
        param_name: item[0]?.input_file_name,
        file_name: `${prompt_id}/${item[0]?.timestamp}/${item[0]?.file_name}`,
        file_location: 'promptbox',
        file_type: type,
      }
      newFileParams.push(paramData); 
      setFfileParams([...fileParams, paramData])}
    })

    if (prompt_id !== '') {
      const payloadData = {
        id: prompt_id,
        user_query: message,
        message_params: messageParams ? messageParams : {},
        memory_required: memoryEnabled ? memoryEnabled : false,
        max_past_messages: memoryEnabled ? 15 : 0,
        history: historyChats,
        file_params: newFileParams ? newFileParams : [],
      }
      disaptchPromptTest(payloadData)
    }
  }

  const uploadFile = async(name: any, fileInfo: any) => {
    // WizrAzurOrchestratorInstance.uploadFile('', fileInfo?.file, '', [], 'promptbox')

      const fileUploadPayload={
        container_id: 3,
        path: prompt_id,
        files: [
          `${name}`,
        ]
      }
      const formData = new FormData();
      const jsonData = JSON.stringify(fileUploadPayload);
      formData.append('data', jsonData);
      formData.append(name, fileInfo?.fileList[0]?.originFileObj);
      await dispatchUploadFileUsingLink(formData)
      //updateFileParams(name, fileInfo)

    
  }

  const mapValueToUserQuery = () => {
   
    
  }

  return (
    <IndividualSettingsBox>
      <Chat
        chatMessage={chatMessage}
        onHistoryClick={() =>
          window.open(`/prompt-history/${prompt_id}`, '_blank', 'noopener,noreferrer')

        }
        onChatClick={onChatEnter}
        promptInputState={promptInputState}
        mapValueToUserQuery={mapValueToUserQuery}
      />
      <ChatSettings background='#FFF'>
        {promptInputState?.message_params?.length > 0 &&
          promptInputState?.message_params.map((item: any, key: number) => {
            return (
              <WizrFlexLayout
                key={key}
                background='#FFF'
                flexDirection='row'
                justifyContent='space-around'
                flex='none'
                style={{ marginTop: '15px' }}
              >
                {item?.parameterType > 3 ? (
                  <WizrFlexLayout alignItems='start' background='#FFF' overFlow='hidden'>
                    <WizrText type='body2'>{item.name}</WizrText>
                    {item?.parameterType === 4 ? (
                      <StyledWizrDropDown
                        placeHolder='Select one option'
                        options={dropDownConverter(item.modalTags)}
                        onChange={(value: any) => updateMessageParams(item.name, value)}
                      />
                    ) : (
                      <WizrTagInput
                        placeHolder='Select multiple option'
                        options={dropDownConverter(item.modalTags)}
                        onChange={(value: any) => updateMessageParams(item.name, value)}
                      ></WizrTagInput>
                    )}
                  </WizrFlexLayout>
                ) : (
                  <></>
                )}
                {item?.parameterType < 4 ? (
                  <WizrFlexLayout alignItems='start' background='#FFF' overFlow='hidden'>
                    <WizrText type='body2'>{item.name}</WizrText>
                    {item?.parameterType === 1 ? (
                      <WizrInput
                        key={key}
                        style={{ maxHeight: '25px' }}
                        value={inputValue[item.name]}
                        onChange={(value: any) => {
                          updateMessageParams(item.name, value)
                          setInputValue({ ...inputValue, [item.name]: value })
                        }}
                      />
                    ) : item?.parameterType === 3 ? (
                      <Input
                        onChange={(value: any) => updateMessageParams(item.name, value)}
                        placeholder='Upload File'
                        value={inputValue[item.name]}
                        suffix={
                          <WizrUploadContainer
                            onChange={(e: any) => {
                              uploadFile(item.name, e)
                              setInputValue({ ...inputValue, [item.name]: e?.file?.name })
                            }}
                          >
                            <Button shape='round'>
                              {inputValue[item.name]?.length > 0 ? 'Reupload' : 'Upload'}
                            </Button>
                          </WizrUploadContainer>
                        }
                      />
                    ) : (
                      <WizrTextArea
                        onChange={(value: any) => updateMessageParams(item.name, value)}
                        placeholder='Enter text'
                        minRows={2}
                      />
                    )}
                  </WizrFlexLayout>
                ) : (
                  <></>
                )}
              </WizrFlexLayout>
            )
          })}
        <WizrFlexLayout
          background='#FFF'
          flexDirection='row'
          justifyContent='space-between'
          flex='none'
        >
          <WizrText type='sub1'>Memory </WizrText>
          <WizrToggleButton isEnabled={memoryEnabled} onChange={setMemoryEnabled} />
        </WizrFlexLayout>
        {/* <WizrFlexLayout background='#FFF' justifyContent='space-between' flex='none'>
          <WizrInput
            type='number'
            value={maxMessages}
            placeholder='Number of conversation'
            onChange={setMaxMessages}
          />
        </WizrFlexLayout> */}
        <WizrFlexLayout background='#FFF' justifyContent='space-between' flex='none'>
          <StyledWizrCheckbox
            text='Show reasoning for the output'
            isEnabled={showReason}
            onChange={(value: any) => setShowReason(value.target.checked)}
          />
        </WizrFlexLayout>
      </ChatSettings>
    </IndividualSettingsBox>
  )
}

const PromptView = (): JSX.Element => {
  const location = useLocation()
  const [promptInputState, setPromptInputState] = useState<{ [key: string]: any }>()
  const prompt_id = localStorage.getItem('prompt_id') || ''
  const loading = useContentLoadingSelector()
  const doManagePrompt = () => dispatchManagePrompt(promptInputState)
  const [action, setAction] = useState<string>('')
  const actiontype = localStorage.getItem('action') || ''  
  const LLMModelData = useLLMModelSelector()

  useEffect(() => {
    dispatchListFolders('knowledge_base')
    dispatchLLMModels()
    dispatchTags()
    // dispatchPromptTags
  }, [])

  useEffect(() => {
    //if (prompt_id===''){dispatchClearChat()}
    //dispatchClearChat()
    const editParams = location?.state
    
    if (!editParams?.testHistory || prompt_id===''){
          
      dispatchClearChat()
    }
    if (editParams) {      
      const type = editParams?.type?editParams?.type:"prompt"
      //prompt_id = editParams?.id
      if (type==="prompt"){
      dispatchGetPromptDetails(prompt_id)}
      else{
        dispatchGetPromptTemplateDetails(prompt_id)
      }
      setAction(actiontype)
    } else {
      dispatchPromptData({})
    }
  }, [location])


  const promptDetails = usePromptDetailsSelector()
  const promptTags = useTagsSelector()

  

  promptDetails.action = action
  useEffect(() => {
    setAction(actiontype)
  }, [actiontype])

  useEffect(() => {
    const editTransformedPromptData = transformEditDataToPromptInput(promptDetails, LLMModelData)
    setPromptInputState(editTransformedPromptData)
  }, [promptDetails])

  const doUpdateFields = (fieldName: string, fieldValue: any) =>
    setPromptInputState({
      ...promptInputState,
      [fieldName]: fieldValue,
    })

  const doPublish = () => {
    if (prompt_id !== '') {
      dispatchPublishPrompt(prompt_id)
    } else {
      dispatchNotificationState({
        message: 'Prompt not saved',
        description: 'Save the prompt before publishing.',
        type: 'error',
      })
    }
  }
  return (
    <WizrFlexLayout>
      <WizrFlexLayout
        style={{ marginTop: '10px' }}
        flexDirection='row'
        justifyContent='space-between'
      >
        <WizrUserJourney userJourney={[{ title: 'AI Studio' },{title:promptInputState?.name}]} />
        <WizrButton
          text='Publish'
          disabled={loading || promptInputState?.published}
          onClick={doPublish}
        />
      </WizrFlexLayout>
      <WizrFlexLayout justifyContent='space-between' flex={15}>
        <WizrFlexLayout style={{ marginTop: '10px' }} flex='none'>
          <TextInputContainer promptInputState={promptInputState} doUpdateFields={doUpdateFields} promptTags={promptTags}/>
        </WizrFlexLayout>
        <StyledContainer flexDirection='row' justifyContent='space-between'>
          <PromptSettingsContainer flexDirection='row' background='#FFF' flex={2}>
            <PromptSettingsSection1
              promptInputState={promptInputState}
              doUpdateFields={doUpdateFields}
              setPromptInputState={setPromptInputState}
            />
            <PromptSettingsSection2
              promptInputState={promptInputState}
              doUpdateFields={doUpdateFields}
            />
          </PromptSettingsContainer>
          <TestAreaContainer flex={1} background='#FFF'>
            <TestAreaSection id={prompt_id} promptInputState={promptInputState} />
          </TestAreaContainer>
        </StyledContainer>
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}
export default PromptView
