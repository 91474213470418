import { useEffect, useState } from 'react'
import {
  dispatchBotDetails,
  dispatchCreateAgent,
  dispatchCreateBot,
  dispatchDeleteWidget,
  dispatchFeatureSettings,
  dispatchGetWidgetTypes,
  dispatchLLMModels,
  dispatchListWidgets,
  dispatchUpdateAgent,
  dispatchUpdateApp,
  dispatchUpdateBot,
  dispatchUpdateSelectedTools,
} from '../../../../redux/actions/content'
import {
  useAgentDetails,
  useBotDetailsSelector,
  useContentLoadingSelector,
  useFeatureSettingSelector,
  useLLMModelSelector,
  useSelectedToolsSelector,
  useWidgetListSelector,
  useWidgetTypesSelector,
} from '../../../../redux/selectors/content'
import WizrFlexLayout from '../../../../components/WizrFlexLayout'
import WizrCard from '../../../../components/Wizrcard'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import KnowledgeAssistWidget from '../WidgetScreens/KnowledgeAssistWidget'
import WizrUserJourney from '../../../../components/WizrUserJourney'
import { ArrowLeftOutlined } from '@ant-design/icons'
import WizrButton from '../../../../components/WizrButton'
import WizrText from '../../../../components/WizrText'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import WizrTextArea from '../../../../components/WizrTextArea'
import WizrInput from '../../../../components/WizrInput'
import CreateNew from '../../../../assets/icons/CreateNew'
import { Button, Layout, Drawer, Card, Tooltip, Dropdown } from 'antd'
import DeleteIcon from '../../../../assets/icons/DeleteIcon'
import styled from 'styled-components'
// import Sider from 'antd/es/layout/Sider'
import TestArea from '../WidgetScreens/TestArea'
import WizrDropDown from '../../../../components/WizrDropDown'
import WizrCheckbox from '../../../../components/WizrCheckbox'
import InfoIcon from '../../../../assets/icons/Info'
import WizrSlider from '../../../../components/WizrSlider'
import CreateAgentPopover from '../../../../components/popovers/CreateAgent'
import { v4 as uuidv4 } from 'uuid'
import AddToolPopover from '../../../../components/popovers/AddToolPopover'
import Toolbox from '../../../../assets/icons/Toolbox'

const StyledWizrInput = styled(WizrInput)`
  background: #f2f4f9;
`

// const StyledWizrDropDown = styled(WizrDropDown)`
//   .ant-select-selector {
//     height: 50px !important;
//   }
// `

const StyledWizrDropDown = styled(WizrDropDown)`
  height: 50px;
  .ant-select-selector {
    padding-top: 10px !important;
    height: 50px !important;
    background-color: #f2f4f9 !important;
    .ant-select-selection-placeholder {
      margin-top: 10px;
    }
  }
`

const CreateAgent = () => {

  const params = useParams()
  const navigate = useNavigate()

  const [selectedToolsState, setSelectedToolsState] = useState([])
  const [promptInputState, setPromptInputState] = useState<{ [key: string]: any }>()

  const widgetList = useWidgetListSelector()
  const LLMModelData = useLLMModelSelector()
  const selectedTools = useSelectedToolsSelector()
  const featureSettings = useFeatureSettingSelector()
  const botDetails = useBotDetailsSelector()
  const agentDetails = useAgentDetails()
  

  const [currentFeatureData, setCurrentFeatureData] = useState<{ [key: string]: any }>({})

  const doUpdateFields = (fieldName: any, fieldValue: any) => {
    setPromptInputState({
      ...promptInputState,
      [fieldName]: fieldValue,
    })
  }

  const location = useLocation()

  const defaultValues = location?.state?.data
  const action = location?.state?.action

  const loading = useContentLoadingSelector();



  const normalOptions = LLMModelData?.map((item: any) => {
    return { label: item.display_name, value: item.id, model_type: item.model_type }
  })

  useEffect(() => {
    if (defaultValues) {
      const defvalues = {
        agent_name: defaultValues?.agent_name,
        agent_type: defaultValues?.agent_type,
        system_message: defaultValues?.params?.agent_params?.system_message,
        toolcheckbox: true,
        memmory: false,
        llm_model_id: defaultValues?.params?.agent_params?.llm_model_id,
        frequency_penalty: defaultValues?.params?.agent_params?.frequency_penalty,
        max_tokens: defaultValues?.params?.agent_params?.max_tokens,
        presence_penalty: defaultValues?.params?.agent_params?.presence_penalty,
        temperature: defaultValues?.params?.agent_params?.temperature,
        top_p: defaultValues?.params?.agent_params?.top_p,
        stop_sequences: defaultValues?.params?.agent_params?.stop_sequences,
        llm_type: defaultValues?.params?.agent_params?.llm_type,
        tools: defaultValues?.tools,
      }

      const toolsList = Object.values(defaultValues?.tools)
      const filteredWidgets = widgetList.filter((widget: any) => toolsList.includes(widget.id))
      setSelectedToolsState(filteredWidgets)
      dispatchUpdateSelectedTools(defaultValues?.tools)
      setPromptInputState(defvalues)
    }
  }, [defaultValues, LLMModelData])

  const SliderData: any = ['temperature', 'top_p', 'frequency_penalty', 'presence_penalty',]

  const findDescription = (des: any) => {
    switch (des) {
      case 'temperature':
        return " It controls the randomness of the response .A lower value makes answers more focused and consistent, while a higher value adds variety and creativity. It is advisable to modify either the temperature or the Top P value, but not both simultaneously, to achieve desired outcomes.{' '}"
      case 'frequency_penalty':
        return ' It reduces the likelihood of generating words that are common in the training  data. A higher frequency penalty value will result in the model being more conservative in its use of repeated tokens. A lower frequency penalty value will  result in the model being more likely to use common words or phrases'
      case 'presence_penalty':
        return 'A higher frequency penalty value will result in the model being more conservative in its use of repeated tokens. A lower frequency penalty value will result in the  model being more likely to use common words or phrases'
      case 'top_p':
        return ' It is also used to control randomness. When you decrease Top P, the model sticksto more expected words. On the flip side, raising Top P lets the model choose fromboth common and uncommon words. Remember, it is best to tweak either temperature or Top P, but not both, for effective adjustments.'
    }
  }

  const findMaxValue = (name: any) => {
    switch (name) {
      case 'temperature':
      case 'top_p':
        return 1
      case 'frequency_penalty':
      case 'presence_penalty':
        return 2
      default:
        return 0 // Default value, you might want to adjust it based on your requirements
    }
  }
  const findMinLabel = (name: any) => {
    switch (name) {
      case 'temperature':
        return 'FOCUSED'
      case 'top_p':
        return 'NARROW'
      case 'frequency_penalty':
        return 'REPETITIVE'
      case 'presence_penalty':
        return 'CONSISTENT'
      default:
        return ''
    }
  }
  const findMaxLabel = (name: any) => {
    switch (name) {
      case 'temperature':
        return 'CREATIVE'
      case 'top_p':
        return 'BROAD'
      case 'frequency_penalty':
        return 'DIVERSE'
      case 'presence_penalty':
        return 'NOVAL'
      default:
        return ''
    }
  }

  useEffect(() => {
    // if(defaultValues){
    const toolsList = Object.values(selectedTools)
    const filteredWidgets = widgetList.filter((widget: any) => toolsList.includes(widget.id))
    setSelectedToolsState(filteredWidgets)
    // }
  }, [selectedTools])

  useEffect(() => {
    const featureSettingsData = featureSettings?.feature_settings
    // setTenantFeatureId(featureSettings?.id)
    const currentFeatureData = featureSettingsData?.find(
      (feature: any) => feature.id === params?.id,
    )
    setCurrentFeatureData(currentFeatureData)
    if (currentFeatureData?.bot_id) {
      dispatchBotDetails(currentFeatureData?.bot_id)
    }
  }, [featureSettings])

  useEffect(() => {    
    // dispatchGetToolsList()
    dispatchListWidgets()
    dispatchLLMModels()
    dispatchGetWidgetTypes()
    dispatchFeatureSettings()
  }, [])

  const saveAgent = async () => {

    console.log('selectedTools', selectedTools);
    console.log('selectedToolsState', selectedToolsState);

    const idArray = selectedToolsState.map((item: any) => item.id);
    const llmType = LLMModelData?.find((item: any) => item?.id === promptInputState?.llm_model_id)
    const id = uuidv4()
    const agent_id = uuidv4()

    const payload = {
      id: agentDetails?.id ? agentDetails?.id : defaultValues ? defaultValues?.id : id,
      agent_id: agentDetails?.agent_id ? agentDetails?.agent_id : defaultValues ? defaultValues?.agent_id : agent_id,
      app_feature_id: params?.id,
      tenant_feature_id: featureSettings?.id,
      agent_description: '',
      agent_version: '0',
      agent_type: promptInputState?.agent_type,
      status: 'active',
      published: true,
      tags: [],
      agent_name: promptInputState?.agent_name,
      params: {
        agent_params: {
          llm_model_id: promptInputState?.llm_model_id,
          llm_type: llmType?.llm_type,
          frequency_penalty: promptInputState?.frequency_penalty || 0,
          presence_penalty: promptInputState?.presence_penalty || 0,
          max_tokens: promptInputState?.max_tokens || 2500,
          temperature: promptInputState?.temperature || 0.5,
          top_p: promptInputState?.top_p || 0.5,
          stop_sequences: [],
          system_message: promptInputState?.system_message,
        },
      },
      tools: idArray,
    }
    
    const { agent_config, ...remainingPayload } = botDetails

    if (defaultValues) {
      await dispatchUpdateAgent(payload)
      const botPayload = {
        bot_config: {
          ...remainingPayload,
          agent_id: defaultValues?.id,
        },
        app_feature_id: params?.id,
        tenant_feature_id: featureSettings?.id,
      }
      await dispatchUpdateBot(botPayload)
      await dispatchFeatureSettings()
    } else {
      if (currentFeatureData?.bot_id) {
        const botPayload = {
          bot_config: {
            ...remainingPayload,
            agent_id: id,
          },
          app_feature_id: params?.id,
          tenant_feature_id: featureSettings?.id,
        }        
        await dispatchUpdateAgent(payload)
        await dispatchUpdateBot(botPayload)
        await dispatchFeatureSettings()
      } else {
        const payloadForBotCreation = {
          bot_config: {
            id: uuidv4(),
            status: 'active',
            bot_config: currentFeatureData?.bot_config,
            installations: currentFeatureData.installations,
            agent_id: id,
          },
          app_feature_id: params?.id,
          tenant_feature_id: featureSettings?.id,
        }        
        await dispatchCreateAgent(payload)
        await dispatchCreateBot(payloadForBotCreation)
        await dispatchFeatureSettings()
      }

    }
  }

  const editAgent = () => {
    navigate(`/createAgent/${params?.id}`, {
      state: {
        data: defaultValues,
        action: 'edit'
      }
    })
  }

  const onDelete = async (item: any) => {
    const filteredArray = selectedToolsState.filter((arrayItem: any) => arrayItem.id !== item.id);
    setSelectedToolsState(filteredArray)
    // await dispatchDeleteWidget(item?.id)
    // await dispatchListWidgets()
  }

  const onEdit = (item: any) => {
    navigate(`/widgets/${item?.tool_type}`, { state: { id: item?.id } })
  }

  const colors = ['#FFAF14', '#FF7FC4', '#C6E504'];
  const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

  return (
    <div style={{ width: '100%' }}>
      <WizrFlexLayout background='#FFF'>
        <WizrFlexLayout>
          <WizrUserJourney
            userJourney={[
              {
                title: (
                  <WizrButton
                    icon={<ArrowLeftOutlined style={{ fontSize: '22px', marginRight: '10px' }} />}
                    type='text'
                    text={
                      <WizrText type='h6' textColor='#707276'>
                        {'Auto Solve'}
                      </WizrText>
                    }
                    onClick={() => navigate(-2)}
                  />
                ),
              },
              {
                title: (
                  <WizrButton
                    type='text'
                    text={
                      <WizrText type='h6' textColor='#707276'>
                        {promptInputState?.agent_name}
                      </WizrText>
                    }
                    onClick={() => navigate(-1)}
                  />

                ),
              },
              {
                title: (
                  <WizrFlexLayout style={{ paddingTop: '5px' }}>
                    <WizrText type='h6'>{'Add Agent'}</WizrText>
                  </WizrFlexLayout>
                ),
              },
            ]}
          />
        </WizrFlexLayout>
        <WizrFlexLayout background='#FFF'
          justifyContent='center'
          alignItems='center'
          style={{ padding: '24px' }}
        >
          <WizrFlexLayout
            background='#FFF'
            textAlign='left'
            style={{ width: '90%', margin: '0 auto', gap: '16px' }}
          >
            <WizrFlexLayout flexDirection='row' background='#FFF'>
              <WizrFlexLayout
                background='#FFF'
                justifyContent='flex-start'
                textAlign='left'
                alignContent='start'
                style={{ padding: '12px', gap: '8px', flexBasis: '30%' }}
              >
                <WizrText type='body2'>Name</WizrText>
                <StyledWizrInput
                  onChange={(value: any) => doUpdateFields(`agent_name`, value)}
                  value={promptInputState ? promptInputState?.agent_name : ''}
                  disabled={action === 'view'}
                />
                <WizrText type='body2'>Selected Type</WizrText>
                <StyledWizrDropDown
                  placeHolder='agent_type'
                  options={[
                    { value: 'Supervisor', id: '1' },
                    { value: 'SingleAgent', id: '2' },
                  ]}
                  onChange={(value: any) => doUpdateFields(`agent_type`, value)}
                  value={promptInputState ? promptInputState.agent_type : ''}
                  disabled={action === 'view'}
                />
                <WizrText type='body2'>System Message</WizrText>
                <WizrTextArea
                  background='#F2F4F9'
                  border='0px #F2F4F9'
                  minRows={5}
                  onChange={(value: any) => doUpdateFields(`system_message`, value)}
                  value={promptInputState?.system_message}
                  disabled={action === 'view'}
                />
                <WizrCheckbox
                  text='Show tools usage, when agent uses a connected tool'
                  isEnabled={promptInputState?.toolcheckbox ?? true}
                  onChange={(e: any) => doUpdateFields(`toolcheckbox`, e.target.checked)}
                // disabled={action === 'view'}
                />
                <WizrFlexLayout
                  justifyContent='flex-start'
                  flexDirection='row'
                  style={{ gap: '10px', paddingTop: '5px' }}
                  background='#FFF'
                >
                  <WizrText type='sub1'>Tools</WizrText>
                  {action !== 'view' ? <AddToolPopover value={promptInputState?.tools} /> : <></>}
                </WizrFlexLayout>
                <>
                  {' '}
                  {selectedToolsState &&
                    selectedToolsState?.map((item: any, index: any) => {
                      return (
                        
                        

                        <StyledTicketCard
                          key={index}
                          title={<WizrFlexLayout
                            background='#FFFFFF'
                            flexDirection='row'
                            style={{
                              padding: '10px 0px',
                              alignItems: 'center'
                            }}
                            textAlign='left'
                          >
                            <WizrFlexLayout background='#FFFFFF'
                              flexDirection='row' justifyContent='flex-start' textAlign='left' alignItems='center' style={{ gap: '10px' }}>
                              {/* <Toolbox stroke={getRandomColor()}/> */}
                              <div style={{ width: '24px', height: '24px' }}> {/* Adjust width and height as needed */}
                                <Toolbox stroke={getRandomColor()} style={{ width: '100%', height: '100%' }} />
                              </div>



                              <WizrText type='sub1' textColor="#000000" style={{

                                whiteSpace: 'normal', // Allows the text to wrap
                                overflowWrap: 'break-word' // Breaks long words if necessary
                              }}>{item?.tool_name}</WizrText>
                            </WizrFlexLayout>



                            <CreateAgentPopover details={item} onEdit={onEdit} onDelete={onDelete} type={'widget'} />




                          </WizrFlexLayout>}
                          bordered={true}
                          hoverable>
                          <WizrFlexLayout background="#FFF" textAlign="left">
                            {/* <WizrText type='cardData' >{item?.params?.fn_params?.description}</WizrText> */}
                            <WizrText type="cardData">
                              <Tooltip
                                title={item?.params?.fn_params?.description} // Full description shown on hover
                                color="grey"
                              >
                                {item?.params?.fn_params?.description
                                  ? (() => {
                                    const words = item.params.fn_params.description.split(' '); // Split description into words
                                    const truncatedDescription = words.slice(0, 12).join(' '); // Take the first 12 words
                                    return words.length > 12 ? `${truncatedDescription} ...` : truncatedDescription; // Append " ..." if truncated
                                  })()
                                  : ""}
                              </Tooltip>
                            </WizrText>





                          </WizrFlexLayout>
                        </StyledTicketCard>
                          
                        
                        
                        
                        // <StyledTicketCard
                        //   key={index}
                        //   title={
                        //     <WizrFlexLayout background='#FFFFFF' flexDirection='row'>
                        //       <WizrText type='sub2' style={{ padding: '10px 0px' }}>
                        //         {item?.tool_name}
                        //       </WizrText>
                        //       <CreateAgentPopover details={item} onEdit={onEdit} onDelete={onDelete} type={'widget'}/>
                        //     </WizrFlexLayout>
                        //   }
                        //   bordered={true}
                        // >
                        //   <WizrFlexLayout background='#FFF' textAlign='left'>
                        //     <WizrText type='body2'>{item?.params?.fn_params?.description}</WizrText>
                        //   </WizrFlexLayout>
                        // </StyledTicketCard>
                      )
                    })}
                </>
              </WizrFlexLayout>
              <WizrFlexLayout
                background='#FFF'
                justifyContent='flex-start'
                textAlign='left'
                alignContent='start'
                style={{ padding: '12px', gap: '8px' }}
              >
                <WizrText type='body2'>LLM Model </WizrText>
                <StyledWizrDropDown
                  placeHolder='Model'
                  options={normalOptions}
                  onChange={(value: any) => doUpdateFields(`llm_model_id`, value)}
                  value={promptInputState ? promptInputState.llm_model_id : ''}
                  disabled={action === 'view'}
                />
                {SliderData.map((item: any, index: number) => {
                  return (
                    <IndividualSettingsBox style={{ margin: '2px' }} key={index}>
                      <WizrFlexLayout
                        flexDirection='row'
                        justifyContent='space-between'
                        background='#FFF'
                        flex='none'
                        style={{ width: '98%' }}
                      >
                        <WizrText type='body2'>{item}</WizrText>
                        <Tooltip
                          title={
                            <WizrText textColor='white' type='caption'>
                              {findDescription(item)}
                            </WizrText>
                          }
                        >
                          {' '}
                          <InfoIcon />{' '}
                        </Tooltip>
                      </WizrFlexLayout>
                      <IndividualLlmSettingsBox>
                        <WizrSlider
                          min={0}
                          max={findMaxValue(item)}
                          minLabel={findMinLabel(item)}
                          maxLabel={findMaxLabel(item)}
                          value={promptInputState ? promptInputState[item] : 0}
                          defaultValue={item?.value}
                          onSliderChange={(value: any) => doUpdateFields(`${item}`, value)}
                          disabled={action === 'view'}
                        />
                      </IndividualLlmSettingsBox>
                    </IndividualSettingsBox>
                  )
                })}
                <WizrText type='body2'>Max Tokens</WizrText>
                <WizrInput
                  type='number'
                  placeholder='Max Tokens'
                  value={promptInputState?.max_tokens ? promptInputState.max_tokens : ''}
                  onChange={(value: any) => doUpdateFields(`max_tokens`, value)}
                  disabled={action === 'view'}
                />
              </WizrFlexLayout>
            </WizrFlexLayout>
            <WizrFlexLayout
              flexDirection='row'
              justifyContent='end'
              background='#FFF'
              style={{ padding: '16px' }}
            >
              {action !== 'view' ? <WizrButton text={'Save'} onClick={saveAgent} style={{ borderRadius: '12px' }} disabled={loading} /> : <></>}
              {action === 'view' ? <WizrButton text={'Edit'} onClick={editAgent} style={{ borderRadius: '12px' }} disabled={loading} /> : <></>}
            </WizrFlexLayout>
          </WizrFlexLayout>
        </WizrFlexLayout>
      </WizrFlexLayout>
    </div>
  )
}

export default CreateAgent

const StyledPlusButton = styled(WizrButton)`
  background-color: black;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding-right: 8px;
  justify-content: center;
  align-items: center;
`

const StyledTicketCard = styled(Card) <any>`
  background-color: #ffffff;
  width: 50%;
  .ant-card-meta-detail {
    border-bottom: none;
    border-top: none;
    
  }
  .ant-card-head {
    border-bottom: none;
    text-align: start;
    padding: 8px 8px 0px 24px !important;
  }
  .ant-card-body {
    text-align: center;
    padding: 0px 24px 24px 24px;
  }
`

const IndividualSettingsBox = styled.div`
  width: 100%;
  text-align: left;
  height: auto;
  margin: 10px 0px 0px 0px;
  background-color: white;
  padding: 0px;
`

const IndividualLlmSettingsBox = styled.div`
  width: 90%;
  text-align: left;
  height: auto;
  margin: 10px 0px 0px 0px;
`
