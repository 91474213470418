import { useNavigate } from "react-router-dom"
import ExtensionLogo from "../assets/icons/ExtensionLogo"
import WizrButton from "../components/WizrButton"
import WizrFlexLayout from "../components/WizrFlexLayout"
import WizrText from "../components/WizrText"

const WizrNoAccessScreen = ():JSX.Element=> {

    const navigate = useNavigate()

    return (
        <WizrFlexLayout style={{ paddingTop: '100px' }} id='wizr_extension_redirect'>
        <WizrFlexLayout
          alignItems='center'
          style={{ padding: '40px', gap: '40px' }}
          justifyContent='flex-start'
        >
            <WizrText type="h3">404</WizrText>
          <WizrText type='h4'>{`Oops! The page you are looking for doesn't exist.`}</WizrText>
          <WizrButton text='Go Back' onClick={() => navigate(-1)} />
        </WizrFlexLayout>
      </WizrFlexLayout>
    )
}
export default WizrNoAccessScreen