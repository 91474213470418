import styled from 'styled-components'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrTable from '../../../components/WizrTable'
import { useEffect } from 'react'
import { dispatchListTenants } from '../../../redux/actions/content'
import { useContentLoadingSelector, useListTenantSelector } from '../../../redux/selectors/content'
import { Spin } from 'antd'

const StyledWizrTable = styled(WizrTable)`
  .ant-table-wrapper {
    width: 100% !important;
  }
  .ant-table-thead {
    background-color: #f2f4f9;
    border-spacing: 0px;
  }
  .ant-table-tbody > tr {
    background-color: #ffffff;
    margin-bottom: 0px;
  }

  .ant-table table {
    border-spacing: 0 5px;
  }
`


const TenantListing = (): JSX.Element => {
  const loading=useContentLoadingSelector()
  const tenants = useListTenantSelector()
  
  useEffect(() => {
    dispatchListTenants()
  }, [])

  return (
    <WizrFlexLayout style={{ borderRadius: '8px' }} >
      {loading?<Spin/>:
      <StyledWizrTable type='tenants' dataSource={tenants && tenants.sort((a: any, b: any) => b.created_on - a.created_on)} scroll={{ y: 500 }} pagination={false} />}
    </WizrFlexLayout>
  )
}

export default TenantListing
