import { Card } from 'antd'
import { styled } from 'styled-components'
import SparkelsIcon from '../../assets/icons/Sparkels'
import { useState } from 'react'
import WizrFlexLayout from '../../components/WizrFlexLayout'
import WizrText from '../../components/WizrText'
import WizrButton from '../../components/WizrButton'
import { useNavigate } from 'react-router-dom'
import { dispatchCreateApp } from '../../redux/actions/content'
import { useAuth } from '../../authContext'

type MenuType = {
  name?: string
  description: string
  app_name?: string
}

interface IWizrPromptsMenu {
  menuList: MenuType[] | any
  onSelected: (item: any) => void
  //reference of from which page it is called
  tab?: string
}

const StyledCard = styled(Card)<any>`
  background-color: #ffffff;
  width: 100%;
  border-radius: 18px;
  .ant-card-meta-detail {
    border-bottom: none;
    border-top: none;
  }
  cursor: pointer;
  margin: 5px 0px;
  .ant-card-body {
    padding-top: 0px;
  }
  .ant-card-head {
    border-bottom: none;
    margin-bottom: 4px;
  }
`
const StyledWizrButton = styled(WizrButton)`
  border-radius: 20px;
  margin-bottom: 4px;
`

const Title = ({ titleName, onCopyClick, tab }: any) => {
  const { userPermission, hasAcces } = useAuth();
  
  return (
    <WizrFlexLayout flexDirection='row' justifyContent='space-between' background='#FFF'>
      <WizrFlexLayout
        flexDirection='row'
        textAlign='left'
        justifyContent='flex-start'
        alignItems='center'
        background='#FFF'
      >
        <SparkelsIcon stroke='#22C55E' />
        <WizrText type='sub2'>{titleName}</WizrText>
      </WizrFlexLayout>
      <StyledWizrButton type='primary' text='Clone' size='medium' onClick={onCopyClick} disabled={tab === 'appDesigner' ? hasAcces('viewtemplate_app') === true ? false : true : hasAcces('clonetemplate_prompt') === true ? false : true}/>
    </WizrFlexLayout>
  )
}

const WizrPromptsMenu = ({ menuList, onSelected, tab, ...props }: IWizrPromptsMenu): JSX.Element => {
  
  const [selected, setSelected] = useState<number>(0)

  const onItemSelected = (key: number) => {
    setSelected(key)
    onSelected(menuList[key])
  }
  const navigate = useNavigate()

  const goToCreatePrompt = (item: any) => {

    if( tab === 'appDesigner' ){
      const { id, app_id, ...restOfItem } = item;
      const appCreatePayload = {
        ...restOfItem,
        app_name: 'Copy of ' + item?.app_name,
        app_type: 'Tool',
        description: item?.description,
        tags: item?.tags,
        memory: item?.memory,
      }      
      dispatchCreateApp(appCreatePayload)
      navigate('/app-designer/tool', { state: { action: 'copy'} })
    }else{
      localStorage.setItem('prompt_id', item.id)
      localStorage.setItem('action', 'copy')
      item.name
        ? [
            navigate('/prompt-view', {
              state: {
                ...item,
                name: 'Copy of ' + item.  name,
                type: 'template',
                action: 'copy',
                testHistory:false
              },
            }),
          ]
        : {}
    }
  }

  return (
    <WizrFlexLayout
      overFlow='auto'
      alignItems='flex-start'
      alignSelf='stretch'
      justifyContent='space-between'
      textAlign='left'
    >
      {menuList?.length ? (
        menuList.map((item: MenuType, key: number) => {
          return (
            <StyledCard
              bordered={false}
              key={key}
              onClick={(e: any) => onItemSelected(key)}
              num={key}
              isSelected={key === selected}
              title={
                <Title
                  onCopyClick={() => goToCreatePrompt(item)}
                  titleName={item.name || item.app_name}
                  tab={tab}
                >
                  {item?.name || item.app_name}
                </Title>
              }
              {...props}
            >
              <WizrText type='body2'>{item.description}</WizrText>
            </StyledCard>
          )
        })
      ) : (
        <></>
      )}
    </WizrFlexLayout>
  )
}

export default WizrPromptsMenu
