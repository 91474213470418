import { Card } from "antd";
import WizrFlexLayout from "./WizrFlexLayout";
import WizrText from "./WizrText";
import InfoIcon from "../assets/icons/Info";

interface WizrCardProps {
    title?: any;
    description?: any;
    button?: React.ReactNode;
    onClick?: () => void;
    icon?: React.ReactNode;
    width?: string | number; // Define width as string or number
    hoverable?: boolean;
}

const WizrCard: React.FC<WizrCardProps> = ({ title, description, button, onClick, icon, width='23%',hoverable }) => {
    return (
        <Card
            onClick={onClick}
            title={
                <div style={{ gap: '10px', display: 'flex', flexDirection: 'row' }}>
                    <div>{icon}</div>
                    <div>
                        <WizrText type='sub1' textColor="#000000">{title}</WizrText>
                    </div>
                </div>
            }
            extra={button}
            style={{ maxWidth: width, minWidth: width ,borderRadius:"16px"}} // Assign width directly without wrapping in an object
            headStyle={{ border: 'none', padding: '10px 10px 0px 20px' }}
            hoverable = {hoverable}
            // aria-disabled={false}
        >
            <WizrFlexLayout background="white" textAlign="left">
                <WizrText type='cardData'>{description}</WizrText>
            </WizrFlexLayout>
        </Card>
    );
}

export default WizrCard;
