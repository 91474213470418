import { Layout, Form } from 'antd'
import WizrInput from '../components/WizrInput'
import WizrButton from '../components/WizrButton'
import styled from 'styled-components'
import PasswordAvatar from '../assets/icons/PasswordAvatar'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrText from '../components/WizrText'

const FormContainer = styled(Layout)`
  justify-content: center;
  align-items: center;
  align-content: center;
  background: transparent;
`
const ChangePassword = (): JSX.Element => {
  return (
    <WizrFlexLayout background='#FFF'>
      <FormContainer>
        <WizrText type='h6'>Change Password</WizrText>
        <br />
        <WizrText type='body1'>Enter new password below to change your password</WizrText>
        <br />
        <Form name='basic' initialValues={{ remember: true }} autoComplete='off'>
          <Form.Item name='username' rules={[{ required: true, message: 'Username' }]}>
            <WizrInput placeholder='New Password' postComponent={<PasswordAvatar />} />
          </Form.Item>

          <Form.Item
            name='password'
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <WizrInput placeholder='Confirm New Password' postComponent={<PasswordAvatar />} />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <WizrFlexLayout>
              <WizrButton text='Reset Password' />
            </WizrFlexLayout>
          </Form.Item>
        </Form>
      </FormContainer>
    </WizrFlexLayout>
  )
}

export default ChangePassword
