import { Layout, Card, Form } from 'antd'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import WizrButton from '../components/WizrButton'
import WizrInput from '../components/WizrInput'
import UsernameAvatar from '../assets/icons/UsernameAvatar'
import PasswordAvatar from '../assets/icons/PasswordAvatar'
import WizrCheckbox from '../components/WizrCheckbox'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrHeadingText from '../components/WizrHeadingText'
import { useEffect } from 'react'

const LoginCard = styled(Card)`
  width: 30%;
  border-radius: 16px;
  background: #fff;
  text-align: center;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1) !important;
  .ant-card-head {
    border: none !important;
  }
  .ant-card-head-title {
    color: var(--grayscale-900, #1d1f22);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
`

const StyledLayout = styled(Layout)`
  background: transparent !important;
  margin-top: 15px;
`

const Login = (): JSX.Element => {
  const navigation = useNavigate()
  

  const startB2CAuthentication = () => {
    localStorage.setItem("reset","false")
    const authUrl = `${process.env.REACT_APP_WIZR_B2C_AUTH_ENDPOINT}?p=${process.env.REACT_APP_WIZR_B2C_P}&client_id=${process.env.REACT_APP_WIZR_B2C_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_WIZR_B2C_REDIRECT_URI}&nonce=defaultNonce&scope=openid&response_type=id_token&prompt=login`
    window.location.href = authUrl
  }

  useEffect(() => {
    console.log('REACT_APP_WIZR_B2C_AUTH_ENDPOINT', process.env.REACT_APP_WIZR_B2C_AUTH_ENDPOINT);
    if (!localStorage.getItem('id_token')) {
      const authUrl = `${process.env.REACT_APP_WIZR_B2C_AUTH_ENDPOINT}?p=${process.env.REACT_APP_WIZR_B2C_P}&client_id=${process.env.REACT_APP_WIZR_B2C_CLIENT_ID}&nonce=defaultNonce&redirect_uri=${process.env.REACT_APP_WIZR_B2C_REDIRECT_URI}&nonce=defaultNonce&scope=openid&response_type=id_token&prompt=login`;
      window.location.href = authUrl;
    }
  }, [])

  return (
    <WizrFlexLayout
      justifyContent='center'
      alignContent='center'
      alignItems='center'
      background='#FFF'
    >
      <LoginCard
        title={<WizrHeadingText type='h5'>Session expired. Please click here to login</WizrHeadingText>}
        bordered={false}
      >
        <WizrFlexLayout background='#FFF'>
        
            
            <WizrFlexLayout background='#FFF'>
                <StyledLayout>
                  <WizrButton text='Login' onClick={startB2CAuthentication} />
                </StyledLayout>
            </WizrFlexLayout>
          
        </WizrFlexLayout>
      </LoginCard>
    </WizrFlexLayout>
  )
}

export default Login
