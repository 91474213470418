import { Tag, Space } from 'antd'
import { styled } from 'styled-components'
import { useEffect, useState } from 'react'
import WizrText from '../WizrText/WizrText'

interface IWizrTag {
  data: string[] | any
  fontSize?: number
  borderRadius?: number
  onClick?: any
}
const { CheckableTag } = Tag

const StyledTags = styled(CheckableTag)<any>`
  border-radius: ${(p) => (p.borderRadius ? p.borderRadius + 'px' : '16px')};
  border: 0px solid #000;
  background-color: ${(p) => (p.checked ? 'black' : '#EAECF1')};
  font-size: ${(p) => (p.fontSize ? p.fontSize + 'px' : '16px')};
  padding: 3px 11px;
`

const StyledSpace = styled(Space)`
  overflow: auto;
  width: 100% !important;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`

const WizrTag = ({ data, onClick, ...props }: IWizrTag): JSX.Element => {
  const [checked, setChecked] = useState<any>(0)

  useEffect(() => {
    setChecked(data?.[2])
  }, [])

  return (
    <StyledSpace direction='horizontal' size='small'>
      {data?.length ? (
        data.map((item: any) => {
          return (
            <StyledTags
              key={item}
              checked={checked === item}
              onChange={() => {
                setChecked(item)
                onClick(item)
              }}
              {...props}
            >
              <WizrText type='sub2' textColor={checked === item ? '#FFF' : '#000'}>
                {item}
              </WizrText>
            </StyledTags>
          )
        })
      ) : (
        <></>
      )}
    </StyledSpace>
  )
}

export default WizrTag
