import { ArrowLeftOutlined } from '@ant-design/icons'
import WizrButton from '../../../../components/WizrButton'
import WizrFlexLayout from '../../../../components/WizrFlexLayout'
import WizrUserJourney from '../../../../components/WizrUserJourney'
import WizrText from '../../../../components/WizrText'
import { useNavigate } from 'react-router-dom'
import WizrInput from '../../../../components/WizrInput'
import styled from 'styled-components'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import CreateNew from '../../../../assets/icons/CreateNew'
import { Button, Tooltip } from 'antd'
import DeleteIcon from '../../../../assets/icons/DeleteIcon'
import WizrTextArea from '../../../../components/WizrTextArea'
import WizrSlider from '../../../../components/WizrSlider'
import InfoIcon from '../../../../assets/icons/Info'
import WizrDropDown from '../../../../components/WizrDropDown'
import { useContentsSelector, useLLMModelSelector } from '../../../../redux/selectors/content'
import { useEffect, useState } from 'react'
import { dispatchLLMModels, dispatchListFolders } from '../../../../redux/actions/content'
import WizrTagInput from '../../../../components/WizrTagInput'

const StyledWizrInput = styled(WizrInput)`
width: 47%;
background:#F2F4F9;`

const StyledWizrButton = styled(WizrButton)`
  height:20px;
  width:20px;
  border-radius:50px;
  padding: 5.833px ;
  justify-content: center;
  align-items: center;
  padding-left:13px;
`

const KnowledgeAssistWidget = ({promptInputState, setPromptInputState}: any) => {

  const navigate = useNavigate()

  const SliderData: any = ["frequency_penalty", "presence_penalty", "temperature", "top_p"]

  const findDescription = (des: any) => {
    switch (des) {
      case "temperature":
        return " It controls the randomness of the response .A lower value makes answers more focused and consistent, while a higher value adds variety and creativity. It is advisable to modify either the temperature or the Top P value, but not both simultaneously, to achieve desired outcomes.{' '}"
      case "frequency_penalty":
        return " It reduces the likelihood of generating words that are common in the training  data. A higher frequency penalty value will result in the model being more conservative in its use of repeated tokens. A lower frequency penalty value will  result in the model being more likely to use common words or phrases"
      case "presence_penalty":
        return "A higher frequency penalty value will result in the model being more conservative in its use of repeated tokens. A lower frequency penalty value will result in the  model being more likely to use common words or phrases"
      case "top_p":
        return " It is also used to control randomness. When you decrease Top P, the model sticksto more expected words. On the flip side, raising Top P lets the model choose fromboth common and uncommon words. Remember, it is best to tweak either temperature or Top P, but not both, for effective adjustments."
      // case "best_of":
      //     return "Best of"
      // case "max_tokens":
      //     return "Max Tokens"
      // case "post_response_text":
      //     return "Post Response Text"
      // case "pre_response_text":
      //     return "Pre Response Text"
    }
  }

  const findMaxValue = (name: any) => {
    switch (name) {
      case "temperature":
      case "top_p":
        return 1;
      case "frequency_penalty":
      case "presence_penalty":
        return 2;
      default:
        return 0; // Default value, you might want to adjust it based on your requirements
    }
  };
  const findMinLabel = (name: any) => {
    switch (name) {
      case "temperature":
        return "FOCUSED"
      case "top_p":
        return "NARROW";
      case "frequency_penalty":
        return "REPETITIVE"
      case "presence_penalty":
        return "CONSISTENT";
      default:
        return ""
    }
  };
  const findMaxLabel = (name: any) => {
    switch (name) {
      case "temperature":
        return "CREATIVE"
      case "top_p":
        return "BROAD";
      case "frequency_penalty":
        return "DIVERSE"
      case "presence_penalty":
        return "NOVAL"
      default:
        return ""

    }
  };

  useEffect(() => {
    dispatchLLMModels()
    dispatchListFolders('knowledge_base')
  }, [])

  const LLMModelData = useLLMModelSelector()  

  const normalOptions = LLMModelData?.map((item: any) => {
    return { label: item.display_name, value: item.id, model_type: item.model_type }
  })

  const [modelName, setModelName] = useState<any>({})
  

  const doUpdateFields = (fieldName: any, fieldValue: any) => {
    setPromptInputState({
      ...promptInputState,
      [fieldName]: fieldValue,
    })
  }

  const folderData = useContentsSelector()
  const folderList = folderData?.map((item: any) => ({
    label: item.folder_name,
    value: item.folder_id,
    // disabled: getDisabledStatus(item)
  }))

  // const [currentembeddingModel,setCurrentEmbeddingModel] = useState<any>(promptInputState?.embedding_model_id)


  const selectKnowledgeBase = (value: any) => {
    if (value.length === 1) {
      const embeddingId = folderData?.find((item: any) => item.folder_id === value[0])?.embedding_model_id
      // setCurrentEmbeddingModel(embeddingId)
      // doUpdateFields('embedding_model_id', embeddingId)
      setPromptInputState({ ...promptInputState, embedding_model_id: embeddingId, llm_folder_id_list: value })
    }
    else if (value.length === 0) {
      // setCurrentEmbeddingModel('')
      // doUpdateFields('embedding_model_id', '')
      setPromptInputState({ ...promptInputState, embedding_model_id: '', llm_folder_id_list: value })
    } else {
      doUpdateFields('llm_folder_id_list', value)
    }
  }

  return (
    <WizrFlexLayout background='#FFF' textAlign='left' >
      <IndividualSettingsBox style={{padding: '8px 0px'}}>
        <WizrText type='body2'>LLM Model </WizrText>
          <WizrDropDown
            placeHolder='Model'
            options={normalOptions}
            onChange={(value: any) => doUpdateFields(`llm_model_id`, value)}
            value={promptInputState ? promptInputState.llm_model_id : ''}
          />
      </IndividualSettingsBox>
      <IndividualSettingsBox style={{padding: '8px 0px'}}>
      <WizrText type='sub1'>Knowledge base</WizrText>
      <WizrTagInput
                options={folderList}
                value={promptInputState?.llm_folder_id_list}
                onChange={(value: any) => selectKnowledgeBase(value)}
                placeHolder='Select folder(s)'
              />
      </IndividualSettingsBox>
      <WizrText type='body2'>System Message</WizrText>
      <WizrTextArea background='#F2F4F9' border='0px #F2F4F9' minRows={5} onChange={(value: any) => doUpdateFields(`system_message`, value)} value={promptInputState ? promptInputState?.system_message : ""}/>
      {SliderData.map((item: any, index: number) => {
        return (
          <IndividualSettingsBox style={{ margin: "2px" }} key={index}>
            <WizrFlexLayout
              flexDirection='row'
              justifyContent='space-between'
              background='#FFF'
              flex='none'
              style={{ width: "98%" }}
            >
              <WizrText type='body2'>{item}</WizrText>
              <Tooltip
                title={
                  <WizrText textColor='white' type='caption'>
                    {findDescription(item)}
                  </WizrText>
                }
              >
                {' '}
                <InfoIcon />{' '}
              </Tooltip>
            </WizrFlexLayout>
            <IndividualLlmSettingsBox>
              <WizrSlider
                min={0}
                max={findMaxValue(item)}
                minLabel={findMinLabel(item)}
                maxLabel={findMaxLabel(item)}
                value={promptInputState ? promptInputState[item] : 0}
                defaultValue={item?.value}
              onSliderChange={(value: any) => doUpdateFields(`${item}`, value)}
              />
            </IndividualLlmSettingsBox>
          </IndividualSettingsBox>
        );
      })}
      <IndividualSettingsBox style={{padding: '8px 0px'}}>
        <WizrText type='body2'>Max Tokens</WizrText>
          <WizrInput
          type='number'
              placeholder='Max Tokens'
              value={promptInputState?.max_tokens ? promptInputState.max_tokens : ''}
              onChange={(value: any) => doUpdateFields(`max_tokens`, value)}
          />
      </IndividualSettingsBox>
      <IndividualSettingsBox style={{padding: '8px 0px'}}>
      <WizrText type='body2'>Match Threshold</WizrText>
        <WizrInput
          type='number'
          max={1.0}
          min={0.0}
          placeholder='Token Limit'
          step={0.1}
          value={promptInputState?.match_threshold}
          onChange={(value: number) => {
            const numericValue = Number(value); // Convert to a number
            const sanitizedValue = numericValue > 1 ? 1 : numericValue < 0 ? 0 : numericValue;

            doUpdateFields('match_threshold', sanitizedValue)}}
        />
      </IndividualSettingsBox>
      <IndividualSettingsBox style={{padding: '8px 0px'}}>
      <WizrText type='body2'>Top K</WizrText>
      <WizrInput
          type='number'
          placeholder='Token Limit'
          max={15}
          min={1}
          step={1}
          value={promptInputState?.top_k}
          onChange={(value: number) => {
            const numericValue = Number(value); // Convert to a number
    const sanitizedValue = numericValue > 15 ? 15 : numericValue < 1 ? 1 : numericValue;
    doUpdateFields('top_k', sanitizedValue);
          }}
         />{' '}
      </IndividualSettingsBox>
    </WizrFlexLayout>
  );
};

export default KnowledgeAssistWidget;


const IndividualSettingsBox = styled.div`
  width: 100%;
  text-align: left;
  height: auto;
  margin: 10px 0px 0px 0px;
  background-color:white;
  padding:0px;
`

const IndividualLlmSettingsBox = styled.div`
  width: 90%;
  text-align: left;
  height: auto;
  margin: 10px 0px 0px 0px;
`
