import { useEffect } from "react"
import WizrFlexLayout from "../../../components/WizrFlexLayout"
import { dispatchAccessList, dispatchRolesList } from "../../../redux/actions/content"
import styled from "styled-components"
import WizrTable from "../../../components/WizrTable"
import { useContentLoadingSelector, useListRolesSelectors } from "../../../redux/selectors/content"
import { Spin } from "antd"
const StyledWizrTable = styled(WizrTable)`
  .ant-table-wrapper {
    width: 100% !important;
  }
  .ant-table-thead {
    background-color: #f2f4f9;
    border-spacing: 0px;
  }
  .ant-table-tbody > tr {
    background-color: #ffffff;
    margin-bottom: 0px;
  }

  .ant-table table {
    border-spacing: 0 5px;
  }
`

const RolesListing=():JSX.Element=>{
    const loading=useContentLoadingSelector()
    const roles = useListRolesSelectors()

    useEffect(() => {
     dispatchAccessList()
     dispatchRolesList()
    }, [])
    
    return(
        <WizrFlexLayout>
             {loading?<Spin/>:
      <StyledWizrTable type='roles' dataSource={roles} scroll={{ y: 500 }} pagination={false} />}
        </WizrFlexLayout>
    )
}
export default RolesListing