import { Popover } from 'antd'
import WizrButton from '../WizrButton'
import WizrFlexLayout from '../WizrFlexLayout'
import { useWizrModalContext, MODAL_TYPES } from '../../providers/modal/WizrModalProvider'
import CreateNew from '../../assets/icons/CreateNew'
import { useNavigate } from 'react-router-dom'
import { dispatchCreateApp } from '../../redux/actions/content'
import styled from 'styled-components'
import { useAuth } from '../../authContext'

const CreateAppContent = (): JSX.Element => {
  const { showModal, hideModal } = useWizrModalContext()
  const navigate = useNavigate()
 
  const onCreate = () => {
    showModal(MODAL_TYPES.CREATE_APP, {
      onFormSubmission: (appData: any) => {        
        const appCreatePayload = {
          app_version: '0',
          app_name: appData?.appName,
          description: appData?.appDescription,
          tags: appData?.appTags,
          memory: appData?.memory || false,
          app_type: 'Tool',
          app_feature: appData?.app_feature || '',
          app_industry: appData?.app_industry || '',
          app_category: appData.app_category || 'general'
        }                             
        dispatchCreateApp(appCreatePayload)
        hideModal()
        navigate('/app-designer/tool', { state: { action: 'create' } })
      },
      onCancel: hideModal,
    })
  }

  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      <WizrButton text='Tools' type='text' onClick={onCreate} justifyContent='flex-start' />

      <WizrButton text='Chat Bot' type='text' onClick={() => {}} justifyContent='flex-start' />
    </WizrFlexLayout>
  )
}

const CreateAppPopover = (): JSX.Element => {
  const StyledWizrButton= styled(WizrButton)`
  border-radius: 20px;`
  const { userPermission, hasAcces } = useAuth();
  return (
    <Popover content={<CreateAppContent />} title='' trigger='click'>
      <StyledWizrButton type='primary' icon={<CreateNew fill='white' />} text='Create New' disabled={hasAcces('create_app') === true ? false : true}/>
    </Popover>
  )
}

export default CreateAppPopover
