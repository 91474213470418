import { Popover } from 'antd'
import WizrButton from '../WizrButton'
import MoreIcon from '../../assets/icons/More'
import EditIcon from '../../assets/icons/EditIcon'
import { dispatchSaveCurrentAppDetails, dispatchDeleteApp, dispatchDisableTenants, dispatchDeleteTenants, dispatchListTenants, dispatchupdateUserView, dispatchEnableTenants } from '../../redux/actions/content'
import Copy from '../../assets/icons/Copy'
import { useNavigate } from 'react-router-dom'
import WizrFlexLayout from '../../stories/WizrFlexLayout/WizrFlexLayout'
import DeleteIcon from '../../assets/icons/DeleteIcon'
import { act } from 'react-dom/test-utils'
import { useCurrentAppDetailsSelector, useGetViewUserSelector } from '../../redux/selectors/content'
import View from '../../assets/icons/View'
import Disable from '../../assets/icons/Disable'
import { useEffect } from 'react'
import { MODAL_TYPES, useWizrModalContext } from '../../providers/modal/WizrModalProvider'
import styled from 'styled-components'
import { useAuth } from '../../authContext'

// const currentAppDetails = useCurrentAppDetailsSelector()


const TenantActionPopoverContent = (tenantDetails:any): JSX.Element => { 
  const { showModal, hideModal } = useWizrModalContext()
  const { userPermission, hasAcces } = useAuth();
  const navigate = useNavigate()
  const onEditAction=()=>{
    navigate('/tenantdetails', { state: { action: 'edit' ,details:tenantDetails?.tenantDetails} })
  }
  const onViewAction=()=>{    
    dispatchupdateUserView({
      activeKey:"users",
      tenantId:tenantDetails?.tenantDetails?.tenant_id
    })
  }
  const onDeleteAction = async()=>{
    showModal(MODAL_TYPES.CONFIRMATION, {
      text:`Are you sure you want to delete ${tenantDetails?.tenantDetails?.tenant_name}`,
      buttontext:"Confirm",
  onConfirm:deleteTenant,
  onCancel:hideModal
  
    }) 
  }
  const deleteTenant= async()=>{    
    hideModal()
    await dispatchDeleteTenants(tenantDetails?.tenantDetails?.id)
    await dispatchListTenants()
  }
  const onDisableAction=async()=>{   
    if (tenantDetails?.tenantDetails?.status==="active"){
   await dispatchDisableTenants(tenantDetails?.tenantDetails?.id)}
   else{
    await dispatchEnableTenants(tenantDetails?.tenantDetails?.id)
   }
   await dispatchListTenants()

  }
  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
        
    <WizrButton
        icon={<EditIcon />}
        text='Edit'
        type='text'
        onClick={()=>{onEditAction()}}
        justifyContent='flex-start'
        disabled={hasAcces('edittenants_settings')  === true ? false : true}

      />
      <WizrButton
        icon={<View />}
        text='View User'
        type='text'
        onClick={()=>{onViewAction()}}
        justifyContent='flex-start'
        disabled={hasAcces('view_users')  === true ? false : true}

      />
      <WizrButton
        icon={<Disable />}
        text={tenantDetails?.tenantDetails?.status==="active"?'Disable':"Enable"}
        type='text'
        onClick={()=>{onDisableAction()}}
        justifyContent='flex-start'
        disabled={hasAcces('enabledisabletenants_settings')  === true ? false : true}

      />
      <WizrButton
        icon={<DeleteIcon />}
        text='Delete'
        type='text'
        onClick={()=>{onDeleteAction()}}
        justifyContent='flex-start'
        disabled={hasAcces('deletetenants_settings')  === true ? false : true}

      />
    </WizrFlexLayout>
  )
}

const TenantActionPopOver = (tenantDetails:any): JSX.Element => {


  return (
    <Popover
      content={
        <TenantActionPopoverContent
        tenantDetails={tenantDetails?.tenantDetails}
        />
      }
      trigger='click'
    >
      <StyledButton icon={<MoreIcon />} text='' type='text' style={{height: '40px', width: '40px', borderRadius: '50px'}}/>
    </Popover>
  )
}

export default TenantActionPopOver

const StyledButton = styled(WizrButton)`
border-radius: 50%;
width: 20px;
height: 20px;
padding-right: 8px;
justify-content: center;
align-items: center;
  /* Default styles */
  path {
    stroke: #999B9F; /* Default stroke color */
    transition: stroke 0.3s ease; /* Smooth transition for color change */
  }

  /* Hover styles */
  &:hover path {
    stroke: #1D1F22
  }
`;



