import { Typography, TypographyProps } from 'antd'
import { styled } from 'styled-components'

const { Title } = Typography

type ITextType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5'

interface IWizrHeadingText {
  children: any
  type: ITextType
  textColor?: string
}

const titleMapping: { [key: string]: any } = {
  h1: {
    level: 1,
    color: '#000',
    fontSize: '97px',
    lineHeight: '112px',
    letterSpacing: '-1.5px',
    fontWeight: 300,
  },
  h2: {
    level: 2,
    color: '#1F1D1D',
    fontSize: '61px',
    lineHeight: '72px',
    letterSpacing: '-0.5px',
    fontWeight: 300,
  },
  h3: {
    level: 3,
    color: '#1F1D1D',
    fontSize: '48px',
    lineHeight: '56px',
    letterSpacing: '0px',
    fontWeight: 400,
  },
  h4: {
    level: 4,
    color: '#1F1D1D',
    fontSize: '34px',
    lineHeight: '34px',
    letterSpacing: '0.25px',
    fontWeight: 700,
  },
  h5: {
    level: 5,
    color: '#1F1D1D',
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    fontWeight: 700,
  },
}

const StyledTitle = styled(Title)<any>`
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Montserrat;
  font-style: normal;
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : 300)};
  color: ${(p) => (p.color ? p.color : '#000')};
  font-size: ${(p) => (p.fontSize ? p.fontSize : '12px')};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : '0px')};
  letter-spacing: ${(p) => (p.letterSpacing ? p.letterSpacing : '0px')};
`

const WizrHeadingText = ({ children, type, textColor, ...props }: IWizrHeadingText) => {
  const headerTextProps = titleMapping[type]
  const { level, color, fontSize, lineHeight, letterSpacing, fontWeight } = headerTextProps

  return (
    <StyledTitle
      level={level}
      type={type}
      color={textColor || color}
      fontSize={fontSize}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      fontWeight={fontWeight}
      {...props}
    >
      {children}
    </StyledTitle>
  )
}

export default WizrHeadingText
