import styled from 'styled-components'
import Hamburger from '../assets/icons/Hamburger'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrText from '../components/WizrText'
import Box from '../assets/icons/Box'
import WizrButton from '../components/WizrButton'
import WizrIcon from '../components/WizrIcon'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Spin, Tooltip } from 'antd'
import AIIcon from '../assets/icons/AIIcon'
import { useEffect } from 'react'
import { dispatchAppData, dispatchAppPublished, dispatchCurrentModule,  } from '../redux/actions/content'
import { useContentLoadingSelector, useappDataSelector } from '../redux/selectors/content'
import WizrTag from '../components/WizrTags'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useAuth } from '../authContext';
import WizrCard from '../components/Wizrcard'
import NotesIcon from '../assets/icons/Notes-lines'

const StyledWizrText = styled(WizrText)`
padding :0px 20px `
const StyledContentCard =  styled(WizrFlexLayout)`
padding: 30px 42px 30px 40px;
flex-direction: column;
justify-content: center;
align-items: flex-start;

border-radius: 16px;
border: 1px solid var(--generic-stroke, #E5E7EB);
background: var(--grayscale-50, #F9FAFA);
width:70%
`
const StyledWizrButton = styled(WizrButton)`
  border-radius: 20px;
  height: 30px;
  margin-bottom: 3px;
  .anticon {
    color: white;
  }
`
const StyledCard = styled(Card)`
display: flex;
width: 23%;
height: 80%

flex-direction: row;

border-radius: 16px;
background: var(--generic-white, #FFF);
`
const DashboardPage = (): JSX.Element => {

  const { userPermission, hasAcces } = useAuth();
  const navigate = useNavigate()
  useEffect(() => {
    dispatchAppPublished()
  }, [])
  const data= useappDataSelector()
  
  const appData :any=  data  && data.slice(0,11)

  const loading = useContentLoadingSelector()
  const onRunClick=(item:any)=>{
    navigate("/bulkrun",{state:{item}})
}


  return (
    <div  style={{overflowX:"hidden",overflowY:"auto",paddingTop:"20px"}}>      
      <WizrFlexLayout  textAlign='left' style={{marginLeft:"30px",minHeight:"60px"}} flex={1}>
      <WizrText type='h4'>Build Incredible Generative AI Apps with Wizr AI Studio</WizrText>
    </WizrFlexLayout>
    <WizrFlexLayout 
     flexDirection='row' style={{gap: "16px",minHeight:"185px"}} flex={1}>
   
    <StyledContentCard   style={{textAlign:"left",gap:"10px",marginLeft:"30px"}}>
      <WizrFlexLayout flexDirection='row' justifyContent='flex-start' style={{gap:"10px",minHeight:"23px"}} background='#F9FAFA'>
      <Box/>
      <WizrText type='h7'>Apps</WizrText>
      </WizrFlexLayout >
        <WizrText type='body2' >
        Ready to transform your app idea into reality? Explore our predefined business use-case templates
        </WizrText>
        <StyledWizrButton
          type='primary'
          text='Explore'
          disabled={hasAcces('listapps_home') === true ? false : true}
          onClick={()=>{navigate("/app-designer")
            dispatchCurrentModule("prompt")
          }}
        />
      </StyledContentCard>
      <StyledContentCard  style={{textAlign:"left",gap:"10px",marginRight:"30px"}} >
      <WizrFlexLayout flexDirection='row' justifyContent='flex-start' style={{gap:"10px",minHeight:"23px"}} background='#F9FAFA'>
      <NotesIcon stroke={'black'} />
      <WizrText type='h7'>Content</WizrText>
      </WizrFlexLayout >
        <WizrText type='body2' >
        Upload your content into our file management system. This will allow your apps to retrieve and answer questions from your knowledge.
        </WizrText>
        <StyledWizrButton
          type='primary'
          text='Manage Content'
          onClick={()=>{navigate("/folder")
          dispatchCurrentModule("folder")

          }}
          disabled={hasAcces('listfolder_home') === true ? false : true}
        />
      </StyledContentCard>
    
    </WizrFlexLayout>
    <WizrFlexLayout  style={{padding:"15px",textAlign:"left",marginLeft:"15px"}}  flex={10}>
      <WizrText type='h6'>
        Your Apps
      </WizrText>
      {loading?<WizrFlexLayout><Spin/></WizrFlexLayout>:<div
      style={{
        display: 'flex',
        flex: '1',
        flexDirection: 'row',
        textAlign: 'left',
        justifyContent: 'flex-start',
        gap: '16px',
        flexWrap: 'wrap',
        paddingTop:'10px',
      }}
    >
      {appData &&
        appData?.map((item: any, index: any) => {
          return (
            <WizrCard
            hoverable = {true}
            width={'23.5%'}
            title={<WizrFlexLayout flexDirection='column' background='white' style={{paddingTop:"10px"}}>
                <WizrFlexLayout background='white' >    
                  <div style={{height:'45px' ,width:'45px',borderRadius:"25px",background:"#F2F4F9",justifyContent:"center",alignContent:"center",paddingTop:"3px"}}>
<AIIcon height='34' width='34'/></div>
</WizrFlexLayout>
<WizrFlexLayout background='white' style={{ paddingTop:'5px', textAlign:'left' }}>
  <WizrText style={{ wordWrap: 'break-word',whiteSpace:'normal', overFlowWrap:'break-word' }} type='sub2'>{item?.app_name}</WizrText>
</WizrFlexLayout>
              </WizrFlexLayout>
              }
              description={<WizrFlexLayout flexDirection='column' background='white'  textAlign='left' alignContent='space-between'>
              <WizrFlexLayout background='white' textAlign='left' style={{maxHeight:"120px",minHeight:"120px"}}>    
              <WizrText type='caption'>Version {item?.app_version}</WizrText> 
    <div>
    <WizrTag type = "colorfull" data={item?.tags}/></div>
    <WizrText type="body2">
    <Tooltip title={item?.description} color='grey'>
    {item?.description
  ? (() => {
      const words = item.description.split(' '); // Split the description into an array of words
      const truncatedDescription = words.slice(0, 12).join(' '); // Take the first 10 words and join them into a string
      return words.length > 12 ? truncatedDescription + ' ...' : truncatedDescription;
    })()
  : ""
}  </Tooltip>
    </WizrText>
    </WizrFlexLayout>
   
            </WizrFlexLayout>}

onClick={()=>{onRunClick(item)}}

              key={index}
            />
          )
        })}
        <StyledCard style={{backgroundColor:"#F9FAFA"}}
       >
        <WizrText type="body1">Explore</WizrText>
        <WizrFlexLayout background='#F9FAFA' alignContent='center' justifyContent='center'alignItems='center' style={{height:"80%",width:"auto"}}>
          <Button style={{width:"80px",height:"80px",borderRadius:"50px",backgroundColor:"#F2F4F9"}}
          icon={<ArrowRightOutlined style={{ fontSize: '35px',color:"grey" }}/>}
          onClick={()=>{
            navigate("/app-designer")
          }}
          ></Button>
        </WizrFlexLayout>
       </StyledCard>
    </div>
    }
      
    </WizrFlexLayout>
    </div>
  )
}

export default DashboardPage
