import { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import ReactFlow, { useNodesState, useEdgesState, addEdge, Panel, Controls, useViewport, useReactFlow, Background, } from 'reactflow'
import { initialNodes, initialEdges } from '../utils/appDesignConfigs'
import styled from 'styled-components'
import 'reactflow/dist/style.css'
import {
  dispatchCreateApp,
  dispatchListFolders,
  dispatchUpdateApp,
  dispatchAppDetails,
  dispatchPublishApp,
  dispatchAppVersions,
  dispatchPromptPublished,
  dispatchPublishedAppEditDetails,
  dispatchSetAsDefault,
  dispatchSetAsTemplate
} from '../redux/actions/content'
import {
  useContentLoadingSelector,
  useCurrentAppDetailsSelector,
  usePromtTemplateSelector,
  useAppDesignDataSelector,
  useAppVersionSelector,
} from '../redux/selectors/content'
import { Spin, Layout, Space, Dropdown, Menu, Popover } from 'antd'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrButton from '../components/WizrButton'
import SettingsIcon from '../assets/icons/Setting'
import PlayIcon from '../assets/icons/PlayIcon'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import OutputNode from './components/AppDesignerTool.tsx/OutputNode'
import StartNode from './components/AppDesignerTool.tsx/StartNode'
import LLMNode from './components/AppDesignerTool.tsx/LLMNode'
import ApiBlock from './components/AppDesignerTool.tsx/ApiBlock'
import ToolBar from './components/AppDesignerTool.tsx/ToolBar'
import CodeNode from './components/AppDesignerTool.tsx/CodeBlock'
import TestArea from './components/AppDesignerTool.tsx/TestArea'
import EditArea from './components/AppEditTools/AppEditArea'
import ButtonEdge from './components/AppDesignerTool.tsx/ButtonEdge'
import VisionNode from './components/AppDesignerTool.tsx/VisionBlock'
import VoiceBlock from './components/AppDesignerTool.tsx/VoiceBlock'
import { ArrowLeftOutlined, DownOutlined } from '@ant-design/icons'
import { useWizrModalContext, MODAL_TYPES } from '../providers/modal/WizrModalProvider'
import WizrText from '../components/WizrText'
import { useAuth } from '../authContext'
import VectorBlock from './components/AppDesignerTool.tsx/VectorBlock'
import { v4 as uuidv4 } from 'uuid'
import { getUserRole } from '../utils'
import { set } from 'react-hook-form'
import EditIcon from '../assets/icons/EditIcon'


const { Sider } = Layout

const StyledSider = styled(Sider)`
position:absolute !important;
right:0;
top:60px !important;
height:calc(100% - 60px) !important ;
  .ant-layout-sider-children {
    background: #fff;
    display: flex;
  }
  padding: 10px 0px 20px 0px;
  
  background: #fff !important;
  .sc-egWZns hPXWyL{
    margin-left:20px;
  }
`
const Styledinput = styled.input`

	padding: 0;
	background: #F2F4F9;
	border: none;
	border-radius: 0;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
 `

const AppDesignerTool = (): JSX.Element => {

  const [showTestArea, setShowTestArea] = useState(false)
  const [showNodeEdit, setShowNodeEdit] = useState(false)
  const [nodes, setNodes, onNodesChange] = useNodesState<any>(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)
  const publishedPromptData = usePromtTemplateSelector()
  const isContentLoading = useContentLoadingSelector()
  const appDesignData = useAppDesignDataSelector()
  const [rfInstance, setRfInstance] = useState<any>(null)
  const location = useLocation()
  const currentAppDetails = useCurrentAppDetailsSelector()    
  const loading = useContentLoadingSelector()
  const currentAppVersionsDetails = useAppVersionSelector()  

  const [isPublished, setIsPublished] = useState(false)
  const [isDefault, setIsDefault] = useState(false)

  const [editData, setEditData] = useState<any>({})
const [unSavedDataPresent,setUnSavedDataPresent] = useState(false)

  const rfFlow = rfInstance?.toObject()

// useEffect(() => {
// }, [unSavedDataPresent])

  type SelectedVersionType = {
    app_version: string; // Change the type to match your data structure
  };

  const navigate = useNavigate()


  const { id, action, app_id, } = useParams()
  

  const appAction = location?.state?.action || action
  const Id = location?.state?.id || id || ''
  const appId = location?.state?.appId || app_id || ''   
  const type = location?.state?.type || '' 
  


  const { showModal, hideModal } = useWizrModalContext()
  const { setViewport } = useReactFlow()

  const [appName, setAppName] = useState();
  const [startParameters, setStartParameters] = useState()

  useEffect(() => {
    const params = (currentAppDetails?.nodes && currentAppDetails?.nodes[0]?.data?.config?.parameters || []).map((item: any) => item?.name);
    setStartParameters(params)
  }, [currentAppDetails?.nodes && currentAppDetails?.nodes[0]?.data?.config?.parameters])

  // useEffect(() => {
  //   // Your logic to trigger layout recalculation based on changes in defaultNodes or defaultEdges;
  //   // Call useLayout() when there are changes in defaultNodes or defaultEdges
  //   useLayout();
  // }, [nodes, edges]);  
  

  const nodeTypes = useMemo(
    () => ({
      start: (props: any) => (
        <StartNode allNodeData={nodes} updateNodeData={setNodes} {...props} updateEdgeData={setEdges} isDisabled={!isDefault} onEdit={onEdit} />
      ),
      llm: (props: any) => <LLMNode updateNodeData={setNodes} updateEdgeData={setEdges} onEdit={onEdit} onCopyNodeDetail={onCopyNodeDetail} isDisabled={!isDefault} {...props} />,
      code: (props: any) => <CodeNode updateNodeData={setNodes} updateEdgeData={setEdges} onEdit={onEdit} onCopyNodeDetail={onCopyNodeDetail} isDisabled={!isDefault} {...props} />,
      api: (props: any) => < ApiBlock allNodeData={nodes} updateNodeData={setNodes} updateEdgeData={setEdges} onCopyNodeDetail={onCopyNodeDetail} isDisabled={!isDefault} onEdit={onEdit} {...props} />,
      vision: (props: any) => < VisionNode allNodeData={nodes} updateNodeData={setNodes} onEdit={onEdit} onCopyNodeDetail={onCopyNodeDetail} isDisabled={!isDefault} {...props} />,
      transcription: (props: any) => <VoiceBlock allNodeData={nodes} updateNodeData={setNodes} onEdit={onEdit} onCopyNodeDetail={onCopyNodeDetail} isDisabled={!isDefault} {...props} />,
      vector: (props: any) => <VectorBlock allNodeData={nodes} updateNodeData={setNodes} onEdit={onEdit} onCopyNodeDetail={onCopyNodeDetail} isDisabled={!isDefault} {...props} />,
      end: (props: any) => (
        <OutputNode allNodeData={nodes} updateNodeData={setNodes} isDisabled={!isDefault} {...props} />
      ),
    }),
    [setNodes, isDefault],
  )

  const edgeTypes = useMemo(
    () => ({
      buttonedge: (props: any) => <ButtonEdge updateEdgeData={setEdges} updateNodeFromEdge={onToolBarItemClick} isDisabled={isDefault} {...props} setUnSavedDataPresent={setUnSavedDataPresent}/>,
    }), [setEdges, isDefault],
  )

  const onConnect = useCallback((params: any) => {
    const customEdge = {
      ...params,
      id: `edge_${params.source}-${params.target}`,
      type: 'buttonedge',
    };

    setEdges((eds) => addEdge(customEdge, eds));

  }, [edges, setEdges]);

  useEffect(() => {
    setAppName(currentAppDetails?.app_name)
  }, [currentAppDetails?.app_name]);

  const onEdit = (modal: any, data: any) => {
    setShowNodeEdit(true)
    setEditData(data)
  }

  const onSave = () => {
setUnSavedDataPresent(false)
    const updatedRfFlow = {
      ...rfFlow,
      edges: edges,
      nodes: nodes,
    };

    delete updatedRfFlow.enabled

    if(isPublished && currentAppDetails?.app_version !== 'draft'){      
      currentAppDetails.id = uuidv4()
      currentAppDetails.app_version = 'draft'
      currentAppDetails.published = false
      currentAppDetails.default = false
    }else if(isPublished){
      currentAppDetails.app_version = 'draft'
    }

    const saveAppPayload = {
      ...currentAppDetails,
      ...updatedRfFlow,
      app_name: appName,
      updated_on: Date.now(),
    } 
    dispatchUpdateApp(saveAppPayload)
    
  }

  const onLaunch = () => {
    let launchAppPayload;
     launchAppPayload = {
      "id": currentAppDetails?.id,
      "published_on": Date.now(),
      "app_id": currentAppDetails?.app_id,
    }
    if (currentAppDetails?.type==='template'){
      launchAppPayload = {...launchAppPayload,
        tenant_id: "WIZR-BASIC-TENANT"
      }
    }
    dispatchPublishApp(launchAppPayload)
    if (currentAppDetails?.app_id) {
      if (type==='template'){
        dispatchAppVersions(currentAppDetails?.app_id, currentAppDetails?.type,true)

      }
      else{
        dispatchAppVersions(currentAppDetails?.app_id, currentAppDetails?.type,false)

      }
    }
    navigate('/bulkrun', { state: { currentAppDetails } })

  }

  useEffect(() => {
    dispatchListFolders('knowledge_base')
    dispatchPromptPublished()    
    if (Id) {
      if (appAction === 'published_edit') {        
        dispatchPublishedAppEditDetails(appId,'app')
      }else{
        {currentAppDetails?.type==='app'?
        dispatchAppDetails(Id):
        dispatchPublishedAppEditDetails(appId,'template')
      }}
    }
  
  }, [])

  useEffect(() => {
    if (currentAppDetails?.app_id) {      
      if (type==='template'){
      dispatchAppVersions(currentAppDetails?.app_id, 'app',true)
    }
      else{
        dispatchAppVersions(currentAppDetails?.app_id, 'app',false)

      }

    }
    if (appAction === 'edit' || appAction === 'copy' || appAction === 'published_edit' ||  appAction === 'view') {
      updateDefaultValues()
    }
    // updateDefaultValues()
    setIsPublished(currentAppDetails?.published)
    
    setIsDefault( appAction === 'view' ? false : currentAppDetails?.published ? currentAppDetails?.default : true)
    
  }, [currentAppDetails])

  const updateDefaultValues = () => {    
    const { nodes, edges } = currentAppDetails ?? {}
    setNodes(nodes)
    setEdges(edges)
  }

  const closeTestArea = () => {
    setShowTestArea(false)
  }

  const closeEditArea = () => {
    setShowNodeEdit(false)
  }

  const updateAppSetiings = () => {    
    const appData = {
      appName: currentAppDetails?.app_name,
      memory: currentAppDetails?.memory,
      //appMemory:currentAppDetails?.app_name || 0,
      appDescription: currentAppDetails?.description,
      appTags: currentAppDetails?.tags,
      app_category: currentAppDetails?.app_category,
      app_feature: currentAppDetails?.app_feature,
      app_industry: currentAppDetails?.app_industry,
    }

    showModal(MODAL_TYPES.CREATE_APP, {
      appData,
      onFormSubmission: (appData: any) => {
        const appCreatePayload = {
          app_name: appData?.appName || currentAppDetails?.app_name,
          app_type: 'Tool',
          description: appData?.appDescription || currentAppDetails?.description,
          tags: appData?.appTags || currentAppDetails?.tags,
          memory: appData?.memory,
          updated_on: Date.now(),
          app_category: appData?.app_category ? appData?.app_category : 'general',
          app_feature: appData?.app_feature ? appData?.app_feature : '',
          app_industry: appData?.app_industry ? appData?.app_industry : '',
        }


        const rfFlow = rfInstance?.toObject()
        const UpdateAppPayload = {
          ...currentAppDetails,
          ...rfFlow,
          ...appCreatePayload
        }

        dispatchUpdateApp(UpdateAppPayload)
        hideModal()
      },
      onCancel: hideModal,
    })
  }

  const val = nodes?.length - 1

  const yPos = useRef(0);
  const blockCounter = useRef(val);

  const generateNodeId = () => {

    const existingIds = nodes.map((node: any) => node.id);
    let attempts = 0

    while (attempts < 100) {
      blockCounter.current += 1;
      const newId = `step_${blockCounter.current}`;
      if (!existingIds.includes(newId)) {
        return newId;
      }
      attempts += 1;
    }
    return null;
  }


  const onToolBarItemClick = useCallback((type: any) => {
    setUnSavedDataPresent(true)
    let typeUpdated:any;
    if (type === 'Past Ticket Search' || type==='FAQ Search') {
 typeUpdated = 'vector'
    }
    else{
      typeUpdated = type
    }
    setNodes((prevNodes: any) => {
      yPos.current += 100;

      return [
        ...prevNodes,
        {
          id: generateNodeId(),
          data: { label: `${type} Block` },
          type: typeUpdated,
          position: { x: 100, y: yPos.current },
        },
      ];
    });
  }, [generateNodeId]);

  const onCopyNodeDetail = useCallback((data: any,  type: any) => {
    setNodes((prevNodes: any) => {
      yPos.current += 100;

      return [
        ...prevNodes,
        {
          id: generateNodeId(),
          data: data,
          type: type,
          position: { x: 100, y: yPos.current },
        },
      ];
    });
  }, [generateNodeId]);

  const [selectedVersion, setSelectedVersion] = useState<SelectedVersionType | null>(null);

  const handleVersionSelect = (appVersion: any) => {
    setSelectedVersion(appVersion);
    dispatchAppDetails(appVersion.id)
  };

  const onSetasDefault = () => {    
    const onSetasDefaultPayload = {
      "id": currentAppDetails?.id,
      "app_id": currentAppDetails?.app_id,
    }
    dispatchSetAsDefault(onSetasDefaultPayload)
  }

  const setAsTemplate =async () => {
    const setasTemplatePayload = {
      "id": currentAppDetails?.id,
      "app_id": currentAppDetails?.app_id,
    }
    await dispatchSetAsTemplate(setasTemplatePayload)
    dispatchAppDetails(currentAppDetails?.id)

  }


  const menu = (
    <Menu>
      {currentAppVersionsDetails && currentAppVersionsDetails.map((appVersion: any) => (
        <Menu.Item key={appVersion.id} onClick={() => handleVersionSelect(appVersion)}>
          {`App Version ${appVersion.app_version}`}
        </Menu.Item>
      ))}
    </Menu>
  );

  const { userPermission, hasAcces } = useAuth();

  return (
    <WizrFlexLayout>
      {publishedPromptData?.length || !isContentLoading ? (
        <WizrFlexLayout>
          <WizrFlexLayout
            style={{ marginTop: '0PX', padding: "10px 5px 0 5px ", maxHeight: '60px' }}
            flexDirection='row'
            justifyContent='space-between'
            background='#fff'
          >
            <WizrFlexLayout flexDirection='row' background='#fff' flex={.5} >
              <WizrButton type='link' icon={<ArrowLeftOutlined />} onClick={() => navigate(-1)} />
            </WizrFlexLayout>
            <WizrFlexLayout background='#fff' flex={2} flexDirection='column'>
              <WizrFlexLayout background='#fff'>
                <WizrText type='sub1'>{appName}</WizrText></WizrFlexLayout>
              <WizrFlexLayout background='#fff'>
                <Dropdown trigger={['click']} overlay={menu} >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space style={{ color: '#707276' }}>
                      <span>{selectedVersion ? `App Version ${selectedVersion.app_version}` : 'Versions'}</span>
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>

              </WizrFlexLayout>
            </WizrFlexLayout>
            <WizrFlexLayout flexDirection='row' background='#fff' style={{ gap: '8px' }} justifyContent='flex-end'> 
              <WizrButton type='link' icon={<EditIcon />} style={{width:'24px'}}/>
              <WizrButton type='link' icon={<SettingsIcon />} onClick={updateAppSetiings} style={{width:'24px'}}/>  
            </WizrFlexLayout>  
            <WizrFlexLayout flexDirection='row' background='#fff' style={{ gap: '1px' }}>  
              <WizrButton
                style={{ backgroundColor: "#22C55E", width: "35px", height: "35px", borderRadius: "60px", paddingLeft: "25px"}}
                icon={<PlayIcon />}
                type='link'
                onClick={() => setShowTestArea(!showTestArea)}
              />
              { (isDefault === true || isPublished === false) ? <WizrButton type='text' onClick={onSave} text='Save' style={{ borderWidth: "2px", borderColor: "black", borderRadius: "20px" }} disabled={showNodeEdit}></WizrButton> : <WizrButton type='text' onClick={onSave} text='Restore' style={{ borderWidth: "2px", borderColor: "black", borderRadius: "20px" }}></WizrButton>}
              { (isDefault === true || isPublished === false) ? <WizrButton onClick={onLaunch} text='Launch' disabled={!loading && hasAcces('publish_app') && unSavedDataPresent!== true ? false : true} style={{ borderRadius: "20px" }}></WizrButton> : <WizrButton type='text' onClick={onSetasDefault} text='Set as default' style={{ borderWidth: "2px", borderColor: "black", borderRadius: "20px" }}></WizrButton>}
              {getUserRole() === 'superadmin' ? <WizrButton onClick={setAsTemplate} text='Set as template' disabled={!loading && hasAcces('publish_app') === true ? false : true} style={{ borderRadius: "20px" }}></WizrButton> : <></>}
              <WizrButton text='Run' style={{ borderRadius: "20px" }}></WizrButton>
            </WizrFlexLayout>

          </WizrFlexLayout >
          <Popover>
            <ToolBar onToolBarItemClick={onToolBarItemClick} />
          </Popover>


          <WizrFlexLayout background='#EAECF1'>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onInit={setRfInstance}
              fitView={true}
              minZoom={0}
              defaultViewport={{ x: 300, y: 100, zoom: 0.7 }}
            >
              <Background />

              <Controls style={{
                display: 'flex',
                flexDirection: 'row',
              }} />
            </ReactFlow>
            <StyledSider
              width={650}
              collapsedWidth={0}
              trigger={null}
              collapsible
              collapsed={!showTestArea}
            >
              <TestArea onTestAreaClose={closeTestArea} parameters={startParameters} />

            </StyledSider>
            <StyledSider
              width={editData?.type === "llm" ? 850 : 650}
              style={{ borderTop: "3px solid black" }}
              collapsedWidth={0}
              trigger={null}
              collapsible
              collapsed={!showNodeEdit}
            >
              <EditArea closeEditArea={closeEditArea} parameters={startParameters} editData={editData} setNodes={setNodes} setUnSavedDataPresent={setUnSavedDataPresent}/>
            </StyledSider>
          </WizrFlexLayout>


        </WizrFlexLayout>
      ) : (
        <Spin />
      )}
    </WizrFlexLayout>
  )
}

export default AppDesignerTool
