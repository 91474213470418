const WebFormatLargeIcon = ()=>{
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="16" fill="#FFF960"/>
<g clip-path="url(#clip0_12599_46987)">
<path d="M16.0003 22.6641C12.3183 22.6641 9.33366 19.6794 9.33366 15.9974C9.33366 12.3154 12.3183 9.33072 16.0003 9.33072C19.6823 9.33072 22.667 12.3154 22.667 15.9974" stroke="#1D1F22" stroke-width="1.5" stroke-linecap="round"/>
<path d="M17.0208 22.158C16.6968 22.4927 16.3502 22.6654 16.0002 22.6654C15.6502 22.6654 15.3035 22.4927 14.9802 22.158C14.6562 21.8227 14.3622 21.3314 14.1148 20.7127C13.8668 20.0934 13.6702 19.3594 13.5362 18.55C13.3995 17.7065 13.3317 16.8532 13.3335 15.9987C13.3335 15.1234 13.4022 14.256 13.5362 13.4474C13.6702 12.6387 13.8668 11.904 14.1148 11.2847C14.3622 10.6654 14.6562 10.1747 14.9795 9.83936C15.3035 9.50536 15.6502 9.33203 16.0002 9.33203C16.3502 9.33203 16.6968 9.5047 17.0202 9.83936C17.3442 10.1747 17.6382 10.666 17.8855 11.2847C18.1335 11.904 18.3302 12.638 18.4635 13.4474C18.5975 14.256 18.6668 15.1234 18.6668 15.9987M9.3335 15.9987H14.6668M22.6668 15.9987H17.3335" stroke="#1D1F22" stroke-width="1.5" stroke-linecap="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.8136 19.8703C18.8136 19.5912 18.5212 19.4801 18.4172 19.7361C18.0969 20.5234 18.257 21.4246 18.8287 22.0534C19.1826 22.4435 19.6847 22.666 20.2114 22.666C20.738 22.666 21.2402 22.4435 21.594 22.0534C22.3575 21.2131 22.3575 19.9302 21.594 19.0899L20.3407 17.7477C20.3071 17.7118 20.2601 17.6914 20.2109 17.6914C20.1618 17.6914 20.1148 17.7118 20.0812 17.7477C20.0466 17.7856 20.0276 17.8351 20.0278 17.8863V19.8703C20.0388 20.2057 19.7765 20.4867 19.4412 20.4988H19.4003C19.065 20.4867 18.8026 20.2057 18.8136 19.8703Z" stroke="#1D1F22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_12599_46987">
<rect width="16" height="16" fill="white" transform="translate(8 8)"/>
</clipPath>
</defs>
</svg>

    )
}

export default WebFormatLargeIcon