import NodeContainer from './NodeContainer'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import WizrText from '../../../components/WizrText'
import WizrDropDown from '../../../components/WizrDropDown'
import WizrTagInput from '../../../components/WizrTagInput'
import { usePromtTemplateSelector, useContentsSelector } from '../../../redux/selectors/content'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrInput from '../../../components/WizrInput'
import WizrTag from '../../../components/WizrTags'
import WizrFlexSpacing from '../../../components/WizrFlexSpacing'
import { useEdges, useNodes } from 'reactflow'
import { GroupOutlined } from '@ant-design/icons'
import WizrTextArea from '../../../components/WizrTextArea'
import CodeEditor from '@uiw/react-textarea-code-editor';
import { generateNodeSequenceResponse } from '../../../utils'
import { getConnectedEdges } from 'react-flow-renderer';


const CodeNode = (props: any): JSX.Element => {

  const { id, dataCode, updateNodeData, data, updateEdgeData, onEdit , onCopyNodeDetail, isDisabled} = props

  const [selectedLanguage, setselectedLanguage] = useState<any>("Python")
  const [SelectedCode, setSelectedCode] = useState<any>("")

  useEffect(() => {    
    setSelectedCode(data?.config?.function)
    // setselectedLanguage(data?.config?.language)
  }, [data])

  const edges = useEdges()
  const nodes = useNodes()

  const startnodedata: any = nodes && nodes[0]?.data
  const [parameterList, setParameterList] = useState<any>()
  // let parameterList:any = startnodedata?.config?.parameters.map((param: any) => param?.name)

  const lines = SelectedCode ? SelectedCode.split('\n') : ''
  const lineCount = lines.length;

  const lineNumbers = Array.from({ length: Math.max(lineCount, 10) }, (_, i) => i + 1);



  useEffect(() => {
    const saveAppPayload = {
      edges: edges,
      nodes: nodes,
    };
    const sequence = generateNodeSequenceResponse(saveAppPayload);
    const trimmedNodeSequence = elementsBeforeSpecificElement(sequence, id);
    const defaultParameters = startnodedata?.config?.parameters.map((param: any) => param?.name) || []
    setParameterList((prevList: any) => [...defaultParameters, ...trimmedNodeSequence]);

  }, [nodes, edges, setParameterList, id]);


  function elementsBeforeSpecificElement(arr: any, specificElement: any) {
    const index = arr.indexOf(specificElement);
    if (index === -1) {
      return arr;
    }
    const newArray = arr.slice(0, index).slice(1).map((item: any) => {
      return `${item}.output`
    })
    return newArray

  }

  const [isEditView, setIsEditView] = useState(false)

  const updateCurrentNodeData = () => {
    updateNodeData((nds: any) =>

      nds.map((node: any) => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            config: {
              language: selectedLanguage,
              function: SelectedCode,
            },
          }
        }
        return node
      }),
    )


    setIsEditView(!isEditView)
  }


  const languages = [
    { id: 1, label: "Python" }
  ]

  const inputRef = useRef<any>(null);

  const onParameterAdd = (valueToAdd: any) => {
    if (!inputRef.current) return;

    const { selectionStart, selectionEnd } = inputRef.current;

    const newValue =
      SelectedCode.substring(0, selectionStart) +
      valueToAdd +
      SelectedCode.substring(selectionEnd);

    setSelectedCode(newValue);

    // Set the cursor position after the inserted value
    const newPosition = selectionStart + valueToAdd.length;

    // Update the selectionStart and selectionEnd
    inputRef.current.setSelectionRange(newPosition, newPosition);

    // Set the focus to the input
    inputRef.current.focus();
  };


  // const onParameterAdd=(value:any)=>{
  //   // setSelectedCode(SelectedCode+value)
  //   setSelectedCode((prevValue: any) => prevValue + value);
  // }
  const onDeleteNodeDetail = (e: any) => {  
    const objectWithId: any = nodes && nodes.find((obj: any) => obj.id === e);    
    const connectedEdges = getConnectedEdges([objectWithId], edges);  
      
    if (connectedEdges.length > 0) {
      let updatedEdges = edges.filter((edge) => !connectedEdges.includes(edge));
    
      if (connectedEdges.length > 1) {
        const newEdge = {
          id: `edge_${connectedEdges[0].source}-${connectedEdges[1].target}`,
          source: connectedEdges[0].source,
          target: connectedEdges[1].target,
          type: 'buttonedge',
        };
    
        updatedEdges = updatedEdges.concat([newEdge]);
      }
    
      updateEdgeData(updatedEdges);
    }
    updateNodeData((nds: any) => nds.filter((node: any) => node.id !== e));

  }  

  return (
    <NodeContainer
      item={{ nodeTitle: data?.label, id: id, icon: <GroupOutlined style={{ marginRight: 10 }} /> }}
      data={dataCode}
      isEditMode={isEditView}
      saveCurrentAction={updateCurrentNodeData}
      onEditNodeDetail={() => { onEdit(data?.label, props)}}
      onDeleteNodeDetail={onDeleteNodeDetail}
      onCopyNodeDetail={() => { onCopyNodeDetail(data, 'code')}}
      isDisabled={isDisabled}
      nodeType={'6'}
    >
      <LLMContainer alignItems='flex-start'>
        {isEditView ? (
          <>
            <WizrText type='body2'>Language</WizrText>
            <WizrDropDown
              placeHolder='Select one option'
              value={selectedLanguage}
              options={languages}
              onChange={(value: any) => { setselectedLanguage(value) }}
              className="nodrag nopan"
            />
            <StyledCodeContainer>
              <StyledLineNumbers>
                {lineNumbers.map((_: any, index: any) => (
                  <div key={index} className="line-number">
                    {index + 1}
                  </div>
                ))}
              </StyledLineNumbers>
              <CodeEditor
                ref={inputRef}
                value={SelectedCode}
                placeholder="Please enter Python code."
                language='python'
                style={{
                  fontSize: 15,
                  width: "100%",
                  minHeight: '200px',
                  backgroundColor: "white",
                }}
                onChange={(evn) => setSelectedCode(evn.target.value)}
              />
            </StyledCodeContainer>
            <StyledFlexSpacing
              justifyContent='space-between'
              flexDirection='column'
              alignItems='center'
              size={20}
            >
              <StyledWizrTag
                background="grey"
                data={parameterList}
                onClick={(e: any) => { onParameterAdd(e) }
                }
              />
            </StyledFlexSpacing>
            {/* <WizrTextArea > </WizrTextArea> */}
          </>
        ) : (
          <>
            <WizrText type='body3' textColor='#707276'>
              Language
            </WizrText>
            <WizrText type='body2' textColor='#1D1F22' >
              {selectedLanguage}
            </WizrText>
            <WizrText type='body3' textColor='#707276'>
              Code
            </WizrText>
            {/* <WizrTextArea disabled={true} value={SelectedCode} onChange={()=>{}}> 

            </WizrTextArea> */}
            <CodeEditor
              value={SelectedCode}
              language='python'
              disabled={true}
              style={{
                fontSize: "16px",
                width: "100%",
                height: '100px',
                backgroundColor: "white",
              }}
              onChange={() => { }}
            />
          </>
        )}
      </LLMContainer>
    </NodeContainer>
  )
}

const LLMContainer = styled(WizrFlexLayout)`
  gap: 16px;
`

const StyledWizrTag = styled(WizrTag)`
  height: 42px;
  padding: 8px 11px;
  justify-content: space-between;
`

const StyledFlexSpacing = styled(WizrFlexSpacing)`
  .ant-space-item {
    display: flex !important;
    width: 100% !important;
    flex-wrap: wrap;
  }
`
const StyledCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`
const StyledLineNumbers = styled.div`
  width: 30px;
  padding: 0 10px;
  border-right: 1px solid #ccc;
  background-color: #f0f0f0;
  user-select: none;
`

export default CodeNode
