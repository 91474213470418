import exp from "constants"

const Iternation=():JSX.Element=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
  <path d="M9.5 17C13.9184 17 17.5 13.4184 17.5 9C17.5 4.5816 13.9184 1 9.5 1C5.0816 1 1.5 4.5816 1.5 9C1.5 13.4184 5.0816 17 9.5 17Z" stroke="#1D1F22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.3167 10.2C12.8071 11.8228 11.2911 13 9.49991 13C7.70911 13 6.19271 11.8228 5.68311 10.2V12.6M13.3167 5.4V7.8C12.8071 6.1772 11.2911 5 9.49991 5C7.70911 5 6.19271 6.1772 5.68311 7.8" stroke="#1D1F22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    )
}
export default Iternation