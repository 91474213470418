import { Popover } from 'antd'
import WizrButton from '../WizrButton'
import MoreIcon from '../../assets/icons/More'
import EditIcon from '../../assets/icons/EditIcon'
import { useNavigate } from 'react-router-dom'
import WizrFlexLayout from '../../stories/WizrFlexLayout/WizrFlexLayout'
import DeleteIcon from '../../assets/icons/DeleteIcon'
import { MODAL_TYPES, useWizrModalContext } from '../../providers/modal/WizrModalProvider'
import { dispatchCurrentModule, dispatchDeletIntegration, dispatchIntegrationList, dispatchUpdateIntegration } from '../../redux/actions/content'
import styled from 'styled-components'
import { useAuth } from '../../authContext'



const IntegrationPopOver = (integration:any): JSX.Element => {
    const data = integration?.integration
  const { showModal, hideModal } = useWizrModalContext()
  const { userPermission, hasAcces } = useAuth();
  const navigate = useNavigate()
  const onEditAction =  ()=>{
    showModal(MODAL_TYPES.EDIT_INTEGRATION,{
        integrationData:data,
    onFormSubmission:async (integrationData:any)=>{
      const redirect=localStorage.getItem('redirectCxSetttings')
        if(redirect==='true'){
          dispatchCurrentModule('cxhub')
          navigate('/cxsettings')
                       }
      const {_attachments, _etag, _rid, _self, _ts,feature_settings_config,...restOfData } = data
      const payload = {
        ...restOfData,
        config_data:integrationData.config_data,
        display_name:integrationData.display_name,
        integration_key:integrationData.integration_key,  
        integration_display_name:integrationData.integration_display_name,    
        registered_emailid:integrationData.registered_emailid,  
      }
      hideModal()
      await dispatchUpdateIntegration(payload)
      dispatchIntegrationList()
    },
      onCancel: hideModal,
      })
  }
  const deletePackage=async()=>{
    hideModal()
    await dispatchDeletIntegration(data?.id)
    dispatchIntegrationList()
    
  }

  const onDeleteAction = ()=>{
    showModal(MODAL_TYPES.CONFIRMATION, {
      text:`Are you sure you want to delete ${data?.display_name} ?`,
      buttontext:"Confirm",
  onConfirm:deletePackage,
  onCancel:hideModal

    })    
  }
  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      
    <WizrButton
        icon={<EditIcon />}
        text='Edit'
        type='text'
        onClick={()=>{onEditAction()}}
        justifyContent='flex-start'
        disabled={hasAcces('editintegrations_settings') === true ? false : true}
      />
      <WizrButton
        icon={<DeleteIcon />}
        text='Delete'
        type='text'
        onClick={()=>{onDeleteAction()}}
        justifyContent='flex-start'
        disabled={hasAcces('deleteintegrations_settings') === true ? false : true}
      />
    </WizrFlexLayout>
  )
}

const IntegrationActionPopOver = (integrationDetails:any): JSX.Element => {
  const navigate = useNavigate()
const product = integrationDetails?.integrationDetails
 
  return (
    <Popover
      content={
        <IntegrationPopOver
        integration={integrationDetails?.integrationDetails}
        />
      }
      trigger='click'
    >
      <StyledButton icon={<MoreIcon />} text='' type='text' style={{height: '40px', width: '40px', borderRadius: '50px'}}/>
    </Popover>
  )
}

export default IntegrationActionPopOver

const StyledButton = styled(WizrButton)`
border-radius: 50%;
width: 20px;
height: 20px;
padding-right: 8px;
justify-content: center;
align-items: center;
  /* Default styles */
  path {
    stroke: #999B9F; /* Default stroke color */
    transition: stroke 0.3s ease; /* Smooth transition for color change */
  }

  /* Hover styles */
  &:hover path {
    stroke: #1D1F22
  }
`;
