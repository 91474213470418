import styled from "styled-components"
import WizrFlexLayout from "../../../components/WizrFlexLayout"
import WizrTable from "../../../components/WizrTable"
import { useContentLoadingSelector, useGetIntegrationList, useGetMasterIntegrationList } from "../../../redux/selectors/content"
import { useEffect } from "react"
import { Spin } from "antd"
import { dispatchCreateIntegration, dispatchCurrentModule, dispatchIntegrationList, dispatchMasterIntegrationList, dispatchUpdateIntegration } from "../../../redux/actions/content"
import { useLocation, useNavigate } from "react-router-dom"
import { MODAL_TYPES, useWizrModalContext } from "../../../providers/modal/WizrModalProvider"

const StyledWizrTable = styled(WizrTable)`
  .ant-table-wrapper {
    width: 100% !important;
  }
  .ant-table-thead {
    background-color: #f2f4f9;
    border-spacing: 0px;
  }
  .ant-table-tbody > tr {
    background-color: #ffffff;
    margin-bottom: 0px;
  }

  .ant-table table {
    border-spacing: 0 5px;
  }
`

const Integrations = ():JSX.Element=>{
  const location = useLocation()
  const navigate = useNavigate()
    const integrations = useGetIntegrationList()
    const loading =useContentLoadingSelector()
    const { showModal, hideModal } = useWizrModalContext()

    useEffect(() => {
        dispatchIntegrationList()   
    }, [])
    useEffect(() => {
      if (location.state && location.state.action==='settingsedit') {
        showModal(MODAL_TYPES.EDIT_INTEGRATION,{
          integrationData:location.state.data,
      onFormSubmission:async (integrationData:any)=>{
        hideModal()
        const redirect=localStorage.getItem('redirectCxSetttings')
        // if(redirect==='true'){
          navigate('/cxsettings')
          dispatchCurrentModule('cxhub')

                      //  }
          const {_attachments, _etag, _rid, _self, _ts,feature_settings_config,...restOfData } = location.state.data
          const payload = {
                         ...restOfData,
                         config_data:integrationData.config_data,
                         display_name:integrationData.display_name,
                         integration_key:integrationData.integration_key,  
                         integration_display_name:integrationData.integration_display_name,    
                         registered_emailid:integrationData.registered_emailid,  
                       }
       await dispatchUpdateIntegration(payload)
     
       dispatchIntegrationList()
       localStorage.setItem('redirectCxSetttings', 'false')
      },
        onCancel: hideModal,
        })
      }
      else if (location.state && location.state.action==='settingsadd') {
        showModal(MODAL_TYPES.CREATE_INTEGRATION,{
          onFormSubmission:async (integrationData:any)=>{
            hideModal()
            const redirect=localStorage.getItem('redirectCxSetttings')
            // if(redirect==='true'){
              navigate('/cxsettings')
              dispatchCurrentModule('cxhub')
                          //  }
            const payload = {
              config_data:integrationData.config_data,
              display_name:integrationData.display_name,
              integration_key:integrationData.integration_key, 
              integration_display_name:integrationData.integration_display_name,
              registered_emailid:integrationData.registered_emailid,
              status:"active"}
            await dispatchCreateIntegration(payload)
            dispatchIntegrationList()
            localStorage.setItem('redirectCxSetttings', 'false')
          },
          onCancel: hideModal,
        })
      }
  }, [])
    
    return(
        <WizrFlexLayout>
          {loading?<Spin/>:
            <StyledWizrTable type="integrations" dataSource={integrations.sort((a:any,b:any)=>b.updated_on-a.updated_on)} pagination={false}></StyledWizrTable>
        }
        </WizrFlexLayout>
    )
}

export default Integrations