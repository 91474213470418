import { useEffect, useState } from "react";
import Bars from "../assets/icons/Bars";
import EditIcon from "../assets/icons/EditIcon";
import Share from "../assets/icons/Share";
import RefreshIcon from "../assets/icons/refresh";
import WizrButton from "../components/WizrButton";
import WizrFlexLayout from "../components/WizrFlexLayout"
import WizrText from "../components/WizrText"
import WizrUserJourney from "../components/WizrUserJourney"
import { Button, Input, Popover, Radio, RadioChangeEvent, Space, Spin, Tabs, Typography, message as antdMessage } from 'antd';
import WizrTable from "../components/WizrTable";
import WizrUploadContainer from "../components/WizrUploadButton";
import { WizrAzurOrchestratorBulkRun, WizrAzurOrchestratorInstance } from "../utils/wizrAzureConnector";
import { async } from "q";
import { appShareLinkGeneration, executeBulkrun, getBulkTestStatus } from "../api/helpers/contents";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getFileType, getTenantId, uploadCheck } from "../utils";
import jwt_decode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid'
import { useAppShareLinkGeneration, useAppStatusSelector, useBulkRunResultDynamic, useBulkRunstatus, useChatMessageSelector, useContentLoadingSelector, useFileParams, useUpdatedUrlforCsvUpload } from "../redux/selectors/content";
import { dispatchAppToolTest, dispatchClearChat, dispatchGetRunStatus, dispatchAppShareLinkGeneration, dispatchbulkrunStatusApp, dispatchgenerateFileUpload, dispatchTerminateBulkrun, dispatchUploadFileUsingLink, dispatchExecuteBulkRun, dispatchSaveCurrentAppDetails, diaptchUpdateFileUploadStatus } from "../redux/actions/content";
import styled from "styled-components";
import HistoryIcon from "../assets/icons/History";
import DeleteIcon from '../assets/icons/DeleteIcon'
import Brain from "../assets/icons/Brain";
import DownloadAlt from "../assets/icons/DownloadAlt";
import WizrInput from "../components/WizrInput";
import WizrTextArea from "../components/WizrTextArea";
import WizrTagInput from "../components/WizrTagInput";
import WizrDropDown from "../components/WizrDropDown";
import Chat from "../components/WizrChat";
import WizrToggleButton from "../components/WizrToggleButton";
import Copy from "../assets/icons/Copy";
import copy from 'copy-to-clipboard';
import APIIntegration from "./ApiIntegration";
//import SingleRunContainerLeft from "./components/BulkRunPages.tsx/SingleRunContainerLeft";
//import {  } from 'antd';
import Papa from 'papaparse';
import { UploadOutlined } from "@ant-design/icons";
import { useAuth } from "../authContext";

// import { store } from '../redux/store'

// const { dispatch } = store ?? {}
interface UploadedFileParameters {
  [key: string]: string; // Assuming the keys are strings and values are strings
}
const { Text } = Typography;
const StyledWizrButton = styled(WizrButton)`
border-radius: 20px;
border: 1px solid var(--grayscale-800, #1D1F22);
background: var(--generic-white, #FFF);
margin-right:10px
`


const StyledHeaderWizrButton = styled(WizrButton)`
border-radius: 20px;
background: var(--grayscale-200, #EAECF1);`

const StyledWizrDropDown = styled(WizrDropDown)`
  width: 100%;
`

const BulkRunContainerLeft = ({ manageFileUpload, manageFileDownload, parameters, uploadedfile, loading, setUploadedfile, setIsTested, isProcessing, execution_status_id, app_id, executeBulkrun, setIsProcessing }: any): JSX.Element => {
  const fileuploadstatus = useContentLoadingSelector()
  const UpdatedUrlforCsvUpload = useUpdatedUrlforCsvUpload()
  const [uploadedFileAsParameters, setUploadFileAsParameters] = useState<UploadedFileParameters>({})
  const [csvStatus, setCsvStatus] = useState(true)
  const fileparams = parameters?.filter((item: any) => (item.type === 3 || item.type === 6 || item.type === 7) && item.name !== "user_query")



  useEffect(() => {
    const keysUploaded = Object.keys(uploadedFileAsParameters);
    const keysToUploaded = fileparams.map((item: any) => item.name);
    const haveSameValues = keysUploaded.length === keysToUploaded.length && keysUploaded.every((value: any) => keysToUploaded.includes(value));
    setCsvStatus(haveSameValues)
  }, [uploadedFileAsParameters])

  const resetState = () => {
    setUploadedfile("No choosen file")
    setIsTested(false)
  }
  const cancelBulkRun = () => {

    const payload = {
      execution_status_id: execution_status_id,
      app_id: app_id
    }
    dispatchTerminateBulkrun(payload)
  }

  const uploadFile = (fileInfo: any, name: any, type: any) => {   
    
    const check = uploadCheck(type, fileInfo?.file)
    if(check){
      setUploadFileAsParameters((prevParameters) => ({
        ...prevParameters,
        [name]: fileInfo?.file?.originFileObj?.webkitRelativePath.split("/")[0],
      }));
      const timestamp = new Date().getTime()
      const payload = {
        container_id: 1,
        app_id: `${app_id}`,
        timestamp: timestamp,
        fileInfo: fileInfo
      }
      dispatchgenerateFileUpload(payload, {})
    }

  }


  return (
    <WizrFlexLayout background="white" style={{ borderRadius: "16px", padding: "15px" }} alignItems="flex-start">
      <WizrFlexLayout flex={.7} background="white" alignItems="flex-start">
        <WizrText type="body2" textColor="#707276">Upload a CSV with input in the following structure</WizrText></WizrFlexLayout>
      <WizrFlexLayout flex={2.1} background="white" style={{ overflowX: 'auto' }}>
        {/* <WizrTable type="bulkrun"></WizrTable> */}
        <table style={{ border: "1px solid var(--generic-stroke, #E5E7EB)", overflowX: 'auto' }} >
          <thead>
            <tr >
              {parameters.map((parameter: any, index: any) => (
                <th key={index} style={{ backgroundColor: '#F2F4F9', color: 'black', minWidth: '200px' }}><WizrText type="sub2">{parameter.name}</WizrText></th>
              ))}
            </tr>
            <tr>
              {parameters.map((item: any, index: any) => (
                <td key={index} style={{ backgroundColor: 'white', color: 'black', minWidth: '200px' }}>
                  {/* <WizrText type="body2">Example of {item.name}</WizrText> */}
                  <WizrFlexLayout flexDirection="row" background="#fff">
                    {(item.type === 3 || item.type === 6 || item.type === 7) ? <>
                      <WizrText type="body2"  >Example of {item.name}</WizrText>
                    </> : <WizrText type="body2">Example of {item.name}</WizrText>}
                  </WizrFlexLayout>
                </td>
              ))}
            </tr>
          </thead>
          {/* You can add the table body based on your data */}
        </table>
      </WizrFlexLayout>
      <WizrFlexLayout flex={1} background="white">
        <WizrFlexLayout background="white" alignItems="flex-start">
          <WizrText type="body2" textColor="#707276">Download the csv template {""}
            <Text underline style={{ color: '#707276', cursor: 'pointer' }} onClick={manageFileDownload}>here</Text> </WizrText>
        </WizrFlexLayout>
      </WizrFlexLayout >
      <WizrFlexLayout flexDirection="row" flex={1} justifyContent="flex-start" background="white" >
        <WizrUploadContainer onChange={manageFileUpload}>
          <WizrButton
            disabled={!isProcessing && !csvStatus}
            text={<WizrText type="button">{fileuploadstatus ? "Uploading....." : "Upload File"}</WizrText>} style={{ borderRadius: "60px", marginRight: "40px" }}>
          </WizrButton>
        </WizrUploadContainer>
        <div style={{ paddingTop: "3px" }}><WizrText type="caption" >{uploadedfile}</WizrText></div>
      </WizrFlexLayout>
      <WizrFlexLayout flex={3} flexDirection="column" justifyContent="flex-start" background="white" >
        {fileparams && fileparams?.map((input: any, key: number) => {
          const name = input.name

          return (
            <WizrFlexLayout key={key} background="white" flexDirection="row" justifyContent="flex-start">
              <WizrUploadContainer directory={true} onChange={(fileInfo: any) => uploadFile(fileInfo, name, input.type)}>
                <WizrButton
                  icon={<UploadOutlined />}
                  type="link"
                  disabled={isProcessing}
                  text={<WizrText type="link">{
                    fileuploadstatus ? "Uploading....." : uploadedFileAsParameters?.[name] ? uploadedFileAsParameters[name] : `upload files for ${name}`}</WizrText>} style={{ borderRadius: "60px", marginRight: "40px" }}>
                </WizrButton>
              </WizrUploadContainer></WizrFlexLayout>
          )
        })}
      </WizrFlexLayout>
      <WizrFlexLayout flex={1} flexDirection="row" background="white" justifyContent="flex-end">
        {!isProcessing ? <>
          <StyledWizrButton
            type="link" text={<WizrText type="button" textColor="#1D1F22">Reset</WizrText>} onClick={() => resetState()}></StyledWizrButton>
          <WizrButton style={{ borderRadius: "20px" }}
            text={<WizrText type="button">Run batch</WizrText>} onClick={() => { executeBulkrun() }}></WizrButton></> :
          <WizrButton style={{ borderRadius: "20px" }}
            onClick={() => { cancelBulkRun() }}
            text={<WizrText type="button">Cancel Run</WizrText>}></WizrButton>}
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

const BulkRunContainerRight = ({ isTested, manageOutputDownload, isProcessing, uploadedfile, previousRunStatus }: any): JSX.Element => {
  return (
    <WizrFlexLayout background="white" style={{ borderRadius: "16px", padding: "16px" }}>
      <WizrFlexLayout background="#F2F4F9" style={{ borderRadius: "16px" }}>
        {isProcessing ?

          <WizrFlexLayout alignContent="center" justifyContent="center">
            <WizrFlexLayout flex={4}>
              <></>

            </WizrFlexLayout>
            <WizrFlexLayout flexDirection="column" alignItems="center" flex={1.5} style={{ gap: "10PX" }}>
              <Spin />
              <WizrText type="body2">We are processing the file {uploadedfile}. <br></br>This might take a while </WizrText>

            </WizrFlexLayout>
            <WizrFlexLayout flex={4}>
              <></>
            </WizrFlexLayout>
          </WizrFlexLayout> : !isTested ?
            <WizrFlexLayout>
              <WizrFlexLayout flex={4}>
                <></>
              </WizrFlexLayout>
              <WizrFlexLayout flex={1} flexDirection="column" alignItems="center">
                <Brain />
                <WizrText type="body2">Results will show here</WizrText>
              </WizrFlexLayout>
              <WizrFlexLayout flexDirection="column" alignItems="center" flex={1}>
                <></>
              </WizrFlexLayout>
              <WizrFlexLayout flex={4}>
                <></>
              </WizrFlexLayout>
            </WizrFlexLayout> :
            <WizrFlexLayout>
              <WizrFlexLayout flex={4}>
                <></>
              </WizrFlexLayout>
              <WizrFlexLayout flexDirection="column" alignItems="center" flex={1}>
                <DownloadAlt />
                <WizrText type="body2" textColor="#707276">Output is ready for {uploadedfile}.<br></br> You can download it from {""}
                  <Text underline style={{ color: '#707276', cursor: 'pointer' }} onClick={manageOutputDownload}>here</Text> </WizrText>
              </WizrFlexLayout>
              <WizrFlexLayout flex={4}>
                <></>

              </WizrFlexLayout>
            </WizrFlexLayout>}
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

const SingleRunContainerLeft = ({ parameters, doUpdateFields, saveFileParams, saveFilesData, appDetails, app_id }: any): JSX.Element => {

  const [inputValue, setInputValue] = useState<any>({})
  const [messageParams, setMessageParams] = useState<any>({ '{{user_query}}': '' })
  const [fileParams, setFfileParams] = useState<any>([])
  const [filesData, setFilesData] = useState<any>([])

  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item,
        value: item,
      }))
    return result
  }

  const updateMessageParams = (name: string, value: any) => {
    const names = `{{${name}}}`
    const finalValue = value?.length > 0 ? value.toString() : value
    setMessageParams({ ...messageParams, [names]: finalValue })
  }

  const uploadedFileValues = useFileParams()

  const uploadFile = (name: any, fileInfo: any, type?: any) => {

    const check = uploadCheck(type, fileInfo?.file)
    if(check){
      const fileToReplace = uploadedFileValues?.filter((item: any) => item[0]?.input_file_name !== name)  
      diaptchUpdateFileUploadStatus(fileToReplace)   
      if(fileInfo!==''){
      const fileUploadPayload={
        container_id: 1,
        path: app_id,
        files: [
          `${name}`,
        ]
      }
      const formData = new FormData();
      const jsonData = JSON.stringify(fileUploadPayload);
      formData.append('data', jsonData);
      formData.append(name, fileInfo?.fileList[0]?.originFileObj);
      dispatchUploadFileUsingLink(formData)}
    }
  }


  // const updateFileParams = (name: any, fileInfo: any) => {
  //   const type = getFileType(fileInfo?.file?.name)

  //   const fileData = {
  //     param_name: name,
  //     file_name: fileInfo?.file?.name,
  //     file_location: 'promptbox',
  //     file_type: type,
  //   }

  //   fileParams?.map((item: any, key: any) => {
  //     if (item.param_name === name) {
  //       fileParams.splice(key, 1)
  //     }
  //   })

  //   setFfileParams([...fileParams, fileData])

  //   const fileValue = {
  //     param_name: name,
  //     file: fileInfo?.fileList[0]?.originFileObj,
  //   }

  //   filesData?.map((item: any, key: any) => {
  //     if (item.param_name === name) {
  //       filesData.splice(key, 1)
  //     }
  //   })

  //   setFilesData([...filesData, fileValue])
  //   saveFilesData([...filesData, fileValue])

  // }
  return (

    <WizrFlexLayout background="white" style={{ borderRadius: "16px", padding: "15px", }}>
      <WizrFlexLayout flex={8} background="white" alignItems='flex-start' justifyContent="flex-start" style={{ width: '90%', gap: '5px', marginLeft: "20px" }}>
        {parameters && parameters?.map((input: any, key: number) => {
          if (input.name !== "user_query") {
            switch (input.type) {
              case 1:
                return (
                  <>
                    <WizrText type='body2'>{input.name}</WizrText>
                    <WizrInput
                      background={'#F2F4F9'}
                      key={key}
                      placeholder="Parameter Value"
                      style={{ maxHeight: '25px' }}
                      value={inputValue[input.name]}
                      onChange={(value: any) => {
                        updateMessageParams(input.name, value)
                        setInputValue({ ...inputValue, [input.name]: value })
                        doUpdateFields(input.name, value)
                      }}
                    />
                  </>
                )
              case 2:
                return (
                  <>
                    <WizrText type='body2'>{input.name}</WizrText>
                    <WizrTextArea
                      onChange={(value: any) => {
                        updateMessageParams(input.name, value);
                        setInputValue({ ...inputValue, [input.name]: value })
                        doUpdateFields(input.name, value)
                      }}
                      placeholder='Enter text'
                      minRows={2}
                      maxRows={6}
                      value={inputValue[input.name]}
                      background={'#F2F4F9'}
                    />
                  </>
                )
              case 4:
                return (
                  <>
                    <WizrText type='body2'>{input?.name}</WizrText>
                    <WizrDropDown
                      placeHolder='Select one option'
                      options={dropDownConverter(input?.modalTags)}
                      onChange={(value: any) => {
                        updateMessageParams(input.name, value);
                        setInputValue({ ...inputValue, [input.name]: value })
                        doUpdateFields(input.name, value)
                      }}
                    // background={'#F2F4F9'}
                    />
                  </>
                )
              case 5:
                return (
                  <>
                    <WizrText type='body2'>{input?.name}</WizrText>
                    <WizrTagInput
                      placeHolder='Select multiple option'
                      options={dropDownConverter(input?.modalTags)}
                      onChange={(value: any) => {
                        updateMessageParams(input.name, value);
                        setInputValue({ ...inputValue, [input.name]: value });
                        doUpdateFields(input.name, value);
                      }}
                    ></WizrTagInput>
                  </>
                )
              case 3:
              case 6:
              case 7:
                return (
                  <>
                    <WizrText type='body2'>{input.name}</WizrText>
                    <Input
                      //onChange={(value: any) => updateMessageParams(item.name, value)}
                      placeholder='Upload File'
                      value={inputValue[input.name]}
                      suffix={
                        <Space>
                          {inputValue[input.name] && ( // Only display clear icon if there's a value
                            <Button shape='round' onClick={(e: any) => {
                              uploadFile(input.name, '')
                              setInputValue({ ...inputValue, [input.name]: null })
                              doUpdateFields(input.name, '')
                            }}>
                              remove file
                            </Button>
                          )}
                          <WizrUploadContainer
                            onChange={(e: any) => {
                              uploadFile(input.name, e, input.type)
                              setInputValue({ ...inputValue, [input.name]: e?.file?.name })
                              doUpdateFields(input.name, e?.file?.name)
                            }}
                          >
                            <Button shape='round' >
                              Upload
                            </Button>
                          </WizrUploadContainer>
                        </Space>
                      }
                    />
                  </>
                )
            }
          }
        })}
      </WizrFlexLayout>
      <WizrFlexLayout flex={1} flexDirection="row" background="white" justifyContent="flex-end" style={{ marginTop: "10px" }}>
        <StyledWizrButton
          type="link" text={<WizrText type="button" textColor="#1D1F22">Reset</WizrText>} onClick={() => { setInputValue({}) }}></StyledWizrButton>
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}


const AppSharePopOver = (id: any): JSX.Element => {

  const popoverLoader = useContentLoadingSelector()
  const appShareLinkGeneration: any = useAppShareLinkGeneration()

  return <WizrFlexLayout >
    <WizrFlexLayout flexDirection="row" background="white" justifyContent="flex-start" style={{ paddingBottom: '10px', alignItems: 'center', gap: '16px' }}>
      <WizrText type="sub2"  >Enable sharing</WizrText>
      <WizrToggleButton isEnabled={true} />
    </WizrFlexLayout >
    <WizrFlexLayout background="white">
      <Input
        value={appShareLinkGeneration}
        //disabled={true}
        style={{
          width: '411px',
          height: '40px',
          flexShrink: 0,
          borderRadius: '16px',
        }}
        suffix={
          <WizrButton
            type="link"
            icon={popoverLoader ? <Spin /> : <Copy color="#999B9F" />}
            onClick={() => {
              antdMessage.success('Copied to clipboard', 2);
              copy(appShareLinkGeneration)
            }}
          />
        }
      />

    </WizrFlexLayout>
  </WizrFlexLayout>

}


const BulkRun = (): JSX.Element => {

  const navigate = useNavigate()

  const UpdatedUrlforCsvUpload = useUpdatedUrlforCsvUpload()

  const location = useLocation()
  const previousRunStatusAll = useBulkRunstatus()
  const previousRunStatus = previousRunStatusAll[0] ? previousRunStatusAll[0] : []

  const Appstatus = useAppStatusSelector()
  const bulkrunstatusdynamic = useBulkRunResultDynamic()

  const [key, setKey] = useState('single')
  const [selectedTab, setSelectedTab] = useState('single');
  const [uploadedfile, setUploadedfile] = useState("No choosen file")
  const TabPane = Tabs.TabPane
  const [isTested, setIsTested] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  // const [fileuploadStatus,setFileUploadStatus]= useState(false)

  const [fileParams, setFileParams] = useState<any>([])
  const [filesData, setFilesData] = useState<any>([])
  const [currentExecutionStatusId, setCurrentExecutionStatusId] = useState<any>('')
  const [selectedOperation, setSelectedOperation] = useState('1');
  useEffect(() => {

    if (previousRunStatus) {
      if (previousRunStatus?.execution_status === "In_Progress") {
        setIsProcessing(true)
        setUploadedfile(previousRunStatus?.file_name)
        setCurrentExecutionStatusId(previousRunStatus?.execution_status_id)

      }
      else if (previousRunStatus?.execution_status === "Completed" || previousRunStatus?.execution_status === "Completed_With_Errors") {
        //setUploadedfile(previousRunStatus?.file_name)

        setIsProcessing(false)
        setIsTested(true)
      }
      else {

        setIsProcessing(false)
        setIsTested(false)
      }
    }
  }, [previousRunStatus])
  useEffect(() => {
    if ((bulkrunstatusdynamic?.execution_status === "Completed" || bulkrunstatusdynamic?.execution_status === "Terminated") && bulkrunstatusdynamic?.appId === appDetails?.app_id) {
      const payload = {
        app_id: appDetails?.app_id
      }
      dispatchbulkrunStatusApp(payload)
      //setUploadedfile(previousRunStatus?.file_name)

    }
  }, [bulkrunstatusdynamic])

  const appDetails = location?.state?.currentAppDetails || location?.state?.appDetail?.appDetail || location?.state?.item


  const parameterList = appDetails?.nodes[0]?.data?.config?.parameters
  const parameters = (appDetails?.nodes[0]?.data?.config?.parameters || []).map((item: any) => item?.name);

  const parameterObject = parameters.reduce((result: any, parameter: any) => {
    result[`{{${parameter}}}`] = "";
    return result;
  }, {});

  //setInputState(parameterObject)
  const [inputState, setInputState] = useState<any>(parameterObject)
  const chatMessage = useChatMessageSelector()

  const [historyChats, setHistoryChats] = useState<{ user: string; assistant: string }[]>([])

  useEffect(() => {
    const user = chatMessage?.filter((item: any) => item.isSent).map((item: any) => item.text)
    const assistant = chatMessage?.filter((item: any) => !item.isSent).map((item: any) => item.text)
    if (user?.length === assistant?.length) {
      const result = user.map((value, index) =>
        Object.assign({}, { user: value, assistant: assistant?.[index] }),
      )
      setHistoryChats(result)
    }
  }, [chatMessage])

  useEffect(() => {

    const payload = {
      app_id: appDetails?.app_id
    }
    dispatchbulkrunStatusApp(payload)
    dispatchClearChat()
  }, [])

  const items = [
    { key: "1", text: "Use App" },
    { key: "2", text: "API" },
    // { key: "3", text: "SDK" }
  ]

  const onTabChange = (e: RadioChangeEvent) => {
    setSelectedTab(e.target.value);
    setKey(e.target.value)
  }

  const getButtonStyle = (value: any) => {
    if (value === selectedTab) {
      return {
        backgroundColor: '#1D1F22',
        borderColor: '#1D1F22',
        color: '#fff',
      };
    }
    return {};
  };

  const [modifiedData, setModifiedData] = useState(null);

  const manageFileUpload = async (fileInfo: any) => {

    const reader = new FileReader();

    // Event listener on reader when the file loads, we parse it and set the data.
    reader.onload = (event: any) => {

      const content = event.target.result;

      // Parse the CSV content
      Papa.parse(content, {
        header: true,
        complete: (result: any) => {
          // Modify the data (example: add 1 to the 'quantity' column)
          const updatedData: any = result.data.map((row: any) => {
            const updatedRow: any = {};
            for (const key in row) {
              if (row[key]) {
                const matchingItem = UpdatedUrlforCsvUpload.find((item: any) => item.includes(row[key]));
                updatedRow[key] = matchingItem ? matchingItem : row[key];
              } else {
                updatedRow[key] = row[key];
              }
            }
            return updatedRow;
          });

          // Set the modified data in the state
          setModifiedData(updatedData);
          const updatedCsv = Papa.unparse(updatedData);
          const updatedBlob = new Blob([updatedCsv], { type: 'text/csv' });


          const execution_status_id = uuidv4()
          setCurrentExecutionStatusId(execution_status_id)
          const fileUploadPayload = {

            container_id: 2,
            path: appDetails?.app_id,
            files: [
              `${fileInfo?.file?.name}`,
            ],
            metadata: {
              id: appDetails?.id,
              version_id: appDetails?.version_id,
              execution_status_id: execution_status_id,
              exec_type: 'bulk-run'

            }
          }

          const formData = new FormData();
          const jsonData = JSON.stringify(fileUploadPayload);
          formData.append('data', jsonData);
          formData.append(fileInfo?.file?.name, new File([updatedBlob], fileInfo?.file?.name, { type: 'text/csv' }));
          dispatchUploadFileUsingLink(formData)
          setUploadedfile(fileInfo?.file?.name)


        },
      });
    };

    // Use `reader.onload` instead of `event.target.onload`
    reader.readAsText(fileInfo.fileList[0]?.originFileObj);





    setIsTested(false)





  }



  const manageFileDownload = async () => {

    await WizrAzurOrchestratorBulkRun.downloadFileTemplate(appDetails?.app_id, appDetails?.app_name)
  }

  const manageOutputDownload = async () => {
    const file_name = previousRunStatus?.output_file_name
    await WizrAzurOrchestratorBulkRun.downloadFileOutput(appDetails?.app_id, file_name)
  }

  
  const uploadedFileValues = useFileParams()

  const onChatClick = () => {

    Object.keys(inputState).forEach((key) => {      
      const item = inputState[key];      
      const file = uploadedFileValues?.find(
        (file: any) => file[0] && file[0].file_name === item && appDetails?.app_id === file[0].path
      );      
      if (file) {
        inputState[key] = `${file[0].timestamp}/${file[0].file_name}`;
      }
    });

    const testAppPayload = {
      id: appDetails?.id,
      app_id: appDetails?.app_id,
      history: appDetails?.memory ? historyChats : [],
      exec_type: "app",
      max_past_messages: appDetails?.memory ? 15 : 0,
      message_params: inputState,
      // file_params: fileParams ? fileParams : [],
    }    

    const formData = new FormData();
    const jsonData = JSON.stringify(testAppPayload);
    formData.append('data', jsonData);
    filesData?.map((item: any) => {
      formData.append(item.param_name, item.file);
    })
    dispatchAppToolTest(testAppPayload)
  }

  const doUpdateFields = (fieldName: string, fieldValue: any) =>
    setInputState({
      ...inputState,
      [`{{${fieldName}}}`]: fieldValue,
    })

  const mapValueToUserQuery = (fieldValue: any) =>
    setInputState({
      ...inputState,
      '{{user_query}}': fieldValue,
    })

  const apprunHistory = () => {


    // navigate("/runhistory", { state: appDetails })
    window.open(`/runhistory/${appDetails?.app_id}`, '_blank', 'noopener,noreferrer')

  }

  const goback = () => {
    navigate(-1)
    dispatchClearChat()
  }



  const appShareLink = () => {

    const tenantId: string = getTenantId();


    const payload = {
      id: appDetails?.id,
      app_id: appDetails?.app_id,
      //tenant_id: tenantId,
      exec_type: "SINGLERUN",
      prefix: process.env.REACT_APP_WIZR_B2C_REDIRECT_URI + "/appshare"
    }
    dispatchAppShareLinkGeneration(payload)

  }
  const executeBulkrun = async () => {
    const jwtDecodeData: { [key: string]: any } = jwt_decode(localStorage.id_token)
    // const execution_status_id = uuidv4()
    // setCurrentExecutionStatusId(execution_status_id)
    const tenantId: string = getTenantId()

    const payload: any =
    {
      container_id: 2,
      source: "inbox",
      destination: "inprocess",
      blob_name: `${appDetails?.app_id}/${uploadedfile}`,
      process_name: "bulk-execute",
      message_body: {
        execution_status_id: currentExecutionStatusId,
        file_name: uploadedfile,
        app_id: appDetails?.app_id,
        version_id: appDetails?.id,
        execution_status: "In_Progress"
      }
    }

    // {
    //   tenant_id: tenantId,
    //   id: appDetails?.id,
    //   app_id: appDetails?.app_id,
    //   version_id: appDetails?.id,
    //   user_id: jwtDecodeData?.oid,
    //   user_name: jwtDecodeData?.name,
    //   execution_status_id: execution_status_id,
    //   llm_model_name:appDetails?.nodes[1]?.data?.config?.llm_model_config?.model_name

    // } 
    dispatchExecuteBulkRun(payload)
    setIsProcessing(true)
    //getBulkTestStatus(appDetails?.app_id,execution_status_id)
    await dispatchGetRunStatus(appDetails?.app_id, currentExecutionStatusId, appDetails?.app_name);
    setIsProcessing(false)
    setIsTested(true)

  }

  const handleTabChange = (key: any) => {
    setSelectedOperation(key)
  }

  const { userPermission, hasAcces } = useAuth();

  const onEditAction = () => {
    dispatchSaveCurrentAppDetails(appDetails)
    const action = 'edit'
    navigate(`/app-designer/tool`, { state: { action, id: appDetails?.id, appId: appDetails?.app_id } })

  }

  const getAccessStatusEdit = () => {
    switch (appDetails?.published) {
      case true:
        if (appDetails.app_category === "package") {
          return !hasAcces('edit_packagepublished_app')
        }
        else {
          return !hasAcces('edit_generalpublished_app')

        }

      case false:
        if (appDetails?.app_category === "package") {
          return !hasAcces('edit_packagetemplate_app')
        }
        else {
          return !hasAcces('edit_generaltemplate_app')

        }
      default:
        return hasAcces('editdraft_app') === true ? false : true
    }
  }

  

  return <WizrFlexLayout>
    <WizrFlexLayout flex={1.3} flexDirection="row" style={{ minHeight: "0px" }}>
      <WizrFlexLayout flex={3} flexDirection="row" justifyContent="space-between">
        <WizrFlexLayout flex={3}>
          <WizrUserJourney userJourney={[{
            title:
              <WizrButton
                padding='0px'
                type='text'
                text={<WizrText type='h6'>Apps</WizrText>}
                onClick={() =>
                  goback()
                }
              />
          }, { title: <WizrText type='h8'>{appDetails?.app_name}</WizrText> }]} />
        </WizrFlexLayout>

        <WizrFlexLayout flex={1}>
          <Tabs defaultActiveKey='1' destroyInactiveTabPane={false} onChange={handleTabChange}>
            {items.map((appMenuItem: any) => (
              <TabPane
                tab={<WizrText type='body2'>{appMenuItem.text}</WizrText>}
                key={appMenuItem.key}
              >
              </TabPane>
            ))}</Tabs>
        </WizrFlexLayout >
      </WizrFlexLayout>
      <WizrFlexLayout alignContent="flex-end" flexDirection="row" style={{ paddingTop: "10px" }} >
        <WizrFlexLayout flex={2} flexDirection="row" style={{ maxWidth: "370px" }} alignContent="flex-end">
          {hasAcces('view_appshare') === true ? <Popover content={<AppSharePopOver id={appDetails?.id} />} title='' trigger='click'>
            <WizrButton type="link" icon={<Share />} onClick={() => { appShareLink() }}></WizrButton>
          </Popover> : <></>}
          <StyledHeaderWizrButton type="link" icon={<RefreshIcon />} text={<WizrText type="button" textColor="#1D1F22" >history</WizrText>} onClick={() => { apprunHistory() }} disabled={hasAcces('view_runhistory') === true ? false : true}></StyledHeaderWizrButton>
          <StyledHeaderWizrButton type="link" icon={<EditIcon />} text={<WizrText type="button" textColor="#1D1F22">Edit</WizrText>} onClick={() => { onEditAction() }}
            disabled={appDetails.app_category === "package" ? true : hasAcces('editdraft_app') === true ? false : true}
          ></StyledHeaderWizrButton>
          <StyledHeaderWizrButton type="link" icon={<Bars />} text={<WizrText type="button" onClick={() => navigate('/appInsights', { state: { app_id: appDetails?.app_id } })} textColor="#1D1F22">Insights</WizrText>}></StyledHeaderWizrButton>
        </WizrFlexLayout></WizrFlexLayout>
{/* navigate(`/app-designer/tool`, { state: { action, id: appDetails?.id, appId: appDetails?.app_id } }) */}
    </WizrFlexLayout>
    {selectedOperation === "1" ? <>

      <WizrFlexLayout flex={1} alignItems="flex-start" style={{ padding: "20px 0 0 20px" }}>
        <Radio.Group onChange={onTabChange} value={selectedTab}>
          <Radio.Button value="single" style={getButtonStyle('single')}>
            Single Run
          </Radio.Button>
          <Radio.Button value="bulk" style={getButtonStyle('bulk')}>
            Bulk Run
          </Radio.Button>

        </Radio.Group>
      </WizrFlexLayout>

      <WizrFlexLayout flex={.5} flexDirection="row" alignItems="flex-start" style={{ padding: " 0 10px" }}>

        <WizrFlexLayout flex={2.3} alignItems="flex-start">
          <WizrText type="sub1">{selectedTab === "bulk" ? "Bulk Run" : "Single Run"}</WizrText>
        </WizrFlexLayout>

        <WizrFlexLayout flexDirection="row" flex={3} alignItems="flex-start" style={{ paddingLeft: "20px" }}>
          <WizrText type="sub1">Output</WizrText>
          {key === "bulk" ? <WizrButton type="link" icon={<HistoryIcon />} onClick={() => {
            window.open(`/bulkrunlist/${appDetails?.app_id}`, '_blank', 'noopener,noreferrer')
          }} text={<WizrText type="body2">View Bulk Runs</WizrText>}></WizrButton>
            : <WizrButton type="link" icon={<RefreshIcon />} onClick={() => { dispatchClearChat() }} text={<WizrText type="body2">refresh</WizrText>}></WizrButton>}

          {/* <WizrButton type="link" text={<WizrText type="button" textColor="black">view run results</WizrText>}></WizrButton> */}
        </WizrFlexLayout>


      </WizrFlexLayout>
      <WizrFlexLayout flex={9} flexDirection="row" style={{ paddingTop: "0" }}>

        <WizrFlexLayout flex={2.3} style={{ padding: " 0 10px" }} justifyContent="flex-start">

          {key === "bulk" ?
            <BulkRunContainerLeft manageFileUpload={manageFileUpload} manageFileDownload={manageFileDownload} parameters={parameterList} uploadedfile={uploadedfile} setUploadedfile={setUploadedfile} setIsTested={setIsTested} isProcessing={isProcessing} execution_status_id={currentExecutionStatusId} app_id={appDetails?.app_id} executeBulkrun={executeBulkrun} setIsProcessing={setIsProcessing} /> :
            <SingleRunContainerLeft parameters={parameterList} doUpdateFields={doUpdateFields} saveFileParams={setFileParams} saveFilesData={setFilesData} appDetails={appDetails} app_id={appDetails?.app_id}/>}
        </WizrFlexLayout>

        <WizrFlexLayout flex={3} style={{ padding: " 0 10px" }}>
          {key === "bulk" ?
            <BulkRunContainerRight isTested={isTested} manageOutputDownload={manageOutputDownload} isProcessing={isProcessing} uploadedfile={uploadedfile} previousRunStatus={previousRunStatus} /> :
            <WizrFlexLayout style={{ borderRadius: "16px", padding: "15px" }} background="white">
              <Chat chatMessage={chatMessage} onChatClick={onChatClick} mapValueToUserQuery={mapValueToUserQuery} bulkRun={true} apptest={false} />
            </WizrFlexLayout>}

        </WizrFlexLayout>


      </WizrFlexLayout></> :
      <APIIntegration appDetails={appDetails} />}

  </WizrFlexLayout>
}
export default BulkRun



