import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import WizrText from '../../../components/WizrText'
import WizrDropDown from '../../../components/WizrDropDown'
import WizrTagInput from '../../../components/WizrTagInput'
import { usePromtTemplateSelector, useContentsSelector, useVisionModelSelector } from '../../../redux/selectors/content'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrInput from '../../../components/WizrInput'
import WizrTag from '../../../components/WizrTags'
import WizrFlexSpacing from '../../../components/WizrFlexSpacing'
import { useEdges, useNodes } from 'reactflow'
import { ArrowsAltOutlined, GroupOutlined, ShrinkOutlined } from '@ant-design/icons'
import WizrTextArea from '../../../components/WizrTextArea'
import CodeEditor from '@uiw/react-textarea-code-editor';
import { generateNodeSequenceResponse, getTenantId } from '../../../utils'
import WizrButton from '../../../components/WizrButton'
import DeleteIcon from '../../../assets/icons/DeleteIcon'
import { dispatcVisionMModels } from '../../../redux/actions/content'

import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import WizrWholeTextArea from '../../../components/WizrWholeTextArea'
import { Tooltip } from 'antd'


const VisionNodeEdit = (props: any): JSX.Element => {

  const { id, dataCode, updateNodeData, data, nodeUpdate, closeEditArea } = props

  const [selectedModel, setSelectedModel] = useState<any>("")
  const [token, setToken] = useState<any>("")
  const [userQuery, setUserQuery] = useState<any>("")
  const [parameterList, setParameterList] = useState<any>()
  const [modelSetting, setModelSetting] = useState<any>([])
  const [viewFullUserQuery,setViewFullUserQuery] = useState(false)

  const { register, control, setValue, getValues } = useForm({
    defaultValues: {
      visionBlock: [{ file: "" }]
    }
  });
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "visionBlock"
  });  

  const visionModelData: any = useVisionModelSelector()

  const edges = useEdges()
  const nodes = useNodes()

  const startnodedata: any = nodes && nodes[0]?.data


  useEffect(() => {
    const saveAppPayload = {
      edges: edges,
      nodes: nodes,
    };
    const sequence = generateNodeSequenceResponse(saveAppPayload);
    const trimmedNodeSequence = elementsBeforeSpecificElement(sequence, id);
    const defaultParameters = startnodedata?.config?.parameters.map((param: any) => param?.name) || []
    setParameterList((prevList: any) => [...defaultParameters, ...trimmedNodeSequence]);

  }, [nodes, edges, setParameterList, id]);

  useEffect(() => {
    const tenantId =  getTenantId()
    dispatcVisionMModels()
  }, [])

  useEffect(() => {
    setSelectedModel(data?.config?.llm_type ? data?.config?.llm_type : '')
    
    if (data?.config?.llm_type){
      const selected = visionModelData?.filter((item:any)=>item?.llm_type===data?.config?.llm_type)
      
      setModelSetting(selected[0]?.llm_settings)
    }
    setToken(data?.config?.max_tokens ? data?.config?.max_tokens : '')
    setUserQuery(data?.config?.user_query ? data?.config?.user_query : '')
    const newArray = data?.config?.files.map((value: any, index: any) => ({ file: value }));
    if (newArray) {
      setValue("visionBlock", newArray);
    }
    
  }, [data])
  



  function elementsBeforeSpecificElement(arr: any, specificElement: any) {
    const index = arr.indexOf(specificElement);
    if (index === -1) {
      return arr;
    }
    const newArray = arr.slice(0, index).slice(1).map((item: any) => {
      return `${item}.output`
    })
    return newArray

  }

  const [isEditView, setIsEditView] = useState(false)

  const updateCurrentNodeData = () => {    
const currentModel =  visionModelData?.find((item:any)=>item?.llm_type===selectedModel)
    const fieldArrayFormValues = getValues().visionBlock
    updateNodeData((nds: any) =>
      nds.map((node: any) => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            config: {
              user_query: userQuery,
              max_tokens: token,
              llm_type: selectedModel,
              vision_llm_model_id: currentModel?.id,
              files: fieldArrayFormValues?.length > 0 ? fieldArrayFormValues.map((item: any) => item.file) : []
            },
          }
        }
        return node
      }),
    )
    closeEditArea()
  }

  const onDeleteNodeDetail = (e: any) => {
    updateNodeData((nds: any) =>
      nds.filter((node: any) => node.id !== e)
    );
  }

  const drodownConverter = () => {
    const normalOptions = visionModelData?.map((item: any) => {
      return { label: item.display_name, value: item.llm_type, model_type: item.model_type }
    })
    return normalOptions
  }

  useEffect(() => {
    if (nodeUpdate === 'vision') {
      updateCurrentNodeData()
    }
  }, [nodeUpdate])

 const selectModel = (value:any)=>{
  setSelectedModel(value)
  const selected = visionModelData?.filter((item:any)=>item?.llm_type===value)

  
  setModelSetting(selected[0]?.llm_settings)
 }
 const findName=(name:any)=>{
  
  switch (name){
    case "max_tokens":
      return "Max Tokens"
  }
 }
 const onSettingChange=(text:any,name:any)=>{
  
  switch (name){
    case "max_tokens":
      setToken(parseInt(text))
      break;
  }
 }
 const getValue=(text:any)=>{
  
  switch (text){
    case "max_tokens":
     return token
  }
 }

 const [cursorPosition, setCursorPosition] = useState<number | null>(null);
 const handleCursorChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
   setCursorPosition(event.target.selectionStart);
 };
  return (

      <VisionContainer alignItems='flex-start' background='#fff'>
          {!viewFullUserQuery?<>
            <WizrText type='body2'>Model</WizrText>
            <WizrDropDown
              placeHolder='Select one option'
              value={selectedModel}
              options={drodownConverter()}
              onChange={(value: any) => { selectModel(value) }}
              className="nodrag nopan"
            />
            <WizrFlexLayout background='white' flexDirection='row'>
            <WizrText type='body2'>User query</WizrText>
            <Tooltip title="Maximize User Query section">  
            <WizrButton icon={  <ArrowsAltOutlined  style={{fontSize:"20px"}}/>} onClick={()=>{setViewFullUserQuery(true)}} type='link' />
            </Tooltip>
             </WizrFlexLayout>
            <WizrInput placeholder='user query' onChange={(text: string) => setUserQuery(text)} value={userQuery}   onSelect={handleCursorChange}
/>
            <StyledFlexSpacing
              justifyContent='space-between'
              flexDirection='column'
              alignItems='center'
              size={20}
            >
              <StyledWizrTag
              background="grey"
              data={parameterList}
              // checkable={true}
              onClick={(e: any) => {
                const clickedTagValue = e; // Replace this with the actual way to get the clicked tag value
                //  if (cursorPosition !== null) {
                  // Insert the clicked tag value at the cursor position
                  const updatedSystemMessage =
                    `${userQuery.slice(0, cursorPosition)}{{${clickedTagValue}}}${userQuery.slice(cursorPosition)}`
                    setUserQuery(updatedSystemMessage)

                  // Update the systemMessage state with the modified value

                  // Move the cursor position to the end of the inserted value
                  // setCursorPosition(cursorPosition + clickedTagValue.length);
                // }
              }}
            />
            </StyledFlexSpacing>
            {modelSetting?.map((item:any)=>{
              return(
                <>
                  <WizrText type='body2'>{findName(item?.key)}</WizrText>
                  <WizrInput placeholder={item?.key} onChange={(text: string) => onSettingChange(text,item?.key)} value={getValue(item?.key)} />
                  <StyledFlexSpacing
              justifyContent='space-between'
              flexDirection='column'
              alignItems='center'
              size={20}
            >
              <StyledWizrTag
                background="grey"
                data={parameterList}
                onClick={(e: any) => { }} />
            </StyledFlexSpacing>
                  </>
              )
              
            })}
          
            
            {fields.map((item, index) => {
              return (
                <WizrFlexLayout key={item.id} background='#fff'>
                  <WizrText type='body3' textColor='#707276'>
                    File
                  </WizrText>
                  <WizrFlexLayout flexDirection='row' justifyContent='flex-start' alignItems='flex-start' style={{ gap: '10px', margin: '5px 0px' }}>
                    <Controller
                      render={({ field }) => <WizrInput {...field} />}
                      name={`visionBlock.${index}.file`}
                      control={control}
                    />
                    <WizrButton type='text' icon={<DeleteIcon />} onClick={() => remove(index)} style={{ minHeight: '50px' }} />
                  </WizrFlexLayout>
                  <StyledFlexSpacing justifyContent='space-between' flexDirection='column' alignItems='center' size={20}>
                    <StyledWizrTag
                      background="grey"
                      data={parameterList}
                      onClick={(e: any) => {
                        setValue(`visionBlock.${index}.file`, `{{${e}}}`);
                      }}
                    />
                  </StyledFlexSpacing>
                </WizrFlexLayout>
              );
            })}
            <WizrButton type='text' text='Add files' onClick={() => append({ file: '' })} style={{ borderWidth: '2px', borderColor: 'black', borderRadius: '20px', width: '100%' }} />
            </>:
            <WizrFlexLayout style={{padding:'20px'}} background='white'>
            <WizrFlexLayout flexDirection='row' justifyContent='space-between' background='white'>
          <WizrText type='sub2'>User Query</WizrText>
          <Tooltip title="Restore User Query section"> 
          <WizrButton icon={<ShrinkOutlined style={{fontSize:"20px"}}/>} onClick={()=>{setViewFullUserQuery(false)}} type='link' />
          </Tooltip>
          </WizrFlexLayout>
                  <WizrWholeTextArea 
                  value={userQuery}
                  onSelect={handleCursorChange}
                  onChange={(text: string) => setUserQuery(text)}/>
                 <StyledFlexSpacing
              justifyContent='space-between'
              flexDirection='column'
              alignItems='center'
              size={20}
            >
              <StyledWizrTag
              background="grey"
              data={parameterList}
              onClick={(e: any) => {
                const clickedTagValue = e; // Replace this with the actual way to get the clicked tag value
                 if (cursorPosition !== null) {
                  // Insert the clicked tag value at the cursor position
                  const updatedSystemMessage =
                    `${userQuery.slice(0, cursorPosition)}{{${clickedTagValue}}}${userQuery.slice(cursorPosition)}`
                    setUserQuery(updatedSystemMessage)

                  // Update the systemMessage state with the modified value

                  // Move the cursor position to the end of the inserted value
                  setCursorPosition(cursorPosition + clickedTagValue.length + 4);
                }
              }}
            />
            </StyledFlexSpacing>
                  </WizrFlexLayout>}
      </VisionContainer>
  )
}

const VisionContainer = styled(WizrFlexLayout)`
  gap: 16px;
  margin-bottom: 90px;
`
const StyledWizrButton = styled(WizrButton)`
margin-left:610px;
margin-bottom:7px;`

const StyledWizrTag = styled(WizrTag)`
  height: 42px;
  padding: 8px 11px;
  justify-content: space-between;
`

const StyledFlexSpacing = styled(WizrFlexSpacing)`
  .ant-space-item {
    display: flex !important;
    width: 100% !important;
    flex-wrap: wrap;
  }
  background-color:white;
`

export default VisionNodeEdit
