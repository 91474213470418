import React from 'react'

const DoclogoIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.308 1.00001H10.829C10.8863 1.00001 10.9433 1.00367 11 1.01101C13.2473 1.16817 14.9924 3.0332 15 5.28601V11.715C14.9917 14.0871 13.0641 16.0044 10.692 16H5.308C2.93551 16.0044 1.00772 14.0865 1 11.714V5.28601C1.00772 2.91353 2.93551 0.995581 5.308 1.00001Z'
        stroke='#DC2626'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8.23378 7.65278L8.22644 7.68703C8.51266 8.4307 8.90651 8.92974 9.5083 9.29668C10.7314 9.14501 11.5167 9.22819 11.8396 9.54621C12.0622 9.76882 12.1283 10.1847 11.9913 10.5149C11.913 10.7033 11.7051 11.0164 11.1767 11.0164C10.6434 11.0164 9.85812 10.7791 9.16826 10.4097C8.55311 10.5135 7.87373 10.7433 7.21692 10.9655L7.21691 10.9655L7.18921 10.9748C7.04733 11.0238 6.90055 11.0727 6.75867 11.1192C5.82908 12.7141 5.13189 13.0615 4.70134 13.0615C4.5986 13.0615 4.51053 13.0395 4.43714 13.0126C4.26835 12.949 4.12402 12.8169 4.0335 12.6457C3.95277 12.4915 3.91853 12.3203 3.94788 12.1857C4.09221 11.4568 5.06339 10.8794 5.78749 10.5051C6.17645 9.82019 6.7073 8.66799 7.03999 7.87539C6.77579 6.85284 6.63146 6.05046 6.79292 5.46335C6.87854 5.14289 7.22346 4.92272 7.60998 4.93984C7.93778 4.95452 8.32185 5.15756 8.41481 5.67862C8.55348 6.33549 8.37762 7.0601 8.23612 7.64312L8.23378 7.65278ZM7.69809 6.85041C7.79594 6.51771 7.81307 5.85966 7.68586 5.64194C7.66874 5.61014 7.65161 5.59546 7.63204 5.59302C7.5611 5.67374 7.57822 6.3636 7.69809 6.85041ZM7.64665 8.58966C7.9084 9.04223 8.23865 9.43119 8.60315 9.71985C8.08012 9.8385 7.60264 10.0338 7.17543 10.2086L7.16228 10.214C7.12028 10.231 7.07869 10.248 7.03751 10.2648L7.0375 10.2648C6.97794 10.2892 6.91924 10.3132 6.86139 10.3363C7.18675 9.74676 7.45828 9.07892 7.64665 8.58966ZM11.4433 10.214C11.4579 10.2042 11.4555 10.1969 11.453 10.192L11.4525 10.1912C11.4435 10.1765 11.3137 9.96585 10.3498 10.011C11.2133 10.351 11.4139 10.2336 11.4335 10.2213L11.4384 10.214H11.4433ZM5.54281 11.4028C5.20278 11.8969 4.94592 12.144 4.80648 12.2516C4.97038 11.9507 5.29084 11.6327 5.54281 11.4028Z'
        fill='#DC2626'
      />
      <path
        d='M15 6.03599C15.4142 6.03599 15.75 5.7002 15.75 5.28599C15.75 4.87177 15.4142 4.53599 15 4.53599V6.03599ZM11 5.28599H10.25C10.25 5.7002 10.5858 6.03599 11 6.03599V5.28599ZM11.75 1.01099C11.75 0.596773 11.4142 0.260986 11 0.260986C10.5858 0.260986 10.25 0.596773 10.25 1.01099H11.75ZM15 4.53599H11V6.03599H15V4.53599ZM11.75 5.28599V1.01099H10.25V5.28599H11.75Z'
        fill='#DC2626'
      />
    </svg>
  )
}

export default DoclogoIcon
