import { Button, Input, Spin, Table, TableProps } from 'antd'
import WizrText from './WizrText'
import WizrTag from './WizrTags'
import WizrButton from './WizrButton'
import FolderIcon from '../assets/icons/Folder'
import FileIcon from '../assets/icons/FileIcon'
import WizrAvatar from './WizrAvatar'
import { dispatchUpdateCurrentFolder, dispatchTicketDetails, dispatchupdateUserView, dispatchCurrentModule, dispatchDeleteTicketsFaqPt, dispatchListUserRows, dispatchCustomerHealthDetails } from '../redux/actions/content'
import { downloadBlob, routerHistory } from '../utils'
import { wizrAzureConnectorInstance } from '../utils/wizrAzureConnector'
import FolderActionPopOver from './popovers/FolderAction'
import FileActionPopOver from './popovers/FileAction'
import PromptActionPopOver from './popovers/PromptAction'
import Hamburger from '../assets/icons/Hamburger'
import { Body2 } from '../stories/WizrText/WizrText.stories'
import AppActionPopOver from './popovers/AppAction'
import PdflogoIcon from '../assets/icons/Pdflogo'
import DoclogoIcon from '../assets/icons/Doclogo'
import Mp3logoIcon from '../assets/icons/Mp3logo'
import Mp4logoIcon from '../assets/icons/Mp4logo'
import UrlIcon from '../assets/icons/UrlIcon'
import UrllogoIcon from '../assets/icons/UrllogoIcon'
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import AppRun from './popovers/AppRun'
import FileName from './popovers/FileName'
import styled from 'styled-components'
import BulkRunDownload from './popovers/BulkRunDownload'
import TenantActionPopOver from './popovers/TenantActionPopOver'
import UserAction from './popovers/UserAction'
import ProductActionPopOver from './popovers/ProductAction'
import RolesActionPopover from './popovers/RolesActionPopover'
import WizrFlexLayout from './WizrFlexLayout'
import PackageActionPopOver from './popovers/packageAction'
import IntegrationActionPopOver from './popovers/IntegrationActionPopOver'
import GroupActionPopover from './popovers/GroupActionPopover'
import InfoIcon from '../assets/icons/Info'
import TicketActionPopover from './popovers/TicketActionPopover'
import PersonaActionPopOver from './popovers/PersonaActionPopOver'
import WizrFlexSpacing from './WizrFlexSpacing'
import ZendeskIcon from '../assets/icons/ZendeskIcon'
import DeleteIcon from '../assets/icons/DeleteIcon'
import GoogleIcon from '../assets/icons/GoogleIcon'
import CustomerHealthCustomerCard from './popovers/CustomerHealth'
import CustomerHealthRoundTag from './popovers/CustomerHealthRoundTag'
import WizrIcon from './WizrIcon'
// import WizrTags from './WizrTags'

type WizrTableType =
  | 'folder'
  | 'file'
  | 'prompt'
  | 'history'
  | 'App'
  | 'AppTemplate'
  | 'AppDraft'
  | 'apphistory'
  | 'bulkrun'
  | 'tenants'
  | 'users'
  | 'billing'
  | 'invoice'
  | 'products'
  | 'roles'
  | 'packages'
  | 'past_tickets'
  | 'integrations'
  | 'grouplisting'
  | 'intentcategorytable'
  | 'tagkeywordtable'
  | 'tickettable'
  | 'sentimenttable'
  | 'tickettypetable'
  | 'persona'
  | 'tickets'
  | 'integrationsSettings'
  | 'AppDraftMaster'
  | 'AppMaster'
  | 'faq'
  | 'customerHealth'
  | 'appInsights'
  | 'appInsightsSingle'


interface IWizrTable extends TableProps<any> {
  type: WizrTableType
  tabSelected?: string
}


const WizrTable = ({ type, tabSelected, ...props }: IWizrTable): JSX.Element => {

  const uniqueAppNames = new Set();
  const uniqueAppId = new Set();

  props?.dataSource && props?.dataSource.forEach(item => {
    uniqueAppNames.add(item.app_name);
  });

  props?.dataSource && props?.dataSource.forEach(item => {
    uniqueAppId.add(item.app_id);
  });
  
  // Convert the Set to an array and map it to the desired structure
  const filerArrayAppname = Array.from(uniqueAppNames).map((appName, index) => ({
    text: appName, // Assign a new unique id starting from 1
    value: appName
  }));

  const filerArrayAppId = Array.from(uniqueAppId).map((appId, index) => ({
    text: appId, // Assign a new unique id starting from 1
    value: appId
  }));
  

  const filterValues:  any = [
    // {
    //   text: 'App1',
    //   value: 'App1',
    // },
    // {
    //   text: 'App2',
    //   value: 'App2',
    // },
    // {
    //   text: 'App3',
    //   value: 'App3',
    // },
  ]

  const tableColumnConfigs: { [key: string]: any } = {
    folder: [
      {
        title: 'Name',
        dataIndex: 'folder_name',
        key: 'folder_name',
        sorter: (a: any, b: any) => a.folder_name.localeCompare(b.folder_name),
        render: (text: string, folderDetail: any) => (
          <WizrButton
            padding={'0px'}
            icon={<FolderIcon />}
            text={text}
            type='text'
            onClick={() => {
              dispatchUpdateCurrentFolder(folderDetail)
              routerHistory.navigate(`folder/${folderDetail?.folder_id}`)
            }}
          />
        ),
      },
      {
        title: 'Owner',
        dataIndex: 'owner',
        key: 'owner',
        sorter: (a: any, b: any) => a.user_name.localeCompare(b.user_name),
        render: (text: string, folderDetail: any) => (
          <WizrAvatar name={folderDetail?.user_name} background='transparent'></WizrAvatar>
        ),
      },
      {
        title: 'Description',
        dataIndex: 'folder_description',
        key: 'folder_description',
        render: (text: string) => (
          <div style={{ maxHeight: '200px', overflow: 'auto', maxWidth: '600px' }}>
            <WizrText type='body2'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: '',
        dataIndex: 'moreAction',
        render: (text: string, folderDetail: any) => <FolderActionPopOver detail={folderDetail} type={'knowledge_base'} />,
      },
    ],
    past_tickets: [
      {
        title: '',
        dataIndex: 'folder_name',
        key: 'folder_name',
        render: (text: string, folderDetail: any) => (
          <WizrButton
            padding={'0px'}
            icon={<FolderIcon />}
            text={text}
            type='text'
            onClick={() => {
              // dispatchUpdateCurrentFolder(folderDetail)
              routerHistory.navigate(`past_tickets/${folderDetail?.folder_id}`)
            }}
          />
        ),
      },
      {
        title: '',
        dataIndex: 'folder_description',
        key: 'folder_description',
        render: (text: string) => (
          <div style={{ maxHeight: '200px', overflow: 'auto', minWidth: '600px' }}>
            <WizrText type='body2'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: '',
        dataIndex: 'created_on',
        key: 'created_on',
        render: (text: number) => (
          <WizrText type='body2'>
            {new Date(text).toLocaleString('en-GB', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
      },
      {
        title: '',
        dataIndex: 'moreAction',
        render: (text: string, folderDetail: any) => <FolderActionPopOver detail={folderDetail} type={'past_tickets'} />,
      },
    ],
    faq: [
      {
        title: '',
        dataIndex: 'folder_name',
        key: 'folder_name',
        render: (text: string, folderDetail: any) => (
          <WizrButton
            padding={'0px'}
            icon={<FolderIcon />}
            text={text}
            type='text'
            onClick={() => {
              // dispatchUpdateCurrentFolder(folderDetail)
              routerHistory.navigate(`faq/${folderDetail?.folder_id}`)
            }}
          />
        ),
      },
      {
        title: '',
        dataIndex: 'folder_description',
        key: 'folder_description',
        render: (text: string) => (
          <div style={{ maxHeight: '200px', overflow: 'auto', minWidth: '600px' }}>
            <WizrText type='body2'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: '',
        dataIndex: 'created_on',
        key: 'created_on',
        render: (text: number) => (
          <WizrText type='body2'>
            {new Date(text).toLocaleString('en-GB', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
      },
      {
        title: '',
        dataIndex: 'moreAction',
        render: (text: string, folderDetail: any) => <FolderActionPopOver detail={folderDetail} type={'faq'} />,
      },
    ],
    tickets: [
      {
        title: 'Ticket id',
        dataIndex: 'ticket_id',
        sorter: (a: any, b: any) => a.ticket_id.localeCompare(b.ticket_id),
        render: (text: string) => (
          <div>
            <WizrText type='body2'>{text}</WizrText>
          </div>
        ),
        key: 'ticket_id',
      },
      {
        title: 'Subject',
        dataIndex: 'subject',
        sorter: (a: any, b: any) => a.user_name.localeCompare(b.user_name),
        key: 'subject',
        render: (text: string) => (
          <div>
            <WizrText type='body2'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        render: (text: string) => (
          <div>
            <WizrText type='body2'>{text}</WizrText>
          </div>
        ),
      },
  
      {
        title: 'Ticket Priority',
        dataIndex: 'priority',
        key: 'priority',
        render: (text: string) => (
          <WizrTag
            background="#EF4444"
            data={[text]}
            borderradius='4px'
          />
        ),
      },
      {
        title: 'Raised by',
        dataIndex: 'raised_by',
        key: 'raised_by',
        render: (text: string, fileDetail: any) => (
          <WizrAvatar background='transparent' name={text}></WizrAvatar>
        ),
      },
      {
        title: 'Created On',
        dataIndex: 'ticket_created_on',
        key: 'ticket_created_on',
        render: (text: string) => (
          <div>
            <WizrText type='body2'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: '',
        dataIndex: '',
        render: (text: string) =>
          <div>
            <WizrButton
              icon={<InfoIcon />}
              type='text'
              onClick={() => { console.log('dispatchTicketDetails', text); dispatchTicketDetails(text) }}
            />
          </div>,
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: (text: any) =>
          <div>
            <WizrButton
              icon={<DeleteIcon />}
              type='text'
              onClick={async () => {
                await dispatchDeleteTicketsFaqPt(text?.row_id, text?.type)
                await dispatchListUserRows(text?.type, text?.folderId)
  
              }}
              disabled={true}
            />
          </div>,
      },
    ],
    file: [
      {
        title: 'Name',
        dataIndex: 'file_name',
        sorter: (a: any, b: any) => a.file_name.localeCompare(b.file_name),
        render: (text: string, details: any) => {
          const iconList: any = {
            PDF: PdflogoIcon,
            DOC: DoclogoIcon,
            MP3: Mp3logoIcon,
            MP4: Mp4logoIcon,
            url: UrllogoIcon,
            TEXT: FileIcon,
          }
          const folderId = localStorage.getItem('folderId')
          const MyComponent = ({ fileType }: any) => {
            if (iconList[fileType]) {
              const IconComponent = iconList[fileType]
              return <IconComponent />
            } else {
              // If the fileType is not in the list, render a default icon or handle the case accordingly.
              return <FileIcon />
            }
          }
  
          return (
            <div style={{ maxWidth: '800px', overflow: 'auto' }}>
              <FileName details={details} text={text} />
            </div>
          )
        },
        key: 'file_name',
      },
      {
        title: 'Owner',
        dataIndex: 'owner',
        sorter: (a: any, b: any) => a.user_name.localeCompare(b.user_name),
        key: 'owner',
        render: (text: string, fileDetail: any) => (
          <WizrAvatar background='transparent' name={fileDetail?.user_name}></WizrAvatar>
        ),
      },
      {
        title: 'Tags',
        dataIndex: 'tags',
        render: (text: any) => {
          return text?.length > 0 && text[0] !== '' ? <WizrTag type='colorfull' data={text} /> : null
        },
      },
  
      {
        title: 'Upload Status',
        dataIndex: 'upload_status',
        key: 'upload_status',
        render: (text: string) => (
          <WizrText type='body2'>
            {text}
            {text === 'inprogress' ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 15, marginLeft: 5 }} spin />} />
            ) : (
              <></>
            )}
          </WizrText>
        ),
      },
  
      {
        title: '',
        dataIndex: '',
        render: (text: string, fileDetail: any) => <FileActionPopOver detail={fileDetail} />,
      },
    ],
    prompt: [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      },
      {
        title: 'Owner',
        dataIndex: 'user_name',
        key: 'user_name',
        render: (text: string, promptDetail: any) => (
          <WizrAvatar gapSize={-10} name={promptDetail?.user_name} background='#FFF'></WizrAvatar>
        ),
        sorter: (a: any, b: any) => a.user_name.localeCompare(b.user_name),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Tags',
        dataIndex: 'tags',
        key: 'tags',
        render: (tags: string[], record: any) => (
          <WizrTag type='colorfull' checkable={false} data={tags} />
        ),
        sorter: (a: any, b: any) => (a.tags?.length || 0) - (b.tags?.length || 0),
      },
      {
        title: 'Last UpdatedOn',
        dataIndex: 'updated_on',
        key: 'updated_on',
        render: (text: number) => (
          <WizrText type='body2'>
            {new Date(text).toLocaleString('en-GB', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
        sorter: (a: any, b: any) => a?.updated_on - b?.updated_on,
      },
      {
        title: '',
        dataIndex: '',
        render: (text: any, promptDetail: any) => (
          <PromptActionPopOver detail={promptDetail} tabSelected={text.published} />
        ),
      },
    ],
    history: [
      {
        title: 'Model',
        width: 100,
        dataIndex: 'llm_model_name',
        key: 'llm_model_name',
        render: (text: string) => (
          <div style={{ maxHeight: '200px', overflow: 'auto' }}>
            <WizrText type='body4'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: 'Query',
        width: 190,
        dataIndex: 'query',
        key: 'query',
        render: (text: string) => (
          <div style={{ maxHeight: '200px', overflow: 'auto' }}>
            <WizrText type='body4'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: 'Output',
        width: 400,
        dataIndex: 'response',
        key: 'response',
        render: (text: string) => (
          <div
            style={{
              maxHeight: '200px',
              overflow: 'auto',
              whiteSpace: 'pre-wrap',
              overflowWrap: 'break-word',
            }}
          >
            <WizrText type='body4'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: 'System',
        width: 300,
        dataIndex: 'system_message_test_values',
        key: 'system_message_test_values',
        render: (text: string) => (
          <div style={{ maxHeight: '200px', overflow: 'auto' }}>
            <WizrText type='body4'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: 'Feedback',
        width: 70,
        dataIndex: 'feedback',
        key: 'feedback',
        render: (text: number) => (
          <div style={{ maxHeight: '200px', overflow: 'auto' }}>
            {' '}
            <WizrText type='body4'>{text === 0 ? 'LIKE' : text === 1 ? 'DISLIKE' : 'NA'}</WizrText>
          </div>
        ),
      },
      {
        title: 'Comments',
        width: 70,
        dataIndex: 'comment',
        key: 'comment',
        render: (text: string) => (
          <div style={{ maxHeight: '200px', overflow: 'auto' }}>
            <WizrText type='body4'>{text ? text : 'NA'}</WizrText>
          </div>
        ),
      },
      {
        title: 'Parameters',
        width: 100,
        dataIndex: 'message_params_test_values',
        key: 'message_params_test_values',
        render: (text: any) => (
          <div style={{ maxHeight: '200px', overflow: 'auto' }}>
            {' '}
            <WizrText type='body4'>
              {Object.keys(text).length === 0 ? 'NA' : JSON.stringify(text)}
            </WizrText>
          </div>
        ),
      },
      {
        title: 'Temperature',
        width: 70,
        dataIndex: ['prompt_body', 'temperature'],
        key: 'temperature',
        render: (text: string) => (
          <div style={{ maxHeight: '200px', overflow: 'auto' }}>
            {' '}
            <WizrText type='body4'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: 'Token Limit',
        width: 70,
        dataIndex: ['prompt_body', 'max_tokens'],
        key: 'max_tokens',
        render: (text: string) => (
          <div style={{ maxHeight: '200px', overflow: 'auto' }}>
            <WizrText type='body4'>{text}</WizrText>
          </div>
        ),
      },
  
      {
        title: 'Zero Shot',
        width: 80,
        dataIndex: ['prompt_body', 'zero_shot'],
        key: 'zero_shot',
        render: (text: boolean) => (
          <div style={{ maxHeight: '200px', overflow: 'auto' }}>
            {' '}
            <WizrText type='body4'>{text === true ? 'true' : 'false'}</WizrText>
          </div>
        ),
      },
      {
        title: 'Example',
        width: 250,
        dataIndex: 'examples_test_values',
        key: 'examples_test_values',
        render: (text: any = '') =>
          text?.length ? (
            <div style={{ maxHeight: '200px', overflow: 'auto' }}>
              <WizrText type='body4'>
                {text.map((item: any) => {
                  return `{"user": "${item.user}", "assistant": "${item.assistant}"}`
                })}
              </WizrText>
            </div>
          ) : (
            <WizrText type='body4'>NA</WizrText>
          ),
      },
      {
        title: 'Token Used',
        width: 70,
        dataIndex: ['llm_audit', 'total_tokens'],
        key: 'total_tokens',
        render: (text: string) => <WizrText type='body4'>{text}</WizrText>,
      },
      {
        title: 'Username',
        width: 70,
        dataIndex: 'user_name',
        key: 'user_name',
        render: (text: string) => <WizrText type='body4'>{text}</WizrText>,
      },
      {
        title: 'Time of Run',
        dataIndex: 'run_time',
        key: 'run_time',
        render: (text: number) => (
          <WizrText type='body2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
      },
      {
        title: 'Time of Response',
        dataIndex: ['llm_audit', 'response_time'],
        key: 'response_time',
        render: (text: number) => (
          <WizrText type='body2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
      },
      {
        title: 'Duration Of Run',
        dataIndex: 'duration',
        key: 'duration',
        render: (text: number) => <WizrText type='body2'>{text} sec</WizrText>,
      },
      {
        title: 'Memory',
        width: 70,
        dataIndex: 'memory_required',
        key: 'memory_required',
        render: (text: string) => (
          <WizrText type='body4'>{text === 'true' ? 'True' : 'False'}</WizrText>
        ),
      },
      {
        title: 'Knowledge Base',
        width: 70,
        dataIndex: 'knowledgebase_required',
        key: 'knowledgebase_required',
        render: (text: string) => (
          <WizrText type='body4'>{text === 'true' ? 'True' : 'False'}</WizrText>
        ),
      },
      {
        title: 'Stop',
        width: 70,
        dataIndex: ['prompt_body', 'stop_sequences'],
        key: 'stop_sequences',
        render: (text: any) => (
          <div style={{ maxHeight: '200px', overflow: 'auto' }}>
            <WizrText type='body4'>{text && text.length > 0 ? text.join(', ') : 'NA'}</WizrText>
          </div>
        ),
      },
      {
        title: 'Presence Penalty',
        width: 70,
        dataIndex: ['prompt_body', 'presence_penalty'],
        key: 'presence_penalty',
        render: (text: string) => <WizrText type='body4'>{text}</WizrText>,
      },
      {
        title: 'Frequency Penalty',
        width: 70,
        dataIndex: ['prompt_body', 'frequency_penalty'],
        key: 'frequency_penalty',
        render: (text: string) => <WizrText type='body4'>{text}</WizrText>,
      },
      {
        title: 'Top P',
        width: 70,
        dataIndex: ['prompt_body', 'top_p'],
        key: 'top_p',
        render: (text: string) => <WizrText type='body4'>{text}</WizrText>,
      },
    ],
    AppTemplate: [
      {
        title: 'Name',
        dataIndex: 'app_name',
        key: 'app_name',
        render: (text: any, appDetail: any) => (
          <WizrFlexLayout flexDirection='coulmn' background='white'>
            <WizrText type='body2'>{appDetail?.app_name}</WizrText>
            <WizrText type='caption'>{` Version ${appDetail?.app_version}`}</WizrText>
          </WizrFlexLayout>
        ),
        sorter: (a: any, b: any) => a.app_name.localeCompare(b.app_name),
      },
      {
        title: 'App Type',
        dataIndex: 'app_category',
        key: 'app_category',
        render: (text: string) => (
          <div style={{ maxHeight: '100px', overflow: 'auto', maxWidth: '600px' }}>
            <WizrText type='body2'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: 250,
        render: (text: string) => (
          <div style={{ maxHeight: '100px', overflow: 'auto', maxWidth: '600px' }}>
            <WizrText type='body2'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: 'Tags',
        dataIndex: 'tags',
        key: 'tags',
        render: (text: any) => (
          <WizrTag
            checkable={false}
            data={text?.length > 0 && text[0] !== null ? text : null}
            type='colorfull'
          />
        ),
        //sorter: (a: any, b: any) => (a.tags?.length || 0) - (b.tags?.length || 0),
      },
      {
        title: 'Owner',
        dataIndex: 'user_name',
        key: 'user_name',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
  
        sorter: (a: any, b: any) => a.user_name.localeCompare(b.user_name),
      },
      {
        title: 'Updated On',
        dataIndex: 'updated_on',
        key: 'updated_on',
        render: (text: number) => (
          <WizrText type='body2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
        sorter: (a: any, b: any) => a.updated_on - b.updated_on,
      },
      {
        title: '',
        dataIndex: 'published',
        key: 'published',
        width: 90,
        render: (text: boolean, appDetail: any) => text && <AppRun appDetail={appDetail} />
      },
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, appDetail: any) => <AppActionPopOver detail={appDetail} type='template' />,
      },
    ],
    App: [
      {
        title: 'Name',
        dataIndex: 'app_name',
        key: 'app_name',
        render: (text: any, appDetail: any) => (
          <WizrFlexLayout flexDirection='coulmn' background='white'>
            <WizrText type='body2'>{appDetail?.app_name}</WizrText>
            <WizrText type='caption'>{` Version ${appDetail?.app_version}`}</WizrText>
          </WizrFlexLayout>
        ),
        sorter: (a: any, b: any) => a.appDetail?.app_name.localeCompare(b.appDetail?.app_name),
      },
  
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: 250,
        render: (text: string) => (
          <div style={{ maxHeight: '100px', overflow: 'auto', maxWidth: '600px' }}>
            <WizrText type='body2'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'published',
        key: 'published',
        render: (text: boolean) => (
          <WizrTag
            background={text ? '#22C55E' : '#EF4444'}
            data={text ? ['Active'] : ['INACTIVE']}
            borderradius='4px'
          />
        ),
      },
      {
        title: 'Tags',
        dataIndex: 'tags',
        key: 'tags',
        render: (text: any) => (
          <WizrTag
            checkable={false}
            data={text?.length > 0 && text[0] !== null ? text : null}
            type='colorfull'
          />
        ),
        //sorter: (a: any, b: any) => (a.tags?.length || 0) - (b.tags?.length || 0),
      },
      {
        title: 'Owner',
        dataIndex: 'user_name',
        key: 'user_name',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
  
        sorter: (a: any, b: any) => a.user_name.localeCompare(b.user_name),
      },
      {
        title: 'Updated On',
        dataIndex: 'updated_on',
        key: 'updated_on',
        render: (text: number) => (
          <WizrText type='body2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
        sorter: (a: any, b: any) => a.updated_on - b.updated_on,
      },
      {
        title: '',
        dataIndex: 'published',
        key: 'published',
        width: 90,
        render: (text: boolean, appDetail: any) => text && <AppRun appDetail={appDetail} />,
      },
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, appDetail: any) => (
          <AppActionPopOver detail={appDetail} type='published' />
        ),
      },
    ],
    AppDraft: [
      {
        title: 'Name',
        dataIndex: 'app_name',
        key: 'app_name',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
        sorter: (a: any, b: any) => a.app_name.localeCompare(b.app_name),
      },
  
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: 250,
        render: (text: string) => (
          <div style={{ maxHeight: '100px', overflow: 'auto', maxWidth: '600px' }}>
            <WizrText type='body2'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: 'Tags',
        dataIndex: 'tags',
        key: 'tags',
        render: (text: any) => (
          <WizrTag
            checkable={false}
            data={text?.length > 0 && text[0] !== null ? text : null}
            type='colorfull'
          />
        ),
        //sorter: (a: any, b: any) => (a.tags?.length || 0) - (b.tags?.length || 0),
      },
      {
        title: 'Owner',
        dataIndex: 'user_name',
        key: 'user_name',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
  
        sorter: (a: any, b: any) => a.user_name.localeCompare(b.user_name),
      },
      {
        title: 'Updated On',
        dataIndex: 'updated_on',
        key: 'updated_on',
        render: (text: number) => (
          <WizrText type='body2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
        sorter: (a: any, b: any) => a.updated_on - b.updated_on,
      },
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, appDetail: any) => <AppActionPopOver detail={appDetail} type='draft' />,
      },
    ],
    AppMaster: [
      {
        title: 'Name',
        dataIndex: 'app_name',
        key: 'app_name',
        render: (text: any, appDetail: any) => (
          <WizrFlexLayout flexDirection='coulmn' background='white'>
            <WizrText type='body2'>{appDetail?.app_name}</WizrText>
            <WizrText type='caption'>{` Version ${appDetail?.app_version}`}</WizrText>
          </WizrFlexLayout>
        ),
        sorter: (a: any, b: any) => a.appDetail?.app_name.localeCompare(b.appDetail?.app_name),
      },
      {
        title: 'App Type',
        dataIndex: 'app_category',
        key: 'app_category',
        render: (text: string) => (
          <div style={{ maxHeight: '100px', overflow: 'auto', maxWidth: '600px' }}>
            <WizrText type='body2'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: 250,
        render: (text: string) => (
          <div style={{ maxHeight: '100px', overflow: 'auto', maxWidth: '600px' }}>
            <WizrText type='body2'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'published',
        key: 'published',
        render: (text: boolean) => (
          <WizrTag
            background={text ? '#22C55E' : '#EF4444'}
            data={text ? ['Active'] : ['INACTIVE']}
            borderradius='4px'
          />
        ),
      },
      {
        title: 'Tags',
        dataIndex: 'tags',
        key: 'tags',
        render: (text: any) => (
          <WizrTag
            checkable={false}
            data={text?.length > 0 && text[0] !== null ? text : null}
            type='colorfull'
          />
        ),
        //sorter: (a: any, b: any) => (a.tags?.length || 0) - (b.tags?.length || 0),
      },
      {
        title: 'Owner',
        dataIndex: 'user_name',
        key: 'user_name',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
  
        sorter: (a: any, b: any) => a.user_name.localeCompare(b.user_name),
      },
      {
        title: 'Updated On',
        dataIndex: 'updated_on',
        key: 'updated_on',
        render: (text: number) => (
          <WizrText type='body2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
        sorter: (a: any, b: any) => a.updated_on - b.updated_on,
      },
      {
        title: '',
        dataIndex: 'published',
        key: 'published',
        width: 90,
        render: (text: boolean, appDetail: any) => text && <AppRun appDetail={appDetail} />,
      },
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, appDetail: any) => (
          <AppActionPopOver detail={appDetail} type='published' />
        ),
      },
    ],
    AppDraftMaster: [
      {
        title: 'Name',
        dataIndex: 'app_name',
        key: 'app_name',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
        sorter: (a: any, b: any) => a.app_name.localeCompare(b.app_name),
      },
      {
        title: 'App Type',
        dataIndex: 'app_category',
        key: 'app_category',
        render: (text: string) => (
          <div style={{ maxHeight: '100px', overflow: 'auto', maxWidth: '600px' }}>
            <WizrText type='body2'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: 250,
        render: (text: string) => (
          <div style={{ maxHeight: '100px', overflow: 'auto', maxWidth: '600px' }}>
            <WizrText type='body2'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: 'Tags',
        dataIndex: 'tags',
        key: 'tags',
        render: (text: any) => (
          <WizrTag
            checkable={false}
            data={text?.length > 0 && text[0] !== null ? text : null}
            type='colorfull'
          />
        ),
        //sorter: (a: any, b: any) => (a.tags?.length || 0) - (b.tags?.length || 0),
      },
      {
        title: 'Owner',
        dataIndex: 'user_name',
        key: 'user_name',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
  
        sorter: (a: any, b: any) => a.user_name.localeCompare(b.user_name),
      },
      {
        title: 'Updated On',
        dataIndex: 'updated_on',
        key: 'updated_on',
        render: (text: number) => (
          <WizrText type='body2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
        sorter: (a: any, b: any) => a.updated_on - b.updated_on,
      },
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, appDetail: any) => <AppActionPopOver detail={appDetail} />,
      },
    ],
    apphistory: [
      {
        title: 'User Input',
        width: 190,
        dataIndex: ['app_input', 'message_params'],
        key: 'message_params',
        render: (text: string) => (
          <div style={{ maxHeight: '200px', overflow: 'auto' }}>
            {Object.entries(text).map(([key, value]) => (
              <div key={key}>
                <WizrText type='body4'>{key}</WizrText> : <WizrText type='body4'>{value}</WizrText>
              </div>
            ))}
          </div>
        ),
      },
      {
        title: 'End output',
        width: 350,
        dataIndex: 'app_output',
        key: 'app_output',
        render: (text: string) => (
          <div
            style={{
              maxHeight: '200px',
              overflow: 'auto',
              whiteSpace: 'pre-wrap',
              overflowWrap: 'break-word',
            }}
          >
            <WizrText type='body4'>{text}</WizrText>
          </div>
        ),
      },
      {
        title: 'Output format',
        width: 70,
        dataIndex: 'output_format',
        key: 'output_format',
        render: (text: string) => <WizrText type='body4'>{text}</WizrText>,
      },
      {
        title: 'Time of Run',
        width: 100,
        dataIndex: 'run_time',
        key: 'run_time',
        render: (text: number) => (
          <WizrText type='body2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
      },
      {
        title: 'Time of Response',
        width: 60,
        dataIndex: 'response_time',
        key: 'response_time',
        render: (text: number) => (
          <WizrText type='body2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
      },
      {
        title: 'Duration of Response',
        width: 60,
        dataIndex: 'duration',
        key: 'duration',
        render: (text: number) => <WizrText type='body2'>{text} sec</WizrText>,
      },
      {
        title: 'Type',
        width: 70,
        dataIndex: 'execution_status_id',
        key: 'execution_status_id',
        render: (text: string) => (
          <WizrText type='body4'>{text ? 'Bulk Run' : 'Single Run'}</WizrText>
        ),
      },
      {
        title: 'Error',
        width: 100,
        dataIndex: 'error_message',
        key: 'error_message',
        render: (text: string) => (
          <div style={{ maxHeight: '100px', overflow: 'auto', maxWidth: '600px' }}>
            <WizrText type='body4'>{text ? text : 'No errors'}</WizrText>
          </div>
        ),
      },
      {
        title: 'Version',
        width: 70,
        dataIndex: 'app_version',
        key: 'app_version',
        render: (text: string) => <WizrText type='body4'>{text}</WizrText>,
      },
      {
        title: 'User',
        width: 70,
        dataIndex: 'user_name',
        key: 'user_name',
        render: (text: string) => <WizrText type='body4'>{text}</WizrText>,
      },
      {
        title: 'Status',
        width: 70,
        dataIndex: 'status',
        key: 'status',
        render: (text: string) => <WizrText type='body4'>{text}</WizrText>,
      },
    ],
    bulkrun: [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        render: (text: any, record: any, index: any) => index + 1,
      },
      {
        title: 'File Name',
        dataIndex: 'file_name',
        key: 'file_name',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Time of Run',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (text: number) => (
          <WizrText type='body2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
      },
      {
        title: 'Processed Rows',
        dataIndex: 'total_rows',
        key: 'total_rows',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Failed Rows',
        dataIndex: 'failed_rows',
        key: 'failed_rows',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Errors',
        dataIndex: 'error_message',
        key: 'error_message',
        render: (text: string) => <WizrText type='body2'>{text ? text : 'No errors'}</WizrText>,
      },
      {
        title: 'Status',
        dataIndex: 'execution_status',
        key: 'execution_status',
        // render: (text:string) => (
        //   <WizrText type='body2'>{text}</WizrText>
        // ),
        render: (text: any) => (
          <WizrTag
            borderradius='4px'
            checkable={false}
            background={
              text === 'Completed' ? '#22C55E' : text === 'In_Progress' ? '#FBBF24' : '#E5E7EB'
            }
            data={[text]}
          />
        ),
      },
      {
        title: 'Time Taken',
        dataIndex: 'duration',
        key: 'duration',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: ' User Name',
        dataIndex: 'user_name',
        key: 'user_name',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Output',
        width: '400px',
        dataIndex: 'output_file_name',
        key: 'output_file_name',
        render: (text: string, appDetail: any) => (
          // <WizrText type='body2'>{text}</WizrText>
          <BulkRunDownload fileName={appDetail} />
        ),
      },
    ],
    tenants: [
      {
        title: 'Tenant Name',
        dataIndex: 'tenant_name',
        key: 'tenant_name',
        render: (text: string) => <WizrAvatar name={text} background='transparent'></WizrAvatar>,
        sorter: (a: any, b: any) => a.tenant_name.localeCompare(b.tenant_name),
      },
  
      {
        title: 'Type',
        dataIndex: 'tenant_type',
        key: 'tenant_type',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Product',
        dataIndex: 'product_name',
        key: 'product_name',
        render: (text: boolean) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Region',
        dataIndex: 'region',
        key: 'region',
        render: (text: any) => <WizrText type='body2'>{text}</WizrText>,
        //sorter: (a: any, b: any) => (a.tags?.length || 0) - (b.tags?.length || 0),
      },
      {
        title: 'Domain',
        dataIndex: 'user_domains',
        key: 'user_domains',
        render: (text: string[]) => (
          //  <WizrText type='body2'>{text}</WizrText>,
          <WizrTag
            checkable={false}
            data={text !== null && text.length > 0 && text[0] !== null ? text : []}
            type='colorfull'
          />
        ),
        sorter: (a: any, b: any) => a.user_domains.localeCompare(b.user_domains),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 100,
        render: (text: string) => (
          <WizrTag
            background={text === 'active' ? '#22C55E' : '#EF4444'}
            data={text === 'active' ? ['ACTIVE'] : ['DISABLED']}
            borderradius='4px'
          />
        ),
  
        // sorter: (a: any, b: any) => a.user_name.localeCompare(b.user_name),
      },
      {
        title: 'Created on',
        dataIndex: 'created_on',
        key: 'created_on',
        render: (text: number) => (
          <WizrText type='body2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
        sorter: (a: any, b: any) => a.updated_on - b.updated_on,
      },
  
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, tenantDetails: any) => (
          <TenantActionPopOver tenantDetails={tenantDetails} />
        ),
      },
    ],
    users: [
      {
        title: 'Name',
        dataIndex: 'displayName',
        key: 'displayName',
        render: (text: string) => <WizrAvatar name={text} background='transparent'></WizrAvatar>,
        sorter: (a: any, b: any) => a.displayName.localeCompare(b.displayName),
      },
  
      {
        title: 'Email',
        dataIndex: 'mail',
        key: 'mail',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Mobile',
        width: 130,
        dataIndex: 'mobilePhone',
        key: 'mobilePhone',
        render: (text: boolean) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Role',
        dataIndex: '',
        key: '',
        width: 130,
  
        render: (text: any, userDetails: { [key: string]: any }) => {
          const userRoleKey = Object.keys(userDetails).find((key) => key.includes('UserRole'))
          if (userRoleKey !== undefined) {
            const userRoleValue = userDetails[userRoleKey]
  
            return <WizrText type='body2'>{userRoleValue}</WizrText>
          }
        },
        //sorter: (a: any, b: any) => (a.tags?.length || 0) - (b.tags?.length || 0),
      },
      {
        title: 'Tenant Name',
        //width:180,
        dataIndex: 'tenant_name',
        key: 'tenant_name',
        render: (text: any) => <WizrText type='body2'>{text}</WizrText>,
  
        //sorter: (a: any, b: any) => (a.tags?.length || 0) - (b.tags?.length || 0),
      },
      {
        title: 'Status',
        dataIndex: 'accountEnabled',
        width: 100,
        key: 'accountEnabled',
        render: (text: string) => (
          <WizrTag
            background={text ? '#22C55E' : '#EF4444'}
            data={text ? ['ACTIVE'] : ['DISABLED']}
            borderradius='4px'
          />
        ),
  
        // sorter: (a: any, b: any) => a.user_name.localeCompare(b.user_name),
      },
      {
        title: 'Created on',
        width: 150,
        dataIndex: 'createdDateTime',
        key: 'createdDateTime',
        render: (text: number) => (
          <WizrText type='body2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
            {/* {text} */}
          </WizrText>
        ),
        sorter: (a: any, b: any) => a.createdDateTime - b.createdDateTime,
      },
  
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, userDetails: any) => <UserAction userDetails={userDetails} />,
      },
    ],
    billing: [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (text: number) => (
          <WizrText type='body2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
        sorter: (a: any, b: any) => a.date - b.date,
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        render: (text: string) => <WizrAvatar name={text} background='transparent'></WizrAvatar>,
        sorter: (a: any, b: any) => a.company.localeCompare(b.company),
      },
  
      {
        title: 'App',
        dataIndex: 'app',
        key: 'app',
        // width: 350,
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
        render: (text: boolean) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Input Taken',
        dataIndex: 'input',
        key: 'input',
        render: (text: any) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Output Taken',
        dataIndex: 'output',
        key: 'output',
        render: (text: any) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Requests',
        dataIndex: 'request',
        key: 'request',
        render: (text: any) => <WizrText type='body2'>{text}</WizrText>,
        sorter: (a: any, b: any) => a.request - b.request,
      },
      {
        title: 'Cost',
        dataIndex: 'cost',
        key: 'cost',
        render: (text: any) => <WizrText type='body2'>{text}</WizrText>,
        sorter: (a: any, b: any) => a.cost - b.cost,
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: (text: any) => <WizrText type='body2'>{text}</WizrText>,
        sorter: (a: any, b: any) => a.price - b.price,
      },
    ],
    invoice: [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (text: number) => (
          <WizrText type='body2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
        sorter: (a: any, b: any) => a.date - b.date,
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        render: (text: string) => <WizrAvatar name={text} background='transparent'></WizrAvatar>,
        filters: [
          { text: 'google', value: 'google' },
          { text: 'amazon', value: 'amazon' },
        ],
        onFilter: (value: string, record: any) => {
          return record.company === value
        },
      },
  
      {
        title: 'Invoice',
        dataIndex: 'invoice',
        key: 'invoice',
        width: 350,
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Recepient',
        dataIndex: 'recepient',
        key: 'recepient',
        render: (text: boolean) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text: any) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (text: any) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Paid On',
        dataIndex: 'paid',
        key: 'paid',
        render: (text: number) => (
          <WizrText type='body2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
        sorter: (a: any, b: any) => a.paid.localeCompare(b.paid),
      },
    ],
    products: [
      {
        title: 'Name',
        dataIndex: 'product_name',
        width: 100,
        key: 'product_name',
        render: (text: boolean) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'AI Models',
        dataIndex: 'llm_model_details',
        key: 'llm_model_details',
        render: (text: string[]) => {
          const data = text.map((item: any) => item?.display_name)
  
          return (
            <WizrTag
              type='colorfull'
              checkable={false}
              data={data !== null && data.length > 0 && data[0] !== null ? data : []}
            />
          )
        },
      },
      {
        title: 'Number of calls ',
        dataIndex: 'calls_count',
        key: 'calls_count',
        render: (text: string[]) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Access ',
        dataIndex: 'access_privileges',
        key: 'access_privileges',
        render: (text: string[]) => {
          const resultString = text.join(', ')
  
          return (
            <div style={{ overflow: 'hidden' }}>
              <WizrText type='body2'>{resultString}</WizrText>
            </div>
          )
        },
      },
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, productDetails: any) => (
          <ProductActionPopOver productDetails={productDetails} />
        ),
      },
    ],
    roles: [
      {
        title: 'Role ',
        dataIndex: 'role_name',
        key: 'role_name',
        width: 300,
        render: (text: boolean) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Access ',
        dataIndex: 'access_privileges',
        key: 'access_privileges',
        render: (text: string[]) => {
          const resultString = text.join(', ')
  
          return (
            <div style={{ overflow: 'hidden' }}>
              <WizrText type='body2'>{resultString}</WizrText>
            </div>
          )
        },
      },
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, rolesDetails: any) => <RolesActionPopover rolesDetails={rolesDetails} />,
      },
    ],
    packages: [
      {
        title: 'Name ',
        dataIndex: 'package_name',
        key: 'package_name',
        width: 300,
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: 'Apps ',
        dataIndex: 'app_details',
        key: 'app_details',
        render: (text: string[]) => {
          const appNames = text?.map((item: any) => item?.app_name)
  
          return (
            <div>
              <WizrTag
                type='colorfull'
                checkable={false}
                data={
                  appNames && appNames !== null && appNames.length > 0 && appNames[0] !== null
                    ? appNames
                    : []
                }
              />{' '}
            </div>
          )
        },
      },
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, packageDetails: any) => (
          <PackageActionPopOver packageDetails={packageDetails} />
        ),
      },
    ],
    integrations: [
      {
        title: <WizrText type='sub2'>Name</WizrText>,
        dataIndex: 'display_name',
        key: 'display_name',
        width: 300,
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: <WizrText type='sub2'>Tool</WizrText>,
        dataIndex: 'integration_display_name',
        key: 'integration_display_name',
        render: (text: string) =>
          <WizrFlexLayout flexDirection='row' justifyContent='flex-start' alignItems='center ' style={{ gap: '10px' }} background='white'>
            <ZendeskIcon />
            <WizrText type='body2'>{text}</WizrText>
          </WizrFlexLayout>
        ,
      },
      {
        title: <WizrText type='sub2'>Email</WizrText>,
        dataIndex: 'registered_emailid',
        key: 'registered_emailid',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: <WizrText type='sub2'>Integrated On</WizrText>,
        dataIndex: 'updated_on',
        key: 'updated_on',
        render: (text: any) => (
          <WizrText type='caption2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
      },
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, integrationDetails: any) => (
          <IntegrationActionPopOver integrationDetails={integrationDetails} />
        ),
      },
    ],
    integrationsSettings: [
      {
        title: <WizrText type='sub2'>Name</WizrText>,
        dataIndex: 'display_name',
        key: 'display_name',
        width: 300,
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>
      },
      {
        title: <WizrText type='sub2'>Tool</WizrText>,
        dataIndex: 'integration_display_name',
        key: 'integration_display_name',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: <WizrText type='sub2'>Email</WizrText>,
        dataIndex: 'registered_emailid',
        key: 'registered_emailid',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: <WizrText type='sub2'>Integrated On</WizrText>,
        dataIndex: 'updated_on',
        key: 'updated_on',
        render: (text: any) => (
          <WizrText type='caption2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
      },
    ],
    grouplisting: [
      {
        title: <WizrText type='sub2'>Name</WizrText>,
        render: (folderDetails: any) => (
          <WizrFlexLayout
            flexDirection='row'
            justifyContent='flex-start'
            alignContent='flex-start'
            background='transparent'
            flex='none'
          >
            <FolderIcon />
            <WizrText type='body2' style={{ padding: '3px' }}>{folderDetails?.folder_name}</WizrText>
          </WizrFlexLayout>
          // <WizrButton
          //   padding={'0px'}
          //   icon={<FolderIcon />}
          //   text={folderDetails?.folder_name}
          //   type='text'
          //   disabled={true}
          //   onClick={() => {
          //     routerHistory.navigate(`/featuregroup/${folderDetails?.group}/${folderDetails?.folder_id}`, {
          //       state: { files: folderDetails },
          //     })
          //   }}
          // />
        ),
      },
      {
        title: <WizrText type='sub2'>Created On</WizrText>,
        dataIndex: 'created_on',
        width: 190,
        // render: (folderDetails: any) => (
        //   <WizrButton
        //     padding={'0px'}
        //     text={folderDetails?.folder_name}
        //     type='text'
        //     onClick={() => {
        //       routerHistory.navigate(`/featuregroup/${folderDetails?.group}/${folderDetails?.folder_id}`, {
        //         state: { files: folderDetails },
        //       })
        //     }}
        //   />
        // ),
  
        render: (text: any) => (
          <WizrText type='caption2' >
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
  
          </WizrText>
        ),
        sorter: (a: any, b: any) => a.created_on - b.created_on,
      },
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, groupDetails: any) => (
          <GroupActionPopover groupDetails={groupDetails} />
        ),
      },
    ],
    intentcategorytable: [
      {
        title: <WizrText type='sub2'>Category</WizrText>,
        dataIndex: 'name',
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: <WizrText type='sub2'>Intent</WizrText>,
        dataIndex: 'intent',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
        sorter: (a: any, b: any) => a.intent.localeCompare(b.intent),
  
      },
      {
        title: <WizrText type='sub2'>Description</WizrText>,
        dataIndex: 'description',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
        sorter: (a: any, b: any) => a.description.localeCompare(b.description),
  
      },
      {
        title: <WizrText type='sub2'>Date of create</WizrText>,
        dataIndex: 'created_on',
        render: (text: any) => (
          <WizrText type='caption2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
        sorter: (a: any, b: any) => a.created_on - b.created_on
  
      },
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, details: any) => (
          <TicketActionPopover details={details} />),
      },
    ],
    tickettypetable: [
      {
        title: <WizrText type='sub2'>Ticket Type</WizrText>,
        dataIndex: 'name',
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
  
      {
        title: <WizrText type='sub2'>Description</WizrText>,
        dataIndex: 'description',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
        sorter: (a: any, b: any) => a.description.localeCompare(b.description),
  
      },
      {
        title: <WizrText type='sub2'>Date of create</WizrText>,
        dataIndex: 'created_on',
        render: (text: any) => (
          <WizrText type='caption2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
        sorter: (a: any, b: any) => a.created_on - b.created_on,
  
      },
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, details: any) => (
          <TicketActionPopover details={details} />),
      },
    ],
    sentimenttable: [
      {
        title: <WizrText type='sub2'>Sentiment</WizrText>,
        dataIndex: 'name',
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
  
      {
        title: <WizrText type='sub2'>Description</WizrText>,
        dataIndex: 'description',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
        sorter: (a: any, b: any) => a.description.localeCompare(b.description),
  
      },
      {
        title: <WizrText type='sub2'>Date of create</WizrText>,
        dataIndex: 'created_on',
        render: (text: any) => (
          <WizrText type='caption2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
        sorter: (a: any, b: any) => a.created_on - b.created_on,
  
      },
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, details: any) => (
          <TicketActionPopover details={details} />),
      },
    ],
    tickettable: [
      {
        title: <WizrText type='sub2'>Values</WizrText>,
        dataIndex: 'name',
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
  
      {
        title: <WizrText type='sub2'>Description</WizrText>,
        dataIndex: 'description',
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
        sorter: (a: any, b: any) => a.description.localeCompare(b.description),
  
      },
      {
        title: <WizrText type='sub2'>Date of create</WizrText>,
        dataIndex: 'created_on',
        render: (text: any) => (
          <WizrText type='caption2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
        sorter: (a: any, b: any) => a.created_on - b.created_on,
  
      },
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, details: any) => (
          <TicketActionPopover details={details} />),
      },
    ],
    tagkeywordtable: [
      {
        title: <WizrText type='sub2'>Tag Name</WizrText>,
        dataIndex: 'name',
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: <WizrText type='sub2'>Date of create</WizrText>,
        dataIndex: 'created_on',
        render: (text: any) => (
          <WizrText type='caption2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
        sorter: (a: any, b: any) => a.created_on - b.created_on,
  
      },
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, details: any) => (
          <TicketActionPopover details={details} />),
      },
    ],
    persona: [
      {
        title: <WizrText type='sub2'>Name</WizrText>,
        dataIndex: 'name',
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: <WizrText type='sub2'>Description</WizrText>,
        dataIndex: 'description',
        sorter: (a: any, b: any) => a.description.localeCompare(b.description),
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: <WizrText type='sub2'>Language</WizrText>,
        dataIndex: 'language',
        sorter: (a: any, b: any) => a.language.localeCompare(b.language),
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: <WizrText type='sub2'>Tone</WizrText>,
        dataIndex: 'tone',
        sorter: (a: any, b: any) => a.tone.localeCompare(b.tone),
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: <WizrText type='sub2'>Length</WizrText>,
        dataIndex: 'length',
        sorter: (a: any, b: any) => a.length - b.length,
        render: (text: string) => <WizrText type='body2'>{text}</WizrText>,
      },
      {
        title: <WizrText type='sub2'>Date of create</WizrText>,
        dataIndex: 'created_on',
        render: (text: any) => (
          <WizrText type='caption2'>
            {new Date(text).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </WizrText>
        ),
        sorter: (a: any, b: any) => a.created_on - b.created_on,
  
      },
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, persona: any) => (
          <PersonaActionPopOver persona={persona} />
        ),
      },
    ],
    customerHealth: [
      {
        title: <WizrText type='sub2'>Customer Name</WizrText>,
        dataIndex: '',
        sorter: (a: any, b: any) => a.customer.localeCompare(b.customer),
        render: (text: any, details: any) =>
          < CustomerHealthCustomerCard details={details} />,
      },
      {
        title: <WizrText type='sub2'>Overall Health</WizrText>,
        dataIndex: 'health',
        sorter: (a: any, b: any) => a.health.localeCompare(b.description),
        render: (text: string) => <CustomerHealthRoundTag text={text} type='sentiment' />,
      },
      {
        title: <WizrText type='sub2'>Sentiment</WizrText>,
        dataIndex: 'sentiment',
        sorter: (a: any, b: any) => a.sentiment.localeCompare(b.sentiment),
        render: (text: string) => <CustomerHealthRoundTag text={text} type='sentiment' />,
      },
      {
        title: <WizrText type='sub2'>Escalations</WizrText>,
        dataIndex: 'escalation',
        sorter: (a: any, b: any) => a.escalation.localeCompare(b.escalation),
        render: (text: string) => <CustomerHealthRoundTag text={text} type='escalation' />,
      },
      {
        title: <WizrText type='sub2'>Time to resolve</WizrText>,
        dataIndex: 'time_for_resolve',
        sorter: (a: any, b: any) => a.time_for_resolve.localeCompare(b.time_for_resolve),
        render: (text: string) => <CustomerHealthRoundTag text={text} type='resolution' />,
      },
    ],
    appInsights: [
      {
        title: <WizrText type='sub2'>App Name</WizrText>,
        dataIndex: 'app_name',
        render: (text: string) => (
          <WizrFlexLayout justifyContent='flex-start' alignContent='center' alignItems='center' flexDirection='row' background='white' style={{gap: '8px' }}>
            <WizrIcon name='sparkels' stroke="#1D1F22" />
            <WizrText type='body2'>{text}</WizrText>
          </WizrFlexLayout>
        ),
        filters: filerArrayAppname,
        filterSearch: true,
        onFilter: (value: any, record: any) => record.app_name.includes(value as string)
      },
      {
        title: <WizrText type='sub2'>App Id</WizrText>,
        dataIndex: 'app_id',
        // sorter: (a: any, b: any) => a.health.localeCompare(b.description),
        // render: (text: string) => (
        //   <div style={{ maxHeight: '100px', overflow: 'auto', maxWidth: '600px' }}>
        //     <WizrText type='body2'>{text}</WizrText>
        //   </div>
        //   ),
        filters: filerArrayAppId,
        filterSearch: true,
        onFilter: (value: any, record: any) => record.app_id.includes(value as string)
      },
      {
        title: <WizrText type='sub2'>Total Execution Count</WizrText>,
        dataIndex: 'executions',
        // sorter: (a: any, b: any) => a.sentiment.localeCompare(b.sentiment),
        // render: (text: string) => (
        //   <div style={{ maxHeight: '100px', overflow: 'auto', maxWidth: '600px' }}>
        //     <WizrText type='body2'>{text}</WizrText>
        //   </div>
        //   ), 
        // filters: filterValues,
        // filterSearch: true, 
      },
      {
        title: <WizrText type='sub2'>Average Time</WizrText>,
        dataIndex: 'total_execution_time',
        // sorter: (a: any, b: any) => a.escalation.localeCompare(b.escalation),
        // render: (text: string) => (
        //   <div style={{ maxHeight: '100px', overflow: 'auto', maxWidth: '600px' }}>
        //     <WizrText type='body2'>{text}</WizrText>
        //   </div>
        //   ), 
        // filters: filterValues,
        // filterSearch: true,
      },
      {
        title: <WizrText type='sub2'>Error Rate</WizrText>,
        dataIndex: 'error_rate',
        // sorter: (a: any, b: any) => a.time_for_resolve.localeCompare(b.time_for_resolve),
        render: (text: string) => (
          <div style={{ maxHeight: '100px', overflow: 'auto', maxWidth: '600px' }}>
            <WizrText type='body2'>{parseFloat(text).toFixed(2)}</WizrText>
          </div>
          ),
        // filters: filterValues,
        // filterSearch: true,
      },
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, appDetail: any) => <AppActionPopOver detail={appDetail} />,
      },
  
    ],
    appInsightsSingle: [
      {
        title: <WizrText type='sub2'>Date</WizrText>,
        dataIndex: 'data',
        // render: (text: string) => (
        //   <WizrFlexLayout justifyContent='flex-start' alignContent='center' alignItems='center' flexDirection='row' background='white' style={{gap: '8px' }}>
        //     <WizrIcon name='sparkels' stroke="#1D1F22" />
        //     <WizrText type='body2'>{text}</WizrText>
        //   </WizrFlexLayout>
        // ),
        filters: filerArrayAppname,
        filterSearch: true,
        // onFilter: (value: any, record: any) => record.app_name.includes(value as string)
      },
      {
        title: <WizrText type='sub2'>Version</WizrText>,
        dataIndex: 'version',
        // sorter: (a: any, b: any) => a.health.localeCompare(b.description),
        // render: (text: string) => (
        //   <div style={{ maxHeight: '100px', overflow: 'auto', maxWidth: '600px' }}>
        //     <WizrText type='body2'>{text}</WizrText>
        //   </div>
        //   ),
        filters: filerArrayAppId,
        // filterSearch: true,
        // onFilter: (value: any, record: any) => record.app_id.includes(value as string)
      },
      {
        title: <WizrText type='sub2'>Total Execution Count</WizrText>,
        dataIndex: 'executions',
        // sorter: (a: any, b: any) => a.sentiment.localeCompare(b.sentiment),
        // render: (text: string) => (
        //   <div style={{ maxHeight: '100px', overflow: 'auto', maxWidth: '600px' }}>
        //     <WizrText type='body2'>{text}</WizrText>
        //   </div>
        //   ), 
        filters: filterValues,
        // filterSearch: true, 
      },
      {
        title: <WizrText type='sub2'>Average Time</WizrText>,
        dataIndex: 'total_execution_time',
        // sorter: (a: any, b: any) => a.escalation.localeCompare(b.escalation),
        // render: (text: string) => (
        //   <div style={{ maxHeight: '100px', overflow: 'auto', maxWidth: '600px' }}>
        //     <WizrText type='body2'>{text}</WizrText>
        //   </div>
        //   ), 
        filters: filterValues,
        // filterSearch: true,
      },
      {
        title: <WizrText type='sub2'>Error Rate</WizrText>,
        dataIndex: 'error_rate',
        // sorter: (a: any, b: any) => a.time_for_resolve.localeCompare(b.time_for_resolve),
        // render: (text: string) => (
        //   <div style={{ maxHeight: '100px', overflow: 'auto', maxWidth: '600px' }}>
        //     <WizrText type='body2'>{parseFloat(text).toFixed(2)}</WizrText>
        //   </div>
        //   ),
        filters: filterValues,
        // filterSearch: true,
      },
      {
        title: '',
        dataIndex: '',
        width: 90,
        render: (text: any, appDetail: any) => <AppActionPopOver detail={appDetail} />,
      },
  
    ]
  
  }



  const columnConfig = tableColumnConfigs[type]

  const groupliastingNavigation = (e: any, record: any) => {
    e.stopPropagation();
    const target = e.target;
    const columnIndex = Array.from(target.parentNode.children).indexOf(target);
    const columnName = columnConfig[columnIndex]?.dataIndex;
    if (columnName) {
      routerHistory.navigate(`/featuregroup/${record?.group}/${record?.folder_id}`, {
        state: { files: record },
      })
    }
  }

  const integrationNavigation = (record: any) => {
    localStorage.setItem('redirectCxSetttings', "true")

    routerHistory.navigate('/settings', { state: { action: "settingsedit", data: record } })
    dispatchupdateUserView({
      activeKey: 'integrations',
    })
    dispatchCurrentModule('settings')
  }

  const customerHealthNavigation = (record: any) => {
    dispatchCustomerHealthDetails(record)
  }
  



  return <Table columns={columnConfig} {...props} style={{ background: '#F2F4F9' }}
    onRow={(record, rowIndex) => {
      return {
        onClick: event => {
          type === 'grouplisting' ? groupliastingNavigation(event, record) : type === 'integrationsSettings' ? integrationNavigation(record) : type === 'customerHealth' ? customerHealthNavigation(record) : '';
        },
      };
    }} rowClassName={() => "rowClassName1"}></Table>
}

export default WizrTable
