// import { useState } from 'react'
// import Markdown from 'react-markdown'

// const WizrMarkDown = ({ children }: any) => {
//     const [showPreview,setImagePreview] = useState(false)
//     const handleImageClick = (src: any) => {
//         console.log('hellooo')

//     }
//   return (
//     <div id='markDownContainer'>
//       <Markdown
//         components={{
//           a: ({ href, children }) => (
//             <a href={href} target='_blank' rel='noopener noreferrer'>
//               {children}
//             </a>
//           ),
//           img: ({ src, alt, title }) => (
//             <img
//               src={src}
//               alt={alt}
//               title={title}
//               style={{cursor: 'pointer' }}
//               onClick={() => handleImageClick(src)}
//             />
//           ),
//         }}
//       >
//         {children}
//       </Markdown>
//       {showPreview && <div id='imagePreview'></div>}
//     </div>
//   )
// }

// export default WizrMarkDown

import React, { useState } from "react";
import Markdown from "react-markdown";
import WizrDropDown from "./WizrDropDown";
import CloseIcon from "../assets/icons/Close";
import CloseIconOutlined from "../assets/icons/CloseIconOutlined";

const WizrMarkDown = ({ children }: any) => {
    const [modal, setModal] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
  
    const toggleModal = (src:any) => {
      setPreviewImage(src);
      setModal(!modal);
    };
  
    if (modal) {
      document.body.classList.add("active-modal");
    } else {
      document.body.classList.remove("active-modal");
    }
  
    return (
      <div id="markDownContainer">
        <Markdown
          components={{
            a: ({ href, children }) => (
              <a href={href} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            ),
            img: ({ src, alt, title }) => (
              <img
                src={src}
                alt={alt}
                title={title}
                style={{ cursor: "pointer" }}
                onClick={() => toggleModal(src)}
              />
            ),
          }}
        >
          {children}
        </Markdown>
  
        {modal && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "rgba(49,49,49,0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999, // Ensure the modal is on top of everything
          }}
        >
          {/* Close button in the faded area */}
          <button
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              padding: "10px 15px",
              cursor: "pointer",
              backgroundColor: "transparent", 
              border: "none",
              borderRadius: "5px",
              zIndex: 10000, // Ensure the close button is on top of the faded background
            }}
            onClick={toggleModal}
          >
            <CloseIconOutlined color={'white'} size={'35'}/>
          </button>

          {/* Modal content */}
          <div
            style={{
              position: "relative",
              backgroundColor: "#f1f1f1",
              padding: "20px",
              borderRadius: "10px",
              maxWidth: "90%",
              maxHeight: "90%",
              zIndex: 10000, // Ensure the content inside the modal is on top of the overlay
            }}
          >
            <img
              src={previewImage}
              alt="Preview"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </div>
        </div>
      )}
    </div>
    );
  }

export default WizrMarkDown;
