import { ArrowLeftOutlined } from "@ant-design/icons"
import WizrButton from "../../../components/WizrButton"
import WizrFlexLayout from "../../../components/WizrFlexLayout"
import WizrText from "../../../components/WizrText"
import { useLocation, useNavigate } from "react-router-dom"
import HistoryIcon from "../../../assets/icons/History"
import Copy from "../../../assets/icons/Copy"
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { useEffect, useRef, useState } from "react"
import { Spin, message } from "antd"
import styled from "styled-components"
import WizrUserJourney from "../../../components/WizrUserJourney"
import Hamburger from "../../../assets/icons/Hamburger"
import Editor from '../../../components/ckEditor'
import { dispatchAppToolTest, dispatchClearChat, dispatchFeatureSettings, dispatchGetFeatureAppDetails } from "../../../redux/actions/content"
import WizrTextArea from "../../../components/WizrTextArea"
import { useAppDesignDataSelector, useChatMessageSelector, useContentLoadingSelector, useCurrentAppDetailsSelector, useFeatureSettingSelector, useTestStatusSelector } from "../../../redux/selectors/content"


const StyledHeaderWizrButton = styled(WizrButton)`
border-radius: 20px;
background: var(--grayscale-200, #EAECF1);`
const StyledTextButton = styled(WizrText)`
  text-decoration: underline !important;
`
const StyledWizrButton = styled(WizrButton)`
border-radius: 20px;
border: 1px solid var(--grayscale-800, #1D1F22);
background: var(--generic-white, #FFF);
margin-right:10px
`

const FeatureTest = () => {
    const location = useLocation();
    const appdata = useCurrentAppDetailsSelector();
    const currentFeature = location?.state?.details
    useEffect(() => {
      if (currentFeature?.app_id || currentFeature?.app_id!==''){
        dispatchGetFeatureAppDetails(currentFeature?.app_id)}
        dispatchFeatureSettings()
        dispatchClearChat()
    }, []);
    const featureSettings = useFeatureSettingSelector()
    const chat = useChatMessageSelector()
    const loading = useTestStatusSelector()
    const navigate =  useNavigate();    
    const contentRef = useRef<HTMLDivElement>(null);
    const [data, setData] = useState('');
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [ticketContent,setTicketContent] = useState('');
    useEffect(() => {
        setEditorLoaded(true);
      }, []);
      useEffect(() => {
 const result = chat && chat[0] && chat[0]?.text
 if (result){
 setData(result)}
      }, [chat]);

    const copyContentToClipboard = () => {
    
        const range = document.createRange();
      range.selectNodeContents(contentRef.current!); // Use the non-null assertion operator (!) or add a null check
    
      const selection = window.getSelection();
    
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
    
        document.execCommand('copy');
        selection.removeAllRanges();
      }
      message.success('Copied to clipboard', 2);
      };
 
      const onChatClick = async() => {   
        dispatchClearChat()
        setData('')
        const parameters = appdata?.nodes && appdata?.nodes[0]?.data?.config?.parameters
        const parametersName = (parameters).map((item: any) => item?.name);
        const parameterObject = parametersName.reduce((result: any, parameter: any) => {
            result[`{{${parameter}}}`] = parameter==='support_ticket_content' ? ticketContent :'';
            return result;
          }, {});

          const payload ={
            app_id: currentFeature?.app_id,
            app_feature_id: currentFeature?.id,
            tenant_feature_id: featureSettings?.id,
            history: [],
            exec_type: "test",
            max_past_messages: 0,
            message_params: parameterObject
        }

        
    await dispatchAppToolTest(payload)
          }
       const   onReset  = () => {
        setTicketContent('')
       }

    return(
        <WizrFlexLayout style={{ padding: "10px 0px" }}>
      <WizrFlexLayout flex={1.5} flexDirection="row" alignItems="flex-start" >

        <WizrFlexLayout flex={1.5} alignItems="flex-start" flexDirection="row">
        <WizrUserJourney
          userJourney={[
            {
              title: (
                <WizrButton
                  padding='0px'
                  type='text'
                  text={<WizrText type='h6' textColor='#707276'>Cx Settings</WizrText>}
                  onClick={() => navigate(-1)}
                icon={<Hamburger  />}
                />
              ),
            },
            {
              title:  (
                <WizrButton
                  padding='0px'
                  type='text'
                  text={<WizrText type='h6'>{currentFeature?.setting_name}</WizrText>}
                  onClick={() => navigate(-1)}
                />
              ),
              isCurrent: true,
            },
          ]}
        />  
          
          
        </WizrFlexLayout>

        <WizrFlexLayout flexDirection="row" flex={4} alignItems="flex-start" >

          <WizrFlexLayout alignItems="flex-end" flexDirection="row" justifyContent="flex-end" style={{alignItems:'center'}}>
          <StyledHeaderWizrButton type="link" icon={<HistoryIcon />} text={<WizrText type="button" textColor="#1D1F22" >history</WizrText>} onClick={() => {  }}></StyledHeaderWizrButton>
         
          </WizrFlexLayout>
        </WizrFlexLayout>


      </WizrFlexLayout>
      <WizrFlexLayout flex={9} flexDirection="row" style={{ paddingTop: "0" }}>

        <WizrFlexLayout style={{ padding: " 0 10px", maxWidth: "350px" }} justifyContent="flex-start">
<WizrFlexLayout background="white"  style={{borderRadius:'16px',gap:'10px',padding:'20px'}} textAlign="left" justifyContent="flex-start">
    <WizrText type="body2">Ticket</WizrText>
    <WizrTextArea background='#F2F4F9' border= '0px #F2F4F9' minRows={16} value={ticketContent} onChange={(value:any)=>{setTicketContent(value)}} />
    <WizrFlexLayout  flexDirection="row" background="white" justifyContent="flex-end" style={{marginTop:"10px"}}>
          <StyledWizrButton
            type="link" text={<WizrText type="button" textColor="#1D1F22">Reset</WizrText>} onClick={() => {onReset()}}></StyledWizrButton>
           {!loading? <WizrButton type="primary" style={{borderRadius:"16px"}}text={<WizrText type="button" textColor="#FFF">Run</WizrText>} onClick={() => { onChatClick() }}></WizrButton>:
           <WizrButton type="primary" style={{borderRadius:"16px"}}text={<Spin/>} disabled={true}></WizrButton>}
        </WizrFlexLayout>
</WizrFlexLayout>
          
          {/* <SingleRunContainerLeft mapValueToUserQuery={mapValueToUserQuery} parameters={parameterList} doUpdateFields={doUpdateFields} saveFileParams={setFileParams} saveFilesData={setFilesData} onChatClick={onChatClick} currentApp={currentApp} appDetails={appDetails}/> */}
        </WizrFlexLayout>

        <WizrFlexLayout style={{ padding: " 0 10px" }}>

          <WizrFlexLayout style={{  padding: '-20px' }} background="white">
            {/* <StyledWizrEditor > */}
            <WizrFlexLayout>
            <div ref={contentRef}>

              <Editor
                name="description"
                onChange={(data: any) => {
                    setData(data)
                }}
                value={data}
                editorLoaded={editorLoaded}
              />
              </div>
              
            </WizrFlexLayout>
          


          </WizrFlexLayout>

        </WizrFlexLayout>


      </WizrFlexLayout>

    </WizrFlexLayout>
   )
}
export default FeatureTest;
