import { useEffect, useState } from 'react'
import Chat from '../../../../components/WizrChat'
import { useNavigate } from 'react-router-dom'
import WizrDropDown from '../../../../components/WizrDropDown'
import WizrFlexLayout from '../../../../components/WizrFlexLayout'
import WizrInput from '../../../../components/WizrInput'
import WizrTagInput from '../../../../components/WizrTagInput'
import WizrText from '../../../../components/WizrText'
import WizrTextArea from '../../../../components/WizrTextArea'
import { diaptchUpdateFileUploadStatus, dispatchAppToolTest, dispatchChatMessage, dispatchClearChat, dispatchTestWidget, dispatchUploadFileUsingLink } from '../../../../redux/actions/content'
import { useChatMessageSelector, useContentLoadingSelector, useCurrentAppDetailsSelector, useFileParams, useGetWidgetDetails } from '../../../../redux/selectors/content'
import { styled } from 'styled-components'
import axios from 'axios'
import WizrButton from '../../../../components/WizrButton'
import CloseIcon from '../../../../assets/icons/Close'
import WizrUploadContainer from '../../../../components/WizrUploadButton'
import { Button, Input, Space } from 'antd'
import { WizrAzurOrchestratorInstance } from '../../../../utils/wizrAzureConnector'
import { getFileType } from '../../../../utils'

interface ITestArea {
    onTestAreaClose?: any
    parameters?: any
}

const ChatSettings = styled(WizrFlexLayout)`
  border-radius: 6px;
  margin-bottom: 1px;
  gap: 5px;
  padding-top: 0px;
  min-height: 20%;
  overflow: auto;
  padding: 0px 20px;
`
const StyledWizrDropDown = styled(WizrDropDown)`
  width: 100%;
`
const StyledWizrButton = styled(WizrButton)`
margin-left:610px;
margin-bottom:7px;`

const IndividualSettingsBox = styled.div`
  width: 100%;
  text-align: left;
  margin: 10px 0px 0px 0px;
  overflow: auto;
`

const TestArea = ({ onTestAreaClose, parameters }: ITestArea): JSX.Element => {


    const widgetDetails = useGetWidgetDetails()    
    const chatMessage = useChatMessageSelector()
    const currentAppDetails = useCurrentAppDetailsSelector()
    // const parameters = ( currentAppDetails?.nodes && currentAppDetails?.nodes[0]?.data?.config?.parameters || []).map((item: any) => item?.name);

    // const testParams = currentAppDetails?.nodes && currentAppDetails?.nodes[0]?.data?.config?.parameters.filter((item: any) => item.name !== 'user_query')
    const testParams = currentAppDetails?.nodes && currentAppDetails?.nodes[0]?.data?.config?.parameters
    const [runtype, setRuntype] = useState("single")

    // const [messageParams, setMessageParams] = useState<any>()
    const [inputValue, setInputValue] = useState<any>({})
    
    // const [fileParams, setFfileParams] = useState<any>([])
    // const [filesData, setFilesData] = useState<any>([])
    // const [historyChats, setHistoryChats] = useState<{ user: string; assistant: string }[]>([])
    // const navigate = useNavigate()

    // const uploadedFileValues = useFileParams()

    useEffect(() => {
        // const user = chatMessage?.filter((item: any) => item.isSent).map((item: any) => item.text)
        // const assistant = chatMessage?.filter((item: any) => !item.isSent).map((item: any) => item.text)
        // if (user?.length === assistant?.length) {
        //     const result = user.map((value: any, index: any) =>
        //         Object.assign({}, { user: value, assistant: assistant?.[index] }),
        //     )
        //     setHistoryChats(result)
        // }
    }, [chatMessage])

    useEffect(() => {
        // const parameterList = testParams?.map((item: any) => item?.name)
        // const parameterObject = parameterList?.reduce((result: any, parameter: any) => {
        //     result[`{{${parameter}}}`] = "";
        //     return result;
        // }, {});
        // setMessageParams(parameterObject)
    }, [testParams])


    useEffect(() => {
        dispatchClearChat()
    }, [])

    const mapValueToUserQuery = (value: string) => {
        updateMessageParams('user_query', value)
    }

    const updateMessageParams = (name: string, value: any) => {
        // const names = `{{${name}}}`
        // const finalValue = value?.length > 0 ? value.toString() : value
        // setMessageParams({ ...messageParams, [names]: finalValue })
    }

    const onChatSubmit = () => {

        const modifiedObject: any = {};

        for (const key in inputValue) {
            if (Object.prototype.hasOwnProperty.call(inputValue, key)) {
              const newKey = key.toLowerCase().replace(/ /g, '_'); // Use regex to replace all spaces
              modifiedObject[newKey] = inputValue[key];
            }
          }          
        
        const payload = {
            "widget_id": widgetDetails.id,
            "params": modifiedObject
        }

        dispatchTestWidget(payload)
        dispatchChatMessage({ text:'Generate', isSent: true })
    }

    const doUpdateFields = (fieldName: any, fieldValue: any) => {
        const fieldNameConverted = fieldName?.toLowerCase().replace(' ','_')        
        setInputValue({
          ...inputValue,
          [fieldName]: fieldValue,
        })
      }

    //   console.log('chatMessage', chatMessage);
    const loading = useContentLoadingSelector()    
      

    return (
        <IndividualSettingsBox>
            <WizrFlexLayout alignItems='center' background='#fff' style={{ padding: '0px 10px' }}>
                <Chat
                    onChatClick={onChatSubmit}
                    chatMessage={chatMessage}
                    enableChat={true}
                    mapValueToUserQuery={mapValueToUserQuery}
                    runtype={runtype}
                    setRuntype={setRuntype}
                    appDetails={currentAppDetails}
                    apptest={true}
                    page='autosolve'
                    onTestAreaClose={onTestAreaClose}
                />
            </WizrFlexLayout>
            {parameters?.map((item: any, index: any) => {
                return (
                    <WizrFlexLayout
                        flexDirection='row'
                        justifyContent='flex-start'
                        background='#fff'
                        alignItems='flex-start'
                        style={{ gap: '10px', margin: '5px 0px' }}
                        key={item.id}
                    >
                        <WizrFlexLayout background='#FFF' textAlign='left' justifyContent='left' alignContent='left'>
                            <WizrText type='sub2'>{item.key}</WizrText>
                            <WizrInput
                                value={inputValue[item.key]}
                                onChange={(value: any) => doUpdateFields(`${item.key}`, value)}
                            ></WizrInput>
                        </WizrFlexLayout>
                        {/* <WizrFlexLayout background='#FFF' textAlign='left' justifyContent='left' alignContent='left'>
                            <WizrText type='sub2'>{item.value}</WizrText>
                            <WizrInput
                                value={inputValue[item.value]}
                                onChange={(value: any) => doUpdateFields(`${item.value}`, value)}
                            ></WizrInput>
                        </WizrFlexLayout> */}

                    </WizrFlexLayout>)
            })}
            <WizrFlexLayout flexDirection='row' justifyContent='end' background='#FFF' style={{ padding: '16px' }}>
                <WizrButton type='text' text='reset' onClick={() => { setInputValue({})}} style={{ borderRadius: '12px' }} />
                <WizrButton text={'Run'} onClick={() => { onChatSubmit() }} style={{ borderRadius: '12px' }} disabled={loading}/>
            </WizrFlexLayout>
        </IndividualSettingsBox>
    )
}



export default TestArea