const ExportIcon = (props: any) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} fill='none' {...props}>
    <path stroke='#1D1F22' strokeLinecap='round' strokeWidth={1.5} d='M1 9a8 8 0 0 0 16 0' />
    <path
      stroke='#1D1F22'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M9 11V1m0 0 3 3M9 1 6 4'
    />
  </svg>
)
export default ExportIcon
