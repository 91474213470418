import { Bar, BarChart, Cell, Label, Pie, PieChart, ResponsiveContainer, XAxis, YAxis, Text } from "recharts";
import WizrFlexLayout from "../../../components/WizrFlexLayout";
import WizrText from "../../../components/WizrText";
import WizrIcon from "../../../components/WizrIcon";
import styled from "styled-components";
import { Card } from "antd";
import { useFirstContactResolution, useTicketEscallation, useTimetoResolve, useTimetoResponse, useTopDecreasedIntentsSelector, useTopIncreasedIntentsSelector, useTopIntentsSelector, useTotalIntentsSelector } from "../../../redux/selectors/content";
import { useMemo, useState } from "react";
import WizrButton from "../../../components/WizrButton";


const CustomerIntents = ({ onClickIntentsandsentiments, range, interval }: any) => {

  // api responses for the cards
  const topIntents = useTopIntentsSelector();
  const topIncresedIntents = useTopIncreasedIntentsSelector();
  const topDecreasedIntents = useTopDecreasedIntentsSelector();
  const totalIntents = useTotalIntentsSelector();

    // fn to formate the api response
    const extractData = (result: any) => {
      const rows = result?.resultTable?.rows || [];
      const columns = result?.resultTable?.dataSchema?.columnNames || [];
      return rows.map((row: any) => {
        const obj: any = {};
        columns.forEach((columnName: any, index: any) => {
          obj[columnName] = row[index];
        });
        return obj;
      });
    };

  // update the api response to the format we need
  const topIntentsArray = useMemo(() => extractData(topIntents), [topIntents]);
  const topIncreasedIntentsArray = useMemo(() => extractData(topIncresedIntents), [topIncresedIntents]);
  const topDecreasedIntentsArray = useMemo(() => extractData(topDecreasedIntents), [topDecreasedIntents]);
  const totalIntentsArray = useMemo(() => extractData(totalIntents), [totalIntents]);



  // to show the view all btns for each cards
  const [show, setShow] = useState(false);
  const [showIncrease, setShowIncrease] = useState(false);
  const [showDecrease, setShowDecrease] = useState(false);

  return (
    <WizrFlexLayout flexDirection='row' style={{ gap: '16px', minHeight: '306px', maxHeight: '306px' }}>
      <StyledTicketCard
        title={<WizrFlexLayout
          background='#FFFFFF'
          flexDirection='row'
          style={{ maxHeight: '52px' }}
        >
          <WizrText type='sub2' >Top Intents</WizrText>
          <WizrText type='sub2' >Sentiments</WizrText>
        </WizrFlexLayout>}
        bordered={false}
        style={{ flexGrow: 1 }}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        hoverable>
        {
          topIntentsArray?.slice(0, 3).map((value: any, key: any) => {
            return (
              <WizrFlexLayout
                background='#FFFFFF'
                flexDirection='row'
                key={key}
                onClick={() => onClickIntentsandsentiments(value)}
                style={{ minHeight: '44px', padding: '0px 0px 24px 0px' }}
              >
                <WizrFlexLayout flexDirection='column' background='#FFFFFF' alignItems='flex-start' justifyContent='flex-start' style={{ textAlign: 'start', flex: '3' }}>
                  <WizrText type='body2' >{value.Intent}</WizrText>
                  <WizrFlexLayout flexDirection='row' background='#FFFFFF' alignItems='flex-start' justifyContent='flex-start' style={{ gap: '5px' }}>
                    <WizrText type='sub2'>{value.CurrentMonthCount}</WizrText>
                    <WizrIcon name='trendUpRed' />
                    <WizrText type='sub2' textColor='#EF4444'>{value?.IncreasePercentage?.toFixed(1)}%</WizrText>
                  </WizrFlexLayout>
                </WizrFlexLayout>
                <WizrFlexLayout background='#FFFFFF' alignItems='flex-end'>
                  <WizrText type='sub2' textColor={value?.OverallSentimentScore?.toFixed(1) > 55 ? "#22C55E" : value?.OverallSentimentScore?.toFixed(1) < 55 && value?.OverallSentimentScore?.toFixed(1) > 45 ? "#FBBF24" : "#EF4444"} style={{ fontSize: '26px', marginTop: '8px' }} >{value?.OverallSentimentScore?.toFixed(1)}</WizrText>
                </WizrFlexLayout>
              </WizrFlexLayout>
            )
          })
        }
        {show ? <WizrFlexLayout background="#FFF" textAlign="end" alignItems="end">
          <WizrButton type='text' color="#999B9F" text='View All' onClick={() => { onClickIntentsandsentiments('') }} style={{ borderRadius: '12px' }} />
        </WizrFlexLayout> : <></>}
      </StyledTicketCard>
      <StyledTicketCard
        title={<WizrFlexLayout
          background='#FFFFFF'
          flexDirection='row'
        >
          <WizrText type='sub2' >Recent Increases</WizrText>
          <WizrText type='sub2' >Sentiments</WizrText>
        </WizrFlexLayout>}
        bordered={false}
        style={{ flexGrow: 1 }}
        onMouseEnter={() => setShowIncrease(true)}
        onMouseLeave={() => setShowIncrease(false)}
        hoverable>
        {
          topIncreasedIntentsArray?.map((value: any, key: any) => {
            return (
              <WizrFlexLayout
                background='#FFFFFF'
                flexDirection='row'
                key={key}
                onClick={() => onClickIntentsandsentiments(value)}
                style={{ minHeight: '44px', padding: '0px 0px 24px 0px' }}
              >
                <WizrFlexLayout flexDirection='column' background='#FFFFFF' alignItems='flex-start' justifyContent='flex-start' style={{ textAlign: 'start', flex: '3' }}>
                  <WizrText type='body2' >{value.Intent}</WizrText>
                  <WizrFlexLayout flexDirection='row' background='#FFFFFF' alignItems='flex-start' justifyContent='flex-start' style={{ gap: '5px' }}>
                    <WizrText type='sub2'>{value.CurrentMonthCount}</WizrText>
                    <WizrIcon name='trendUpRed' />
                    <WizrText type='sub2' textColor='#EF4444'>{value?.IncreasePercentage?.toFixed(1)}%</WizrText>
                  </WizrFlexLayout>
                </WizrFlexLayout>
                <WizrFlexLayout background='#FFFFFF' alignItems='flex-end'>
                  <WizrText type='sub2' textColor={value?.OverallSentimentScore?.toFixed(1) > 55 ? "#22C55E" : value?.OverallSentimentScore?.toFixed(1) < 55 && value?.OverallSentimentScore?.toFixed(1) > 45 ? "#FBBF24" : "#EF4444"} style={{ fontSize: '26px', marginTop: '8px' }}>{value?.OverallSentimentScore?.toFixed(1)}</WizrText>
                </WizrFlexLayout>
              </WizrFlexLayout>
            )
          })
        }
        {showIncrease ? <WizrFlexLayout background="#FFF" textAlign="end" alignItems="end">
          <WizrButton type='text' color="#999B9F" text='View All' onClick={() => { onClickIntentsandsentiments('') }} style={{ borderRadius: '12px' }} />
        </WizrFlexLayout> : <></>}
      </StyledTicketCard>
      <StyledTicketCard
        title={<WizrFlexLayout
          background='#FFFFFF'
          flexDirection='row'
        >
          <WizrText type='sub2' >Recent Decreases</WizrText>
          <WizrText type='sub2' >Sentiment</WizrText>
        </WizrFlexLayout>}
        bordered={false}
        style={{ flexGrow: 1 }}
        onMouseEnter={() => setShowDecrease(true)}
        onMouseLeave={() => setShowDecrease(false)}
        hoverable>
        {
          topDecreasedIntentsArray?.map((value: any, key: any) => {
            return (
              <WizrFlexLayout
                background='#FFFFFF'
                flexDirection='row'
                key={key}
                onClick={() => onClickIntentsandsentiments(value)}
                style={{ minHeight: '44px', padding: '0px 0px 24px 0px' }}
              >
                <WizrFlexLayout flexDirection='column' background='#FFFFFF' alignItems='flex-start' justifyContent='flex-start' style={{ textAlign: 'start', flex: '3' }}>
                  <WizrText type='body2' >{value.Intent}</WizrText>
                  <WizrFlexLayout flexDirection='row' background='#FFFFFF' alignItems='flex-start' justifyContent='flex-start' style={{ gap: '5px' }}>
                    <WizrText type='sub2'>{value.CurrentMonthCount}</WizrText>
                    <WizrIcon name='trendDownGreen' />
                    <WizrText type='sub2' textColor='#22C55E'>{value?.DecreasePercentage?.toFixed(1)}%</WizrText>
                  </WizrFlexLayout>
                </WizrFlexLayout>
                <WizrFlexLayout background='#FFFFFF' alignItems='flex-end'>
                  <WizrText type='sub2' textColor={value?.OverallSentimentScore?.toFixed(1) > 55 ? "#22C55E" : value?.OverallSentimentScore?.toFixed(1) < 55 && value?.OverallSentimentScore?.toFixed(1) > 45 ? "#FBBF24" : "#EF4444"} style={{ fontSize: '26px', marginTop: '8px' }}>{value?.OverallSentimentScore?.toFixed(1)}</WizrText>
                </WizrFlexLayout>
              </WizrFlexLayout>
            )
          })
        }
        {showDecrease ? <WizrFlexLayout background="#FFF" textAlign="end" alignItems="end">
          <WizrButton type='text' color="#999B9F" text='View All' onClick={() => { onClickIntentsandsentiments('') }} style={{ borderRadius: '12px' }} />
        </WizrFlexLayout> : <></>}
      </StyledTicketCard>
      <StyledTicketCard
        title={<WizrText type='sub1'>Overall Tickets</WizrText>}
        bordered={false}
        style={{ flexGrow: 1 }}
        onClick={() => { onClickIntentsandsentiments('') }}
        hoverable>
        <WizrFlexLayout alignItems='flex-start' background='white' justifyContent='center' style={{ display: 'flex', gap: '8px', padding: '16px 0px 0px 70px' }}>
          <WizrText type='caption' textColor='#22C55E' style={{ fontSize: '64px' }}>{totalIntentsArray[0]?.TicketCount}</WizrText>
          <WizrText type='sub2' textColor='#22C55E' style={{ padding: '16px 0px 0px 0px' }}>{totalIntentsArray[0]?.ChangeInCountPercentage?.toFixed(1)}% vs last {interval}</WizrText>
          <WizrText type='caption'>Overall sentiment</WizrText>
          <WizrText type='sub1' textColor='#22C55E' style={{ fontSize: '34px' }}>{totalIntentsArray[0]?.CurrentOverallSentimentScore?.toFixed(1)}</WizrText>
          <WizrText type='sub2' ><WizrText type="sub2" textColor='#22C55E'>{totalIntentsArray[0]?.SentimentScoreChangePercentage?.toFixed(1)}%</WizrText> vs last {interval}</WizrText>
        </WizrFlexLayout>

      </StyledTicketCard>
    </WizrFlexLayout>
  )
}

const TicketResolutionCards = (range: any): JSX.Element => {

  // apis for ticket resolution
  const timeToResponse = useTimetoResponse();
  const timeToResolve = useTimetoResolve();
  const ticketEscallation = useTicketEscallation();
  const firstContactResolution = useFirstContactResolution();

  const extractData = (result: any) => {
    const rows = result?.resultTable?.rows || [];
    const columns = result?.resultTable?.dataSchema?.columnNames || [];

    return rows.map((row: any) => {
      const obj: any = {};
      columns.forEach((columnName: any, index: any) => {
        obj[columnName] = row[index];
      });
      return obj;
    });
  };

  // update the api response to the format we want
  const timeToResponseArray = useMemo(() => extractData(timeToResponse), [timeToResponse]);
  const timeToResolveArray = useMemo(() => extractData(timeToResolve), [timeToResolve]);
  const ticketEscalationArray = useMemo(() => extractData(ticketEscallation), [ticketEscallation]);
  const firstContactResolutionArray = useMemo(() => extractData(firstContactResolution), [firstContactResolution]);

  return (
    <WizrFlexLayout
      justifyContent='space-between'
      alignItems='stretch'
      style={{
        display: 'flex',
        // flex: '1',
        flexDirection: 'row',
        textAlign: 'left',
        justifyContent: 'flex-start',
        gap: '30px',
        overflowX: 'auto',
        padding: "12px"

      }}
    >
      <StyledTicketResolutionCard title="Time to Respond" bordered={false} style={{ flexGrow: 1 }} hoverable>
        <WizrText type='sub2' textColor='#22C55E' style={{ fontSize: '28px' }}>{(timeToResponseArray[0]?.CurrentPeriodAverageResponseTime / 3600000).toFixed(1)} hrs</WizrText>
        <WizrFlexLayout justifyContent='center' alignContent='center' alignItems='center' flexDirection='row' background='white' style={{ marginTop: '8px', gap: '4px' }}>
          <WizrIcon name='trendUpRed' />
          <WizrText type='sub2' textColor='#EF4444'>{timeToResponseArray[0]?.ResponseTimeChangePercentage?.toFixed(1)}%</WizrText>
          <WizrText type='sub2'>v.s last month</WizrText>
        </WizrFlexLayout>
      </StyledTicketResolutionCard>
      <StyledTicketResolutionCard title="Time to Resolve" bordered={false} style={{ flexGrow: 1 }} hoverable>
        <WizrText type='sub2' textColor='#22C55E' style={{ fontSize: '28px' }}>{(timeToResolveArray[0]?.CurrentPeriodAverageResolutionTime / 3600000).toFixed(1)} hrs</WizrText>
        <WizrFlexLayout justifyContent='center' alignContent='center' alignItems='center' flexDirection='row' background='white' style={{ marginTop: '8px', gap: '4px' }}>
          <WizrIcon name='trendUpRed' />
          <WizrText type='sub2' textColor='#EF4444'>{timeToResolveArray[0]?.ResolutionTimeChangePercentage.toFixed(1)}%</WizrText>
          <WizrText type='sub2'> v.s last month</WizrText>
        </WizrFlexLayout>
      </StyledTicketResolutionCard>
      <StyledTicketResolutionCard title="Ticket Escalation" bordered={false} style={{ flexGrow: 1 }} hoverable>
        <WizrText type='sub2' textColor='#22C55E' style={{ fontSize: '28px' }}> {ticketEscalationArray[0]?.EscalatedCurrent}</WizrText>
        <WizrFlexLayout justifyContent='center' alignContent='center' alignItems='center' flexDirection='row' background='white' style={{ marginTop: '8px', gap: '4px' }}>
          <WizrIcon name='trendUpRed' />
          <WizrText type='sub2' textColor='#EF4444'>12%</WizrText>
          <WizrText type='sub2'> v.s last month</WizrText>
        </WizrFlexLayout>
      </StyledTicketResolutionCard>
      <StyledTicketResolutionCard title="First Contact Resolution" bordered={false} style={{ flexGrow: 1 }} hoverable>
        <WizrText type='sub2' textColor='#22C55E' style={{ fontSize: '28px' }}>{firstContactResolutionArray[0]?.CurrentMonthCount}</WizrText>
        <WizrFlexLayout justifyContent='center' alignContent='center' alignItems='center' flexDirection='row' background='white' style={{ marginTop: '8px', gap: '4px' }}>
          <WizrIcon name='trendUpRed' />
          <WizrText type='sub2' textColor='#EF4444'>{firstContactResolutionArray[0]?.PercentageChange.toFixed(1)}%</WizrText>
          <WizrText type='sub2'> v.s last month</WizrText>
        </WizrFlexLayout>
      </StyledTicketResolutionCard>
    </WizrFlexLayout>
  )
}


const CustomerAnalytics = ({ onClickIntentsandsentiments, range, interval }: any) => {

  const data = [
    { name: 'Technical Issues', value: 58 },
    { name: 'User Assistance', value: 125 },
    { name: 'Data and security', value: 100 },
    { name: 'General enquiries', value: 110 },
    { name: 'Billing and subscription', value: 85 },
    { name: 'Integration and Compactibility', value: 62 },
  ];

  const pieData = [
    { name: 'Total', value: 3259 },
    { name: 'Auto solve', value: 750 }
  ];


  const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];
  const CustomizedAxisTick = ({ x, y, payload }: any) => {
    return (
      <Text x={x - 28} y={y - 10} width={100} textAnchor="middle" verticalAnchor="start">
        {payload.value}
      </Text>
    );
  };

  return (
    <WizrFlexLayout>
      <WizrFlexLayout style={{ minHeight: '240px', gap: '20px' }} alignItems='flex-start'>
        <WizrText type='sub1'>Customer Intents and Sentiments</WizrText>
        <CustomerIntents onClickIntentsandsentiments={onClickIntentsandsentiments} range={range} interval={interval} />
      </WizrFlexLayout>
      <WizrFlexLayout alignContent='flex-start' alignItems='flex-start' flexDirection='column' textAlign='left' style={{ gap: '20px', marginTop: '8px' }}>
        <WizrText type='sub1'>Ticket Resolution analytics</WizrText>
        <TicketResolutionCards range={range} />
      </WizrFlexLayout>
      <WizrFlexLayout flexDirection='row' style={{ gap: '10px', }}>
        <WizrFlexLayout alignContent='flex-start' alignItems='flex-start' flexDirection='column' background='white' textAlign='left' style={{ gap: '20px', marginTop: '8px', minWidth: '952px', padding: '10px' }}>
          <WizrText type='sub1'>Auto Solve Analytics</WizrText>
          <BarChart
            width={952}
            height={400}
            data={data}
            layout="vertical" // Set layout to vertical
            margin={{ top: 20, right: 30, left: 30, bottom: 5 }}
          >
            <XAxis type="number" stroke="transparent" />
            <YAxis dataKey="name" type="category" stroke="transparent" tick={<CustomizedAxisTick />} />
            <Label style={{
              textAnchor: "middle",
              fontSize: "130%",
              fill: "white",
            }} />
            <Bar dataKey="value" fill="#8884d8" label={{ position: 'right' }} barSize={25} >
              {data.map((entry: any, index: any) => (
                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
              ))}
            </Bar>
          </BarChart>
        </WizrFlexLayout>
        <WizrFlexLayout flexDirection='column' alignItems='flex-start' alignContent='flex-start' style={{ width: '100%', padding: '10px', gap: '10px', display: 'flex' }}>
          <StyledTicketCardForCharts title="Auto Solve Rate">
            <WizrFlexLayout background='white' style={{ height: '196px', flexGrow: 1 }}>
              <ResponsiveContainer>
                <PieChart >
                  <Pie dataKey="value" data={pieData} fill="#8884d8" label />
                </PieChart>
              </ResponsiveContainer>
            </WizrFlexLayout>
          </StyledTicketCardForCharts>
          <StyledTicketCardForCharts title="Time to Respond" bordered={false} style={{ flexGrow: 1 }} hoverable>
            <WizrText type='sub2' style={{ fontSize: '28px' }}> 30 mins</WizrText>
            <WizrFlexLayout justifyContent='center' alignContent='center' alignItems='center' flexDirection='row' background='white'>
              <WizrIcon name='trendUpRed' />
              <WizrText type='sub2'>12% v.s last month</WizrText>
            </WizrFlexLayout>
          </StyledTicketCardForCharts>
        </WizrFlexLayout>
      </WizrFlexLayout>
    </WizrFlexLayout>
  );
}

export default CustomerAnalytics;

const StyledTicketCardForCharts = styled(Card) <any>`
  background-color: #ffffff;
  width: 100%;
  .ant-card-meta-detail {
    border-bottom: none;
    border-top: none;
  }
  .ant-card-head {
    border-bottom: none;
    margin-bottom: 4px;
    text-align: start
  }
  .ant-card-body {
    text-align: center;
  }
`
const StyledTicketCard = styled(Card) <any>`
  background-color: #ffffff;
  width: 100%;
  .ant-card-meta-detail {
    border-bottom: none;
    border-top: none;
  }
  .ant-card-head {
    border-bottom: none;
    text-align: start
  }
  .ant-card-body {
    text-align: center;
    padding: 24px;
  }
`

const StyledTicketResolutionCard = styled(Card) <any>`
  background-color: #ffffff;
  width: 100%;
  .ant-card-meta-detail {
    border-bottom: none;
    border-top: none;
  }
  .ant-card-head {
    border-bottom: none;
    margin-bottom: 4px;
    text-align: start
  }
  .ant-card-body {
    text-align: center;
  }
`

