import { styled } from 'styled-components'
import { Breadcrumb } from 'antd'
import WizrFlexLayout from './WizrFlexLayout'
import WizrText from './WizrText'
import WizrIcon from './WizrIcon'

interface IWizrUserJourney {
  userJourney: any
}

const StyledBreadcrumb = styled(Breadcrumb)`
  .ant-breadcrumb-separator {
    display: flex;
    align-items: center;
  }
`

const StyledContainer = styled(WizrFlexLayout)`
  padding: 20px 0px;
`
const styleJourney = (journeys: any) => {
  return journeys?.map((journey: any, key: number) => {
    return { title: <WizrText type='h6'>{journey?.title}</WizrText> }
  })
}

const WizrUserJourney = ({ userJourney }: IWizrUserJourney): JSX.Element => {
  const styledUserJourneys = styleJourney(userJourney)
  return (
    <StyledContainer>
      <StyledBreadcrumb
        items={styledUserJourneys}
        separator={<WizrIcon name='breakCrumbArrow' />}
      />
    </StyledContainer>
  )
}

export default WizrUserJourney
