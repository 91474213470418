import { styled } from 'styled-components'
import { Checkbox } from 'antd'
import WizrText from './WizrText'

interface IWizrCheckbox {
  text?: string
  onChange?: any
  isEnabled?: boolean | undefined
  value?: any
}

const StyledCheckbox = styled(Checkbox)`
  color: var(--grayscale-700, #5d5f62);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.25px;
`

const WizrCheckbox = ({ text, onChange, isEnabled = false ,value}: IWizrCheckbox): JSX.Element => {
  return (
    <StyledCheckbox onChange={onChange} checked={isEnabled} value={value}>
      <WizrText type='body2'>{text}</WizrText>
    </StyledCheckbox>
  )
}

export default WizrCheckbox
