import { Popover } from 'antd'
import WizrButton from '../WizrButton'
import UploadIcon from '../../assets/icons/Upload'
import WizrFlexLayout from '../WizrFlexLayout'
import FileIcon from '../../assets/icons/FileIcon'
import UrlIcon from '../../assets/icons/UrlIcon'
import WizrUploadContainer from '../WizrUploadButton'
import { useWizrModalContext, MODAL_TYPES } from '../../providers/modal/WizrModalProvider'
import { dispatchUploadFile } from '../../redux/actions/content'
import { addValueToLocalStorage, getValueFromLocalStorage, uploadCheck } from '../../utils'
import { useAuth } from '../../authContext'

interface IUploadActionPopOver {
  detail: any
}

interface IUploadActionPopOverContent {
  folderDetail: any
}


const setUploadStartStatus = (urlList: any) => {
  const existingUploadMapping = getValueFromLocalStorage('UrlToUpload');
  const currentFolderId = localStorage.getItem('folderId');

  if (currentFolderId) {
    const currentFolderUploadMap = existingUploadMapping?.[currentFolderId] || {};
    const fileMapping = { [currentFolderId]: currentFolderUploadMap };

    urlList.forEach((url: any) => {
      fileMapping[currentFolderId][url] = 'inprogress';
    });

    addValueToLocalStorage('UrlToUpload', { ...existingUploadMapping, ...fileMapping });
  }
};




const UploadActionPopOverContent = ({ folderDetail }: IUploadActionPopOverContent): JSX.Element => {
  const { showModal, hideModal } = useWizrModalContext()
  const testFolderId = localStorage.getItem('folderId')

  const startFileUpload = (fileInfo: any) => {
    const check = uploadCheck(3, fileInfo?.file)
    if(check){
      showModal(MODAL_TYPES.ADD_TAGS, {
        fileDetail: fileInfo,
        onSubmit: (tagValues: any) => {
          
          showModal(MODAL_TYPES.MULTI_FILE_UPLOAD, {
            filesList: fileInfo?.fileList,
            folderId: folderDetail?.folder_id || testFolderId,
            fileTags: tagValues,
            onDone: hideModal,
            onCancel: hideModal,
          })
        },
        onCancel: () => hideModal(),
      })
    }
  }

  // const startUrlUpload = () => {
  //   showModal(MODAL_TYPES.ADD_TAGS, {
  //     fileDetail: null,
  //     onSubmit: (tagValues: any) => {
  //       showModal(MODAL_TYPES.UPLOAD_URL, {
  //         folderId: folderDetail?.folder_id,
  //         fileTags: tagValues,
  //         onFormSubmission: (formValues: any) => {
  //           hideModal()
  //           dispatchUploadFile({
  //             file_names: formValues?.url?.split(','),
  //             folder_id: folderDetail?.folder_id || testFolderId,
  //             tags: tagValues,
  //             type: 'url',
  //           })
  //         },
  //         onCancel: hideModal,
  //       })
  //     },
  //     onCancel: () => hideModal(),
  //   })
  // }
  const startUrlUpload = () => {
    showModal(MODAL_TYPES.UPLOAD_URL, {
      folderId: folderDetail?.folder_id,
      onFormSubmission: (formValues: any) => {
        hideModal() // Hide the "Upload URL" modal
        showModal(MODAL_TYPES.ADD_TAGS, {
          fileDetail: null,
          onSubmit: (tagValues: any) => {
            setUploadStartStatus(formValues?.url?.split(','))
            dispatchUploadFile({
              file_names: formValues?.url?.split(','),
              folder_id: folderDetail?.folder_id || testFolderId,
              tags: tagValues?tagValues:[],
              type: 'url',
            })
            hideModal()
          },
          onCancel: () => hideModal(),
        })
      },
      onCancel: () => hideModal(),
    })
  }

  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      <WizrUploadContainer onChange={startFileUpload}>
        <WizrButton
          icon={<FileIcon />}
          text='Upload Files'
          type='text'
          onClick={() => {}}
          justifyContent='flex-start'
        />
      </WizrUploadContainer>
      <WizrButton
        icon={<UrlIcon />}
        text='Page URL'
        type='text'
        onClick={startUrlUpload}
        justifyContent='flex-start'
      />
    </WizrFlexLayout>
  )
}

const UploadActionPopOver = ({ detail }: IUploadActionPopOver): JSX.Element => {
  const { userPermission, hasAcces } = useAuth();
  return (
    <Popover
      content={<UploadActionPopOverContent folderDetail={detail} />}
      title=''
      trigger='click'
    >
      <WizrButton type='text' icon={<UploadIcon />} text='Upload' disabled={hasAcces('update_folder') === true ? false : true}/>
    </Popover>
  )
}

export default UploadActionPopOver
