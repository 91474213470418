import axios, { AxiosError, AxiosInstance } from 'axios';

const errorCodes: { [key: number]: string } = {
  400: "Some of the inputs provided to the request aren't valid.",
  401: "The user session isn't valid.",
  403: "The user isn't authorized to perform the specified request.",
  500: 'An unexpected error occurred while executing the code.',
  502: 'Service temporarily overloaded.',
  507: "Error occurred while making Cosmos DB connection or while fetching the Cosmos data.",
  508: "Error occurred while making PostgreSQL DB connection or while fetching the PostgreSQL data.",
};

class WizrAPIHandler {
  private _instance: AxiosInstance;

  constructor() {
    this._instance = axios.create({ timeout: 600000 });
    this._instance.interceptors.request.use(this.requestInterceptor, this.errorHandler);
    this._instance.interceptors.response.use(this.responseInterceptor, this.errorHandler);
  }

  get(url: string) {
    return this._instance.get(url);
  }

  post(url: string, params: { [key: string]: string | number }) {
    return this._instance.post(url, params);
  }

  delete(url: string) {
    return this._instance.delete(url);
  }

  put(url: string, params: { [key: string]: string | number }) {
    return this._instance.put(url, params);
  }

  appTestPost(url: string, params: { [key: string]: string | number }) {
    const appTestInstance = axios.create({
      baseURL: this._instance.defaults.baseURL,
      timeout: this._instance.defaults.timeout,
    });
    appTestInstance.interceptors.request.use(this.formpostRequestInterceptor, this.errorHandler);
    appTestInstance.interceptors.response.use(this.responseInterceptor, this.errorHandler);

    appTestInstance.defaults.headers['Content-Type'] = 'application/json';
    appTestInstance.defaults.headers['wizr-auth'] = localStorage.getItem('appshare_token');

    return appTestInstance.post(url, params);
  }

  controlRoomApis(url: string, params: { [key: string]: string | number }) {
    const controlroomapiInstance = axios.create({
      baseURL: this._instance.defaults.baseURL,
      timeout: this._instance.defaults.timeout,
    });
    controlroomapiInstance.interceptors.request.use(this.formpostRequestInterceptor, this.errorHandler);
    controlroomapiInstance.interceptors.response.use(this.responseInterceptor, this.errorHandler);

    controlroomapiInstance.defaults.headers['Content-Type'] = 'application/json';
    controlroomapiInstance.defaults.headers['Authorization'] = 'Basic M2RiZmFmYjFlZjE0NDg5MjllZjRjOTY4YjFkYzZlYTA6TGcxL1A1KzcrNE9Ya0VwS0JhMzdmR2J3YkI0alp5eXlCa1dLK3lYcHJhRT0=';

    return controlroomapiInstance.post(url, params);
  }

  formDatapost(url: string, params: { [key: string]: string | number }) {
    const formpostInstance = axios.create({
      baseURL: this._instance.defaults.baseURL,
      timeout: this._instance.defaults.timeout,
    });
    formpostInstance.interceptors.request.use(this.formpostRequestInterceptor, this.errorHandler);
    formpostInstance.interceptors.response.use(this.responseInterceptor, this.errorHandler);

    const apiKey = process.env.REACT_APP_API_AUTHORIZATION_HEADER_APIKEY;
    const apiKeyValue = process.env.REACT_APP_API_AUTHORIZATION_HEADER_APIKEY_VALUE;
    if (apiKey && apiKeyValue) {
      formpostInstance.defaults.headers[apiKey] = apiKeyValue;
    } else {
      console.error('API key value is undefined');
    }

    formpostInstance.defaults.headers['Content-Type'] = 'multipart/form-data';
    formpostInstance.defaults.headers['wizr-auth'] = localStorage.getItem('id_token');

    return formpostInstance.post(url, params);
  }

  appsharepost(url: string, params: { [key: string]: string | number }) {
    const appShareInstance = axios.create({
      baseURL: this._instance.defaults.baseURL,
      timeout: this._instance.defaults.timeout,
    });
    appShareInstance.interceptors.request.use(this.formpostRequestInterceptor, this.errorHandler);
    appShareInstance.interceptors.response.use(this.responseInterceptor, this.errorHandler);

    appShareInstance.defaults.headers['Content-Type'] = 'multipart/form-data';
    appShareInstance.defaults.headers['wizr-auth'] = localStorage.getItem('appshare_token');

    return appShareInstance.post(url, params);
  }

  appshareget(url: string) {
    const appShareInstance = axios.create({
      baseURL: this._instance.defaults.baseURL,
      timeout: this._instance.defaults.timeout,
    });
    appShareInstance.interceptors.request.use(this.formpostRequestInterceptor, this.errorHandler);
    appShareInstance.interceptors.response.use(this.responseInterceptor, this.errorHandler);

    appShareInstance.defaults.headers['Content-Type'] = 'multipart/form-data';
    appShareInstance.defaults.headers['wizr-auth'] = localStorage.getItem('appshare_token');

    return appShareInstance.get(url);
  }

  private requestInterceptor(config: any) {
    const apiKey = process.env.REACT_APP_API_AUTHORIZATION_HEADER_APIKEY;
    if (apiKey) {
      const apiKeyValue = process.env.REACT_APP_API_AUTHORIZATION_HEADER_APIKEY_VALUE;
      config.headers[apiKey] = apiKeyValue;
    }
    const token = localStorage.getItem('id_token');
    if (token) {
      config.headers['wizr-auth'] = token;
    }
    return config;
  }

  private formpostRequestInterceptor(config: any) {
    return config;
  }

  private responseInterceptor(response: any) {
    if (response.data.status in errorCodes) {
      throw new Error(response.data.message);
    }
    return response;
  }

  private errorHandler(error: AxiosError) {
    const statuscode = error.response?.status;
    if (statuscode !== undefined) {
      throw new Error(errorCodes[statuscode]);
    } else {
      throw new Error('Unknown error occurred');
    }
  }
}

const WizrAPIInstance = new WizrAPIHandler();

export {
  WizrAPIInstance,
};

// import axios, { AxiosError, AxiosInstance } from 'axios'
// const errorCodes: { [key: number]: string } = {
//   400: "Some of the inputs provided to the request aren't valid ",
//   401: "The user session isn't valid",
//   403: "The user isn't authorized to perform the specified request.",
//   500: 'An unexpected error occured while executing the code',
//   502: '	Service temporarily overloaded.',
//   508: "Error occured while making postgres db connection or while fetching the postgres data",
//   507: "Error occured while making cosmos db connection or while fetching the cosmos data",
// }

// class WizrAPI {
//   private _instance: AxiosInstance
//   constructor(baseURL?: string) {
//     this._instance = axios.create({ baseURL, timeout: 600000 })
//     this._instance.interceptors.request.use(this.requestInterceptor, this.errorHandler)
//     this._instance.interceptors.response.use(this.responseInterceptor, this.errorHandler)
//   }
//   get(url: string) {
//     return this._instance.get(url)
//   }
//   post(url: string, params: { [key: string]: string | number }) {
//     return this._instance.post(url, params)
//   }
//   delete(url: string) {
//     return this._instance.delete(url)
//   }
//   put(url: string, params: { [key: string]: string | number }) {
//     return this._instance.put(url, params)
//   }
//   formDatapost(url: string, params: { [key: string]: string | number }) {
//     // Create a new Axios instance for formpost with an additional header
//     const formpostInstance = axios.create({
//       baseURL: this._instance.defaults.baseURL,
//       timeout: this._instance.defaults.timeout,
//     });
//     formpostInstance.interceptors.request.use(this.formpostRequestInterceptor, this.errorHandler);
//     formpostInstance.interceptors.response.use(this.responseInterceptor, this.errorHandler);

//     // Modify the configuration for the formpost request
//     formpostInstance.defaults.headers['Content-Type'] = 'multipart/form-data';
//     formpostInstance.defaults.headers['Wizr-Auth'] = localStorage.getItem('id_token');

//     return formpostInstance.post(url, params);
//   }
//   appsharepost(url:string, params: { [key: string]: string | number }){
//         // Create a new Axios instance for formpost with an additional header
//         const appShareInstance = axios.create({
//           baseURL: this._instance.defaults.baseURL,
//           timeout: this._instance.defaults.timeout,
//         });
//         appShareInstance.interceptors.request.use(this.formpostRequestInterceptor, this.errorHandler);
//         appShareInstance.interceptors.response.use(this.responseInterceptor, this.errorHandler);
    
//         // Modify the configuration for the formpost request
//         appShareInstance.defaults.headers['Content-Type'] = 'multipart/form-data';
//         appShareInstance.defaults.headers['Wizr-Access-Key'] = 'adf7524820284803a39a04589b107bc1';
    
//         return appShareInstance.post(url,params);
//   }
//   requestInterceptor(config: any) {
//     config.headers['wizr-auth'] = localStorage.getItem('id_token')
//     return config
//   }
//   formpostRequestInterceptor(config: any) {
//     // This interceptor is used exclusively for formpost
//     // You can add specific logic for formpost request headers here
//     return config;
//   }
//   responseInterceptor(response: any) {
//     if (response.data.status in errorCodes) { 
//       throw new Error(response.data.message)
//     }
//     return response
//   }
//   errorHandler(error: AxiosError) {
//     const statuscode = error.response?.status
//     if (statuscode !== undefined) {
//       throw new Error(errorCodes[statuscode])
//     } else {
//       throw new Error('Unknown error occurred')
//     }

//     return error
//   }
// }

// // const WizrFolderAPI = new WizrAPI(process.env.REACT_APP_API_CONTENT_FOLDER)
// const WizrFileAPI = new WizrAPI(process.env.REACT_APP_API_FILE_MANAGER)
// const WizrTemplateAPI = new WizrAPI(process.env.REACT_APP_API_PROMPT_DESIGNER)
// const WizrPublishedDraftAPI = new WizrAPI(process.env.REACT_APP_API_PROMPT_MANAGER)
// const WizrTestChatAPI = new WizrAPI(process.env.REACT_APP_API_PROMPT_ORCHESTRATOR)
// const WizrCosmosQuery = new WizrAPI(process.env.REACT_APP_API_SYSTEM_CONFIG)
// const WizrPromptDetails = new WizrAPI(process.env.REACT_APP_API_PROMPT_MANAGER)
// const WizrAppDetails = new WizrAPI(process.env.REACT_APP_API_APP_MANAGER)
// const WizrAppChatApi = new WizrAPI(process.env.REACT_APP_API_APP_ORCHESTRATOR)




// export {
//   // WizrFolderAPI,
//   WizrFileAPI,
//   WizrTemplateAPI,
//   WizrPublishedDraftAPI,
//   WizrTestChatAPI,
//   WizrCosmosQuery,
//   WizrPromptDetails,
//   WizrAppDetails,
//   WizrAppChatApi,
//   WizrAPIInstance,
// }



// class WizrAPIHandler {
//   private _instance: AxiosInstance
//   constructor() {
//     this._instance = axios.create({ timeout: 600000 })
//     this._instance.interceptors.request.use(this.requestInterceptor, this.errorHandler)
//     this._instance.interceptors.response.use(this.responseInterceptor, this.errorHandler)
//   }
  
//   get(url: string) {
//     return this._instance.get(url)
//   }
//   post(url: string, params: { [key: string]: string | number }) {
//     return this._instance.post(url, params)
//   }
//   delete(url: string) {
//     return this._instance.delete(url)
//   }
//   put(url: string, params: { [key: string]: string | number }) {
//     return this._instance.put(url, params)
//   }
//   appTestPost(url: string, params: { [key: string]: string | number }) {
//     // Create a new Axios instance for appTestPost with an additional header
//     const appTestInstance = axios.create({
//       baseURL: this._instance.defaults.baseURL,
//       timeout: this._instance.defaults.timeout,
//     });
//     appTestInstance.interceptors.request.use(this.formpostRequestInterceptor, this.errorHandler);
//     appTestInstance.interceptors.response.use(this.responseInterceptor, this.errorHandler);
  
//     // Modify the configuration for the appTestPost request
//     appTestInstance.defaults.headers['Content-Type'] = 'application/json';
//     appTestInstance.defaults.headers['wizr-auth'] = localStorage.getItem('appshare_token');
  
//     return appTestInstance.post(url, params);
//   }
//   controlRoomApis(url: string, params: { [key: string]: string | number }) {
//     const controlroomapiInstance = axios.create({
//       baseURL: this._instance.defaults.baseURL,
//       timeout: this._instance.defaults.timeout,
//     });
//     controlroomapiInstance.interceptors.request.use(this.formpostRequestInterceptor, this.errorHandler);
//     controlroomapiInstance.interceptors.response.use(this.responseInterceptor, this.errorHandler);
  
//     controlroomapiInstance.defaults.headers['Content-Type'] = 'application/json';
//     controlroomapiInstance.defaults.headers['Authorization'] = 'Basic M2RiZmFmYjFlZjE0NDg5MjllZjRjOTY4YjFkYzZlYTA6TGcxL1A1KzcrNE9Ya0VwS0JhMzdmR2J3YkI0alp5eXlCa1dLK3lYcHJhRT0='
  
//     return controlroomapiInstance.post(url, params);
//   }
  
//   formDatapost(url: string, params: { [key: string]: string | number }) {
//     // Create a new Axios instance for formpost with an additional header
//     const formpostInstance = axios.create({
//       baseURL: this._instance.defaults.baseURL,
//       timeout: this._instance.defaults.timeout,
//     });
//     formpostInstance.interceptors.request.use(this.formpostRequestInterceptor, this.errorHandler);
//     formpostInstance.interceptors.response.use(this.responseInterceptor, this.errorHandler);

//     // Modify the configuration for the formpost request
//     const apiKeyValue = process.env.REACT_APP_API_AUTHORIZATION_HEADER_APIKEY_VALUE;
//     const apiKey: any = process.env.REACT_APP_API_AUTHORIZATION_HEADER_APIKEY


// if (apiKeyValue) {
//   formpostInstance.defaults.headers[apiKey] = apiKeyValue;
// } else {
//   // Handle the case where the API key value is undefined (optional)
//   console.error('API key value is undefined');
// }

//     formpostInstance.defaults.headers['Content-Type'] = 'multipart/form-data';
//     formpostInstance.defaults.headers['wizr-auth'] = localStorage.getItem('id_token');

//     return formpostInstance.post(url, params);
//   }
//   appsharepost(url:string, params: { [key: string]: string | number }){
//     // Create a new Axios instance for formpost with an additional header
//     const appShareInstance = axios.create({
//       baseURL: this._instance.defaults.baseURL,
//       timeout: this._instance.defaults.timeout,
//     });
//     appShareInstance.interceptors.request.use(this.formpostRequestInterceptor, this.errorHandler);
//     appShareInstance.interceptors.response.use(this.responseInterceptor, this.errorHandler);

//     // Modify the configuration for the formpost request
//     appShareInstance.defaults.headers['Content-Type'] = 'multipart/form-data';
//     appShareInstance.defaults.headers['wizr-auth'] = localStorage.getItem('appshare_token');

//     return appShareInstance.post(url,params);
// }
// appshareget(url:string){
//   // Create a new Axios instance for formpost with an additional header
//   const appShareInstance = axios.create({
//     baseURL: this._instance.defaults.baseURL,
//     timeout: this._instance.defaults.timeout,
//   });
//   appShareInstance.interceptors.request.use(this.formpostRequestInterceptor, this.errorHandler);
//   appShareInstance.interceptors.response.use(this.responseInterceptor, this.errorHandler);

//   // Modify the configuration for the formpost request
//   appShareInstance.defaults.headers['Content-Type'] = 'multipart/form-data';
//   appShareInstance.defaults.headers['wizr-auth'] = localStorage.getItem('appshare_token');

//   return appShareInstance.get(url);
// }

//   requestInterceptor(config: any) {
//     const apiKey: any = process.env.REACT_APP_API_AUTHORIZATION_HEADER_APIKEY
//     const header: any = process.env.REACT_APP_API_AUTHORIZATION_HEADER
//     config.headers['wizr-auth'] = localStorage.getItem('id_token')
//     config.headers[apiKey] = process.env.REACT_APP_API_AUTHORIZATION_HEADER_APIKEY_VALUE
//     return config
//   }

//   formpostRequestInterceptor(config: any) {
//     // This interceptor is used exclusively for formpost
//     // You can add specific logic for formpost request headers here
//     return config;
//   }
//   responseInterceptor(response: any) {
//     if (response.data.status in errorCodes) { 
//       throw new Error(response.data.message)
//     }
//     return response
//   }
//   errorHandler(error: AxiosError) {
//     const statuscode = error.response?.status
//     if (statuscode !== undefined) {
//       throw new Error(errorCodes[statuscode])
//     } else {
//       throw new Error('Unknown error occurred')
//     }

//     return error
//   }
// }

// const WizrAPIInstance= new WizrAPIHandler()
