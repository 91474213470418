const AutoSolve=({stroke='#999B9F'}: any): JSX. Element => {
    return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_11071_37594"mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_11071_37594)">
        <path d="M11 21.9508C9.73333 21.8174 8.55417 21.4716 7.4625 20.9133C6.37083 20.3549 5.42083 19.6299 4.6125 18.7383C3.80417 17.8466 3.16667 16.8258 2.7 15.6758C2.23333 14.5258 2 13.3008 2 12.0008C2 10.4841 2.30417 9.08411 2.9125 7.80078C3.52083 6.51745 4.35 5.41745 5.4 4.50078H3V2.50078H9V8.50078H7V5.77578C6.08333 6.50911 5.35417 7.41328 4.8125 8.48828C4.27083 9.56328 4 10.7341 4 12.0008C4 14.0508 4.67083 15.8216 6.0125 17.3133C7.35417 18.8049 9.01667 19.6758 11 19.9258V21.9508ZM10.575 16.6008L6.35 12.3508L7.75 10.9508L10.575 13.7758L16.25 8.10078L17.65 9.52578L10.575 16.6008ZM15 21.5008V15.5008H17V18.2258C17.9167 17.4758 18.6458 16.5674 19.1875 15.5008C19.7292 14.4341 20 13.2674 20 12.0008C20 9.95078 19.3292 8.17995 17.9875 6.68828C16.6458 5.19661 14.9833 4.32578 13 4.07578V2.05078C15.5333 2.30078 17.6667 3.36745 19.4 5.25078C21.1333 7.13411 22 9.38411 22 12.0008C22 13.5174 21.6958 14.9174 21.0875 16.2008C20.4792 17.4841 19.65 18.5841 18.6 19.5008H21V21.5008H15Z" fill={stroke}/>
        </g>
        </svg>)
}
export default AutoSolve