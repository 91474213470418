const DownloadIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.308 1.00001H10.829C10.8863 1.00001 10.9433 1.00367 11 1.01101C13.2473 1.16817 14.9924 3.0332 15 5.28601V11.715C14.9917 14.0871 13.0641 16.0044 10.692 16H5.308C2.93551 16.0044 1.00772 14.0865 1 11.714V5.28601C1.00772 2.91353 2.93551 0.995581 5.308 1.00001Z'
        stroke='#999B9F'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M11 13.75C11.4142 13.75 11.75 13.4142 11.75 13C11.75 12.5858 11.4142 12.25 11 12.25V13.75ZM5 12.25C4.58579 12.25 4.25 12.5858 4.25 13C4.25 13.4142 4.58579 13.75 5 13.75V12.25ZM15 6.03599C15.4142 6.03599 15.75 5.7002 15.75 5.28599C15.75 4.87177 15.4142 4.53599 15 4.53599V6.03599ZM11 5.28599H10.25C10.25 5.7002 10.5858 6.03599 11 6.03599V5.28599ZM11.75 1.01099C11.75 0.596773 11.4142 0.260986 11 0.260986C10.5858 0.260986 10.25 0.596773 10.25 1.01099H11.75ZM10.5303 8.53032C10.8232 8.23742 10.8232 7.76255 10.5303 7.46966C10.2374 7.17676 9.76256 7.17676 9.46967 7.46966L10.5303 8.53032ZM7.46967 9.46966C7.17678 9.76255 7.17678 10.2374 7.46967 10.5303C7.76256 10.8232 8.23744 10.8232 8.53033 10.5303L7.46967 9.46966ZM7.46967 10.5303C7.76256 10.8232 8.23744 10.8232 8.53033 10.5303C8.82322 10.2374 8.82322 9.76255 8.53033 9.46966L7.46967 10.5303ZM6.53033 7.46966C6.23744 7.17676 5.76256 7.17676 5.46967 7.46966C5.17678 7.76255 5.17678 8.23742 5.46967 8.53032L6.53033 7.46966ZM7.25 9.99999C7.25 10.4142 7.58579 10.75 8 10.75C8.41421 10.75 8.75 10.4142 8.75 9.99999H7.25ZM8.75 3.99999C8.75 3.58577 8.41421 3.24999 8 3.24999C7.58579 3.24999 7.25 3.58577 7.25 3.99999H8.75ZM11 12.25H5V13.75H11V12.25ZM15 4.53599H11V6.03599H15V4.53599ZM11.75 5.28599V1.01099H10.25V5.28599H11.75ZM9.46967 7.46966L7.46967 9.46966L8.53033 10.5303L10.5303 8.53032L9.46967 7.46966ZM8.53033 9.46966L6.53033 7.46966L5.46967 8.53032L7.46967 10.5303L8.53033 9.46966ZM8.75 9.99999V3.99999H7.25V9.99999H8.75Z'
        fill='#999B9F'
      />
    </svg>
  )
}

export default DownloadIcon
