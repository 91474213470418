import {
    PieChart, Pie, Legend, Tooltip, Cell, BarChart,
    Bar,
    XAxis,
    YAxis,
    ReferenceLine,
    Label,
    Text,
    Line,
    CartesianGrid
} from "recharts";
import WizrFlexLayout from "../../../components/WizrFlexLayout";
import WizrText from "../../../components/WizrText";
import styled from "styled-components";
import { Avatar, Card, Dropdown, Select, Space } from "antd";
import WizrButton from "../../../components/WizrButton";
import { DownOutlined } from "@ant-design/icons";

const TicketResolutionAnalytics = () => {

    const data = [
        { name: "Varun", overall_tickets: 4000, ticket_backlog: 2400, ticket_resolution_time: 2400, response_time: 1800 },
        { name: "Dona", overall_tickets: 3000, ticket_backlog: 1398, ticket_resolution_time: 2210, response_time: 2750 },
        { name: "Roshan", overall_tickets: 2000, ticket_backlog: 980, ticket_resolution_time: 2290, response_time: 1500 },
        { name: "Clint", overall_tickets: 2780, ticket_backlog: 3908, ticket_resolution_time: 2000, response_time: 4400 },
        { name: "Jithin", overall_tickets: 1890, ticket_backlog: 4800, ticket_resolution_time: 2181, response_time: 3181 },
        { name: "Adnan", overall_tickets: 2390, ticket_backlog: 3800, ticket_resolution_time: 2500, response_time: 1536 },
        { name: "Hr", overall_tickets: 3490, ticket_backlog: 4300, ticket_resolution_time: 2100, response_time: 3456 },
    ];

    const Escalation = [
        { name: 'Italy', value: 15 },
        { name: 'India', value: 10 },
        { name: 'Spain', value: 9 },
        { name: 'USA', value: 6 },
        { name: 'UK', value: 4 },
        { name: 'UAE', value: 2 },
    ];

    const FirstContactResolutionTime = [
        { name: 'Technical issues', value: 15 },
        { name: 'User Assistance', value: 10 },
        { name: 'Data and Security', value: 6.5 },
        { name: 'General Enquiries', value: 4 },
        { name: 'Billing And Subscription', value: 3 },
        { name: 'Integration and Compatibility', value: 11 },
    ];

    const overallTickets = [
        { name: 'Technical issues', value: 285 },
        { name: 'User Assistance', value: 596 },
        { name: 'Data and Security', value: 523 },
        { name: 'General Enquiries', value: 542 },
        { name: 'Billing And Subscription', value: 312 },
        { name: 'Integration and Compatibility', value: 241 },
    ];

    const colors = ['#785DFB', '#FD765D', '#22C55E', '#F7BB49', '#B1596F', '#FEB27F'];
    const contactResolutionColors = ['#785DFB', '#FD765D', '#84E1D9', '#F7BB49', '#C56BDA', '#FEB27F'];

    const CustomizedAxisTick = ({ x, y, payload }: any) => {
        return (
            <Text x={x - 28} y={y - 10} width={100} textAnchor="middle" verticalAnchor="start">
                {payload.value}
            </Text>
        );
    };

    const piechartData = [
        { name: "John", value: 500 },
        { name: "Michael", value: 100 },
        { name: "Harry simmons", value: 200 },
        { name: "Emma", value: 300 },
        { name: "Brooklyn simmons", value: 400 },
    ];

    const COLORS = ["#5415BC", "#22C55E", "#4285F4", "#FFA319", "#39CEF3",];

    const total = data.reduce((acc: any, curr: any) => acc + curr.value, 0);
    const formattedData = piechartData.map((entry: any, index: any) => ({
        ...entry,
        percent: `${((entry.value / total) * 100).toFixed(2)}%`,
        fill: COLORS[index % COLORS.length], // Assign color from COLORS array
    }));

    const CustomLegend = (props: any) => {
        const { payload } = props;
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '20px' }}>
                {payload.map((entry: any, index: any) => (
                    <>
                        <WizrText type="sub1">{`32%`}</WizrText>
                        <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                            <div style={{
                                width: '20px',
                                height: '20px',
                                backgroundColor: entry.color,
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: '8px'
                            }}>
                            </div>
                            <WizrText type="caption">{entry.value}</WizrText>
                        </div>
                    </>
                ))}
            </div>
        );
    };




    return (
        <WizrFlexLayout style={{ gap: '16px' }}>
            <WizrFlexLayout flexDirection="row" style={{ gap: '16px' }}>
                <WizrFlexLayout background="#FFF" alignItems="flex-start" style={{ padding: '16px', gap: '8px' }}>
                    <WizrFlexLayout flexDirection="row" background="#FFF" style={{ flex: 1 }}>
                        <WizrText type="sub2">Escalation</WizrText>
                        <WizrButton type='text' color="#999B9F" text='View All' onClick={() => { }} style={{ borderRadius: '12px' }} />
                    </WizrFlexLayout>
                    <Dropdown trigger={['click']} menu={{ items: [], selectable: true }} >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space style={{ color: '#707276' }}>
                                <span>{'Location'}</span>
                                <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>
                    <BarChart
                        width={650}
                        height={300}
                        data={Escalation}
                        layout="vertical" // Set layout to vertical
                        margin={{ top: 20, right: 30, left: 30, bottom: 5 }}
                    >
                        <XAxis type="number" stroke="transparent" />
                        <YAxis dataKey="name" type="category" stroke="transparent" tick={<CustomizedAxisTick />} />
                        <Label style={{
                            textAnchor: "middle",
                            fontSize: "130%",
                            fill: "white",
                        }} />
                        <Bar dataKey="value" fill="#8884d8" label={{ position: 'right' }} barSize={25} >
                            {data.map((entry: any, index: any) => (
                                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                            ))}
                        </Bar>
                    </BarChart>
                </WizrFlexLayout>
                <WizrFlexLayout background="#FFF" alignItems="flex-start" style={{ padding: '16px', gap: '8px' }}>
                    <WizrFlexLayout flexDirection="row" background="#FFF" style={{ flex: 1 }}>
                        <WizrText type="sub2">First Contact Resolution Time</WizrText>
                        <WizrButton type='text' color="#999B9F" text='View All' onClick={() => { }} style={{ borderRadius: '12px' }} />
                    </WizrFlexLayout>
                    <Dropdown trigger={['click']} menu={{ items: [], selectable: true }} >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space style={{ color: '#707276' }}>
                                <span>{'Category'}</span>
                                <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>
                    <BarChart
                        width={650}
                        height={300}
                        data={FirstContactResolutionTime}
                        layout="vertical" // Set layout to vertical
                        margin={{ top: 20, right: 30, left: 30, bottom: 5 }}
                    >
                        <XAxis type="number" stroke="transparent" padding={{ left: 10 }} />
                        <YAxis dataKey="name" type="category" stroke="transparent" tick={<CustomizedAxisTick />} />
                        <Label style={{
                            textAnchor: "middle",
                            fontSize: "130%",
                            fill: "white",
                        }} />
                        <Bar dataKey="value" fill="#8884d8" label={{ position: 'right' }} barSize={25} >
                            {data.map((entry: any, index: any) => (
                                <Cell key={`cell-${index}`} fill={contactResolutionColors[index % 20]} />
                            ))}
                        </Bar>
                    </BarChart>
                </WizrFlexLayout>
            </WizrFlexLayout>
            <WizrFlexLayout flexDirection="row" style={{ gap: '16px' }}>
                <WizrFlexLayout background="#FFF" alignItems="flex-start" style={{ padding: '16px', gap: '24px' }}>
                    <WizrFlexLayout flexDirection="row" background="#FFF" style={{ flex: 1 }}>
                        <WizrText type="sub2">Ticket Closure Rate</WizrText>
                        <WizrButton type='text' color="#999B9F" text='View All' onClick={() => { }} style={{ borderRadius: '12px' }} />
                    </WizrFlexLayout>
                    <PieChart width={650} height={350}>
                        <Pie
                            data={formattedData}
                            labelLine={false}
                            innerRadius={70}
                            fill="#8884d8"
                            dataKey="value"
                            paddingAngle={2}
                            cornerRadius={10}
                        >
                            {formattedData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.fill} />
                            ))}
                        </Pie>
                        <text
                            x={240}
                            y={180}
                            textAnchor="middle"
                            dominantBaseline="middle"
                            fill="black"
                        >
                            Closed Tickets 532
                        </text>
                        <Legend
                            layout="vertical"
                            verticalAlign="middle"
                            align="right"
                            content={<CustomLegend />}
                        />
                    </PieChart>
                </WizrFlexLayout>
                <WizrFlexLayout background="#FFF" alignItems="flex-start" style={{ padding: '16px', gap: '8px' }}>
                    <WizrFlexLayout flexDirection="row" background="#FFF" style={{ flex: 1 }}>
                        <WizrText type="sub2">First Contact Resolution Time</WizrText>
                        <WizrButton type='text' color="#999B9F" text='View All' onClick={() => { }} style={{ borderRadius: '12px' }} />
                    </WizrFlexLayout>
                    <Dropdown trigger={['click']} menu={{ items: [], selectable: true }} >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space style={{ color: '#707276' }}>
                                <span>{'Category'}</span>
                                <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>
                    <BarChart
                        width={650}
                        height={350}
                        data={overallTickets}
                        layout="vertical" // Set layout to vertical
                        margin={{ top: 20, right: 30, left: 30, bottom: 5 }}
                    >
                        <XAxis type="number" stroke="transparent" padding={{ left: 10 }} />
                        <YAxis dataKey="name" type="category" stroke="transparent" tick={<CustomizedAxisTick />} />
                        <Label style={{
                            textAnchor: "middle",
                            fontSize: "130%",
                            fill: "white",
                        }} />
                        <Bar dataKey="value" fill="#8884d8" label={{ position: 'right' }} barSize={25} >
                            {data.map((entry: any, index: any) => (
                                <Cell key={`cell-${index}`} fill={contactResolutionColors[index % 20]} />
                            ))}
                        </Bar>
                    </BarChart>
                </WizrFlexLayout>
            </WizrFlexLayout>
        </WizrFlexLayout>
    );
}

export default TicketResolutionAnalytics;