import { Popover } from 'antd'
import MoreIcon from '../../assets/icons/More'
import WizrButton from '../WizrButton'
import WizrFlexLayout from '../WizrFlexLayout'
import EditIcon from '../../assets/icons/EditIcon'
import DeleteIcon from '../../assets/icons/DeleteIcon'
import styled from 'styled-components'

const ExampleActionPopOverContent = ({ onEditAction, onDeleteAction }: any): JSX.Element => {
  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      <WizrButton
        icon={<EditIcon />}
        text='Edit'
        type='text'
        onClick={onEditAction}
        justifyContent='flex-start'
      />
      <WizrButton
        icon={<DeleteIcon />}
        text='Delete'
        type='text'
        onClick={onDeleteAction}
        justifyContent='flex-start'
      />
    </WizrFlexLayout>
  )
}

const ExampleActionPopover = ({ onEditAction, onDeleteAction }: any): JSX.Element => {
  return (
    <Popover
      trigger='click'
      zIndex={1}
      content={
        <ExampleActionPopOverContent onEditAction={onEditAction} onDeleteAction={onDeleteAction} />
      }
    >
      <StyledButton icon={<MoreIcon />} text='' type='text' style={{height: '40px', width: '40px', borderRadius: '50px'}}/>
    </Popover>
  )
}

export default ExampleActionPopover

const StyledButton = styled(WizrButton)`
border-radius: 50%;
width: 20px;
height: 20px;
padding-right: 8px;
justify-content: center;
align-items: center;
  /* Default styles */
  path {
    stroke: #999B9F; /* Default stroke color */
    transition: stroke 0.3s ease; /* Smooth transition for color change */
  }

  /* Hover styles */
  &:hover path {
    stroke: #1D1F22
  }
`;
