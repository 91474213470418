import NodeContainer from './NodeContainer'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import WizrText from '../../../components/WizrText'
import WizrDropDown from '../../../components/WizrDropDown'
import WizrTagInput from '../../../components/WizrTagInput'
import { usePromtTemplateSelector, useContentsSelector } from '../../../redux/selectors/content'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrInput from '../../../components/WizrInput'
import WizrTag from '../../../components/WizrTags'
import WizrFlexSpacing from '../../../components/WizrFlexSpacing'
import { useEdges, useNodes } from 'reactflow'
import FolderIcon from '../../../assets/icons/Folder'
import WizrToggleButton from '../../../components/WizrToggleButton'
import { generateNodeSequenceResponse } from '../../../utils'
import { getConnectedEdges } from 'react-flow-renderer';


const ParametersList = ({ data, startNodeData, keyMappings, model_type, isEditMode, nodeSequence, label }: any): JSX.Element => {
  
  const trimmedNodeSequence = elementsBeforeSpecificElement(nodeSequence, label)
  
  const parameterList1 = startNodeData?.data?.config ? startNodeData?.data?.config?.parameters?.map((param: any) => param?.name) : []
  const parameterList = [...parameterList1, ...trimmedNodeSequence]  
  
  const [mergedParameters, setMergedParameters] = useState([...(data?.config?.prompt_config?.prompt_body?.message_params || [])])
  
  const[parameterInputState, setParameterInputState ] = useState<{[key: string]: string}>({})

  function elementsBeforeSpecificElement(arr: any, specificElement: any) {
    const index = arr.indexOf(specificElement);
    if (index === -1) {
      return arr;
    }        
    const newArray = arr.slice(0, index).slice(1).map((item: any) => {
       return `${item}.output`
    })
    return newArray

  }  
  

  useEffect(() => {
      
    if( model_type === 'chatcompletion' && mergedParameters && !mergedParameters.some((item: any) => item.name === 'user_query')){  
      const data1 = [...(data?.config?.prompt_config?.prompt_body?.message_params || [])]      
      const newdata = { name: 'user_query', type: 2, value: '' }
      const newdata2 = [...data1, newdata]
      setMergedParameters(newdata2);     

    }else {
      const data1 = [...(data?.config?.prompt_config?.prompt_body?.message_params || [])]
      setMergedParameters(data1);
    }

  }, [model_type, data?.config?.prompt_config?.prompt_body?.message_params]);

  
  useEffect(() => {
    // to map default default keymapping values to the parameters
    let params =  {...parameterInputState}
    mergedParameters?.map((param: any) => {
      const keyToGet = `{{${param?.name}}}`
      params = {...params, [`{{${param?.name}}}`]: data?.config?.key_mapping?.[keyToGet] || '' } 
    })        
    setParameterInputState(params)      

  }, [data, mergedParameters])

  const onParameterUserInput = (key: string, value: string) => {
    setParameterInputState({...parameterInputState, [`{{${key}}}`]: value })
    keyMappings({...parameterInputState, [`{{${key}}}`]: value })
  }

  const onParameterUserSelect = (key: string, value: string) => {    
    setParameterInputState({...parameterInputState, [`{{${key}}}`]: parameterInputState[`{{${key}}}`] ? parameterInputState[`{{${key}}}`] + `{{${value}}}` : `{{${value}}}`})
    keyMappings({...parameterInputState, [`{{${key}}}`]: parameterInputState[`{{${key}}}`] ? parameterInputState[`{{${key}}}`] + `{{${value}}}` : `{{${value}}}`})
  }  

  return (
    <>    
      {mergedParameters?.map((param: any) => {
        const keyToGet = `{{${param?.name}}}`      
        
        return <>
          <WizrText type='body2' textColor='#707276'>{param?.name}</WizrText>
          <WizrInput placeholder={param?.name} onChange={(text: string) => onParameterUserInput(param?.name, text)} value={parameterInputState[keyToGet]} disabled={isEditMode}/>
          <StyledFlexSpacing
            justifyContent='space-between'
            flexDirection='column'
            alignItems='center'
            size={20}
          >
            {!isEditMode?
            <StyledWizrTag 
            background="grey"
              data={parameterList}
              onClick={(e: any) => {
                
                onParameterUserSelect(param?.name, e)}}
            />:<></>}
          </StyledFlexSpacing>
        </>
      })}
    </>
  )
}

const LLMNode = (props: any): JSX.Element => {

  const { id, updateNodeData, data, updateEdgeData, onEdit, onCopyNodeDetail, isDisabled } = props      
  
  const nodes = useNodes()
  const edges = useEdges()
  const model_type = data?.config?.prompt_config?.prompt_body?.model_type  
  const [nodeSequence, setNodeSequence] = useState<any>([])  

  useEffect(() => {
    const saveAppPayload = {
      edges: edges,
      nodes: nodes,
    }  
    setNodeSequence(generateNodeSequenceResponse(saveAppPayload))
  }, [nodes, edges])
  
  const publishedPrompts = usePromtTemplateSelector()
  const availableFoldersList = useContentsSelector()
  const [selectedPrompt, setSelectedPrompt] = useState<any>(data?.config?.prompt_config)
  const [selectedKnoledgeBase, setSelectedKnowledgeBase] = useState<any>(data?.config?.llm_folder_id_list ? data?.config?.llm_folder_id_list : [])
  const [memory,setmemory] = useState(false)
  const [keyMapping, setKeyMapping] = useState<any>()

  const [isEditView, setIsEditView] = useState(false)

  const updateSelectedPrompt = (value: any) => {
    
    const promptDetails = publishedPrompts?.filter((prompt: any) => prompt?.id === value)?.[0]
    
      updateNodeData((nds: any) =>
        nds.map((node: any) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              config: {
                ...node.data.config,
                prompt_config: promptDetails,
                
              },
            }
          }
          return node
        }),
      )
      
      setSelectedPrompt(promptDetails)
    const folderIdList = promptDetails?.prompt_body?.folder_id_list; 

    // const folderNames = availableFoldersList
    // .filter((item: any) => promptDetails?.prompt_body?.folder_id_list.includes(item.folder_id)).map((element: any) => element.folder)
    setSelectedKnowledgeBase(folderIdList)    
  }

  

  const updateSelectedKnowledgeBase = (value: any) => {    
    setSelectedKnowledgeBase(value)
  }

  const updateCurrentNodeData = () => {
    updateNodeData((nds: any) =>
      nds.map((node: any) => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            config: {
              memory: memory,
              prompt_config: selectedPrompt,
              llm_folder_id_list: selectedKnoledgeBase,
              key_mapping : keyMapping,
            },
          }
        }
        return node
      }),
    )
    setIsEditView(!isEditView)
  }  

  const keyMappings = (userInput: any) => {
        
    setKeyMapping(userInput)
    updateNodeData((nds: any) =>
      nds.map((node: any) => {
        if (node.id === id) {
          
          node.data = {
            ...node.data,
            config: {
              ...node.data.config,
              key_mapping : userInput ? userInput : {}
            },
          }
          
        }
        return node
      }),
    )
  }

  const startNodeData = nodes?.filter((node: any) => node?.id === 'start')?.[0]  

  


  const handleNameUpdate = (itemId: any, newName: any) => {

      updateNodeData((nds: any) =>
        nds.map((node: any) => {
          if (node.id === itemId) {
            node.data = {
              ...node.data,
              label: newName,
            }
          }
          return node
        }),
      )

  };

  const filterNameFromKnowledgeBase = (item: string) => {
    
    // const folderNames = availableFoldersList.filter((item: any) => selectedKnoledgeBase.map((id:any)=>item.folder_id ===id))
    const filteredArray = availableFoldersList.filter(itemA => selectedKnoledgeBase.includes(itemA.folder_id));
    const filteredNames = filteredArray.map(item => item.folder_name); 

    return filteredNames
  }

  const onDeleteNodeDetail = (e: any) => {  
    const objectWithId: any = nodes && nodes.find((obj: any) => obj.id === e);    
    const connectedEdges = getConnectedEdges([objectWithId], edges);  
      
    if (connectedEdges.length > 0) {
      let updatedEdges = edges.filter((edge) => !connectedEdges.includes(edge));
    
      if (connectedEdges.length > 1) {
        const newEdge = {
          id: `edge_${connectedEdges[0].source}-${connectedEdges[1].target}`,
          source: connectedEdges[0].source,
          target: connectedEdges[1].target,
          type: 'buttonedge',
        };
    
        updatedEdges = updatedEdges.concat([newEdge]);
      }
    
      updateEdgeData(updatedEdges);
    }
    updateNodeData((nds: any) => nds.filter((node: any) => node.id !== e));

  }   

  return (
    <NodeContainer
      item={{ nodeTitle: data?.label, id: id }}
      data={data}
      isEditMode={isEditView}
      saveCurrentAction={updateCurrentNodeData}
      onEditNodeDetail={() => { onEdit(data?.label, props)}}
      onUpdateName={(newName: any) => handleNameUpdate(id, newName)}
      onDeleteNodeDetail={onDeleteNodeDetail}
      onCopyNodeDetail={() => {onCopyNodeDetail(data, 'llm')}}
      isDisabled={isDisabled}
      nodeType = {'5'}
    >
      <LLMContainer alignItems='flex-start'>
        {isEditView ? (
          <>
            <WizrText type='body2'>Prompt</WizrText>
            <WizrDropDown
              placeHolder='Select one option'
              value={selectedPrompt?.name}
              options={publishedPrompts?.sort((a:any, b:any) => b.updated_on - a.updated_on)?.map((item: any) => ({              
                label: item?.name,
                value: item?.id,
              }))}
              onChange={updateSelectedPrompt}
              className="nodrag nopan"
            />
            {selectedPrompt?.prompt_body?.knowledgebase_required && (
              <>
            <WizrText type='body2'>Knowledge Base</WizrText>
            <WizrTagInput
              options={availableFoldersList?.map((item: any) => ({
                label: item.folder_name,
                value: item.folder_id,
              }))}
              value={selectedKnoledgeBase}
              onChange={updateSelectedKnowledgeBase}
              placeHolder='Select folder(s)'
            />
            </>)}
            <WizrFlexLayout flexDirection='row'>
              <WizrText type="body2">Memory</WizrText>
              <WizrToggleButton 
              isEnabled={memory}
              onChange={(e:boolean)=> setmemory(e)}/>

           
            </WizrFlexLayout>
            {/* <ParametersList data={data} startNodeData={startNodeData} keyMappings={keyMappings} model_type={model_type} isEditMode={false} nodeSequence={nodeSequence} label={id}/>  */}

          </>
        ) : (
          <>
            <WizrText type='body3' textColor='#707276'>
              Prompt
            </WizrText>
            <WizrText type='body3'>{data?.config?.prompt_config?.name}</WizrText>

             <WizrText type='body3' textColor='#707276'>
              Knowledge Base
            </WizrText>
           
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {selectedKnoledgeBase.map((item: any, index: number) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                  <FolderIcon />
                  <WizrText type='body3'>
                    {filterNameFromKnowledgeBase(item)[index]}
                  </WizrText>
                </div>
              ))}
            </div>
            <WizrFlexLayout flexDirection='row'>
              <WizrText type="body2">Memory</WizrText>
              <WizrToggleButton 
              isEnabled={memory}
              />

           
            </WizrFlexLayout>
            { model_type === 'chatcompletion' ? <>
          <WizrText type='body2' textColor='#707276'>User_query</WizrText>
          <WizrInput placeholder='User_query' disabled={true}/>
 
        </> : <></>}
            {/* <ParametersList data={data} startNodeData={startNodeData} keyMappings={keyMappings} model_type={model_type} isEditMode={true} nodeSequence={nodeSequence} label={id}/>  */}
          </>
        )}
      </LLMContainer>
    </NodeContainer>
  )
}

const LLMContainer = styled(WizrFlexLayout)`
  gap: 16px;
`

const StyledWizrTag = styled(WizrTag)`
  height: 42px;
  padding: 8px 11px;
  justify-content: space-between;
`

const StyledFlexSpacing = styled(WizrFlexSpacing)`
  .ant-space-item {
    display: flex !important;
    width: 100% !important;
    flex-wrap: wrap;
  }
`

export default LLMNode
