import { Layout, Form } from 'antd'
import WizrInput from '../components/WizrInput'
import WizrButton from '../components/WizrButton'
import styled from 'styled-components'
import WizrText from '../components/WizrText'
import WizrFlexLayout from '../components/WizrFlexLayout'

const FormContainer = styled(Layout)`
  justify-content: center;
  align-items: center;
  align-content: center;
  background: transparent;
`

const ForgotPassword = (): JSX.Element => {
  return (
    <WizrFlexLayout background='#FFF'>
      <FormContainer>
        <WizrText type='h6'>Forgot Password?</WizrText>
        <WizrText type='body1'>
          <br />
          Having trouble accessing your account?
          <br />
          No Worries! We’re here to help you regain access.
        </WizrText>
        <WizrText type='body2'>
          <br />
          Please provide email address associated with your account. <br />
          We will send you a link to reset your password.
        </WizrText>
        <br />
        <Form name='basic' initialValues={{ remember: true }} autoComplete='off'>
          <Form.Item name='username' rules={[{ required: true, message: 'Username' }]}>
            <WizrInput placeholder='Email Address' />
          </Form.Item>

          <Form.Item
            name='password'
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <WizrFlexLayout>
              <WizrButton text='Send link' />
            </WizrFlexLayout>
          </Form.Item>
        </Form>
      </FormContainer>
    </WizrFlexLayout>
  )
}

export default ForgotPassword
