import GoogleIcon from '../../assets/icons/GoogleIcon'
import WizrFlexLayout from '../WizrFlexLayout'
import WizrText from '../WizrText'
import WizrAvatar from '../WizrAvatar'
import FaceBookIcon from '../../assets/icons/FaceBookIcon'
import WizrTag from '../WizrTags'
import styled from 'styled-components'

const CustomerHealthCustomerCard = ({ details }: any): JSX.Element => {
  const getIcon = (companyName: any) => {
    switch (companyName) {
      case 'Google':
        return <GoogleIcon />
      case 'Facebook':
        return <FaceBookIcon />
      default:
        return (
          <WizrAvatar
            name={companyName}
            background='transparent'
            showText={false}
            justifyContent='left'
          />
        )
    }
  }
  return (
    <WizrFlexLayout
      flexDirection='column'
      background='white'
      justifyContent='flex-start'
      style={{ gap: '5px' }}
    >
      <WizrFlexLayout
        flexDirection='row'
        justifyContent='flex-start'
        style={{ gap: '10px' }}
        alignItems='center'
        background='white'
      >
        {getIcon(details?.customer)}
        <WizrText type='sub1'> {details?.customer}</WizrText>
        {details?.health < 50 ? (
          <WizrTag
            padding='0px 5px'
            textType='tagData'
            marginTop='0'
            background='#EF4444'
            data={['CHURN RISK']}
            borderradius='4px'
          />
        ) : (
          <></>
        )}
      </WizrFlexLayout>

      <WizrFlexLayout
        justifyContent='flex-start'
        alignItems='center'
        flexDirection='row'
        style={{ gap: '10px' }}
        background='white'
      >
        <WizrText type='caption2'>Total Tickets:</WizrText>
        <WizrText type='body2' textColor='#000000'>
          {details?.total_tickets}
        </WizrText>
      </WizrFlexLayout>

      <WizrFlexLayout
        justifyContent='flex-start'
        alignItems='center'
        flexDirection='row'
        style={{ gap: '10px' }}
        background='white'
      >
        <WizrText type='caption2'>Open Tickets:</WizrText>
        <WizrText type='body2' textColor='#000000'>
          {details?.open_tickets}
        </WizrText>
      </WizrFlexLayout>

      <WizrFlexLayout
        justifyContent='flex-start'
        alignItems='center'
        flexDirection='row'
        style={{ gap: '10px' }}
        background='white'
      >
        <WizrText type='caption2'>Escalations:</WizrText>
        <WizrText type='body2' textColor='#000000'>
          {details?.escalation}
        </WizrText>
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

export default CustomerHealthCustomerCard
