const Category = ({stroke='#999B9F'}):JSX.Element => {
    return(
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5 10.4453C19.1569 10.4453 20.5 9.10217 20.5 7.44531C20.5 5.78846 19.1569 4.44531 17.5 4.44531C15.8431 4.44531 14.5 5.78846 14.5 7.44531C14.5 9.10217 15.8431 10.4453 17.5 10.4453Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.5 20.4453C9.15685 20.4453 10.5 19.1022 10.5 17.4453C10.5 15.7885 9.15685 14.4453 7.5 14.4453C5.84315 14.4453 4.5 15.7885 4.5 17.4453C4.5 19.1022 5.84315 20.4453 7.5 20.4453Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.5 14.4453H20.5V19.4453C20.5 19.7105 20.3946 19.9649 20.2071 20.1524C20.0196 20.34 19.7652 20.4453 19.5 20.4453H15.5C15.2348 20.4453 14.9804 20.34 14.7929 20.1524C14.6054 19.9649 14.5 19.7105 14.5 19.4453V14.4453ZM4.5 4.44531H10.5V9.44531C10.5 9.71053 10.3946 9.96488 10.2071 10.1524C10.0196 10.34 9.76522 10.4453 9.5 10.4453H5.5C5.23478 10.4453 4.98043 10.34 4.79289 10.1524C4.60536 9.96488 4.5 9.71053 4.5 9.44531V4.44531Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    )
}
export default Category;