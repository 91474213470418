import { Drawer, Menu } from "antd";

// import EditSquare from "../assets/icons/EditSquare";
// import WizrIcon2 from "../assets/icons/WizrIcon2";
import styled from "styled-components";
import WizrFlexLayout from "../../../../../components/WizrFlexLayout";
import WizrText from "../../../../../components/WizrText";
import WizrButton from "../../../../../components/WizrButton";
import EditSquare from "../../../../../assets/icons/EditSquare";
import WizrIcon from "../../../../../components/WizrIcon";
import WizrIconLogo from "../../../../../assets/icons/WizrIcon";

const StyledMenu = styled(Menu)`
  margin-top: 0.625rem !important; // 10px
  .ant-menu-item {
    padding: 0.3125rem !important; // 5px
  }
  .ant-menu-item-selected {
    background-color: #f2f4f9 !important;
  }
`;
 const width :any= window.innerWidth;
 let DrawerWidth:any;

 switch (true) {
   case width > 800:
     DrawerWidth = "40%";
     break;
   case width >= 500 && width <= 800:
     DrawerWidth = "50%";
     break;
   case width < 500:
     DrawerWidth = "75%";
     break;
   default:
     DrawerWidth = "75%"; // Optional: if you want a fallback default
     break;
 }
 
 console.log("WebFormatLargeDrawer.tsx",window.innerWidth);
 console.log("WebFormatLargeDrawer.tsx",DrawerWidth);
const WebFormatLargeDrawer = ({open,setOpen,onNewSearchClick,isStreaming,onClick,conversationId,conversationIdList,logo}:any) => {
return(
    <Drawer
    placement={"left"}
    width={DrawerWidth}
    open={open}
    onClose={() => {setOpen(!open)}}
  >
   <WizrFlexLayout
    background="#F9FAFA"
    style={{
      gap: "2.5rem",
      minHeight: "100vh",
    }}
    textAlign="left"
  >
    {/* <WizrIcon /> */}
    <img src={logo} alt="Wizr" style={{ width: "8.75rem", height: "3.125rem" }} />
    <WizrFlexLayout
      background="#F9FAFA"
      style={{ maxHeight: "4.375rem", gap: "0rem" }}
      overFlow="hidden"
    >
      <WizrFlexLayout
        justifyContent="center"
        background="#F9FAFA"
        overFlow="hidden"
        style={{ position: "relative", left: "9%" }}
      >
        <WizrButton
          type="link"
          text={
            <WizrFlexLayout
              flexDirection="row"
              background="#F9FAFA"
              justifyContent="space-evenly"
              alignContent="center"
              style={{gap:"5%"}}
              overFlow="auto"
              >
              <WizrText type="body1" textColor="black">
                New Search
              </WizrText>
              <EditSquare />
            </WizrFlexLayout>
          }
          style={{
            borderWidth: "0.0625rem",
            borderColor: "#E5E7EB",
            borderRadius: "1rem",
            width: "80%",
            padding: "0.625rem 1.25rem",
          }}
          onClick={() => {
            onNewSearchClick();
            setOpen(!open);
          }}
          disabled={isStreaming}
        ></WizrButton>
      </WizrFlexLayout>
      <WizrFlexLayout
        flexDirection="row"
        background="#F9FAFA"
        justifyContent="center"
        alignItems="center"
        style={{ gap: "0.25rem", maxHeight: "3.125rem" }}
        >
        <WizrText type="body3">Powered by</WizrText>
        <WizrIconLogo />
      </WizrFlexLayout>
    </WizrFlexLayout>

    <WizrFlexLayout background="#F9FAFA" textAlign="left">
      <div
       style={{
        borderBottom: "0.0625rem solid #E5E7EB",
        paddingBottom: "0.3125rem",
        marginBottom: "1.25rem",
        display: "flex",
        flexDirection: "column",
        gap: "1.25rem",
      }}
      >
        <WizrText type="sub1" textColor="black">
          Conversations
        </WizrText>
        <StyledMenu
          onClick={onClick}
          style={{ width: "100%" }}
          selectedKeys={[conversationId]} // Set selectedKeys
          mode="inline"
          items={conversationIdList}
          disabled={isStreaming}
        />
      </div>
    </WizrFlexLayout>
  </WizrFlexLayout>
  </Drawer>
)
}

export default WebFormatLargeDrawer;