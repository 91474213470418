import { useLocation, useNavigate, useParams } from 'react-router-dom'
import WizrButton from '../../../components/WizrButton'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrText from '../../../components/WizrText'
import WizrUserJourney from '../../../components/WizrUserJourney'
import { icon } from '../../../icons/svgr.config'
import { ArrowLeftOutlined } from '@ant-design/icons'
import Hamburger from '../../../assets/icons/Hamburger'
import CreateNew from '../../../assets/icons/CreateNew'
import WizrTable from '../../../components/WizrTable'
import { useFeatureSettingSelector } from '../../../redux/selectors/content'
import {
  dispatchAppShareDetails,
  dispatchCreateFolderGroup,
  dispatchFeatureSettings,
} from '../../../redux/actions/content'
import { MODAL_TYPES, useWizrModalContext } from '../../../providers/modal/WizrModalProvider'
import { useEffect } from 'react'
import { useAuth } from '../../../authContext'

const GroupListing = ():JSX.Element=> {
    useEffect(() => { 
        dispatchFeatureSettings()
       },[])
  const { showModal, hideModal } = useWizrModalContext()
  const params = useParams()
  const navigate = useNavigate()
  const { userPermission, hasAcces } = useAuth();

  const fetaureData = useFeatureSettingSelector()
  const type = params?.title
  const featureType = fetaureData?.ticket_settings

  const foldersArray: any = featureType &&  Object.values(featureType).filter((item: any) => {
    if (item.ticket_type === type ) {
      return item.folders
    }
  })
  const data = foldersArray && foldersArray[0]?.folders?.map((item: any) => ({
    ...item,
    group: foldersArray[0]?.ticket_type,
  }))
//   const activeData =  foldersArray && foldersArray[0]?.folders?.filter((item: any) => {
// if(item?.status!=='deleted'){
//   return {
//     ...item,
//     group: foldersArray[0]?.ticket_type,
//   }
// }
//   })
const dataFinal  = data && data.filter((item:any)=>{
  if(item?.status==="active"){
    return item
  }
 
})
  const createNewFolder = () => {
    let type: string
    switch (foldersArray[0]?.ticket_type) {
      case 'categories_intent':
        type = 'categories'
        break
      case 'sentiment':
        type = 'sentiments'
        break
      case 'tags':
        type = 'tags'
        break
      case 'priority':
        type = 'ticket-priority'
        break
      case 'type':
        type = 'ticket-type'
        break
    }
    showModal(MODAL_TYPES.CREATE_GROUP, {
      onFormSubmission: async ({ currentGroupName }: any) => {
        const payload = {
          id: fetaureData?.id,
          data: {
            folder_name: currentGroupName,
            status: 'active',
          },
        }
        await dispatchCreateFolderGroup(payload, type)
        dispatchFeatureSettings()
        hideModal()
      },

      onCancel: hideModal,
    })
  }
 

  return (
    <WizrFlexLayout alignContent='flex-start'>
      <WizrFlexLayout justifyContent='space-between' flexDirection='row' flex={1.4} style={{paddingBottom:'0px'}} >
        <WizrUserJourney
          userJourney={[
            {
              title: (
                <WizrButton
                  padding='0px'
                  type='text'
                  text={<WizrText type='h6' textColor='#707276'>Cx Settings</WizrText>}
                  onClick={() => navigate(-1)}
                  icon={<Hamburger />}
                />
              ),
            },
            {
              title: (
                <WizrButton
                  padding='0px'
                  type='text'
                  text={<WizrText type='h6'>{foldersArray && foldersArray[0]?.display_name}</WizrText>}
                  onClick={() => {}}
                />
              ),
              isCurrent: true,
            },
          ]}
        />
        <WizrButton
          type='primary'
          icon={<CreateNew fill={'white'} />}
          style={{ marginTop: '10px', borderRadius: '25px' }}
          text={<WizrText type='button'>{`Add Group`}</WizrText>}
          onClick={() => {
            createNewFolder()
          }}
          disabled={!hasAcces('create_cxsettings_ticketgroup')}
        >
          {' '}
        </WizrButton>
      </WizrFlexLayout>
      <WizrFlexLayout flex={11} >
        <WizrTable type='grouplisting' dataSource={dataFinal && dataFinal.sort((a:any,b:any)=>b.created_on-a.created_on)} pagination={false} size='small' />
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}
export default GroupListing
