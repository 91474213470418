import { GroupOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";
import WizrFlexLayout from "../../../components/WizrFlexLayout";
import WizrText from "../../../components/WizrText";
import WizrDropDown from "../../../components/WizrDropDown";
import { useVisionModelSelector } from "../../../redux/selectors/content";
import WizrInput from "../../../components/WizrInput";
import WizrFlexSpacing from "../../../components/WizrFlexSpacing";
import WizrTag from "../../../components/WizrTags";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import WizrButton from "../../../components/WizrButton";
import WizrToggleButton from "../../../components/WizrToggleButton";
import { useEdges, useNodes } from "reactflow";
import { generateNodeSequenceResponse, getTenantId } from "../../../utils";
import { dispatcVisionMModels, dispatchVoiceModels } from "../../../redux/actions/content";
import { Tooltip } from "antd";
import InfoIcon from "../../../assets/icons/Info";
import WizrSlider from "../../../components/WizrSlider";
import RadioGroup from "../../../components/WizrRadiogroup";
import WizrTextArea from "../../../components/WizrTextArea";

const VisionContainer = styled(WizrFlexLayout)`
  gap: 16px;
  overflow: auto;
  margin-bottom: 90px;
`
const StyledFlexSpacing = styled(WizrFlexSpacing)`
  .ant-space-item {
    display: flex !important;
    width: 100% !important;
    flex-wrap: wrap;
  }
`
const StyledWizrTag = styled(WizrTag)`
  height: 42px;
  padding: 8px 11px;
  justify-content: space-between;
`

const VoiceBlockEdit = (props: any): JSX.Element => {

  const { id, dataCode, updateNodeData, data, nodeUpdate, closeEditArea } = props
  const [selectedModel, setSelectedModel] = useState<any>("")
  const [selectedLanguage, setSelectedLanguage] = useState<any>("")
  const [parameterList, setParameterList] = useState<any>()
  const [Diarize, setDiarize] = useState<any>(false)
  const [Punctuate, setPunctuate] = useState<any>(false)
  const [Utterance, setUtterance] = useState<any>(false)
  const [Summarize, setSummarize] = useState<any>(false)
  const [Topics, setTopics] = useState<any>(false)
  const [modelSetting, setModelSetting] = useState<any>([])
  const [temperature, setTemperature] = useState<any>([])
  const [action, setAction] = useState<any>([])
  const [prompt, setPrompt] = useState<any>([])  

  const voiceModelData = useVisionModelSelector()

  const { register, control, setValue, getValues } = useForm({
  });
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "voiceBlock"
  });

  const edges = useEdges()
  const nodes = useNodes()


  const startnodedata: any = nodes && nodes[0]?.data


  useEffect(() => {
    const tenantId = getTenantId()
    dispatchVoiceModels()
  }, [])

  useEffect(() => {
    const saveAppPayload = {
      edges: edges,
      nodes: nodes,
    };
    const sequence = generateNodeSequenceResponse(saveAppPayload);
    const trimmedNodeSequence = elementsBeforeSpecificElement(sequence, id);
    const defaultParameters = startnodedata?.config?.parameters.map((param: any) => param?.name) || []
    setParameterList((prevList: any) => [...defaultParameters, ...trimmedNodeSequence]);

  }, [nodes, edges, setParameterList, id]);

  function elementsBeforeSpecificElement(arr: any, specificElement: any) {
    const index = arr.indexOf(specificElement);
    if (index === -1) {
      return arr;
    }
    const newArray = arr.slice(0, index).slice(1).map((item: any) => {
      return `${item}.output`
    })
    return newArray

  }

  const findModalId = (modelType: any) => {
    const modelId = voiceModelData?.find((item: any) => item.display_name === modelType)
    return modelId?.id
  }

  const findModalName = (id: any) => {
    const modelName = voiceModelData?.find((item: any) => item.id === id)
    return modelName?.display_name
  }


  const drodownConverter = () => {
    const normalOptions = voiceModelData?.map((item: any) => {
      return { label: item.display_name, value: item.display_name, model_type: item.llm_type }
    })
    return normalOptions
  }


  useEffect(() => {

    setPunctuate(data?.config?.punctuate ? data?.config?.punctuate : false)
    setUtterance(data?.config?.Utterance ? data?.config?.Utterance : false)
    setSelectedLanguage(data?.config?.language ? data?.config?.language : 'en')
    setSelectedModel(data?.config?.model_name ? data?.config?.model_name : '')
    setDiarize(data?.config?.multiple_speakers ? data?.config?.multiple_speakers : false)
    setTopics(data?.config?.topics_required ? data?.config?.topics_required : false)
    setAction(data?.config?.action ? data?.config?.action : 'translate')
    setPrompt(data?.config?.prompt ? data?.config?.prompt : '')
    setTemperature(data?.config?.temperature ? data?.config?.temperature : 0)
    const newArray = data?.config?.files && data?.config?.files.map((value: any, index: any) => ({ file: value }));
    if (newArray) {
      setValue("voiceBlock", newArray);
    }
    // setSelectedModelName(data?.config?.transcription_model_id ? data?.config?.transcription_model_id : '')
    if (data?.config?.transcription_model_id) {
      const selected = voiceModelData?.filter((item: any) => item?.id === data?.config?.transcription_model_id)

      setModelSetting(selected[0]?.llm_settings)
    }
  }, [data])

  const updateCurrentNodeData = () => {

    const fieldArrayFormValues = getValues().voiceBlock ? getValues().voiceBlock : []

    // const config = {
    //   model_name: selectedModel,
    //   punctuate: Punctuate,
    //   Utterance: Utterance,
    //   multiple_speakers: Diarize,
    //   topics_required: Topics,
    //   language: selectedLanguage,
    //   llm_type: 'deepgram',
    //   transcription_model_id: findModalId(selectedModel),
    //   files: fieldArrayFormValues?.length > 0 ? fieldArrayFormValues.map((item: any) => item.file) : [],
    // }

    let config = {}

    if( selectedModel === 'Azure Whisper v2-large'){
      config = {
        model_name: selectedModel,
        action: action,
        transcription_model_id: findModalId(selectedModel),
        files: fieldArrayFormValues?.length > 0 ? fieldArrayFormValues.map((item: any) => item.file) : [],
        temperature: temperature,
        prompt: prompt,
        llm_type: "azureopenai"
      }
    }else{
      config = {
        model_name: selectedModel,
        punctuate: Punctuate,
        Utterance: Utterance,
        multiple_speakers: Diarize,
        topics_required: Topics,
        language: selectedLanguage,
        llm_type: 'deepgram',
        transcription_model_id: findModalId(selectedModel),
        files: fieldArrayFormValues?.length > 0 ? fieldArrayFormValues.map((item: any) => item.file) : [],
      }
    }

    updateNodeData((nds: any) =>
      nds.map((node: any) => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            config
          }
        }
        return node
      }),
    )
    closeEditArea()
  }

  const onDeleteNodeDetail = (e: any) => {
    updateNodeData((nds: any) =>
      nds.filter((node: any) => node.id !== e)
    );
  }


  const toggleSettings = [
    'Diarize', 'Punctuate', 'Utterance', 'Summarize', 'Topics'
  ]

  const setToggleSetting = (e: boolean, item: any) => {
    switch (item) {
      case "diarize":
        setDiarize(e)
        // setRadio({'event': e, 'state': item})
        break
      case "punctuate":
        setPunctuate(e)
        break
      case "utterance":
        setUtterance(e)
        break
      case "summarize":
        setSummarize(e)
        break
      case "topics":
        setTopics(e)
        break
    }
  }
  const findName = (name: any) => {
    switch (name) {
      case "diarize":
        return "Diarize"

      case "punctuate":
        return "Punctuate"

      case "utterance":
        return "Utterance"

      case "summarize":
        return "Summarize"

      case "topics":
        return "Topics"

    }
  }

  const findState = (name: any) => {
    switch (name) {
      case "diarize":
        return Diarize

      case "punctuate":
        return Punctuate

      case "utterance":
        return Utterance

      case "summarize":
        return Summarize
      case "topics":
        return Topics

    }
  }

  const languageOptions = [
    {
      label: 'English',
      value: 'en'
    },
    {
      label: 'Hindi',
      value: 'hi'
    },
  ]

  useEffect(() => {
    if (nodeUpdate === 'transcription') {
      updateCurrentNodeData()
    }
  }, [nodeUpdate])

  const selectModel = (value: any) => {
    setSelectedModel(value)
    const selected = voiceModelData?.filter((item: any) => item?.display_name === value)
    setModelSetting(selected[0]?.llm_settings)
  }

  const handleRadioChange = (e: any) => {
    setAction(e.target.value)
  }

  return (
    <VisionContainer alignItems='flex-start' background="#fff">
      <>
        <WizrText type='body2'>Model</WizrText>
        <WizrDropDown
          placeHolder='Select one option'
          value={selectedModel}
          options={drodownConverter()}
          onChange={(value: any) => { selectModel(value) }}
          className="nodrag nopan"
        />

        {modelSetting?.map((item: any, index: any) => {
          return (
            (item?.key === "language") &&
            (<>
              <WizrText type='body2'>Languages</WizrText>
              <WizrDropDown
                placeHolder='Select one option'
                value={selectedLanguage}
                options={languageOptions}
                onChange={(value: any) => { setSelectedLanguage(value) }}
                className="nodrag nopan"
              /></>

            )
          )
        })}
        { selectedModel !== 'Azure Whisper v2-large' ? modelSetting?.map((item: any, index: any) => {
          return (
            (item?.key !== "language") &&
            (<WizrFlexLayout flexDirection="row" alignContent="space-between" background="#fff" key={index}>
              <WizrText type="body2">{findName(item?.key)}</WizrText>
              <WizrToggleButton onChange={(e: boolean) => setToggleSetting(e, item?.key)}
                isEnabled={findState(item?.key)}></WizrToggleButton>
            </WizrFlexLayout>)
          )
        }) : <>
        <IndividualSettingsBox style={{margin:"2px"}}>
        <WizrFlexLayout
          flexDirection='row'
          justifyContent='space-between'
          background='white'
          flex='none'
          style={{width:"98%"}}
        >
          <WizrText type='body2'>Temperature</WizrText>
        </WizrFlexLayout>
        <IndividualLlmSettingsBox>
          <WizrSlider
            min={0}
            max={1}
            minLabel={'FOCUSED'}
            maxLabel={'CREATIVE'}
            // value={}
            // defaultValue={item?.value}
            onSliderChange={(e: any) => setTemperature(e)}
          />
        </IndividualLlmSettingsBox>
        <WizrFlexLayout
          flexDirection='row'
          justifyContent='space-between'
          background='white'
          flex='none'
          style={{width:"98%"}}
        >
          <WizrText type='body2'>Action</WizrText>
        </WizrFlexLayout>
        <IndividualLlmSettingsBox>
          <RadioGroup
            options={[
              { label: 'Translate', value: 'translate' },
              { label: 'Transcribe', value: 'Transcribe' },
            ]}
            value={action}
            onChange={handleRadioChange}
          />
        </IndividualLlmSettingsBox>
        <WizrFlexLayout
          flexDirection='row'
          justifyContent='space-between'
          background='white'
          flex='none'
          style={{width:"98%", marginTop:"10px"}}
        >
          <WizrText type='body2'>Prompt</WizrText>
        </WizrFlexLayout>
        <IndividualLlmSettingsBox>
          <WizrTextArea
            value={''}
            onChange={(value: any) => setPrompt(value)}
          ></WizrTextArea>
        </IndividualLlmSettingsBox>
      </IndividualSettingsBox>
        </>}
        {fields.map((item, index) => {
          return (
            <WizrFlexLayout key={item.id} background="#fff">
              <WizrText type='body3' textColor='#707276'>
                File
              </WizrText>
              <WizrFlexLayout flexDirection='row' justifyContent='flex-start' alignItems='flex-start' style={{ gap: '10px', margin: '5px 0px' }}>
                <Controller
                  render={({ field }) => <WizrInput {...field} />}
                  name={`voiceBlock.${index}.file`}
                  control={control}
                />
                <WizrButton type='text' icon={<DeleteIcon />} onClick={() => remove(index)} style={{ minHeight: '50px' }} />
              </WizrFlexLayout>
              <StyledFlexSpacing justifyContent='space-between' flexDirection='column' alignItems='center' size={20}>
                <StyledWizrTag
                  background="grey"
                  data={parameterList}
                  onClick={(e: any) => {
                    setValue(`voiceBlock.${index}.file`, `{{${e}}}`);
                  }}
                />
              </StyledFlexSpacing>
            </WizrFlexLayout>
          );
        })}
        <WizrButton type='text' text='Add files' onClick={() => append({ file: '' })} style={{ borderWidth: '2px', borderColor: 'black', borderRadius: '20px', width: '100%' }} />


        {/* <WizrButton type='text' text='Add files' onClick={() => setFileNumbers(fileNumbers + 1)} style={{ borderWidth: "2px", borderColor: "black", borderRadius: "20px", width: "100%" }}></WizrButton> */}
      </>
    </VisionContainer>
  )
}

export default VoiceBlockEdit


const IndividualSettingsBox = styled.div`
  width: 100%;
  text-align: left;
  height: auto;
  margin: 10px 0px 0px 0px;
  background-color:white;
  padding:0px;
`
const IndividualLlmSettingsBox = styled.div`
  width: 90%;
  text-align: left;
  height: auto;
  margin: 10px 0px 0px 0px;
`