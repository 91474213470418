import NodeContainer from './NodeContainer'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrFlexSpacing from '../../../components/WizrFlexSpacing'
import WizrText from '../../../components/WizrText'
import styled from 'styled-components'
import { outputNodeTypes } from '../../../utils/appDesignConfigs'
import { useEffect } from 'react'
import { useNodes } from 'reactflow'

const OutputNode = ({ id, data, updateNodeData, isDisabled }: any): JSX.Element => {

  const nodes = useNodes() 
  const outputNode: any = nodes.length > 0 ? nodes[nodes.length - 1].data : undefined;
  useEffect(() => {
    updateNodeData((nds: any) =>
                  nds && nds.map((node: any) => {
                    if (node.id === id) {
                      node.data = {
                        ...node.data,
                        config: {
                          output_type: 'text',
                        },
                      }
                    }
                    return node
                  }),
                )
  }, [])

  const handleNameUpdate = (itemId: any, newName: any) => {

    updateNodeData((nds: any) =>
      nds.map((node: any) => {
        if (node.id === itemId) {
          node.data = {
            ...node.data,
            label: newName,
          }
        }
        return node
      }),
    )

};

  return (
    <NodeContainer item={{ nodeTitle: outputNode?.label, id: 'end' }} startNode={{isStartNode:true}} data={data} onUpdateName={(newName: any) => handleNameUpdate('end', newName)} isDisabled={isDisabled}       nodeType={'10'}>
      <WizrFlexSpacing background='transparent' flexDirection='row' justifyContent='flex-start'>
        <>
          {outputNodeTypes?.map((type: any) => (
            <OutputTypeHolder
            style={{ borderColor : data?.config?.output_type === type?.key ? 'black' : '' }}
              key={type?.key}
              onClick={() =>
                updateNodeData((nds: any) =>
                  nds.map((node: any) => {
                    if (node.id === id) {
                      node.data = {
                        ...node.data,
                        config: {
                          output_type: type?.key,
                        },
                      }
                    }
                    return node
                  }),
                )
              }
            >
              <type.Icon />
              <WizrText type='body2'>{type?.text}</WizrText>
            </OutputTypeHolder>
          ))}
        </>
      </WizrFlexSpacing>
    </NodeContainer>
  )
}

const OutputTypeHolder = styled(WizrFlexLayout)`
  border-radius: 8px;
  border: 1px solid var(--grayscale-500, #999b9f);
  padding: 10px;
`
export default OutputNode
