import styled from "styled-components"
import WizrFlexLayout from "../../../components/WizrFlexLayout"
import WizrTable from "../../../components/WizrTable"
import { useEffect } from "react"
import { dispatchAppPublished, dispatchListAppForPackages, dispatchPackageList, dispatchProuctsList } from "../../../redux/actions/content"
import { useContentLoadingSelector, useListPackagesSelectors, useListProductsSelectors } from "../../../redux/selectors/content"
import { Spin } from "antd"

const StyledWizrTable = styled(WizrTable)`
  .ant-table-wrapper {
    width: 100% !important;
  }
  .ant-table-thead {
    background-color: #f2f4f9;
    border-spacing: 0px;
  }
  .ant-table-tbody > tr {
    background-color: #ffffff;
    margin-bottom: 0px;
  }

  .ant-table table {
    border-spacing: 0 5px;
  }
`

const PackageListing = ():JSX.Element=>{
  const loading =useContentLoadingSelector()
   
    const packages= useListPackagesSelectors()
    useEffect(() => {
      dispatchPackageList()
      dispatchListAppForPackages()
    }, [])
    
    return(
        <WizrFlexLayout>
          {loading?<Spin/>:
            <StyledWizrTable type="packages" dataSource={packages && packages.sort((a: any, b: any) => b.updated_on - a.updated_on)} pagination={false}></StyledWizrTable>}
        </WizrFlexLayout>
    )
}
export default PackageListing