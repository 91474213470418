import { WizrAzurOrchestratorBulkRun } from "../../utils/wizrAzureConnector";
import WizrButton from "../WizrButton"
import { useNavigate } from "react-router-dom"

const BulkRunDownload=(appDetail:any):JSX.Element=>{
  const file= appDetail?.fileName?.output_file_name
    const fileDownloadAction = async (file:any)=>{
        await WizrAzurOrchestratorBulkRun.downloadFileOutput(appDetail?.fileName?.app_id, file)
    }
    
    return(
        <WizrButton type='link' text={file} color={"black"} style={{textDecoration:"underline",whiteSpace: "normal", wordWrap: "break-word" ,height:"auto",textAlign:"left"}} onClick={()=>{fileDownloadAction(file)}}/>
    )
}
export default BulkRunDownload