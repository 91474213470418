import NodeContainer from '../AppDesignerTool.tsx/NodeContainer'
import WizrButton from '../../../components/WizrButton'
import WizrInput from '../../../components/WizrInput'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import CreateNew from '../../../assets/icons/CreateNew'
import WizrText from '../../../components/WizrText'
import styled from 'styled-components'
import { useCallback, useEffect, useRef, useState } from 'react'
import WizrFlexSpacing from '../../../components/WizrFlexSpacing'
import DeleteIcon from '../../../assets/icons/DeleteIcon'
import ReactFlow, { useEdges, useNodes } from 'reactflow';
import { Button, Form, Radio, RadioChangeEvent } from 'antd'
import WizrDropDown from '../../../components/WizrDropDown'
import CloseIcon from '../../../assets/icons/Close'
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import WizrTextArea from '../../../components/WizrTextArea'
import WizrTag from '../../../components/WizrTags'
import { generateNodeSequenceResponse } from '../../../utils'
import { getConnectedEdges } from 'react-flow-renderer';


const FormSection1 = ({ form1Methods }: any) => {

  const { register, setValue, control } = form1Methods;
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "formSection1"
  });

  return (
    <WizrFlexLayout flexDirection='column' justifyContent='flex-start' background='#fff' alignItems='flex-start' style={{ gap: '7px' }}>
      <form >
        <WizrFlexLayout flexDirection='row' style={{ gap: '5px', height: '25px' }} background='#fff' justifyContent='flex-start'>
          <WizrText type='body2' >
            Header
          </WizrText>
          <StyledWizrButton icon={<CreateNew fill={'white'} />} style={{}} onClick={() => append({ key: "KEY", value: "VALUE" })} />
        </WizrFlexLayout>
        {fields.map((item: any, index: any) => {
          return (
            <WizrFlexLayout flexDirection='row' justifyContent='flex-start' background='#fff' alignItems='flex-start' style={{ gap: '10px', margin: '5px 0px' }} key={item.id}>
              <Controller
                render={({ field }) => <WizrInput {...field} value={field.value} />}
                name={`formSection1.${index}.key`}
                control={control}

              />
              <Controller
                render={({ field }) => <WizrInput {...field} />}
                name={`formSection1.${index}.value`}
                control={control}
              />
              <Button type='text' icon={<DeleteIcon />} onClick={() => remove(index)} style={{ minHeight: '50px' }} />
            </WizrFlexLayout>
          )
        })}
      </form>
    </WizrFlexLayout>
  )
}

const FormSection3 = ({ form3Methods }: any) => {

  const { register, setValue, control } = form3Methods;  
  const [bodyType, setBodyType] = useState(form3Methods.control._formValues && form3Methods.control._formValues.bodyType
    ? form3Methods.control._formValues.bodyType
    : 'url_encoded')

  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "formSection3"
  });

  const onChangeBody = (e: any) => {
    const selectedBodyType = e.target.value;
    setValue('bodyType', selectedBodyType); // Update 'bodyType' in form state
    setBodyType(selectedBodyType); // Update local state
  
    if (selectedBodyType !== 'body') {
      setValue('body', ''); // Clear 'body' value when 'bodyType' is not 'body'
    }
  }

  return (
    <WizrFlexLayout flexDirection='row' justifyContent='flex-start' background='#fff' alignItems='flex-start' >
      <form >
        <WizrFlexLayout flexDirection='row' style={{ gap: '5px', height: '25px' }} background='#fff' justifyContent='flex-start'>
          <WizrText type='body2' >
            Body
          </WizrText>
          <StyledWizrButton icon={<CreateNew fill={'white'} />} onClick={() => append({ key: "KEY", value: "VALUE" })} />
        </WizrFlexLayout>
        <WizrFlexLayout style={{ height: '25px' }} background='#fff' justifyContent='flex-start' alignItems='flex-start'>
          <Radio.Group onChange={onChangeBody} value={bodyType}>
            <Radio value={'url_encoded'}>URL Encoded</Radio>
            <Radio value={'form_data'}>Form Data</Radio>
            <Radio value={'body'}>Body</Radio>
          </Radio.Group>
        </WizrFlexLayout>

        {bodyType === 'body' ?
          <WizrFlexLayout background='#fff' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' style={{ gap: '10px', marginBottom: '10px', width: '395px' }}>
            <Controller
              render={({ field }) => <WizrTextArea {...field} value={field.value} />}
              name={`body`}
              control={control}
            />
          </WizrFlexLayout>
          : <WizrFlexLayout background='#fff'>
            {fields.map((item: any, index: any) => {
              return (
                <WizrFlexLayout background='#fff' flexDirection='row' justifyContent='flex-start' alignItems='flex-start' style={{ gap: '10px', marginBottom: '10px' }} key={item.id}>
                  <Controller
                    render={({ field }) => <WizrInput {...field} value={field.value} />}
                    name={`formSection3.${index}.key`}
                    control={control}

                  />
                  <Controller
                    render={({ field }) => <WizrInput {...field} />}
                    name={`formSection3.${index}.value`}
                    control={control}
                  />
                  <Button type='text' icon={<DeleteIcon />} onClick={() => remove(index)} style={{ minHeight: '50px' }} />
                </WizrFlexLayout>
              )
            })}
          </WizrFlexLayout>}
      </form>
    </WizrFlexLayout>
  )
}


const ApiBlockEdit = (props: any): JSX.Element => {

  const { id, data, updateNodeData, updateEdgeData, nodeUpdate, closeEditArea } = props
  const nodes = useNodes()
  const edges = useEdges()

  const { register: register1, setValue: setValue1, getValues: getValues1, control: control1 } = useForm({
    defaultValues: {
      formSection1: [{ key: "", value: "" }],
    }
  });
  const { register: register3, setValue: setValue3, getValues: getValues3, control: control3 } = useForm({
    defaultValues: {
      formSection3: [{ key: "", value: "" }],
      body: '',
      bodyType: 'url_encoded'
    }
  });

  const startnodedata: any = nodes && nodes[0]?.data

  const [isEditMode, setIsEditMode] = useState(false)
  const [parameterList, setParameterList] = useState<any>(startnodedata?.config?.parameters || [])
  const [apiMethod, setApiMethod] = useState<any>("get")
  const [url, setUrl] = useState<any>("")

  const convertJsontoArray = (inputJson: any) => {
    if(inputJson){  
    // Parse JSON-like string
    const parsedObject = JSON.parse(inputJson);
    // Transform into the desired format
    return Object.entries(parsedObject).map(([key, value]) => ({
      key,
      value
    }));
    }
  }

  useEffect(() => {   

    const header: any = convertJsontoArray(data?.config?.headers)
    if(header){
      setValue1('formSection1', header);
    }
    
    if(data?.config?.body_type !== 'body'){
      const body: any = convertJsontoArray(data?.config?.body) 
      if(body){
        setValue3('formSection3', body);
      }
    }else{
      if(data?.config?.body){
        setValue3('body', data?.config?.body)
      }
    }
    setValue3('bodyType', data?.config?.body_type ? data?.config?.body_type : 'url_encoded')
    setUrl(data?.config?.url ? data?.config?.url : '')
    setApiMethod(data?.config?.method ? data?.config?.method : 'get')

  }, [data?.config])

  useEffect(() => {
    const saveAppPayload = {
      edges: edges,
      nodes: nodes,
    };
    const sequence = generateNodeSequenceResponse(saveAppPayload);
    const trimmedNodeSequence = elementsBeforeSpecificElement(sequence, id);
    const defaultParameters = startnodedata?.config?.parameters.map((param: any) => param?.name) || []
    setParameterList((prevList: any) => [...defaultParameters, ...trimmedNodeSequence]);

  }, [nodes, edges, setParameterList, id]);


  function elementsBeforeSpecificElement(arr: any, specificElement: any) {
    const index = arr.indexOf(specificElement);
    if (index === -1) {
      return arr;
    }
    const newArray = arr.slice(0, index).slice(1).map((item: any) => {
      return `${item}.output`
    })
    return newArray

  }


  const handleNameUpdate = (itemId: any, newName: any) => {
    updateNodeData((nds: any) =>
      nds.map((node: any) => {
        if (node.id === itemId) {
          node.data = {
            ...node.data,
            label: newName,
          }
        }
        return node
      }),
    )
  };

  const stringifyArry = (arr: any) => {
    return JSON.stringify(arr.reduce((acc: any, { key, value }: any) => {
      acc[key] = value;
      return acc;
    }, {}), null, 2)
  }




  const onSaveAction = () => {
    
    const header = stringifyArry(getValues1().formSection1);
    let body = '';
    if(getValues3().bodyType !== 'body'){
      body = stringifyArry(  getValues3().formSection3); 
    }else{
      body = getValues3().body 
    }
    

    updateNodeData((nds: any) =>
      nds.map((node: any) => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            config: {
              body: body,
              headers: header,
              method: apiMethod,
              retries: 0,
              url: url,
              body_type: apiMethod === 'get' ? '' : getValues3().bodyType
            },
          }
        }
        return node
      }),
    )
    closeEditArea()

  }

  const handleModeChange = (e: RadioChangeEvent) => {
    setApiMethod(e.target.value)
  };

  const handleUrl = (value: any) => {
    setUrl(value)
  }

  const onDeleteNodeDetail = (e: any) => {  
    const objectWithId: any = nodes && nodes.find((obj: any) => obj.id === e);    
    const connectedEdges = getConnectedEdges([objectWithId], edges);  
      
    if (connectedEdges.length > 0) {
      let updatedEdges = edges.filter((edge) => !connectedEdges.includes(edge));
    
      if (connectedEdges.length > 1) {
        const newEdge = {
          id: `edge_${connectedEdges[0].source}-${connectedEdges[1].target}`,
          source: connectedEdges[0].source,
          target: connectedEdges[1].target,
          type: 'buttonedge',
        };
    
        updatedEdges = updatedEdges.concat([newEdge]);
      }
    
      updateEdgeData(updatedEdges);
    }
    updateNodeData((nds: any) => nds.filter((node: any) => node.id !== e));

  }  


  useEffect(() => {
    if (nodeUpdate === 'api') {
      onSaveAction()
    }
  }, [nodeUpdate])


  return (
        <ContentContainer alignItems='flex-start' background='#fff' style={{height: '100%'}}>
          <WizrFlexLayout background='#fff'>
            <Radio.Group onChange={handleModeChange} style={{ flex: 1, display: 'flex' }}>
              <Radio.Button value="get" style={{ flex: 1, display: 'flex', background: apiMethod === 'post' ? '#E5E7EB' : '#ffff' }}  >Get </Radio.Button>
              <Radio.Button value="post" style={{ flex: 1, display: 'flex', background: apiMethod === 'get' ? '#E5E7EB' : '#ffff' }}>Post </Radio.Button>
            </Radio.Group>
          </WizrFlexLayout>
          <WizrFlexLayout alignItems='flex-start' background='#fff' style={{ margin: "10px 0px" }}>
            <WizrText type='body2' >
              URL
            </WizrText>
            <WizrInput onChange={handleUrl} value={url}></WizrInput>
          </WizrFlexLayout>
          <FormSection1 form1Methods={{ register: register1, setValue: setValue1, control: control1 }} />
          {/* <FormSection2 form2Methods={{ register: register2, setValue: setValue2, control: control2 }}/> */}
          {apiMethod === 'post' ? <FormSection3 form3Methods={{ register: register3, setValue: setValue3, control: control3 }} /> : <></>}
        </ContentContainer>
  )
}

const StyledWizrButton = styled(WizrButton)`
  height:20px;
  width:20px;
  border-radius:50px;
  padding: 5.833px ;
  justify-content: center;
  align-items: center;
  padding-left:13px;
`

const ContentContainer = styled(WizrFlexLayout)`
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  gap: 8px;
`

const StyledWizrTag = styled(WizrTag)`
  height: 42px;
  padding: 8px 11px;
  justify-content: space-between;
`

const StyledFlexSpacing = styled(WizrFlexSpacing)`
  .ant-space-item {
    display: flex !important;
    width: 100% !important;
    flex-wrap: wrap;
  }
`

export default ApiBlockEdit