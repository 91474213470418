import Hamburger from '../assets/icons/Hamburger'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrText from '../components/WizrText'
import Search from '../assets/icons/Search'
import { Card, Input, Popover, Spin, Tooltip, message } from 'antd'
import styled from 'styled-components'
import { useEffect, useRef, useState } from 'react'
import {
  dispatchBotDetails,
  dispatchCloneAutoSolveFeature,
  dispatchCreateFeatureIntegrationSettings,
  dispatchCreateFeatureIntegrationSettingsAutoSolve,
  dispatchCreateIntegration,
  dispatchCurrentModule,
  dispatchDeleteWidget,
  dispatchFeatureSettings,
  dispatchGetWidgetDetails,
  dispatchGetWidgetTypes,
  dispatchIntegrationList,
  dispatchListWidgets,
  dispatchUpdateFeatureSettingsStatus,
  dispatchupdateUserView,
} from '../redux/actions/content'
import {
  useBotDetailsSelector,
  useContentLoadingSelector,
  useFeatureSettingSelector,
  useGetIntegrationList,
  useLoaderSelector,
  useWidgetListSelector,
  useWidgetTypesSelector,
} from '../redux/selectors/content'
import WizrTable from '../components/WizrTable'
import WizrButton from '../components/WizrButton'
import { useNavigate, useParams } from 'react-router-dom'
import CreateNew from '../assets/icons/CreateNew'
import EdgeButton from '../assets/icons/edgeClose'
import { MODAL_TYPES, useWizrModalContext } from '../providers/modal/WizrModalProvider'
import WizrToggleButton from '../components/WizrToggleButton'
import MoreIcon from '../assets/icons/More'
import Copy from '../assets/icons/Copy'
import PlayIcon from '../assets/icons/PlayButton'
import WizrCard from '../components/Wizrcard'
import EditIcon from '../assets/icons/EditIcon'
import { set } from 'react-hook-form'
import CompanyIcon from '../assets/icons/CompanyIcon'
import Person from '../assets/icons/Person'
import SentimentIcon from '../assets/icons/SentimentIcon'
import Tags from '../assets/icons/Tags'
import Category from '../assets/icons/Category'
import TicketPriority from '../assets/icons/TicketPriority'
import TicketType from '../assets/icons/TicketType'
import ZendeskIcon from '../assets/icons/ZendeskIcon'
import Item from 'antd/es/list/Item'
import { useAuth } from '../authContext'
import WizrNoAccessScreen from '../components/WizrNoAccessScreen'
import CreateAgentPopover from '../components/popovers/CreateAgent'
import { v4 as uuidv4 } from 'uuid'
import Autotag from '../assets/icons/Autotag'
import Agentassist from '../assets/icons/Agentassist'
import AutoSolve from '../assets/icons/Autosolve'
import WizrTag from '../components/WizrTags'
import Toolbox from '../assets/icons/Toolbox'

const SearchInput = styled(Input)`
  border-radius: 30px;
  border: 0px solid var(--surface-overlay, #f9f9f9);
  background: #fff;
  width: 512px;
  height: 40px;
`
const StyledWizrTable = styled(WizrTable)`
  .ant-table-wrapper {
    width: 100% !important;
  }
  .ant-table-thead {
    background-color: #f2f4f9;
    border-spacing: 0px;
  }
  .ant-table-tbody > tr {
    background-color: #ffffff;
    margin-bottom: 0px;
  }

  .ant-table table {
    border-spacing: 0 5px;
  }
`
const StyledTextButton = styled(WizrText)`
  text-decoration: underline !important;
`
const StyledPlusButton = styled(WizrButton)`
  background-color: black;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding-right: 8px;
  justify-content: center;
  align-items: center;
`

const StyledButton = styled(WizrButton)`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding-right: 8px;
  justify-content: center;
  align-items: center;
  /* Default styles */
  path {
    stroke: #999b9f; /* Default stroke color */
    transition: stroke 0.3s ease; /* Smooth transition for color change */
  }

  /* Hover styles */
  &:hover path {
    stroke: #1d1f22;
  }
`

const StyledCard = styled(Card)`
border-radius : 16px !important;
  .ant-card-head{ padding: 16px 24px 6px 16px;
  }
  .ant-card-head-title {
    white-space: normal !important;
  }
  .ant-card-body{
  padding:0px 24px 24px 24px;
  }
`




const StyledTicketCard = styled(Card) <any>`
  background-color: #ffffff;
  min-width: 23%;
  max-width: 23%;
  border-radius:16px;
  .ant-card-meta-detail {
    border-bottom: none;
    border-top: none;
  }
  .ant-card-head {
    border-bottom: none;
    text-align: start;
    padding: 8px 8px 0px 24px !important;
  }
  .ant-card-body {
    text-align: center;
    padding: 0px 24px 24px 24px;
  }
`

const WidgetTypePopover = ({ content }: any): JSX.Element => {
  const navigate = useNavigate()
  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      {content && content?.map((item: any, index: any) => {
        return (
          <WizrButton
            text={item?.tool_display_name}
            type='text'
            onClick={() => {
              navigate(`/widgets/${item?.tool_type}`)
              // console.log('clicked', item?.tool_type)
            }}
            justifyContent='flex-start'
            key={index}
          />
        )
      })}
    </WizrFlexLayout>
  )

}

const WidgetListing = () => {

  const navigate = useNavigate()

  useEffect(() => {
    dispatchListWidgets()
    dispatchGetWidgetTypes()
  }, []);

  const onDelete = async (item: any) => {
    await dispatchDeleteWidget(item?.id)
    await dispatchListWidgets()
  }

  const onEdit = (item: any) => {
    navigate(`/widgets/${item?.tool_type}`, { state: { id: item?.id } })
  }

  const colors = ['#FFAF14', '#FF7FC4', '#C6E504'];
  const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

  const widgetsList = useWidgetListSelector();
  return (
    <WizrFlexLayout flexDirection="row" justifyContent='flex-start' style={{ gap: '30px', padding: "12px", display: 'flex', flex: '1', flexWrap: 'wrap' }}>
      {widgetsList &&
        widgetsList?.map((item: any, index: any) => {
          return (
            <StyledTicketCard
              key={index}
              title={<WizrFlexLayout
                background='#FFFFFF'
                flexDirection='row'
                style={{
                  padding: '10px 0px',
                  alignItems: 'center'
                }}
                textAlign='left'
              >
                <WizrFlexLayout background='#FFFFFF'
                  flexDirection='row' justifyContent='flex-start' textAlign='left' alignItems='center' style={{ gap: '10px' }}>
                  {/* <Toolbox stroke={getRandomColor()}/> */}
                  <div style={{ width: '24px', height: '24px' }}> {/* Adjust width and height as needed */}
                    <Toolbox stroke={getRandomColor()} style={{ width: '100%', height: '100%' }} />
                  </div>



                  <WizrText type='sub1' textColor="#000000" style={{

                    whiteSpace: 'normal', // Allows the text to wrap
                    overflowWrap: 'break-word' // Breaks long words if necessary
                  }}>{item?.tool_name}</WizrText>
                </WizrFlexLayout>



                <CreateAgentPopover details={item} onEdit={onEdit} onDelete={onDelete} type={'widget'} />




              </WizrFlexLayout>}
              bordered={true}
              hoverable>
              <WizrFlexLayout background="#FFF" textAlign="left">
                {/* <WizrText type='cardData' >{item?.params?.fn_params?.description}</WizrText> */}
                <WizrText type="cardData">
                  <Tooltip
                    title={item?.params?.fn_params?.description} // Full description shown on hover
                    color="grey"
                  >
                    {item?.params?.fn_params?.description
                      ? (() => {
                        const words = item.params.fn_params.description.split(' '); // Split description into words
                        const truncatedDescription = words.slice(0, 12).join(' '); // Take the first 12 words
                        return words.length > 12 ? `${truncatedDescription} ...` : truncatedDescription; // Append " ..." if truncated
                      })()
                      : ""}
                  </Tooltip>
                </WizrText>





              </WizrFlexLayout>
            </StyledTicketCard>
          )
        })}
    </WizrFlexLayout>
  );
}

const FeatureActionPopover = (item: any): JSX.Element => {

  const featureDetailsWhole = useFeatureSettingSelector()
  const { showModal, hideModal } = useWizrModalContext()
  const loading = useContentLoadingSelector()
  const navigate = useNavigate()
  const { userPermission, hasAcces } = useAuth()
  const BotData = useBotDetailsSelector()
  const {
    id,
    created_on,
    created_by_username,
    created_by,
    updated_by,
    updated_by_name,
    updated_by_username,
    updated_on,
    setting_name,
    ...restOfItems
  } = item?.item || {}

  useEffect(() => {
    if (item.item?.bot_id) {
      dispatchBotDetails(item?.item?.bot_id)
    }

  }, [item?.item])

  const onFeatureCopy = async () => {
    showModal(MODAL_TYPES.ADD_NAME, {
      name: `Copy of ${item?.item?.setting_name}`,
      onFormSubmission: async ({ currentname }: any) => {
        const featureSettings = {
          ...restOfItems,
          status: 'disabled',
          created_on: Date.now(),
          setting_name: currentname,
        }
        const payload = { id: featureDetailsWhole?.id, feature_settings: featureSettings }
        if (item?.item?.setting_type !== 'autosolve') {
          const res = await dispatchCreateFeatureIntegrationSettings(payload)
        }
        else {
          const bot_id = uuidv4()
          if (!featureSettings?.bot_id) {
            await dispatchCreateFeatureIntegrationSettings(payload)
          }
          else {
            const finalPayload = {
              id: featureDetailsWhole?.id,
              feature_settings: {
                ...featureSettings,
                status: 'disabled',
                created_on: Date.now(),
                setting_name: currentname,
                bot_id: bot_id
              }
            }
            const botDetails = {
              bot_config: {
                ...BotData,
                agent_id: uuidv4(),
                id: bot_id
              },
              tenant_feature_id: featureDetailsWhole?.id
            }


            const finalOne = { feactureClone: finalPayload, botDetails: botDetails }
            await dispatchCloneAutoSolveFeature(finalOne)
          }

          // if (featureSettings?.bot_id) {
          //   const res = await dispatchBotDetails(featureSettings?.bot_id)
          //   console.log('res', res);
          // }
          // const bot_id = uuidv4()
          // const finalPayload = {
          //   id: featureDetailsWhole?.id,
          //   feature_settings: {
          //     ...featureSettings,
          //     bot_id: bot_id
          //   }
          // }
          // console.log('final', finalPayload)
          // const botDetails = {
          //   bot_config: {
          //     ...BotData,
          //     agent_id: uuidv4(),
          //     bot_id: bot_id
          //   },
          //   tenant_feature_id: featureDetailsWhole?.id,
          // }
          // const data = { finalPayload: finalPayload, botDetails: botDetails }
          // await dispatchCreateFeatureIntegrationSettingsAutoSolve(data)
          // console.log('sdfvvfsvvrfds', item)
          // await dispatchCreateFeatureIntegrationSettings(payload)
          //await dispatchCreateBot(payload)

        }
        dispatchFeatureSettings()
        hideModal()
      },

      onCancel: hideModal,
    })
    // const featureSettings = {
    //   ...restOfItems,
    //   created_on: Date.now(),
    //   setting_name: `Copy of ${item?.item?.setting_name} `,
    // }
  }
  const onTestAction = () => {
    navigate('/featuretest', { state: { details: item?.item } })
  }
  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      <WizrButton
        icon={<Copy />}
        text='Clone feature'
        type='text'
        justifyContent='flex-start'
        onClick={() => {
          onFeatureCopy()
        }}
        disabled={!hasAcces('clone_cxsettings_features')}
      />
    </WizrFlexLayout>
  )
}
const CxSettingsSteps = (): JSX.Element => {
  const stepsData = [
    {
      heading: 'Integrations',
      valueheader: '',
      value: 'Connect platforms like Zendesk, Salesforce, Freshdesk, etc. to Wizr.',
      value2: 'Access the detailed integration process [link here].',
    },
    {
      heading: 'Company and Ticket Settings',
      valueheader: '',
      value: 'Customize settings for easy configuration of customer support areas.',
      value2: 'Set up company and ticket settings for different scenarios.',
    },
    {
      heading: 'Feature Configuration',
      valueheader: '',
      value: 'Tailor feature settings to meet specific needs and improve workflow.',
      value2:
        'Customize settings for individual features using relevant company and ticket settings.',
    },
    {
      heading: 'Give App Access to Users',
      valueheader: '',

      value: 'Enable features after customization and integrate via APIs or extensions.',
      value2: 'Boost your productivity with Wizr features.',
    },
  ]
  return (
    <WizrFlexLayout flexDirection='row' style={{ gap: '20px' }}>
      {stepsData?.map((item: any, index: any) => {
        return (
          <WizrFlexLayout
            key={index}
            flexDirection='column'
            background='white'
            style={{ padding: '20px', borderRadius: '16px', gap: '10px' }}
          >
            <WizrFlexLayout
              background='#FFFFFF'
              flexDirection='row'
              style={{
                justifyContent: 'flex-start',
                gap: '10px',
                maxHeight: '40px',
                alignItems: 'center',
                textAlign: 'left',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '31px',
                  height: '31px',
                  borderRadius: '50px',
                  background:
                    'linear-gradient(138.48deg, #02F5DB -37.36%, #00BDFC 17.25%, #568FFE 59%, #A36BFE 98.67%)',
                  padding: '6px',
                  color: 'white ',
                }}
              >
                {index + 1}
              </div>
              <WizrText type='sub1'>{item?.heading}</WizrText>
            </WizrFlexLayout>
            <WizrFlexLayout
              flexDirection='column'
              background='#FFFFFF'
              textAlign='left'
              justifyContent='flex-start'
            >
              <WizrText type='cardData' textColor='#5D5F62'>
                {item?.valueheader}
              </WizrText>
              <ul style={{ padding: ' 0px 17px' }}>
                <li>
                  <WizrText type='cardData' textColor='#5D5F62'>
                    {item?.value}
                  </WizrText>
                </li>
                <li>
                  {' '}
                  <WizrText type='cardData' textColor='#5D5F62'>
                    {item?.value2}
                  </WizrText>
                </li>
              </ul>
            </WizrFlexLayout>
          </WizrFlexLayout>
        )
      })}
    </WizrFlexLayout>
  )
}
const FeatureCard = (props: any): JSX.Element => {
  const loading = useLoaderSelector()
  const [messageApi, contextHolder] = message.useMessage()
  const { userPermission, hasAcces } = useAuth()

  const success = () => {
    messageApi.open({
      type: 'loading',
      content: 'Action in progress..',
    })
  }

  if (loading) {
    success()
  }
  const navigate = useNavigate()
  const featureSettingsExtracted = props?.featureSettings
  const integrations = useGetIntegrationList()
  const Id = props?.id
  const changeFeatureStatus = async (e: any, value: any) => {
    const payload = {
      id: Id,
      feature_settings: {
        status: e ? 'active' : 'disabled',
        id: value?.id,
        updated_on: Date.now(),
      },
    }
    await dispatchUpdateFeatureSettingsStatus(payload)
    dispatchFeatureSettings()
  }

  const getIntegrationName = (id: any) => {
    const integrationName = integrations?.find((item: any) => item?.id === id)
    if (integrationName) {
      return (
        <WizrText type='caption2' textColor='#5D5F62'>
          {integrationName?.display_name}
        </WizrText>
      )
    } else {
      return <></>
    }
  }
  return (
    // <WizrFlexLayout flexDirection='row' textAlign='left' justifyContent='flex-start' style={{gap:'30px'}}>
    <div
      style={{
        display: 'flex',
        flex: '1',
        flexDirection: 'row',
        textAlign: 'left',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        gap: '30px',
        padding: '12px',

      }}
    >
      {featureSettingsExtracted &&
        featureSettingsExtracted?.map((item: any, index: any) => {
          return (
            <>
              {contextHolder}
              <StyledCard
                key={index}
                hoverable
                //   onClick={() => {
                //     navigate('/featuresettings',{state:{details:item}})
                //  }}
                className='feature-card'
                title={
                  <WizrFlexLayout flexDirection='row' background='white' justifyContent='flex-start' textAlign='left' style={{ gap: "4px" }}>
                    {
                      item?.setting_type == 'autotag' ?
                        <Autotag stroke="#AC69FD" /> :
                        item?.setting_type == 'agentassist' ?

                          <Agentassist stroke="#FFAF14" /> :
                          <AutoSolve stroke="#25D441" />
                    }
                    <WizrText type='sub1' textColor='#000000'>
                      {item?.setting_name}
                    </WizrText></WizrFlexLayout>
                }
                extra={
                  <WizrToggleButton
                    disabled={hasAcces('enabledisable_cxsettings_features') === true ? false : true}
                    isEnabled={item?.status === 'active'}
                    onChange={(e: any) => {
                      changeFeatureStatus(e, item)
                    }}
                  />
                }
                style={{ minWidth: '23%', maxWidth: '23%' }}
                headStyle={{ border: 'none' }}
              >
                <WizrFlexLayout background='white' style={{ gap: '10px' }}>
                  <WizrTag type={'colorfull'}
                    data={item?.setting_type == 'agentassist' ? ['Agentassist'] : item?.setting_type == 'autotag' ? ['Autotag'] : ['Autosolve']}
                    style={{ backgroundColor: item?.setting_type == "agentassist" ? '#FEF3C7' : item?.setting_type == 'autotag' ? '#D1C4F0' : '#DCFCE7', borderWidth: item?.setting_type == "agentassist" ? '1px ' : item?.setting_type == 'autotag' ? '1px ' : '1px', borderColor: item?.setting_type == "agentassist" ? '#FBD34D' : item?.setting_type == 'autotag' ? '#6138CF ' : '#4ADE80', width: 'fit-content', padding: '0px 10px' }} />
                  <WizrFlexLayout
                    style={{ minHeight: '70px', maxHeight: '70px' }}
                    background='white'
                    textAlign='left'
                  >
                    <WizrText type='cardData' textColor='#5D5F62'>
                      {item?.default_description}
                    </WizrText>
                  </WizrFlexLayout>
                  <WizrFlexLayout background='white'>
                    {item?.integration_id ? (
                      <WizrFlexLayout
                        background='white'
                        flexDirection='row'
                        alignItems='center'
                        justifyContent='flex-start'
                        style={{ gap: '15px' }}
                      >
                        <div
                          style={{
                            height: '35px',
                            width: '35px',
                            borderRadius: '50px',
                            background: '#DCDEE2',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        >
                          <ZendeskIcon />
                        </div>
                        {getIntegrationName(item?.integration_id)}
                      </WizrFlexLayout>
                    ) : (
                      <WizrFlexLayout>
                        <></>
                      </WizrFlexLayout>
                    )}
                  </WizrFlexLayout>
                  <WizrFlexLayout
                    justifyContent='space-between'
                    flexDirection='row'
                    background='white'
                    style={{ minHeight: '50px', maxHeight: '50px' }}
                  >
                    <Popover content={<FeatureActionPopover item={item} />} trigger='click'>
                      <StyledButton
                        icon={<MoreIcon />}
                        text=''
                        type='text'
                        style={{ height: '40px', width: '40px', borderRadius: '50px' }}
                      />
                    </Popover>
                    <WizrButton
                      type='primary'
                      text='Settings'
                      style={{ borderRadius: '20px' }}
                      disabled={hasAcces('edit_cxsettings_features') === true ? false : true}
                      onClick={() => {
                        // if (item?.setting_type === 'autotag') {
                        //  navigate(`/autotag/${item?.id}`,{state:{details:item}})}
                        //  else if (item?.setting_type === 'agentassist') {
                        //   navigate(`/agentassist/${item?.id}`,{state:{details:item}})}
                        navigate(`/${item?.setting_type}/${item?.id}`, { state: { details: item } })
                      }}
                    />
                  </WizrFlexLayout>
                </WizrFlexLayout>
              </StyledCard>
            </>
          )
        })}
      {/* </WizrFlexLayout> */}
    </div>
  )
}

const CompanyDetailsCard = (companyDetails: any): JSX.Element => {
  const navigate = useNavigate()
  const { userPermission, hasAcces } = useAuth()
  const companyDetailsExtracted = companyDetails?.companyDetails
  return (
    <div
      style={{
        display: 'flex',
        flex: '1',
        flexDirection: 'row',
        textAlign: 'left',
        justifyContent: 'flex-start',
        gap: '30px',
        overflowX: 'auto',
        padding: '12px',
      }}
    >
      <WizrCard
        icon={<CompanyIcon stroke={'#7C82FF'} />}
        onClick={() =>
          hasAcces('edit_cxsettings_company') &&
          navigate('/company', { state: { type: 'company', data: companyDetailsExtracted } })
        }
        title={'About the company'}
        description={'General description of the company'}
        hoverable={hasAcces('edit_cxsettings_company')}
      // button={
      //   <WizrButton
      //     icon={<EditIcon />}
      //     text=''
      //     type='text'
      //     onClick={() => {
      //       navigate('/company', { state: { type: 'company', data: companyDetailsExtracted } })
      //     }}
      //   />
      // }
      />
      <WizrCard
        icon={<Person stroke={'#25D441'} />}
        title={'Agent Persona'}
        description={'List of agent personas'}
        hoverable={hasAcces('edit_cxsettings_company')}
        onClick={() => {
          hasAcces('edit_cxsettings_company') &&
            navigate('/company', { state: { type: 'persona', data: companyDetailsExtracted } })
        }}
      />
    </div>
  )
}

const TicketSettingsCard = (ticketSettings: any): JSX.Element => {

  const navigate = useNavigate()
  const ticketSettingsExtracted = ticketSettings?.ticketSettings
  const { userPermission, hasAcces } = useAuth()
  const onEditClick = (item: any) => {
    navigate(`/featuregroup/${ticketSettingsExtracted?.[item]?.ticket_type}`, {
      state: { title: ticketSettingsExtracted?.[item]?.display_name, data: ticketSettings },
    })
  }
  const getIcon = (item: any) => {
    switch (ticketSettingsExtracted?.[item]?.ticket_type) {
      case 'categories_intent':
        return <Category stroke='#FF7FC4' />

      case 'sentiment':
        return <SentimentIcon stroke='#FFAF14' />

      case 'tags':
        return <Tags stroke='#AC69FD' />

      case 'priority':
        return <TicketPriority stroke='#FF772B' />

      case 'type':
        return <TicketType stroke='#C6E504' />
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flex: '1',
        flexDirection: 'row',
        textAlign: 'left',
        justifyContent: 'flex-start',
        gap: '30px',
        flexWrap: 'wrap',
        padding: '12px ',
      }}
    >
      {ticketSettingsExtracted &&
        Object.keys(ticketSettingsExtracted)?.map((item: any, index: any) => {
          return (
            <WizrCard
              icon={getIcon(item)}
              title={ticketSettingsExtracted?.[item]?.display_name}
              description={ticketSettingsExtracted?.[item]?.description}
              hoverable={hasAcces('edit_cxsettings_ticket')}
              onClick={() => {
                hasAcces('edit_cxsettings_ticket') && onEditClick(item)
              }}
              key={index}
            />
          )
        })}
    </div>
  )
}

const CxSettings = (): JSX.Element => {
  const loading = useContentLoadingSelector()
  const navigate = useNavigate()
  const params = useParams()
  const featureSettingsWhole = useFeatureSettingSelector()
  const integrations = useGetIntegrationList()
  const { showModal, hideModal } = useWizrModalContext()
  const [integrationToDisplay, setIntegrationToDisplay] = useState<any[]>([])
  const [featureSettingsToList, setFeatureSettingsToList] = useState<any[]>([{}])
  const [autosolveFeaturesData, setAutosolveFeaturesData] = useState<any[]>([])
  const [agentassistFeaturesData, setAgentassistFeaturesData] = useState<any[]>([])
  const [autotagFeaturesData, setAutotagFeaturesData] = useState<any[]>([])
  const [viewFullAutosolve, setViewFullAutosolve] = useState(false);
  const [featureCount, setfeatureCount] = useState<any>({});
  const [viewFullAgentassist, setViewFullAgentassist] = useState(false);
  const [viewFullAutotag, setViewFullAutotag] = useState(false);
  const [viewFull, setViewFull] = useState(false)
  const featureSettings = featureSettingsWhole?.feature_settings
  const companyDetails = featureSettingsWhole?.company_settings
  const ticketSettings = featureSettingsWhole?.ticket_settings
  const { userPermission, hasAcces } = useAuth()

  useEffect(() => {
    integrations.sort((a: any, b: any) => b.updated_on - a.updated_on)
    const integrationData: any[] = integrations.slice(0, 4)
    setIntegrationToDisplay(integrationData)
    viewFull
      ? setFeatureSettingsToList(featureSettings)
      : setFeatureSettingsToList(
        featureSettingsWhole?.feature_settings &&
        featureSettingsWhole?.feature_settings.slice(0, 4),
      )
  }, [integrations, viewFull, featureSettingsWhole?.feature_settings])

  useEffect(() => {
    // localStorage.setItem('redirectCxSetttings', 'false')
    dispatchFeatureSettings()
    dispatchIntegrationList()
  }, [])

  const viewFullIntegrations = () => {
    dispatchCurrentModule('settings')
    dispatchupdateUserView({
      activeKey: 'integrations',
    })
  }

  const autosolvescrollToRef = useRef<HTMLDivElement>(null)
  const agentassistscrollToRef = useRef<HTMLDivElement>(null)
  const autotagscrollToRef = useRef<HTMLDivElement>(null)
  const topRef = useRef<HTMLDivElement>(null)
  const widgetTypes = useWidgetTypesSelector()

  useEffect(() => {
    switch (params?.type) {
      case 'autosolve':
        autosolvescrollToRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break;
      case "agentassist":
        agentassistscrollToRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break;
      case "autotag":
        autotagscrollToRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break;
      default:
        topRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break;

    }

  }, [params]);

  useEffect(() => {
    // if (params?.type) {
    //   if (scrollToRef.current) {
    //     scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    //     const currentFeatureTypeData = featureSettings?.filter(
    //       (item: any) => item?.setting_type === params?.type,
    //     )
    //     if (params?.type !== 'triage') {
    //       setFeatureSettingsToList(currentFeatureTypeData)
    //     } else {
    //       const triage = [
    //         {
    //           setting_type: 'triage',
    //           setting_name: 'Triage',
    //           status: 'active',
    //           default_description:
    //             'This app is used to analyze the incoming ticket and triage the ticket to corresponding teams /agents ',
    //         },
    //       ]
    //       setFeatureSettingsToList(triage)
    //     }
    //   }
    // } else {
    //   if (topRef.current) {
    //     topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    //     setViewFull(false)
    //   }
    // }
    //   const autosolve = featureSettings?.filter((item: any) => item?.setting_type  === 'autosolve')
    //   const length =autosolve && autosolve.length
    //   const data = autosolve.slice(0, 4)
    //   setAutosolveFeaturesData(data)
    //   const agentassist = featureSettings?.filter((item: any) => item?.setting_type  === 'agentassist').slice(0, 4)
    //   setAgentassistFeaturesData(agentassist)
    //   const autotag = featureSettings?.filter((item: any) => item?.setting_type  === 'autotag').slice(0, 4)
    //   setAutotagFeaturesData(autotag)
    // }, [featureSettings])

    const autosolve = featureSettings?.filter((item: any) => item?.setting_type === 'autosolve')
      .sort((a: any, b: any) => {
        // Sort items with status 'active' to come first
        if (a.status === 'active' && b.status !== 'active') return -1;
        if (a.status !== 'active' && b.status === 'active') return 1;
        return 0;
      });
    setAutosolveFeaturesData(autosolve?.slice(0, 4) || []);

    const agentassist = featureSettings?.filter((item: any) => item?.setting_type === 'agentassist')
      .sort((a: any, b: any) => {
        // Sort items with status 'active' to come first
        if (a.status === 'active' && b.status !== 'active') return -1;
        if (a.status !== 'active' && b.status === 'active') return 1;
        return 0;
      });
    setAgentassistFeaturesData(agentassist?.slice(0, 4) || []);

    const autotag = featureSettings?.filter((item: any) => item?.setting_type === 'autotag')
      .sort((a: any, b: any) => {
        // Sort items with status 'active' to come first
        if (a.status === 'active' && b.status !== 'active') return -1;
        if (a.status !== 'active' && b.status === 'active') return 1;
        return 0;
      });
    setAutotagFeaturesData(autotag?.slice(0, 4) || []);
    setfeatureCount({
      'autosolve': autosolve?.length,
      "agentassist": agentassist?.length, "autotag": autotag?.length

    });


  }, [featureSettings]);

  const handleViewToggle = (type: string) => {    // viewFull ? setViewFull(false) : setViewFull(true)

    const fullData = featureSettings?.filter((item: any) => item?.setting_type === type)
      .sort((a: any, b: any) => {
        // Sort items with status 'active' to come first
        if (a.status === 'active' && b.status !== 'active') return -1;
        if (a.status !== 'active' && b.status === 'active') return 1;
        return 0;
      });
    const lessData = featureSettings?.filter((item: any) => item?.setting_type === type)
      .sort((a: any, b: any) => {
        // Sort items with status 'active' to come first
        if (a.status === 'active' && b.status !== 'active') return -1;
        if (a.status !== 'active' && b.status === 'active') return 1;
        return 0;
      }).slice(0, 4)
    switch (type) {
      case 'autosolve':
        !viewFullAutosolve ?
          setAutosolveFeaturesData(fullData) :
          setAutosolveFeaturesData(lessData)
        setViewFullAutosolve(!viewFullAutosolve)
        break;
      case 'agentassist':
        !viewFullAgentassist ?
          setAgentassistFeaturesData(fullData) :
          setAgentassistFeaturesData(lessData)
        setViewFullAgentassist(!viewFullAgentassist)
        break;
      case 'autotag':
        !viewFullAutotag ?
          setAutotagFeaturesData(fullData) :
          setAutotagFeaturesData(lessData)
        setViewFullAutotag(!viewFullAutotag)
        break;
    }
  };

  // const getFeaturesData = (type: string) => {
  //   const allData = featureSettings?.filter((item: any) => item?.setting_type === type);
  //   const isViewFull = type === 'autosolve' ? viewFullAutosolve :
  //                       type === 'agentassist' ? viewFullAgentassist :
  //                       viewFullAutotag;
  //   return isViewFull ? allData : allData?.slice(0, 4);
  // };


  // const handleviewFull = (data: string) => {
  //   const autosolve = featureSettings?.filter((item: any) => item?.setting_type  === data)
  //   setAutosolveFeaturesData(autosolve)
  // }


  return (
    <div style={{ minHeight: '100vh', overflow: 'auto', overflowY: 'auto', paddingBottom: '20px' }}>
      <div ref={topRef}>
        <WizrFlexLayout
          style={{ padding: '20px 0', gap: '20px', maxHeight: '45px' }}
          flexDirection='row'
          justifyContent='flex-start'
        >
          <div style={{ paddingTop: '3px' }}>
            <Hamburger />
          </div>

          <WizrText type='h6'>Cx Settings</WizrText>
        </WizrFlexLayout>
      </div>
      <WizrFlexLayout style={{ gap: '20px' }}>
        <WizrFlexLayout alignItems='center' style={{ maxHeight: '45px' }}>
          <SearchInput placeholder='Search' prefix={<Search />} />
        </WizrFlexLayout>
        <WizrFlexLayout style={{ minHeight: '240px' }}>
          <CxSettingsSteps />
        </WizrFlexLayout>
        <WizrFlexLayout justifyContent='flex-start' style={{ gap: '0px' }}>
          <WizrFlexLayout justifyContent='space-between' flexDirection='row'>
            <WizrFlexLayout
              justifyContent='flex-start'
              flexDirection='row'
              style={{ gap: '10px', paddingTop: '5px' }}
            >
              <WizrText type='sub1'>Integrations</WizrText>
              <StyledPlusButton
                disabled={hasAcces('create_cxsettings_integrations') === true ? false : true}
                onClick={() => {
                  viewFullIntegrations()
                  navigate('/settings', { state: { action: 'settingsadd' } })
                }}
                type='primary'
                icon={<CreateNew fill='white' />}
              />
            </WizrFlexLayout>
            <WizrButton
              text={
                <StyledTextButton type='link'>
                  <WizrText type='sub2' textColor='#1D1F22'>
                    View All Integrations
                  </WizrText>
                </StyledTextButton>
              }
              type='link'
              disabled={hasAcces('edit_cxsettings_integrations') === true ? false : true}
              onClick={() => {
                viewFullIntegrations()
                navigate('/settings')
              }}
            />
          </WizrFlexLayout>
          {hasAcces('view_cxsettings_integrations') ? (
            <StyledWizrTable
              type='integrationsSettings'
              dataSource={integrationToDisplay}
              pagination={false}
            ></StyledWizrTable>
          ) : (
            <WizrNoAccessScreen />
          )}
        </WizrFlexLayout>

        <WizrFlexLayout
          justifyContent='flex-start'
          flexDirection='column'
          style={{ gap: '20px' }}
          textAlign='left'
        >
          <WizrFlexLayout justifyContent='space-between' flexDirection='row'>
            <div ref={autosolvescrollToRef}>
              <WizrFlexLayout
                justifyContent='flex-start'
                flexDirection='row'
                style={{ gap: '10px', paddingTop: '5px' }}
              >
                <WizrText type='sub1'>Auto Solve</WizrText>
              </WizrFlexLayout>
            </div>
            <WizrButton
              text={
                <StyledTextButton type='link'>
                  <WizrText type='sub2' textColor='#1D1F22'>
                    {viewFullAutosolve ? 'View Less' : `View All (${featureCount?.autosolve})`}
                  </WizrText>
                </StyledTextButton>
              }
              type='link'
              onClick={() => handleViewToggle('autosolve')}
              disabled={!hasAcces('view_cxsettings_features')}
            />
          </WizrFlexLayout>

          {hasAcces('view_cxsettings_features') ? (
            <FeatureCard featureSettings={autosolveFeaturesData} id={featureSettingsWhole?.id} />
          ) : (
            <WizrNoAccessScreen />
          )}
        </WizrFlexLayout>

        <WizrFlexLayout
          justifyContent='flex-start'
          flexDirection='column'
          style={{ gap: '20px' }}
          textAlign='left'
        >
          <WizrFlexLayout justifyContent='space-between' flexDirection='row'>
            <div ref={agentassistscrollToRef}>
              <WizrFlexLayout
                justifyContent='flex-start'
                flexDirection='row'
                style={{ gap: '10px', paddingTop: '5px' }}
              >
                <WizrText type='sub1'>Agent Assist</WizrText>
              </WizrFlexLayout>
            </div>
            <WizrButton
              text={
                <StyledTextButton type='link'>
                  <WizrText type='sub2' textColor='#1D1F22'>
                    {viewFullAgentassist ? 'View Less' : `View All (${featureCount?.agentassist})`}
                  </WizrText>
                </StyledTextButton>
              }
              type='link'
              onClick={() => handleViewToggle('agentassist')}
              disabled={!hasAcces('view_cxsettings_features')}
            />
          </WizrFlexLayout>

          {hasAcces('view_cxsettings_features') ? (
            <FeatureCard featureSettings={agentassistFeaturesData} id={featureSettingsWhole?.id} />
          ) : (
            <WizrNoAccessScreen />
          )}
        </WizrFlexLayout>

        <WizrFlexLayout
          justifyContent='flex-start'
          flexDirection='column'
          style={{ gap: '20px' }}
          textAlign='left'
        >
          <WizrFlexLayout justifyContent='space-between' flexDirection='row'>
            <div ref={autotagscrollToRef}>
              <WizrFlexLayout
                justifyContent='flex-start'
                flexDirection='row'
                style={{ gap: '10px', paddingTop: '5px' }}
              >
                <WizrText type='sub1'>Auto Tag</WizrText>
              </WizrFlexLayout>
            </div>
            <WizrButton
              text={
                <StyledTextButton type='link'>
                  <WizrText type='sub2' textColor='#1D1F22'>
                    {viewFullAutotag ? 'View Less' : `View All (${featureCount?.autotag})`}
                  </WizrText>
                </StyledTextButton>
              }
              type='link'
              onClick={() => handleViewToggle('autotag')}
              disabled={!hasAcces('view_cxsettings_features')}
            />
          </WizrFlexLayout>

          {hasAcces('view_cxsettings_features') ? (
            <FeatureCard featureSettings={autotagFeaturesData} id={featureSettingsWhole?.id} />
          ) : (
            <WizrNoAccessScreen />
          )}
        </WizrFlexLayout>

        <WizrFlexLayout
          justifyContent='flex-start'
          flexDirection='column'
          textAlign='left'
          style={{ gap: '20px' }}
        >

          <WizrText type='sub1'>Company Support Settings </WizrText>
          {hasAcces('view_cxsettings_company') ? (
            <CompanyDetailsCard companyDetails={companyDetails} />
          ) : (
            <WizrNoAccessScreen />
          )}
        </WizrFlexLayout>
        <WizrFlexLayout
          justifyContent='flex-start'
          flexDirection='column'
          style={{ gap: '20px' }}
          textAlign='left'
        >
          <WizrText type='sub1'>Ticket Settings </WizrText>
          {hasAcces('view_cxsettings_ticket') ? (
            <TicketSettingsCard ticketSettings={ticketSettings} />
          ) : (
            <WizrNoAccessScreen />
          )}
        </WizrFlexLayout>
        <WizrFlexLayout
          justifyContent='flex-start'
          flexDirection='column'
          style={{ gap: '20px' }}
          textAlign='left'
        >
          <WizrFlexLayout justifyContent='flex-start' flexDirection='row' style={{ gap: '10px' }}>
            <WizrText type='sub1'>Tools/Widgets</WizrText>
            <Popover content={<WidgetTypePopover content={widgetTypes} />} title='' trigger='click'>
              <StyledPlusButton
                type='primary'
                icon={<CreateNew fill='white' />}
              /></Popover>
          </WizrFlexLayout>
          {hasAcces('view_cxsettings_ticket') ? (
            <WidgetListing />
          ) : (
            <WizrNoAccessScreen />
          )}
        </WizrFlexLayout>
      </WizrFlexLayout>
    </div>
  )
}
export default CxSettings
