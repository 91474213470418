import { useEffect, useState } from "react";
import WizrButton from "../components/WizrButton";
import WizrFlexLayout from "../components/WizrFlexLayout"
import WizrText from "../components/WizrText"
import { Button, Input,  Space, Spin } from 'antd';

import WizrUploadContainer from "../components/WizrUploadButton";
import {  WizrAzurOrchestratorInstance } from "../utils/wizrAzureConnector";

import { useParams } from "react-router-dom";
import { getFileType } from "../utils";
import {  useAppShareLinkValidation, useChatMessageSelector, useContentLoadingSelector, useCurrentAppDetailsSelector } from "../redux/selectors/content";
import {  dispatchAppShareDetails, dispatchAppShareLinkValidation, dispatchAppShareToolTest,  } from "../redux/actions/content";
import styled from "styled-components";
import HistoryIcon from "../assets/icons/History";

import WizrInput from "../components/WizrInput";
import WizrTextArea from "../components/WizrTextArea";
import WizrTagInput from "../components/WizrTagInput";
import WizrDropDown from "../components/WizrDropDown";
import Chat from "../components/WizrChat";
//import {  } from 'antd';

const StyledWizrButton = styled(WizrButton)`
border-radius: 20px;
border: 1px solid var(--grayscale-800, #1D1F22);
background: var(--generic-white, #FFF);
margin-right:10px
`


const StyledHeaderWizrButton = styled(WizrButton)`
border-radius: 20px;
background: var(--grayscale-200, #EAECF1);`



const SingleRunContainerLeft = ({ parameters, doUpdateFields, saveFileParams, saveFilesData }: any): JSX.Element => {

  const [inputValue, setInputValue] = useState<any>({})
  const [messageParams, setMessageParams] = useState<any>({ '{{user_query}}': '' })
  const [fileParams, setFfileParams] = useState<any>([])
  const [filesData, setFilesData] = useState<any>([])

  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item,
        value: item,
      }))
    return result
  }

  const updateMessageParams = (name: string, value: any) => {
    const names = `{{${name}}}`
    const finalValue = value?.length > 0 ? value.toString() : value
    setMessageParams({ ...messageParams, [names]: finalValue })
  }

  const uploadFile = (name: any, fileInfo: any) => {

    if (fileInfo !== '') {
      updateFileParams(name, fileInfo)
      WizrAzurOrchestratorInstance.uploadFile('', fileInfo?.file, '', [], 'promptbox')
    } else {
      const newFileParams = fileParams.filter((item: any) => item.param_name !== name)
      setFfileParams(newFileParams)
      saveFileParams(newFileParams)
    }
  }


  const updateFileParams = (name: any, fileInfo: any) => {
    const type = getFileType(fileInfo?.file?.name)

    const fileData = {
      param_name: name,
      file_name: fileInfo?.file?.name,
      file_location: 'promptbox',
      file_type: type,
    }

    fileParams?.map((item: any, key: any) => {
      if (item.param_name === name) {
        fileParams.splice(key, 1)
      }
    })

    setFfileParams([...fileParams, fileData])

    const fileValue = {
      param_name: name,
      file: fileInfo?.fileList[0]?.originFileObj,
    }

    filesData?.map((item: any, key: any) => {
      if (item.param_name === name) {
        filesData.splice(key, 1)
      }
    })

    setFilesData([...filesData, fileValue])
    saveFilesData([...filesData, fileValue])

  }

  return (

    <WizrFlexLayout background="white" style={{ borderRadius: "16px", padding: "15px", }}>
      <WizrFlexLayout flex={8} background="white" alignItems='flex-start' justifyContent="flex-start" style={{ width: '90%', gap: '5px',marginLeft:"20px" }}>
        {parameters && parameters?.map((input: any, key: number) => {
          if (input.name!=="user_query"){
          switch (input.type) {
            case 1:
              return (
                <>
                  <WizrText type='body2'>{input.name}</WizrText>
                  <WizrInput
                    background={'#F2F4F9'}
                    key={key}
                    placeholder="Parameter Value"
                    style={{ maxHeight: '25px' }}
                    value={inputValue[input.name]}
                    onChange={(value: any) => {
                      updateMessageParams(input.name, value)
                      setInputValue({ ...inputValue, [input.name]: value })
                      doUpdateFields(input.name, value)
                    }}
                  />
                </>
              )
            case 2:
              return (
                <>
                  <WizrText type='body2'>{input.name}</WizrText>
                  <WizrTextArea
                    onChange={(value: any) => {
                      updateMessageParams(input.name, value);
                      setInputValue({ ...inputValue, [input.name]: value })
                      doUpdateFields(input.name, value)
                    }}
                    placeholder='Enter text'
                    minRows={2}
                    value={inputValue[input.name]}
                    background={'#F2F4F9'}
                  />
                </>
              )
            case 4:
              return (
                <>
                  <WizrText type='body2'>{input?.name}</WizrText>
                  <WizrDropDown
                    placeHolder='Select one option'
                    options={dropDownConverter(input?.modalTags)}
                    onChange={(value: any) => {
                      updateMessageParams(input.name, value);
                      setInputValue({ ...inputValue, [input.name]: value })
                      doUpdateFields(input.name, value)
                    }}
                    // background={'#F2F4F9'}
                  />
                </>
              )
            case 5:
              return (
                <>
                  <WizrText type='body2'>{input?.name}</WizrText>
                  <WizrTagInput
                    placeHolder='Select multiple option'
                    options={dropDownConverter(input?.modalTags)}
                    onChange={(value: any) => {
                      updateMessageParams(input.name, value);
                      setInputValue({ ...inputValue, [input.name]: value });
                      doUpdateFields(input.name, value);
                    }}
                  ></WizrTagInput>
                </>
              )
            case 3:
              return (
                <>
                  <WizrText type='body2'>{input.name}</WizrText>
                  <Input
                    //onChange={(value: any) => updateMessageParams(item.name, value)}
                    placeholder='Upload File'
                    value={inputValue[input.name]}
                    suffix={
                      <Space>
                        {inputValue[input.name] && ( 
                          <Button shape='round' onClick={(e: any) => {                            
                            uploadFile(input.name, '')
                            setInputValue({ ...inputValue, [input.name]: null })
                            doUpdateFields(input.name, '')
                          }}>
                            remove file
                          </Button>
                        )}
                        <WizrUploadContainer
                          onChange={(e: any) => {
                            uploadFile(input.name, e)
                            setInputValue({ ...inputValue, [input.name]: e?.file?.name })
                            doUpdateFields(input.name, e?.file?.name)
                          }}
                        >
                          <Button shape='round' >
                            Upload
                          </Button>
                        </WizrUploadContainer>
                      </Space>
                    }
                  />
                </>
              )
          }
        }})}
      </WizrFlexLayout>
      <WizrFlexLayout flex={1} flexDirection="row" background="white" justifyContent="flex-end" style={{marginTop:"10px"}}>
        <StyledWizrButton
          type="link" text={<WizrText type="button" textColor="#1D1F22">Reset</WizrText>} onClick={() => { setInputValue({})  }}></StyledWizrButton>
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

const AppShare = (): JSX.Element => {
  
  const params = useParams();
  const id = params?.id;
  const token = params?.token;

  if(token){
    localStorage.setItem("appshare_token",token)
  }
  
  const loading = useContentLoadingSelector()
  
  const payload = {
    "token": token
    }
    useEffect(() => {
      const fetchData = async () => {
        try {
          // First API call
          await dispatchAppShareLinkValidation(payload);
    
          // Second API call
          dispatchAppShareDetails(id);
        } catch (error) {
          // Handle errors if needed
          console.error('Error occurred:', error);
        }
      };
    
      fetchData();
    }, []);
    
 
  const appDetails = useCurrentAppDetailsSelector()
  const validated = useAppShareLinkValidation()

 
 
  const [fileParams, setFileParams] = useState<any>([])
  const [filesData, setFilesData] = useState<any>([])


  const parameterList = appDetails?.nodes && appDetails?.nodes[0]?.data?.config?.parameters
  

  const parameters =(appDetails?.nodes && appDetails?.nodes[0]?.data?.config?.parameters || []).map((item: any) => item?.name);
 
  
  const [inputState, setInputState] = useState<any>({})

 

 
  const chatMessage = useChatMessageSelector()

  const [historyChats, setHistoryChats] = useState<{ user: string; assistant: string }[]>([])

  useEffect(() => {
    const user = chatMessage?.filter((item: any) => item.isSent).map((item: any) => item.text)
    const assistant = chatMessage?.filter((item: any) => !item.isSent).map((item: any) => item.text)
    if (user?.length === assistant?.length) {
      const result = user.map((value, index) =>
        Object.assign({}, { user: value, assistant: assistant?.[index] }),
      )
      setHistoryChats(result)
    }
  }, [chatMessage])

  
  useEffect(() => {    
    const parameterObject = parameters?.reduce((result:any, parameter:any) => {
      result[`{{${parameter}}}`] = "";
      return result;
    }, {});
  
    
    setInputState(parameterObject)
  }, [appDetails?.nodes && appDetails?.nodes[0]?.data?.config?.parameters])

 

  const onChatClick = async () => {    
    const testAppPayload = {
      id: appDetails?.id,
      app_id: appDetails?.app_id,
      history: appDetails?.memory ? historyChats : [],
      exec_type: "app",
      max_past_messages: appDetails?.memory ? 15 : 0,
      message_params: inputState,
      file_params: fileParams ? fileParams : [],
    }

    const formData = new FormData();
    const jsonData = JSON.stringify(testAppPayload);
   
    formData.append('data', jsonData);
  
   {
       try {
     
      dispatchAppShareToolTest(testAppPayload,appDetails?.app_id)
     
      
    } catch (error) {
      console.error('Error:', error);
      
    }
   }
  }

  const doUpdateFields = (fieldName: string, fieldValue: any) =>
    setInputState({
      ...inputState,
      [`{{${fieldName}}}`]: fieldValue,
    })

  const mapValueToUserQuery = (fieldValue: any) =>
    setInputState({
      ...inputState,
      '{{user_query}}': fieldValue,
    })

  return  <WizrFlexLayout style={{padding:"10px 0px"}}>
    
     {loading?<Spin/>:
     validated === "Success"?
     <>
    <WizrFlexLayout flex={.5} flexDirection="row" alignItems="flex-start" style={{ padding: " 15px" }}>

      <WizrFlexLayout flex={2.3} alignItems="flex-start">
        <WizrText type="sub1">{appDetails?.app_name}</WizrText>
      </WizrFlexLayout>

      <WizrFlexLayout flexDirection="row" flex={3} alignItems="flex-start" style={{ paddingLeft: "20px" }}>
        <WizrText type="sub1">Output</WizrText>
        {/* <StyledHeaderWizrButton type="link" icon={<HistoryIcon />} text={<WizrText type="button" textColor="#1D1F22" >history</WizrText>}></StyledHeaderWizrButton> */}
        
        {/* <WizrButton type="link" text={<WizrText type="button" textColor="black">view run results</WizrText>}></WizrButton> */}
      </WizrFlexLayout>


    </WizrFlexLayout>
    <WizrFlexLayout flex={9} flexDirection="row" style={{ paddingTop: "0" }}>

      <WizrFlexLayout flex={2.3} style={{ padding: " 0 10px" }} justifyContent="flex-start">

        
          <SingleRunContainerLeft parameters={parameterList} doUpdateFields={doUpdateFields} saveFileParams={setFileParams} saveFilesData={setFilesData} />
      </WizrFlexLayout>

      <WizrFlexLayout flex={3} style={{ padding: " 0 10px" }}>
        
          <WizrFlexLayout style={{ borderRadius: "16px", padding: "15px" }} background="white">
            <Chat chatMessage={chatMessage} onChatClick={onChatClick} mapValueToUserQuery={mapValueToUserQuery} appshare={true}/>
          </WizrFlexLayout>

      </WizrFlexLayout>


    </WizrFlexLayout>
    </>:<WizrText type="sub2">INVALID SESSION</WizrText>}
  </WizrFlexLayout>
}
export default AppShare;

