import {
    dispatchRunHistory,
  } from '../redux/actions/content'
  import {
    useRunHistorySelector,
  } from '../redux/selectors/content'
  import { useEffect, useState } from 'react'
  import * as XLSX from 'xlsx'
  import WizrTable from '../components/WizrTable'
  import WizrFlexLayout from '../components/WizrFlexLayout'
  import WizrUserJourney from '../components/WizrUserJourney'
  import WizrButton from '../components/WizrButton'
  import WizrText from '../components/WizrText'
  import { styled } from 'styled-components'
  import { useLocation, useNavigate, useParams } from 'react-router-dom'
  import DownloadIcon from '../assets/icons/DownloadIcon'
  import { ArrowLeftOutlined } from '@ant-design/icons'
  
  const StyledWizrTable = styled(WizrTable)``
  const StyledButtonWrapper = styled.div`
    border-radius: 50px;
    background: var(--grayscale-200, #eaecf1);
    width: 40px;
  `
  
  const RunHistory = (): JSX.Element => {
    const params = useLocation()
    const [appName,setAppName]=useState<string>('') 

   const Id =useParams()
    const app_id = Id?.id
    useEffect(() => {
    if (app_id){
        dispatchRunHistory(app_id)
    }
    }, [])
    const data: any = useRunHistorySelector()  


    useEffect(() => {
      if (data && data.length > 0) {
      setAppName(data[0]?.app_name)
      }
    }, [data])

    const updatedData = data?.map((item: any) => {
      const formated = item?.app_output !== "" ? JSON.parse(item?.app_output) : '';
      const duration=(item?.response_time - item?.run_time)/1000
      const dataUpdated = {
        ...item,
        output: formated?.content,
        duration:duration,
      };
      return dataUpdated;
    });    
    
     
    const navigate = useNavigate()

  
    const handleDownloadClick = () => {
      if (updatedData && updatedData.length > 0) {
        const columns = [
          'User_Input',
          'End_output',
          'Output_format',
          'Time_of_Run',
          'Time_of_Response',
          'Error',
          'Version',
          'User',
          'Status',
          "App_Name"
        ]
        const defaultWidth = 200
        const defaultHeight = 50
  
        const dataToExport =updatedData.map((item: any) => {
          
          const rowData: any = {
            User_Input:item?.app_input?.message_params || "NA",
            End_output:item?.output || "NA",
            Output_format:item?.output_format || "NA",
            Time_of_Run: new Date(item?.run_time).toLocaleString() || 'NA',
            Time_of_Response:new Date(item?.response_time).toLocaleString() || 'NA',
            Error:item?.error_message  || "NA",
            Version:item?.app_version || "NA",
            User:item?.user_name || "NA",
            Status:item?.status || "NA",
            App_Name:item?.app_name || "NA"
            
          }
  
          return rowData
        })
        const ws = XLSX.utils.json_to_sheet(dataToExport, { header: columns })
        columns.forEach((column, columnIndex) => {
          ws['!cols'] = ws['!cols'] || []
          ws['!cols'][columnIndex] = { wpx: defaultWidth }
        })
  
        for (let rowIndex = 1; rowIndex <= dataToExport.length; rowIndex++) {
          ws[`!rows`] = ws[`!rows`] || []
          ws[`!rows`][rowIndex] = { hpx: defaultHeight }
        }
        const formattedDate = new Date()
          .toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })
          .replace(/\//g, '')
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
        XLSX.writeFile(wb, `${appName}_${formattedDate}.xlsx`)
      }
    }
  
    return (
      <WizrFlexLayout>
        <WizrFlexLayout justifyContent='space-between' alignItems='center' flexDirection='row'>
          <WizrUserJourney
            userJourney={[
              {
                title: (
                  <WizrButton
                  icon={<ArrowLeftOutlined style={{ fontSize: "22px" ,marginRight:"10px"}}/>}
                    type='text'
                    text={<WizrText type='h6'>{appName}</WizrText>}
                    onClick={() => navigate(-1)}
                  />
                ),
              },
              {
                title: 'History',
              },
            ]}
          />
          <StyledButtonWrapper>
            <WizrButton icon={<DownloadIcon />} text='' type='text' onClick={handleDownloadClick} />
          </StyledButtonWrapper>
        </WizrFlexLayout>
  
        <WizrFlexLayout flex={6}>
          <StyledWizrTable
            type='apphistory'
            dataSource={updatedData && updatedData.sort((a: any, b: any) => b.run_time - a.run_time)}
            scroll={{ x: 'max-content' }}
            pagination={false}
          />
        </WizrFlexLayout>
      </WizrFlexLayout>
    )
  }
  
  export default RunHistory
  