import React from 'react';

interface CloseIconOutlinedProps {
  color?: string;
  size?: any;
}

const CloseIconOutlined: React.FC<CloseIconOutlinedProps> = ({ color = '#999B9F',size='25' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.44231 16.2929C6.05178 16.6834 6.05178 17.3166 6.44231 17.7071C6.83283 18.0976 7.466 18.0976 7.85652 17.7071L6.44231 16.2929ZM12.8565 12.7071C13.247 12.3166 13.247 11.6834 12.8565 11.2929C12.466 10.9024 11.8328 10.9024 11.4423 11.2929L12.8565 12.7071ZM11.4423 11.2929C11.0518 11.6834 11.0518 12.3166 11.4423 12.7071C11.8328 13.0976 12.466 13.0976 12.8565 12.7071L11.4423 11.2929ZM17.8565 7.70711C18.247 7.31658 18.247 6.68342 17.8565 6.29289C17.466 5.90237 16.8328 5.90237 16.4423 6.29289L17.8565 7.70711ZM12.8565 11.2929C12.466 10.9024 11.8328 10.9024 11.4423 11.2929C11.0518 11.6834 11.0518 12.3166 11.4423 12.7071L12.8565 11.2929ZM16.4423 17.7071C16.8328 18.0976 17.466 18.0976 17.8565 17.7071C18.247 17.3166 18.247 16.6834 17.8565 16.2929L16.4423 17.7071ZM11.4423 12.7071C11.8328 13.0976 12.466 13.0976 12.8565 12.7071C13.247 12.3166 13.247 11.6834 12.8565 11.2929L11.4423 12.7071ZM7.85652 6.29289C7.466 5.90237 6.83283 5.90237 6.44231 6.29289C6.05178 6.68342 6.05178 7.31658 6.44231 7.70711L7.85652 6.29289ZM7.85652 17.7071L12.8565 12.7071L11.4423 11.2929L6.44231 16.2929L7.85652 17.7071ZM12.8565 12.7071L17.8565 7.70711L16.4423 6.29289L11.4423 11.2929L12.8565 12.7071ZM11.4423 12.7071L16.4423 17.7071L17.8565 16.2929L12.8565 11.2929L11.4423 12.7071ZM12.8565 11.2929L7.85652 6.29289L6.44231 7.70711L11.4423 12.7071L12.8565 11.2929Z"
        fill={color}
      />
    </svg>
  );
}

export default CloseIconOutlined;
