import styled from "styled-components"
import WizrAvatar from "../../../components/WizrAvatar"
import WizrFlexLayout from "../../../components/WizrFlexLayout"
import WizrText from "../../../components/WizrText"
import { useEffect, useState } from "react"
import WizrInput from "../../../components/WizrInput"
import WizrButton from "../../../components/WizrButton"
import jwtDecode from "jwt-decode"
import { dispatchGetUserDetails, dispatchRolesList } from "../../../redux/actions/content"
import { useContentLoadingSelector, useGetTenantDetails, useGetUSerDetailsSelector, useListRolesSelectors } from "../../../redux/selectors/content"
import EditIcon from "../../../assets/icons/EditIcon"
import { MODAL_TYPES, useWizrModalContext } from "../../../providers/modal/WizrModalProvider"
import { log } from "console"
import { Spin } from "antd"
import { useAuth } from "../../../authContext"

const StyledWizrFlexLayout = styled(WizrFlexLayout)`
padding:10px;
align-items:center;

.sampleIcon {
    height: 100px;
    width: 100px;
    background: #ccc;
    border-radius: 50px;
    text-align:center;
    padding-top:25px;
    
  }`
  const StyledHeaderWizrButton = styled(WizrButton)`
border-radius: 20px;
background: var(--grayscale-200, #EAECF1);`

  const StyledWizrInput = styled(WizrInput)`
  background-color:#F2F4F9;
  `
  const StyledFormContainer = styled(WizrFlexLayout)`
  align-items:flex-start;
  justify-content:flex-start;
  background:white;
  gap:10px;
 

  `
const Profile = ():JSX.Element=>{
  const { showModal, hideModal } = useWizrModalContext()

  const token = localStorage.getItem("id_token")
  useEffect(()=>{
    dispatchRolesList()

    if (token){
      const tokenDecoded :{oid:any}= jwtDecode(token)
        const userId = tokenDecoded?.oid
        dispatchGetUserDetails(userId)
    }

  },[])

  if (token){
    const tokenDecoded :{oid:any}= jwtDecode(token)
      const userId = tokenDecoded?.oid
  }
    const userDetails =useGetUSerDetailsSelector()
    const tenantDetails = useGetTenantDetails()
    const roles= useListRolesSelectors()    



    useEffect(()=>{
      const userRoleKey = Object.keys(userDetails).find(key => key.includes('UserRole'));
      const displayRole = roles.map((item: any) => {
        if (userRoleKey && item?.role_key === userDetails[userRoleKey]) {
            return item?.display_name;
        } else {
            return null;
        }
    });
      let newUserDetails = { ...userDetails };
      if (userRoleKey) {
        newUserDetails = {
            ...userDetails,
            role: userDetails[userRoleKey],
            display_name_user:displayRole
        };
        
        setProfileDeatils(newUserDetails)
    }
    },[userDetails,roles])
const [profileDetails,setProfileDeatils] = useState(userDetails)

  const onUserEdit=()=>{
    
    showModal(MODAL_TYPES.EDIT_USER_DETAILS,{
      profileDetails,
      onCancel: hideModal,
    })
  } 
  const loading = useContentLoadingSelector()


  const resetPassword = ()=>{    
    localStorage.setItem("reset","true")
const urlForPasswordReset=`${process.env.REACT_APP_WIZR_B2C_AUTH_ENDPOINT}?p=${process.env.REACT_APP_WIZR_RESET_P}&client_id=${process.env.REACT_APP_WIZR_B2C_CLIENT_ID}&nonce=defaultNonce&redirect_uri=${process.env.REACT_APP_WIZR_B2C_REDIRECT_URI}&scope=openid&response_type=id_token&prompt=login`
window.location.href=urlForPasswordReset
}
const { userPermission, hasAcces } = useAuth();

    return (
      <WizrFlexLayout >
        {loading?<Spin/>:
        <WizrFlexLayout style={{borderRadius:"8px"}} flexDirection="row">
          <WizrFlexLayout flex={1} background="white">
            <></>
          </WizrFlexLayout>
          
<WizrFlexLayout flex={2} background="white" style={{padding:"10px"}}>
    <WizrFlexLayout background="white" justifyContent="flex-start">
<StyledWizrFlexLayout style={{maxHeight:"190px"}}background="white" >
  {/* <WizrFlexLayout style={{maxHeight:"20px"}} background="white" >
 </WizrFlexLayout> */}
<div className="sampleIcon">
    <WizrText type="h4" >{profileDetails?.displayName?profileDetails?.displayName[0]:""}</WizrText>
</div>
<WizrText type="sub2">{profileDetails?.displayName}</WizrText>
<WizrText type="body2" textColor="#707276">{profileDetails?.display_name_user}</WizrText>

</StyledWizrFlexLayout>
<WizrFlexLayout background="white" flexDirection="row" style={{gap:"10px",padding:"20px 0",maxHeight:"100px"}} alignItems='flex-start'>
  {/* <div style={{flexDirection:"row"}}> */}
    <WizrFlexLayout flexDirection="column" background="white">
    <WizrText type="body3" textColor=" #707276">Email</WizrText>
    <WizrText type="body2" textColor="#1D1F22">{profileDetails?.mail}</WizrText></WizrFlexLayout>

    {/* </div> */}
        <WizrFlexLayout flexDirection="column" background="white">

    <WizrText type="body3" textColor=" #707276">Phone</WizrText>
    <WizrText type="body2">{profileDetails?.mobilePhone}</WizrText>

    </WizrFlexLayout>
        <WizrFlexLayout flexDirection="column" background="white">

    <WizrText type="body3" textColor=" #707276">Company Name</WizrText>
    <WizrText type="body2">{tenantDetails?.tenant_name}</WizrText>

    </WizrFlexLayout>
    {/* <WizrText type="caption">Phone</WizrText>
    <StyledWizrInput value={profileDetails?.mobilePhone} disabled={true}></StyledWizrInput> */}


    </WizrFlexLayout>
    <WizrFlexLayout background="white"justifyContent="center">
      <WizrButton type="link" text={<WizrText type="link">Reset Password</WizrText>} onClick={()=>resetPassword()}></WizrButton>
    </WizrFlexLayout>
    </WizrFlexLayout>
</WizrFlexLayout>
<WizrFlexLayout flex={1} background="white" alignContent="flex-end" alignItems="flex-end" style={{padding:"5px"}}>
<StyledHeaderWizrButton type="link" text={<WizrText type="button" textColor="#1D1F22" >Edit Profile</WizrText>} icon={<EditIcon/>} 
          disabled={hasAcces('editprofile_settings') === true ? false : true}
 onClick={()=>{onUserEdit()}}></StyledHeaderWizrButton> 
    </WizrFlexLayout>


        </WizrFlexLayout>}
        </WizrFlexLayout>
    )
}

export default Profile