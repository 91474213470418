import {
  dispatchListFolders,
  dispatchCreateFolder,
  dispatchPromptHistory,
} from '../redux/actions/content'
import {
  useContentsSelector,
  useContentLoadingSelector,
  usePromptHistorySelector,
} from '../redux/selectors/content'
import { useEffect } from 'react'
import * as XLSX from 'xlsx'
import WizrTable from '../components/WizrTable'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrUserJourney from '../components/WizrUserJourney'
import WizrButton from '../components/WizrButton'
import WizrText from '../components/WizrText'
import { styled } from 'styled-components'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import DownloadIcon from '../assets/icons/DownloadIcon'
import { ArrowLeftOutlined } from '@ant-design/icons'

const StyledWizrTable = styled(WizrTable)``
const StyledButtonWrapper = styled.div`
  border-radius: 50px;
  background: var(--grayscale-200, #eaecf1);
  width: 40px;
`

const History = (): JSX.Element => {
  const params = useLocation()
const Id = useParams()
  useEffect(() => {
    if (Id?.id) {
      dispatchPromptHistory(Id?.id)
    }
  }, [])
  const data: any = usePromptHistorySelector() 
  const UpdatedData=data?.map((item:any)=>{
      const duration=(item?.llm_audit?.response_time - item?.run_time)/1000
      const dataUpdated = {...item,duration}
      return dataUpdated
     })
   
  const navigate = useNavigate()

  const handleDownloadClick = () => {
    if (UpdatedData && UpdatedData.length > 0) {
      const columns = [
        'llm_model',
        'query',
        'response',
        'Feedback',
        'Comment',
        'Parameters',
        'Temperature',
        'Token_Limit',
        'Zero_Shot',
        'Example',
        'Tokens_Used',
        'User_Name',
        'Time_Of_Response',
        'Time_Of_Run',
        'Duration_Of_Run',
        'Memory',
        'Knowledge_Base',
        'Stop',
        'Presence_Penalty',
        'Frequency_Penalty',
      ]
      const defaultWidth = 200
      const defaultHeight = 50

      const dataToExport = UpdatedData.map((item: any) => {
        
        const rowData: any = {
          llm_model: item.llm_model_name || 'NA',
          response: item.response || 'NA',
          query: item.query || 'NA',
          Feedback: item.feedback === 1 ? 'DISLIKE' : item.feedback === 0 ? 'LIKE' : 'NA',
          Comment: item.comment || 'NA',
          Parameters: Object.keys(item.message_params_test_values).length > 0
          ? Object.entries(item.message_params_test_values)
              .map(([key, value]) => `${key}: ${value}`)
              .join('\n')
              : 'NA',
        
          Temperature: item.prompt_body?.temperature || 'NA',
          Token_Limit: item.prompt_body?.max_tokens || 'NA',
          Zero_Shot: item.prompt_body?.zero_shot || 'NA',
          Example:
            item.prompt_body?.examples.length > 0
              ? item.prompt_body.examples
                  .map((example: any) => `User: ${example.user}, Assistant: ${example.assistant}`)
                  .join('\n')
              : 'NA',
          Tokens_Used: item.llm_audit?.total_tokens || 'NA',
          Time_Of_Response: new Date(item.llm_audit.response_time).toLocaleString() || 'NA',
          Time_Of_Run: new Date(item.run_time).toLocaleString(),
          Memory: item.memory_required?"True":'False' || 'NA',
          Duration_Of_Run:item?.duration || 'NA',
          Knowledge_Base: item.prompt_body?.knowledgebase_required || 'NA',
          Stop: item.prompt_body?.stop_sequences.length>0 && item.prompt_body?.stop_sequences ? item.prompt_body?.stop_sequences.join(","):"NA" ,
          Presence_Penalty: item.prompt_body?.presence_penalty || 'NA',
          Frequency_Penalty: item.prompt_body?.frequency_penalty || 'NA',
          User_Name: item.user_name || 'NA',
        }

        return rowData
      })
      const ws = XLSX.utils.json_to_sheet(dataToExport, { header: columns })
      columns.forEach((column, columnIndex) => {
        ws['!cols'] = ws['!cols'] || []
        ws['!cols'][columnIndex] = { wpx: defaultWidth }
      })

      for (let rowIndex = 1; rowIndex <= dataToExport.length; rowIndex++) {
        ws[`!rows`] = ws[`!rows`] || []
        ws[`!rows`][rowIndex] = { hpx: defaultHeight }
      }
      const formattedDate = new Date()
        .toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .replace(/\//g, '')
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, `${"app"}_${formattedDate}.xlsx`)
    }
  }

  return (
    <WizrFlexLayout>
      <WizrFlexLayout justifyContent='space-between' alignItems='center' flexDirection='row'>
        <WizrUserJourney
          userJourney={[
            {
              title: (
                <WizrButton
                icon={<ArrowLeftOutlined style={{ fontSize: "22px" ,marginRight:"10px"}}/>}
                  type='text'
                  text={<WizrText type='h6'>{"app"}</WizrText>}
                  onClick={() => navigate(-1)}
                />
              ),
            },
            {
              title: 'History',
            },
          ]}
        />
        <StyledButtonWrapper>
          <WizrButton icon={<DownloadIcon />} text='' type='text' onClick={handleDownloadClick} />
        </StyledButtonWrapper>
      </WizrFlexLayout>

      <WizrFlexLayout flex={6}>
        <StyledWizrTable
          type='history'
          dataSource={UpdatedData && UpdatedData.sort((a: any, b: any) => b.run_time - a.run_time)}
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

export default History
