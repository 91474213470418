const WhatsAppIcon = () => {
return(
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_12639_48610)">
<path d="M0.51209 11.8563C0.511527 13.8728 1.04253 15.8417 2.05222 17.5771L0.415527 23.5066L6.53103 21.9156C8.2225 22.8292 10.1177 23.308 12.0435 23.3081H12.0486C18.4062 23.3081 23.5815 18.1748 23.5842 11.8653C23.5855 8.80792 22.3866 5.93295 20.2084 3.76997C18.0306 1.60718 15.1342 0.415458 12.0481 0.414062C5.68972 0.414062 0.514809 5.54709 0.512184 11.8563" fill="#27D045"/>
<path d="M0.100313 11.8527C0.0996563 13.9417 0.649687 15.981 1.69537 17.7786L0 23.9207L6.33478 22.2726C8.08022 23.2168 10.0454 23.7147 12.0451 23.7154H12.0502C18.636 23.7154 23.9972 18.3975 24 11.8621C24.0011 8.69488 22.7591 5.71656 20.5031 3.47609C18.2468 1.23591 15.2468 0.00130233 12.0502 0C5.46337 0 0.102938 5.31721 0.100313 11.8527ZM3.87291 17.469L3.63637 17.0965C2.64206 15.5277 2.11725 13.7149 2.118 11.8534C2.12006 6.4213 6.57544 2.00186 12.054 2.00186C14.7071 2.00298 17.2005 3.02921 19.0759 4.89116C20.9512 6.7533 21.9831 9.22865 21.9824 11.8614C21.98 17.2935 17.5245 21.7135 12.0502 21.7135H12.0463C10.2638 21.7126 8.51569 21.2376 6.99113 20.34L6.62831 20.1265L2.86912 21.1045L3.87291 17.469Z" fill="url(#paint0_linear_12639_48610)"/>
<path d="M9.06334 6.89771C8.83965 6.4044 8.60425 6.39445 8.39153 6.3858C8.21734 6.37836 8.01821 6.37892 7.81928 6.37892C7.62015 6.37892 7.29662 6.45324 7.02315 6.74952C6.7494 7.04608 5.97803 7.76273 5.97803 9.22031C5.97803 10.6779 7.048 12.0866 7.19715 12.2845C7.3465 12.482 9.26275 15.5689 12.2976 16.7564C14.8199 17.7433 15.3332 17.547 15.8806 17.4975C16.4281 17.4482 17.6472 16.7811 17.8959 16.0892C18.1448 15.3975 18.1448 14.8046 18.0702 14.6807C17.9956 14.5572 17.7964 14.4831 17.4978 14.335C17.1992 14.1869 15.7312 13.4701 15.4576 13.3712C15.1838 13.2724 14.9848 13.2231 14.7857 13.5198C14.5865 13.8159 14.0147 14.4831 13.8405 14.6807C13.6664 14.8787 13.4921 14.9034 13.1936 14.7552C12.8948 14.6065 11.9332 14.2941 10.7924 13.2849C9.90475 12.4996 9.3055 11.5298 9.13131 11.2331C8.95712 10.937 9.11265 10.7764 9.26237 10.6288C9.39653 10.496 9.56106 10.2828 9.7105 10.1099C9.85937 9.93687 9.90906 9.81343 10.0086 9.61585C10.1083 9.41808 10.0584 9.24506 9.98387 9.09687C9.90906 8.94868 9.32884 7.48347 9.06334 6.89771Z" fill="white"/>
</g>
<defs>
<linearGradient id="paint0_linear_12639_48610" x1="1200" y1="2392.07" x2="1200" y2="0" gradientUnits="userSpaceOnUse">
<stop stop-color="#F9F9F9"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<clipPath id="clip0_12639_48610">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

)
}
export default WhatsAppIcon;