import { Input, Spin, message } from "antd"
import WizrFlexLayout from "../components/WizrFlexLayout"
import WizrText from "../components/WizrText"
import Copy from "../assets/icons/Copy"
import CodeEditor from '@uiw/react-textarea-code-editor';
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getTenantId } from "../utils";
import { dispatchAppApiIntegrate, dispatchCurrentModule, dispatchupdateUserView } from "../redux/actions/content";
import { useAppApiUrl, useContentLoadingSelector } from "../redux/selectors/content";
import WizrButton from "../components/WizrButton";
import copy from 'copy-to-clipboard';
import { useNavigate } from "react-router-dom";


const StyledInput = styled(Input)`
width:90%;
alignItems:center;
height:52px;
.ant-input-group-addon{
    background: #DCDEE2;
}
`
const StyledTextButton = styled(WizrText)`
  /* Apply underline style and default cursor */
  text-decoration: underline !important;
  cursor: pointer !important;;
`;

const APIIntegration = (appDetails:any):JSX.Element=>{
    type MessageParams = {
        [key: string]: string;
      };
    const [messageParams,setMessageParams]=useState<MessageParams>({})
    const loading = useContentLoadingSelector()
    const appIntegrateUrl = useAppApiUrl()
    const tenantId = getTenantId()
    const navigate = useNavigate()
  const parameters = (appDetails?.appDetails?.nodes[0]?.data?.config?.parameters || []).map((item: any) => item?.name);

 parameters.forEach((param: string) => {
  messageParams[`{{${param}}}`] = `<${param}>`;
});
 const customTheme = {
  default: '#333', // Default text color
 }
  
const payload = {  
    "app_id": appDetails?.appDetails?.app_id,  
    "history": [],  
    "exec_type": "app",  
    "message_params": messageParams
}

    const response = {  
        "id": "<RUN_ID>",  
        "statusQueryGetUri": "RESPONSE_URL " 
      }
      const output = 
        {
          "name": "app_orchestrator",
          "instanceId": "<INSTANCE_ID>",
          "runtimeStatus": "<RUNTIMESTATUS>",
          "input": "<INPUT_DATA>",
          "customStatus": "Done",
          "output": "<OUTPUT>",
          "createdTime": "<CREATED_TIME>",
          "lastUpdatedTime": "<LASTUPDATED_TIME>"
      }
      
      

  
    const inputRef = useRef<any>(null);

    useEffect(() => {
        const payloadAPIIntegarte= {
            id: appDetails?.appDetails?.id,
            app_id: appDetails?.appDetails?.app_id,
            exec_type:"SYNC",
            }
        dispatchAppApiIntegrate(payloadAPIIntegarte)
    
    }, [])
    
  const copyUrl=()=>{
    message.success('Copied to clipboard', 2); 
                        copy(appIntegrateUrl)
  }

  const goToAccounts = () => {
    navigate('/settings')
    dispatchupdateUserView({
      activeKey: 'accounts',
    })
    dispatchCurrentModule('settings')
  }
    return(
        <WizrFlexLayout justifyContent="flex-start" textAlign="left" alignContent="flex-start" alignItems="flex-start" flex={9}>
            <WizrText type='sub1'> APP - Integrate Wizr easily using below APIS</WizrText>
            <WizrFlexLayout background="white" textAlign="left" style={{padding:"20px 60px",margin:"20px 0px",borderRadius:"8px",gap:"12px"}}justifyContent="flex-start"  alignContent="flex-start" alignItems="flex-start">
                <WizrText type="sub1" textColor="black">Overview</WizrText>
                <WizrText type="body2" textColor="#5D5F62">This API is used to invoke external app execution in Wizr AI Core.</WizrText>
                <WizrText type="sub1" textColor="black">API Endpoint</WizrText>

                <StyledInput  
                addonBefore={<WizrText type="sub1">POST</WizrText>} 
                value={appIntegrateUrl}
                suffix={<WizrButton type="link" icon={loading?<Spin/>:<Copy />} onClick={()=>copyUrl()}></WizrButton>}></StyledInput>
                <WizrText type="sub1" textColor="black">Headers</WizrText>
                <div>
                <WizrText type="body2" textColor="#5D5F62"> Wizr-Access-Key:</WizrText><WizrText type="body6"> API_KEY</WizrText></div>
                {/* <WizrText type="body2" textColor="#5D5F62"> This key is the subscription key and must be included in the request headers. You can find the  <WizrText type="body6"> API_KEY</WizrText> in your account settings. Click <WizrButton text={<StyledTextButton type='link' >here</StyledTextButton>}
              type='link'
              onClick={()=>{}} /> 
              to get the details.</WizrText> */}
<WizrText type="body2" textColor="#5D5F62">This key is the subscription key and must be included in the request headers. You can find the <WizrText type="body6">API_KEY</WizrText> in your account settings. Click <StyledTextButton type='link' onClick={()=>{goToAccounts()}}> here
</StyledTextButton> to get the details.</WizrText>

                <WizrText type="sub1" textColor="black"> Request Body</WizrText>
                <WizrText type="body2" textColor="#5D5F62">The request body is of form-data type and should include the following parameters:</WizrText>                
                <ul>
        <li ><WizrText type="body2"textColor="#5D5F62">  <WizrText type="body6"> app_id </WizrText>: String, required. The unique identifier of the application. For this endpoint, the app_id is  <WizrText type="body6">{appDetails?.appDetails?.app_id} </WizrText>.</WizrText>
</li>
<li ><WizrText type="body2"textColor="#5D5F62">  <WizrText type="body6"> history </WizrText>: Array, optional. An array of previous interactions or executions.</WizrText></li>
<li ><WizrText type="body2"textColor="#5D5F62">  <WizrText type="body6"> exec_type </WizrText>: String, required. The type of execution. For this endpoint, the <WizrText type="body6"> exec_type </WizrText> is <WizrText type="body6">   &ldquo;app  &ldquo; </WizrText>.</WizrText></li>
<li ><WizrText type="body2"textColor="#5D5F62">  <WizrText type="body6"> message_params </WizrText>: Object, required. The parameters for the message.</WizrText></li>

    </ul>
                {/* <WizrText type="body2" textColor="#5D5F62"> To authenticate your requests, include your API key in the headers of your HTTP requests. You can find your API key in the admin portal</WizrText>
                <WizrText type="sub1" textColor="#5D5F62">API KEY </WizrText>
                <WizrText type="body2" textColor="#5D5F62">
                    Include the following headers in your requests:<br/>
                    Content-Type: Set to application/json when sending data in the request body.<br/>
                    Authorization: Include your API key using the Bearer token authentication method</WizrText> */}
                <WizrText type="sub2" textColor="#5D5F62">Example </WizrText>
                <CodeEditor       
                language="python"
                 ref={inputRef}          
                 value={JSON.stringify(payload, null, 2)} 
                 style={{
                   fontSize: 15,
                   width: "90%",
                   minHeight: '250px',
                   backgroundColor: "black",
                   color:"white",
                   borderRadius:"20px"
                 }}/>
                <WizrText type="sub1" textColor="black">Response </WizrText>
                <WizrText type="body2" textColor="#5D5F62">The response from this API will be a JSON object in the following format</WizrText>                
                <CodeEditor       
                language="python"
                 ref={inputRef}          
                 value={JSON.stringify(response, null, 2)} 
                 style={{
                   fontSize: 15,
                   width: "90%",
                   minHeight: '100px',
                   backgroundColor: "black",
                   color:"white",
                   borderRadius:"20px"
                 }}/>
                 <WizrText type="body2"textColor="#5D5F62"> The <WizrText type="body6"> statusQueryGetUri </WizrText>(GET) needs to be polled to get the result of execution. This should include the following key in the header with value set to the API Key:<WizrText type="body6"> --header &ldquo; Wizr-Access-Key: API_KEY&ldquo; . </WizrText></WizrText>
                 <CodeEditor       
                language="python"
                 ref={inputRef}      
    
                 value={JSON.stringify(output, null, 2)} 
                 style={{
                   fontSize: 15,
                   width: "90%",
                   minHeight: '230px',
                   backgroundColor: "black",
                   borderRadius:"20px"}}/>
                 <WizrText type="body2"textColor="#5D5F62"> The <WizrText type="body6"> runtimeStatus </WizrText>attribute indicates the completion status, which could be<WizrText type="body6" >  &ldquo; Completed&ldquo; , &ldquo; Failed&ldquo; , &ldquo; Pending&ldquo;  or &ldquo; Running&ldquo;  .</WizrText>The API needs to be polled until the status is either
                 <WizrText type="body6">   &ldquo; Completed &ldquo;  or  &ldquo; Failed &ldquo; . </WizrText></WizrText>
                 
                 <WizrText type="body2" textColor="#5D5F62"> The<WizrText type="body6"> output</WizrText> key in the provided JSON object can have two different structures:</WizrText>
                 <ol>
 <li ><WizrText type="body2"textColor="#5D5F62">  <WizrText type="body6"> Error Case</WizrText>: The &ldquo;output&ldquo; key contains an error message string.Example <WizrText type="body6"> &ldquo;&#123;\&ldquo;error\&ldquo; : &ldquo;Error_Message_String&ldquo;&#125;&ldquo;</WizrText></WizrText></li>
<li ><WizrText type="body2"textColor="#5D5F62">  <WizrText type="body6"> Content Case</WizrText>: The &ldquo;output&ldquo; key contains  content data..Example <WizrText type="body6"> &ldquo;&#123;\&ldquo;content\&ldquo; : &ldquo;Output_Data&ldquo;&#125;&ldquo;</WizrText></WizrText></li>

    </ol>
                 <WizrText type="sub1" textColor="black">Error Handling</WizrText>
 <WizrText type="body2" textColor="#5D5F62"> If there is an error in the request, the API will return an HTTP error status code along with a description of the error in the response body. Please refer to HTTP status code definitions to understand the nature of the error.
</WizrText>


          </WizrFlexLayout>
        </WizrFlexLayout>
    )
}
export default APIIntegration