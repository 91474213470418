import { store } from '../store'

const { dispatch } = store ?? {}

const dispatchCreateFolder = ({ currentFolderName, currentFolderDescription, currentembeddingModel }: any) =>
  dispatch.contentModel.createFolderEffect({ currentFolderName, currentFolderDescription, currentembeddingModel })
const dispatchUpdateCurrentFolder = (folderDetail: any) =>
  dispatch.contentModel.updateCurrentFolder(folderDetail)
const dispatchListFolders = (type: string) => dispatch.contentModel.listFoldersEffect(type)
//for hardcoding remove this after use
const dispatchTempListFolders = (type: string) => dispatch.contentModel.listTempFoldersEffect(type)

const dispatchDeleteFolder = ({ folderId, type }: any) =>
  dispatch.contentModel.deleteFolderEffect({ folderId, type })
const dispatchUpdateFolder = ({ payload, type }: any) =>
  dispatch.contentModel.updateFolderEffect({ payload, type })
const dispatchListFiles = (folderId: string) => dispatch.contentModel.listFilesEffect(folderId)
const dispatchUpdateFile = ({ file_id, file_name, file_tags }: any) =>
  dispatch.contentModel.updateFileEffect({ file_id, file_name, file_tags })
const dispatchDeleteFile = (fileId: string) => dispatch.contentModel.deleteFile(fileId)
const dispatchTemplates = () => dispatch.contentModel.listPromptTemplates()
const dispatchPromptPublished = () =>
  dispatch.contentModel.listPublishedPrompts()
const dispatchPromptDraft = () =>
  dispatch.contentModel.listDraftPrompts()
const dispatchUploadFile = ({ file_names, folder_id, tags, type }: any) =>
  dispatch.contentModel.uploadFile({ file_names, folder_id, tags, type })
const dispatchManagePrompt = (payload: any) => dispatch.contentModel.managePrompts(payload)
const dispatchManagePromptApp = (payload: any) => dispatch.contentModel.managePromptsApp(payload)

const dispatchAddComments = (payload: any) => dispatch.contentModel.addComments(payload)
const dispatchPromptHistory = (promptId: string) =>
  dispatch.contentModel.listPromptHistory(promptId)
const dispatchPublishPrompt = (id?: any) => dispatch.contentModel.publishPromptDraft(id)
const dispatchUnpublishPrompt = (id?: any) => dispatch.contentModel.unpublishPromptPublished(id)
const disaptchPromptTest = (payload: any) => dispatch.contentModel.promptTestChat(payload)
const dispatchChatMessage = (payload: any) => dispatch.contentModel.updateChatMessage(payload)
const dispatchLLMModels = () => dispatch.contentModel.LLModelList()
const dispatchVectorDb = () => dispatch.contentModel.getVectorDb()

const dispatchGetPromptDetails = (promptId: string) => {
  dispatch.contentModel.getPromptDetails({ promptId })
}
const dispatchGetPromptTemplateDetails = (promptId: string) => {
  dispatch.contentModel.getPromptTemplateDetails({ promptId })
}

const dispatchPromptData = (payload: any) => dispatch.contentModel.updatePromptData(payload)
const dispatchAppData = () => dispatch.contentModel.listAppTemplates()
const dispatchAppPublished = () =>
  dispatch.contentModel.listAppPublished()
const dispatchAppDraft = () =>
  dispatch.contentModel.listAppDraft()

const dispatchClearChat = () => dispatch.contentModel.clearChatMessage()
const dispatchCreateApp = (payload: any) => dispatch.contentModel.createApp(payload)
const dispatchCreateAppNewtab = (payload: any) => dispatch.contentModel.createAppNewtab(payload)
const dispatchUpdateApp = (payload: any) => dispatch.contentModel.updateApp(payload)
const dispatchSaveCurrentAppDetails = (payload: any) =>
  dispatch.contentModel.updateCurrentAppDetails(payload)
const dispatchAppToolTest = (payload: any) => dispatch.contentModel.appToolTest(payload)
const dispatchDeleteApp = (appId: string) => dispatch.contentModel.deleteApp(appId)
const dispatchAppDetails = (appId: any) => {
  dispatch.contentModel.getAppDetails({ appId })
}
const dispatchPublishedAppEditDetails = (appId: any, type: any) => {
  dispatch.contentModel.getPublishedAppEditDetails({ appId, type })
}
const dispatchAppDetailsTemplates = (appId: any) => {
  dispatch.contentModel.getAppDetailsTemplate({ appId })
}

const dispatchAppShareDetails = (appId: any) => {
  dispatch.contentModel.getAppShareDetails({ appId })
}

const dispatchQueryUrls = (url: any) => {
  dispatch.contentModel.stopGeneration(url)
}
const dispatchPublishApp = (payload: any) => dispatch.contentModel.publishApp(payload)
const dispatchAppHistory = (appId: string) => dispatch.contentModel.listAppHistory(appId)
const dispatchAppVersions = (appId: string, type: string, isTemplate: boolean) => dispatch.contentModel.listAppVersions({ appId, type, isTemplate })
const dispatchGetRunStatus = (appId: string, execId: any, appname: any) => dispatch.contentModel.getBulkTestStatus({ appId, execId, appname })
const dispatchAppShareToolTest = (payload: any, appId: any) => dispatch.contentModel.appToolShareTest({ payload, appId })
const dispatchRunHistory = (appId: string) => dispatch.contentModel.listRunHistory(appId)
const dispatchTags = () => dispatch.contentModel.listTags()
//const dispatchPromptTags = () =>  dispatch.contentModel.listPromptTags()
const dispatchAppShareLinkGeneration = (payload: any) => dispatch.contentModel.appShareLinkGeneration(payload)
const dispatchAppShareLinkValidation = (payload: any) => dispatch.contentModel.appShareLinkValidation(payload)
const dispatchbulkrunStatusApp = (payload: any) => dispatch.contentModel.bulkrunStatusApp(payload)

const dispatcVisionMModels = () => dispatch.contentModel.VisionModalList()
const dispatchVoiceModels = () => dispatch.contentModel.VoiceModelList()
const dispatchModels = () => dispatch.contentModel.getModels()


const dispatchUploadFileUsingLink = (payload: any) => dispatch.contentModel.uploadFilesusingLink(payload)
const dispatchFileUploadMultiplePaths = (fileInfo: any, name: any, appIds: any) => dispatch.contentModel.uploadFileMultipleApps({ fileInfo, name, appIds })

const dispatchenduserapi = (payload: any) => dispatch.contentModel.enduserapi(payload)

const dispatchgenerateFileUpload = (payload: any, metadata: any) => dispatch.contentModel.generateSAStoken({ payload, metadata })
const dispatchTerminateBulkrun = (payload: any) => dispatch.contentModel.terminateBulkrun(payload)
const dispatchExecuteBulkRun = (payload: any) => dispatch.contentModel.executeBulkrun(payload)
const dispatchAppApiIntegrate = (payload: any) => dispatch.contentModel.appApiIntegrate(payload)
const dispatchEnableDisableUser = (payload: any) => dispatch.contentModel.enableDisableUser(payload)
const dispatchListUsers = (type: any) => dispatch.contentModel.listUsers(type)
const dispatchUpdateUserDetails = (payload: any) => dispatch.contentModel.updateUserDetails(payload)
const dispatchCreateUser = (payload: any) => dispatch.contentModel.createUser(payload)
const dispatchDeleteUser = (userId: any) => dispatch.contentModel.deleteUser(userId)
const dispatchGetUserDetails = (userId: any) => dispatch.contentModel.getUserDetails(userId)
const dispatchListTenants = () => dispatch.contentModel.getTenantList()
const dispatchCreateTenants = (payload: any) => dispatch.contentModel.createTenants(payload)
const dispatchUpdateTenants = (payload: any) => dispatch.contentModel.updateTenants(payload)
const dispatchClearUserList = () => dispatch.contentModel.updateUserList()
const dispatchAccessList = () => dispatch.contentModel.getAccessList()
const dispatchRolesList = () => dispatch.contentModel.getRoleList()
const dispatchDeleteAccess = (accessId: any) => dispatch.contentModel.deleteAccess(accessId)
const dispatchCreateRole = (payload: any) => dispatch.contentModel.createRole(payload)
const dispatchUpdateRole = (payload: any) => dispatch.contentModel.updateRole(payload)
const dispatchDeleteRole = (roleId: any) => dispatch.contentModel.deleteRole(roleId)
const dispatchTenantDetails = () => dispatch.contentModel.getTenanatDetails()
const dispatchSetPermissionValues = (idToken: string) => dispatch.contentModel.setPermissionValues(idToken)
const dispatchPackageList = () => dispatch.contentModel.getPackageList()
const dispatchProuctsList = () => dispatch.contentModel.getProductList()
const dispatchUpdateProduct = (payload: any) => dispatch.contentModel.updateProduct(payload)
const dispatchCreatePackage = (payload: any) => dispatch.contentModel.createPackage(payload)
const dispatchUpdatePackage = (payload: any) => dispatch.contentModel.updatePackages(payload)
const dispatchDeletePackages = (packageId: any) => dispatch.contentModel.deletePackage(packageId)
const dispatchDeleteTenants = (Id: any) => dispatch.contentModel.deleteTenants(Id)
const dispatchDisableTenants = (Id: any) => dispatch.contentModel.disableTenant(Id)
const dispatchupdateUserView = (payload: any) => dispatch.contentModel.updateViewUser(payload)
const dispatchEnableTenants = (Id: any) => dispatch.contentModel.enableTenant(Id)
const dispatchIntegrationList = () => dispatch.contentModel.getIntegrationList()
const dispatchMasterIntegrationList = () => dispatch.contentModel.getMasterTenantIntegrations()
const dispatchDeletIntegration = (integrationId: any) => dispatch.contentModel.deleteIntegration(integrationId)
const dispatchUpdateIntegration = (payload: any) => dispatch.contentModel.updateIntegration(payload)
const dispatchCreateIntegration = (payload: any) => dispatch.contentModel.createIntegration(payload)
const dispatchListAppForPackages = () => dispatch.contentModel.listAppForPackage()
const dispatchListAppGeneral = () => dispatch.contentModel.listAppGeneral()
const dispatchSetAsDefault = (payload: any) => dispatch.contentModel.setAsDefault(payload)
const dispatchFeatureSettings = () => dispatch.contentModel.getFeatureSettings()
const diaptchUpdateFileUploadStatus = (fileToReplace: any) => dispatch.contentModel.updateUploadedFileValues(fileToReplace)
const dispatchUpdateFeatureSettingsStatus = (payload: any) => dispatch.contentModel.updateFetaureSettingsStatus(payload)
const dispatchCreateFeatureIntegrationSettings = (payload: any) => dispatch.contentModel.createFeatureIntegrationSettings(payload)
const dispatchCloneAutoSolveFeature = (payload: any) => dispatch.contentModel.cloneAutosolveFeature(payload)
const dispatchCreateFeatureIntegrationSettingsAutoSolve = (data: any) => dispatch.contentModel.createFeatureIntegrationSettingsAutoSolve(data)
const dispatchCreateFolderGroup = (payload: any, type: any) => dispatch.contentModel.createGroupFolder({ payload, type })
const dispatchSetAsTemplate = (payload: any) => dispatch.contentModel.setAsTemplate(payload)
const dispatchTicketDetails = (payload: any) => dispatch.contentModel.updateTicketDetail(payload)
const dispatchListAutoTags = () => dispatch.contentModel.getAutoTagList()
const dispatchUpdateTicketType = (payload: any, type: any) => dispatch.contentModel.updateTicketType({ payload, type })
const dispatchCompanyInfo = (payload: any) => dispatch.contentModel.updateCompanyInfo(payload)
const dispatchUpdatePersona = (payload: any) => dispatch.contentModel.updatePersona(payload)
const dispatchGetFeatureAppDetails = (appId: any) => dispatch.contentModel.getFeatureAppDetails(appId)
const dispatchGetPackageDetails = (type: any) => dispatch.contentModel.getPacakageDetails(type)
const dispatchUpdateFeatureSettings = (payload: any) => dispatch.contentModel.editFeatureSettings(payload)
const dispatchGetEndPointUrl = (payload: any) => dispatch.contentModel.getEndPointUrl(payload)
const dispatchGetTenantGroups = (Id: any) => dispatch.contentModel.getTenantGroups(Id)
const dispatchSaveTenantGroups = (payload: any) => dispatch.contentModel.saveTenantGroups(payload)
const dispatchCreateFodlerFaqPt = (payload: any) => dispatch.contentModel.createFolderFaqPT(payload)
const dispatchListUserRows = (type: any, folderId: any) => dispatch.contentModel.getGroupRowDetails({ type, folderId })
const dispatchDeleteRowDetails = (type: any, rowId: any) => dispatch.contentModel.deleteGroupRowDetails({ type, rowId })
const dispatchDeleteFaqPastTicketGroups = (type: any, folderId: any) => dispatch.contentModel.deleteGroupsFaqPastTickets({ type, folderId })
const dispatchGetIntegrationToolsForTenants = () => dispatch.contentModel.getIntegrationToolsForTenant
const dispatchGetIntegrationForTools = (tool: any) => dispatch.contentModel.getIntegrationsForSelectedTool(tool)
const dispatchEmbeddingModelList = () => dispatch.contentModel.embeddingModelList()
const dispatchImportPastTickets = (payload: any) => dispatch.contentModel.importPastTickets(payload)

const dispatchTopIntentsQuery = (payload: any) => dispatch.contentModel.topIntentsQuery(payload)
const dispatchTopIncreasedIntenetQuery = (payload: any) => dispatch.contentModel.topIncreasedIntenetQuery(payload)
const dispatchTopDecreasedIntentQuery = (payload: any) => dispatch.contentModel.topDecreasedIntentQuery(payload)
const dispatchTotalIntentsCountQuery = (payload: any) => dispatch.contentModel.totalIntentsCountQuery(payload)
const dispatchTicketBasedOnIntent = (payload: any) => dispatch.contentModel.ticketsBasedonIntent(payload)
const dispatchIntentsGrpahData = (payload: any) => dispatch.contentModel.IntentsGraphData(payload)
const dispatchIntentsList = (payload: any) => dispatch.contentModel.IntentsLists(payload)
const dispatchTimetoResponse = (payload: any) => dispatch.contentModel.TimeToResponse(payload)
const dispatchTimetoResolve = (payload: any) => dispatch.contentModel.TimeToResolve(payload)
const dispatchTicketEscallation = (payload: any) => dispatch.contentModel.TicketEscallation(payload)
const dispatchFirstContactResolution = (payload: any) => dispatch.contentModel.FirstContactResolution(payload)
const dispatchCommonTags = (payload: any) => dispatch.contentModel.CommonTags(payload)

const dispatchSubscriptionDetails = () => dispatch.contentModel.getSubscriptionDetails()
const dispatchCurrentModule = (payload: any) => dispatch.contentModel.updateCurrentModule(payload)
const dispatchDeleteTicketsFaqPt = (rowId: any, type: any) => dispatch.contentModel.deleteTicketsFaqPt({ rowId, type })
const dispatchCustomerHealthDetails = (customer: any) => dispatch.contentModel.updateCustomerHealth(customer)
const dispatchTestChatBot = (payload: any) => dispatch.contentModel.testChatBot(payload)
const dispatchTestChatBotAdithyaBirla = (payload: any) => dispatch.contentModel.testChatBotAdithyaBirla(payload)
const dispatchClearChatBotHistory = () => dispatch.contentModel.clearChatBotHistory()
const dispatchChatMessageState = (payload: any) => dispatch.contentModel.updateChatMessageState(payload)
const dispatchListAllIntents = (payload: any) => dispatch.contentModel.listAllIntents(payload)
const dispatchFiltervalues = (payload: any) => dispatch.contentModel.controlroomFilerValues(payload)
const dispatchAppInsights = (payload: any) => dispatch.contentModel.appInsights(payload)
const dispatchBotDetails = (payload: any) => dispatch.contentModel.getBotDetails(payload)
const dispatchClearBotDetails = () => dispatch.contentModel.clearChatDetails()
const dispatchUpdateBot = (payload: any, type?: any) => dispatch.contentModel.updateBot({payload, type})
const dispatchAutoSolveBot = ({ payload, errorMessage }: any) => dispatch.contentModel.testAutoSolveBot({ payload, errorMessage })
const dispatchListWidgets = () => dispatch.contentModel.listWidgets()
const dispatchGetWidgetTypes = () => dispatch.contentModel.listWidgetTypes()
const dispatchCreateWidget = (payload: any) => dispatch.contentModel.createWidget(payload)
const dispatchUpdateWidget = (payload: any) => dispatch.contentModel.updateWidget(payload)
const dispatchTestWidget = (payload: any) => dispatch.contentModel.testWidget(payload)
const dispatchGetAgentList = (payload: any) => dispatch.contentModel.getAgentList(payload)
const dispatchGetInstallationList = () => dispatch.contentModel.getInstallationList()
const dispatchUpdateSelectedTools = (payload: any) => dispatch.contentModel.updateSelectedTools(payload)
const dispatchCreateAgent = (payload: any) => dispatch.contentModel.createAgent(payload)
const dispatchCreateBot = (payload: any) => dispatch.contentModel.createBot(payload)
const dispatchUpdateAgent = (payload: any) => dispatch.contentModel.updateAgentValues(payload)
const dispatchDeleteAgent = (payload: any) => dispatch.contentModel.deleteAgent(payload)
const dispatchDeleteWidget = (payload: any) => dispatch.contentModel.deleteWidget(payload)
const dispatchGetWidgetDetails = (payload: any) => dispatch.contentModel.getWidgetDetails(payload)
const dispatchGetBotRunHistory = (payload: any) => dispatch.contentModel.getBotRunHistory(payload)
const dispatchGetBotRunHistoryOfConversation = (payload: any) => dispatch.contentModel.getBotRunHistoryofConversation(payload)
const dispatchUpdateChatdetailsToEmpty = (payload: any) => dispatch.contentModel.updateAutosolveRunHistoryDetailsOfConversation(payload)
const dispatchAgentConversationList = (payload: any) => dispatch.contentModel.getAgentConversationList(payload)
const dispatchGetAgentConversationDetails= (payload: any) => dispatch.contentModel.getAgentAssistConversationDetail(payload)
const dispatchUpdateAgentChatdetailsToEmpty = (payload: any) => dispatch.contentModel.updateAgentConversationDetail(payload)
const dispatchGenerateCode = (payload: any) => dispatch.contentModel.generateCode(payload)
const dispatchBotRunHistory = (bot_id:any,id:any,headers:any) =>{ 
  dispatch.contentModel.getBotRunHistoryWebFormat({bot_id,id,headers})
}
const dispatchSaveFeedback = (payload:any,user_id:any,headers:any) => dispatch.contentModel.saveFeedback({payload,user_id,headers})
const dispatchTestApp = (appPayload: any, headers: any) => dispatch.contentModel.testApp({ appPayload, headers });
const dispatchGetAutosolveConversation = (payload:any) => dispatch.contentModel.getAutosolveConversationDetail(payload)
const dispatchClearFileParams = (payload:any) => dispatch.contentModel.clearFileParams(payload)




export {
  dispatchCreateFolder,
  dispatchUpdateCurrentFolder,
  dispatchListFolders,
  dispatchTempListFolders,
  dispatchDeleteFolder,
  dispatchUpdateFolder,
  dispatchListFiles,
  dispatchUpdateFile,
  dispatchDeleteFile,
  dispatchTemplates,
  dispatchAppPublished,
  dispatchUploadFile,
  dispatchManagePrompt,
  dispatchAddComments,
  dispatchPromptHistory,
  dispatchPublishPrompt,
  dispatchUnpublishPrompt,
  disaptchPromptTest,
  dispatchChatMessage,
  dispatchLLMModels,
  dispatchGetPromptDetails,
  dispatchPromptData,
  dispatchAppData,
  dispatchAppDraft,
  dispatchClearChat,
  dispatchCreateApp,
  dispatchCreateAppNewtab,
  dispatchUpdateApp,
  dispatchSaveCurrentAppDetails,
  dispatchAppToolTest,
  dispatchDeleteApp,
  dispatchAppDetails,
  dispatchPublishedAppEditDetails,
  dispatchQueryUrls,
  dispatchPublishApp,
  dispatchAppHistory,
  dispatchAppVersions,
  dispatchGetRunStatus,
  dispatchAppShareToolTest,
  dispatchRunHistory,
  dispatchTags,
  dispatchAppShareDetails,
  dispatchAppShareLinkGeneration,
  dispatchAppShareLinkValidation,
  dispatchbulkrunStatusApp,
  dispatcVisionMModels,
  dispatchUploadFileUsingLink,
  dispatchgenerateFileUpload,
  dispatchTerminateBulkrun,
  dispatchenduserapi,
  dispatchExecuteBulkRun,
  dispatchAppApiIntegrate,
  dispatchManagePromptApp,
  dispatchGetPromptTemplateDetails,
  dispatchPromptPublished,
  dispatchPromptDraft,
  dispatchAppDetailsTemplates,
  dispatchVectorDb,
  dispatchEnableDisableUser,
  dispatchListUsers,
  dispatchUpdateUserDetails,
  dispatchCreateUser,
  dispatchDeleteUser,
  dispatchGetUserDetails,
  dispatchListTenants,
  dispatchCreateTenants,
  dispatchClearUserList,
  dispatchAccessList,
  dispatchRolesList,
  dispatchDeleteAccess,
  dispatchCreateRole,
  dispatchUpdateRole,
  dispatchDeleteRole,
  dispatchTenantDetails,
  dispatchSetPermissionValues,
  dispatchPackageList,
  dispatchProuctsList,
  dispatchUpdateProduct,
  dispatchCreatePackage,
  dispatchUpdatePackage,
  dispatchUpdateTenants,
  dispatchDeletePackages,
  dispatchDeleteTenants,
  dispatchDisableTenants,
  dispatchupdateUserView,
  dispatchEnableTenants,
  dispatchVoiceModels,
  dispatchModels,
  dispatchIntegrationList,
  dispatchMasterIntegrationList,
  dispatchDeletIntegration,
  dispatchUpdateIntegration,
  dispatchCreateIntegration,
  dispatchListAppForPackages,
  dispatchListAppGeneral,
  dispatchSetAsDefault,
  dispatchFeatureSettings,
  diaptchUpdateFileUploadStatus,
  dispatchUpdateFeatureSettingsStatus,
  dispatchCreateFeatureIntegrationSettings,
  dispatchCreateFolderGroup,
  dispatchSetAsTemplate,
  dispatchTicketDetails,
  dispatchListAutoTags,
  dispatchUpdateTicketType,
  dispatchCompanyInfo,
  dispatchUpdatePersona,
  dispatchGetFeatureAppDetails,
  dispatchGetPackageDetails,
  dispatchUpdateFeatureSettings,
  dispatchGetEndPointUrl,
  dispatchGetTenantGroups,
  dispatchSaveTenantGroups,
  dispatchFileUploadMultiplePaths,
  dispatchCreateFodlerFaqPt,
  dispatchListUserRows,
  dispatchDeleteRowDetails,
  dispatchDeleteFaqPastTicketGroups,
  dispatchGetIntegrationToolsForTenants,
  dispatchGetIntegrationForTools,
  dispatchEmbeddingModelList,
  dispatchImportPastTickets,
  dispatchSubscriptionDetails,
  dispatchTopIntentsQuery,
  dispatchTopIncreasedIntenetQuery,
  dispatchTopDecreasedIntentQuery,
  dispatchTotalIntentsCountQuery,
  dispatchTicketBasedOnIntent,
  dispatchIntentsGrpahData,
  dispatchIntentsList,
  dispatchTimetoResponse,
  dispatchTimetoResolve,
  dispatchTicketEscallation,
  dispatchFirstContactResolution,
  dispatchCommonTags,
  dispatchCurrentModule,
  dispatchDeleteTicketsFaqPt,
  dispatchCustomerHealthDetails,
  dispatchListAllIntents,
  dispatchTestChatBot,
  dispatchClearChatBotHistory,
  dispatchChatMessageState,
  dispatchFiltervalues,
  dispatchTestChatBotAdithyaBirla,
  dispatchAppInsights,
  dispatchBotDetails,
  dispatchUpdateBot,
  dispatchAutoSolveBot,
  dispatchListWidgets,
  dispatchGetWidgetTypes,
  dispatchCreateWidget,
  dispatchTestWidget,
  dispatchClearBotDetails,
  dispatchGetAgentList,
  dispatchGetInstallationList,
  dispatchUpdateSelectedTools,
  dispatchCreateAgent,
  dispatchCreateBot,
  dispatchUpdateAgent,
  dispatchDeleteAgent,
  dispatchCreateFeatureIntegrationSettingsAutoSolve,
  dispatchGetWidgetDetails,
  dispatchUpdateWidget,
  dispatchDeleteWidget,
  dispatchCloneAutoSolveFeature,
  dispatchGetBotRunHistory,
  dispatchGetBotRunHistoryOfConversation,
  dispatchAgentConversationList,
  dispatchGetAgentConversationDetails,
  dispatchGenerateCode,
  dispatchBotRunHistory,
  dispatchSaveFeedback,
  dispatchTestApp,
  dispatchClearFileParams,
  dispatchGetAutosolveConversation,
  dispatchUpdateChatdetailsToEmpty,
  dispatchUpdateAgentChatdetailsToEmpty
}
