import { useRef } from 'react';
import { EdgeProps, useNodes, useReactFlow } from 'reactflow';

// this hook implements the logic for clicking the button on a workflow edge
// on edge click: create a node in between the two nodes that are connected by the edge
function useEdgeClick(id: EdgeProps['id']) {

    const nodes = useNodes()
    const val = nodes?.length - 1
    const blockCounter = useRef(val);

    const generateNodeId = () => {
    
        const existingIds = nodes.map((node: any) => node.id);    
        let attempts = 0
        
        while (attempts < 100) {
          blockCounter.current += 1;
          const newId = `step_${blockCounter.current}`;      
          if (!existingIds.includes(newId)) {
            return newId;
          }
          attempts += 1;
        }
        return null;
    }

  const { setEdges, setNodes, getNode, getEdge } = useReactFlow();
  
  const handleEdgeClick = (type: any) => {
    // first we retrieve the edge object to get the source and target id
    const edge = getEdge(id);

    if (!edge) {
      return;
    }

    // we retrieve the target node to get its position
    const targetNode = getNode(edge.target);

    if (!targetNode) {
      return;
    }

    // create a unique id for newly added elements
    const insertNodeId = generateNodeId()
    let typeUpdated:any;
    if (type === 'Past Ticket Search' || type==='FAQ Search') {
 typeUpdated = 'vector'
    }
    else{
      typeUpdated = type
    }
    // this is the node object that will be added in between source and target node
    const insertNode = {
        id: insertNodeId,
        data: { label: `${type} Block` },
        type: typeUpdated,
        position: { x: targetNode.position.x - 200, y: targetNode.position.y + 200 },
    };

    // new connection from source to new node
    const sourceEdge = {
      id: `edge_${edge.source}-${insertNodeId}`,
      source: edge.source,
      target: insertNodeId,
      type: 'buttonedge',
    };

    // new connection from new node to target
    const targetEdge = {
      id: `edge_${insertNodeId}-${edge.target}`,
      source: insertNodeId,
      target: edge.target,
      type: 'buttonedge',
    };

    // remove the edge that was clicked as we have a new connection with a node inbetween
    setEdges((edges: any) => edges.filter((e: any) => e.id !== id).concat([sourceEdge, targetEdge]));
    // insert the node between the source and target node in the react flow state
    setNodes((nodes: any) => {
      const targetNodeIndex = nodes.findIndex((node: any) => node.id === edge.target);

      return [...nodes.slice(0, targetNodeIndex), insertNode, ...nodes.slice(targetNodeIndex, nodes.length)];
    });
  };

  return handleEdgeClick;
}

export default useEdgeClick;
