const AlphabetIcon = (props: any) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={25} height={25} fill='none' {...props}>
    <g
      stroke='#1D1F22'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      clipPath='url(#a)'
    >
      <path d='m2.191 19.968 5.625-15 5.625 15m-1.968-5.25H4.16m11.813-3c.571-1.345 1.921-2.25 3.468-2.25 2.157 0 3.75 1.5 3.75 3.75v6.75' />
      <path d='M15.691 17.296c0 1.687 1.26 2.719 2.813 2.719 2.531 0 4.687-1.266 4.687-4.97v-.702c-.937 0-2.718.047-4.312.234-1.536.181-3.188.89-3.188 2.719Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M.691.491h24v24h-24z' />
      </clipPath>
    </defs>
  </svg>
)
export default AlphabetIcon
