import { Layout, Input, Button } from 'antd'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import SearchSetting from '../../assets/icons/SearchSetting'
import Search from '../../assets/icons/Search'
import WizrUserSideBar from '../WizrUserSideBar'
import WizrFlexLayout from '../WizrFlexLayout'
import WizrText from '../WizrText'

const { Content, Header } = Layout

const UserContent = styled(Content)`
  textalign: 'center';
  color: '#fff';
  align-self: stretch;
  width: 100%;
  padding: 5px 30px;
  display: flex;
`

const FullWidthContent = styled(Content)`
  textalign: 'center';
  color: '#fff';
  align-self: stretch;
  width: 100%;
  display: flex;
`

const UserHeader = styled(Header)`
  background: transparent;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 10px 30px;
`

const SearchInput = styled(Input)`
  border-radius: 30px;
  border: 0px solid var(--surface-overlay, #f9f9f9);
  background: #fff;
  width: 536px;
  flex-shrink: 0;
`

const UserLayout = ({ children }: { children: JSX.Element }): JSX.Element => {
  const location = useLocation()
  const showSearchHeader = location?.pathname?.includes('folder') || location?.pathname?.includes('past_tickets') || location?.pathname?.includes('faq')
  
  
  const showFullWidthScreen = location?.pathname?.includes('/app-designer/tool') || location?.pathname?.includes('/chatbot') || location?.pathname?.includes('/webFormatLarge')
  const appShare = location?.pathname?.includes('/appshare')
  const login = location?.pathname?.includes('/login') || location?.pathname?.includes('/extension') || location?.pathname?.includes('/webFormatLarge')

  return (
    <WizrFlexLayout flexDirection='column'>     
      {appShare || login ? <></> : <WizrUserSideBar />}
      <WizrFlexLayout>
        {showSearchHeader ? (
          <UserHeader>
            <WizrFlexLayout
              textAlign='center'
              alignItems='center'
              flexDirection='row'
              justifyContent='space-between'
            >
              <WizrText type='caption'>Embedding Model - text-embedding-ada-002</WizrText>
              <SearchInput
                placeholder='Search'
                prefix={<Search />}
                suffix={<Button shape='circle' icon={<SearchSetting />}></Button>}
              />
              <div></div>
            </WizrFlexLayout>
          </UserHeader>
        ) : (
          <></>
        )}
        {showFullWidthScreen ? <FullWidthContent>{children}</FullWidthContent> : <UserContent>{children}</UserContent>}
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

export default UserLayout
