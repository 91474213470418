import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CreateNew from '../../../assets/icons/CreateNew'
import Hamburger from '../../../assets/icons/Hamburger'
import WizrButton from '../../../components/WizrButton'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrText from '../../../components/WizrText'
import WizrUserJourney from '../../../components/WizrUserJourney'
import Analytics from '../../../assets/icons/AnalyticsIcon'
import WizrDropDown from '../../../components/WizrDropDown'
import styled from 'styled-components'
import WizrToggleButton from '../../../components/WizrToggleButton'
import WizrInput from '../../../components/WizrInput'
import { useEffect, useState } from 'react'
import {
  dispatchFeatureSettings,
  dispatchGetPackageDetails,
  dispatchIntegrationList,
  dispatchMasterIntegrationList,
  dispatchUpdateFeatureSettings,
} from '../../../redux/actions/content'
import {
  useContentLoadingSelector,
  useFeatureSettingSelector,
  useGetIntegrationList,
  useGetMasterIntegrationList,
  useappDataSelector,
} from '../../../redux/selectors/content'
import ZendeskIcon from '../../../assets/icons/ZendeskIcon'
import WizrTextArea from '../../../components/WizrTextArea'
import Category from '../../../assets/icons/Category'
import { setInterval } from 'timers'
import { set } from 'react-hook-form'

const StyledDropDown = styled(WizrDropDown)`
  height: 40px;
  width: 24% !important;
  .ant-select-selector {
    height: 40px !important;
    background-color: #f2f4f9 !important;
    border-radius: 16px;
    border: 0px #f2f4f9 !important;
    .ant-select-selection-placeholder {
      margin-top: 6px;
    }
  }
`
const StyledTextButton = styled(WizrText)`
  text-decoration: underline !important;
`

const StyledInput = styled(WizrInput)`
  height: 40px;
  width: 30%;
  background-color: #f2f4f9;
  border: 0px #f2f4f9 !important;
`

const StyledDropDownDefault = styled(WizrDropDown)`
  height: 40px;
  .ant-select-selector {
    height: 40px !important;
    background-color: #f2f4f9 !important;
    border-radius: 16px;
    border: 0px #f2f4f9 !important;
    padding-top: 5px !important;
    .ant-select-selection-placeholder {
      margin-top: 2px;
    }
  }
`
const StyledInputName = styled.input`
  padding-top: 4px;
  background: #F2F4F9;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #1F1D1D;
  width: 500px;

  &:placeholder {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #1F1D1D;
  }
`;

const DataFields = ({
  currentFeatureData,
  doUpdateDataFields,
  dataField,
  featureSettings,
  doUpdateIntentCatogoriesFields
}: any) => {
  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item?.folder_name,
        value: item?.folder_id,
      }))
    return result
  }

  const getStatus = (key: any): boolean => {
    const item = dataField?.find((item: any) => item?.key_name === key)
    return item ? item.enabled : false
  }
  const getValue = (key: any, field: any) => {
    const item = dataField?.find((item: any) => item?.key_name === key)
    return item ? item?.[field] : ''
  }

  const intents = featureSettings?.ticket_settings?.categories_intent?.folders.filter(
    (item: any) => item?.status !== 'deleted',
  )
  const sentiment = featureSettings?.ticket_settings?.sentiment?.folders.filter(
    (item: any) => item?.status !== 'deleted',
  )
  const tags = featureSettings?.ticket_settings?.tags?.folders.filter(
    (item: any) => item?.status !== 'deleted',
  )
  const priority = featureSettings?.ticket_settings?.priority?.folders.filter(
    (item: any) => item?.status !== 'deleted',
  )
  const type = featureSettings?.ticket_settings?.type?.folders.filter(
    (item: any) => item?.status !== 'deleted',
  )
  // )


  return (
    <WizrFlexLayout background='white' style={{ gap: '10px' }}>
      <WizrFlexLayout background='white' textAlign='left' flexDirection='row'>
        <WizrFlexLayout style={{ maxWidth: '27%' }} background='white' textAlign='left'>
          <WizrText type='body2' textColor='#707276'>
            Categories{' '}
          </WizrText>
        </WizrFlexLayout>
        <WizrToggleButton
          isEnabled={getStatus('categories')}
          onChange={(e: boolean) => doUpdateDataFields('categories', 'enabled', e)}
        />
        <StyledDropDown
          options={dropDownConverter(intents)}
          placeHolder='Select Group'
          value={getValue('categories', 'folder_id')}
          onChange={(value: any) => {
            doUpdateIntentCatogoriesFields('folder_id',value)

            // doUpdateDataFields('categories', 'folder_id', value)
            // doUpdateDataFields('intent', 'folder_id', value)
          }}
        />
        <StyledInput
          placeholder='Mention zendesk ID'
          value={getValue('categories', 'zendesk_id')}
          onChange={(value: any) => doUpdateDataFields('categories', 'zendesk_id', value)}
        />
      </WizrFlexLayout>
      <WizrFlexLayout background='white' textAlign='left' flexDirection='row'>
        <WizrFlexLayout style={{ maxWidth: '27%' }} background='white' textAlign='left'>
          <WizrText type='body2' textColor='#707276'>
            Intent
          </WizrText>
        </WizrFlexLayout>
        <WizrToggleButton
          isEnabled={getStatus('intent')}
          onChange={(e: boolean) => doUpdateDataFields('intent', 'enabled', e)}
        />
        <StyledDropDown
          options={dropDownConverter(intents)}
          placeHolder='Select Group'
          value={getValue('intent', 'folder_id')}
          onChange={(value: any) => {
            // doUpdateDataFields('intent', 'folder_id', value)
            // doUpdateDataFields('categories', 'folder_id', value)
            doUpdateIntentCatogoriesFields('folder_id',value)

          }}
        />
        <StyledInput
          placeholder='Mention zendesk ID'
          value={getValue('intent', 'zendesk_id')}
          onChange={(value: any) => doUpdateDataFields('intent', 'zendesk_id', value)}
        />
      </WizrFlexLayout>
      <WizrFlexLayout background='white' textAlign='left' flexDirection='row'>
        <WizrFlexLayout style={{ maxWidth: '27%' }} background='white' textAlign='left'>
          <WizrText type='body2' textColor='#707276'>
            Type{' '}
          </WizrText>
        </WizrFlexLayout>
        <WizrToggleButton
          isEnabled={getStatus('type')}
          onChange={(e: boolean) => doUpdateDataFields('type', 'enabled', e)}
        />
        <StyledDropDown
          options={dropDownConverter(type)}
          placeHolder='Select Group'
          value={getValue('type', 'folder_id')}
          onChange={(value: any) => doUpdateDataFields('type', 'folder_id', value)}
        />
        <StyledInput
          placeholder='Mention zendesk ID'
          value={getValue('type', 'zendesk_id')}
          onChange={(value: any) => doUpdateDataFields('type', 'zendesk_id', value)}
        />
      </WizrFlexLayout>
      <WizrFlexLayout background='white' textAlign='left' flexDirection='row'>
        <WizrFlexLayout style={{ maxWidth: '27%' }} background='white' textAlign='left'>
          <WizrText type='body2' textColor='#707276'>
            {' '}
            Relevance{' '}
          </WizrText>
        </WizrFlexLayout>
        <WizrToggleButton
          isEnabled={getStatus('relevance')}
          onChange={(e: boolean) => doUpdateDataFields('relevance', 'enabled', e)}
        />
        <WizrFlexLayout
          style={{ maxWidth: '24%' }}
          background='white'
          textAlign='center'
          justifyContent='center'
        >
          <WizrText type='body2'> Yes/No</WizrText>
        </WizrFlexLayout>
        <StyledInput
          placeholder='Mention zendesk ID'
          value={getValue('relevance', 'zendesk_id')}
          onChange={(value: any) => doUpdateDataFields('relevance', 'zendesk_id', value)}
        />
      </WizrFlexLayout>
      <WizrFlexLayout background='white' textAlign='left' flexDirection='row'>
        <WizrFlexLayout style={{ maxWidth: '27%' }} background='white' textAlign='left'>
          <WizrText type='body2' textColor='#707276'>
            Auto tags{' '}
          </WizrText>
        </WizrFlexLayout>
        <WizrToggleButton
          isEnabled={getStatus('tags')}
          onChange={(e: boolean) => doUpdateDataFields('tags', 'enabled', e)}
        />
        <StyledDropDown
          options={dropDownConverter(tags)}
          placeHolder='Select Group'
          value={getValue('tags', 'folder_id')}
          onChange={(value: any) => doUpdateDataFields('tags', 'folder_id', value)}
        />
        <StyledInput
          placeholder='Mention zendesk ID'
          value={getValue('tags', 'zendesk_id')}
          onChange={(value: any) => doUpdateDataFields('tags', 'zendesk_id', value)}
        />
      </WizrFlexLayout>
      <WizrFlexLayout background='white' textAlign='left' flexDirection='row'>
        <WizrFlexLayout style={{ maxWidth: '27%' }} background='white' textAlign='left'>
          <WizrText type='body2' textColor='#707276'>
            Priority
          </WizrText>
        </WizrFlexLayout>
        <WizrToggleButton
          isEnabled={getStatus('priority')}
          onChange={(e: boolean) => doUpdateDataFields('priority', 'enabled', e)}
        />
        <StyledDropDown
          options={dropDownConverter(priority)}
          placeHolder='Select Group'
          value={getValue('priority', 'folder_id')}
          onChange={(value: any) => doUpdateDataFields('priority', 'folder_id', value)}
        />
        <StyledInput
          placeholder='Mention zendesk ID'
          value={getValue('priority', 'zendesk_id')}
          onChange={(value: any) => doUpdateDataFields('priority', 'zendesk_id', value)}
        />
      </WizrFlexLayout>
      <WizrFlexLayout background='white' textAlign='left' flexDirection='row'>
        <WizrFlexLayout style={{ maxWidth: '27%' }} background='white' textAlign='left'>
          <WizrText type='body2' textColor='#707276'>
            Sentiment
          </WizrText>
        </WizrFlexLayout>
        <WizrToggleButton
          isEnabled={getStatus('sentiment')}
          onChange={(e: boolean) => doUpdateDataFields('sentiment', 'enabled', e)}
        />
        <StyledDropDown
          options={dropDownConverter(sentiment)}
          placeHolder='Select Group'
          value={getValue('sentiment', 'folder_id')}
          onChange={(value: any) => doUpdateDataFields('sentiment', 'folder_id', value)}
        />
        <StyledInput
          placeholder='Mention zendesk ID'
          value={getValue('sentiment', 'zendesk_id')}
          onChange={(value: any) => doUpdateDataFields('sentiment', 'zendesk_id', value)}
        />
      </WizrFlexLayout>
      <WizrFlexLayout background='white' textAlign='left' flexDirection='row'>
        <WizrFlexLayout style={{ maxWidth: '27%' }} background='white' textAlign='left'>
          <WizrText type='body2' textColor='#707276'>
            Escalate{' '}
          </WizrText>
        </WizrFlexLayout>
        <WizrToggleButton
          isEnabled={getStatus('escalate')}
          onChange={(e: boolean) => doUpdateDataFields('escalate', 'enabled', e)}
        />
        <WizrFlexLayout
          style={{ maxWidth: '24%' }}
          background='white'
          textAlign='center'
          justifyContent='center'
        >
          <WizrText type='body2'> Yes/No</WizrText>
        </WizrFlexLayout>
        <StyledInput
          placeholder='Mention zendesk ID'
          value={getValue('escalate', 'zendesk_id')}
          onChange={(value: any) => doUpdateDataFields('escalate', 'zendesk_id', value)}
        />
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

const AutoTag = () => {
  const location = useLocation()
  const params = useParams()
  const featureSettings = useFeatureSettingSelector()
  const id = params?.id
  const currentFeature = featureSettings?.feature_settings?.filter((item:any)=>item?.id===id)
  const [currentFeatureData, setCurrentFeatureData] = useState(currentFeature && currentFeature[0])
  const [integrationName,SetIntegrationName]=useState('')
  const [dataField, setDataFields] = useState(currentFeature?.data_fields)
const loading =useContentLoadingSelector()
  const navigate = useNavigate()
  const type = currentFeatureData?.setting_type
  useEffect(() => {
    dispatchGetPackageDetails(type)
    dispatchMasterIntegrationList()
    dispatchFeatureSettings()
    dispatchIntegrationList()   

  }, [])

  // useEffect (()=>{
  //   const id = currentFeatureData?.integration_id
  //   const name = integrationList?.find((item:any)=>item?.id===id)?.display_name
  //   SetIntegrationName(name)
  //   setDataFields(currentFeatureData?.data_fields)
  // },[currentFeatureData])
  const integrationList =useGetIntegrationList()

    useEffect (()=>{
      const featureId = params?.id

      const currentFeature = featureSettings?.feature_settings?.filter((item:any)=>item?.id===featureId)
      const feature = currentFeature && currentFeature[0]
      setCurrentFeatureData(feature)
    const id = feature?.integration_id
    const name = integrationList?.find((item:any)=>item?.id===id)?.display_name
    SetIntegrationName(name)
    setDataFields(feature?.data_fields)
  },[featureSettings,integrationList])

  const applist = useappDataSelector()
  const persona = featureSettings?.company_settings?.persona

  const masterIntegrations = useGetMasterIntegrationList()
  const getIcon = (item: any) => {
    switch (item?.integration_key) {
      case 'zendesk':
        return <ZendeskIcon />
      default:
        return null
    }
  }

  const dropDownConverterIntegration = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: (
          <div style={{ alignItems: 'center', display: 'flex', gap: '10px' }}>
            <div>
              {getIcon(item)}
              {/* Render the icon component */}
            </div>
            <div>
              {' '}
              {item.display_name} {/* Render the label text */}
            </div>
          </div>
        ),
        value: item.id,
      }))
    return result
  }

  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item?.app_name,
        value: item?.app_id,
      }))
    return result
  }
  const dropDownConverterPersona = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item?.name,
        value: item?.persona_id,
      }))
    return result
  }

  const doUpdateFields = (name: any, value: any) => {
   
    setCurrentFeatureData({
      ...currentFeatureData,
      [name]: value,
    })
  }

  const doUpdateDataFields = (type: any, key: any, value: any) => {
  

    // setDataFields((prevDataFields: any) => {
    //   console.log('prevDataFields',prevDataFields)
    //   return prevDataFields.map((item: any, index: any) => {
    //     if (item?.key_name === type) {
    //       // If the current index matches the one to update
         
    //       return {
    //         ...item, // Keep all properties unchanged
    //         [key]: value, // Update the enabled property
    //       }
    //     } else {
    //       // For other indexes, return the item unchanged
    //       return item
    //     }
    //   })
    // })
    const data = dataField.map((item: any, index: any) => {
        if (item?.key_name === type) {
          // If the current index matches the one to update
         
          return {
            ...item, // Keep all properties unchanged
            [key]: value, // Update the enabled property
          }
        } else {
          // For other indexes, return the item unchanged
          return item
        }
      })

    setDataFields(data)
    setCurrentFeatureData({ ...currentFeatureData, data_fields: data })
  }


  const doUpdateIntentCatogoriesFields = (key: any, value: any) => {
    const intents = dataField.find((item: any) => item?.key_name==="intent")
    const categories = dataField.find((item: any) => item?.key_name==="categories")
    const rest = dataField.filter((item: any) => (item?.key_name!=="categories" && item?.key_name!=="intent"))
    const intentUpdated = { ...intents, [key]: value }
    const categoriesUpdated = { ...categories, [key]: value }
    const data = [intentUpdated,categoriesUpdated,...rest]

  setDataFields(data)
  setCurrentFeatureData({ ...currentFeatureData, data_fields: data })
  }


  const onSaveAction =async () => {
    const payload = {id: featureSettings?.id,
      feature_settings: currentFeatureData}
   await dispatchUpdateFeatureSettings(payload)
   dispatchFeatureSettings()
  }

  const setIntegrations = (key: any, value: any) => {
    // doUpdateFields('integration_type', value)
    const integrationCurrent = integrationList?.find((item:any)=>item?.id===value)
    SetIntegrationName(integrationCurrent?.display_name)
    if( integrationCurrent?.integration_key === 'zendesk') {
      const Zendeskdata =masterIntegrations.find((item:any)=>item.integration_key==='zendesk')?.feature_settings_config
      const autoTagData = Zendeskdata?.find((item:any)=>item.setting_type==='autotag')
      setDataFields(autoTagData?.data_fields)
      setCurrentFeatureData({ ...currentFeatureData,
         data_fields: autoTagData?.data_fields ,
         end_point_url:autoTagData?.end_point_url,
        integration_type: integrationCurrent?.integration_key,
        integration_id:value
      })
   
      // setCurrentFeatureData({...currentFeatureData,endpoint_url:autoTagData?.endpoint_url})
// doUpdateFields('endpoint_url',autoTagData?.endpoint_url)
    }
  }

  const generateEndPoint = () => {
    navigate('/endpoint', { state: { details: currentFeatureData } })
  }
  return (
    <div
      style={{
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        overflow: 'auto',
        width: '100%',
      }}
    >
      <WizrFlexLayout justifyContent='space-between' flexDirection='row' flex={1.4}>
        <WizrUserJourney
          userJourney={[
            {
              title: (
                <WizrButton
                  padding='0px'
                  type='text'
                  text={
                    <WizrText type='h6' textColor='#707276'>
                      Cx Settings
                    </WizrText>
                  }
                  onClick={() => navigate(-1)}
                  icon={<Hamburger />}
                />
              ),
            },
            {
              title: (
                // <WizrButton
                //   padding='0px'
                //   type='text'
                //   text={
                //     <WizrText type='h6'>{`${currentFeatureData?.setting_name} - Settings`}</WizrText>
                //   }
                //   onClick={() => navigate(-1)}
                // />
                <StyledInputName type="text"  value={currentFeatureData?.setting_name} onChange={(e:any) => {doUpdateFields('setting_name', e.target.value)}}/>

              ),
              isCurrent: true,
            },
          ]}
        />
        <WizrButton
          type='text'
          icon={<Analytics />}
          style={{ marginTop: '20px', borderRadius: '16px', backgroundColor: '#EAECF1' }}
          text={<WizrText textColor='black' type='button'>{`App Analytics`}</WizrText>}
          onClick={() => {}}
        >
          {' '}
        </WizrButton>
      </WizrFlexLayout>
      {currentFeatureData?.setting_type === "autotag" ? 
      <WizrFlexLayout
        background='white'
        flex={11}
        style={{ borderRadius: '16px', padding: '20px 40px' }}
        textAlign='left'
      >
        <WizrText type='sub1'> Settings</WizrText>
        <WizrFlexLayout
          flexDirection='row'
          background='white'
          style={{ width: '55%', marginTop: '10px', gap: '40px' }}
          textAlign='left'
        >
          <WizrFlexLayout
            background='white'
            textAlign='left'
            style={{ gap: '10px', minWidth: '20px' }}
          >
            <WizrText type='body2' textColor='#707276'>
              {' '}
              Apps
            </WizrText>
            <StyledDropDownDefault
              placeHolder='Select App'
              options={dropDownConverter(applist)}
              value={currentFeatureData?.app_id}
              onChange={(value: any) => {
                doUpdateFields('app_id', value)
              }}
            />
            <WizrFlexLayout
              background='white'
              textAlign='left'
              flexDirection='row'
              style={{ marginTop: '10px' }}
            >
              <WizrText type='body2' textColor='#707276'>
                {' '}
                Autotag Enabled
              </WizrText>
              <WizrToggleButton
                isEnabled={currentFeatureData?.status === 'active' ? true : false}
                onChange={(e: boolean) => {
                  doUpdateFields('status', e ? 'active' : 'disabled')
                }}
              />
            </WizrFlexLayout>
          </WizrFlexLayout>
          <WizrFlexLayout background='white' textAlign='left' style={{ gap: '10px' }}>
            <WizrText type='body2' textColor='#707276'>
              {' '}
              Integrations
            </WizrText>
            <StyledDropDownDefault
              placeHolder='Select Integration'
              options={dropDownConverterIntegration(integrationList)}
              value={integrationName}
              onChange={(value: any) => {
                setIntegrations('integration_type', value)
              }}
            />
            <WizrFlexLayout background='white' textAlign='left' flexDirection='row'>
              <WizrButton
              disabled={!currentFeatureData?.integration_id || currentFeatureData?.integration_id===''}
              onClick={()=>generateEndPoint()}
                text={
                  <StyledTextButton type='link' >
                    <WizrText type='body2' textColor='#1D1F22'>
                      Generate end point URL & Payload
                    </WizrText>
                  </StyledTextButton>
                }
                type='link'
              />
            </WizrFlexLayout>
          </WizrFlexLayout>
        </WizrFlexLayout>
        <WizrFlexLayout
          textAlign='left'
          style={{ minHeight: '400px', gap: '30px', marginTop: '40px' }}
          background='white'
        >
          <WizrText type='sub1'> Data Fields</WizrText>
          <WizrFlexLayout style={{ width: '70%' }}>
            {currentFeatureData?.integration_type || currentFeatureData?.integration_type !== '' ? (
              <DataFields
                currentFeatureData={currentFeatureData}
                doUpdateDataFields={doUpdateDataFields}
                dataField={dataField}
                featureSettings={featureSettings}
                doUpdateIntentCatogoriesFields={doUpdateIntentCatogoriesFields}
              />
            ) : (
              <></>
            )}
          </WizrFlexLayout>
          <WizrFlexLayout textAlign='left' background='white' style={{ width: '27%', gap: '10px' }}>
            <WizrText type='body2'>Personas</WizrText>

            <StyledDropDownDefault
              placeHolder='Select Persona'
              options={dropDownConverterPersona(persona)}
              value={currentFeatureData?.persona_id}
              onChange={(value: any) => {
                doUpdateFields('persona_id', value)
              }}
            />
          </WizrFlexLayout>
          <WizrFlexLayout textAlign='left' background='white' style={{ width: '80%', gap: '10px' }}>
            <WizrText type='body2'>About the Company</WizrText>

            {/* <WizrTextArea
              background='#F2F4F9'
              border='0px #F2F4F9'
              minRows={8}
              value={currentFeatureData?.feature_params?.about_the_company}
              onChange={(value: any) => {}}
              placeholder='Briefly explain what the company is and what it does in 3 to 5 lines. This will be used to set the context to the AI model'
            /> */}
             <WizrFlexLayout   background='#F2F4F9' textAlign='left' style={{padding:'20px',minHeight:"200px"}}>
              <WizrText type='body2' textColor='#999B9F'>{currentFeatureData?.feature_params?.about_the_company}</WizrText>
            </WizrFlexLayout>
          </WizrFlexLayout>
          <WizrFlexLayout alignItems='flex-end' background='white'>
            <WizrButton
            disabled={loading}
              type='primary'
              text={'Save'}
              style={{ width: '70px' }}
              onClick={() => {
                onSaveAction()
              }}
            />
          </WizrFlexLayout>
        </WizrFlexLayout>
      </WizrFlexLayout>:<></>}
    </div>
  )
}
export default AutoTag
