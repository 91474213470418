import styled from 'styled-components'
import WizrFlexLayout from '../../../../components/WizrFlexLayout'
import WizrText from '../../../../components/WizrText'
import WizrButton from '../../../../components/WizrButton'
import CreateNew from '../../../../assets/icons/CreateNew'
import { Card } from 'antd'
import AppActionPopOver from '../../../../components/popovers/AppAction'
import CreateAgentPopover from '../../../../components/popovers/CreateAgent'
import WizrTag from '../../../../components/WizrTags'
import { useNavigate } from 'react-router-dom'
import { useEffect ,useState} from 'react'
import {
  dispatchGetInstallationList,
  dispatchGetPackageDetails,
} from '../../../../redux/actions/content'
import {
  useAgentListSelector,
  useGetInstalltionListSelector,
} from '../../../../redux/selectors/content'
import CustomApiIcon from '../../../../assets/icons/CustomApiIcon'
import WebChatIcon from '../../../../assets/icons/WebChatIcon'
import WebFormatLargeInstallationPage from './WebFormatLargeInstallationPage'
import WebFormatLargeIcon from '../../../../assets/icons/WebFormatLargeIcon'
import SlackIcon from '../../../../assets/icons/SlackIcon'
import WhatsAppIcon from '../../../../assets/icons/WhatsAppIcon'
import GmailIcon from '../../../../assets/icons/GmailIcon'
import TeamsIcon from '../../../../assets/icons/TeamsIcon'
import OutlookIcon from '../../../../assets/icons/OutlookIcon'

const StyledPlusButton = styled(WizrButton)`
  background-color: black;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding-right: 8px;
  justify-content: center;
  align-items: center;
`

const StyledTicketCard = styled(Card)<any>`
  background-color: #ffffff;
  width: 24%;
  .ant-card-meta-detail {
    border-bottom: none;
    border-top: none;
  }
  .ant-card-head {
    border-bottom: none;
    text-align: start;
    padding: 10px !important;
  }
  .ant-card-body {
    text-align: center;
    padding: 15px;
  }
`

const Installations = ({currentFeatureData}:any): JSX.Element => {
  const navigate = useNavigate()
  const agentList = useAgentListSelector()
  const Installations = currentFeatureData?.installations
  const [viewInstallation, setViewInstallation] = useState(false)   

  const getIcon = (type: string) => {
    console.log("type",type)  
    switch (type) {
      case 'custom_api"':
        return <CustomApiIcon />
      case 'web_chat':
        return <WebChatIcon />
      case 'web_format_large':
        return <WebFormatLargeIcon />
      case 'slack_integration':
        return <SlackIcon />
      case 'whatsapp_integration':
        return <WhatsAppIcon />
      case 'gmail_integration':
        return <GmailIcon />
      case 'teams_integration':
        return <TeamsIcon />
      case 'outlook_integration':
        return <OutlookIcon />
      default:
        return <CustomApiIcon />
    }
  }

  return (
    <div>
        {!viewInstallation ? (
    <WizrFlexLayout
      background='white'
      textAlign='left'
      style={{ gap: '16px', padding: "24px", display: 'flex', flex: '1', flexWrap: 'wrap' }}  
      flexDirection='row'
      justifyContent='flex-start'
    >
{/* <WebFormatLargeInstallationPage /> */}
<>
{Installations?.map((item: any) => (
          <StyledTicketCard
          hoverable
            key={item.id}
            title={
              <WizrFlexLayout
                background='#FFFFFF'
                flexDirection='row'
                justifyContent='flex-start'
                style={{ gap: '10px' }}
                alignItems='center'
              >
                {/* <CustomApiIcon /> */}
                {getIcon(item?.type)}
                <WizrText type='sub2' style={{ padding: '10px 0px' }}>
                  {item?.display_name}
                </WizrText>
                {/* <CreateAgentPopover /> */}
              </WizrFlexLayout>
            }
            bordered={true}
            onClick={() => {
            item?.type==="web_format_large" &&
              setViewInstallation(true)
            //   dispatchGetPackageDetails(item.id)
            }}
          >
            <WizrFlexLayout background='#FFF' textAlign='left'>
              <WizrText type='body2'>
              {item?.description}

              </WizrText>
            </WizrFlexLayout>
          </StyledTicketCard>
        ))}
        </>
    </WizrFlexLayout>):<WebFormatLargeInstallationPage />}</div>
  )
}

export default Installations
