import { Tabs, Spin, Radio, RadioChangeEvent } from 'antd'
import Search from '../assets/icons/Search'
import styled from 'styled-components'
import WizrTag from '../stories/WizrTag/WizrTag'
import WizrPromptsMenu from '../stories/WizrPromptMenuList/WizrPromtMenuList'
import WizrInput from '../components/WizrInput'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrText from '../components/WizrText'
import { useEffect, useMemo, useState } from 'react'
import { dispatchAppData, dispatchAppDraft, dispatchAppPublished } from '../redux/actions/content'
import {
  useContentLoadingSelector,
  useGetTenantDetails,
  usePromtTemplateSelector,
  useappDataSelector,
} from '../redux/selectors/content'
import WizrButton from '../components/WizrButton'

import WizrTable from '../components/WizrTable'
import { useNavigate } from 'react-router-dom'
import WizrFlexSpacing from '../components/WizrFlexSpacing'
import WizrUserJourney from '../components/WizrUserJourney'
import CreateAppPopover from '../components/popovers/CreateApp'
import WizrTags from '../components/WizrTags'

const TabPane = Tabs.TabPane

const StyledInputHolder = styled(WizrFlexLayout)`
  padding: 0px 33%;
  overflow: hidden;
  .liofSj {
    border-radius: 30px;
  }
`
const StyledWizrButton = styled(WizrButton)`
  border-radius: 20px;
  height: 30px;
  margin-bottom: 3px;
  .anticon {
    color: white;
  }
`

const StyledFlexSpacing = styled(WizrFlexSpacing)`
  .ant-space-item {
    display: flex !important;
    width: 100% !important;
  }
`
const StyleWizrPromptsMenu = styled(WizrPromptsMenu)`
  background-color: #ffffff;
  border-radius: 14px;
  width: 960px;
  margin: 4px auto;
  overflow: auto
`

const StyledTabs = styled(Tabs)`
  width: 100%;
  flex: 1;
  margin:20px;
  justify-content: space-between;
`

const StyledWizrTable = styled(WizrTable)`
  .ant-table-wrapper {
    width: 100% !important;
  }
  .ant-table-thead {
    background-color: #f2f4f9;
    border-spacing: 0px;
  }
  .ant-table-tbody > tr {
    background-color: #ffffff;
    margin-bottom: 0px;
  }

  .ant-table table {
    border-spacing: 0 5px;
  }
`
const StyledWizrTag = styled(WizrTag)`
  height: 42px;
  padding: 8px 11px;
  justify-content: space-between;
`
const StyledWizrInput = styled(WizrInput)`
width: 426px;
height: 40px;
padding: 8px 8px 8px 8px;
border-radius: 30px;
border: 1px solid var(--grayscale-300, #DCDEE2);
background: #FFF;
`


const AppTabs = [
  {
    key: 'tools',
    text: "Tools"
  },
  {
    key: 'chatbot',
    text: "Chatbot"
  }
]


const AppCategory = ({
  appData,
}: {
  appData: Array<any>
}): JSX.Element => {

  const [appListData, setAppListData] = useState<any>()
  const [key, setKey] = useState('published')
  const [selectedTab, setSelectedTab] = useState('published');

  useEffect(() => {
    setAppListData(appData)
  }, [appData])


  const getButtonStyle = (value: any) => {
    if (value === selectedTab) {
      return {
        backgroundColor: '#1D1F22',
        borderColor: '#1D1F22',
        color: '#fff',
      };
    }
    return {};
  };

  const uniqueTagList = appData?.length
  ? appData
    .map((item: any) => item.tags)
    .flat()
    .reduce((accumulator: string[], tag: string) => {
      if (tag && !accumulator.includes(tag)) {
        accumulator.push(tag)
      }
      return accumulator
    }, [])
  : []

uniqueTagList.unshift('All')

  // const uniqueTagList = useMemo(() => {
  //   return appData?.length
  //     ? appData
  //         .map((item) => item.tags)
  //         .flat()
  //         .reduce((accumulator, tag) => {
  //           if (tag && !accumulator.includes(tag)) {
  //             accumulator.push(tag);
  //           }
  //           return accumulator;
  //         }, [])
  //     : [];
  // }, [appData]);

  // uniqueTagList.unshift('All')

  const isContentLoading = useContentLoadingSelector()

  const doFilteringBasedOnTag = (tagName: string) => {
    if (tagName !== 'All') {
      setAppListData(appData?.filter((field) => field?.tags?.includes(tagName)))
    } else {
      setAppListData(appData)
    }
  }

  const onTabChange = (e: RadioChangeEvent) => {
    setSelectedTab(e.target.value);
    e.target.value === 'templates' ? dispatchAppData() : e.target.value === 'published' ? dispatchAppPublished() : dispatchAppDraft()
    setKey(e.target.value)
  }

  const tenantDetails = useGetTenantDetails()  
  const isMasterTenant = tenantDetails?.master_tenant


  return (
    <WizrFlexLayout>
      {isContentLoading ? (
        <Spin />
      ) : (
        <StyledFlexSpacing
          justifyContent='space-between'
          flexDirection='column'
          alignItems='center'
          size={20}
        >
          <WizrFlexSpacing >
            <StyledInputHolder alignItems='center' justifyContent='space-between'>

              <br />
              <StyledWizrTag
                data={uniqueTagList}
                onClick={(name: any) => doFilteringBasedOnTag(name)}
              />
            </StyledInputHolder>

            <Radio.Group onChange={onTabChange} value={selectedTab} style={{ marginBottom: 16 }}>
              {isMasterTenant ? <Radio.Button value="templates" style={getButtonStyle('templates')}>
                Templates
              </Radio.Button> : <></>}
              <Radio.Button value="published" style={getButtonStyle('published')}>
                Published Apps
              </Radio.Button>
              <Radio.Button value="draft" style={getButtonStyle('draft')}>
                Drafts
              </Radio.Button>
            </Radio.Group>
            <StyledFlexSpacing
              justifyContent='space-between'
              flexDirection='column'
              alignItems='center'
              size={20}
              overflow='auto'
              style={{ width: '100%', maxHeight: '500px', paddingBottom: '20px'}}
              >
              {key === 'templates' ? (
                <StyledWizrTable
                  dataSource={appListData}
                  type='AppTemplate'
                  tabSelected={key}
                  scroll={{ y: 500 }}
                  pagination={false}
                />
              ) : key === "published" ? (
                  isMasterTenant?<StyledWizrTable
                  dataSource={appListData}
                  type='AppMaster'
                  tabSelected={key}
                  scroll={{ y: 500 }}
                  pagination={false}
                />:
                <StyledWizrTable
                  dataSource={appListData}
                  type='App'
                  tabSelected={key}
                  scroll={{ y: 500 }}
                  pagination={false}
                />
              ) : 
              isMasterTenant?
              <StyledWizrTable
                dataSource={appListData}
                type='AppDraftMaster'
                tabSelected={key}
                scroll={{ y: 500 }}
                pagination={false}
              />:
              <StyledWizrTable
              dataSource={appListData}
              type='AppDraft'
              tabSelected={key}
              scroll={{ y: 500 }}
              pagination={false}
            />}
            </StyledFlexSpacing>
            </WizrFlexSpacing>
        </StyledFlexSpacing>
      )}
    </WizrFlexLayout>
  )
}

const Apps = (): JSX.Element => {

  const appData: any = useappDataSelector()
  const [filteredData, setFilteredData] = useState<any>();
  
  

  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    dispatchAppPublished()
  }, [])

  useEffect(() => {
    setFilteredData(appData)
  }, [appData])

  const handleSearch = (value: string) => {
    
    const filtered = appData.filter((app: any) => app.app_name?.toLowerCase().includes(value?.toLowerCase()));
    setFilteredData(filtered);
  }

  return (
    <WizrFlexLayout alignItems='flex-start' justifyContent='space-between' style={{ marginTop: '20px' }}>
      <WizrFlexSpacing flexDirection='row' justifyContent='space-between' alignItems='center'>
        <WizrFlexSpacing flexDirection='row' justifyContent='space-between' alignItems='center'>
          <WizrUserJourney userJourney={[{ title: 'Apps' }]} />
          <StyledTabs defaultActiveKey='1' destroyInactiveTabPane={false}>
            {AppTabs.map((appMenuItem: any) => (
              <TabPane
                tab={<WizrText type='body2'>{appMenuItem.text}</WizrText>}
                key={appMenuItem.key}
              >
              </TabPane>
            ))}</StyledTabs>
        </WizrFlexSpacing>
        <CreateAppPopover />
      </WizrFlexSpacing>
      <WizrFlexLayout flex={10} alignItems='center'>
        <StyledWizrInput placeholder='Search for Apps' value={searchValue} onChange={(value: any) =>{ setSearchValue(value); handleSearch(value)}} preComponent={<Search />} background='#FFF' />
        <AppCategory appData={filteredData} />
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

export default Apps