import { Popover } from 'antd'
import WizrButton from '../WizrButton'
import MoreIcon from '../../assets/icons/More'
import EditIcon from '../../assets/icons/EditIcon'
import Copy from '../../assets/icons/Copy'
import WizrFlexLayout from '../WizrFlexLayout'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

interface IPromptCopyEdit {
  detail?: any
}

interface IPromptCopyEditPopOverContent {
  onEditAction: () => void
  onCopyAction: () => void
}

const PromptCopyEditPopOverContent = ({
  onEditAction,
  onCopyAction,
}: IPromptCopyEditPopOverContent): JSX.Element => {
  
 
  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      <WizrButton
        icon={<EditIcon />}
        text='Edit'
        type='text'
        onClick={onEditAction}
        justifyContent='flex-start'
      />
      <WizrButton
        icon={<Copy />}
        text='Clone'
        type='text'
        onClick={onCopyAction}
        justifyContent='flex-start'
      />
    </WizrFlexLayout>
  )
}

const PromptCopyEdit = ({ detail }: IPromptCopyEdit): JSX.Element => {
  const navigate=useNavigate()
  const onCopy = () => { 
    localStorage.setItem('action', "copy")
    const id=localStorage.getItem("prompt_id")
    navigate('/prompt-view', { state: {...detail,id,action:"prompt"} })}
  const onEdit = () => {}
  return (
    <Popover
      content={<PromptCopyEditPopOverContent onCopyAction={onCopy} onEditAction={onEdit} />}
      trigger='click'
    >
      <StyledButton icon={<MoreIcon />} text='' type='text' style={{height: '40px', width: '40px', borderRadius: '50px'}}/>
    </Popover>
  )
}

export default PromptCopyEdit

const StyledButton = styled(WizrButton)`
border-radius: 50%;
width: 20px;
height: 20px;
padding-right: 8px;
justify-content: center;
align-items: center;
  /* Default styles */
  path {
    stroke: #999B9F; /* Default stroke color */
    transition: stroke 0.3s ease; /* Smooth transition for color change */
  }

  /* Hover styles */
  &:hover path {
    stroke: #1D1F22
  }
`;
