import styled from "styled-components";
import WizrText from "../WizrText";
import WizrFlexLayout from "../WizrFlexLayout";
import WizrCheckbox from "../WizrCheckbox";
import { useState } from "react";
import TickIcon from "../../assets/icons/TickIcon";
import CloneIcon from "../../assets/icons/CloseIcon";

const StyledTable = styled.table`
    border-collapse: collapse;
    width: 100%;
    th, td {
        border: 1px solid #EAECF1;
        padding:  15px 10px  !important;
        text-align: left;
        }`

     
const WizrAccessTableView = ({setSelectedItems,selectedItems}:any) => {
   // const [currentActiveCheckBoxes,setCurrentActiveCheckBoxes] = useState<any[]>([])
    // const currentActiveCheckBoxes :any[] = []

    const getChecked = (key:any) => {
return  selectedItems.includes(key)   
 }
    
const addRoles = (value:any,dependency?:any) => {
    
    if (value.target.checked){
        if (dependency){
            setSelectedItems([...selectedItems,value.target.value,dependency])
        }
        else{
            setSelectedItems([...selectedItems,value.target.value])}
    }
    else{
        setSelectedItems(selectedItems.filter((item:any)=>item!==value.target.value))
    }
}

    return(
    <div >
        <StyledTable  className="accesstable">
            <tr>
                    <th rowSpan={2}><WizrText type="sub2">Module</WizrText></th>
                    <th rowSpan={2}><WizrText type="sub2">Features</WizrText></th>
                    <th colSpan={8}><WizrFlexLayout textAlign="center" background="white"><WizrText type="sub2">Operations</WizrText></WizrFlexLayout></th>
            </tr>
            <tr>
                    <td><WizrText type="body2" textColor="#1D1F22">View</WizrText></td>
                    <td><WizrText type="body2">Create/Upload</WizrText></td>
                    <td><WizrText type="body2">Edit</WizrText></td>
                    <td><WizrText type="body2">Delete</WizrText></td>
                    <td><WizrText type="body2">Publish/Unpublish</WizrText></td>
                    <td><WizrText type="body2">Run/Test</WizrText></td>
                    <td><WizrText type="body2">Copy</WizrText></td>
                    <td><WizrText type="body2">Enable/Disable</WizrText></td>


            </tr>

{/* Home module */}
            <tr>
                <td rowSpan={3}><WizrText type="body2">Home</WizrText></td>
                <td><WizrText type="body2">Home</WizrText></td>
                {/* <td><WizrCheckbox value={'view_home'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('view_home')}/></td>   */}
                <td>{getChecked('view_home')?<TickIcon />:<CloneIcon />}</td>
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Apps</WizrText></td>
                <td>{getChecked('listapps_home')?<TickIcon />:<CloneIcon />} </td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td>{getChecked('apprun_home')?<TickIcon />:<CloneIcon />} </td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Contents</WizrText></td>
                <td>{getChecked('listfolder_home')?<TickIcon />:<CloneIcon />} </td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */} 
                <td></td>     {/* Run/Test */}
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

{/* Content module */}

            <tr>
                <td rowSpan={6}><WizrText type="body2">Content</WizrText></td>
                <td><WizrText type="body2">Knowledge Base - Folder</WizrText></td>
                <td>{getChecked('view_folder')?<TickIcon />:<CloneIcon />} </td>  {/* View */} 
                <td>{getChecked('create_folder')?<TickIcon />:<CloneIcon />} </td>     {/* Create/Upload */} 
                <td>{getChecked('update_folder')?<TickIcon />:<CloneIcon />} </td>     {/* Edit */}      
                <td>{getChecked('delete_folder')?<TickIcon />:<CloneIcon />} </td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Knowledge Base - File</WizrText></td>
                <td>{getChecked('view_file')?<TickIcon />:<CloneIcon />} </td> {/* View */} 
                <td>{getChecked('upload_file')?<TickIcon />:<CloneIcon />} </td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td>{getChecked('delete_file')?<TickIcon />:<CloneIcon />} </td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Faq - Folder</WizrText></td>
                <td>{getChecked('view_faq')?<TickIcon />:<CloneIcon />} </td>  {/* View */} 
                <td>{getChecked('create_faqfolder')?<TickIcon />:<CloneIcon />} </td>     {/* Create/Upload */} 
                <td>{getChecked('update_faqfolder')?<TickIcon />:<CloneIcon />} </td>     {/* Edit */}      
                <td>{getChecked('delete_faqfolder')?<TickIcon />:<CloneIcon />} </td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Faq - File</WizrText></td>
                <td>{getChecked('view_faqfile')?<TickIcon />:<CloneIcon />} </td> {/* View */} 
                <td>{getChecked('upload_faqfile')?<TickIcon />:<CloneIcon />}</td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td>{getChecked('delete_faqfile')?<TickIcon />:<CloneIcon />} </td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Past Ticket - Folder</WizrText></td>
                <td>{getChecked('view_past_tickets')?<TickIcon />:<CloneIcon />} </td>  {/* View */} 
                <td>{getChecked('create_ptfolder')?<TickIcon />:<CloneIcon />} </td>     {/* Create/Upload */} 
                <td>{getChecked('update_ptfolder')?<TickIcon />:<CloneIcon />} </td>     {/* Edit */}      
                <td>{getChecked('delete_ptfolder')?<TickIcon />:<CloneIcon />} </td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}   
            </tr>

            <tr>
                <td><WizrText type="body2">Past Ticket - File</WizrText></td>
                <td>{getChecked('view_ptfile')?<TickIcon />:<CloneIcon />}  </td> {/* View */} 
                <td>{getChecked('upload_ptfile')?<TickIcon />:<CloneIcon />}  </td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td>{getChecked('delete_ptfile')?<TickIcon />:<CloneIcon />} </td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

{/* AI Studio module */}

            <tr>
                <td rowSpan={36}><WizrText type="body2">AI Studio</WizrText></td>
                <td><WizrText type="body2">Package App -Templates</WizrText></td>
                <td>{getChecked('viewtemplate_app')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td>{getChecked('create_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Create/Upload */} 
                <td>{getChecked('edit_packagetemplate_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Edit */}      
                <td>{getChecked('delete_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Delete */} 
                <td>{getChecked('publish_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Publish/Unpublish */}     
                <td>{getChecked('singlerun_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Run/Test */}   
                <td>{getChecked('clonetemplate_app')?<TickIcon />:<CloneIcon />} </td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Prompt</WizrText></td>
                <td>{getChecked('view_prompt')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Apps</WizrText></td>
                <td>{getChecked('view_app-designer')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Templates Test History</WizrText></td>
                <td>{getChecked('view_app-history')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Templates Run History</WizrText></td>
                <td>{getChecked('view_runhistory')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Templates App Share</WizrText></td>
                <td>{getChecked('view_appshare')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Templates Api Integration</WizrText></td>
                <td>{getChecked('integrationapi_app')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Templates BulkRun</WizrText></td>
                <td>{getChecked('bulkrun_app')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td>{getChecked('bulkrun_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>


            <tr>
                <td><WizrText type="body2">Package App -Published</WizrText></td>
                <td>{getChecked('viewpublished_app')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td>{getChecked('create_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Create/Upload */} 
                <td>{getChecked('edit_packagepublished_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Edit */}      
                <td>{getChecked('delete_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td>{getChecked('singlerun_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Run/Test */}   
                <td>{getChecked('copypublished_app')?<TickIcon />:<CloneIcon />}   </td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Published Test History</WizrText></td>
                <td>{getChecked('view_app-history')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Published Run History  </WizrText></td>
                <td>{getChecked('view_runhistory')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Published App Share</WizrText></td>
                <td>{getChecked('view_appshare')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Published Api Integration</WizrText></td>
                <td>{getChecked('integrationapi_app')?<TickIcon />:<CloneIcon />} </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Published BulkRun</WizrText></td>
                <td>{getChecked('bulkrun_app')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td>{getChecked('bulkrun_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Draft</WizrText></td>
                <td>{getChecked('viewdraft_app')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td>{getChecked('create_app')?<TickIcon />:<CloneIcon />} </td>     {/* Create/Upload */} 
                <td>{getChecked('editdraft_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Edit */}      
                <td>{getChecked('delete_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Delete */} 
                <td>{getChecked('publish_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Publish/Unpublish */}     
                <td>{getChecked('test_app')?<TickIcon />:<CloneIcon />} </td>     {/* Run/Test */}   
                <td>{getChecked('copydraft_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Draft Test History</WizrText></td>
                <td>{getChecked('view_app-history')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>
            
            <tr>
                <td><WizrText type="body2">General App -Templates</WizrText></td>
                <td>{getChecked('viewtemplate_app')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td>{getChecked('create_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Create/Upload */} 
                <td>{getChecked('edit_generaltemplate_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Edit */}      
                <td>{getChecked('delete_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Delete */} 
                <td>{getChecked('publish_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Publish/Unpublish */}     
                <td>{getChecked('singlerun_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Run/Test */}   
                <td>{getChecked('clonetemplate_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Templates Test History</WizrText></td>
                <td>{getChecked('view_app-history')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Templates Run History</WizrText></td>
                <td>{getChecked('view_runhistory')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Templates App Share</WizrText></td>
                <td>{getChecked('view_appshare')?<TickIcon />:<CloneIcon />} </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Templates Api Integration</WizrText></td>
                <td>{getChecked('integrationapi_app')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Templates BulkRun</WizrText></td>
                <td>{getChecked('bulkrun_app')?<TickIcon />:<CloneIcon />} </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td>{getChecked('bulkrun_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>


            <tr>
                <td><WizrText type="body2">General App -Published</WizrText></td>
                <td>{getChecked('viewpublished_app')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td>{getChecked('create_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Create/Upload */} 
                <td>{getChecked('edit_generalpublished_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Edit */}      
                <td>{getChecked('delete_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td>{getChecked('singlerun_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Run/Test */}   
                <td>{getChecked('copypublished_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Published Test History</WizrText></td>
                <td>{getChecked('view_app-history')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Published Run History</WizrText></td>
                <td>{getChecked('view_runhistory')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Published App Share</WizrText></td>
                <td>{getChecked('view_appshare')?<TickIcon />:<CloneIcon />} </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Published Api Integration</WizrText></td>
                <td>{getChecked('integrationapi_app')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Bulk run</WizrText></td>
                <td>{getChecked('view_bulkrun')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td>{getChecked('view_bulkrun')?<TickIcon />:<CloneIcon />}  </td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Draft</WizrText></td>
                <td>{getChecked('viewdraft_app')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td>{getChecked('create_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Create/Upload */} 
                <td>{getChecked('editdraft_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Edit */}      
                <td>{getChecked('delete_app')?<TickIcon />:<CloneIcon />} </td>     {/* Delete */} 
                <td>{getChecked('publish_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Publish/Unpublish */}     
                <td>{getChecked('test_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Run/Test */}   
                <td>{getChecked('copydraft_app')?<TickIcon />:<CloneIcon />}  </td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Draft Test History</WizrText></td>
                <td>{getChecked('view_app-history')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Prompt -Templates</WizrText></td>
                <td>{getChecked('viewtemplate_prompt')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td>{getChecked('clonetemplate_prompt')?<TickIcon />:<CloneIcon />}  </td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Prompt -Published</WizrText></td>
                <td>{getChecked('viewpublished_prompt')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td>{getChecked('create_prompt')?<TickIcon />:<CloneIcon />} </td>     {/* Create/Upload */} 
                <td>{getChecked('editpublished_prompt')?<TickIcon />:<CloneIcon />}  </td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td>{getChecked('unpublish_prompt')?<TickIcon />:<CloneIcon />}  </td>     {/* Publish/Unpublish */}     
                <td>{getChecked('test_prompt')?<TickIcon />:<CloneIcon />}  </td>     {/* Run/Test */}   
                <td>{getChecked('copypublished_prompt')?<TickIcon />:<CloneIcon />}  </td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Prompt -Draft</WizrText></td>
                <td>{getChecked('viewdraft_prompt')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td>{getChecked('create_prompt')?<TickIcon />:<CloneIcon />}  </td>     {/* Create/Upload */} 
                <td>{getChecked('editdraft_prompt')?<TickIcon />:<CloneIcon />}  </td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td>{getChecked('publish_prompt')?<TickIcon />:<CloneIcon />}  </td>     {/* Publish/Unpublish */}     
                <td>{getChecked('test_prompt')?<TickIcon />:<CloneIcon />}  </td>     {/* Run/Test */}   
                <td>{getChecked('copydraft_prompt')?<TickIcon />:<CloneIcon />}  </td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Prompt History</WizrText></td>
                <td>{getChecked('view_prompt-history')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">App Insights</WizrText></td>
                <td>{getChecked('view_appInsights')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Bulkrun List</WizrText></td>
                <td><WizrCheckbox value={'view_bulkrunlist'} onChange={(value:any)=>{addRoles(value,'view_bulkrunlist')}} isEnabled={getChecked('view_bulkrunlist')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

{/* CX HUB Module */}

            <tr>
                <td rowSpan={19}><WizrText type="body2">CX Hub</WizrText></td>
                <td><WizrText type="body2">Cx Hub</WizrText></td>
                <td>{getChecked('view_cxhub')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Cx Control Room</WizrText></td>
                <td>{getChecked('view_cxcontrol')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Cx Settings</WizrText></td>
                <td>{getChecked('view_cxsettings')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Featuregroup</WizrText></td>
                <td>{getChecked('view_featuregroup')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Feature Test</WizrText></td>
                <td>{getChecked('view_featuretest')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Autotag</WizrText></td>
                <td>{getChecked('view_autotag')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Autotag endpoint</WizrText></td>
                <td>{getChecked('view_endpoint')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Agent Assist</WizrText></td>
                <td>{getChecked('view_agentassist')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Auto Solve</WizrText></td>
                <td>{getChecked('view_autosolve')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Chatbot</WizrText></td>
                <td>{getChecked('view_chatbot')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Autosolve Chatbot</WizrText></td>
                <td>{getChecked('view_autosolvechatbot')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Chatbot Listing</WizrText></td>
                <td>{getChecked('view_chatbotlisting')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Widgets</WizrText></td>
                <td>{getChecked('view_widgets')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Create Agent</WizrText></td>
                <td>{getChecked('view_createAgent')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Cx Settings - Integrations</WizrText></td>
                <td>{getChecked('view_cxsettings_integrations')?<TickIcon />:<CloneIcon />}  </td> {/* View */} 
                <td>{getChecked('create_cxsettings_integrations')?<TickIcon />:<CloneIcon />}  </td>     {/* Create/Upload */}
                <td>{getChecked('edit_cxsettings_integrations')?<TickIcon />:<CloneIcon />}  </td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Cx Settings - Feature Settings</WizrText></td>
                <td>{getChecked('view_cxsettings_features')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td>{getChecked('edit_cxsettings_features')?<TickIcon />:<CloneIcon />}  </td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td>{getChecked('run_cxsettings_features')?<TickIcon />:<CloneIcon />}  </td>     {/* Run/Test */}   
                <td>{getChecked('clone_cxsettings_features')?<TickIcon />:<CloneIcon />}  </td>     {/* Copy */}  
                <td>{getChecked('enabledisable_cxsettings_features')?<TickIcon />:<CloneIcon />}  </td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Cx Settings - Company Settings</WizrText></td>
                <td>{getChecked('view_cxsettings_company')?<TickIcon />:<CloneIcon />} </td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td>{getChecked('edit_cxsettings_company')?<TickIcon />:<CloneIcon />}  </td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Cx Settings - Ticket Settings</WizrText></td>
                <td>{getChecked('view_cxsettings_ticket')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td>{getChecked('create_cxsettings_ticketgroup')?<TickIcon />:<CloneIcon />}  </td>     {/* Create/Upload */} 
                <td>{getChecked('edit_cxsettings_ticket')?<TickIcon />:<CloneIcon />}  </td>     {/* Edit */}      
                <td>{getChecked('delete_cxsettings_ticketgroup')?<TickIcon />:<CloneIcon />}  </td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}   
            </tr>

            <tr>
                <td><WizrText type="body2">Cx Settings - Customer Interactions</WizrText></td>
                <td>{getChecked('view_customerInteractions')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}   
            </tr>


{/* Settings Module */}

<tr>
                <td rowSpan={17}><WizrText type="body2">Settings</WizrText></td>
                <td><WizrText type="body2">Settings</WizrText></td>
                <td>{getChecked('view_settings')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Profile</WizrText></td>
                <td>{getChecked('viewprofile_settings')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td>{getChecked('editprofile_settings')?<TickIcon />:<CloneIcon />}  </td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Products</WizrText></td>
                <td>{getChecked('view_product')?<TickIcon />:<CloneIcon />} </td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td>{getChecked('editproduct_settings')?<TickIcon />:<CloneIcon />}  </td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Industry Templates</WizrText></td>
                <td>{getChecked('view_packages')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td>{getChecked('createpackages_settings')?<TickIcon />:<CloneIcon />}  </td>     {/* Create/Upload */} 
                <td>{getChecked('editpackages_settings')?<TickIcon />:<CloneIcon />}  </td>     {/* Edit */}      
                <td>{getChecked('deletepackages_settings')?<TickIcon />:<CloneIcon />}  </td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Roles</WizrText></td>
                <td>{getChecked('view_roles')?<TickIcon />:<CloneIcon />}  </td> {/* View */} 
                <td>{getChecked('createroles_settings')?<TickIcon />:<CloneIcon />}  </td>     {/* Create/Upload */}
                <td>{getChecked('editroles_settings')?<TickIcon />:<CloneIcon />}  </td>     {/* Edit */}  
                <td>{getChecked('deleteroles_settings')?<TickIcon />:<CloneIcon />}  </td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Tenants</WizrText></td>
                <td>{getChecked('view_tenant')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td>{getChecked('createtenants_settings')?<TickIcon />:<CloneIcon />}  </td>     {/* Create/Upload */} 
                <td>{getChecked('edittenants_settings')?<TickIcon />:<CloneIcon />} </td>     {/* Edit */}      
                <td>{getChecked('deletetenants_settings')?<TickIcon />:<CloneIcon />}  </td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td>{getChecked('enabledisabletenants_settings')?<TickIcon />:<CloneIcon />}  </td>     {/* Enable/Disable */}   
            </tr>

            <tr>
                <td><WizrText type="body2">Tenant Details</WizrText></td>
                <td> {getChecked('view_tenantdetails')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}   
            </tr>

            <tr>
                <td><WizrText type="body2">Users</WizrText></td>
                <td>{getChecked('view_users')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td>{getChecked('createusers_settings')?<TickIcon />:<CloneIcon />}  </td>     {/* Create/Upload */} 
                <td>{getChecked('editusers_settings')?<TickIcon />:<CloneIcon />}  </td>     {/* Edit */}      
                <td>{getChecked('deleteusers_settings')?<TickIcon />:<CloneIcon />}  </td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td>{getChecked('enabledisableusers_settings')?<TickIcon />:<CloneIcon />}  </td>     {/* Enable/Disable */}   
            </tr>

            <tr>
                <td><WizrText type="body2">User Details</WizrText></td>
                <td> {getChecked('view_userdetails')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}   
            </tr>

            <tr>
                <td><WizrText type="body2">User Roles</WizrText></td>
                <td> {getChecked('view_userroles')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}   
            </tr>
            
            <tr>
                <td><WizrText type="body2">Company</WizrText></td>
                <td> {getChecked('view_company')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}   
            </tr>

            <tr>
                <td><WizrText type="body2">Products</WizrText></td>
                <td> {getChecked('view_updateproduct')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Accounts</WizrText></td>
                <td>{getChecked('view_accounts')?<TickIcon />:<CloneIcon />}  </td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Integrations</WizrText></td>
                <td>{getChecked('view_integrations')?<TickIcon />:<CloneIcon />}  </td>  {/* View */} 
                <td>{getChecked('createintegrations_settings')?<TickIcon />:<CloneIcon />}  </td>     {/* Create/Upload */} 
                <td>{getChecked('deleteintegrations_settings')?<TickIcon />:<CloneIcon />}  </td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}   
            </tr>

            <tr>
                <td><WizrText type="body2">Billings</WizrText></td>
                <td>{getChecked('view_billing')?<TickIcon />:<CloneIcon />}  </td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">AI and DB</WizrText></td>
                <td>{getChecked('view_ai&db')?<TickIcon />:<CloneIcon />}  </td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">API Providers</WizrText></td>
                <td>{getChecked('view_apiprovider')?<TickIcon />:<CloneIcon />}  </td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

{/* end user module */}
            <tr>
                <td rowSpan={5}><WizrText type="body2">End User</WizrText></td>
                <td><WizrText type="body2">end user</WizrText></td>
                {/* <td><WizrCheckbox value={'view_home'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('view_home')}/></td>   */}
                <td>{getChecked('view_euserapplisting')?<TickIcon />:<CloneIcon />}</td>
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">end user history</WizrText></td>
                <td>{getChecked('view_euserhistory')?<TickIcon />:<CloneIcon />} </td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">end user chatbot listing</WizrText></td>
                <td>{getChecked('view_chatbotlisting')?<TickIcon />:<CloneIcon />} </td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">end user chatbot</WizrText></td>
                <td>{getChecked('view_chatbot')?<TickIcon />:<CloneIcon />} </td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">end user apps</WizrText></td>
                <td>{getChecked('view_enduserapp')?<TickIcon />:<CloneIcon />} </td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

{/* end user module */}
            <tr>
                <td rowSpan={5}><WizrText type="body2">End User</WizrText></td>
                <td><WizrText type="body2">end user</WizrText></td>
                {/* <td><WizrCheckbox value={'view_home'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('view_home')}/></td>   */}
                <td>{getChecked('view_euserapplisting')?<TickIcon />:<CloneIcon />}</td>
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">end user history</WizrText></td>
                <td>{getChecked('view_euserhistory')?<TickIcon />:<CloneIcon />} </td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">end user chatbot listing</WizrText></td>
                <td>{getChecked('view_chatbotlisting')?<TickIcon />:<CloneIcon />} </td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">end user chatbot</WizrText></td>
                <td>{getChecked('view_chatbot')?<TickIcon />:<CloneIcon />} </td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">end user apps</WizrText></td>
                <td>{getChecked('view_enduserapp')?<TickIcon />:<CloneIcon />} </td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>


        </StyledTable>
    </div>
    )
}
export default WizrAccessTableView;