import { Input, Popover } from "antd"
import WizrInput from "./WizrInput"
import WizrButton from "./WizrButton"
import { useState } from "react";
import ReactMarkdown from 'react-markdown';
import { CaretDownOutlined } from "@ant-design/icons";
const { TextArea } = Input;

const Options = ({list,setInputState,updateFields,keyToSet,inputValue,setInputValue}:any):JSX.Element=>{
    return(<>
    <div style={{width:"270px",maxHeight:"270px", overflowY:"auto",overflowX:"hidden"}}>
        {list.map((item:any,index:any)=>{
            return(
            <WizrButton
            key={index}
            style={{width:"270px" ,whiteSpace: "normal", wordWrap: "break-word" ,height:"auto"}}
             text={ <ReactMarkdown>{String(item)}</ReactMarkdown>} 
             type='text' 
             onClick={() => {
               
                
                if (item==="United States Citizenship and Immigration Services Texas Service Center"){
                updateFields(keyToSet,item);
                updateFields("immigration address line1","6046 N Belt Line Rd");

                updateFields("immigration address line2","Irving TX 75038-0001");

                }
                else if (item==="United States Citizenship and Immigration Services Vermont Service Center"){
                    updateFields(keyToSet,item);
                    updateFields("immigration address line1","75 Lower Welden Street");
                    updateFields("immigration address line2","St. Albans VT 054791");
    
                    }
                    else if (item==="United States Citizenship and Immigration Services California Service Center"){
                        updateFields(keyToSet,item);
                        updateFields("immigration address line1","24000 Avila Road");
                        updateFields("immigration address line2","Laguna Niguel CA 926771");
        
                        }
                        else if (item==="United States Citizenship and Immigration Services Nebraska Service Center"){
                            updateFields(keyToSet,item);
                            updateFields("immigration address line1"," 850 S Street");
            
                            updateFields("immigration address line2"," Lincoln NE 685081");
            
                            }

                //setInputState(item) 
                else{
                // setInputValue({ ...inputValue, [keyToSet]: item });

                updateFields(keyToSet,item);
                }
                
                }
        } justifyContent='flex-start' />)

        })}</div>
        </>
    )
}

const InputDropDown = ({list,updateFields,keyToSet,inputValue,setInputValue,isTestArea = false}:any):JSX.Element =>{
  
    
    
    const [inputState,setInputState]=useState("")
    return(
        <div>
            <Popover trigger={"click"} content={<Options list={list} setInputState={setInputState} updateFields={updateFields} keyToSet={keyToSet} inputValue={inputValue} setInputValue={setInputValue} />}>
                {isTestArea?<TextArea 
            autoSize={{ minRows: 2, maxRows: 6 }}
            value={inputValue[keyToSet]} 
            onChange={(e:any)=>{setInputState(e.target.value)
                updateFields(keyToSet,e.target.value)}}
            ></TextArea>:
            <WizrInput 
            style={{minWidth:"270px"}}
            //autoSize={{ minRows: 1, maxRows: 6 }}
            value={inputValue[keyToSet]} 
            onChange={(value:any)=>{
                setInputValue({ ...inputValue, [keyToSet]: value });

                //setInputState(value)
                setInputValue({ ...inputValue, [keyToSet]: value });

                updateFields(keyToSet,value)}}
                suffix={<CaretDownOutlined />}
            ></WizrInput>}
            
            </Popover>
        </div>
    )
}
export default InputDropDown