import { Popover } from 'antd'
import WizrButton from '../WizrButton'
import MoreIcon from '../../assets/icons/More'
import EditIcon from '../../assets/icons/EditIcon'
import { useNavigate, useParams } from 'react-router-dom'
import WizrFlexLayout from '../../stories/WizrFlexLayout/WizrFlexLayout'
import DeleteIcon from '../../assets/icons/DeleteIcon'
import { MODAL_TYPES, useWizrModalContext } from '../../providers/modal/WizrModalProvider'
import { dispatchCreateFolderGroup, dispatchDeletIntegration, dispatchFeatureSettings, dispatchIntegrationList, dispatchUpdateIntegration } from '../../redux/actions/content'
import { useFeatureSettingSelector } from '../../redux/selectors/content'
import styled from 'styled-components'
import { useAuth } from '../../authContext'



const GroupPopOver = (group:any): JSX.Element => {
    const fetaureData = useFeatureSettingSelector()
  const { showModal, hideModal } = useWizrModalContext()
  const { userPermission, hasAcces } = useAuth();
const data = group?.group

let type: string
switch (data?.group) {
  case 'categories_intent':
    type = 'categories'
    break
  case 'sentiment':
    type = 'sentiments'
    break
  case 'tags':
    type = 'tags'
    break
  case 'priority':
    type = 'ticket-priority'
    break
  case 'type':
    type = 'ticket-type'
    break
}
  const onEditAction =  (e:any)=>{
    e?.stopPropagation()
    showModal(MODAL_TYPES.EDIT_GROUP,{
        groupName:data?.folder_name,
        onFormSubmission: async ({ currentGroupName }: any) => {
            const payload = {
              id: fetaureData?.id,
              data: {
                folder_id:data?.folder_id,
                folder_name: currentGroupName,
                status: data?.status,
              },
            }
            await dispatchCreateFolderGroup(payload, type)
            dispatchFeatureSettings()
            hideModal()
          },
    
          onCancel: hideModal,
      })
  }
  const deleteGroup=async(e:any)=>{
    hideModal()
    const payload = {
        id: fetaureData?.id,
        data: {
          folder_id:data?.folder_id,
          folder_name: data?.folder_name,
          status: 'deleted',
        },
      }
      await dispatchCreateFolderGroup(payload, type)
      dispatchFeatureSettings()
      hideModal()    
  }

  const onDeleteAction = (e:any)=>{
    e.stopPropagation()
    showModal(MODAL_TYPES.CONFIRMATION, {
      text:`Are you sure you want to delete ${data?.folder_name} ?`,
      buttontext:"Confirm",
  onConfirm:deleteGroup,
  onCancel:hideModal

    })    
  }
  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      
    <WizrButton
        icon={<EditIcon />}
        text='Edit'
        type='text'
        onClick={(e:any)=>{onEditAction(e)}}
        justifyContent='flex-start'
        disabled={!hasAcces('edit_cxsettings_ticket')}

      />
      <WizrButton
        icon={<DeleteIcon />}
        text='Delete'
        type='text'
        onClick={(e:any)=>{onDeleteAction(e)}}
        justifyContent='flex-start'
        disabled={!hasAcces('delete_cxsettings_ticketgroup')}

      />
    </WizrFlexLayout>
  )
}

const GroupActionPopover = (groupDetails:any): JSX.Element => {
  const navigate = useNavigate()
 
  return (
    <Popover
      content={
        <GroupPopOver
        group={groupDetails?.groupDetails}
        />
      }
      trigger='click'
    >
      <StyledButton icon={<MoreIcon />} text='' type='text' style={{height: '40px', width: '40px', borderRadius: '50px'}}/>
    </Popover>
  )
}

export default GroupActionPopover

const StyledButton = styled(WizrButton)`
border-radius: 50%;
width: 20px;
height: 20px;
padding-right: 8px;
justify-content: center;
align-items: center;
  /* Default styles */
  path {
    stroke: #999B9F; /* Default stroke color */
    transition: stroke 0.3s ease; /* Smooth transition for color change */
  }

  /* Hover styles */
  &:hover path {
    stroke: #1D1F22
  }
`;
