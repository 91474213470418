import { Popover } from 'antd'
import WizrButton from '../WizrButton'
import MoreIcon from '../../assets/icons/More'
import EditIcon from '../../assets/icons/EditIcon'
import { useNavigate, useParams } from 'react-router-dom'
import WizrFlexLayout from '../../stories/WizrFlexLayout/WizrFlexLayout'
import DeleteIcon from '../../assets/icons/DeleteIcon'
import { MODAL_TYPES, useWizrModalContext } from '../../providers/modal/WizrModalProvider'
import { dispatchCreateFolderGroup, dispatchDeletIntegration, dispatchFeatureSettings, dispatchIntegrationList, dispatchUpdateIntegration, dispatchUpdateTicketType } from '../../redux/actions/content'
import { useFeatureSettingSelector } from '../../redux/selectors/content'
import styled from 'styled-components'



const TicketPopover = ({group}:any): JSX.Element => {
    const featureData = useFeatureSettingSelector()
    const params = useParams()
    const title = params?.title
  const { showModal, hideModal } = useWizrModalContext()
let type: string
switch (title) {
  case 'categories_intent':
    type = 'categories'
    break
  case 'sentiment':
    type = 'sentiments'
    break
  case 'tags':
    type = 'tags'
    break
  case 'priority':
    type = 'ticket-priority'
    break
  case 'type':
    type = 'ticket-type'
    break
}
  const onEditAction =  ()=>{
    showModal(MODAL_TYPES.EDIT_TICKET_TYPE, {
        type:title,
        data:group,
        folder:params?.id,
        onCancel:hideModal
      })
  }
  const deleteTicket=async()=>{
    hideModal()
    const payload = {
        id :featureData?.id,
        folder_id:params?.id,
        data:{
          id:group?.id,
            status:'deleted'
        }}
     await dispatchUpdateTicketType(payload,type)
      dispatchFeatureSettings()
      hideModal()    
  }

  const onDeleteAction = ()=>{
    showModal(MODAL_TYPES.CONFIRMATION, {
      text:`Are you sure you want to delete ${group?.name}?`,
      buttontext:"Confirm",
  onConfirm:deleteTicket,
  onCancel:hideModal

    })    
  }
  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      
    <WizrButton
        icon={<EditIcon />}
        text='Edit'
        type='text'
        onClick={()=>{onEditAction()}}
        justifyContent='flex-start'
      />
      <WizrButton
        icon={<DeleteIcon />}
        text='Delete'
        type='text'
        onClick={()=>{onDeleteAction()}}
        justifyContent='flex-start'
      />
    </WizrFlexLayout>
  )
}

const TicketActionPopover = ({details}:any): JSX.Element => {
  return (
    <Popover
      content={
        <TicketPopover
        group={details}
        />
      }
      trigger='click'
    >
      <StyledButton icon={<MoreIcon />} text='' type='text' style={{height: '40px', width: '40px', borderRadius: '50px'}}/>
    </Popover>
  )
}

export default TicketActionPopover

const StyledButton = styled(WizrButton)`
border-radius: 50%;
width: 20px;
height: 20px;
padding-right: 8px;
justify-content: center;
align-items: center;
  /* Default styles */
  path {
    stroke: #999B9F; /* Default stroke color */
    transition: stroke 0.3s ease; /* Smooth transition for color change */
  }

  /* Hover styles */
  &:hover path {
    stroke: #1D1F22
  }
`;
