import React from 'react'

const Mp3logoIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.308 1.00001H10.829C10.8863 1.00001 10.9433 1.00367 11 1.01101C13.2473 1.16817 14.9924 3.0332 15 5.28601V11.715C14.9917 14.0871 13.0641 16.0044 10.692 16H5.308C2.93551 16.0044 1.00772 14.0865 1 11.714V5.28601C1.00772 2.91353 2.93551 0.995581 5.308 1.00001Z'
        stroke='#1D1F22'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15 6.03599C15.4142 6.03599 15.75 5.7002 15.75 5.28599C15.75 4.87177 15.4142 4.53599 15 4.53599V6.03599ZM11 5.28599H10.25C10.25 5.7002 10.5858 6.03599 11 6.03599V5.28599ZM11.75 1.01099C11.75 0.596773 11.4142 0.260986 11 0.260986C10.5858 0.260986 10.25 0.596773 10.25 1.01099H11.75ZM7 12.75C7.41421 12.75 7.75 12.4142 7.75 12C7.75 11.5858 7.41421 11.25 7 11.25V12.75ZM4 11.25C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75V11.25ZM11 9.74999C11.4142 9.74999 11.75 9.4142 11.75 8.99999C11.75 8.58577 11.4142 8.24999 11 8.24999V9.74999ZM4 8.24999C3.58579 8.24999 3.25 8.58577 3.25 8.99999C3.25 9.4142 3.58579 9.74999 4 9.74999V8.24999ZM15 4.53599H11V6.03599H15V4.53599ZM11.75 5.28599V1.01099H10.25V5.28599H11.75ZM7 11.25H4V12.75H7V11.25ZM11 8.24999H4V9.74999H11V8.24999Z'
        fill='#1D1F22'
      />
    </svg>
  )
}

export default Mp3logoIcon
