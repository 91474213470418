import styled from "styled-components";
import WizrText from "../WizrText";
import WizrFlexLayout from "../WizrFlexLayout";
import WizrCheckbox from "../WizrCheckbox";
import { useState } from "react";

const StyledTable = styled.table`
    border-collapse: collapse;
    width: 100%;
    th, td {
        border: 1px solid #EAECF1;
        padding:  15px 10px  !important;
        text-align: left;
        }`

     
const WizrAccesTable = ({setSelectedItems,selectedItems}:any) => {
   // const [currentActiveCheckBoxes,setCurrentActiveCheckBoxes] = useState<any[]>([])
    // const currentActiveCheckBoxes :any[] = []

    const getChecked = (key:any) => {
return  selectedItems.includes(key)   
 }
    
const addRoles = (value:any,dependency?:any) => {
    
    if (value.target.checked){
        if (dependency){
            setSelectedItems([...selectedItems,value.target.value,dependency])
        }
        else{
            setSelectedItems([...selectedItems,value.target.value])}
    }
    else{
        setSelectedItems(selectedItems.filter((item:any)=>item!==value.target.value))
    }
}

    return(
    <div >
        <StyledTable  className="accesstable">
            <tr>
                    <th rowSpan={2}><WizrText type="sub2">Module</WizrText></th>
                    <th rowSpan={2}><WizrText type="sub2">Features</WizrText></th>
                    <th colSpan={8}><WizrFlexLayout textAlign="center" background="white"><WizrText type="sub2">Operations</WizrText></WizrFlexLayout></th>
            </tr>
            <tr>
                    <td><WizrText type="body2" textColor="#1D1F22">View</WizrText></td>
                    <td><WizrText type="body2">Create/Upload</WizrText></td>
                    <td><WizrText type="body2">Edit</WizrText></td>
                    <td><WizrText type="body2">Delete</WizrText></td>
                    <td><WizrText type="body2">Publish/Unpublish</WizrText></td>
                    <td><WizrText type="body2">Run/Test</WizrText></td>
                    <td><WizrText type="body2">Copy</WizrText></td>
                    <td><WizrText type="body2">Enable/Disable</WizrText></td>


            </tr>

{/* Home module */}
            <tr>
                <td rowSpan={3}><WizrText type="body2">Home</WizrText></td>
                <td><WizrText type="body2">Home</WizrText></td>
                <td><WizrCheckbox value={'view_home'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('view_home')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Apps</WizrText></td>
                <td><WizrCheckbox value={'listapps_home'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('listapps_home')}/></td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td><WizrCheckbox value={'apprun_home'} onChange={(value:any)=>{addRoles(value,'listapps_home')}} isEnabled={getChecked('apprun_home')}/></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Contents</WizrText></td>
                <td><WizrCheckbox value={'listfolder_home'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('listfolder_home')}/></td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */} 
                <td></td>     {/* Run/Test */}
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

{/* Content module */}

            <tr>
                <td rowSpan={6}><WizrText type="body2">Content</WizrText></td>
                <td><WizrText type="body2">Knowledge Base - Folder</WizrText></td>
                <td><WizrCheckbox value={'view_folder'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('view_folder')}/></td>  {/* View */} 
                <td><WizrCheckbox value={'create_folder'} onChange={(value:any)=>{addRoles(value,'view_folder')}} isEnabled={getChecked('create_folder')}/></td>     {/* Create/Upload */} 
                <td><WizrCheckbox value={'update_folder'} onChange={(value:any)=>{addRoles(value,'view_folder')}} isEnabled={getChecked('update_folder')}/></td>     {/* Edit */}      
                <td><WizrCheckbox value={'delete_folder'} onChange={(value:any)=>{addRoles(value,'view_folder')}} isEnabled={getChecked('delete_folder')}/></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Knowledge Base - File</WizrText></td>
                <td><WizrCheckbox value={'view_file'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('view_file')}/></td> {/* View */} 
                <td><WizrCheckbox value={'upload_file'} onChange={(value:any)=>{addRoles(value,'view_file')}} isEnabled={getChecked('upload_file')}/></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td><WizrCheckbox value={'delete_file'} onChange={(value:any)=>{addRoles(value,'view_file')}} isEnabled={getChecked('delete_file')}/></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Faq - Folder</WizrText></td>
                <td><WizrCheckbox value={'view_faq'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('view_faq')}/></td>  {/* View */} 
                <td><WizrCheckbox value={'create_faqfolder'} onChange={(value:any)=>{addRoles(value,'view_faqfolder')}} isEnabled={getChecked('create_faqfolder')}/></td>     {/* Create/Upload */} 
                <td><WizrCheckbox value={'update_faqfolder'} onChange={(value:any)=>{addRoles(value,'view_faqfolder')}} isEnabled={getChecked('update_faqfolder')}/></td>     {/* Edit */}      
                <td><WizrCheckbox value={'delete_faqfolder'} onChange={(value:any)=>{addRoles(value,'view_faqfolder')}} isEnabled={getChecked('delete_faqfolder')}/></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Faq - File</WizrText></td>
                <td><WizrCheckbox value={'view_faqfile'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('view_faqfile')}/></td> {/* View */} 
                <td><WizrCheckbox value={'upload_faqfile'} onChange={(value:any)=>{addRoles(value,'view_faqfile')}} isEnabled={getChecked('upload_faqfile')}/></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td><WizrCheckbox value={'delete_faqfile'} onChange={(value:any)=>{addRoles(value,'view_faqfile')}} isEnabled={getChecked('delete_faqfile')}/></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Past Ticket - Folder</WizrText></td> 
                <td><WizrCheckbox value={'view_past_tickets'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('view_past_tickets')}/></td>  {/* View */} 
                <td><WizrCheckbox value={'create_ptfolder'} onChange={(value:any)=>{addRoles(value,'view_ptfolder')}} isEnabled={getChecked('create_ptfolder')}/></td>     {/* Create/Upload */} 
                <td><WizrCheckbox value={'update_ptfolder'} onChange={(value:any)=>{addRoles(value,'view_ptfolder')}} isEnabled={getChecked('update_ptfolder')}/></td>     {/* Edit */}      
                <td><WizrCheckbox value={'delete_ptfolder'} onChange={(value:any)=>{addRoles(value,'view_ptfolder')}} isEnabled={getChecked('delete_ptfolder')}/></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}   
            </tr>

            <tr>
                <td><WizrText type="body2">Past Ticket - File</WizrText></td>
                <td><WizrCheckbox value={'view_ptfile'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('view_ptfile')}/></td> {/* View */} 
                <td><WizrCheckbox value={'upload_ptfile'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('upload_ptfile')}/></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td><WizrCheckbox value={'delete_ptfile'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('delete_ptfile')}/></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

{/* AI Studio module */}

            <tr>
                <td rowSpan={36}><WizrText type="body2">AI Studio</WizrText></td>
                <td><WizrText type="body2">Package App -Templates</WizrText></td>
                <td><WizrCheckbox value={'viewtemplate_app'} onChange={(value:any)=>{addRoles(value,'viewtemplate_app')}} isEnabled={getChecked('viewtemplate_app')}/></td>  {/* View */} 
                <td><WizrCheckbox value={'create_app'} onChange={(value:any)=>{addRoles(value,'viewtemplate_app')}} isEnabled={getChecked('create_app')}/></td>     {/* Create/Upload */} 
                <td><WizrCheckbox value={'edit_packagetemplate_app'} onChange={(value:any)=>{addRoles(value,'viewtemplate_app')}} isEnabled={getChecked('edit_packagetemplate_app')}/></td>     {/* Edit */}      
                <td><WizrCheckbox value={'delete_app'} onChange={(value:any)=>{addRoles(value,'viewtemplate_app')}} isEnabled={getChecked('delete_app')}/></td>     {/* Delete */} 
                <td><WizrCheckbox value={'publish_app'} onChange={(value:any)=>{addRoles(value,'viewtemplate_app')}} isEnabled={getChecked('publish_app')}/></td>     {/* Publish/Unpublish */}     
                <td><WizrCheckbox value={'singlerun_app'} onChange={(value:any)=>{addRoles(value,'viewtemplate_app')}} isEnabled={getChecked('singlerun_app')}/></td>     {/* Run/Test */}   
                <td><WizrCheckbox value={'clonetemplate_app'} onChange={(value:any)=>{addRoles(value,'viewtemplate_app')}} isEnabled={getChecked('clonetemplate_app')}/></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Prompt</WizrText></td>
                <td><WizrCheckbox value={'view_prompt'} onChange={(value:any)=>{addRoles(value,'view_prompt')}} isEnabled={getChecked('view_prompt')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">App-Designer</WizrText></td>
                <td><WizrCheckbox value={'view_app-designer'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('view_app-designer')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Templates Test History</WizrText></td>
                <td><WizrCheckbox value={'view_app-history'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('view_app-history')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Templates Run History</WizrText></td>
                <td><WizrCheckbox value={'view_runhistory'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('view_runhistory')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Templates App Share</WizrText></td>
                <td><WizrCheckbox value={'view_appshare'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('view_appshare')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Templates Api Integration</WizrText></td>
                <td><WizrCheckbox value={'integrationapi_app'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('integrationapi_app')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Templates BulkRun</WizrText></td>
                <td><WizrCheckbox value={'bulkrun_app'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('bulkrun_app')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td><WizrCheckbox value={'bulkrun_app'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('bulkrun_app')}/></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>


            <tr>
                <td><WizrText type="body2">Package App -Published</WizrText></td>
                <td><WizrCheckbox value={'viewpublished_app'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('viewpublished_app')}/></td>  {/* View */} 
                <td><WizrCheckbox value={'create_app'} onChange={(value:any)=>{addRoles(value,'viewpublished_app')}} isEnabled={getChecked('create_app')}/></td>     {/* Create/Upload */} 
                <td><WizrCheckbox value={'edit_packagepublished_app'} onChange={(value:any)=>{addRoles(value,'viewpublished_app')}} isEnabled={getChecked('edit_packagepublished_app')}/></td>     {/* Edit */}      
                <td><WizrCheckbox value={'delete_app'} onChange={(value:any)=>{addRoles(value,'viewpublished_app')}} isEnabled={getChecked('delete_app')}/></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td><WizrCheckbox value={'singlerun_app'} onChange={(value:any)=>{addRoles(value,'viewpublished_app')}} isEnabled={getChecked('singlerun_app')}/></td>     {/* Run/Test */}   
                <td><WizrCheckbox value={'copypublished_app'} onChange={(value:any)=>{addRoles(value,'viewpublished_app')}} isEnabled={getChecked('copypublished_app')}/></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Published Test History</WizrText></td>
                <td><WizrCheckbox value={'view_app-history'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('view_app-history')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Published Run History  </WizrText></td>
                <td><WizrCheckbox value={'view_runhistory'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('view_runhistory')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Published App Share</WizrText></td>
                <td><WizrCheckbox value={'view_appshare'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('view_appshare')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Published Api Integration</WizrText></td>
                <td><WizrCheckbox value={'integrationapi_app'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('integrationapi_app')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Published BulkRun</WizrText></td>
                <td><WizrCheckbox value={'bulkrun_app'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('bulkrun_app')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td><WizrCheckbox value={'bulkrun_app'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('bulkrun_app')}/></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Draft</WizrText></td>
                <td><WizrCheckbox value={'viewdraft_app'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('viewdraft_app')}/></td>  {/* View */} 
                <td><WizrCheckbox value={'create_app'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('create_app')}/></td>     {/* Create/Upload */} 
                <td><WizrCheckbox value={'editdraft_app'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('editdraft_app')}/></td>     {/* Edit */}      
                <td><WizrCheckbox value={'delete_app'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('delete_app')}/></td>     {/* Delete */} 
                <td><WizrCheckbox value={'publish_app'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('publish_app')}/></td>     {/* Publish/Unpublish */}     
                <td><WizrCheckbox value={'test_app'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('test_app')}/></td>     {/* Run/Test */}   
                <td><WizrCheckbox value={'copydraft_app'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('copydraft_app')}/></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Package App -Draft Test History</WizrText></td>
                <td><WizrCheckbox value={'view_app-history'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('view_app-history')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>
            
            <tr>
                <td><WizrText type="body2">General App -Templates</WizrText></td>
                <td><WizrCheckbox value={'viewtemplate_app'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('viewtemplate_app')}/></td>  {/* View */} 
                <td><WizrCheckbox value={'create_app'} onChange={(value:any)=>{addRoles(value,'viewtemplate_app')}} isEnabled={getChecked('create_app')}/></td>     {/* Create/Upload */} 
                <td><WizrCheckbox value={'edit_generaltemplate_app'} onChange={(value:any)=>{addRoles(value,'viewtemplate_app')}} isEnabled={getChecked('edit_generaltemplate_app')}/></td>     {/* Edit */}      
                <td><WizrCheckbox value={'delete_app'} onChange={(value:any)=>{addRoles(value,'viewtemplate_app')}} isEnabled={getChecked('delete_app')}/></td>     {/* Delete */} 
                <td><WizrCheckbox value={'publish_app'} onChange={(value:any)=>{addRoles(value,'viewtemplate_app')}} isEnabled={getChecked('publish_app')}/></td>     {/* Publish/Unpublish */}     
                <td><WizrCheckbox value={'singlerun_app'} onChange={(value:any)=>{addRoles(value,'viewtemplate_app')}} isEnabled={getChecked('singlerun_app')}/></td>     {/* Run/Test */}   
                <td><WizrCheckbox value={'clonetemplate_app'} onChange={(value:any)=>{addRoles(value,'viewtemplate_app')}} isEnabled={getChecked('clonetemplate_app')}/></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Templates Test History</WizrText></td>
                <td><WizrCheckbox value={'view_app-history'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('view_app-history')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Templates Run History</WizrText></td>
                <td><WizrCheckbox value={'view_runhistory'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('view_runhistory')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Templates App Share</WizrText></td>
                <td><WizrCheckbox value={'view_appshare'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('view_appshare')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Templates Api Integration</WizrText></td>
                <td><WizrCheckbox value={'integrationapi_app'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('integrationapi_app')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Templates BulkRun</WizrText></td>
                <td><WizrCheckbox value={'bulkrun_app'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('bulkrun_app')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td><WizrCheckbox value={'bulkrun_app'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('bulkrun_app')}/></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>


            <tr>
                <td><WizrText type="body2">General App -Published</WizrText></td>
                <td><WizrCheckbox value={'viewpublished_app'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('viewpublished_app')}/></td>  {/* View */} 
                <td><WizrCheckbox value={'create_app'} onChange={(value:any)=>{addRoles(value,'viewpublished_app')}} isEnabled={getChecked('create_app')}/></td>     {/* Create/Upload */} 
                <td><WizrCheckbox value={'edit_generalpublished_app'} onChange={(value:any)=>{addRoles(value,'viewpublished_app')}} isEnabled={getChecked('edit_generalpublished_app')}/></td>     {/* Edit */}      
                <td><WizrCheckbox value={'delete_app'} onChange={(value:any)=>{addRoles(value,'viewpublished_app')}} isEnabled={getChecked('delete_app')}/></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td><WizrCheckbox value={'singlerun_app'} onChange={(value:any)=>{addRoles(value,'viewpublished_app')}} isEnabled={getChecked('singlerun_app')}/></td>     {/* Run/Test */}   
                <td><WizrCheckbox value={'copypublished_app'} onChange={(value:any)=>{addRoles(value,'viewpublished_app')}} isEnabled={getChecked('copypublished_app')}/></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Published Test History</WizrText></td>
                <td><WizrCheckbox value={'view_app-history'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('view_app-history')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Published Run History</WizrText></td>
                <td><WizrCheckbox value={'view_runhistory'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('view_runhistory')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Published App Share</WizrText></td>
                <td><WizrCheckbox value={'view_appshare'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('view_appshare')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Published Api Integration</WizrText></td>
                <td><WizrCheckbox value={'integrationapi_app'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('integrationapi_app')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Bulk run</WizrText></td>
                <td><WizrCheckbox value={'view_bulkrun'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('view_bulkrun')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td><WizrCheckbox value={'view_bulkrun'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('view_bulkrun')}/></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Draft</WizrText></td>
                <td><WizrCheckbox value={'viewdraft_app'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('viewdraft_app')}/></td>  {/* View */} 
                <td><WizrCheckbox value={'create_app'} onChange={(value:any)=>{addRoles(value,'viewdraft_app')}} isEnabled={getChecked('create_app')}/></td>     {/* Create/Upload */} 
                <td><WizrCheckbox value={'editdraft_app'} onChange={(value:any)=>{addRoles(value,'viewdraft_app')}} isEnabled={getChecked('editdraft_app')}/></td>     {/* Edit */}      
                <td><WizrCheckbox value={'delete_app'} onChange={(value:any)=>{addRoles(value,'viewdraft_app')}} isEnabled={getChecked('delete_app')}/></td>     {/* Delete */} 
                <td><WizrCheckbox value={'publish_app'} onChange={(value:any)=>{addRoles(value,'viewdraft_app')}} isEnabled={getChecked('publish_app')}/></td>     {/* Publish/Unpublish */}     
                <td><WizrCheckbox value={'test_app'} onChange={(value:any)=>{addRoles(value,'viewdraft_app')}} isEnabled={getChecked('test_app')}/></td>     {/* Run/Test */}   
                <td><WizrCheckbox value={'copydraft_app'} onChange={(value:any)=>{addRoles(value,'viewdraft_app')}} isEnabled={getChecked('copydraft_app')}/></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">General App -Draft Test History</WizrText></td>
                <td><WizrCheckbox value={'view_app-history'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('view_app-history')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Prompt -Templates</WizrText></td>
                <td><WizrCheckbox value={'viewtemplate_prompt'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('viewtemplate_prompt')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td><WizrCheckbox value={'clonetemplate_prompt'} onChange={(value:any)=>{addRoles(value,'viewtemplate_prompt')}} isEnabled={getChecked('clonetemplate_prompt')}/></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Prompt -Published</WizrText></td>
                <td><WizrCheckbox value={'viewpublished_prompt'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('viewpublished_prompt')}/></td>  {/* View */} 
                <td><WizrCheckbox value={'create_prompt'} onChange={(value:any)=>{addRoles(value,'viewpublished_prompt')}} isEnabled={getChecked('create_prompt')}/></td>     {/* Create/Upload */} 
                <td><WizrCheckbox value={'editpublished_prompt'} onChange={(value:any)=>{addRoles(value,'viewpublished_prompt')}} isEnabled={getChecked('editpublished_prompt')}/></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td><WizrCheckbox value={'unpublish_prompt'} onChange={(value:any)=>{addRoles(value,'viewpublished_prompt')}} isEnabled={getChecked('unpublish_prompt')}/></td>     {/* Publish/Unpublish */}     
                <td><WizrCheckbox value={'test_prompt'} onChange={(value:any)=>{addRoles(value,'viewpublished_prompt')}} isEnabled={getChecked('test_prompt')}/></td>     {/* Run/Test */}   
                <td><WizrCheckbox value={'copypublished_prompt'} onChange={(value:any)=>{addRoles(value,'viewpublished_prompt')}} isEnabled={getChecked('copypublished_prompt')}/></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Prompt -Draft</WizrText></td>
                <td><WizrCheckbox value={'viewdraft_prompt'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('viewdraft_prompt')}/></td>  {/* View */} 
                <td><WizrCheckbox value={'create_prompt'} onChange={(value:any)=>{addRoles(value,'viewdraft_prompt')}} isEnabled={getChecked('create_prompt')}/></td>     {/* Create/Upload */} 
                <td><WizrCheckbox value={'editdraft_prompt'} onChange={(value:any)=>{addRoles(value,'viewdraft_prompt')}} isEnabled={getChecked('editdraft_prompt')}/></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td><WizrCheckbox value={'publish_prompt'} onChange={(value:any)=>{addRoles(value,'viewdraft_prompt')}} isEnabled={getChecked('publish_prompt')}/></td>     {/* Publish/Unpublish */}     
                <td><WizrCheckbox value={'test_prompt'} onChange={(value:any)=>{addRoles(value,'viewdraft_prompt')}} isEnabled={getChecked('test_prompt')}/></td>     {/* Run/Test */}   
                <td><WizrCheckbox value={'copydraft_prompt'} onChange={(value:any)=>{addRoles(value,'viewdraft_prompt')}} isEnabled={getChecked('copydraft_prompt')}/></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Prompt History</WizrText></td>
                <td><WizrCheckbox value={'view_prompt-history'} onChange={(value:any)=>{addRoles(value,'view_app-designer')}} isEnabled={getChecked('view_prompt-history')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">App Insights</WizrText></td>
                <td><WizrCheckbox value={'view_appInsights'} onChange={(value:any)=>{addRoles(value,'view_appInsights')}} isEnabled={getChecked('view_appInsights')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Bulkrun List</WizrText></td>
                <td><WizrCheckbox value={'view_bulkrunlist'} onChange={(value:any)=>{addRoles(value,'view_bulkrunlist')}} isEnabled={getChecked('view_bulkrunlist')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

{/* CX HUB Module */}

            <tr>
                <td rowSpan={20}><WizrText type="body2">CX Hub</WizrText></td>
                <td><WizrText type="body2">Cx Hub</WizrText></td>
                <td><WizrCheckbox value={'view_cxhub'} onChange={(value:any)=>{addRoles(value,'view_cxhub')}} isEnabled={getChecked('view_cxhub')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Cx Control Room</WizrText></td>
                <td><WizrCheckbox value={'view_cxcontrol'} onChange={(value:any)=>{addRoles(value,'view_cxcontrol')}} isEnabled={getChecked('view_cxcontrol')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Cx Settings</WizrText></td>
                <td><WizrCheckbox value={'view_cxsettings'} onChange={(value:any)=>{addRoles(value,'view_cxsettings')}} isEnabled={getChecked('view_cxsettings')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */} 
            </tr>
            <tr>
                <td><WizrText type="body2">Featuregroup</WizrText></td>
                <td><WizrCheckbox value={'view_featuregroup'} onChange={(value:any)=>{addRoles(value,'view_featuregroup')}} isEnabled={getChecked('view_featuregroup')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Feature Test</WizrText></td>
                <td><WizrCheckbox value={'view_featuretest'} onChange={(value:any)=>{addRoles(value,'view_featuretest')}} isEnabled={getChecked('view_featuretest')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Autotag</WizrText></td>
                <td><WizrCheckbox value={'view_autotag'} onChange={(value:any)=>{addRoles(value,'view_autotag')}} isEnabled={getChecked('view_autotag')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Autotag endpoint</WizrText></td>
                <td><WizrCheckbox value={'view_endpoint'} onChange={(value:any)=>{addRoles(value,'view_endpoint')}} isEnabled={getChecked('view_endpoint')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Agent Assist</WizrText></td>
                <td><WizrCheckbox value={'view_agentassist'} onChange={(value:any)=>{addRoles(value,'view_agentassist')}} isEnabled={getChecked('view_agentassist')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Auto Solve</WizrText></td>
                <td><WizrCheckbox value={'view_autosolve'} onChange={(value:any)=>{addRoles(value,'view_autosolve')}} isEnabled={getChecked('view_autosolve')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Chatbot</WizrText></td>
                <td><WizrCheckbox value={'view_chatbot'} onChange={(value:any)=>{addRoles(value,'view_chatbot')}} isEnabled={getChecked('view_chatbot')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Autosolve Chatbot</WizrText></td>
                <td><WizrCheckbox value={'view_austosolvechatbot'} onChange={(value:any)=>{addRoles(value,'view_austosolvechatbot')}} isEnabled={getChecked('view_austosolvechatbot')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Chatbot Listing</WizrText></td>
                <td><WizrCheckbox value={'view_chatbotlisting'} onChange={(value:any)=>{addRoles(value,'view_chatbotlisting')}} isEnabled={getChecked('view_chatbotlisting')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Widgets</WizrText></td>
                <td><WizrCheckbox value={'view_widgets'} onChange={(value:any)=>{addRoles(value,'view_widgets')}} isEnabled={getChecked('view_widgets')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Create Agent</WizrText></td>
                <td><WizrCheckbox value={'view_createAgent'} onChange={(value:any)=>{addRoles(value,'view_createAgent')}} isEnabled={getChecked('view_createAgent')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Autosolve Chatbot</WizrText></td>
                <td><WizrCheckbox value={'view_autosolvechatbot'} onChange={(value:any)=>{addRoles(value,'view_autosolvechatbot')}} isEnabled={getChecked('view_autosolvechatboot')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Cx Settings - Integrations</WizrText></td>
                <td><WizrCheckbox value={'view_cxsettings_integrations'} onChange={(value:any)=>{addRoles(value,'view_cxhub')}} isEnabled={getChecked('view_cxsettings_integrations')}/></td> {/* View */} 
                <td><WizrCheckbox value={'create_cxsettings_integrations'} onChange={(value:any)=>{addRoles(value,'view_cxsettings_integrations')}} isEnabled={getChecked('create_cxsettings_integrations')}/></td>     {/* Create/Upload */}
                <td><WizrCheckbox value={'edit_cxsettings_integrations'} onChange={(value:any)=>{addRoles(value,'view_cxsettings_integrations')}} isEnabled={getChecked('edit_cxsettings_integrations')}/></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Cx Settings - Feature Settings</WizrText></td>
                <td><WizrCheckbox value={'view_cxsettings_features'} onChange={(value:any)=>{addRoles(value,'view_cxhub')}} isEnabled={getChecked('view_cxsettings_features')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td><WizrCheckbox value={'edit_cxsettings_features'} onChange={(value:any)=>{addRoles(value,'view_cxsettings_features')}} isEnabled={getChecked('edit_cxsettings_features')}/></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td><WizrCheckbox value={'run_cxsettings_features'} onChange={(value:any)=>{addRoles(value,'view_cxsettings_features')}} isEnabled={getChecked('run_cxsettings_features')}/></td>     {/* Run/Test */}   
                <td><WizrCheckbox value={'clone_cxsettings_features'} onChange={(value:any)=>{addRoles(value,'view_cxsettings_features')}} isEnabled={getChecked('clone_cxsettings_features')}/></td>     {/* Copy */}  
                <td><WizrCheckbox value={'enabledisable_cxsettings_features'} onChange={(value:any)=>{addRoles(value,'view_cxsettings_features')}} isEnabled={getChecked('enabledisable_cxsettings_features')}/></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Cx Settings - Company Settings</WizrText></td>
                <td><WizrCheckbox value={'view_cxsettings_company'} onChange={(value:any)=>{addRoles(value,'view_cxhub')}} isEnabled={getChecked('view_cxsettings_company')}/></td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td><WizrCheckbox value={'edit_cxsettings_company'} onChange={(value:any)=>{addRoles(value,'view_cxsettings_company')}} isEnabled={getChecked('edit_cxsettings_company')}/></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Cx Settings - Ticket Settings</WizrText></td>
                <td><WizrCheckbox value={'view_cxsettings_ticket'} onChange={(value:any)=>{addRoles(value,'view_cxhub')}} isEnabled={getChecked('view_cxsettings_ticket')}/></td>  {/* View */} 
                <td><WizrCheckbox value={'create_cxsettings_ticketgroup'} onChange={(value:any)=>{addRoles(value,'view_cxsettings_ticket')}} isEnabled={getChecked('create_cxsettings_ticketgroup')}/></td>     {/* Create/Upload */} 
                <td><WizrCheckbox value={'edit_cxsettings_ticket'} onChange={(value:any)=>{addRoles(value,'view_cxsettings_ticket')}} isEnabled={getChecked('edit_cxsettings_ticket')}/></td>     {/* Edit */}      
                <td><WizrCheckbox value={'delete_cxsettings_ticketgroup'} onChange={(value:any)=>{addRoles(value,'view_cxsettings_ticket')}} isEnabled={getChecked('delete_cxsettings_ticketgroup')}/></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}   
            </tr>

            <tr>
                <td><WizrText type="body2">Cx Settings - Customer Interactions</WizrText></td>
                <td><WizrCheckbox value={'view_customerInteractions'} onChange={(value:any)=>{addRoles(value,'view_customerInteractions')}} isEnabled={getChecked('view_customerInteractions')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}   
            </tr>


{/* Settings Module */}

            <tr>
                <td rowSpan={18}><WizrText type="body2">Settings</WizrText></td>
                <td><WizrText type="body2">Settings</WizrText></td>
                <td><WizrCheckbox value={'view_settings'} onChange={(value:any)=>{addRoles(value,'view_settings')}} isEnabled={getChecked('view_settings')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            
            <tr>
                <td><WizrText type="body2">Profile</WizrText></td>
                <td><WizrCheckbox value={'viewprofile_settings'} onChange={(value:any)=>{addRoles(value,'view_settings')}} isEnabled={getChecked('viewprofile_settings')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td><WizrCheckbox value={'editprofile_settings'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('editprofile_settings')}/></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Products</WizrText></td>
                <td><WizrCheckbox value={'view_product'} onChange={(value:any)=>{addRoles(value,'view_settings')}} isEnabled={getChecked('view_product')}/></td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td><WizrCheckbox value={'editproduct_settings'} onChange={(value:any)=>{addRoles(value,'view_product')}} isEnabled={getChecked('editproduct_settings')}/></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Industry Templates</WizrText></td>
                <td><WizrCheckbox value={'view_packages'} onChange={(value:any)=>{addRoles(value,'view_settings')}} isEnabled={getChecked('view_packages')}/></td>  {/* View */} 
                <td><WizrCheckbox value={'createpackages_settings'} onChange={(value:any)=>{addRoles(value,'view_packages')}} isEnabled={getChecked('createpackages_settings')}/></td>     {/* Create/Upload */} 
                <td><WizrCheckbox value={'editpackages_settings'} onChange={(value:any)=>{addRoles(value,'view_packages')}} isEnabled={getChecked('editpackages_settings')}/></td>     {/* Edit */}      
                <td><WizrCheckbox value={'deletepackages_settings'} onChange={(value:any)=>{addRoles(value,'view_packages')}} isEnabled={getChecked('deletepackages_settings')}/></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Roles</WizrText></td>
                <td><WizrCheckbox value={'view_roles'} onChange={(value:any)=>{addRoles(value,'view_settings')}} isEnabled={getChecked('view_roles')}/></td> {/* View */} 
                <td><WizrCheckbox value={'createroles_settings'} onChange={(value:any)=>{addRoles(value)}} isEnabled={getChecked('createroles_settings')}/></td>     {/* Create/Upload */}
                <td><WizrCheckbox value={'editroles_settings'} onChange={(value:any)=>{addRoles(value,'view_roles')}} isEnabled={getChecked('editroles_settings')}/></td>     {/* Edit */}  
                <td><WizrCheckbox value={'deleteroles_settings'} onChange={(value:any)=>{addRoles(value,'view_roles')}} isEnabled={getChecked('deleteroles_settings')}/></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */} 
            </tr>

            <tr>
                <td><WizrText type="body2">Tenants</WizrText></td>
                <td><WizrCheckbox value={'view_tenant'} onChange={(value:any)=>{addRoles(value,'view_settings')}} isEnabled={getChecked('view_tenant')}/></td>  {/* View */} 
                <td><WizrCheckbox value={'createtenants_settings'} onChange={(value:any)=>{addRoles(value,'view_tenant')}} isEnabled={getChecked('createtenants_settings')}/></td>     {/* Create/Upload */} 
                <td><WizrCheckbox value={'edittenants_settings'} onChange={(value:any)=>{addRoles(value,'view_tenant')}} isEnabled={getChecked('edittenants_settings')}/></td>     {/* Edit */}      
                <td><WizrCheckbox value={'deletetenants_settings'} onChange={(value:any)=>{addRoles(value,'view_tenant')}} isEnabled={getChecked('deletetenants_settings')}/></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td><WizrCheckbox value={'enabledisabletenants_settings'} onChange={(value:any)=>{addRoles(value,'view_tenant')}} isEnabled={getChecked('enabledisabletenants_settings')}/></td>     {/* Enable/Disable */}   
            </tr>

            <tr>
                <td><WizrText type="body2">Tenant Details</WizrText></td>
                <td><WizrCheckbox value={'view_tenantdetails'} onChange={(value:any)=>{addRoles(value,'view_tenantdetails')}} isEnabled={getChecked('view_tenantdetails')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}   
            </tr>

            <tr>
                <td><WizrText type="body2">Tenant Details</WizrText></td>
                <td><WizrCheckbox value={'view_tenantdetails'} onChange={(value:any)=>{addRoles(value,'view_tenantdetails')}} isEnabled={getChecked('view_tenantdetails')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}   
            </tr>

            <tr>
                <td><WizrText type="body2">Users</WizrText></td>
                <td><WizrCheckbox value={'view_users'} onChange={(value:any)=>{addRoles(value,'view_settings')}} isEnabled={getChecked('view_users')}/></td>  {/* View */} 
                <td><WizrCheckbox value={'createusers_settings'} onChange={(value:any)=>{addRoles(value,'view_users')}} isEnabled={getChecked('createusers_settings')}/></td>     {/* Create/Upload */} 
                <td><WizrCheckbox value={'editusers_settings'} onChange={(value:any)=>{addRoles(value,'view_users')}} isEnabled={getChecked('editusers_settings')}/></td>     {/* Edit */}      
                <td><WizrCheckbox value={'deleteusers_settings'} onChange={(value:any)=>{addRoles(value,'view_users')}} isEnabled={getChecked('deleteusers_settings')}/></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td><WizrCheckbox value={'enabledisableusers_settings'} onChange={(value:any)=>{addRoles(value,'view_users')}} isEnabled={getChecked('enabledisableusers_settings')}/></td>     {/* Enable/Disable */}   
            </tr>

            <tr>
                <td><WizrText type="body2">User Details</WizrText></td>
                <td><WizrCheckbox value={'view_userdetails'} onChange={(value:any)=>{addRoles(value,'view_userdetails')}} isEnabled={getChecked('view_userdetails')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}   
            </tr>

            <tr>
                <td><WizrText type="body2">User Roles</WizrText></td>
                <td><WizrCheckbox value={'view_userroles'} onChange={(value:any)=>{addRoles(value,'view_userroles')}} isEnabled={getChecked('view_userroles')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}   
            </tr>

            <tr>
                <td><WizrText type="body2">Company</WizrText></td>
                <td><WizrCheckbox value={'view_company'} onChange={(value:any)=>{addRoles(value,'view_company')}} isEnabled={getChecked('view_company')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}   
            </tr>

            <tr>
                <td><WizrText type="body2">Products</WizrText></td>
                <td><WizrCheckbox value={'view_updateproduct'} onChange={(value:any)=>{addRoles(value,'view_updateproduct')}} isEnabled={getChecked('view_updateproduct')}/></td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>


            <tr>
                <td><WizrText type="body2">Accounts</WizrText></td>
                <td><WizrCheckbox value={'view_accounts'} onChange={(value:any)=>{addRoles(value,'view_settings')}} isEnabled={getChecked('view_accounts')}/></td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">Integrations</WizrText></td>
                <td><WizrCheckbox value={'view_integrations'} onChange={(value:any)=>{addRoles(value,'view_settings')}} isEnabled={getChecked('view_integrations')}/></td>  {/* View */} 
                <td><WizrCheckbox value={'createintegrations_settings'} onChange={(value:any)=>{addRoles(value,'view_integrations')}} isEnabled={getChecked('createintegrations_settings')}/></td>     {/* Create/Upload */} 
                <td><WizrCheckbox value={'editintegrations_settings'} onChange={(value:any)=>{addRoles(value,'view_integrations')}} isEnabled={getChecked('editintegrations_settings')}/></td>     {/* Edit */}      
                <td><WizrCheckbox value={'deleteintegrations_settings'} onChange={(value:any)=>{addRoles(value,'view_integrations')}} isEnabled={getChecked('deleteintegrations_settings')}/></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}   
            </tr>

            <tr>
                <td><WizrText type="body2">Billings</WizrText></td>
                <td><WizrCheckbox value={'view_billing'} onChange={(value:any)=>{addRoles(value,'view_settings')}} isEnabled={getChecked('view_billing')}/></td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">AI and DB</WizrText></td>
                <td><WizrCheckbox value={'view_ai&db'} onChange={(value:any)=>{addRoles(value,'view_settings')}} isEnabled={getChecked('view_ai&db')}/></td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">API Providers</WizrText></td>
                <td><WizrCheckbox value={'view_apiprovider'} onChange={(value:any)=>{addRoles(value,'view_settings')}} isEnabled={getChecked('view_apiprovider')}/></td> {/* View */} 
                <td></td>     {/* Create/Upload */}
                <td></td>     {/* Edit */}  
                <td></td>     {/* Delete */}
                <td></td>     {/* Publish/Unpublish */}  
                <td></td>  {/* Run/Test */} 
                <td></td>     {/* Copy */} 
                <td></td>     {/* Enable/Disable */}  
            </tr>

{/* enduser */}
            <tr>
                <td rowSpan={7}><WizrText type="body2">End user</WizrText></td>
                <td><WizrText type="body2">end user</WizrText></td>
                <td><WizrCheckbox value={'view_euserapplisting'} onChange={(value:any)=>{addRoles(value,'view_euserapplisting')}} isEnabled={getChecked('view_euserapplisting')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">end user history</WizrText></td>
                <td><WizrCheckbox value={'view_euserhistory'} onChange={(value:any)=>{addRoles(value,'view_euserhistory')}} isEnabled={getChecked('view_euserhistory')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">end user chatbot listing</WizrText></td>
                <td><WizrCheckbox value={'view_chatbotlisting'} onChange={(value:any)=>{addRoles(value,'view_chatbotlisting')}} isEnabled={getChecked('view_chatbotlisting')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">end user chatbot</WizrText></td>
                <td><WizrCheckbox value={'view_chatbot'} onChange={(value:any)=>{addRoles(value,'view_chatbot')}} isEnabled={getChecked('view_chatbot')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            <tr>
                <td><WizrText type="body2">end user apps</WizrText></td>
                <td><WizrCheckbox value={'view_enduserapp'} onChange={(value:any)=>{addRoles(value,'view_enduserapp')}} isEnabled={getChecked('view_enduserapp')}/></td>  {/* View */} 
                <td></td>     {/* Create/Upload */} 
                <td></td>     {/* Edit */}      
                <td></td>     {/* Delete */} 
                <td></td>     {/* Publish/Unpublish */}     
                <td></td>     {/* Run/Test */}   
                <td></td>     {/* Copy */}  
                <td></td>     {/* Enable/Disable */}  
            </tr>

            


        </StyledTable>
    </div>
    )
}
export default WizrAccesTable;