import styled from "styled-components"
import WizrFlexLayout from "../../../components/WizrFlexLayout"
import WizrTable from "../../../components/WizrTable"
import { useEffect, useState } from "react"
import { dispatchListTenants, dispatchListUsers } from "../../../redux/actions/content"
import { useContentLoadingSelector, useGetViewUserSelector, useListTenantSelector, useUserListSelector } from "../../../redux/selectors/content"
import { Spin } from "antd"
import { MODAL_TYPES, useWizrModalContext } from "../../../providers/modal/WizrModalProvider"
import jwtDecode from "jwt-decode"
import WizrDropDown from "../../../components/WizrDropDown"
import WizrText from "../../../components/WizrText"
import { getTenantId } from "../../../utils"


const StyledWizrTable = styled(WizrTable)`
  .ant-table-wrapper {
    width: 100% !important;
  }
  .ant-table-thead {
    background-color: #f2f4f9;
    border-spacing: 0px;
  }
  .ant-table-tbody > tr {
    background-color: #ffffff;
    margin-bottom: 0px;
  }

  .ant-table table {
    border-spacing: 0 5px;
  }
`
const StyledDropDown = styled(WizrDropDown)`
.ant-select-selector{
    
    width:300px !important;
   
}
 `


const UserListing = ({ setCurrentTenant }: any): JSX.Element => {
  const { showModal, hideModal } = useWizrModalContext()
  const [tenantForListing, setTenantForListing] = useState("")
  const [tenant, setTenant] = useState()
  const [userRole, setUserRole] = useState("")

  const userList = useUserListSelector()

  const loading = useContentLoadingSelector()
  const token = localStorage.getItem("id_token")
  const tenants = useListTenantSelector()
  const userView = useGetViewUserSelector()


  useEffect(() => {
    dispatchListTenants()
    if (token) {
      const tokenDecoded: { extension_UserRole: any, extension_TenantId: any } = jwtDecode(token)
      const userRole = tokenDecoded?.extension_UserRole
      setUserRole(userRole)
      const tenantId = getTenantId()
      if (userRole !== "superadmin") {
        // showModal(MODAL_TYPES.SELECT_TENANT, {
        //   onselectTenant: (tenant:any) => {
        //     dispatchListUsers(`tenant&tenant_id=${tenant}`)
        //    setCurrentTenant(tenant)
        //     hideModal()
        //   },
        //   onCancel: hideModal,
        // })
        dispatchListUsers(`tenant&tenant_id=${tenantId}`)
        setTenantForListing(tenantId)

      }
      // else{
      // }}
    }
  }, [])
  useEffect(() => {
    if (userView?.tenantId) {
      dispatchListUsers(`tenant&tenant_id=${userView?.tenantId}`)
      setTenantForListing(userView?.tenantId)
      const tenantName = tenants.map((item: any) => {
        if (userView?.tenantId === item?.tenant_id) {
          return item?.tenant_name;
        }
        return undefined;
      }).filter((name: any) => name !== undefined);
      setCurrentTenant({ value: userView?.tenantId, label: tenantName })
      setTenant(userView?.tenantId)
    }
  }, [userView])

  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item?.tenant_name,
        value: item?.tenant_id,
      }))
    return result
  }


  const updatedUserList = userList && userList?.map((item: any) => {
    const dataUpdated = {
      ...item,
      typeTenant: tenantForListing,
      createdOn: new Date(item?.createdDateTime)
    };
    return dataUpdated;
  });
  const onTenantSelection = (value: any, label: any) => {

    setTenant(value)
    dispatchListUsers(`tenant&tenant_id=${value}`)
    setCurrentTenant(label)
    setTenantForListing(value)

  }

  return (
    <WizrFlexLayout style={{ borderRadius: "8px" }} >
      {loading ? <Spin /> : <WizrFlexLayout  >
        {userRole === "superadmin" ?
          <WizrFlexLayout flex={1} flexDirection="row">
            <WizrFlexLayout flex={10}>
              <></>
            </WizrFlexLayout>
            <WizrFlexLayout flex={2}>
              <WizrDropDown options={dropDownConverter(tenants)} value={tenant} placeHolder='Select Tenant' onChange={(value: any, label: any) => onTenantSelection(value, label)} /></WizrFlexLayout></WizrFlexLayout> : <></>}
        <WizrFlexLayout flex={11} >
          {tenant || userRole !== "superadmin" ?
            <StyledWizrTable
              type="users"
              dataSource={updatedUserList.sort((a: any, b: any) => b.createdOn - a.createdOn)}
              scroll={{ y: 500 }}
              pagination={false}
            /> : <WizrFlexLayout>
              <WizrText type="body1">Select a tenant to continue</WizrText>
            </WizrFlexLayout>
          }
        </WizrFlexLayout>
      </WizrFlexLayout>}
    </WizrFlexLayout>
  )
}

export default UserListing