import { Popover } from 'antd'
import WizrButton from '../WizrButton'
import MoreIcon from '../../assets/icons/More'
import EditIcon from '../../assets/icons/EditIcon'
import { dispatchSaveCurrentAppDetails, dispatchDeleteApp, dispatchEnableDisableUser, dispatchListUsers, dispatchDeleteUser } from '../../redux/actions/content'
import Copy from '../../assets/icons/Copy'
import { useNavigate } from 'react-router-dom'
import WizrFlexLayout from '../../stories/WizrFlexLayout/WizrFlexLayout'
import DeleteIcon from '../../assets/icons/DeleteIcon'
import { act } from 'react-dom/test-utils'
import { useCurrentAppDetailsSelector } from '../../redux/selectors/content'
import View from '../../assets/icons/View'
import Disable from '../../assets/icons/Disable'
import Reset from '../../assets/icons/Reset'
import { MODAL_TYPES, useWizrModalContext } from '../../providers/modal/WizrModalProvider'
import styled from 'styled-components'
import { useAuth } from '../../authContext'



const UserActionPopoverContent = (user:any): JSX.Element => {
  const navigate = useNavigate()
  const userDetails = user?.user
  const { showModal, hideModal } = useWizrModalContext()
  const { userPermission, hasAcces } = useAuth();

  const changeUserStatus=async()=>{
    
const payload={
  user_id:userDetails?.id,
  activation_status:userDetails?.accountEnabled?"disable":"enable"
}
await dispatchEnableDisableUser(payload)
dispatchListUsers(`tenant&tenant_id=${userDetails?.typeTenant}`)
  }

  const editUserDetails=()=>{
    navigate('/userdetails', { state: { action: userDetails ,tenant:{value:userDetails?.typeTenant,label:""} } })
  }
  const onDeleteAction = async()=>{
    showModal(MODAL_TYPES.CONFIRMATION, {
      text:`Are you sure you want to delete ${userDetails?.displayName}`,
      buttontext:"Confirm",
  onConfirm:onDeleteUser,
  onCancel:hideModal
  
    }) 
  }
const onDeleteUser = async ()=>{
  hideModal()
  await dispatchDeleteUser(userDetails?.id)  
  dispatchListUsers(`tenant&tenant_id=${userDetails?.typeTenant}`)
}
const resetPassword = ()=>{
  localStorage.setItem("reset","true")
const urlForPasswordReset=`${process.env.REACT_APP_WIZR_B2C_AUTH_ENDPOINT}?p=${process.env.REACT_APP_WIZR_RESET_P}&client_id=${process.env.REACT_APP_WIZR_B2C_CLIENT_ID}&nonce=defaultNonce&redirect_uri=${process.env.REACT_APP_WIZR_B2C_REDIRECT_URI}&scope=openid&response_type=id_token&prompt=login`
window.location.href=urlForPasswordReset
}
  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      
    <WizrButton
        icon={<EditIcon />}
        text='Edit'
        type='text'
        onClick={()=>{editUserDetails()}}
        justifyContent='flex-start'
        disabled={hasAcces('editusers_settings')  === true ? false : true}
      />
      {/* <WizrButton
        icon={<Reset />}
        text='Reset Password'
        type='text'
        onClick={()=>{resetPassword()}}
        justifyContent='flex-start'
      /> */}
      <WizrButton
        icon={<Disable />}
        text={userDetails?.accountEnabled?'Disable':'Enable'}
        type='text'
        onClick={()=>{changeUserStatus()}}
        justifyContent='flex-start'
        disabled={hasAcces('enabledisableusers_settings')  === true ? false : true}

      />
      <WizrButton
        icon={<DeleteIcon />}
        text='Delete'
        type='text'
        onClick={()=>{onDeleteAction()}}
        justifyContent='flex-start'
        disabled={hasAcces('deleteusers_settings')  === true ? false : true}

      />
    </WizrFlexLayout>
  )
}

const UserActionPopOver = (userDetails:any): JSX.Element => {
  const navigate = useNavigate()
 // detail.type = 'prompt'
const user = userDetails?.userDetails


  

  return (
    <Popover
      content={
        <UserActionPopoverContent
        user={user}
        />
      }
      trigger='click'
    >
      <StyledButton icon={<MoreIcon />} text='' type='text' style={{height: '40px', width: '40px', borderRadius: '50px'}}/>
    </Popover>
  )
}

export default UserActionPopOver

const StyledButton = styled(WizrButton)`
border-radius: 50%;
width: 20px;
height: 20px;
padding-right: 8px;
justify-content: center;
align-items: center;
  /* Default styles */
  path {
    stroke: #999B9F; /* Default stroke color */
    transition: stroke 0.3s ease; /* Smooth transition for color change */
  }

  /* Hover styles */
  &:hover path {
    stroke: #1D1F22
  }
`;