import { Popover } from 'antd'
import WizrButton from '../WizrButton'
import MoreIcon from '../../assets/icons/More'
import EditIcon from '../../assets/icons/EditIcon'
import { dispatchSaveCurrentAppDetails, dispatchDeleteApp, dispatchEnableDisableUser, dispatchListUsers, dispatchDeleteUser, dispatchUpdateProduct, dispatchDeletePackages, dispatchPackageList } from '../../redux/actions/content'
import Copy from '../../assets/icons/Copy'
import { useNavigate } from 'react-router-dom'
import WizrFlexLayout from '../../stories/WizrFlexLayout/WizrFlexLayout'
import DeleteIcon from '../../assets/icons/DeleteIcon'
import { act } from 'react-dom/test-utils'
import { useCurrentAppDetailsSelector } from '../../redux/selectors/content'
import View from '../../assets/icons/View'
import Disable from '../../assets/icons/Disable'
import Reset from '../../assets/icons/Reset'
import { MODAL_TYPES, useWizrModalContext } from '../../providers/modal/WizrModalProvider'
import styled from 'styled-components'
import { useAuth } from '../../authContext'



const PackageActionPopOverContent = (packageDetails:any): JSX.Element => {
  
  const { showModal, hideModal } = useWizrModalContext()

  const navigate = useNavigate()
  const onEditAction = ()=>{
    showModal(MODAL_TYPES.UPDATE_PACKAGES,{
        action:"edit",
        details:packageDetails?.package?.packageDetails,
      onCancel: hideModal,
    })
  }
  const deletePackage=async()=>{
    hideModal()
    await dispatchDeletePackages(packageDetails?.package?.packageDetails?.id)
    dispatchPackageList()
    
  }

  const onDeleteAction = ()=>{
    showModal(MODAL_TYPES.CONFIRMATION, {
      text:`Are you sure you want to delete ${packageDetails?.package?.packageDetails?.package_name}`,
      buttontext:"Confirm",
  onConfirm:deletePackage,
  onCancel:hideModal

    })    
  }
  const { userPermission, hasAcces } = useAuth();

  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      
    <WizrButton
        icon={<EditIcon />}
        text='Edit'
        type='text'
        onClick={()=>{onEditAction()}}
        justifyContent='flex-start'
        disabled={hasAcces('editpackages_settings')  === true ? false : true}
      />
      <WizrButton
        icon={<DeleteIcon />}
        text='Delete'
        type='text'
        onClick={()=>{onDeleteAction()}}
        justifyContent='flex-start'
        disabled={hasAcces('deletepackages_settings')  === true ? false : true}

      />
    </WizrFlexLayout>
  )
}

const PackageActionPopOver = (packageDetails:any): JSX.Element => {
  const navigate = useNavigate()
const product = packageDetails?.productDetails
 

  return (
    <Popover
      content={
        <PackageActionPopOverContent
        package={packageDetails}
        />
      }
      trigger='click'
    >
      <StyledButton icon={<MoreIcon />} text='' type='text' style={{height: '40px', width: '40px', borderRadius: '50px'}}/>
    </Popover>
  )
}

export default PackageActionPopOver

const StyledButton = styled(WizrButton)`
border-radius: 50%;
width: 20px;
height: 20px;
padding-right: 8px;
justify-content: center;
align-items: center;
  /* Default styles */
  path {
    stroke: #999B9F; /* Default stroke color */
    transition: stroke 0.3s ease; /* Smooth transition for color change */
  }

  /* Hover styles */
  &:hover path {
    stroke: #1D1F22
  }
`;
