import { useEffect, useState } from "react";

import EditIcon from "../../../assets/icons/EditIcon";
import Share from "../../../assets/icons/Share";
import RefreshIcon from "../../../assets/icons/refresh";
import WizrButton from "../../../components/WizrButton";
import WizrFlexLayout from "../../../components/WizrFlexLayout"
import WizrText from "../../../components/WizrText"
import WizrUserJourney from "../../../components/WizrUserJourney"
import { Button, Input, Popover, Radio, RadioChangeEvent, Space, Spin, Tabs, Typography, message as antdMessage } from 'antd';
import WizrTable from "../../../components/WizrTable";
import WizrUploadContainer from "../../../components/WizrUploadButton";
import { WizrAzurOrchestratorBulkRun, WizrAzurOrchestratorInstance } from "../../../utils/wizrAzureConnector";
import { async } from "q";
import { appShareLinkGeneration, executeBulkrun, getBulkTestStatus } from "../../../api/helpers/contents";
import { useLocation, useNavigate } from "react-router-dom";
import { getFileType, getTenantId } from "../../../utils";
import jwt_decode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid'
import { useAppShareLinkGeneration, useAppStatusSelector, useBulkRunResultDynamic, useBulkRunstatus, useChatMessageSelector, useContentLoadingSelector, useUpdatedUrlforCsvUpload } from "../../../redux/selectors/content";
import {  dispatchAppToolTest, dispatchClearChat, dispatchGetRunStatus, dispatchAppShareLinkGeneration, dispatchbulkrunStatusApp, dispatchgenerateFileUpload, dispatchTerminateBulkrun, dispatchUploadFileUsingLink, dispatchExecuteBulkRun } from "../../../redux/actions/content";
import styled from "styled-components";
import HistoryIcon from "../../../assets/icons/History";
import DeleteIcon from '../../../assets/icons/DeleteIcon'
import Brain from "../../../assets/icons/Brain";
import DownloadAlt from "../../../assets/icons/DownloadAlt";
import WizrInput from "../../../components/WizrInput";
import WizrTextArea from "../../../components/WizrTextArea";
import WizrTagInput from "../../../components/WizrTagInput";
import WizrDropDown from "../../../components/WizrDropDown";
import Chat from "../../../components/WizrChat";
import WizrToggleButton from "../../../components/WizrToggleButton";
import Copy from "../../../assets/icons/Copy";
import copy from 'copy-to-clipboard';

//import SingleRunContainerLeft from "./components/BulkRunPages.tsx/SingleRunContainerLeft";
//import {  } from 'antd';
import Papa from 'papaparse';
import { UploadOutlined } from "@ant-design/icons";
import Bars from "../../../assets/icons/Bars";
import APIIntegration from "../../ApiIntegration";

const { Text } = Typography;
const StyledWizrButton = styled(WizrButton)`
border-radius: 20px;
border: 1px solid var(--grayscale-800, #1D1F22);
background: var(--generic-white, #FFF);
margin-right:10px
`


const StyledHeaderWizrButton = styled(WizrButton)`
border-radius: 20px;
background: var(--grayscale-200, #EAECF1);`

const StyledWizrDropDown = styled(WizrDropDown)`
  width: 100%;
`

const BulkRunContainerLeft = ({ manageFileUpload, manageFileDownload, parameters, uploadedfile, loading, setUploadedfile, setIsTested, isProcessing, execution_status_id, app_id, executeBulkrun, }: any): JSX.Element => {
  const fileuploadstatus = useContentLoadingSelector()
  const UpdatedUrlforCsvUpload = useUpdatedUrlforCsvUpload()
  const resetState = () => {
    setUploadedfile("No choosen file")
    setIsTested(false)
  }
  const cancelBulkRun = () => {

    const payload = {
      execution_status_id: execution_status_id,
      app_id: app_id
    }
    dispatchTerminateBulkrun(payload)
  }

  const uploadFile = (fileInfo: any) => {

    const timestamp = new Date().getTime()
    const payload = {
      container_id: 1,
      app_id: `${app_id}`,
      timestamp: timestamp,
      fileInfo: fileInfo
    }
    dispatchgenerateFileUpload(payload,{})
  }
  const fileparams = parameters?.filter((item: any) => (item.type === 3 || item.type === 6 || item.type === 7) && item.name !== "user_query")
  
  return (
    <WizrFlexLayout background="white" style={{ borderRadius: "16px", padding: "15px" }} alignItems="flex-start" >
      <WizrFlexLayout flex={.7} background="white" alignItems="flex-start">
        <WizrText type="body2" textColor="#707276">Upload a CSV with input in the following structure</WizrText></WizrFlexLayout>
      <WizrFlexLayout flex={2.1} background="white" style={{ overflowX: 'auto' }}>
        {/* <WizrTable type="bulkrun"></WizrTable> */}
        <table style={{ border: "1px solid var(--generic-stroke, #E5E7EB)", overflowX: 'auto' }} >
          <thead>
            <tr >
              {parameters && parameters.map((parameter: any, index: any) => (
                <th key={index} style={{ backgroundColor: '#F2F4F9', color: 'black', minWidth: '200px' }}><WizrText type="sub2">{parameter.name}</WizrText></th>
              ))}
            </tr>
            <tr>
              {parameters && parameters.map((item: any, index: any) => (
                <td key={index} style={{ backgroundColor: 'white', color: 'black', minWidth: '200px' }}>
                  {/* <WizrText type="body2">Example of {item.name}</WizrText> */}
                  <WizrFlexLayout flexDirection="row" background="#fff">
                    {(item.type === 3 || item.type === 6 || item.type === 7) ? <>
                      <WizrText type="body2"  >Example of {item.name}</WizrText>
                      {/* <WizrUploadContainer
                        directory={true}
                        onChange={(e: any) => {
                          uploadFile(e)
                          // setInputValue({ ...inputValue, [input.name]: e?.file?.name })
                        }}
                      >
                        <Button shape='round' type='text' icon={<HistoryIcon />}>
                        </Button>
                      </WizrUploadContainer> */}
                    </> : <WizrText type="body2">Example of {item.name}</WizrText>}
                  </WizrFlexLayout>
                  {/* <WizrUploadContainer
                            onChange={(e: any) => {
                              uploadFile(input.name, e)
                              setInputValue({ ...inputValue, [input.name]: e?.file?.name })
                            }}
                          >
                            <Button shape='round' >
                               Upload
                            </Button>
                          </WizrUploadContainer> */}
                </td>
              ))}
            </tr>
          </thead>
          {/* You can add the table body based on your data */}
        </table>
      </WizrFlexLayout>
      <WizrFlexLayout flex={1} background="white">
        <WizrFlexLayout background="white" alignItems="flex-start">
          <WizrText type="body2" textColor="#707276">Download the csv template {""}
            <Text underline style={{ color: '#707276', cursor: 'pointer' }} onClick={manageFileDownload}>here</Text> </WizrText>
        </WizrFlexLayout>
      </WizrFlexLayout >
      <WizrFlexLayout flexDirection="row" flex={1} justifyContent="flex-start" background="white" >
        <WizrUploadContainer onChange={manageFileUpload}>
          <WizrButton
            disabled={isProcessing}
            text={<WizrText type="button">{fileuploadstatus ? "Uploading....." : "Upload File"}</WizrText>} style={{ borderRadius: "60px", marginRight: "40px" }}>
          </WizrButton>
        </WizrUploadContainer>
        <div style={{ paddingTop: "3px" }}><WizrText type="caption" >{uploadedfile}</WizrText></div>
      </WizrFlexLayout>
      <WizrFlexLayout flex={3} flexDirection="column" justifyContent="flex-start" background="white" >
        {fileparams && fileparams?.map((input: any, key: number) => {
          return(
            <WizrFlexLayout key={key} background="white" flexDirection="row" justifyContent="flex-start"><WizrUploadContainer directory={true} onChange={uploadFile}>
            <WizrButton
            icon={<UploadOutlined />}
           type="link"
              disabled={isProcessing}
              text={<WizrText type="link">{fileuploadstatus ? "Uploading....." : `upload files for ${input.name}`}</WizrText>} style={{ borderRadius: "60px", marginRight: "40px" }}>
            </WizrButton>
          </WizrUploadContainer></WizrFlexLayout>
          )
        })}
      </WizrFlexLayout>
      <WizrFlexLayout flex={1} flexDirection="row" background="white" justifyContent="flex-end">
        {!isProcessing ? <>
          <StyledWizrButton
            type="link" text={<WizrText type="button" textColor="#1D1F22">Reset</WizrText>} onClick={() => resetState()}></StyledWizrButton>
          <WizrButton style={{ borderRadius: "20px" }}
            text={<WizrText type="button">Run batch</WizrText>} onClick={() => { executeBulkrun() }}></WizrButton></> :
          <WizrButton style={{ borderRadius: "20px" }}
            onClick={() => { cancelBulkRun() }}
            text={<WizrText type="button">Cancel Run</WizrText>}></WizrButton>}
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

const BulkRunContainerRight = ({ isTested, manageOutputDownload, isProcessing, uploadedfile, previousRunStatus }: any): JSX.Element => {
  return (
    <WizrFlexLayout background="white" style={{ borderRadius: "16px", padding: "16px" }}>
      <WizrFlexLayout background="#F2F4F9" style={{ borderRadius: "16px" }}>
        {isProcessing ?

          <WizrFlexLayout alignContent="center" justifyContent="center">
            <WizrFlexLayout flex={4}>
              <></>

            </WizrFlexLayout>
            <WizrFlexLayout flexDirection="column" alignItems="center" flex={1.5} style={{ gap: "10PX" }}>
              <Spin />
              <WizrText type="body2">We are processing the file {uploadedfile}. <br></br>This might take a while </WizrText>

            </WizrFlexLayout>
            <WizrFlexLayout flex={4}>
<></>
            </WizrFlexLayout>
          </WizrFlexLayout> : !isTested ?
            <WizrFlexLayout>
              <WizrFlexLayout flex={4}>
<></>
              </WizrFlexLayout>
              <WizrFlexLayout flex={1} flexDirection="column" alignItems="center">
                <Brain />
                <WizrText type="body2">Results will show here</WizrText>
              </WizrFlexLayout>
              <WizrFlexLayout flexDirection="column" alignItems="center" flex={1}>
<></>
              </WizrFlexLayout>
              <WizrFlexLayout flex={4}>
<></>
              </WizrFlexLayout>
            </WizrFlexLayout> :
            <WizrFlexLayout>
              <WizrFlexLayout flex={4}>
                <></>
              </WizrFlexLayout>
              <WizrFlexLayout flexDirection="column" alignItems="center" flex={1}>
                <DownloadAlt />
                <WizrText type="body2" textColor="#707276">Output is ready for {uploadedfile}.<br></br> You can download it from {""}
                  <Text underline style={{ color: '#707276', cursor: 'pointer' }} onClick={manageOutputDownload}>here</Text> </WizrText>
              </WizrFlexLayout>
              <WizrFlexLayout flex={4}>
                <></>

              </WizrFlexLayout>
            </WizrFlexLayout>}
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}



const BulkRunTest = ({appDetails}:any): JSX.Element => {

  const navigate = useNavigate()

  const UpdatedUrlforCsvUpload = useUpdatedUrlforCsvUpload()

  const location = useLocation()
  const previousRunStatusAll= useBulkRunstatus()
  const previousRunStatus= previousRunStatusAll[0]?previousRunStatusAll[0]:[]
  const Appstatus = useAppStatusSelector()
  const bulkrunstatusdynamic = useBulkRunResultDynamic()

  const [key, setKey] = useState('single')
  const [selectedTab, setSelectedTab] = useState('single');
  const [uploadedfile, setUploadedfile] = useState("No choosen file")
  const TabPane = Tabs.TabPane
  const [isTested, setIsTested] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  // const [fileuploadStatus,setFileUploadStatus]= useState(false)

  const [fileParams, setFileParams] = useState<any>([])
  const [filesData, setFilesData] = useState<any>([])
  const [currentExecutionStatusId, setCurrentExecutionStatusId] = useState<any>('')
  const [selectedOperation, setSelectedOperation] = useState('1');
  useEffect(() => {

    if (previousRunStatus) {
      if (previousRunStatus?.execution_status === "In_Progress") {
        setIsProcessing(true)
        setUploadedfile(previousRunStatus?.file_name)
        setCurrentExecutionStatusId(previousRunStatus?.execution_status_id)

      }
      else if (previousRunStatus?.execution_status === "Completed" || previousRunStatus?.execution_status === "Completed_With_Errors") {
        //setUploadedfile(previousRunStatus?.file_name)

        setIsProcessing(false)
        setIsTested(true)
      }
      else {

        setIsProcessing(false)
        setIsTested(false)
      }
    }
  }, [previousRunStatus])
  useEffect(() => {
    if ((bulkrunstatusdynamic?.execution_status === "Completed" || bulkrunstatusdynamic?.execution_status === "Terminated") && bulkrunstatusdynamic?.appId === appDetails?.app_id) {
      const payload = {
        app_id: appDetails?.app_id
      }
      dispatchbulkrunStatusApp(payload)
      //setUploadedfile(previousRunStatus?.file_name)

    }
  }, [bulkrunstatusdynamic])

  

  const parameterList = appDetails?.nodes[0]?.data?.config?.parameters
  const parameters = (appDetails?.nodes[0]?.data?.config?.parameters || []).map((item: any) => item?.name);

  const parameterObject = parameters.reduce((result: any, parameter: any) => {
    result[`{{${parameter}}}`] = "";
    return result;
  }, {});

  //setInputState(parameterObject)
  const [inputState, setInputState] = useState<any>(parameterObject)
  const chatMessage = useChatMessageSelector()

  const [historyChats, setHistoryChats] = useState<{ user: string; assistant: string }[]>([])

  useEffect(() => {
    const user = chatMessage?.filter((item: any) => item.isSent).map((item: any) => item.text)
    const assistant = chatMessage?.filter((item: any) => !item.isSent).map((item: any) => item.text)
    if (user?.length === assistant?.length) {
      const result = user.map((value:any, index:any) =>
        Object.assign({}, { user: value, assistant: assistant?.[index] }),
      )
      setHistoryChats(result)
    }
  }, [chatMessage])

  useEffect(() => {

    const payload = {
      app_id: appDetails?.app_id
    }
    dispatchbulkrunStatusApp(payload)
    dispatchClearChat()
  }, [])

  const items = [
    { key: "1", text: "Use App" },
    { key: "2", text: "API" },
    // { key: "3", text: "SDK" }
  ]

  const onTabChange = (e: RadioChangeEvent) => {
    setSelectedTab(e.target.value);
    setKey(e.target.value)
  }

  const getButtonStyle = (value: any) => {
    if (value === selectedTab) {
      return {
        backgroundColor: '#1D1F22',
        borderColor: '#1D1F22',
        color: '#fff',
      };
    }
    return {};
  };

  const [modifiedData, setModifiedData] = useState(null);

  const manageFileUpload = async (fileInfo: any) => {

    const reader = new FileReader();

    // Event listener on reader when the file loads, we parse it and set the data.
    reader.onload = (event: any) => {

      const content = event.target.result;

      // Parse the CSV content
      Papa.parse(content, {
        header: true,
        complete: (result:any) => {
          // Modify the data (example: add 1 to the 'quantity' column)
          const updatedData: any = result.data.map((row: any) => {
            const updatedRow: any = {};
            for (const key in row) {
              if (row[key]) {
                const matchingItem = UpdatedUrlforCsvUpload.find((item: any) => item.includes(row[key]));
                updatedRow[key] = matchingItem ? matchingItem : row[key];
              } else {
                updatedRow[key] = row[key];
              }
            }
            return updatedRow;
          });

          // Set the modified data in the state
          setModifiedData(updatedData);
          const updatedCsv = Papa.unparse(updatedData);
          const updatedBlob = new Blob([updatedCsv], { type: 'text/csv' });


          const execution_status_id = uuidv4()
          setCurrentExecutionStatusId(execution_status_id)
          const fileUploadPayload = {

            container_id: 2,
            path: appDetails?.app_id,
            files: [
              `${fileInfo?.file?.name}`,
            ],
            metadata: {
              id: appDetails?.id,
              version_id: appDetails?.version_id,
              execution_status_id: execution_status_id,
              exec_type : 'bulk-test'
            }
          }

          const formData = new FormData();
          const jsonData = JSON.stringify(fileUploadPayload);
          formData.append('data', jsonData);
          formData.append(fileInfo?.file?.name, new File([updatedBlob], fileInfo?.file?.name, { type: 'text/csv' }));
          dispatchUploadFileUsingLink(formData)
          setUploadedfile(fileInfo?.file?.name)


        },
      });
    };

    // Use `reader.onload` instead of `event.target.onload`
    reader.readAsText(fileInfo.fileList[0]?.originFileObj);





    setIsTested(false)





  }



  const manageFileDownload = async () => {
    await WizrAzurOrchestratorBulkRun.downloadFileTemplate(appDetails?.app_id, appDetails?.app_name)
  }

  const manageOutputDownload = async () => {
    const file_name = previousRunStatus?.output_file_name
    await WizrAzurOrchestratorBulkRun.downloadFileOutput(appDetails?.app_id, file_name)
  }

  const onChatClick = () => {
    const testAppPayload = {
      id: appDetails?.id,
      app_id: appDetails?.app_id,
      history: appDetails?.memory ? historyChats : [],
      exec_type: "app",
      max_past_messages: appDetails?.memory ? 15 : 0,
      message_params: inputState,
      file_params: fileParams ? fileParams : [],
    }

    const formData = new FormData();
    const jsonData = JSON.stringify(testAppPayload);
    formData.append('data', jsonData);
    filesData?.map((item: any) => {
      formData.append(item.param_name, item.file);
    })
    dispatchAppToolTest(testAppPayload)
  }

  const doUpdateFields = (fieldName: string, fieldValue: any) =>
    setInputState({
      ...inputState,
      [`{{${fieldName}}}`]: fieldValue,
    })

  const mapValueToUserQuery = (fieldValue: any) =>
    setInputState({
      ...inputState,
      '{{user_query}}': fieldValue,
    })

  const apprunHistory = () => {


    navigate("/runhistory", { state: appDetails })
  }

  const goback = () => {
    navigate(-1)
    dispatchClearChat()
  }



  const appShareLink = () => {

    const tenantId: string = getTenantId();


    const payload = {
      id: appDetails?.id,
      app_id: appDetails?.app_id,
      //tenant_id: tenantId,
      exec_type: "SINGLERUN",
      prefix: process.env.REACT_APP_WIZR_B2C_REDIRECT_URI + "/appshare"
    }
    dispatchAppShareLinkGeneration(payload)

  }
  const executeBulkrun = async () => {
    const jwtDecodeData: { [key: string]: any } = jwt_decode(localStorage.id_token)
    // const execution_status_id = uuidv4()
    // setCurrentExecutionStatusId(execution_status_id)
    const tenantId: string = getTenantId()

    const payload: any =
    {
      container_id: 2,
      source: "inbox",
      destination: "inprocess",
      blob_name: `${appDetails?.app_id}/${uploadedfile}`,
      process_name: "bulk-execute",
      message_body: {
        execution_status_id: currentExecutionStatusId,
        file_name: uploadedfile,
        app_id: appDetails?.app_id,
        version_id: appDetails?.id,
        execution_status: "In_Progress"
      }
    }

    // {
    //   tenant_id: tenantId,
    //   id: appDetails?.id,
    //   app_id: appDetails?.app_id,
    //   version_id: appDetails?.id,
    //   user_id: jwtDecodeData?.oid,
    //   user_name: jwtDecodeData?.name,
    //   execution_status_id: execution_status_id,
    //   llm_model_name:appDetails?.nodes[1]?.data?.config?.llm_model_config?.model_name

    // } 
    dispatchExecuteBulkRun(payload)
    setIsProcessing(true)
    //getBulkTestStatus(appDetails?.app_id,execution_status_id)
    await dispatchGetRunStatus(appDetails?.app_id, currentExecutionStatusId, appDetails?.app_name);
    setIsProcessing(false)
    setIsTested(true)

  }

  const handleTabChange = (key: any) => {
    setSelectedOperation(key)
  }

  return <WizrFlexLayout flexDirection="column" style={{minHeight:"460px"}}>
            <BulkRunContainerLeft flex={1} manageFileUpload={manageFileUpload} manageFileDownload={manageFileDownload} parameters={parameterList} uploadedfile={uploadedfile} setUploadedfile={setUploadedfile} setIsTested={setIsTested} isProcessing={isProcessing} execution_status_id={currentExecutionStatusId} app_id={appDetails?.app_id} executeBulkrun={executeBulkrun} /> 
            <BulkRunContainerRight flex={1} isTested={isTested} manageOutputDownload={manageOutputDownload} isProcessing={isProcessing} uploadedfile={uploadedfile} previousRunStatus={previousRunStatus} /> 
  </WizrFlexLayout>
}
export default BulkRunTest



