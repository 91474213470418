const TeamsIcon = () => {
return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_12639_45814)">
<path d="M16.7403 8.96875H22.9395C23.5253 8.96875 24 9.47737 24 10.1047V16.153C24 18.4586 22.255 20.3278 20.1025 20.3278H20.0841C17.9316 20.3281 16.1863 18.4593 16.186 16.1536V9.56252C16.186 9.48455 16.2003 9.40732 16.2281 9.33527C16.256 9.26322 16.2968 9.19775 16.3483 9.14261C16.3998 9.08747 16.4609 9.04373 16.5281 9.0139C16.5954 8.98406 16.6675 8.96872 16.7403 8.96875Z" fill="#5059C9"/>
<path d="M20.9301 7.77124C22.3173 7.77124 23.4418 6.56675 23.4418 5.08093C23.4418 3.59512 22.3173 2.39062 20.9301 2.39062C19.543 2.39062 18.4185 3.59512 18.4185 5.08093C18.4185 6.56675 19.543 7.77124 20.9301 7.77124Z" fill="#5059C9"/>
<path d="M13.1162 7.77198C15.1199 7.77198 16.7442 6.03217 16.7442 3.88599C16.7442 1.73982 15.1199 0 13.1162 0C11.1126 0 9.48828 1.73982 9.48828 3.88599C9.48828 6.03217 11.1126 7.77198 13.1162 7.77198Z" fill="#7B83EB"/>
<path d="M17.9535 8.96875H7.72063C7.14191 8.98411 6.68413 9.49856 6.69754 10.1184V17.0169C6.61673 20.7368 9.36426 23.8237 12.837 23.9148C16.3098 23.8237 19.0574 20.7368 18.9766 17.0169V10.1184C18.99 9.49856 18.5323 8.98411 17.9535 8.96875Z" fill="#7B83EB"/>
<path opacity="0.1" d="M13.3953 8.96875V18.6358C13.394 18.8527 13.3329 19.0642 13.2197 19.244C13.1065 19.4237 12.9462 19.5637 12.7591 19.6462C12.6372 19.7015 12.5062 19.7299 12.3739 19.7299H7.18871C7.11615 19.5326 7.04921 19.3352 6.99333 19.1321C6.79795 18.4461 6.69828 17.7329 6.69755 17.0157V10.1166C6.68415 9.49775 7.14118 8.98411 7.71896 8.96885L13.3953 8.96875Z" fill="#5059C9"/>
<path opacity="0.2" d="M12.837 8.96875V19.2337C12.837 19.3754 12.8104 19.5157 12.7589 19.6462C12.6818 19.8467 12.5511 20.0184 12.3833 20.1396C12.2155 20.2609 12.018 20.3263 11.8156 20.3278H7.45102C7.35615 20.1305 7.2668 19.9331 7.18871 19.7299C7.11368 19.5345 7.04846 19.3349 6.99333 19.1321C6.79795 18.4461 6.69828 17.7329 6.69755 17.0157V10.1166C6.68415 9.49775 7.14118 8.98411 7.71896 8.96885L12.837 8.96875Z" fill="#5059C9"/>
<path opacity="0.2" d="M12.8371 8.96875V18.038C12.8328 18.6403 12.3781 19.1276 11.8156 19.1321H6.99333C6.79795 18.4461 6.69828 17.7329 6.69755 17.0157V10.1166C6.68415 9.49775 7.14118 8.98411 7.71896 8.96885L12.8371 8.96875Z" fill="#5059C9"/>
<path opacity="0.2" d="M12.279 8.96875V18.038C12.2746 18.6403 11.8199 19.1276 11.2576 19.1321H6.99333C6.79795 18.4461 6.69828 17.7329 6.69755 17.0157V10.1166C6.68415 9.49775 7.14118 8.98411 7.71896 8.96885L12.279 8.96875Z" fill="#5059C9"/>
<path opacity="0.1" d="M13.3954 5.87531V7.75845C13.3005 7.76448 13.2112 7.77051 13.1163 7.77051C13.0214 7.77051 12.9321 7.76448 12.8372 7.75845C12.6488 7.74506 12.462 7.71306 12.2791 7.66286C11.7219 7.5215 11.2039 7.24089 10.7673 6.84397C10.3308 6.44705 9.98816 5.94509 9.76746 5.37914C9.6904 5.18635 9.6306 4.98615 9.58887 4.78125H12.374C12.9371 4.78356 13.3932 5.27199 13.3954 5.87531Z" fill="#5059C9"/>
<path opacity="0.2" d="M12.8372 6.47286V7.75822C12.6489 7.74483 12.462 7.71283 12.2791 7.66262C11.722 7.52126 11.2039 7.24064 10.7674 6.84372C10.3309 6.4468 9.98827 5.94485 9.76758 5.37891H11.8158C12.3791 5.38112 12.8351 5.86965 12.8372 6.47286Z" fill="#5059C9"/>
<path opacity="0.2" d="M12.8372 6.47286V7.75822C12.6489 7.74483 12.462 7.71283 12.2791 7.66262C11.722 7.52126 11.2039 7.24064 10.7674 6.84372C10.3309 6.4468 9.98827 5.94485 9.76758 5.37891H11.8158C12.3791 5.38112 12.8351 5.86965 12.8372 6.47286Z" fill="#5059C9"/>
<path opacity="0.2" d="M12.2792 6.47296V7.66272C11.722 7.52136 11.204 7.24074 10.7674 6.8438C10.3309 6.44686 9.98827 5.94488 9.76758 5.37891H11.2578C11.8211 5.38122 12.2771 5.86975 12.2792 6.47296Z" fill="#5059C9"/>
<path d="M1.02309 5.37901H11.2559C11.821 5.37901 12.2791 5.86965 12.2791 6.47487V17.4356C12.2791 18.0409 11.821 18.5315 11.256 18.5315H1.02309C0.458063 18.5315 0 18.0409 0 17.4356V6.47497C0 5.86955 0.458063 5.37901 1.02309 5.37901Z" fill="url(#paint0_linear_12639_45814)"/>
<path d="M8.83217 9.55195H6.78655V15.5184H5.48333V9.55195H3.44727V8.39453H8.83217V9.55195Z" fill="white"/>
</g>
<defs>
<linearGradient id="paint0_linear_12639_45814" x1="213.312" y1="-80.2445" x2="1099.99" y2="1353.45" gradientUnits="userSpaceOnUse">
<stop stop-color="#5A62C3"/>
<stop offset="0.5" stop-color="#4D55BD"/>
<stop offset="1" stop-color="#3940AB"/>
</linearGradient>
<clipPath id="clip0_12639_45814">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

)
}

export default TeamsIcon;