import { dispatchListFolders, dispatchCreateFolder } from '../redux/actions/content'
import { useContentsSelector, useContentLoadingSelector } from '../redux/selectors/content'
import { useEffect } from 'react'
import WizrTable from '../components/WizrTable'
import { Spin } from 'antd'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrUserJourney from '../components/WizrUserJourney'
import WizrButton from '../components/WizrButton'
import { useWizrModalContext, MODAL_TYPES } from '../providers/modal/WizrModalProvider'
import CreateNew from '../assets/icons/CreateNew'
import { dispatchNotificationState } from '../redux/actions/common'
import WizrText from '../components/WizrText'
import { useAuth } from '../authContext';

const Content = (): JSX.Element => {
  const { hasAcces } = useAuth();
  const contentsListing = useContentsSelector()
  const isContentLoading = useContentLoadingSelector()
  const { showModal, hideModal } = useWizrModalContext()

  useEffect(() => {
    dispatchListFolders('knowledge_base')

  }, [])



  return (
    <WizrFlexLayout>
      <WizrFlexLayout justifyContent='space-between' alignItems='center' flexDirection='row'>
        <WizrUserJourney
          userJourney={[
            {
              title: <WizrButton type='text' text={<WizrText type='h6'>Folders</WizrText>} />,
              isCurrent: true,
            },
          ]}
        />
          <WizrButton
          style={{borderRadius:"18px"}}
          type='primary'
          icon={<CreateNew fill='white' />}
          text='Create New'
          disabled={hasAcces('create_folder') === true ? false : true}
          onClick={() =>
            showModal(MODAL_TYPES.CREATE_UPDATE_FOLDER, {
              onFormSubmission: ({ currentFolderName, currentFolderDescription,currentembeddingModel }: any) => {
                const isExistingName = contentsListing?.find(
                  (content: any) => currentFolderName === content?.folder_name,
                )
                if (!isExistingName) {
                  hideModal()
                  dispatchCreateFolder({ currentFolderName, currentFolderDescription ,currentembeddingModel})
                } else {
                  dispatchNotificationState({
                    message: 'Error',
                    description: `${currentFolderName} already exists. Please try different name.`,
                    type: null,
                  })
                }
              },
              onCancel: hideModal,
            })
          }
        />        
      </WizrFlexLayout>
      <WizrFlexLayout flex={10}>
        {isContentLoading ? (
          <Spin />
        ) : (
          <WizrTable type='folder' dataSource={contentsListing} pagination={false} size='small' />
        )}
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

export default Content
