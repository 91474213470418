import { useEffect, useState } from 'react'
import Chat from '../../../components/WizrChat'
import { useNavigate } from 'react-router-dom'
import WizrDropDown from '../../../components/WizrDropDown'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import WizrInput from '../../../components/WizrInput'
import WizrTagInput from '../../../components/WizrTagInput'
import WizrText from '../../../components/WizrText'
import WizrTextArea from '../../../components/WizrTextArea'
import { diaptchUpdateFileUploadStatus, dispatchAppToolTest, dispatchClearChat, dispatchUploadFileUsingLink } from '../../../redux/actions/content'
import { useChatMessageSelector, useCurrentAppDetailsSelector, useFileParams } from '../../../redux/selectors/content'
import { styled } from 'styled-components'
import axios from 'axios'
import WizrButton from '../../../components/WizrButton'
import CloseIcon from '../../../assets/icons/Close'
import WizrUploadContainer from '../../../components/WizrUploadButton'
import { Button,Input, Space } from 'antd'
import { WizrAzurOrchestratorInstance } from '../../../utils/wizrAzureConnector'
import { getFileType, uploadCheck } from '../../../utils'

interface ITestArea{
  onTestAreaClose?: any
  parameters?: any
}

const ChatSettings = styled(WizrFlexLayout)`
  border-radius: 6px;
  margin-bottom: 1px;
  gap: 5px;
  padding-top: 0px;
  min-height: 20%;
  overflow: auto;
  padding: 0px 20px;
`
const StyledWizrDropDown = styled(WizrDropDown)`
  width: 100%;
`
const StyledWizrButton= styled(WizrButton)`
margin-left:610px;
margin-bottom:7px;`

const IndividualSettingsBox = styled.div`
  width: 100%;
  text-align: left;
  margin: 10px 0px 0px 0px;
  overflow: auto;
`

const TestArea = ({onTestAreaClose, parameters}: ITestArea): JSX.Element => {

  const chatMessage = useChatMessageSelector()
  const currentAppDetails = useCurrentAppDetailsSelector()
  // const parameters = ( currentAppDetails?.nodes && currentAppDetails?.nodes[0]?.data?.config?.parameters || []).map((item: any) => item?.name);

  // const testParams = currentAppDetails?.nodes && currentAppDetails?.nodes[0]?.data?.config?.parameters.filter((item: any) => item.name !== 'user_query')
  const testParams = currentAppDetails?.nodes && currentAppDetails?.nodes[0]?.data?.config?.parameters
  const [runtype,setRuntype] = useState("single")

  const [messageParams, setMessageParams] = useState<any>()
  const [inputValue, setInputValue] = useState<any>({})
  const [fileParams, setFfileParams] = useState<any>([])
  const [filesData, setFilesData] = useState<any>([])  
  const [historyChats, setHistoryChats] = useState<{ user: string; assistant: string }[]>([])
  const navigate=useNavigate()

  const uploadedFileValues = useFileParams()

  useEffect(() => {    
    const user = chatMessage?.filter((item: any) => item.isSent).map((item: any) => item.text)
    const assistant = chatMessage?.filter((item: any) => !item.isSent).map((item: any) => item.text)
    if (user?.length === assistant?.length) {
      const result = user.map((value:any, index:any) =>
        Object.assign({}, { user: value, assistant: assistant?.[index] }),
      )
      setHistoryChats(result)
    }
  }, [chatMessage])

  useEffect(() => {    
    const parameterList = testParams?.map((item:any)=>item?.name)    
    const parameterObject = parameterList?.reduce((result:any, parameter:any) => {
      result[`{{${parameter}}}`] = "";
      return result;
    }, {});
    setMessageParams(parameterObject)
  }, [testParams])


  useEffect(()=>{
    dispatchClearChat()
  },[])

  const mapValueToUserQuery = (value: string) => {
    updateMessageParams('user_query', value)
  }

  const updateMessageParams = (name: string, value: any) => {
    const names = `{{${name}}}`
    const finalValue = value?.length > 0 ? value.toString() : value
    setMessageParams({ ...messageParams, [names]: finalValue })
  }
  
  const onChatSubmit = (user_query: string) => {     

  
    
// map testparams with uploadedFileValues and if testparam[name] === uploadedFileValues[input_file_name] then return {{testparam[name]}}: uploadedFileValues[timestamp]/uploadedFileValues[file_name]
  testParams?.map((item: any) => {
    const file = uploadedFileValues?.find((file: any) => file[0] && file[0].input_file_name === item.name && currentAppDetails?.app_id===file[0].path)
    if(file){
      // return {...fileParams, `{{${item.name}}}`: `${file[0].timestamp}/${file[0].file_name}` }
      messageParams[`{{${item.name}}}`] = `${file[0].timestamp}/${file[0].file_name}`
      
    }

  })

  const keyToAdd = "{{user_query}}";

    if (!(keyToAdd in messageParams)) {
      messageParams[keyToAdd] = "";
    }

    const testAppPayload = {
      id: currentAppDetails?.id,
      app_id: currentAppDetails?.app_id,
      history: currentAppDetails?.memory? historyChats : [],
      exec_type:"test",
      max_past_messages: currentAppDetails?.memory ? 15 : 0,
      message_params: messageParams ,
      // file_params: fileParams ? fileParams : [],
    }
        
    const formData = new FormData();
    const jsonData = JSON.stringify(testAppPayload);
    formData.append('data', jsonData);
    // filesData?.map((item: any) => {
    //   formData.append(item.param_name, item.file);
    // })    

    dispatchAppToolTest(testAppPayload) 
  }

  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item,
        value: item,
      }))
    return result
  }

  const uploadFile = (name:any, fileInfo: any, type: any) => {   
    
    if( fileInfo === ''){
      const fileToReplace = uploadedFileValues?.filter((item: any) => item[0]?.input_file_name !== name)  
      diaptchUpdateFileUploadStatus(fileToReplace)  
    }else{
      const check = uploadCheck(type, fileInfo?.file)
      if(check){
        const fileToReplace = uploadedFileValues?.filter((item: any) => item[0]?.input_file_name !== name)  
        diaptchUpdateFileUploadStatus(fileToReplace)   
        const fileUploadPayload={
          container_id: 1,
          path: currentAppDetails?.app_id,
          files: [
            `${name}`,
          ]
        }
        const formData = new FormData();
        const jsonData = JSON.stringify(fileUploadPayload);
        formData.append('data', jsonData);
        formData.append(name, fileInfo?.fileList[0]?.originFileObj);
        dispatchUploadFileUsingLink(formData)
      }
    }


  }

  const updateFileParams = (name: any, fileInfo: any) => {
    const type = getFileType(fileInfo?.file?.name)

    const fileData = {
      param_name: name,
      file_name: fileInfo?.file?.name,
      file_location: 'promptbox',
      file_type: type,
    }

    fileParams?.map((item:any, key: any) => {
      if(item.param_name === name){
        fileParams.splice(key, 1)
      } 
    })

    setFfileParams([...fileParams, fileData])

    const fileValue = {
      param_name: name,
      file: fileInfo?.fileList[0]?.originFileObj,
    }

    filesData?.map((item: any, key: any) => {
      if(item.param_name === name){
        filesData.splice(key, 1)
      } 
    })

    setFilesData([...filesData, fileValue])
    
    
  }

  const onClickTestArea = () => { 
    setInputValue({})
    onTestAreaClose()
  }

  return (
    <IndividualSettingsBox>
      <WizrFlexLayout alignItems='center' background='#fff' style={{padding: '0px 10px'}}>
      <StyledWizrButton type='link' icon={<CloseIcon/>} onClick={onClickTestArea}></StyledWizrButton>
      <Chat
        onHistoryClick={() => {
          // navigate('/app-history', {
          //   state: {app_id:currentAppDetails?.app_id,
          //   app_name:currentAppDetails?.app_name}
          // })
          window.open(`/app-history/${currentAppDetails?.app_id}`, '_blank', 'noopener,noreferrer')

        }}
        onChatClick={onChatSubmit}
        chatMessage={chatMessage}
        enableChat={true}
        mapValueToUserQuery={mapValueToUserQuery}
        runtype={runtype}
        setRuntype={setRuntype}
        appDetails = {currentAppDetails}
        apptest = {true}
        // onRunBatchClick={() => {
          
        //   navigate('/bulkrun',{state:{currentAppDetails}})
        // }}
      />
      {runtype !=="bulk" ?
      <ChatSettings background='#FFF' >
        <WizrFlexLayout background='#FFF' >
        <WizrFlexLayout
          // key={key}
          background='#FFF'
          flexDirection='column'
          justifyContent='space-around'
          flex='none'
          style={{ marginTop: '15px', width: '100%' }}
        >
                    
        { testParams && testParams?.map((input: any, key: number) => {
                 if (input.name!=="user_query"){                  
          switch (input.type){
            case 1:
              return (
              <WizrFlexLayout alignItems='start' background='#FFF' overFlow='hidden' style={{ marginTop: '15px', width: '100%' }}>
              <WizrText type='body2'>{input.name}</WizrText>
              <WizrInput
                  key={key}
                  style={{ maxHeight: '25px' }}
                  value={inputValue[input.name]}
                  onChange={(value: any) => {
                    updateMessageParams(input.name, value)
                    setInputValue({ ...inputValue, [input.name]: value })
                  }}
                />
              </WizrFlexLayout>
              )
            case 2:
              return (  
                <WizrFlexLayout alignItems='start' background='#FFF' overFlow='hidden' style={{ marginTop: '15px', width: '100%' }}>
                <WizrText type='body2'>{input.name}</WizrText>
                <WizrTextArea
                    onChange={(value: any) => {updateMessageParams(input.name, value); setInputValue({ ...inputValue, [input.name]: value })}}
                    placeholder='Enter text'
                    minRows={2}
                    maxRows={6}
                    value={inputValue[input.name]}
                  />
                </WizrFlexLayout>
              )
            case 4:
              return (
                <WizrFlexLayout alignItems='start' background='#FFF' overFlow='hidden' style={{ marginTop: '15px', width: '100%' }}>
                <WizrText type='body2'>{input?.name}</WizrText>
                <StyledWizrDropDown
                  placeHolder='Select one option'
                  options={dropDownConverter(input?.modalTags)}
                  onChange={(value: any) => {updateMessageParams(input.name, value); setInputValue({ ...inputValue, [input.name]: value })}}
                />
                </WizrFlexLayout>
              )
              case 5:
                return (
                  <WizrFlexLayout alignItems='start' background='#FFF' overFlow='hidden' style={{ marginTop: '15px', width: '100%' }}>
                  <WizrText type='body2'>{input?.name}</WizrText>
                  <WizrTagInput
                    placeHolder='Select multiple option'
                    options={dropDownConverter(input?.modalTags)}
                    onChange={(value: any) => {updateMessageParams(input.name, value), setInputValue({ ...inputValue, [input.name]: value })}}
                  ></WizrTagInput>
                  </WizrFlexLayout>
                )
              case 3:
                return (
                  <WizrFlexLayout alignItems='start' background='#FFF' overFlow='hidden' style={{ marginTop: '15px', width: '100%' }}>
                    <WizrText type='body2'>{input.name}</WizrText>
                      <Input
                        //onChange={(value: any) => updateMessageParams(item.name, value)}
                        placeholder='Upload File'
                        value={inputValue[input.name]}
                        suffix={
                          <Space>
                          {inputValue[input.name] && ( // Only display clear icon if there's a value
                            <Button shape='round' onClick={(e: any) => {
                              uploadFile(input.name, '', '')
                              setInputValue({ ...inputValue, [input.name]: null })
                            }}>
                                remove file
                            </Button>
                          )}
                            <WizrUploadContainer
                            onChange={(e: any) => {
                              uploadFile(input.name, e, input.type)
                              setInputValue({ ...inputValue, [input.name]: e?.file?.name })
                            }}
                          >
                            <Button shape='round' >
                               Upload
                            </Button>
                          </WizrUploadContainer>
                          </Space>
                        }
                      />
                  </WizrFlexLayout>
                )
                case 6:
                return (
                  <WizrFlexLayout alignItems='start' background='#FFF' overFlow='hidden' style={{ marginTop: '15px', width: '100%' }}>
                    <WizrText type='body2'>{input.name}</WizrText>
                      <Input
                        placeholder='Upload image'
                        value={inputValue[input.name]}
                        suffix={
                          <Space>
                          {inputValue[input.name] && ( 
                            <Button shape='round' onClick={(e: any) => {
                              uploadFile(input.name, '', '')
                              setInputValue({ ...inputValue, [input.name]: null })
                            }}>
                                remove file
                            </Button>
                          )}
                            <WizrUploadContainer
                            onChange={(e: any) => {
                              uploadFile(input.name, e, input.type)
                              setInputValue({ ...inputValue, [input.name]: e?.file?.name })
                            }}
                          >
                            <Button shape='round' >
                               Upload
                            </Button>
                          </WizrUploadContainer>
                          </Space>
                        }
                      />
                  </WizrFlexLayout>
                )
                case 7:
                return (
                  <WizrFlexLayout alignItems='start' background='#FFF' overFlow='hidden' style={{ marginTop: '15px', width: '100%' }}>
                    <WizrText type='body2'>{input.name}</WizrText>
                      <Input
                        placeholder='Upload media files'
                        value={inputValue[input.name]}
                        suffix={
                          <Space>
                          {inputValue[input.name] && ( 
                            <Button shape='round' onClick={(e: any) => {
                              uploadFile(input.name, '', '')
                              setInputValue({ ...inputValue, [input.name]: null })
                            }}>
                                remove file
                            </Button>
                          )}
                            <WizrUploadContainer
                            onChange={(e: any) => {
                              uploadFile(input.name, e, input.type)
                              setInputValue({ ...inputValue, [input.name]: e?.file?.name })
                            }}
                          >
                            <Button shape='round' >
                               Upload
                            </Button>
                          </WizrUploadContainer>
                          </Space>
                        }
                      />
                  </WizrFlexLayout>
                )
          }}
        })}
        </WizrFlexLayout>
        </WizrFlexLayout>
      </ChatSettings>:<></>}
    </WizrFlexLayout>
    </IndividualSettingsBox>
  )
}



export default TestArea