import {
  Button,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Upload,
  message as antdMessage,
} from 'antd'
import WizrFlexLayout from '../../../../components/WizrFlexLayout'
import RadioGroup from '../../../../components/WizrRadiogroup'
import WizrText from '../../../../components/WizrText'
import WizrTextArea from '../../../../components/WizrTextArea'
import Wizrlogo from '../../../../assets/icons/wizrlogo'
import WizrUploadContainer from '../../../../components/WizrUploadButton'
import InfoIcon from '../../../../assets/icons/Info'
import WizrButton from '../../../../components/WizrButton'
import WizrInput from '../../../../components/WizrInput'
import styled from 'styled-components'
import WizrDropDown from '../../../../components/WizrDropDown'
import { useEffect, useState } from 'react'
import MoreIcon from '../../../../assets/icons/More'
import copy from 'copy-to-clipboard'
import WizrToggleButton from '../../../../components/WizrToggleButton'
import {
  useappDataSelector,
  useBotDetailsSelector,
  useFeatureSettingSelector,
  useFileParams,
  useGenerateCodeSelector,
} from '../../../../redux/selectors/content'
import { set } from 'react-hook-form'
import {
  dispatchBotDetails,
  dispatchClearFileParams,
  dispatchGenerateCode,
  dispatchGetPackageDetails,
  dispatchUpdateBot,
  dispatchUploadFileUsingLink,
} from '../../../../redux/actions/content'
import { title } from 'process'
import { LanguageOptions } from '../../../../utils'
import WizrTagInput from '../../../../components/WizrTagInput'
import { useNavigate, useParams } from 'react-router-dom'
import { format } from 'path'
import { tab } from '@testing-library/user-event/dist/tab'
import Copy from '../../../../assets/icons/Copy'
import { dispatchNotificationState } from '../../../../redux/actions/common'

const StyledWizrInput = styled(WizrInput)`
  height: 50px;
  background-color: #f2f4f9;
  border: 0px #f2f4f9 !important;
  border-radius: 16px;
  .ant-input {
    background-color: #f2f4f9;
  }
`

const StyledDropDown = styled(WizrDropDown)`
  height: 50px;
  .ant-select-selector {
    height: 50px !important;
    border-radius: 16px !important;
    background-color: #f2f4f9 !important;
    border: 0px #f2f4f9 !important;
    align-items: center !important;
    .ant-select-selection-placeholder {
      margin-top: 6px;
    }
  }
  border-radius: 16px;
`

const StyledWizrTagInput = styled(Select)`
  /* Add your custom styles here */
  .ant-select-selection-placeholder {
    text-align: left;
  }
  width: 100%;
  .ant-select-selector {
    min-height: 50px;
    background: #f2f4f9 !important;
    border-radius: 16px !important;
    padding-left: 20px !important;
  }
  .ant-select-selection-item {
    background: white !important;
    border-radius: 20px !important;
    border: 1px solid black !important;
    gap: 8px !important;
    padding-right: 8px !important;
  }
  .ant-select-selection-overflow {
    gap: 8px !important;
  }
  overflow: auto; /* Hide any overflow content */
`

const dropDownConverter = (value: any[]) => {
  const result =
    value?.length > 0 &&
    value?.map((item: any, key: number) => ({
      label: item,
      value: item,
    }))
  return result || []
}
const dropDownConverterApp = (value: any[]) => {
  const result =
    value?.length > 0 &&
    value?.map((item: any, key: number) => ({
      label: item?.app_name,
      value: item?.app_id,
    }))
  return result || []
}

const GradientBar = styled.div`
  height: 30px;
  width: 100%;
  border-radius: 4px;
  background: linear-gradient(90deg, #70f2dc 0%, #55baf6 32.5%, #638ef6 64%, #9b6ef6 100%);
  position: relative;
  cursor: pointer;
`

const WebFormatLargeInstallationPage = () => {
  const [selectedTab, setSelectedTab] = useState('customize')
  const [installationState, setInstallationState] = useState<any>({})
  // const [questionList, setQuestionsList] = useState([])
  const [questions, setQuestions] = useState<string[]>(['', '', '', ''])
  const featureSettings = useFeatureSettingSelector()
  const [fileUploaded,setFileUploaded] = useState(false)
  // const BotDetails = useBotDetailsSelector()
  const params = useParams()
  const featureId = params?.id
  console.log('questions', questions)

  // const [currentBotDetails, setCurrentBotDetails] = useState<any>({});
  //   const [mostLikelyQuestions, setMostLikelyQuestions] = useState({status:""})
  const handleInputChange = (index: number, value: string) => {
    const newQuestions = [...questions]
    newQuestions[index] = value
    setQuestions(newQuestions)
  }

  const onTabChange = (e: RadioChangeEvent) => {
    // setViewFullTicketSetting(true)
    setSelectedTab(e.target.value)
  }

  const getButtonStyle = (value: any) => {
    if (value === selectedTab) {
      return {
        backgroundColor: '#1D1F22',
        borderColor: '#1D1F22',
        color: '#fff',
        // borderRadius: '16px'
      }
    } else {
      return {
        // borderRadius: '16px'
      }
    }
    return {}
  }
  const updateInstallationState = (name: any, value: any) => {
    setInstallationState({
      ...installationState,
      [name]: value,
    })
  }
  //   const updateMostLikelyQuestion = (name: any, value: any) => {
  //     setMostLikelyQuestions({
  //       ...mostLikelyQuestions,
  //       [name]: value,
  //     })
  //   }
  const botDetails = useBotDetailsSelector()

  const FormatBotDetails = (payload: any) => {
    console.log('payload', payload)
    const formattedData = {
      title: payload.title || '',
      source_names: payload.source_names || '',
      mostlikelyquestion_enables: payload.most_likely_questions?.status || 'disabled',
      mostlikelyquestion_list: payload.most_likely_questions?.questions_list || [],
      mostlikelyquestion_file_name: payload.most_likely_questions?.file_name || '',
      mostlikelyquestion_file_path: payload.most_likely_questions?.file_path || '',
      title_font_size: payload.title_font_size || '',
      title_font_style: payload.title_font_style || '',
      predefined_questions_enabled: payload.predifined_questions?.status || 'disabled',
      predefined_questions_count: payload.predifined_questions?.no_of_questions || 0,
      predefined_questions: payload.predifined_questions?.question_list || [],
      subtitle_font_style: payload.subtitle_font_style || '',
      subtitle_font_size: payload.subtitle_font_size || '',
      body_font_style: payload.body_font_style || '',
      body_font_size: payload.body_font_size || '',
      related_questions_enabled: payload.related_questions?.status || 'disabled',
      related_questions: payload.related_questions?.app_id || '',
      languages_enabled: payload.languages?.status || 'disabled',
      languages: payload.languages?.language_list || [],
      support_enabled: payload.support_details?.status || 'disabled',
      support_url: payload.support_details?.support_url || '',
      whitelist_domains: payload.whitelist_domains || [''],
    }
    console.log('format', formattedData)
    setInstallationState(formattedData)

    return formattedData
  }
  const check = (formattedData: any) => {
    console.log('working')
    setInstallationState(formattedData)
  }

  useEffect(() => {
    const webFormatLarge =
      botDetails &&
      botDetails?.installations &&
      botDetails?.installations.filter((item: any) => item.type === 'web_format_large')
    console.log('webFormatLarge', webFormatLarge)
    if (webFormatLarge && webFormatLarge[0]) {
      const settingsState = FormatBotDetails(webFormatLarge[0])
      console.log('settingsState', settingsState)

      // setInstallationState(settingsState)
      setQuestions(settingsState?.predefined_questions || [])
    }
  }, [botDetails])

  const startFileUpload = (fileInfo: any) => {
    // const maxSize = 2 * 1024 * 1024 //2mb
    // if (fileInfo.file.size > maxSize) {
    //     setShowWarning(true)
    // }
    // showWarning && setShowWarning(false)
    // updateInstallationState(
    //   'mostlikelyquestion_file_name',
    //   fileInfo?.fileList[0]?.originFileObj?.name,
    // )
    setFileUploaded(true)
    const fileUploadPayload = {
      container_id: 5,
      path: 'most_likely_questions',
      files: [`${fileInfo?.fileList[0]?.originFileObj?.name}`],
    }
    const formData = new FormData()
    const jsonData = JSON.stringify(fileUploadPayload)
    formData.append('data', jsonData)
    formData.append(
      fileInfo?.fileList[0]?.originFileObj?.name,
      fileInfo?.fileList[0]?.originFileObj,
    )
    dispatchUploadFileUsingLink(formData)
  }

  const fonts = [
    'Montserrat',
    'Helvetica',
    'Arial',
    'Mori',
    'Open Sans',
    'Roboto',
    'Playfair Display',
    'Calibri',
    'Lato',
  ]

  const titleFonts = [30, 34, 38, 42, 46, 50]
  const subTitleFonts = [10, 12, 14, 16, 18, 20]
  const bodyFonts = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

  useEffect(() => {
    dispatchGetPackageDetails('autosolve')
    const payload = {
      bot_id: botDetails?.id,
      type: 'web_format_large',
    }
    dispatchGenerateCode(payload)
    dispatchClearFileParams([])
  }, [])

  const appData = useappDataSelector()
  const navigate = useNavigate()
  const codeGenerated = useGenerateCodeSelector()
  console.log('codeGenerated', codeGenerated)

  const questionsListFromCsv = useFileParams()
  console.log('questionsListFromCsv', questionsListFromCsv)
  useEffect(() => {
    const data = questionsListFromCsv && questionsListFromCsv[questionsListFromCsv.length - 1]
    const arrayData = data && data[0] && data[0]?.questions_list
    const fileName = data && data[0] && data[0]?.input_file_name
    console.log('data',fileName)
    if (data) {
      // Do something with `data` here
      // setQuestionsList(data)
      console.log('questionsListFromCsv', arrayData)
      fileUploaded && 
      setInstallationState({...installationState,
        mostlikelyquestion_list:arrayData,
        mostlikelyquestion_file_name:fileName
      })

      // updateInstallationState('mostlikelyquestion_list', arrayData)
    }
  }, [questionsListFromCsv,fileUploaded])

  const saveSettings = async () => {
    console.log('installationState', installationState)
    console.log('ques',questions)
    const questionList = questions && questions.filter((item) => item !== '')
    if (installationState?.predefined_questions_enabled==='active' && installationState?.predefined_questions_count !==questionList.length){
      dispatchNotificationState({
        message: 'Warning',
        description: `Enter ${installationState?.predefined_questions_count} predefined questions`,
        type: 'warning',
      })
        }
    else {
     
      const settingsState = [
        {
          type: 'web_format_large',
          display_name: 'Web Format-Large',
          description: 'Use the API to link the auto solve bot to existing chatbot.',
          title: installationState?.title || '',
          source_names: installationState?.source_names || '',
          title_font_style: installationState?.title_font_style || '',
          title_font_size: installationState?.title_font_size || '',
          subtitle_font_style: installationState?.subtitle_font_style || '',
          subtitle_font_size: installationState?.subtitle_font_size || '',
          body_font_style: installationState?.body_font_style || '',
          body_font_size: installationState?.body_font_size || '',
          predifined_questions: {
            no_of_questions:
              installationState?.predefined_questions_enabled === 'disabled'
                ? 4
                : installationState?.predefined_questions_count || 4,
            status: installationState?.predefined_questions_enabled || 'disabled',
            question_list:
              installationState?.predefined_questions_enabled === 'disabled'
                ? []
                : questionList || [],
          },
          related_questions: {
            status: installationState?.related_questions_enabled || 'disabled',
            app_id:
              installationState?.related_questions_enabled === 'disabled'
                ? ''
                : installationState?.related_questions || '',
          },
          most_likely_questions: {
            status: installationState?.mostlikelyquestion_enables || 'disabled',
            file_name:
              installationState?.mostlikelyquestion_enables === 'disabled'
                ? ''
                : installationState?.mostlikelyquestion_file_name || '',
            file_path: 'most_likely_questions',
            questions_list:
              installationState?.mostlikelyquestion_enables === 'disabled' ||
              installationState?.mostlikelyquestion_file_name === ''
                ? []
                : installationState?.mostlikelyquestion_list || [],
          },
          languages: {
            status: installationState?.languages_enabled || 'disabled',
            language_list:
              installationState?.languages_enabled === 'disabled' ? [] : installationState?.languages || [],
          },
          support_details: {
            status: installationState?.support_enabled || 'disabled',
            support_url:
              installationState?.support_enabled === 'disabled' ? '' : installationState?.support_url || '',
          },
  
          whitelist_domains: [installationState?.whitelist_domains] || [''],
        },
      ]
  
      const installationJsons =
        botDetails &&
        botDetails?.installations &&
        botDetails?.installations.filter((item: any) => item.type != 'web_format_large')
      const installations = [...installationJsons, ...settingsState]
  
      const payload = {
        tenant_feature_id: featureSettings?.id,
        app_feature_id: featureId,
        bot_config: {
          ...botDetails,
          installations: installations,
        },
      }
  
      await dispatchUpdateBot(payload)
      const generateCodePayload = {
        bot_id: botDetails?.id,
        type: 'web_format_large',
      }
      window.open(`/webFormatLarge/${codeGenerated?.bot_key}`, '_blank', 'noopener,noreferrer')
      dispatchBotDetails(botDetails?.id)
      dispatchGenerateCode(generateCodePayload)
      console.log('helloooo')
    }
   
  }
  console.log('installationState', installationState?.title)

  return (
    <WizrFlexLayout
      flexDirection='column'
      style={{ gap: '8px', padding: '24px' }}
      background='white'
    >
      <WizrFlexLayout>
        <WizrFlexLayout
          flex={1}
          background='#FFF'
          textAlign='left'
          style={{ borderRadius: '16px', gap: '8px' }}
        >
          <WizrFlexLayout justifyContent='center' background='#FFF' flex='none'>
            <Radio.Group
              onChange={onTabChange}
              value={selectedTab}
              style={{ borderRadius: '16px' }}
            >
              <Radio.Button value='customize' style={getButtonStyle('customize')}>
                Customize
              </Radio.Button>
              <Radio.Button value='integrate' style={getButtonStyle('integrate')}>
                Integrate
              </Radio.Button>
            </Radio.Group>
          </WizrFlexLayout>
          {selectedTab === 'customize' ? (
            <WizrFlexLayout
              flexDirection='row'
              background='white'
              style={{ gap: '30px', padding: '8px' }}
            >
              <WizrFlexLayout
                flex={1}
                background='#FFF'
                justifyContent='start'
                textAlign='left'
                style={{ borderRadius: '16px', padding: '  0px 24px ', gap: '8px' }}
              >
                <WizrText type='body2' textColor='#707276'>
                  {' '}
                  Title{' '}
                </WizrText>
                <StyledWizrInput
                  placeholder='Knowledge Search'
                  onChange={(value: any) => {
                    updateInstallationState('title', value)
                  }}
                  value={installationState?.title}
                />
                <WizrText type='body2' textColor='#707276'>
                  {' '}
                  Name of Sources{' '}
                </WizrText>
                <StyledWizrInput
                  placeholder='Enter Bot Title'
                  onChange={(value: any) => {
                    updateInstallationState('source_names', value)
                  }}
                  value={installationState?.source_names}
                />

                <WizrFlexLayout
                  background='#FFF'
                  flex='none'
                  flexDirection='row'
                  justifyContent='flex-start'
                  style={{ gap: '16px' }}
                >
                  <WizrFlexLayout
                    background='#FFF'
                    flex='none'
                    textAlign='left'
                    style={{ gap: '8px', maxWidth: '48%' }}
                  >
                    <WizrText type='body2' textColor='#707276'>
                      Title Font Style{' '}
                    </WizrText>
                    <StyledDropDown
                      options={dropDownConverter(fonts)}
                      placeHolder='Select Font Style'
                      onChange={(value: any) => {
                        updateInstallationState('title_font_style', value)
                      }}
                      value={installationState?.title_font_style}
                    />
                  </WizrFlexLayout>
                  <WizrFlexLayout
                    background='#FFF'
                    flex='none'
                    textAlign='left'
                    style={{ gap: '8px', maxWidth: '48%' }}
                  >
                    <WizrText type='body2' textColor='#707276'>
                      Title Font Size{' '}
                    </WizrText>
                    <StyledDropDown
                      options={dropDownConverter(titleFonts)}
                      placeHolder='Select Font Size'
                      onChange={(value: any) => {
                        updateInstallationState('title_font_size', value)
                      }}
                      value={installationState?.title_font_size}
                    />
                  </WizrFlexLayout>
                </WizrFlexLayout>
                <WizrFlexLayout
                  background='white'
                  flexDirection='row'
                  justifyContent='left'
                  style={{ gap: '8px', padding: '8px 0px' }}
                  alignItems='center'
                  flex='none'
                >
                  <WizrText type='sub2'> Most Likely Questions </WizrText>
                  <InfoIcon />
                  <WizrToggleButton
                    isEnabled={
                      installationState?.mostlikelyquestion_enables === 'active' ? true : false
                    }
                    onChange={() => {
                      updateInstallationState(
                        'mostlikelyquestion_enables',
                        installationState?.mostlikelyquestion_enables === 'active'
                          ? 'disabled'
                          : 'active',
                      )
                    }}
                  />
                </WizrFlexLayout>
                {installationState?.mostlikelyquestion_enables === 'active' ? (
                  <>
                    <WizrText type='hint'>Upload csv in the following format</WizrText>
                    <table
                      style={{
                        border: '1px solid var(--generic-stroke, #E5E7EB)',
                        overflowX: 'auto',
                      }}
                    >
                      <tr>
                        <th style={{ border: '1px solid var(--generic-stroke, #E5E7EB)' }}>
                          questions
                        </th>
                      </tr>
                      <tr>
                        <td>question 1</td>
                      </tr>
                      <tr>
                        <td>question 2</td>
                      </tr>
                    </table>

                    <WizrText type='body2' textColor='#707276'>
                      {' '}
                      Upload CSV{' '}
                    </WizrText>
                    {/* <StyledDropDown options={[]} placeHolder='Select App' onChange={(value: any) => {}} /> */}
                    <StyledWizrInput
                      disabled={installationState?.mostlikelyquestion_enables === 'disabled'}
                      //onChange={(value: any) => updateMessageParams(item.name, value)}
                      placeholder='Upload File'
                      value={installationState?.mostlikelyquestion_file_name}
                      suffix={
                        <Space>
                          {installationState?.mostlikelyquestion_file_name && ( // Only display clear icon if there's a value
                            <Button
                              shape='round'
                              onClick={(e: any) => {
                                updateInstallationState('mostlikelyquestion_file_name', '')
                                // updateInstallationState('mostlikelyquestion_list','')
                              }}
                              disabled={
                                installationState?.mostlikelyquestion_enables === 'active'
                                  ? false
                                  : true
                              }
                            >
                              remove file
                            </Button>
                          )}
                          <WizrUploadContainer
                            fileTypes='.csv'
                            onChange={startFileUpload}
                            disabled={installationState?.mostlikelyquestion_enables === 'disabled'}
                          >
                            <Button
                              shape='round'
                              disabled={
                                installationState?.mostlikelyquestion_enables === 'active'
                                  ? false
                                  : true
                              }
                            >
                              Upload
                            </Button>
                          </WizrUploadContainer>
                        </Space>
                      }
                    />
                  </>
                ) : (
                  <></>
                )}
                <WizrFlexLayout
                  background='white'
                  flexDirection='row'
                  justifyContent='left'
                  style={{ gap: '8px', padding: '8px 0px' }}
                  alignItems='center'
                  flex='none'
                                  >
                  <WizrText type='sub2'>Predefined Questions</WizrText>
                  <InfoIcon />
                  <WizrToggleButton
                    isEnabled={
                      installationState?.predefined_questions_enabled === 'active' ? true : false
                    }
                    onChange={() =>{
                      updateInstallationState(
                        'predefined_questions_enabled',
                        installationState?.predefined_questions_enabled === 'disabled'
                          ? 'active'
                          : 'disabled',
                      )
                  
                  
                    }
                    }
                  />
                </WizrFlexLayout>
                {installationState?.predefined_questions_enabled === 'active' ? (
                  <>
                    <WizrText type='body2' textColor='#707276'>
                      {' '}
                      Select Number of Questions{' '}
                    </WizrText>
                    <StyledDropDown
                      disabled={installationState?.predefined_questions_enabled === 'disabled'}
                      options={dropDownConverter([2, 4])}
                      placeHolder='Select Number of Questions'
                      onChange={(value: any) => {
                        updateInstallationState('predefined_questions_count', value)
                        const newArray = [...questions];
    
                        // Set the last two elements to an empty string
                        newArray[3] = '';
                        newArray[2] = '';
                        
                        // Update the state with the new array
                        setQuestions(newArray);
                      }}
                      value={installationState?.predefined_questions_count}
                    />
                    <WizrText type='body2' textColor='#707276'>
                      {' '}
                      Question 1{' '}
                    </WizrText>
                    <StyledWizrInput
                      disabled={installationState?.predefined_questions_enabled === 'disabled'}
                      placeholder='Enter Question 1'
                      value={questions && questions[0]}
                      onChange={(value: string) => handleInputChange(0, value)}
                    />
                
                    <WizrText type='body2' textColor='#707276'>
                      Question 2
                    </WizrText>
                    <StyledWizrInput
                      disabled={installationState?.predefined_questions_enabled === 'disabled'}
                      placeholder='Enter Question 2'
                      value={questions && questions[1]}
                      onChange={(value: string) => handleInputChange(1, value)}
                    />
                    {installationState?.predefined_questions_count === 4 ?<>
                    <WizrText type='body2' textColor='#707276'>
                      {' '}
                      Question 3
                    </WizrText>
                    <StyledWizrInput
                      disabled={
                        installationState?.predefined_questions_count === 2 ||
                        installationState?.predefined_questions_enabled === 'disabled'
                      }
                      placeholder='Enter Question 3'
                      value={questions && questions[2]}
                      onChange={(value: string) => handleInputChange(2, value)}
                      // disabled={installationState?.predefined_questions_count === 2}
                    />

                    <WizrText type='body2' textColor='#707276'>
                      Question 4
                    </WizrText>
                    <StyledWizrInput
                      // disabled={installationState?.predefined_questions_enabled === 'disabled'}
                      placeholder='Enter Question 4'
                      value={questions && questions[3]}
                      onChange={(value: string) => handleInputChange(3, value)}
                      disabled={
                        installationState?.predefined_questions_count === 2 ||
                        installationState?.predefined_questions_enabled === 'disabled'
                      }
                    />
                    </>:<></>}
                  </>
                ) : (
                  <></>
                )}
              </WizrFlexLayout>
              <WizrFlexLayout
                flex={1}
                background='#FFF'
                justifyContent='start'
                textAlign='left'
                style={{ borderRadius: '16px', padding: '  0px 24px ', gap: '8px' }}
              >
                <WizrFlexLayout
                  background='#FFF'
                  flex='none'
                  flexDirection='row'
                  justifyContent='flex-start'
                  style={{ gap: '16px' }}
                >
                  <WizrFlexLayout
                    background='#FFF'
                    flex='none'
                    textAlign='left'
                    style={{ gap: '8px', maxWidth: '48%' }}
                  >
                    <WizrText type='body2' textColor='#707276'>
                      SubTitle Font Style{' '}
                    </WizrText>
                    <StyledDropDown
                      options={dropDownConverter(fonts)}
                      placeHolder='Select Font Style'
                      onChange={(value: any) => {
                        updateInstallationState('subtitle_font_style', value)
                      }}
                      value={installationState?.subtitle_font_style}
                    />
                  </WizrFlexLayout>
                  <WizrFlexLayout
                    background='#FFF'
                    flex='none'
                    textAlign='left'
                    style={{ gap: '8px', maxWidth: '48%' }}
                  >
                    <WizrText type='body2' textColor='#707276'>
                      SubTitle Font Size{' '}
                    </WizrText>
                    <StyledDropDown
                      options={dropDownConverter(subTitleFonts)}
                      placeHolder='Select Font Size'
                      onChange={(value: any) => {
                        updateInstallationState('subtitle_font_size', value)
                      }}
                      value={installationState?.subtitle_font_size}
                    />
                  </WizrFlexLayout>
                </WizrFlexLayout>
                <WizrFlexLayout
                  background='#FFF'
                  flex='none'
                  flexDirection='row'
                  justifyContent='flex-start'
                  style={{ gap: '16px' }}
                >
                  <WizrFlexLayout
                    background='#FFF'
                    flex='none'
                    textAlign='left'
                    style={{ gap: '8px', maxWidth: '48%' }}
                  >
                    <WizrText type='body2' textColor='#707276'>
                      Body Font Style{' '}
                    </WizrText>
                    <StyledDropDown
                      options={dropDownConverter(fonts)}
                      placeHolder='Select Font Style'
                      onChange={(value: any) => {
                        updateInstallationState('body_font_style', value)
                      }}
                      value={installationState?.body_font_style}
                    />
                  </WizrFlexLayout>
                  <WizrFlexLayout
                    background='#FFF'
                    flex='none'
                    textAlign='left'
                    style={{ gap: '8px', maxWidth: '48%' }}
                  >
                    <WizrText type='body2' textColor='#707276'>
                      Body Font Size{' '}
                    </WizrText>
                    <StyledDropDown
                      options={dropDownConverter(bodyFonts)}
                      placeHolder='Select Font Size'
                      onChange={(value: any) => {
                        updateInstallationState('body_font_size', value)
                      }}
                      value={installationState?.body_font_size}
                    />
                  </WizrFlexLayout>
                </WizrFlexLayout>
                <WizrFlexLayout
                  background='white'
                  flexDirection='row'
                  justifyContent='left'
                  style={{ gap: '8px', padding: '8px 0px' }}
                  alignItems='center'
                  flex='none'
                >
                  <WizrText type='sub2'> Related Questions </WizrText>
                  <InfoIcon />
                  <WizrToggleButton
                    isEnabled={installationState?.related_questions_enabled === 'active'}
                    onChange={() => {
                      installationState?.related_questions_enabled === 'active'
                        ? updateInstallationState('related_questions_enabled', 'disabled')
                        : updateInstallationState('related_questions_enabled', 'active')
                    }}
                  />
                </WizrFlexLayout>
                {installationState?.related_questions_enabled === 'active'?<>
                <WizrText type='body2' textColor='#707276'>
                  {' '}
                  Select App{' '}
                </WizrText>
                <StyledDropDown
                  value={installationState?.related_questions}
                  disabled={installationState?.related_questions_enabled === 'disabled'}
                  options={dropDownConverterApp(appData)}
                  placeHolder='Select App'
                  onChange={(value: any) => {
                    updateInstallationState('related_questions', value)
                  }}
                /></>:<></>}

                <WizrFlexLayout
                  background='white'
                  flexDirection='row'
                  justifyContent='left'
                  style={{ gap: '8px', padding: '8px 0px' }}
                  alignItems='center'
                  flex='none'
                >
                  <WizrText type='sub2'>Languages</WizrText>
                  <InfoIcon />
                  <WizrToggleButton
                    isEnabled={installationState?.languages_enabled === 'active'}
                    onChange={() => {
                      installationState?.languages_enabled === 'active'
                        ? updateInstallationState('languages_enabled', 'disabled')
                        : updateInstallationState('languages_enabled', 'active')
                    }}
                  />
                </WizrFlexLayout>
                {installationState?.languages_enabled === 'active'?<>
                <WizrText type='body2' textColor='#707276'>
                  {' '}
                  Languages{' '}
                </WizrText>
                {/* <StyledDropDown
                options={dropDownConverter(LanguageOptions)}
                placeHolder='Select Languages'
                onChange={(value: any) => {updateInstallationState('languages',value)}}

              /> */}
                <StyledWizrTagInput
                  disabled={installationState?.languages_enabled === 'disabled'}
                  mode='multiple'
                  options={dropDownConverter(LanguageOptions)}
                  defaultValue={installationState?.languages}
                  //   onChange={(value: any) => {
                  //     console.log('value', value)
                  //     doUpdateFields('product_llm_models', value)
                  //   }}
                  onChange={(value: any) => {
                    updateInstallationState('languages', value)
                  }}
                  maxTagCount={'responsive'}
                  value={installationState?.languages}
                /></>:<></>}

                <WizrFlexLayout
                  background='white'
                  flexDirection='row'
                  justifyContent='left'
                  style={{ gap: '8px', padding: '8px 0px' }}
                  alignItems='center'
                  flex='none'
                >
                  <WizrText type='sub2'>Support</WizrText>
                  <InfoIcon />
                  <WizrToggleButton
                    isEnabled={installationState?.support_enabled === 'active'}
                    onChange={() => {
                      installationState?.support_enabled === 'active'
                        ? updateInstallationState('support_enabled', 'disabled')
                        : updateInstallationState('support_enabled', 'active')
                    }}
                  />
                </WizrFlexLayout>
                {installationState?.support_enabled === 'active'?<>
                <WizrText type='body2' textColor='#707276'>
                  {' '}
                  Support Url{' '}
                </WizrText>
                <StyledWizrInput
                  disabled={installationState?.support_enabled === 'disabled'}
                  placeholder='Enter Support Url'
                  onChange={(value: any) => {
                    updateInstallationState('support_url', value)
                  }}
                  value={installationState?.support_url}
                /></>:<></>}
                <WizrText type='body2' textColor='#707276'>
                  {' '}
                  Whitelist Domain{' '}
                </WizrText>
                <StyledWizrInput
                  placeholder='Enter Whitelist Domain'
                  onChange={(value: any) => {
                    updateInstallationState('whitelist_domains', value)
                  }}
                  value={installationState?.whitelist_domains}
                />
              </WizrFlexLayout>
            </WizrFlexLayout>
          ) : (
            <WizrFlexLayout
              background='#FFF'
              justifyContent='start'
              textAlign='left'
              style={{ borderRadius: '16px', padding: '16px', gap: '8px' }}
            >
              <WizrText type='body2'>
                To implement the autosolve, add the provided code snippet just before the closing{' '}
                {'</body>'} tag of your webpage. Ensure you create a {'<div>'} element with the ID
                `embedded-app-container` at the desired location where you want the installation to
                appear.
              </WizrText>

              {/* <WizrTextArea background='#f2f4f9' disabled={true} maxRows={24} value={codeGenerated}></WizrTextArea> */}
              <WizrFlexLayout
                background='#f2f4f9'
                style={{ borderRadius: '16px', padding: '16px', gap: '8px' }}
              >
                <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', textAlign: 'left' }}>
                  {codeGenerated?.script}
                </pre>
                <WizrFlexLayout
                  justifyContent='flex-end'
                  alignItems='flex-end'
                  style={{ padding: '10px' }}
                  background='transparent'
                >
                  <Button
                    type='link'
                    icon={<Copy color={'#999B9F'} />}
                    onClick={() => {
                      antdMessage.success('Copied to clipboard', 2)
                      copy(codeGenerated?.script)
                    }}
                  />
                </WizrFlexLayout>
              </WizrFlexLayout>
            </WizrFlexLayout>
          )}
        </WizrFlexLayout>
      </WizrFlexLayout>
      <WizrFlexLayout
        justifyContent='flex-end'
        alignItems='flex-end'
        style={{ padding: '10px' }}
        background='transparent'
      >
        {/* <WizrButton text={'Test & Preview'} style={{ borderRadius: '20px', marginRight: "7px" }} onClick={saveSettings} >
          </WizrButton>  */}
        {selectedTab === 'customize' ? (
          <WizrButton
            text={'Test & Preview'}
            style={{ borderRadius: '20px', marginRight: '7px' }}
            onClick={saveSettings}
          ></WizrButton>
        ) : (
          <></>
        )}
        {/* <WizrButton text={'Test'} style={{ borderRadius: '20px', marginRight: "7px" ,width:"100px"}} onClick={saveSettings} ></WizrButton> */}
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

export default WebFormatLargeInstallationPage
