import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons"
import WizrButton from "../../../components/WizrButton"
import WizrFlexLayout from "../../../components/WizrFlexLayout"
import WizrUserJourney from "../../../components/WizrUserJourney"
import WizrText from "../../../components/WizrText"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import WizrInput from "../../../components/WizrInput"
import WizrDropDown from "../../../components/WizrDropDown"
import { useEffect, useState } from "react"
import { dispatchCreateUser, dispatchGetTenantGroups, dispatchListTenants, dispatchListUsers, dispatchRolesList, dispatchSaveTenantGroups, dispatchUpdateUserDetails, dispatchupdateUserView } from "../../../redux/actions/content"
import { useContentLoadingSelector, useListRolesSelectors, useListTenantSelector, useTenantTeamSelectors } from "../../../redux/selectors/content"
import jwtDecode from "jwt-decode"
import { Button, Divider, Input, Select, Space, Form } from "antd"
import { MODAL_TYPES, useWizrModalContext } from "../../../providers/modal/WizrModalProvider"
import { v4 as uuidv4 } from 'uuid'
import { getTenantId } from "../../../utils"
import EndUser from "../../../assets/icons/EndUser"

const StyledFormContainer = styled(WizrFlexLayout)`
align-items:flex-start;
justify-content:flex-start;
background:white;
gap:4px;

`
const StyledDropDown = styled(WizrDropDown)`
 height:50px;
.ant-select-selector{
    height:50px !important;
    background-color:#F2F4F9 !important;
   .ant-select-selection-placeholder{
    margin-top:10px;
   }
}
 `
const StyledSelect = styled(Select)`
 height:50px;
.ant-select-selector{
    height:50px !important;
    background-color:#F2F4F9 !important;
   .ant-select-selection-placeholder{
    text-align:left;
   }
}
 `
interface OptionType {
  label: string;
  value: string;
}
interface Payload {
  name: string;
  email: string | null;
  phone_no: string[] | never[];
  user_role: string;
  tenant_id?: string;
  user_teams?: string // Make tenant_id optional
}
const UserDetail = (): JSX.Element => {
  const [role, setRole] = useState("")
  const loading = useContentLoadingSelector()
  const roles = useListRolesSelectors()
  const { showModal, hideModal } = useWizrModalContext()
  const [currentTeams, setCurrentTeams] = useState([])

  const [rolesList, setRolesList] = useState([])
  const navigate = useNavigate()
  const [tenant, setTenant] = useState({ label: "", value: "" })
  const [userDetails, setUserDetails] = useState({
    id: "",
    displayName: "",
    mail: null,
    mobilePhone: [],
    createdDateTime: "",
    accountEnabled: true,
    userRole: "",
    user_teams: ""
  })
  const location = useLocation()
  const action = location?.state?.action

  useEffect(() => {
    dispatchRolesList()
    dispatchListTenants()

    const token = localStorage.getItem("id_token")
    if (token) {
      const tokenDecoded: { extension_UserRole: any, extension_TenantId: any } = jwtDecode(token)
      const userRole = tokenDecoded?.extension_UserRole
      if (tenant?.value) {
        dispatchGetTenantGroups(tenant?.value)
      }

      setRole(userRole)
    }
  }, [tenant])

  useEffect(() => {
    if (location?.state?.action !== "create") {
      const details = location?.state?.action
      const patternUserRole = /extension_[a-f0-9]{32}_UserRole/;
      const patternUserTeam = /extension_[a-f0-9]{32}_UserTeams/
      Object.keys(details).forEach(key => {
        if (patternUserRole.test(key)) {
          const userDetails = {
            ...details,
            userRole: details[key]
          }
          setUserDetails(userDetails)
        }
        if (patternUserTeam.test(key)) {
          const teams = details[key].split(',')
          const userDetails = {
            ...details,
            user_teams: teams
          }
          setCurrentTeams(teams)
          setUserDetails(userDetails)
        }
      });
      const TenantId = /extension_[a-f0-9]{32}_TenantId/;
      Object.keys(details).forEach(key => {
        if (TenantId.test(key)) {
          updateRolesList(details[key])
        }
      });

      // updateRolesList(location?.state?.action)

      //setUserDetails(location?.state?.action)
    }
    if (location?.state?.tenant && Object.keys(location?.state?.tenant).length !== 0) {

      setTenant(location?.state?.tenant);
      updateRolesList(location?.state?.tenant?.value);
    }
    else {
      const tenantId = getTenantId()
      setTenant({ label: "", value: tenantId });
      updateRolesList(tenantId);
    }
  }, [location?.state])

  const tenants = useListTenantSelector()
  const teams: string[] = useTenantTeamSelectors()



  const dropDownConverterRole = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item?.display_name,
        value: item?.role_key,
      }))
    return result
  }
  const dropDownConverterTenant = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item?.tenant_name,
        value: item?.tenant_id,
      }))
    return result
  }
  const dropDownConverterTeams = (value: string[]) => {
    if (!value || value.length === 0) {
      return []; // Return an empty array if the value is falsy or empty
    }

    const result = value.map((item: any, key: number) => ({
      label: (
        <div style={{ alignItems: 'center', display: 'flex', gap: '10px' }}>
          <div>
            <EndUser stroke={'black'} />
          </div>
          <div>
            {item?.team_name} {/* Render the label text */}
          </div>
        </div>
      ),
      value: item?.team_key,
    }));

    return result;
  };


  const updateUserDetails = () => {
    const payload = {
      user_id: userDetails?.id,
      user_data: {
        name: userDetails?.displayName,
        phone_no: userDetails?.mobilePhone,
        user_role: userDetails?.userRole,
        user_teams: userDetails?.user_teams
      }
    }

    dispatchUpdateUserDetails(payload)


  }

  const createUser = () => {
    const payload: Payload = {
      name: userDetails?.displayName,
      email: userDetails?.mail,
      phone_no: userDetails?.mobilePhone,
      user_role: userDetails?.userRole,
      user_teams: userDetails?.user_teams
    }

    if (role === "superadmin") {
      payload.tenant_id = tenant?.value
    }
    dispatchCreateUser(payload)
  }

  const onSaveAction = () => {
    if (action !== "create") {
      updateUserDetails()
    }
    else {
      createUser()
    }
    navigate(-1)
    // if (role==="superadmin"){
    dispatchupdateUserView({
      activeKey: "users",
      tenantId: tenant?.value
    })
    //  }
    //dispatchListUsers(`tenant&tenant_id=${tenant?.value}`)
  }
  const setDetails = (value: any, key: any) => {

    setUserDetails({
      ...userDetails,
      [key]: value
    })
  }
  const updateRolesList = (value: any) => {
    const currentTenant = tenants?.filter((item: any) => item?.tenant_id === value)
    if (currentTenant[0]?.master_tenant) {
      setRolesList(roles)
    }
    else {
      const wizrRoles = ["Super Admin", "Wizr Manager", "Wizr User"]
      const updateTenantsList = roles?.filter((item: any) => !wizrRoles.includes(item?.role_name))
      setRolesList(updateTenantsList)
    }
  }
  const createNewTeam = () => {
    showModal(MODAL_TYPES.CREATE_TEAMS, {
      name: '',
      onFormSubmission: async ({ currentname }: any) => {

        const groupPayload =
        {
          tenant_id: tenant?.value,
          teams: {
            id: uuidv4(),
            team_key: currentname?.replace(/\s+/g, '').toLowerCase(),
            team_name: currentname
          }
        }
        await dispatchSaveTenantGroups(groupPayload)
        dispatchGetTenantGroups(tenant?.value)
        hideModal()
      },

      onCancel: hideModal,
    })
  }
  return (
    <Form style={{ width: "100%" }}>
      <WizrFlexLayout style={{ padding: '0' }}>
        <WizrFlexLayout flex={1} >
          <WizrUserJourney
            userJourney={[
              {
                title: (
                  <WizrButton
                    style={{ paddingBottom: '8px' }}
                    icon={<ArrowLeftOutlined style={{ fontSize: '22px', marginRight: '10px' }} />}
                    type='text'
                    text={<WizrText type='h6'>{'Users'}</WizrText>}
                    onClick={() => navigate(-1)}
                  />
                ),
              },
              {
                title: action === 'create' ? 'Create New Users' : 'Edit User',
              },
            ]}
          ></WizrUserJourney>
        </WizrFlexLayout>
        <WizrFlexLayout
          flexDirection='row'
          flex={11}
          background='white'
          style={{ borderRadius: '8px', padding: "10px" }}

        >
          <WizrFlexLayout background='white' flex={1} >
            <></>
          </WizrFlexLayout>
          <WizrFlexLayout background='white' flex={1} style={{ padding: "10px 20px", gap: '12px' }} alignItems='flex-start' justifyContent='flex-start' alignContent='flex-start'>
            <StyledFormContainer flexDirection='column'>
              <WizrText type='body2' textColor='#707276'>Name</WizrText>
              <WizrInput placeholder='Enter User Name' background={"#F2F4F9"} value={userDetails?.displayName} onChange={(value: any) => { setDetails(value, "displayName") }}></WizrInput>

            </StyledFormContainer>

            <StyledFormContainer flexDirection='column' style={{ minHeight: "95px" }}>
              <WizrText type='body2'>Email</WizrText>
              <Form.Item
                style={{ width: "100%" }}
                name='registered_emailid'
                rules={[
                  {
                    pattern: new RegExp(/^\S+@\S+\.\S+$/), // Regular expression for email validation
                    message: 'Enter a valid email address!',
                  },
                ]}
              >
                <WizrInput placeholder='Enter Email' style={{ width: "100%" }} background={"#F2F4F9"} value={userDetails?.mail} disabled={action !== "create"} onChange={(value: any) => { setDetails(value, "mail") }}></WizrInput></Form.Item>
            </StyledFormContainer>

            <StyledFormContainer flexDirection='column'>
              <WizrText type='body2'> Mobile</WizrText>
              <WizrInput placeholder='Enter MobileNumber ' background={"#F2F4F9"} value={userDetails?.mobilePhone} onChange={(value: any) => { setDetails(value, "mobilePhone") }}></WizrInput>

            </StyledFormContainer>

            <StyledFormContainer flexDirection='column'>
              <WizrText type='body2'>Role</WizrText>
              <StyledDropDown options={dropDownConverterRole(rolesList)} value={userDetails?.userRole} placeHolder='Select Role' onChange={(value: any) => { setDetails(value, "userRole") }} />

            </StyledFormContainer>
            {role === "superadmin" && action === 'create' ? <StyledFormContainer flexDirection='column'>
              <WizrText type='body2'>Select Tenant</WizrText>
              <StyledDropDown options={dropDownConverterTenant(tenants)} value={tenant?.label} placeHolder='Select Tenant' onChange={(value: any, label: any) => {
                updateRolesList(value)
                setTenant(label)
                dispatchGetTenantGroups(value)
                setDetails('', "user_teams")
                setCurrentTeams([])
              }} />

            </StyledFormContainer> : <></>}
            <StyledFormContainer flexDirection='column'>
              <WizrText type='body2'>Teams</WizrText>
              {/* <StyledDropDown options={dropDownConverterRole(rolesList)}  value = {userDetails?.userRole} placeHolder='Select Role' onChange={(value:any)=>{setDetails(value,"userRole")}}/> */}

              <StyledSelect
                // Setting mode to "multiple" enables multiple selection
                value={currentTeams}
                style={{ width: '100%' }}
                placeholder="Select teams"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: '8px 0',
                      }}
                    />
                    <Space
                      style={{
                        padding: '0 8px 4px',
                      }}
                    >

                      <Button type="text" icon={<PlusOutlined />} onClick={() => { createNewTeam() }}>
                        Add Team
                      </Button>
                    </Space>
                  </>
                )}
                options={dropDownConverterTeams(teams)}
                onChange={(value: any) => {
                  setDetails(value, "user_teams")
                  setCurrentTeams(value)
                }}
              />
            </StyledFormContainer>

            <WizrFlexLayout flexDirection="row" justifyContent='flex-end' alignContent='flex-end' alignItems="flex-end" background='white' style={{ maxHeight: "40px", paddingBottom: "8px", gap: "10px" }}>
              <WizrButton style={{ borderRadius: "20px", height: "30px", borderWidth: "1px", borderColor: "black" }} type='text' text={"Cancel"} onClick={() => { navigate(-1) }}></WizrButton>

              <WizrButton
                disabled={loading}
                style={{ width: "80px", borderRadius: "20px", height: "30px" }} type='primary' text={"Save"} onClick={() => { onSaveAction() }}></WizrButton>

            </WizrFlexLayout>

          </WizrFlexLayout>
          <WizrFlexLayout background='white' flex={1} >
            <></>
          </WizrFlexLayout>
        </WizrFlexLayout>
      </WizrFlexLayout>
    </Form>
  )
}
export default UserDetail