const GoogleIcon = ():JSX.Element=>{
    return(
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_401_604)">
<path d="M20.1423 12.6626C20.1423 12.0071 20.0879 11.5288 19.9701 11.0327H12.3093V13.9914H16.806C16.7154 14.7266 16.2258 15.8339 15.1379 16.578L15.1226 16.6771L17.5448 18.5105L17.7126 18.5269C19.2538 17.1361 20.1423 15.0898 20.1423 12.6626Z" fill="#4285F4"/>
<path d="M12.3093 20.458C14.5123 20.458 16.3618 19.7493 17.7126 18.5269L15.1379 16.578C14.4489 17.0475 13.5241 17.3752 12.3093 17.3752C10.1516 17.3752 8.32033 15.9845 7.66751 14.0623L7.57183 14.0702L5.0532 15.9747L5.02026 16.0642C6.36201 18.6685 9.11808 20.458 12.3093 20.458Z" fill="#34A853"/>
<path d="M7.66746 14.0623C7.49521 13.5662 7.39553 13.0347 7.39553 12.4855C7.39553 11.9363 7.49521 11.4048 7.6584 10.9087L7.65384 10.8031L5.10365 8.86792L5.02021 8.9067C4.46721 9.98743 4.1499 11.2011 4.1499 12.4855C4.1499 13.77 4.46721 14.9835 5.02021 16.0643L7.66746 14.0623Z" fill="#FBBC05"/>
<path d="M12.3093 7.59563C13.8415 7.59563 14.875 8.24228 15.4643 8.78268L17.767 6.58579C16.3528 5.30134 14.5123 4.51294 12.3093 4.51294C9.11808 4.51294 6.36201 6.30231 5.02026 8.90665L7.65845 10.9087C8.32033 8.98641 10.1516 7.59563 12.3093 7.59563Z" fill="#EB4335"/>
</g>
<defs>
<clipPath id="clip0_401_604">
<rect width="16" height="16" fill="white" transform="translate(4.1499 4.51294)"/>
</clipPath>
</defs>
</svg>

    )
}

export default GoogleIcon