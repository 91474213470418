import { Upload, UploadProps } from 'antd'
import { styled } from 'styled-components'

interface IWizrUploadContainer extends UploadProps {
  onRemove?: any
  onDrop?: any
  onChange?: any
  children?: JSX.Element
  dragDropEnabled?: boolean
  directory?: boolean
  fileTypes?: string
}

const { Dragger } = Upload

const StyledDagger = styled(Dragger)`
  flex: 1;
  .ant-upload-drag {
    border: none !important;
    background: transparent;
  }
`

const WizrUploadContainer = ({
  onRemove,
  onDrop,
  onChange,
  children,
  dragDropEnabled,
  directory,
  fileTypes,
}: IWizrUploadContainer): JSX.Element => {
  const UploadComponent = dragDropEnabled ? StyledDagger : Upload

  let count = 0  

  const handleUpload = (info: any) => {    
    // Check if it's a directory upload
    if (info.fileList.length === 1) {
      onChange(info);
    } else {
      const number = info.fileList.length
      count++
      if(count === number) {
        onChange(info);
        count = 0;
      }
    }
  };
  

  return (
    <UploadComponent
      name='file'
      onRemove={onRemove}
      onDrop={onDrop}
      onChange={handleUpload}
      disabled={false}
      fileList={[]}
      showUploadList={false}
      multiple={true}
      accept={fileTypes?fileTypes:'*'} 
      directory={directory}
    >
      {' '}
      {children}
    </UploadComponent>
  )
}

export default WizrUploadContainer
