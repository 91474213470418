
const KnowMoreIcon = () :JSX.Element=> {
    return(
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 9L9.5 13L17.517 9M1.5 13L9.5 17L17.517 13M1.5 5.157L9.508 9L17.517 5.157L9.508 1L1.5 5.157Z" stroke="#1D1F22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        

    )
}

export default KnowMoreIcon;