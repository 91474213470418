import { Popover } from 'antd'
import WizrButton from '../WizrButton'
import MoreIcon from '../../assets/icons/More'
import EditIcon from '../../assets/icons/EditIcon'
import DeleteIcon from '../../assets/icons/DeleteIcon'
import { dispatchNotificationState } from '../../redux/actions/common'
import { dispatchDeleteFaqPastTicketGroups, dispatchDeleteFolder, dispatchListFolders, dispatchUpdateFolder } from '../../redux/actions/content'
import { useWizrModalContext, MODAL_TYPES } from '../../providers/modal/WizrModalProvider'
import WizrFlexLayout from '../../stories/WizrFlexLayout/WizrFlexLayout'
import { useContentsSelector } from '../../redux/selectors/content'
import { useAuth } from '../../authContext'
import styled from 'styled-components'

interface IFolderActionPopOver {
  detail: any
  type:any
}

interface IFolderActionPopOverContent {
  onEditAction: () => void
  onDeleteAction: () => void
  type:any
}

const FolderActionPopOverContent = ({
  onEditAction,
  onDeleteAction,
  type
}: IFolderActionPopOverContent): JSX.Element => {

  const { userPermission, hasAcces } = useAuth();  

  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      <WizrButton
        icon={<EditIcon />}
        text='Edit'
        type='text'
        onClick={onEditAction}
        disabled={hasAcces('update_folder') === true ? false : true}
        justifyContent='flex-start'
      />
      <WizrButton
        icon={<DeleteIcon />}
        text='Delete'
        type='text'
         disabled={hasAcces('delete_folder') === true ? false : true}
        // disabled={type !== 'knowledge_base' }
        onClick={onDeleteAction}
        justifyContent='flex-start'
      />
    </WizrFlexLayout>
  )
}

const FolderActionPopOver = ({ detail ,type}: IFolderActionPopOver): JSX.Element => {
  const { showModal, hideModal } = useWizrModalContext()
  const contentsListing = useContentsSelector()

  const onEdit = () =>
    showModal(MODAL_TYPES.CREATE_UPDATE_FOLDER, {
      folderName: detail?.folder_name,
      folderDescription: detail?.folder_description,
      onFormSubmission: ({ currentFolderName, currentFolderDescription }: any) => {
      
        const payload = {
          ...detail,
          folder_name: currentFolderName,
          folder_description: currentFolderDescription,
        }
        if (currentFolderName !== detail?.folder_name) {
          const isExistingName = contentsListing?.find(
            (content: any) => currentFolderName === content?.folder_name,
          )
          if (!isExistingName) {
            hideModal()
            dispatchUpdateFolder({payload,type})
          } else {
            dispatchNotificationState({
              message: 'Error',
              description: `${currentFolderName} already exists. Please try a different name.`,
              type: null,
            })
          }
        } else {
          const payload ={
            ...detail,
            folder_description: currentFolderDescription,
          }
          hideModal()
          dispatchUpdateFolder({payload,type})
        }
      },

      onCancel: hideModal,
    })

  const onDelete = () =>
    showModal(MODAL_TYPES.DELETE_FOLDER, {
      folderName: detail?.folder_name,
      folderSize:detail?.file_count,
      onDeleteConfirm: async() => {
        const folderId = detail?.folder_id
        hideModal()
        if (type === 'knowledge_base') {
        await dispatchDeleteFolder({folderId,type})
      }
        else{
        await  dispatchDeleteFaqPastTicketGroups(type,folderId)
        }
        dispatchListFolders(type)
      },
      onCancel: hideModal,
    })

  return (
    <Popover
      content={<FolderActionPopOverContent onDeleteAction={onDelete} onEditAction={onEdit} type={type}/>}
      title=''
      trigger='click'
    >
      <StyledButton icon={<MoreIcon />} text='' type='text' style={{height: '40px', width: '40px', borderRadius: '50px'}}/>
    </Popover>
  )
}

export default FolderActionPopOver

const StyledButton = styled(WizrButton)`
border-radius: 50%;
width: 20px;
height: 20px;
padding-right: 8px;
justify-content: center;
align-items: center;
  /* Default styles */
  path {
    stroke: #999B9F; /* Default stroke color */
    transition: stroke 0.3s ease; /* Smooth transition for color change */
  }

  /* Hover styles */
  &:hover path {
    stroke: #1D1F22
  }
`;
