import { Popover } from 'antd'
import WizrButton from '../WizrButton'
import MoreIcon from '../../assets/icons/More'
import EditIcon from '../../assets/icons/EditIcon'
import { useNavigate, useParams } from 'react-router-dom'
import WizrFlexLayout from '../../stories/WizrFlexLayout/WizrFlexLayout'
import DeleteIcon from '../../assets/icons/DeleteIcon'
import { MODAL_TYPES, useWizrModalContext } from '../../providers/modal/WizrModalProvider'
import { dispatchCreateFolderGroup, dispatchDeletIntegration, dispatchFeatureSettings, dispatchIntegrationList, dispatchUpdateIntegration, dispatchUpdatePersona } from '../../redux/actions/content'
import { useFeatureSettingSelector } from '../../redux/selectors/content'
import styled from 'styled-components'



const PersonaPopOver = ({persona}:any): JSX.Element => {
    const fetaureData = useFeatureSettingSelector()
  const { showModal, hideModal } = useWizrModalContext()

  const onEditAction =  ()=>{
    showModal(MODAL_TYPES.UPDATE_PERSONA,{  
        persona:persona,  
        action:'edit',
          onCancel: hideModal,
      })
  }
  const deleteGroup=async()=>{
    hideModal()
    const payload = {
      id: fetaureData?.id,
      data: {
      persona_id:persona?.persona_id,
      status: 'deleted'
      }
      }
      await dispatchUpdatePersona(payload)
      dispatchFeatureSettings()
  }

  const onDeleteAction = ()=>{
    showModal(MODAL_TYPES.CONFIRMATION, {
      text:`Are you sure you want to delete ${persona?.name} ?`,
      buttontext:"Confirm",
  onConfirm:deleteGroup,
  onCancel:hideModal

    })    
  }
  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      
    <WizrButton
        icon={<EditIcon />}
        text='Edit'
        type='text'
        onClick={()=>{onEditAction()}}
        justifyContent='flex-start'
      />
      <WizrButton
        icon={<DeleteIcon />}
        text='Delete'
        type='text'
        onClick={()=>{onDeleteAction()}}
        justifyContent='flex-start'
      />
    </WizrFlexLayout>
  )
}

const PersonaActionPopOver = ({persona}:any): JSX.Element => {
  const navigate = useNavigate()
  return (
    <Popover
      content={
        <PersonaPopOver
        persona={persona}
        />
      }
      trigger='click'
    >
      <StyledButton icon={<MoreIcon />} text='' type='text' style={{height: '40px', width: '40px', borderRadius: '50px'}}/>
    </Popover>
  )
}

export default PersonaActionPopOver

const StyledButton = styled(WizrButton)`
border-radius: 50%;
width: 20px;
height: 20px;
padding-right: 8px;
justify-content: center;
align-items: center;
  /* Default styles */
  path {
    stroke: #999B9F; /* Default stroke color */
    transition: stroke 0.3s ease; /* Smooth transition for color change */
  }

  /* Hover styles */
  &:hover path {
    stroke: #1D1F22
  }
`;