import { useEffect } from 'react'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrText from '../components/WizrText'
import { dispatchAppDraft } from '../redux/actions/content'
import { useContentLoadingSelector, useappDataSelector } from '../redux/selectors/content'
import { Card, Spin } from 'antd'
import styled from 'styled-components'
import WizrButton from '../components/WizrButton'
import AIIcon from '../assets/icons/AIIcon'
import WizrTag from '../components/WizrTags'
import { useNavigate } from 'react-router'
import { useAuth } from '../authContext'

const StyledCard = styled(Card)`
display: flex;
width: 23%;
height: 67%;

flex-direction: row;

border-radius: 16px;
background: var(--generic-white, #FFF);
`
const ChatBotListing = (): JSX.Element => {
  const { userPermission, hasAcces } = useAuth()

  //   useEffect(() => {
  //     dispatchAppDraft()
  //   }, [])
  //   const data = useappDataSelector()
  const navigate = useNavigate()
  const appData = [
    { id: 1, chatBot_name: 'Browserstack chatbot', description: " All queries regarding various products and environments will be addressed" },
    { id: 2, chatBot_name: 'ABHI - Autosolve', description: "Addresses user queries based on policy documentation" },
    { id: 3, chatBot_name: 'P44 - Web Format-Large', description: "Test p44 web format-large austosolve" },
  ]


  const loading = useContentLoadingSelector()

  const onRunClick = (item: any) => {
    console.log('item', item);
    if(item?.id === 3){
      navigate(`/testEmbed`)
    }else{
      navigate(`/chatbot/${item?.id}`)
    }

  }
  
  return (
    <WizrFlexLayout
      style={{ padding: '40px', gap: '20px' }}
      textAlign='left'
      alignItems='flex-start'
      alignContent='flex-start'
      justifyContent='flex-start'
    >
      <WizrText type='h4'>Elevate with Wizr’s AI Toolbox</WizrText>
      <WizrText type='body2'>
        Drive into the future of AI innovation with Wizr’s curated selection of AI tools
      </WizrText>
      <WizrText type='h7'>Your Chat Bots</WizrText>
      <WizrFlexLayout>
        {loading ? (
          <Spin />
        ) : (
          <WizrFlexLayout
            flexDirection='row'
            style={{ gap: '15px', marginTop: '16px', paddingRight: '30px', maxHeight: '79%' }}
            justifyContent='flex-start'
          >
            {appData &&
              appData?.slice(0, 4).map((item: any, index: any) => (
                <StyledCard key={index}>
                  <WizrFlexLayout
                    style={{ gap: '15px', height: '100%', width: 'auto' }}
                    textAlign='left'
                    background='white'
                    alignItems='flex-start'
                    justifyContent='flex-start'
                  >
                    <AIIcon></AIIcon>
                    <WizrText type='sub2'>
                      {item?.chatBot_name}
                    </WizrText>
                    {/* <WizrText type='caption'>
                      Version{' '}
                      {item?.app_id !== 'a69ea027-0c27-4d61-ab22-6639ff8b6f67'
                        ? item?.app_version
                        : 'V2'}
                    </WizrText> */}
                    {/* <div>
                      <WizrTag type='colorfull' data={item?.tags} />
                    </div> */}
                    <WizrText type='body2'>{item?.description ? item?.description : ''}</WizrText>
                    <WizrButton
                      type='link'
                      text={
                        <WizrText type='button' textColor='black'>
                          RUN
                        </WizrText>
                      }
                      style={{
                        borderWidth: '1px',
                        borderColor: 'black',
                        borderRadius: '16px',
                        width: 'auto',
                      }}
                      //   disabled={hasAcces('history_app') === true ? false : true}
                      onClick={() => {
                        onRunClick(item)
                      }}
                    ></WizrButton>
                  </WizrFlexLayout>

                </StyledCard>
              ))}
          </WizrFlexLayout>
        )}
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}
export default ChatBotListing
