const SentIcon = ({stroke= '#1D1F22'}:any): JSX.Element => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.5 0.5V2.5" stroke={stroke} stroke-width="2"/>
<path d="M4.5 0.5V2.5" stroke={stroke} stroke-width="2"/>
<path d="M7.5 0.5V2.5" stroke={stroke} stroke-width="2"/>
<path d="M10.5 0.5V2.5" stroke={stroke} stroke-width="2"/>
<path d="M1.5 3.5V5.5" stroke={stroke} stroke-width="2"/>
<path d="M4.5 3.5V5.5" stroke={stroke} stroke-width="2"/>
<path d="M7.5 3.5V5.5" stroke={stroke} stroke-width="2"/>
<path d="M10.5 3.5V5.5" stroke={stroke} stroke-width="2"/>
<path d="M1.5 6.5V8.5" stroke={stroke} stroke-width="2"/>
<path d="M4.5 6.5V8.5" stroke={stroke} stroke-width="2"/>
<path d="M7.5 6.5V8.5" stroke={stroke} stroke-width="2"/>
<path d="M10.5 6.5V8.5" stroke={stroke} stroke-width="2"/>
</svg>
      
    )
  }
  export default SentIcon