const CustomApiIcon=():JSX.Element=>{
    return(
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="16" fill="#FADEFF"/>
<g clip-path="url(#clip0_10459_39151)">
<path d="M4.58594 15.9999L8.99994 11.5859L10.4139 12.9999L7.41394 15.9999L10.4139 18.9999L8.99994 20.4139L4.58594 15.9999ZM11.5859 8.99994L15.9999 4.58594L20.4139 8.99994L18.9999 10.4139L15.9999 7.41394L12.9999 10.4139L11.5859 8.99994ZM12.9999 21.5859L15.9999 24.5859L18.9999 21.5859L20.4139 22.9999L15.9999 27.4139L11.5859 22.9999L12.9999 21.5859ZM14.9979 17.0019V14.9979H17.0019V17.0019H14.9979ZM21.5859 18.9999L24.5859 15.9999L21.5859 12.9999L22.9999 11.5859L27.4139 15.9999L22.9999 20.4139L21.5859 18.9999Z" fill="#1D1F22"/>
</g>
<defs>
<clipPath id="clip0_10459_39151">
<rect width="24" height="24" fill="white" transform="translate(4 4)"/>
</clipPath>
</defs>
</svg>

    )
}

export default CustomApiIcon