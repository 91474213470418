import AIIcon from '../assets/icons/AIIcon'
import LogicIcon from '../assets/icons/LogicIcon'
import PuzzleIcon from '../assets/icons/PuzzleIcon'
import ExportIcon from '../assets/icons/ExportIcon'
import HelpIcon from '../assets/icons/HelpIcon'
import AlphabetIcon from '../assets/icons/AlphabetIcon'
import CodeIcon from '../assets/icons/CodeIcon'
import DeleteIcon from '../assets/icons/DeleteIcon'

const toolBarItems: any = [
  {
    key: 'ai',
    Icon: AIIcon,
    text: 'AI',
  },
  {
    key: 'logic',
    Icon: LogicIcon,
    text: 'Logic',
  },
  {
    key: 'addon',
    Icon: PuzzleIcon,
    text: 'AddOn',
  },
  {
    key: 'export',
    Icon: ExportIcon,
    text: 'Export',
  },
  {
    key: 'help',
    Icon: HelpIcon,
    text: 'Help',
  },
]

const edgeItems: any = [
  {
    key: 'ai',
    Icon: AIIcon,
    text: 'AI',
  },
  {
    key: 'logic',
    Icon: LogicIcon,
    text: 'Logic',
  },
  {
    key: 'addon',
    Icon: PuzzleIcon,
    text: 'AddOn',
  },
  {
    key: 'delete',
    Icon: DeleteIcon,
    text: 'Delete',
  },
]

// const startNodeTypes = [
//   {
//     text: 'Short Text',
//     key: 'shortText',
//     Icon: AlphabetIcon,
//   },
//   {
//     text: 'Long Text',
//     key: 'longText',
//     Icon: AlphabetIcon,
//   },
//   {
//     text: 'Single Select',
//     key: 'singleSelect',
//     Icon: AlphabetIcon,
//   },
//   {
//     text: 'Multi Select',
//     key: 'multiSelect',
//     Icon: AlphabetIcon,
//   },
//   {
//     text: 'File upload',
//     key: 'fileUpload',
//     Icon: AlphabetIcon,
//   },
// ]

const parameterOptions = [
  {
    label: 'Short text',
    value: 1,
  },
  {
    label: 'Long text',
    value: 2,
  },
  { label: 'File upload', value: 3 },
  {
    label: 'Single select',
    value: 4,
  },
  {
    label: 'Multi select',
    value: 5,
  },
  {
    label: 'Image',
    value: 6,
  },
  {
    label: 'Media upload',
    value: 7,
  },
]
const outputNodeTypes = [
  {
    text: 'Text',
    Icon: AlphabetIcon,
    key: 'text',
  },
  {
    text: 'Code',
    Icon: CodeIcon,
    key: 'code',
  },
]

const initialNodes: any = [
  {
    id: 'start',
    data: { label: 'Start' },
    type: 'start',
    position: { x: 0, y: 300 },
    // positionAbsolute: { x: -50.429577464788736, y: 300 },
  },
  {
    id: 'step_2',
    data: { label: 'llm Block'},
    type: 'llm',
    position: { x: 500, y: 300 },
    // positionAbsolute: { x: 461.37464788732393, y: 300 },
  },
  {
    id: 'end',
    data: { label: 'Output' },
    type: 'end',
    position: { x: 1000, y: 300 },
    // positionAbsolute: { x:1055.0295774647889, y: 300 },
  },
]

const initialEdges = [
  { id: 'edge-start-initial', source: 'start', target: 'step_2', type: 'buttonedge' },
  { id: 'edge-initial-end', source: 'step_2', target: 'end', type: 'buttonedge' },
]

export { toolBarItems, parameterOptions, outputNodeTypes, initialNodes, initialEdges, edgeItems }
