import WizrButton from '../../../components/WizrButton'
import WizrInput from '../../../components/WizrInput'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import CreateNew from '../../../assets/icons/CreateNew'
import WizrText from '../../../components/WizrText'
import styled from 'styled-components'
import { useState, useEffect } from 'react'
import WizrFlexSpacing from '../../../components/WizrFlexSpacing'
import ReactFlow, { useEdges, useNodes } from 'reactflow';
import WizrDropDown from '../../../components/WizrDropDown'
import { FilterValues, generateNodeSequenceResponse } from '../../../utils'
import WizrTag from '../../../components/WizrTags'

import { useForm, useFieldArray, Controller, set } from 'react-hook-form';
import DeleteIcon from '../../../assets/icons/DeleteIcon'
import { dispatchListFolders, dispatchVectorDb } from '../../../redux/actions/content'
import { useFaqDataSelector, usePastTicketDataSelector, useVectorDb } from '../../../redux/selectors/content'
import WizrTextArea from '../../../components/WizrTextArea'

const VectorBlockEdit = (props: any): JSX.Element => {
  const { id, data, updateNodeData, updateEdgeData, nodeUpdate, closeEditArea } = props;
  const nodes = useNodes();
  const edges = useEdges();

  const startnodedata: any = nodes && nodes[0]?.data;

  const [parameterList, setParameterList] = useState<any>([]);

  const vector_db_key = useVectorDb()
  

  useEffect(() => {
    const saveAppPayload = {
      edges: edges,
      nodes: nodes,
    };
    const sequence = generateNodeSequenceResponse(saveAppPayload);
    const trimmedNodeSequence = elementsBeforeSpecificElement(sequence, id);
    const defaultParameters = startnodedata?.config?.parameters.map((param: any) => param?.name) || []
    setParameterList([...defaultParameters, ...trimmedNodeSequence]);

  }, [nodes, edges, setParameterList, id]);

  function elementsBeforeSpecificElement(arr: any, specificElement: any) {
    const index = arr.indexOf(specificElement);
    if (index === -1) {
      return arr;
    }
    const newArray = arr.slice(0, index).slice(1).map((item: any) => {
      return `${item}.output`
    })
    return newArray
  }

  const onSaveAction = () => {
    // Your onSaveAction logic here

      const filterStructure = {
        $and: [
          {
            $and: [
              {
                [filterKey]: {
                  [`${filterModel}`]: filterValue,
                },
              },
            ],
          },
        ],
      }
const embeddingId = contentValue?.find((item: any) => item.folder_id === content)


    const config = {
      content: content,
      top_k: parseInt(top_k),
      match_threshold: parseFloat(match_threshold),
      user_query: user_query,
      filter: filterStructure,     
      embedding_model_id: embeddingId?.embedding_model_id 
      //vector_db_key: vector_db_key?.id || "a3391b2a-806a-4b6a-8e52-f5d3259319f5"
    }

    updateNodeData((nds: any) =>
    nds.map((node: any) => {
      if (node.id === id) {
        node.data = {
          ...node.data,
          config
        }
      }
      return node
    }),
  )
  
    closeEditArea()
  }

  const onDeleteNodeDetail = (e: any) => {
    // Your onDeleteNodeDetail logic here
  }

  useEffect(() => {
    if (nodeUpdate === 'vector') {
      onSaveAction()
    }
  }, [nodeUpdate])

  useEffect(() => {
    dispatchVectorDb()
  }, [])

  // useEffect(() => {
  //   extractFilterInfo(filterStructure);
  // }, [filterStructure]);
useEffect(() => {
  {data?.label==='Past Ticket Search Block'?
    dispatchListFolders('past_tickets'):
    dispatchListFolders('faq')
  }

},[data?.label])

const dropDownConverter = (value: string[]) => {
  const result =
    value?.length > 0 &&
    value?.map((item: any, key: number) => ({
      label: item?.folder_name,
      value: item?.folder_id,
    }))
  return result
}

  const contentValue = data?.label==='Past Ticket Search Block'?usePastTicketDataSelector():useFaqDataSelector()
  const extractFilterInfo = (structure: any) => {
    const andClause = structure?.$and[0]?.$and[0];
    if(andClause){
      const keys = Object.keys(andClause);
      if (keys.length === 1) {
        const key = keys[0];
        const filterInfo = andClause[key];
        const filterModel = Object.keys(filterInfo)[0];
        const filterValue = filterInfo[filterModel];
        setFilterKey(key);
        setFilterModel(filterModel);
        setFilterValue(filterValue);
      }
    }
  };

  const [content, setContent] = useState('faq')
  const [top_k, setTop_k] = useState('')
  const [match_threshold, setMatch_threshold] = useState('')
  const [user_query, setUser_query] = useState('')
  const [filterKey, setFilterKey] = useState('')
  const [filterValue, setFilterValue] = useState('')
  const [filterModel, setFilterModel] = useState('$eq')

  useEffect(() => {

    setContent(data?.config?.content)
    setTop_k(data?.config?.top_k)
    setMatch_threshold(data?.config?.match_threshold)
    setUser_query(data?.config?.user_query || '')
    extractFilterInfo(data?.config?.filter);
    
  }, [data])

  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const handleCursorChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCursorPosition(event.target.selectionStart);
  };

  return (
    <ContentContainer alignItems='flex-start' background='#fff'>
      {/* <WizrFlexLayout justifyContent='space-between'  alignItems='center'> */}
      <IndividualSettingsBox>
        <WizrText textColor='#707276' type='body2'>
        {data?.label==='Past Ticket Search Block'?'Past Tickets':'FAQ'}
        </WizrText>
        <IndividualSettingsBox>
          {/* <WizrInput
            placeholder='content'
            value={content}
            // disabled={true}
          onChange={(value: any) => {
            setContent(value)
          }}
          /> */}
          <WizrDropDown
            options={dropDownConverter(contentValue)}
            value={content}
            onChange={(value: any) => setContent(value)}
          />
        </IndividualSettingsBox>
      </IndividualSettingsBox>
      <IndividualSettingsBox>
        <WizrText textColor='#707276' type='body2'>
          Top results (k)
        </WizrText>
        <IndividualSettingsBox>
          <WizrInput
            placeholder='Name'
            value={top_k}
            type='number'
            onChange={(value: any) => {
              setTop_k(value)
            }}
          />
        </IndividualSettingsBox>
      </IndividualSettingsBox>
      <WizrFlexLayout flex='none' background='white'>
        <IndividualSettingsBox>
          <WizrText textColor='#707276' type='body2'>
            Match threshold
          </WizrText>
          <WizrInput
            placeholder='Name'
            value={match_threshold}
            onChange={(value: any) => {
              setMatch_threshold(value)
            }}
          />
        </IndividualSettingsBox>
        <IndividualSettingsBox>
          <WizrText textColor='#707276' type='body2'>
            User query
          </WizrText>
          <WizrInput
            placeholder='Name'
            value={user_query}
            onChange={(value: any) => {
              setUser_query(value)
            }}
            onSelect={handleCursorChange}

          />
        </IndividualSettingsBox>
        <StyledFlexSpacing justifyContent='space-between' flexDirection='column' alignItems='center' size={20} background='white'>
          {/* <StyledWizrTag
            background="grey"
            data={parameterList}
            onClick={(e: any) => {
              setUser_query(`{{${e}}}`);
              // setValue(`visionBlock.${index}.file`, `{{${e}}}`);
            }}
          /> */}
           <StyledWizrTag
              background="grey"
              data={parameterList}
              onClick={(e: any) => {
                const clickedTagValue = e; // Replace this with the actual way to get the clicked tag value
                if (cursorPosition !== null) {
                  // Insert the clicked tag value at the cursor position
                  const updatedSystemMessage =
                    `${user_query.slice(0, cursorPosition)}{{${clickedTagValue}}}${user_query.slice(cursorPosition)}`
                    setUser_query(updatedSystemMessage)

                  // Update the systemMessage state with the modified value

                  // Move the cursor position to the end of the inserted value
                  setCursorPosition(cursorPosition + clickedTagValue.length + 4);
                }
              }}
            />
        </StyledFlexSpacing>
      </WizrFlexLayout>
      {/* <WizrFlexLayout justifyContent='space-between' alignItems='flex-start' style={{ padding: ' 10px 0px' }} background='white'>
        <StyledWizrButton type='text' icon={<CreateNew fill='black' />} text='Metadata filter' onClick={handleMetadataFilterClick}/>
      </WizrFlexLayout> */}
      {/* { metadataFilterClicks > 0 ? <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((field, index) => (
      <WizrFlexLayout flexDirection='row' justifyContent='flex-start' alignItems='flex-start' style={{ gap: '10px', margin: '5px 0px' }} key={index} background='#fff'>
        <WizrButton type='text' icon={<DeleteIcon />} onClick={() => remove(index)} style={{ minHeight: '50px' }} />
        <Controller
          render={({ field }) => <WizrInput {...field} />}
          name={`vectorBlock.${index}.field1`}
          control={control}
        />
                <Controller
          render={({ field }) => <WizrInput {...field} />}
          name={`vectorBlock.${index}.field2`}
          control={control}
        />
                <Controller
          render={({ field }) => <WizrInput {...field} />}
          name={`vectorBlock.${index}.field3`}
          control={control}
        />
                <Controller
          render={({ field }) => <WizrInput {...field} />}
          name={`vectorBlock.${index}.field4`}
          control={control}
        />
      </WizrFlexLayout>
      ))}
    </form> : <></>} */}
      <IndividualSettingsBox>
        <WizrText type='sub2' >Filters</WizrText>
      </IndividualSettingsBox>

      <WizrFlexLayout flexDirection='row' justifyContent='flex-start' alignItems='flex-start' style={{ gap: '10px', margin: '5px 0px' }} background='#fff'>
        <WizrInput
          placeholder='Key'
          value={filterKey}
            onChange={(value: any) => {
              setFilterKey(value)
            }}
        />
        <StyledDropDown
          placeHolder='Model'
          options={FilterValues}
          onChange={(value: any) => setFilterModel(value)}
          value={filterModel}
        />
        <WizrInput
          placeholder='value'
          value={filterValue}
          onChange={(value: any) => {
            setFilterValue(value)
          }}
        />

      </WizrFlexLayout>
      <StyledFlexSpacing justifyContent='space-between' flexDirection='column' alignItems='center' size={20} background='white'>
        <StyledWizrTag
          background="grey"
          data={parameterList}
        onClick={(e: any) => {
          setFilterValue(`{{${e}}}`);
        }}
        />
      </StyledFlexSpacing>
    </ContentContainer>
  )
}

const StyledWizrButton = styled(WizrButton)`
`

const ContentContainer = styled(WizrFlexLayout)`
padding: 15px;
border-radius: 10px;
margin-bottom: 20px;
overflow: auto; /* Enable vertical scrolling */
max-height: 100vh; /* Set a maximum height to fit within the viewport *
gap: 10px
`

const StyledWizrTag = styled(WizrTag)`
  height: 42px;
  padding: 8px 11px;
  justify-content: space-between;
`

const StyledFlexSpacing = styled(WizrFlexSpacing)`
  .ant-space-item {
    display: flex !important;
    width: 100% !important;
    flex-wrap: wrap;
  }
`
const IndividualSettingsBox = styled.div`
  width: 100%;
  text-align: left;
  height: auto;
  margin: 0px 0px 0px 0px;
  background-color:white;
  padding:2px;
  
`
const DropdownBox = styled.div`
height: 100%
`

const StyledDropDown = styled(WizrDropDown)`
 height:52px;
.ant-select-selector{
    height:52px !important;
    background-color:#F2F4F9 !important;
   .ant-select-selection-placeholder{
    margin-top:10px;
   }
   .ant-select-selection-item{
    margin:10px;
   }
}
`



export default VectorBlockEdit;
