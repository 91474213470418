import styled from 'styled-components'
import { Collapse, Popover } from 'antd'
import WizrFlexLayout from '../../../components/WizrFlexLayout'
import { toolBarItems } from '../../../utils/appDesignConfigs'
import WizrText from '../../../components/WizrText'
import WizrButton from '../../../components/WizrButton'
import SettingsIcon from '../../../assets/icons/Setting'
import PlayIcon from '../../../assets/icons/PlayButton'
import ReactFlow, { useNodesState, useEdgesState, addEdge, Panel, Controls } from 'reactflow'
import AIIcon from '../../../assets/icons/AIIcon'
import FolderActionPopOver from '../../../components/popovers/FolderAction'
import { MODAL_TYPES, useWizrModalContext } from '../../../providers/modal/WizrModalProvider'
import AIModelIcon from '../../../assets/icons/AIModelIcon'
import Voice from '../../../assets/icons/Voice'
import Code from '../../../assets/icons/Code'
import IF from '../../../assets/icons/IF'
import { icon } from '../../../icons/svgr.config'
import Iternation from '../../../assets/icons/Iteration'
import App from '../../../assets/icons/App'
import AIPlugins from '../../../assets/icons/AIPlugins'

const StyledCollapse = styled(Collapse)`
  .ant-collapse {
    background-color: white !important;
  }
`
const ToolBoxContainer = styled.div`
flexDirection=row
justifyContent=space-between
`

const ToolBarItemContainer = styled(WizrFlexLayout)`
  margin: 8px;
  flex: none;
  gap: 8px;
`

const ToolBar = ({ onToolBarItemClick }: any): JSX.Element => {


  return (
    <WizrFlexLayout
    style={{ padding: '5px', maxHeight: '70px' ,maxWidth:"350px",marginLeft:"10px"}}
    flexDirection='row'
    justifyContent='space-between'
  
  >
      {toolBarItems?.map((item: any, key: any) => {
        return (
        <WizrFlexLayout  key={key} style={{maxWidth: '55px', alignItems: 'start'}} >
          <Popover content={<ToolbarContent content={item} onCreate={onToolBarItemClick}/>} title='' trigger='click'>
            <WizrButton style={{backgroundColor:"white" , borderRadius:"60px",width:"40px",height:"40px",paddingLeft:"20px"}} type='link' icon={<item.Icon />} onClick={()=>{console.log("clicked")}} />
            <WizrFlexLayout style={{paddingRight:"12px"}}>
            <WizrText type='caption'textColor='#1D1F22'>{item.text}</WizrText>
            </WizrFlexLayout>
          </Popover>
        </WizrFlexLayout>
        )
      }
      )}     
  </WizrFlexLayout>
  )
}

export default ToolBar

const ToolbarContent = ({content, onCreate}: any): JSX.Element => {

  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
      {content.key === 'ai' ? (
        <>
          <WizrButton text='LLM' type='text' onClick={() => onCreate('llm')} justifyContent='flex-start' icon={<AIModelIcon/>}/>
          <WizrButton text='Vision' type='text' onClick={() => onCreate('vision')} justifyContent='flex-start' icon={<Voice/>}/>
          <WizrButton text='Voice' type='text' onClick={() => onCreate('transcription')} justifyContent='flex-start' icon={<Voice/>}/>
        </>
      ) : <></>}
      {content.key === 'logic' ? (
        <>
          <WizrButton text='Code' type='text' onClick={() => onCreate('code')} justifyContent='flex-start' icon={<Code/>}/>
          <WizrButton text='Condition' type='text' onClick={() => {}} justifyContent='flex-start' icon={<IF/>}/>
          <WizrButton text='Iteration' type='text' onClick={() => {}} justifyContent='flex-start' icon={<Iternation/>} />
        </>
      ) : <></>}
      {content.key === 'addon' ? (
        <>
          <WizrButton text='Api' type='text' onClick={() => onCreate('api')} justifyContent='flex-start' icon={<AIIcon/>}/>
          <WizrButton text='Past Tickets Search' type='text' onClick={() => onCreate('Past Ticket Search')} justifyContent='flex-start' icon={<App/>}/>
          <WizrButton text='FAQ Search' type='text' onClick={() => onCreate('FAQ Search')} justifyContent='flex-start' icon={<App/>}/>
          <WizrButton text='Ai Plugins' type='text' onClick={() => {}} justifyContent='flex-start' icon={<AIPlugins/>}/>
        </>
      ) : <></>}
      {content.key === 'export' ? (
        <></>
      ) : <></>}
      {content.key === 'help' ? (
        <></>
      ) : <></>}
    </WizrFlexLayout>
  )
}
