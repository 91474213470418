import React from 'react';

import { Card } from 'antd';
import styled from 'styled-components';
import WizrText from '../../../../../components/WizrText';
import Questions from '../../../../../assets/icons/Questions';

const StyledCard = styled(Card)<{ width: boolean }>`
  border-radius: 16px;
  background-color: #f2f4f9;
  width: ${({ width }) => (width ? '49%' : '100%')};
  .ant-card-body {
    padding: 0px;
    border-radius: 16px;
    background-color: #f2f4f9;
  }
`;

const QuestionCards = ({ questions, setUserInput, testWithQuery, width }: any) => {
  // Determine chunk size based on condition
  
  const chunkSize = width > 700 ? 2 : 1;
  console.log('width', width);
  console.log('chunkSize', chunkSize);
  // Chunk the questions based on the condition
  const chunkedQuestions = questions.reduce((resultArray: any, item: any, index: any) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);

  // Determine card width based on the condition

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '8px',
        padding: width>700 ?'7px ': width>500?'0px 40px' : '0px 20px',
      }}
    >
      {chunkedQuestions.map((row: any, rowIndex: any) => (
        <div
          key={rowIndex}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '4px',
            flexWrap: 'wrap', // Allow wrapping of cards
          }}
        >
          {row.map((question: any, colIndex: any) => (
            <StyledCard
              key={`${rowIndex}-${colIndex}`}
              hoverable
              width = {width>700}
            >
              <div
                onClick={() => {
                  setUserInput(question);
                  testWithQuery(question);
                }}
                style={{
                  display: 'flex',
                  background: '#F2F4F9',
                  padding: '8px',
                  borderRadius: '16px',
                  textAlign: 'left',
                  gap: '4px',
                  cursor: 'pointer',
                  width: '100%',
                }}
              >
                <div>
                  <Questions />
                </div>
                <WizrText type="body3">
                  {question}
                </WizrText>
              </div>
            </StyledCard>
          ))}
        </div>
      ))}
    </div>
  );
};

export default QuestionCards;
