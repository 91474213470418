import {
    PieChart, Pie, Legend, Tooltip, Cell, BarChart,
    Bar,
    XAxis,
    YAxis,
    ReferenceLine,
    Label,
    Text
} from "recharts";
import WizrFlexLayout from "../../../components/WizrFlexLayout";
import WizrText from "../../../components/WizrText";
import styled from "styled-components";
import { Card } from "antd";
import WizrTag from "../../../components/WizrTags";

const StyledTicketCard = styled(Card) <any>`
  background-color: #ffffff;
  width: 100%;
  .ant-card-meta-detail {
    border-bottom: none;
    border-top: none;
  }
  .ant-card-head {
    border-bottom: none;
    margin-bottom: 4px;
    text-align: start
  }
  .ant-card-body {
    text-align: start;
    flex-direction: column !important;
    display: flex;
    padding: 0px 24px 24px 24px;
    line-Height: 48px !important;
  }
`


const WizrImpactScreen = ({ onClickIntentsandsentiments, range, interval, pieData }: any) => {

    const data02 = [
        { name: "Group A", value: 1141 },
        { name: "Group B", value: 2118 },
    ];

    const data01 = [
        { name: "Group A", value: 1141 },
        { name: "Group B", value: 2118 },
    ];

    const COLORS02 = ["#22C55E", "#D9D9D9"];
    const COLORS01 = ["#D9D9D9", "#FBBF24"];

    const data = [
        { name: "Apr 23", uv: 400, pv: 200, qv: 110, zv: 200 },
        { name: "May 23", uv: 300, pv: 140, qv: 150, zv: 100 },
        { name: "Jun 23", uv: 200, pv: 120, qv: 110, zv: 130 },
        { name: "Jul 23", uv: 278, pv: 100, qv: 125, zv: 120 },
        { name: "Aug 23", uv: 189, pv: 200, qv: 175, zv: 320 },
        { name: "Sep 23", uv: 239, pv: 250, qv: 120, zv: 120 },
        { name: "Oct 23", uv: 349, pv: 125, qv: 130, zv: 240 },
        { name: "Nov 23", uv: 349, pv: 240, qv: 125, zv: 180 },
        { name: "Dec 23", uv: 349, pv: 130, qv: 173, zv: 150 },
        { name: "Jan 24", uv: 349, pv: 250, qv: 143, zv: 250 },
        { name: "Feb 24", uv: 349, pv: 230, qv: 134, zv: 120 },
        { name: "Mar 24", uv: 349, pv: 140, qv: 134, zv: 180 },
    ];

    const Customersatisfaction = [
        { name: "Apr 23", uv: 40, },
        { name: "May 23", uv: 55, },
        { name: "Jun 23", uv: 43, },
        { name: "Jul 23", uv: 48, },
        { name: "Aug 23", uv: 55, },
        { name: "Sep 23", uv: 58, },
        { name: "Oct 23", uv: 54, },
        { name: "Nov 23", uv: 54, },
        { name: "Dec 23", uv: 64, },
        { name: "Jan 24", uv: 68, },
        { name: "Feb 24", uv: 70, },
        { name: "Mar 24", uv: 79, },
    ];

    const ticketResolvedRate = [
        { name: "Aug 23", Manual: 999, Agent_assist: 0, Auto_solve: 0, zv: 0 },
        { name: "Sep 23", Manual: 700, Agent_assist: 150, Auto_solve: 150, zv: 120 },
        { name: "Oct 23", Manual: 600, Agent_assist: 150, Auto_solve: 250, zv: 240 },
        { name: "Nov 23", Manual: 500, Agent_assist: 240, Auto_solve: 260, zv: 180 },
        { name: "Dec 23", Manual: 400, Agent_assist: 430, Auto_solve: 170 , zv: 150 },
        { name: "Jan 24", Manual: 400, Agent_assist: 250, Auto_solve: 350, zv: 250 },
        { name: "Feb 24", Manual: 300, Agent_assist: 430, Auto_solve: 270, zv: 120 },
        { name: "Mar 24", Manual: 200, Agent_assist: 540, Auto_solve: 260, zv: 180 },
    ];

    const referenceIndex = data.findIndex((entry) => entry.name === "Sep 23");


    const CustomBar = (props: any) => {
        const { x, y, width, height, referenceIndex } = props;

        const fill =
            props.index < referenceIndex ? "rgba(34, 197, 94, 0.6)" : "#22C55E";
        return <rect x={x} y={y} width={35} height={height} fill={fill} />;
    };

    const CustomBar2 = (props: any) => {
        const { x, y, width, height, value, referenceIndex } = props;

        const fill =
            props.index < referenceIndex ? "rgba(251, 191, 36, 0.6)" : "#FBBF24"
        return <rect x={x} y={y} width={35} height={height} fill={fill} />;
    };

    const CustomBar3 = (props: any) => {
        const { x, y, width, height, referenceIndex } = props;

        const fill =
            props.index < referenceIndex ? "rgba(245, 158, 11, 0.6)" : "#F59E0B";
        return <rect x={x} y={y} width={35} height={height} fill={fill} />;
    };

    const CustomBar4 = (props: any) => {
        const { x, y, width, height, referenceIndex } = props;

        const fill =
            props.index < referenceIndex ? "rgba(239, 68, 68, 0.6)" : "#EF4444";
        return <rect x={x} y={y} width={35} height={height} fill={fill} />;
    };

    const CustomerSatisfactionBar = (props: any) => {

        const { x, y, width, height, value, referenceIndex } = props;
        const firstValue = value[1]


        let fill = "";
        if (firstValue < 50) {
            fill = props.index < referenceIndex ? "rgba(239, 68, 68, 0.6)" : "#EF4444";
        } else if (firstValue >= 50 && firstValue <= 60) {
            fill = props.index < referenceIndex ? "rgba(251, 191, 36, 0.6)" : "#FBBF24";
        } else {
            fill = props.index < referenceIndex ? "rgba(34, 197, 94, 0.6)" : "#22C55E"
        }
        return <rect x={x} y={y} width={35} height={height} fill={fill} />;
    };

    const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];


    const CustomizedAxisTick = ({ x, y, payload }: any) => {
        return (
            <Text x={x - 28} y={y - 10} width={100} textAnchor="middle" verticalAnchor="start">
                {payload.value}
            </Text>
        );
    };

    const ticketReferenceIndex = ticketResolvedRate.findIndex(
        (entry) => entry.name === "Sep 23"
    );

    return (
        <WizrFlexLayout style={{ gap: '16px' }}>
            <WizrFlexLayout
                justifyContent='space-between'
                alignItems='stretch'
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    textAlign: 'left',
                    justifyContent: 'flex-start',
                    gap: '30px',
                    overflowX: 'auto',
                    padding: '8px'
                }}
            >
                <StyledTicketCard
                    title={<WizrFlexLayout alignItems="flex-start" style={{ padding: '16px 0px' }} background="white"><WizrText type="sub2">CSAT Score</WizrText><WizrTag background="#22C55E" data={['Good']} borderradius='4px' /></WizrFlexLayout>}
                    bordered={false} style={{ flexGrow: 1 }} hoverable>
                    <WizrFlexLayout background="#FFF" alignItems="flex-start">
                        <WizrText type='body2' textColor='#22C55E' style={{ fontSize: '48px', lineHeight: '48px' }}> 80</WizrText>
                        <WizrText type='body2'>vs 67 baseline</WizrText>
                    </WizrFlexLayout>
                </StyledTicketCard>
                <StyledTicketCard
                    title={<WizrFlexLayout alignItems="flex-start" style={{ padding: '16px 0px' }} background="white"><WizrText type="sub2">Average Time to Resolution</WizrText><WizrTag background="#22C55E" data={['Good']} borderradius='4px' /></WizrFlexLayout>}
                    bordered={false} style={{ flexGrow: 1 }} hoverable>
                    <WizrFlexLayout background="#FFF" alignItems="flex-start">
                        <WizrText type='body2' textColor='#22C55E' style={{ fontSize: '48px', lineHeight: '48px' }}> 4 hrs</WizrText>
                        <WizrText type='body2'>vs 16 hrs baseline</WizrText>
                    </WizrFlexLayout>
                </StyledTicketCard>
                <StyledTicketCard
                    title={<WizrFlexLayout alignItems="flex-start" justifyContent="start" flexDirection="row" style={{ padding: '16px 0px' }} background="white"><WizrText type="sub2">Auto Solve Rate - </WizrText>
                        <WizrText type="sub2" textColor='#22C55E'>35 % </WizrText></WizrFlexLayout>}
                    bordered={false} style={{ flexGrow: 1 }} hoverable>
                    <WizrFlexLayout background="#FFF" alignItems="flex-start">
                        <PieChart width={239} height={110}>
                            <Pie
                                dataKey="value"
                                data={data02}
                                cx={50}
                                cy={50}
                                innerRadius={6}
                                outerRadius={50}
                            >
                                {data02.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS02[index % COLORS02.length]} />
                                ))}
                            </Pie>
                            {/* <Tooltip /> */}
                            <Legend align="right" layout="vertical" />
                        </PieChart>
                    </WizrFlexLayout>
                </StyledTicketCard>
                <StyledTicketCard
                    title={<WizrFlexLayout alignItems="flex-start" justifyContent="start" flexDirection="row" style={{ padding: '16px 0px' }} background="white"><WizrText type="sub2">Agent Assist Rate - </WizrText>
                        <WizrText type="sub2" textColor='#FBBF24'>72 % </WizrText></WizrFlexLayout>}
                    bordered={false} style={{ flexGrow: 1 }} hoverable>
                    <WizrFlexLayout background="#FFF" alignItems="flex-start">
                        <PieChart width={239} height={110}>
                            <Pie
                                dataKey="value"
                                data={data01}
                                cx={50}
                                cy={50}
                                innerRadius={6}
                                outerRadius={50}
                            >
                                {data02.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS01[index % COLORS01.length]} />
                                ))}
                            </Pie>
                            {/* <Tooltip /> */}
                            <Legend align="right" layout="vertical" />
                        </PieChart>
                    </WizrFlexLayout>
                </StyledTicketCard>
            </WizrFlexLayout>
            <WizrFlexLayout background="#FFF" alignItems="flex-start" style={{ padding: '16px', gap: '24px' }}>
                <WizrText type="sub2">Average Ticket Resolution Time</WizrText>
                <BarChart width={1300} height={350} data={data}>
                    <XAxis dataKey="name" axisLine={{ stroke: '#D9D9D9' }} tickLine={false} />
                    <YAxis
                        label={{ value: 'Number of tickets', angle: -90, position: 'insideLeft' }}
                        domain={[0, 1000]}
                        tickCount={11}
                        ticks={[0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]}
                        axisLine={{ stroke: '#D9D9D9' }}
                        tick={{ stroke: '#5D5F62', strokeWidth: 0 }}
                        tickLine={false}
                    />
                    <Legend />
                    <Bar
                        dataKey="uv"
                        stackId="a"
                        fill="#EF4444"
                        shape={<CustomBar referenceIndex={referenceIndex} />}
                        barSize={35}
                    />
                    <Bar
                        dataKey="pv"
                        stackId="a"
                        fill="#F59E0B"
                        shape={<CustomBar2 referenceIndex={referenceIndex} />}
                        barSize={35}
                    />
                    <Bar
                        dataKey="qv"
                        stackId="a"
                        fill="#FBBF24"
                        shape={<CustomBar3 referenceIndex={referenceIndex} />}
                        barSize={35}
                    />
                    <Bar
                        dataKey="zv"
                        stackId="a"
                        fill="#22C55E"
                        shape={<CustomBar4 referenceIndex={referenceIndex} />}
                        barSize={35}
                    />
                    <ReferenceLine
                        x="Sep 23"
                        stroke="#999B9F"
                        strokeDasharray="3 3"
                        position="start"
                    >
                        <Label value="Before" offset={10} position="insideTopRight" />
                        <Label value="After" offset={10} position="insideTopLeft" />
                    </ReferenceLine>
                </BarChart>
            </WizrFlexLayout>
            <WizrFlexLayout flexDirection="row" style={{ gap: '16px' }}>
                <WizrFlexLayout background="#FFF" alignItems="flex-start" style={{ padding: '16px', gap: '24px' }}>
                    <WizrText type="sub2">Customer Satisfaction</WizrText>
                    <BarChart width={632} height={350} data={Customersatisfaction}>
                        <XAxis
                            dataKey="name"
                            axisLine={{ stroke: '#D9D9D9' }}
                            angle={-45}
                            tickLine={false}
                            tickMargin={15}
                            height={40}
                        // textAnchor="left"
                        />
                        <YAxis
                            label={{ value: 'Sentiment Score', angle: -90, position: 'insideLeft' }}
                            domain={[0, 100]}
                            tickCount={11}
                            ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                            axisLine={{ stroke: '#D9D9D9' }}
                            tick={{ stroke: '#5D5F62', strokeWidth: 0 }}
                            tickLine={false}
                        />
                        <Bar
                            dataKey="uv"
                            stackId="a"
                            shape={<CustomerSatisfactionBar referenceIndex={referenceIndex} />}
                            barSize={35}
                        >
                        </Bar>
                        <ReferenceLine
                            x="Sep 23"
                            stroke="#999B9F"
                            strokeDasharray="3 3"
                            position="start"
                        >
                            <Label value="Before (60%)" offset={10} position="insideTopRight" />
                            <Label value="After (85%)" offset={10} position="insideTopLeft" />
                        </ReferenceLine>
                    </BarChart>
                </WizrFlexLayout>
                <WizrFlexLayout background="#FFF" alignItems="flex-start" style={{ padding: '16px' }}>
                    <WizrText type="sub2">Ticket Resolved Rate</WizrText>
                    <BarChart
                        width={632}
                        height={350}
                        data={ticketResolvedRate}
                        layout="vertical" // Set layout to vertical
                        margin={{ top: 20, right: 30, left: 30, bottom: 5 }}
                    >
                        <XAxis type="number" stroke="transparent" />
                        <YAxis
                            dataKey="name"
                            type="category"
                            stroke="transparent"
                            tick={<CustomizedAxisTick />}
                            reversed
                        />
                        <Bar
                            dataKey="Manual"
                            fill="#EF4444"
                            label={{ position: "right" }}
                            barSize={25}
                            stackId="a"
                        >
                            {ticketResolvedRate.map((entry: any, index: any) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={index < ticketReferenceIndex ? "rgba(239, 68, 68, 0.6)" : "#EF4444"}
                                />
                            ))}
                        </Bar>
                        <Bar
                            dataKey="Agent_assist"
                            fill="#FBBF24"
                            label={{ position: "right" }}
                            barSize={25}
                            stackId="a"
                        >
                            {ticketResolvedRate.map((entry: any, index: any) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={
                                        index < ticketReferenceIndex ? "rgba(251, 191, 36, 0.6)" : "#FBBF24"
                                    }
                                />
                            ))}
                        </Bar>
                        <Bar
                            dataKey="Auto_solve"
                            fill="#22C55E"
                            label={{ position: "right" }}
                            barSize={25}
                            stackId="a"
                        >
                            {ticketResolvedRate.map((entry: any, index: any) => (

                                <Cell
                                    key={`cell-${index}`}
                                    fill={index < ticketReferenceIndex ? "rgba(34, 197, 94, 0.6)" : "#22C55E"}
                                />
                            ))}
                        </Bar>
                        <Legend />
                        <ReferenceLine
                            y="Sep 23" // Adjust the reference line position
                            stroke="#999B9F"
                            strokeDasharray="3 3"
                            position="end"
                        >
                            {/* <Label value="After" offset={-10} position="insideTopRight" angle={90} startOffset={10}/> */}
                            {/* <Label
                                value="Before1"
                                offset={0}
                                position="insideBottomRight"
                                angle={90}
                                style={{padding: '100px'}}
                            /> */}
                        </ReferenceLine>
                    </BarChart>
                </WizrFlexLayout>
            </WizrFlexLayout>

        </WizrFlexLayout>
    );
}

export default WizrImpactScreen;