import styled from 'styled-components'
import { Avatar, Button, Input, Spin, message as antdMessage } from 'antd'

import { useEffect, useRef, useState } from 'react'

import Markdown from 'react-markdown'

import copy from 'copy-to-clipboard'

import { v4 as uuidv4 } from 'uuid'
import { set } from 'react-hook-form'

import { FileImageOutlined, UploadOutlined } from '@ant-design/icons'

import { useNavigate, useParams } from 'react-router-dom'
import { Console } from 'console'
import WizrFlexLayout from '../../../../components/WizrFlexLayout'
import WizrText from '../../../../components/WizrText'
import WizrButton from '../../../../components/WizrButton'
import {
  dispatchAutoSolveBot,
  dispatchBotDetails,
  dispatchChatMessage,
  dispatchChatMessageState,
  dispatchClearChat,
  dispatchClearChatBotHistory,
} from '../../../../redux/actions/content'
import {
  useBotDetailsSelector,
  useChatBotHistorySelector,
  useChatMessageSelector,
  useContentLoadingSelector,
} from '../../../../redux/selectors/content'
import Minimize from '../../../../assets/icons/Minimize'
import Maximize from '../../../../assets/icons/Maximize'
import CloseIconOutlined from '../../../../assets/icons/CloseIconOutlined'
import RobotIcon from '../../../../assets/icons/robot'
import RefreshIcon from '../../../../assets/icons/refresh'
import PdflogoIcon from '../../../../assets/icons/Pdflogo'
import Copy from '../../../../assets/icons/Copy'
import DislikeIcon from '../../../../assets/icons/DislikeIcon'
import LikeIcon from '../../../../assets/icons/LikeIcon'
import WizrUploadContainer from '../../../../components/WizrUploadButton'
import SentIcon from '../../../../assets/icons/sent'
import WizrUserJourney from '../../../../components/WizrUserJourney'
import Hamburger from '../../../../assets/icons/Hamburger'
import WizrIconWhite from '../../../../assets/icons/WizrIconWhite'
import ChatBotViewIcon from '../../../../assets/icons/ChatBotViewIcon'
import WizrChatIcon from '../../../../assets/icons/WizrChatIcon'
import { getCurrentTimeChatBot } from '../../../../utils'
import MicroPhone from '../../../../assets/icons/MicroPhone'
import Link from '../../../../assets/icons/Link'
import ImageIcon from '../../../../assets/icons/ImageIcon'
import MoreIcon from '../../../../assets/icons/More'
import WizrMarkDown from '../../../../components/WizrMarkDown'

const BouncingDotsLoader = (props: any) => {
  return (
    <div className='bouncing-loader'>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
const { TextArea } = Input

const StyledFlexLayout = styled(WizrFlexLayout)<{ view: boolean }>`
position: "relative";
gap: '16px;
width: ${(p) => (p.view ? '100%' : '55%')};
left: ${(p) => (p.view ? '0%' : '25%')};
`

const StyledChatWrapper = styled.div<{ view: boolean }>`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    36.25deg,
    #02f5db 6.26%,
    #00bdfc 46.03%,
    #568ffe 76.44%,
    #a36bfe 105.33%
  );
  width: ${(p) => (p.view ? '100%' : '42%')};
  justify-content: center !important;
  border-radius: 16px;
  padding: 0px 10px 0px 10px;
`

const StyledChatHeader = styled.div`
  display: flex;
  color: black;
  text-align: left;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  padding: 0px 10px 10px 10px;
  background: linear-gradient(
    36.25deg,
    #02f5db 6.26%,
    #00bdfc 46.03%,
    #568ffe 76.44%,
    #a36bfe 105.33%
  );
  gap: 10px;
`

const StyledChatContainer = styled.div<{ bulkRun?: boolean }>`
  position: relative;
  flex: 1;
  border-radius: 16px;
  padding: 0px 12px 12px 6px;
  background-color: #ffffff;
  align-content: flex-start;
  scrollbar-width: thin !important;
  scrollbar-color: #00c8ca white !important;

  &::-webkit-scrollbar {
    width: 8px !important; /* Width of the vertical scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: red !important; /* Color of the scrollbar thumb */
    border-radius: 4px !important; /* Radius of the scrollbar thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: red !important; /* Color of the scrollbar thumb on hover */
  }

  &::-webkit-scrollbar-track {
    background-color: white !important; /* Color of the scrollbar track */
  }
`


const StyledMessage = styled.div<{ isSent: boolean }>`
  display: flex;
  flex-direction: 'row';
  margin-bottom: 10px;
`

const StyledMessageBubble = styled.div<{ isSent: boolean }>`
  max-width: 90%;
  padding-left: 16px;
  padding-right: 10px;
  display: flex;
  text-align: ${({ isSent }) => (isSent ? 'right' : 'left')};
  flex-direction: column;
  align-items: ${({ isSent }) => (isSent ? 'end' : 'start')};
  align-content: ${({ isSent }) => (isSent ? 'end' : 'start')};
  whitespace: 'nowrap'; /* Allows wrapping within words */
  border-radius: ${({ isSent }) => (isSent ? '24px 0px 24px 24px' : '16px 16px 16px 0px')};
  background-color: ${({ isSent }) => (isSent ? '#1D1F22' : '#EAECF1')};
  color: ${({ isSent }) => (isSent ? 'white' : '#1D1F22')};
  justify-content: ${({ isSent }) => (isSent ? 'flex-end' : 'flex-start')};
  align-self: ${({ isSent }) => (isSent ? 'flex-end' : 'flex-start')}
`

const StyledAvatar = styled(Avatar)`
  margin: 0 5px;
  background-color: #00c8ca;
`

const StyledInputContainer = styled.div`
  bottom: 0px;
  display: flex;
  align-items: center;
  padding: 0px 0px;
  justify-content: bottom;
`

const StyledInput = styled(Input)`
  flex: 1;
  margin-right: 10px;
  border-radius: 0px;
  border-width: 0px;
  length: 30px;
`
const WizrChatLayout = styled(WizrFlexLayout)`
max-width: 100%
border-bottom: 1px solid #ccc;
gap: 5px;
`

const StyledTextButton = styled(WizrText)`
  text-decoration: underline !important;
`
const StyledButton = styled(WizrButton)`
  width: 20px;
  height: 20px;
  padding: 0px;
  justify-content: center;
`
const StyledInputName = styled.input`
  padding-top: 4px;
  background: #f2f4f9;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #1f1d1d;
  width: 100%;

  &:placeholder {
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #1f1d1d;
  }
`

const StyledTextArea = styled(TextArea)`
    padding-right: 50px; /* Adjust this padding to fit the suffix content */
   

`

const SuffixContainer = styled.div`
  position: absolute;
  top: 93%;
  right: 15px; /* Adjust position as needed */
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  background: transparent;
`

const AutoSolveChatBot = (): JSX.Element => {
  const [threadId, setThreadId] = useState<string>('')
  const [isNewConversation, setIsNewConversation] = useState(true)
  const params = useParams()
  const chatBotId = params?.id

  useEffect(() => {
    const sessionId = uuidv4()
    setThreadId(sessionId)
    setIsNewConversation(true)
    dispatchClearChatBotHistory()
    dispatchBotDetails(chatBotId)
  }, [])

  const botDetails = useBotDetailsSelector()
  const loading = useContentLoadingSelector()
  
  const chatBotHistory = useChatBotHistorySelector()
  const chatContainerRef = useRef<HTMLDivElement | null>(null)
  const chatMessage = useChatMessageSelector() || []
  
  const [width, setWidth] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [messageState, setMessageState] = useState(chatMessage)
  
  useEffect(() => {
    setMessageState(chatMessage)
  }, [chatMessage])

  useEffect(() => {
    if (chatContainerRef?.current) {
      chatContainerRef.current.scrollTop = chatContainerRef?.current?.scrollHeight
    }
  }, [messageState])

  const startFileUpload = (file: any) => {
    console.log(file)
  }
  

  useEffect(() => {    
    const time = getCurrentTimeChatBot()
    if (botDetails?.bot_config?.bot_personality?.bot_messages && chatMessage?.length < 1) {
      dispatchChatMessage({
        text: botDetails?.bot_config?.bot_personality?.bot_messages?.welcome_message?botDetails?.bot_config?.bot_personality?.bot_messages?.welcome_message:'Hey How can I help you today?',
        isSent: false,
        time: time,
      })
    }
  }, [botDetails?.bot_config?.bot_personality?.bot_messages])

  const testChatBot = () => {
    const time = getCurrentTimeChatBot()
    dispatchChatMessage({ text: inputValue ? inputValue : '', isSent: true, time: time })
    setInputValue('')
    const payload = {
      id: chatBotId,
      user_query: inputValue,
      conversation_id: threadId,
      is_new_conversation: isNewConversation,
      exec_type: 'test',
    }
    setIsNewConversation(false)
    const errorMessage = botDetails?.bot_config?.bot_personality?.bot_messages?.unanswerable_message
    dispatchAutoSolveBot({ payload, errorMessage })
  }

  const like = (item: any) => {
    // chatMessage.forEach((message: any) => {
    //   if (message.text === item.text) {
    //       message.disliked = false;
    //       message.liked = true;
    //   }
    // });

    const newState = chatMessage.map((message: any) => {
      if (message.text === item.text) {
        return {
          ...message,
          disliked: false,
          liked: !message.liked, // Toggle the liked state
        }
      }
      return message
    })
    const time = getCurrentTimeChatBot()
    const newStateFinal = [
      ...newState,
      {
        text: botDetails?.bot_config?.bot_personality?.bot_messages?.upvote_message?botDetails?.bot_config?.bot_personality?.bot_messages?.upvote_message:'Thanks for the feedback.',
        isSent: false,
        time: time,
      },
    ]
    dispatchChatMessageState(newStateFinal)
  }
  const dislike = (item: any) => {
   
    const newState = chatMessage.map((message: any) => {
      if (message.text === item.text) {
        return {
          ...message,
          liked: false,
          disliked: !message.disliked, // Toggle the liked state
        }
      }
      return message
    })
    const time = getCurrentTimeChatBot()
    const newStateFinal = [
      ...newState,
      {
        text: botDetails?.bot_config?.bot_personality?.bot_messages?.downvote_message?botDetails?.bot_config?.bot_personality?.bot_messages?.downvote_message:"Thankyou for the upvote, let me know if need help with anything else..",
        isSent: false,
        time: time,
      },
    ]
    dispatchChatMessageState(newStateFinal)
    // dispatchChatMessageState(newState)

    // If the message is found, update its 'liked' property to true
    // if (targetMessage) {
    //     targetMessage.disliked = true;
    // }
  }

  const handleButtonClick = (url: any) => {
    window.open(url, '_blank') // Opens the URL in a new tab/window for download
  }

  const navigate = useNavigate()

  console.log('messageState', messageState);
 
  return (
    <WizrFlexLayout alignItems='center' style={{ padding: '10px' }}>
      <WizrFlexLayout flex={1} alignItems='flex-start' justifyContent='flex-start'>
        <WizrUserJourney
          userJourney={[
            {
              title: (
                <WizrButton
                  padding='0px'
                  type='text'
                  text={
                    <WizrText type='h6' textColor='#707276'>
                      Cx Settings
                    </WizrText>
                  }
                  onClick={() => navigate(-2)}
                  icon={<Hamburger />}
                />
              ),
            },
            {
              title: (
                <WizrButton
                  padding='0px'
                  type='text'
                  text={
                    <WizrText type='h6' textColor='#707276'>
                      {botDetails?.bot_config?.bot_personality?.bot_name}
                    </WizrText>
                  }
                  onClick={() => navigate(-1)}
                />
              ),
              isCurrent: true,
            },
            {
              title: (
                <WizrButton
                  padding='0px'
                  type='text'
                  text={<WizrText type='h6'>{botDetails?.agent_config?.agent_name}</WizrText>}
                />
              ),
              isCurrent: true,
            },
          ]}
        />
      </WizrFlexLayout>
      <WizrFlexLayout flex={12} flexDirection='row' justifyContent='center' alignContent='center' >
        <StyledChatWrapper view={width}>
          <WizrChatLayout
            background='transparent'
            flexDirection='row'
            justifyContent='flex-start'
            alignItems='center'
            style={{ padding: '10px', minHeight: '45px' }}
          >
            <WizrFlexLayout
              flexDirection='row'
              justifyContent='flex-start'
              background='transparent'
              alignItems='center'
              style={{ gap: '5px', padding: '0px' }}
            >
              <WizrIconWhite />
              <WizrText type='h6' textColor='white'>
                Welcome to Wizr
              </WizrText>
            </WizrFlexLayout>
            <WizrFlexLayout
              background='transparent'
              flexDirection='row'
              justifyContent='flex-end'
              style={{ gap: '8px', paddingRight: '0px', maxWidth: '160px' }}
              alignItems='center'
            >
              <WizrButton
                style={{ paddingRight: ' 0px' }}
                text={
                  <WizrText type='body2' textColor='white'>
                    New Session
                  </WizrText>
                }
                type='link'
                onClick={() => {
                  dispatchClearChatBotHistory()
                  setThreadId(uuidv4())
                  dispatchClearChat()
                  setIsNewConversation(true)
                  if (botDetails?.bot_config?.bot_personality?.bot_messages?.welcome_message) {
                    const time = getCurrentTimeChatBot()
                    dispatchChatMessage({
                      text: botDetails?.bot_config?.bot_personality?.bot_messages?.welcome_message,
                      isSent: false,
                      time: time,
                    })
                  }
                }}
              />
              <StyledButton icon={<ChatBotViewIcon />} onClick={() => {}} type='link' />
              <StyledButton
                icon={width ? <Minimize fill={'white'} /> : <Maximize fill={'white'} />}
                onClick={() => {
                  width ? setWidth(false) : setWidth(true)
                }}
                type='link'
              />
            </WizrFlexLayout>
          </WizrChatLayout>
          <StyledChatContainer bulkRun={false}>
            <div
              style={{ height: '63vh', overflow: 'auto', padding: '10px 0px' }}
              ref={chatContainerRef}
            >
              {messageState?.length > 0 &&
                messageState?.map((message, index) => (
                  <StyledMessage key={index} isSent={message.isSent}>
                    {!message.isSent && <WizrChatIcon />}
                    <WizrFlexLayout
                      background='transparent'
                      justifyContent={message?.isSent ? 'flex-end' : 'flex-start'}
                    >
                      <StyledMessageBubble isSent={message.isSent}>
                        {message.isSent ? (
                          <WizrText textColor='white' type='body2'>
                            <WizrMarkDown>{message.text ? message.text : 'Generate'}</WizrMarkDown>
                          </WizrText>
                        ) : (
                          <>
                            <WizrText type='body2'>
                              <>
                                {message.text ? (
                                  <WizrMarkDown>{message.text}</WizrMarkDown>
                                ) : (
                                  <WizrMarkDown>Please try again</WizrMarkDown>
                                )}
                              </>
                            </WizrText>
                            {message.knowledgeBase && message.knowledgeBase.length!==0 && (
                              <WizrFlexLayout
                                justifyContent='flex-start'
                                style={{ gap: '5px', flexWrap: 'wrap',marginBottom:"10px" }}
                                overFlow='hidden'
                                textAlign='flex-start' 
                                alignContent='flex-start' 
                                alignItems='start'
                              >
                                <WizrText type='body2'>Learn More:</WizrText>
                                {message.knowledgeBase &&
                                  message.knowledgeBase.slice(0, 3).map((item: any) => {
                                    // Accessing the keys of the object
                                    const keys:any = Object.keys(item)
                                    const keysToMap = keys.filter((key:any)=>key!=='score');

                                    console.log('keymessage.knowledgeBases', message.knowledgeBase)
                                    // Mapping over the keys to render buttons
                                    return keysToMap.map((key:any, index:any) => (
                                      <Button
                                        style={{ width: 'auto' }}
                                        key={index}
                                        type='link'
                                        icon={
                                          <WizrFlexLayout
                                            flexDirection='row'
                                            style={{
                                              padding: '5px 10px',
                                              borderRadius: '16px',
                                              borderWidth: '1px',
                                              gap: '5px',
                                            }}
                                          >
                                            <PdflogoIcon />
                                            <WizrText type='body2'>
                                              {key ? key : item[key]}
                                            </WizrText>
                                          </WizrFlexLayout>
                                        }
                                        onClick={() => handleButtonClick(item[key])} // Pass URL to the handler function
                                      />
                                    ))
                                  })}
                              </WizrFlexLayout>
                            )}
                          </>
                        )}
                      </StyledMessageBubble>
                      {message.isSent ? (
                        <WizrFlexLayout
                          background='white'
                          justifyContent='flex-end'
                          textAlign='right'
                        >
                          <WizrText type='caption'>{message?.time}</WizrText>
                        </WizrFlexLayout>
                      ) : (
                        <WizrFlexLayout
                          flexDirection='row'
                          justifyContent='flex-start'
                          background='white'
                          alignItems='center'
                        >
                          <Button
                            type='link'
                            icon={<Copy color={'#999B9F'} />}
                            onClick={() => {
                              antdMessage.success('Copied to clipboard', 2)
                              copy(message.text)
                            }}
                          />

                          <Button
                            type='link'
                            shape='circle'
                            icon={<DislikeIcon colour={message?.disliked ? 'blue' : '#999B9F'} />}
                            onClick={() => {
                              dislike(message)
                            }}
                          />

                          <Button
                            type='link'
                            icon={<LikeIcon colour={message?.liked ? 'blue' : '#999B9F'} />}
                            onClick={() => {
                              like(message)
                            }}
                          />
                          <WizrText type='caption'>{message?.time}</WizrText>
                        </WizrFlexLayout>
                      )}
                    </WizrFlexLayout>
                  </StyledMessage>
                ))}
              {loading && (
                <StyledMessage isSent={false}>
                  <WizrChatIcon />
                  <StyledMessageBubble isSent={false} style={{padding:"10px"}}>
                    <BouncingDotsLoader />
                  </StyledMessageBubble>
                </StyledMessage>
              )}
            </div>
            <div className='inputwrapper' style={{ width: '100%' }}>
              <StyledInputContainer>
                <StyledTextArea
                  rows={3}
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder={loading ? 'Generating.......' : 'Ask something'}
                  onPressEnter={testChatBot}
                  className="fixed-textarea"

                />
                <SuffixContainer>
                  <WizrFlexLayout
                    flexDirection='row'
                    background='white'
                    alignItems='center'
                    style={{ gap: '2px', width: '120px', borderWidth: '0px' }}
                  >
                    <WizrUploadContainer
                      onChange={startFileUpload}
                      fileTypes='.pdf,.csv,.docx,.txt'

                    >
                      <StyledButton icon={<Link />} onClick={() => {}} type='link' />
                    </WizrUploadContainer>
                    <WizrUploadContainer
                      onChange={startFileUpload}
                      style={{ alignItems: 'center' }}
                      fileTypes='.png,.jpeg,.gif,.jpg,.svg,.jfif,.pjpeg,.pjp,.webp'

                    >
                      <StyledButton icon={<ImageIcon />} onClick={() => {}} type='link' />
                    </WizrUploadContainer>

                    <WizrUploadContainer onChange={startFileUpload}>
                      <StyledButton icon={<MicroPhone />} onClick={() => {}} type='link' />
                    </WizrUploadContainer>
                    {/* <WizrButton
                      style={{ paddingLeft: '10px', height: '25px', width: '25px' }}
                      shape='circle'
                      onClick={testChatBot}
                      icon={<SentIcon />}
                    /> */}
                    {/* <WizrButton></WizrButton> */}
                    <StyledSentButton icon={<SentIcon />} text='' type='text'/>

                  </WizrFlexLayout>
                </SuffixContainer>
              </StyledInputContainer>
            </div>
          </StyledChatContainer>
          <WizrFlexLayout
            background='transparent'
            justifyContent='space-between'
            style={{
              borderBottomLeftRadius: '16px',
              borderBottomRightRadius: '16px',
              padding: '5px 0px 0px 0px',
              maxHeight:"30px"
            }}
            flexDirection='row'
          >
            <WizrText type='caption' textColor='#1D1F22'>
              © 2024 Wizr.ai. All rights reserved
            </WizrText>
            <WizrText type='caption' textColor='#1D1F22'>
              Powered by Wizr.ai
            </WizrText>
          </WizrFlexLayout>
        </StyledChatWrapper>
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}

export default AutoSolveChatBot


const StyledSentButton = styled(WizrButton)`
border-radius: 50%;
width: 28px;
height: 28px;
padding-right: 5px;
justify-content: center;
align-items: center;
background-color: black;
`;
