import { Popover } from 'antd'
import WizrButton from '../WizrButton'
import MoreIcon from '../../assets/icons/More'
import EditIcon from '../../assets/icons/EditIcon'
import { dispatchSaveCurrentAppDetails, dispatchDeleteApp, dispatchCreateApp, dispatchCreateAppNewtab } from '../../redux/actions/content'
import Copy from '../../assets/icons/Copy'
import { useNavigate } from 'react-router-dom'
import WizrFlexLayout from '../../stories/WizrFlexLayout/WizrFlexLayout'
import DeleteIcon from '../../assets/icons/DeleteIcon'
import { act } from 'react-dom/test-utils'
import { useCurrentAppDetailsSelector } from '../../redux/selectors/content'
import { useAuth } from '../../authContext'
import styled from 'styled-components'
import ViewIcon from '../../assets/icons/ViewIcon'

// const currentAppDetails = useCurrentAppDetailsSelector()



interface IAppActionPopOver {
  detail?: any
  type?:any
}

interface IAppActionPopOverContent {
  onEditAction?: any
  onCopyAction?: any
  onDeleteAction?: any
  type:any
  detail?: any
  onViewAction?: any
}

const AppActionPopoverContent = ({
  onEditAction,
  onCopyAction,
  onDeleteAction,
  type,
  detail,
  onViewAction,
}: IAppActionPopOverContent): JSX.Element => {
  const { userPermission, hasAcces } = useAuth();
  
  const getAccessStatusEdit = () => {
    switch (type) {
      case 'published':
        if (detail.app_category === "package") {
          return !hasAcces('edit_packagepublished_app') 
        }
        else{
          return !hasAcces('edit_generalpublished_app') 

        }     
    
      case 'template':
        if (detail.app_category === "package") {
          return !hasAcces('edit_packagetemplate_app') 
        }
        else{
          return !hasAcces('edit_generaltemplate_app') 

        }
      default:
        return hasAcces('editdraft_app') === true ? false : true 
         }
  }
  
  const getAccessStatusCopy = () => {
    switch (type) {
      case 'published':
        return hasAcces('copypublished_app') === true ? false : true
     
      case 'template':
      
          return !hasAcces('clonetemplate_app') 

      default:
        return hasAcces('copydraft_app') === true ? false : true 
    }
  }

  return (
    <WizrFlexLayout textAlign='left' background='#FFF'>
   <WizrButton
        icon={<EditIcon />}
        text='Edit'
        type='text'
        onClick={onEditAction}
        justifyContent='flex-start'
        // disabled={ detail.app_category === "package" }
        disabled={getAccessStatusEdit()}
      />
{       getAccessStatusEdit() ? <WizrButton
        icon={<ViewIcon />}
        text='View'
        type='text'
        onClick={onViewAction}
        justifyContent='flex-start'
        // disabled={ detail.app_category === "package" }
        // disabled={getAccessStatusEdit()}
      /> : <></>}
      <WizrButton
        icon={<Copy />}
        text='Clone'
        type='text'
        onClick={onCopyAction}
        justifyContent='flex-start'
        disabled={getAccessStatusCopy() }
      />
      { type !== 'template' || type === undefined ? <WizrButton
        icon={<DeleteIcon />}
        text='Delete'
        type='text'
        onClick={onDeleteAction}
        justifyContent='flex-start'
        disabled={type === 'published' ? hasAcces('delete_app') === true ? false : true : hasAcces('delete_app') === true ? false : true }
      /> : <></>}
    </WizrFlexLayout>
  )
}

const AppActionPopOver = ({ detail, type }: IAppActionPopOver): JSX.Element => {    
    
  const navigate = useNavigate()
  // detail.type = 'app'

  const checkForNewWindow = (e: any) => {
    if (e.ctrlKey || e.metaKey) {
  
      return true
    } else {
     
      return false
    }
  }

  const goToCreatePrompt = (e: any, action: string) => {        
    
    const newWindowCheck = checkForNewWindow(e)
    if( newWindowCheck){
      if( action === 'copy' ) {
        const { id, app_id, user_name, user_id, ...restOfItem } = detail;
        const appCreatePayload = {
          ...restOfItem,
          app_version: '0',
          app_name: 'Copy of ' + detail?.app_name,
          app_type: 'Tool',
          description: detail?.description,
          tags: detail?.tags,
          memory: detail?.memory,
          created_on: Date.now(),
          updated_on:Date.now(),
          app_category: "general",
          published: false
        }      
        dispatchCreateAppNewtab(appCreatePayload)        
      }
      else{   
      window.open(`/app-designer/tool/${detail?.id}/${detail?.app_id}edit`, '_blank', 'noopener,noreferrer');
      }


    }else{
    if( action === 'copy' ) {
      const { id, app_id,user_name,user_id, ...restOfItem } = detail;
      const appCreatePayload = {
        ...restOfItem,
        app_version: '0',
        app_name: 'Copy of ' + detail?.app_name,
        app_type: 'Tool',
        description: detail?.description,
        tags: detail?.tags,
        memory: detail?.memory,
        created_on: Date.now(),
        updated_on:Date.now(),
        action: 'copy',
        app_category: "general",
        published: false

      }    
      dispatchCreateApp(appCreatePayload)
      navigate('/app-designer/tool', { state: { action: 'copy'} })
    }
    else{   
      
    dispatchSaveCurrentAppDetails(detail)
    navigate(`/app-designer/tool`, { state: { action, id: detail?.id, appId: detail?.app_id, type: type } })
    }
    }
  }  

  const cloneTemplate = () => {
    const { id, app_id,tenant_id, ...restOfItem } = detail;
    const appCreatePayload = {
      ...restOfItem,
      app_name: 'Copy of ' + detail?.app_name,
      app_type: 'Tool',
      description: detail?.description,
      tags: detail?.tags,
      memory: detail?.memory,
      type: "app",
      app_version: '0',
    }              
    dispatchCreateApp(appCreatePayload)
    navigate('/app-designer/tool', { state: { action: 'copy'} })
  }

  const onCopy = (e: any) => { type === 'template' ? cloneTemplate() : goToCreatePrompt(e, 'copy')}

  const onEdit = (e: any) => {type !== 'published' ? goToCreatePrompt(e, 'edit') : goToCreatePrompt(e, 'published_edit')}

  const onView = (e: any) => {goToCreatePrompt(e, 'view') }

  const onDelete = () => dispatchDeleteApp(detail?.id)

  return (
    <Popover
      content={
        <AppActionPopoverContent
          onCopyAction={onCopy}
          onEditAction={onEdit}
          onViewAction={onView}
          onDeleteAction={onDelete}
          type={type}
          detail={detail}
        />
      }
      trigger='click'
      style={{margin: '10px'}}
    >
      <StyledButton icon={<MoreIcon />} text='' type='text' style={{height: '40px', width: '40px', borderRadius: '50px'}}/>
    </Popover>
  )
}

export default AppActionPopOver

const StyledButton = styled(WizrButton)`
border-radius: 50%;
width: 20px;
height: 20px;
padding-right: 8px;
justify-content: center;
align-items: center;
  /* Default styles */
  path {
    stroke: #999B9F; /* Default stroke color */
    transition: stroke 0.3s ease; /* Smooth transition for color change */
  }

  /* Hover styles */
  &:hover path {
    stroke: #1D1F22
  }
`;

